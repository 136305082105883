import styled from 'styled-components';
import {
    GeneralButton, ContentBodyContainer, GridCol, FlexGridSixth, ContainerFullWidth
} from '../../../components/Common/CommonLayout';
import { ColorName } from "../../../components/Variables";
import Images from "../../../themes/Images";

const Container = styled(ContentBodyContainer)`
    & .invite-item{
        margin: 2em 0;
    }
    &.content-left{
        padding: 2em 0;
        color: ${ColorName.chathamsBlueDark};
    }
    & .cta-header{
        font-size: 2.6em;
        font-weight: 300;
    }
    & .sologan{
        padding: 2em 0 1em 0;
        font-weight: 900;
    }
`;

const ContainerFull = styled(ContainerFullWidth)`
    background-image: url(${Images.bannerHomepage});
    background-repeat: no-repeat;
    min-height: 50vh;
    background-size: cover;
    background-position: center;
    &.container-banner{
        
    }
    &.for-business-idea{
        background: ${ColorName.chathamsBlueDark};
        color: white;
        font-weight: 600;
        min-height: unset;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
    &.for-announcement{
        background: #CCE0E7;
        min-height: unset;
        padding: 2em 0;
        color: ${ColorName.chathamsBlueDark};
        img{
            border: none;
        }
        & .title-announcement{
            font-size: 1.2em;
            font-weight: 600;
        }
        & .content-announcement{

        }
    }
`;

const ButtonJoin = styled(GeneralButton)`
    margin-top: 1em;
    &.button-banner{
        font-size: 0.8em;
        margin-right: 0.5em;
        border: solid 1px ${ColorName.chathamsBlueDark};
    }
    &.button-explore{
        margin-top: 0;
        font-size: 0.8em;
        margin-left: 0.5em;
        border: solid 1px ${ColorName.white};
        color: white;
    }
`;

const FlexGridSixthTemplate = styled(FlexGridSixth)`
    margin: 0 1em;
    & .border-top{
        border-top: 1px solid ${ColorName.chathamsBlueDark};
    }
    & .order-2{
        order: 2;
        padding-left: 2em;
    }
    & .order-1{
        padding-right: 2em;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        & .order-2{
            padding: 0;
        }
        & .order-1{
            padding: 0;
        }
        & .reverse{
            order: 2;
        }
    }
`;
const GridColHomepage = styled(GridCol)`
    &.left-content{
        margin-right: 2em;
    }
    &.content-business-idea{
        padding: 0.5em 0;
        font-size: 1.2em;
    }
    & ul{
        list-style: none;
        padding-inline-start: 0;
        & li{
            display: flex;
            flex-direction: column;
            padding-bottom: 1em;
        }
        & .title{
            color: ${ColorName.fern};
            font-size: 1.5em;
            font-weight: 600;
        }
        & .description{
            color: ${ColorName.chathamsBlueDark};
            font-size: 0.8em;
        }
    }
    & .image-invite{
        width: 100%;
        border: 1px solid ${ColorName.chathamsBlueDark};
        // border-top: none;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Title = styled.div`
    font-size: 2em;
    font-weight: 600;
    text-align: left;
    color: ${ColorName.chathamsBlueDark}
`;

export {
    Container,
    GridColHomepage,
    Title,
    FlexGridSixthTemplate,
    ButtonJoin,
    ContainerFull
};
