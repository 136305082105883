import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import {
  Container,
  GridColHomepage,
  FlexGridSixthTemplate,
  Title,
  GeneralButtonUser
} from "./Search.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import SummryOverview from "../../../components/Common/SummaryOverview";
import SearchAction from "../../../actions/Search";
import UserDetailPopup from "../../../components/Layout/PortalTemplate/UserDetailPopup";
import PopupUserExplore from "../../../containers/PortalContainer/User/Overview/PopupUserExplore";
import history from "../../../history";
import ColorName from "../../../components/Variables/ColorName.js";
import Utils from "../../../libs/Utils.js";

const Search = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isShowUserDetailPopup, setIsShowUserDetailPopup] = useState(false);
  const [isShowPopUpSendMessage, setIsShowPopSendMessage] = useState(false);
  const dataSearchHompage = useSelector((state) => state.SearchHomepage.dataSearchHompage);
  const valueSearch = useSelector((state) => state.SearchHomepage.search);
  const isLoading = useSelector((state) => state.SearchHomepage.isLoading);
  const isLogged = useSelector((state) => state.Authentication.isLogged);
  const [search, setSearch] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [secondLanguage, setSecondLanguage] = useState(Utils.getCurrentLanguage() === 'al' ? 'en' : 'al');

  useEffect(() => {
    setSecondLanguage(i18n.language === 'al' ? 'en' : 'al');
  }, [i18n.language]);

  useEffect(() => {
    setSearch(valueSearch);
  }, [valueSearch]);

  const getResult = () => { // eslint-disable-line
    const data = {
      keyword: search
    }
    if (search === '') return dispatch(SearchAction.setSearchHomepageDefault())
    dispatch(SearchAction.searchHomepage(data))
  };

  const showUserDetail = (data) => {
    setIsShowUserDetailPopup(true);
    setCurrentUser(data)
  };
  const openPopUpSendMessage = () => {
    setIsShowPopSendMessage(true);
  };

  const _renderFooterButtons = (item) => {
    return (
      <div className="container-button">
        <GeneralButtonUser
          type="button"
          background={ColorName.chathamsBlue}
          className="for-more bor-ra-25"
          value={t("homepageUser.More")}
          onClick={() => showUserDetail(item)}
        />
        <GeneralButtonUser
          type="button"
          className="bor-ra-25 position-contact-me"
          background={ColorName.chathamsBlue}
          value={t("general.Send Message")}
          height="40px"
          onClick={() => openPopUpSendMessage()}
        />
      </div>
    );
  };

  const _renderItemCommunity = () => {
    const render = _.map(dataSearchHompage.searchCommunity, (item, index) => {
      return (
        <Container key={index} className='item'>
          <div>{t('general.Email')}: {item.email}</div>
          <div>{t('general.Name')}: {item.userData.first_name} {item.userData.last_name}</div>
          {isShowUserDetailPopup && (<UserDetailPopup userDetail={currentUser} hideModal={() => setIsShowUserDetailPopup(false)} />)}
          {isShowPopUpSendMessage && (<PopupUserExplore hideModal={() => setIsShowPopSendMessage(false)} dataItem={item} />)}
          {isLogged && (_renderFooterButtons(item))}
        </Container>
      )
    })
    return render;
  };

  const _renderContentCommunities = () => {
    return (
      <div className="content-page">
        <FlexGridSixthTemplate>
          <GridColHomepage colSize="6" gridTemplate="6">
            <Title>{t('general.Community')}</Title>
            {_renderItemCommunity()}
          </GridColHomepage>
        </FlexGridSixthTemplate>
      </div>
    );
  }

  const _renderItemNews = () => {
    const render = _.map(dataSearchHompage.searchNews, (item, index) => {
      return (
        <Container key={index} className='item'>
          <div>{t('general.Title')}: {item.title_json[i18n.language] || item.title_json[secondLanguage]}</div>
          <div>{t('general.Date')}: {item.created_at}</div>
          <div className="container-button">
            <GeneralButtonUser
              type="button"
              background={ColorName.chathamsBlue}
              className="for-more bor-ra-25"
              value={t("homepageUser.More")}
              onClick={() => history.push(`/homepage/news/detail?id=${item.id}`)}
            />
          </div>
        </Container>
      )
    })
    return render;
  };

  const _renderContentNews = () => {
    return (
      <div className="content-page">
        <FlexGridSixthTemplate>
          <GridColHomepage colSize="6" gridTemplate="6">
            <Title>{t('general.News')}</Title>
            {_renderItemNews()}
          </GridColHomepage>
        </FlexGridSixthTemplate>
      </div>
    );
  }

  const _renderItemEvent = () => {
    const render = _.map(dataSearchHompage.searchEvents, (item, index) => {
      return (
        <Container key={index} className='item'>
          <div>{t('general.Title')}: {item.title_json[i18n.language] || item.title_json[secondLanguage]}</div>
          <div>{t('general.Date')}: {item.created_at}</div>
          <div className="container-button">
            <GeneralButtonUser
              type="button"
              background={ColorName.chathamsBlue}
              className="for-more bor-ra-25"
              value={t("homepageUser.More")}
              onClick={() => history.push(`/homepage/events/detail?id=${item.id}`)}
            />
          </div>
        </Container>
      )
    })
    return render;
  };

  const _renderContentEvents = () => {
    return (
      <div className="content-page">
        <FlexGridSixthTemplate>
          <GridColHomepage colSize="6" gridTemplate="6">
          <Title>{t('general.Events')}</Title>
            {_renderItemEvent()}
          </GridColHomepage>
        </FlexGridSixthTemplate>
      </div>
    );
  }

  const _renderItemLibrary = () => {
    const render = _.map(dataSearchHompage.searchLibraries, (item, index) => {
      return (
        <Container key={index} className='item'>
          <div>{t('general.Title')}: {item.title_json[i18n.language] || item.title_json[secondLanguage]}</div>
          <div>{t('general.Date')}: {item.created_at}</div>
          <div className="container-button">
            <GeneralButtonUser
              type="button"
              background={ColorName.chathamsBlue}
              className="for-more bor-ra-25"
              value={t("homepageUser.More")}
              onClick={() => history.push(`/homepage/library/book-detail?id=${item.id}`)}
            />
          </div>
        </Container>
      )
    })
    return render;
  };

  const _renderContentLibraries = () => {
    return (
      <div className="content-page">
        <FlexGridSixthTemplate>
          <GridColHomepage colSize="6" gridTemplate="6">
          <Title>{t('general.Library')}</Title>
            {_renderItemLibrary()}
          </GridColHomepage>
        </FlexGridSixthTemplate>
      </div>
    );
  }

  const _renderSummaryOverview = () => {
    return <SummryOverview />;
  };

  const _renderMain = () => {
    return (
      <Container>
        {!_.isEmpty(_.get(dataSearchHompage, 'searchNews')) && _renderContentNews()}
        {!_.isEmpty(_.get(dataSearchHompage, 'searchEvents')) && _renderContentEvents()}
        {!_.isEmpty(_.get(dataSearchHompage, 'searchCommunity')) && _renderContentCommunities()}
        {!_.isEmpty(_.get(dataSearchHompage, 'searchLibraries')) && _renderContentLibraries()}
        {_renderSummaryOverview()}
      </Container>
    );
  };
  return (
    <DefaultLayout
      context={_renderMain()}
      currentPage="search"
      isLoading={isLoading}
    />
  );
};

export default Search;
