import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
    SideBarContainer,
    Item
} from "./SideBar.styles";
import history from '../../../../history';
import Utils from "../../../../libs/Utils";
import LoginActions from "../../../../actions/Authentication";
import Constants from "../../../../constants/Constants";

const UserSideBar = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [userData, setUserData] = useState([]);
    const [selectedPage, setSelectedPage] = useState('overView');
    const numberUnread = useSelector((state) => state.Message.numberUnread);

    useEffect(() => {
        const data = Utils.convertSnakeKeyToCamel(Utils.getSavedUserData());
        if (!_.isEmpty(data)) {
            setUserData(_.assign(data, data.userData, data.userData.extraDataJson));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedPage(props.page);
    }, [props]);

    const switchPage = (page) => {
        history.push(page);
    };

    const switchPageUserFunding = (page) => {
        if (!_.isEmpty(userData) && userData.role.name === "Investor") {
            Utils.popupAlert({
                type: "error",
                text: t("general.Access denied"),
                title: "Oops..."
            });
        } else {
            history.push(page);
        }
    };

    const logout = () => {
        if (userData.role.name === Constants.ROLE_NAME.LIMITED_USER) return dispatch(LoginActions.logoutLimitedUser());
        return dispatch(LoginActions.logout());
    };

    return (
        <SideBarContainer width="200px">
            <Item
                className={selectedPage === 'userOverview' ? 'selected' : ''}
                onClick={() => switchPage('/user-overview')}
            >
                <span>{t("general.Overview")}</span>
                <i className={`${selectedPage === 'userOverview' ? '' : 'hide-icon'}`} />
            </Item>
            {(!_.isEmpty(userData) && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER)
                && (
                    <>
                        {(!_.isEmpty(userData) && (userData.status === Constants.USER_STATUS.APPROVED || userData.status === Constants.USER_STATUS.USER_UPDATE))
                            && (
                                <Item
                                    className={selectedPage === 'UserExplore' ? 'selected' : ''}
                                    onClick={() => switchPage('/user-explore')}
                                >
                                    <span>{t("general.Explore")}</span>
                                    <i className={`${selectedPage === 'UserExplore' ? '' : 'hide-icon'}`} />
                                </Item>
                            )}
                        <Item
                            className={selectedPage === 'messages' ? 'selected' : ''}
                            onClick={() => switchPage('/messages')}
                        >
                            <span>{t("general.Messages")}</span>
                            <div className='ticker'><span>{_.parseInt(numberUnread) > 0 ? numberUnread : ''}</span><i className={`${selectedPage === 'messages' ? '' : 'hide-icon'}`} /></div>
                        </Item>
                        <Item
                            className={selectedPage === 'setting' ? 'selected' : ''}
                            onClick={() => switchPage('/add-view-user')}
                        >
                            <span>{t("general.Profile")}</span>
                            <i className={`${selectedPage === 'setting' ? '' : 'hide-icon'}`} />
                        </Item>
                        {(!_.isEmpty(userData)
                            && userData.role.name === Constants.ROLE_NAME.ENTREPRENEUR
                            && userData.status === Constants.USER_STATUS.APPROVED)
                            && (
                                <Item
                                    className={(selectedPage === 'userFunding' || selectedPage === "userFundingDetail") ? 'selected' : ''}
                                    onClick={() => switchPageUserFunding('/user-funding')}
                                >
                                    <span>{t("general.Funding")}</span>
                                    <i className={`${(selectedPage === 'userFunding' || selectedPage === "userFundingDetail") ? '' : 'hide-icon'}`} />
                                </Item>
                            )}
                    </>
                )
            }
            {(!_.isEmpty(userData) && userData.role.name === Constants.ROLE_NAME.LIMITED_USER)
                && (
                    <Item
                        className={selectedPage === 'messages' ? 'selected' : ''}
                        onClick={() => history.push('/messages')}
                    >
                        <span>{t("general.Messages")}</span>
                        <i className={`${selectedPage === 'messages' ? '' : 'hide-icon'}`} />
                    </Item>
                )
            }
            <Item onClick={logout}>
                <span>{t("general.Logout")}</span>
                <i className={`${selectedPage === '' ? '' : 'hide-icon'}`} />
            </Item>
        </SideBarContainer>
    );
};

UserSideBar.propTypes = {
    page: PropTypes.string
};

export default UserSideBar;
