import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Content,
    Fields,
    Field,
    Footer,
    InputContainerNewLayout
} from "./Authentication.styles";
import ResetPasswordActions from "../../../actions/ResetPassword";
import Button from "../../../components/Common/ButtonFrontEnd";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import history from "../../../history";
import { ColorName } from "../../../components/Variables";
import Banner from "../../../components/Common/Banner";
import UserPageTemplateV1 from "../../../components/Layout/GuestTemplate/DefaultLayout";
import Constants from "../../../constants/Constants";

const ForgotPassword = () => {
    const { t, i18n } = useTranslation();
    const isLoading = useSelector((state) => state.User.isLoading);
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();

    const switchScreen = (screen) => {
        history.push(screen);
    };

    const inputEmail = (e) => {
        setEmail(e.target.value);
    };

    const forgotPassword = () => {
        const data = {
            email
        };
        dispatch(ResetPasswordActions.requestResetPassword(data));
    };

    const onKeyEnterPress = (e) => {
        if (e.which === 13) {
            const data = {
                email
            };
            dispatch(ResetPasswordActions.requestResetPassword(data));
        }
    };

    return (
        <UserPageTemplateV1 context={(
            <div>
                <div className="full-width">
                    <Banner type="forgotPassword" />
                </div>
                <Content>
                    {isLoading && <Loading />}
                    <Fields>
                        <Field className="mt-input">
                            <InputContainerNewLayout>
                                <input type='text' placeholder={t("general.Enter your email")} onChange={inputEmail} onKeyPress={onKeyEnterPress} />
                            </InputContainerNewLayout>
                        </Field>
                    </Fields>

                    <Fields className="mt-section">
                        <div className="line" />
                        <Field className="alignCenter">
                            <Button
                                onClick={() => forgotPassword()}
                                name={t("general.Submit")}
                                width={i18n.language === Constants.LANGUAGE_CODE.EN ? "7em" : "10em"}
                                background={ColorName.bahamaBlue}
                                color={ColorName.oysterBay}
                                border="none"
                            />
                        </Field>
                        <div className="line mt-2" />
                    </Fields>
                    <Footer>
                        <Button
                            onClick={() => switchScreen("/authentication")}
                            name={t("general.Sign In")}
                            width={i18n.language === Constants.LANGUAGE_CODE.EN ? "7em" : "10em"}
                            background={ColorName.bahamaBlue}
                            color={ColorName.oysterBay}
                            border="none"
                        />
                        {' '}
                        Or
                        {' '}
                        <Button
                            onClick={() => switchScreen("/homepage/register")}
                            name={t("general.Sign Up")}
                            width={i18n.language === Constants.LANGUAGE_CODE.EN ? "7em" : "10em"}
                            background={ColorName.bahamaBlue}
                            color={ColorName.oysterBay}
                            border="none"
                        />
                    </Footer>
                </Content>
            </div>
        )}
        />
    );
};

export default ForgotPassword;
