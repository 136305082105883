import ActionTypes from "../constants/ActionTypes";

const initialState = {
  isSuccess: false,
  hasError: false,
  isLoading: false,
  userData: [],
  UserById: [],
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ActionTypes.USER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        hasError: false,
        isLoading: false,
        userData: action.data,
      };
    case ActionTypes.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        hasError: false,
        isLoading: false,
        UserById: action.data,
      };
    case ActionTypes.USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ActionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        hasError: true,
      };
    case ActionTypes.REQUEST_UPDATE_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    default:
      return state;
  }
};
