import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    UserDetailsContainer,
    Row,
    DropDown,
    ProgressBar,
    WrapperInput
} from "./Users.styles";
import {
    VerticalContainer,
    PageTitle,
    GeneralButton
} from "../../../../components/Common/CommonLayout";
import AdminOverViewAction from '../../../../actions/AdminOverView';
import { ColorName } from "../../../../components/Variables";
import Validation from "../../../../libs/Validation";
import CONSTANTS from "../../../../constants/Constants";

const AddUser = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [roleName, setRoleName] = useState('');
    const isLoading = useSelector((state) => state.AdminOverView.isLoading);
    const roles = useSelector((state) => state.AdminOverView.roles);
    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        address: "",
        password: "",
        password_confirmation: ""
    });
    const [propertyType, setPropertyType] = useState();
    useEffect(() => {
        dispatch(AdminOverViewAction.getRoles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderDropDownRoles = () => {
        const render = _.map(roles, (role, index) => {
            if (role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN 
                || role.name === CONSTANTS.ROLE_NAME.JUDGE
                || role.name === CONSTANTS.ROLE_NAME.LIMITED_ADMIN
                ) 
                return <option key={index} value={role.name}>{t(`general.${role.name}`)}</option>;
        });
        return render;
    };

    const setRole = (e) => {
        setRoleName(e.target.value);
        if (e.target.value === CONSTANTS.ROLE_NAME.ENTREPRENEUR
            || e.target.value === CONSTANTS.ROLE_NAME.INVESTOR
            || e.target.value === CONSTANTS.ROLE_NAME.CONSULTANT) setPropertyType();
    };

    const saveUser = () => {
        const validationRules = [
            {
                name: t("general.Email"),
                value: userData.email,
                rule: ["required", "email"]
            },
            {
                name: t("general.Password"),
                value: userData.password,
                rule: "required"
            },
            {
                name: t("general.Confirm Password"),
                value: userData.password_confirmation,
                rule: "equal",
                ruleValue: userData.password
            },
            {
                name: t("general.First Name"),
                value: userData.first_name,
                rule: "required"
            },
            {
                name: t("general.Last Name"),
                value: userData.last_name,
                rule: "required"
            },
            {
                name: t("general.Phone number"),
                value: userData.phone_number,
                rule: "required"
            },
            {
                name: t("general.Address"),
                value: userData.address,
                rule: "required"
            },
            {
                name: t("general.Role"),
                value: roleName,
                rule: "required"
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        const formData = new FormData();
        formData.append('email', userData.email);
        formData.append('password', userData.password);
        formData.append('password_confirmation', userData.password_confirmation);
        formData.append('user_data[first_name]', userData.first_name);
        formData.append('user_data[last_name]', userData.last_name);
        formData.append('user_data[phone_number]', userData.phone_number);
        formData.append('user_data[address]', userData.address);
        const findRoleID = _.find(roles, (el) => el.name === roleName);
        formData.append('role_id', findRoleID.id);
        if (propertyType) formData.append('property_type', propertyType);
        dispatch(AdminOverViewAction.addNewUser(formData));
    };

    const renderPropertyType = (name) => {
        if (name === CONSTANTS.ROLE_NAME.ENTREPRENEUR) {
            return _.map(_.keys(CONSTANTS.USER_PROPERTY_TYPE), (value, index) => {
                if (CONSTANTS.USER_PROPERTY_TYPE[value] === 1 || CONSTANTS.USER_PROPERTY_TYPE[value] === 2) {
                    return <option key={index} value={CONSTANTS.USER_PROPERTY_TYPE[value]}>{value}</option>;
                }
            });
        }
        if (name === CONSTANTS.ROLE_NAME.INVESTOR) {
            return _.map(_.keys(CONSTANTS.USER_PROPERTY_TYPE), (value, index) => {
                if (CONSTANTS.USER_PROPERTY_TYPE[value] !== 4) {
                    return <option key={index} value={CONSTANTS.USER_PROPERTY_TYPE[value]}>{value}</option>;
                }
            });
        }
        if (name === CONSTANTS.ROLE_NAME.CONSULTANT) {
            return _.map(_.keys(CONSTANTS.USER_PROPERTY_TYPE), (value, index) => {
                if (CONSTANTS.USER_PROPERTY_TYPE[value] === 4 || CONSTANTS.USER_PROPERTY_TYPE[value] === 1) {
                    return <option key={index} value={CONSTANTS.USER_PROPERTY_TYPE[value]}>{value}</option>;
                }
            });
        }
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={isLoading}
            page="users"
            content={(
                <BodyContainer>
                    <PageTitle>
                        {t("general.Add New User")}
                    </PageTitle>
                    <ProgressBar className="mb-2">
                        <VerticalContainer>
                            <UserDetailsContainer>
                                <Row>
                                    <label htmlFor="email">
                                        {t("general.Email")}
:
                                        {' '}
                                    </label>
                                    <WrapperInput
                                        type="text"
                                        value={userData.email || ""}
                                        onChange={(event) => setUserData({ ...userData, email: event.target.value })}
                                    />
                                </Row>
                                <Row>
                                    <label htmlFor="email">
                                        {t("general.Password")}
:
                                        {' '}
                                    </label>
                                    <WrapperInput
                                        type="password"
                                        value={userData.password || ""}
                                        onChange={(event) => setUserData({ ...userData, password: event.target.value })}
                                    />
                                </Row>
                                <Row>
                                    <label htmlFor="email">
                                        {t("general.Confirm Password")}
:
                                        {' '}
                                    </label>
                                    <WrapperInput
                                        type="password"
                                        value={userData.password_confirmation || ""}
                                        onChange={(event) => setUserData({ ...userData, password_confirmation: event.target.value })}
                                    />
                                </Row>
                                <Row>
                                    <label htmlFor="firstName">
                                        {t("general.First Name")}
:
                                        {' '}
                                    </label>
                                    <WrapperInput
                                        type="text"
                                        id="firstName"
                                        value={userData.first_name || ""}
                                        onChange={(event) => setUserData({ ...userData, first_name: event.target.value })}
                                    />
                                </Row>
                                <Row>
                                    <label htmlFor="lastName">
                                        {t("general.Last Name")}
:
                                        {' '}
                                    </label>
                                    <WrapperInput
                                        type="text"
                                        id="lastName"
                                        value={userData.last_name || ""}
                                        onChange={(event) => setUserData({ ...userData, last_name: event.target.value })}
                                    />
                                </Row>
                                <Row>
                                    <label htmlFor="Phone">
                                        {t("general.Phone number")}
:
                                        {' '}
                                    </label>
                                    <WrapperInput
                                        type="text"
                                        id="Phone"
                                        value={userData.phone_number || ""}
                                        onChange={(event) => setUserData({ ...userData, phone_number: event.target.value })}
                                    />
                                </Row>
                                <Row>
                                    <label htmlFor="address">
                                        {t("general.Address")}
:
                                        {' '}
                                    </label>
                                    <WrapperInput
                                        type="text"
                                        id="address"
                                        value={userData.address || ""}
                                        onChange={(event) => setUserData({ ...userData, address: event.target.value })}
                                    />
                                </Row>
                            </UserDetailsContainer>
                            <UserDetailsContainer>
                                <Row>
                                    <label htmlFor="role">
                                        {t("general.Role")}
                                        {' '}
                                    </label>
                                    <DropDown value={roleName} onChange={(e) => setRole(e)}>
                                        <option value='' disabled>{t("general.Choose role")}</option>
                                        {
                                            renderDropDownRoles()
                                        }
                                    </DropDown>
                                </Row>
                                {(roleName === CONSTANTS.ROLE_NAME.ENTREPRENEUR || roleName === CONSTANTS.ROLE_NAME.CONSULTANT || roleName === CONSTANTS.ROLE_NAME.INVESTOR) && (
                                    <Row>
                                        <label htmlFor="role">
                                            {t("general.Type")}
:
                                            {' '}
                                        </label>
                                        <DropDown value={propertyType || ''} onChange={(e) => setPropertyType(e.target.value)}>
                                            <option value='' disabled>{t("general.Choose property type")}</option>
                                            {
                                                renderPropertyType(roleName)
                                            }
                                        </DropDown>
                                    </Row>
                                )}
                            </UserDetailsContainer>
                            <Row marginTop="4em">
                                <GeneralButton
                                    type="button"
                                    value={t("general.Add User")}
                                    background={ColorName.chathamsBlue}
                                    onClick={() => saveUser()}
                                />
                            </Row>

                        </VerticalContainer>
                    </ProgressBar>
                </BodyContainer>
            )}
        />
    );
};

export default AddUser;
