import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    FormItem,
    FormContainer,
    SubmitButton,
    SelectionTag,
    SelectionContainer
} from "../../../../GuestContainer/UserRegister/UserRegister.styles";
import GeneralCheckBoxForm from "../../../../../components/Common/GeneralCheckBoxForm";
import { GeneralInputForm, GeneralTextAreaFrom } from "../../../../../components/Common/CommonLayout";
import GeneralDropdownForm from "../../../../../components/Common/GeneralDropdownForm";
import LIST from "../ResolveList";
import Utils from "../../../../../libs/Utils";
import AdminOverViewAction from "../../../../../actions/AdminOverView";
import Validation from "../../../../../libs/Validation";

const Business = (props) => {
    const { t } = useTranslation();
    const checkBoxRef = [];
    const locateOptions = LIST.resolveCountryList();
    const yearOptions = LIST.yearOfEstablishment();
    const LanguageOptions = LIST.resolveLanguageList();
    const dispatch = useDispatch();
    const [userID, setUserID] = useState();
    const [formData, setFormData] = useState({
        email: '',
        role_id: '',
        property_type: '',
        password: ''
    });
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        address: '',
        phone_number: '',
        website: '',
        facebook: '',
        instagram: '',
        linkedin: ''
    });
    const [extraData, setExtraData] = useState({
        interested: [],
        located: '',
        city: '',
        language: [],
        industry_operate: '',
        operate_locate: [],
        idea_describe: '',
        consultancy_needed: [],
        administrator_name: '',
        administrator_mail: '',
        number_of_employee: '',
        establishment_year: '',
        turnover: '',
        profit: '',
        business_idea: '',
        legal_investing_name: '',
        hear_about: [],
        about_me: ''
    });
    const [describeOtherChoie, setDescribeOtherChoie] = useState({});
    const [isHasEmptyFields, setIsHasEmptyFields] = useState(false);

    useEffect(() => {
        const { originalUserData } = props;
        if (!_.isEmpty(originalUserData)) {
            setUserID(originalUserData.id);
            setFormData({
                email: originalUserData.email,
                status: originalUserData.status.toString()
            });
            setUserData({
                first_name: originalUserData.userData.firstName,
                last_name: originalUserData.userData.lastName,
                address: originalUserData.userData.address,
                phone_number: originalUserData.userData.phoneNumber,
                website: originalUserData.userData.website,
                facebook: originalUserData.userData.facebook,
                instagram: originalUserData.userData.instagram,
                linkedin: originalUserData.userData.linkedin,
            });
            if (originalUserData.userData.extraDataJson) {
                const newExtraData = {
                    interested: originalUserData.userData.extraDataJson.interested.split(','),
                    located: originalUserData.userData.extraDataJson.located,
                    city: originalUserData.userData.extraDataJson.city,
                    language: originalUserData.userData.extraDataJson.language.split(','),
                    industry_operate: originalUserData.userData.extraDataJson.industry_operate,
                    operate_locate: originalUserData.userData.extraDataJson.operate_locate.split(','),
                    idea_describe: originalUserData.userData.extraDataJson.idea_describe,
                    consultancy_needed: originalUserData.userData.extraDataJson.consultancy_needed.split(','),
                    administrator_name: originalUserData.userData.extraDataJson.administrator_name,
                    administrator_mail: originalUserData.userData.extraDataJson.administrator_mail,
                    number_of_employee: originalUserData.userData.extraDataJson.number_of_employee,
                    establishment_year: originalUserData.userData.extraDataJson.establishment_year,
                    turnover: originalUserData.userData.extraDataJson.turnover,
                    profit: originalUserData.userData.extraDataJson.profit,
                    business_idea: originalUserData.userData.extraDataJson.business_idea,
                    legal_investing_name: originalUserData.userData.extraDataJson.legal_investing_name,
                    hear_about: originalUserData.userData.extraDataJson.hear_about.split(','),
                    about_me: originalUserData.userData.extraDataJson.about_me || ''
                };
                if (originalUserData.userData.extraDataJson.business_idea_name) newExtraData.business_idea_name = originalUserData.userData.extraDataJson.business_idea_name;
                if (originalUserData.userData.extraDataJson.industry_belong) newExtraData.industry_belong = originalUserData.userData.extraDataJson.industry_belong;
                if (originalUserData.userData.extraDataJson.new_idea_describe) newExtraData.new_idea_describe = originalUserData.userData.extraDataJson.new_idea_describe;
                setExtraData(newExtraData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.originalUserData]);

    useEffect(() => {
        if (props.avatar) setUserData({ ...userData, avatar: props.avatar });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const selectMultipleChoice = (parentData, value, dataType) => {
        let newData = [...parentData];
        if (!newData.includes("None")) {
            if (!newData.includes(value)) {
                newData.push(value);
            } else {
                newData = _.remove(newData, (el) => el !== value);
            }
        } else {
            newData = _.remove(newData, (el) => el !== "None");
            newData.push(value);
        }
        if (dataType === 'interested') setExtraData({ ...extraData, interested: newData });
        if (dataType === 'consultancyNeeded') setExtraData({ ...extraData, consultancy_needed: newData });
        if (dataType === 'hearAbout') setExtraData({ ...extraData, hear_about: newData });
    };
    
    const validateForAbout = () => {
        const validationRules = [
            {
                name: t("general.About"),
                value: extraData.about_me,
                rule: [{ max: 300 }]
            }
        ];
        if (!Validation.formValidation(validationRules)) return true;
        return false;
    };
    const submitForm = () => {
        Utils.popupAlert({
            type: "warning",
            title: t("rounderTable.Do you want to update"),
            buttons: [t("general.Cancel"), true],
            dangerMode: true
        }).then((willContinue) => {
            if (willContinue) {
                let flag = false;
                const flagAbout = validateForAbout();
                if (flagAbout) {
                    return;
                }
                const submitFormData = new FormData();
                const notRequireFields = ['address', 'website', 'facebook', 'instagram', 'linkedin', 'file_application', 'about_me', 'legal_investing_name'];
                _.map(_.keys(formData), (item) => {
                    submitFormData.append(`${item}`, formData[item]);
                    if (!formData[item]) flag = true;
                });
                _.map(_.keys(userData), (item) => {
                    submitFormData.append(`user_data[${item}]`, userData[item]);
                    if (!userData[item] && !notRequireFields.includes(item)) flag = true;
                });
                _.map(_.keys(extraData), (item) => {
                    if (_.isArray(extraData[item])) {
                        const otherData = [...extraData[item]];
                        otherData.push(describeOtherChoie[item]);
                        !extraData[item].includes("Other")
                            ? submitFormData.append(`user_data[extra_data][${item}]`, extraData[item])
                            : submitFormData.append(`user_data[extra_data][${item}]`, otherData);
                    } else {
                        submitFormData.append(`user_data[extra_data][${item}]`, extraData[item]);
                    }
                    if (((!extraData[item] && item !== 'identity_type') || (_.isArray(extraData[item]) && _.isEmpty(extraData[item]))) && !notRequireFields.includes(item)) flag = true;
                });
                if (flag) {
                    Utils.popupAlert({ title: t("alert.Warning"), text: t('alert.Found empty field(s)'), type: 'warning' });
                    setIsHasEmptyFields(true);
                    return;
                }
                dispatch(AdminOverViewAction.updateRoleForUser(submitFormData, userID));
                setIsHasEmptyFields(false);
            }
        });
        
    };

    const selectTag = (parentData, value, type) => {
        let newData = [...parentData];
        if (!newData.includes(value)) {
            newData.push(value);
        } else {
            newData = _.remove(newData, (el) => el !== value);
        }
        if (type === 'operate') setExtraData({ ...extraData, operate_locate: newData });
        if (type === 'language') setExtraData({ ...extraData, language: newData });
    };

    const _renderTags = (data, type) => {
        const render = _.map(data, (tag, index) => {
            return (
                <SelectionTag key={index}>
                    <span>{tag}</span>
                    <i className="fas fa-times" onClick={() => selectTag(data, tag, type)} />
                </SelectionTag>
            );
        });
        return render;
    };

    const _renderFormItemsForBusiness = () => {
        const lastInterestedValue = extraData.identity_type === 'Individual with a business idea'
            ? t('general.I want to explore opportunities with other startups')
            : `${t("general.I want to explore opportunities with other startups")}`;
        return (
            <FormContainer>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.interested) ? "text-red" : "form-question"}`}>{t("general.Why are you interested in becoming a member of Dua Partner Invest?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want insider access to startups'
                            name='interested1'
                            defaultChecked={extraData.interested.includes('I want insider access to startups')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested1 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested1.click()}>{t("general.I want insider access to startups")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to get funding for my business idea'
                            name='interested2'
                            defaultChecked={extraData.interested.includes('I want to get funding for my business idea')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested2 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested2.click()}>{t("general.I want to get funding for my business idea")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to get know-how for my business idea'
                            name='interested3'
                            defaultChecked={extraData.interested.includes('I want to get know-how for my business idea')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested3 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested3.click()}>{t("general.I want to get know-how for my business idea")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to have information on the latest trends of startups ecosystem'
                            name='interested4'
                            defaultChecked={extraData.interested.includes('I want to have information on the latest trends of startups ecosystem')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested4 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested4.click()}>{t("general.I want to have information on the latest trends of startups ecosystem")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to be part of the community of entrepreneurs'
                            name='interested5'
                            defaultChecked={extraData.interested.includes('I want to be part of the community of entrepreneurs')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested5 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested5.click()}>{t("general.I want to be part of the community of entrepreneurs")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value={lastInterestedValue}
                            name='entrepreneurInterested6'
                            defaultChecked={extraData.interested.includes(lastInterestedValue)}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested6 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested6.click()}>{lastInterestedValue}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.located ? "text-red" : "form-question"}`}>{t("general.Where are you located?")}</p>
                    <div className="d-flex">
                        <GeneralDropdownForm defaultValue={extraData.located} placeHolder={t("general.Choose country")} options={locateOptions} onChange={(e) => setExtraData({ ...extraData, located: e.target.value })} />
                        <GeneralDropdownForm defaultValue={extraData.city} placeHolder={t("general.Choose city")} options={LIST.getCityList(extraData.located)} onChange={(e) => setExtraData({ ...extraData, city: e.target.value })} />
                    </div>
                    {(isHasEmptyFields && !extraData.city) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <GeneralInputForm value={userData.address || 'N/A'} placeholder={t("general.Address")} margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, address: e.target.value })} />
                    <GeneralInputForm value={userData.phone_number || 'N/A'} placeholder={`${t("general.Phone number")} (${t("general.private")})`} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, phone_number: e.target.value })} />
                    {(isHasEmptyFields && !userData.phone_number) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <GeneralInputForm value={formData.email || 'N/A'} placeholder={`${t("general.Email address")} (${t("general.private")})`} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setFormData({ ...formData, email: e.target.value })} disabled />
                    {(isHasEmptyFields && !formData.email) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <GeneralInputForm value={userData.website || 'N/A'} placeholder={t("general.Internet Address")} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, website: e.target.value })} />
                    <GeneralInputForm value={userData.facebook || 'N/A'} placeholder="Facebook" margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, facebook: e.target.value })} />
                    <GeneralInputForm value={userData.instagram || 'N/A'} placeholder="Instagram" margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, instagram: e.target.value })} />
                    <GeneralInputForm value={userData.linkedin || 'N/A'} placeholder="Linkedin" margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, linkedin: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.language) ? "text-red" : "form-question"}`}>{t("general.Which are the languages you prefer to interact")}</p>
                    <GeneralDropdownForm placeHolder={t("general.Choose language")} options={LanguageOptions} onChange={(e) => selectTag(extraData.language, e.target.value, "language")} />
                    <SelectionContainer>
                        {_renderTags(extraData.language, 'language')}
                    </SelectionContainer>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <GeneralInputForm value={extraData.administrator_name || 'N/A'} placeholder={t("general.Name of Administrator/Legal Representative")} margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, administrator_name: e.target.value })} />
                    {(isHasEmptyFields && !extraData.administrator_name) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <GeneralInputForm value={extraData.administrator_mail || 'N/A'} placeholder={t("general.Email address of Administrator/Legal Representative")} margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, administrator_mail: e.target.value })} />
                    {(isHasEmptyFields && !extraData.administrator_mail) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <GeneralDropdownForm defaultValue={extraData.establishment_year} placeHolder={t("general.Choose year of establishment")} options={yearOptions} onChange={(e) => setExtraData({ ...extraData, establishment_year: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.industry_operate ? "text-red" : "form-question"}`}>{t("general.In which industry do you operate?")}</p>
                    <GeneralDropdownForm
                        defaultValue={extraData.industry_operate}
                        placeHolder={t("general.Choose industry")}
                        options={LIST.industryOptions}
                        onChange={(e) => setExtraData({ ...extraData, industry_operate: e.target.value })}
                        translatedropdown={true}
                    />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.operate_locate ? "text-red" : "form-question"}`}>{t("general.Where do you operate?")}</p>
                    <GeneralDropdownForm
                        placeHolder={t("general.Choose all that apply")}
                        options={locateOptions}
                        onChange={(e) => selectTag(extraData.operate_locate, e.target.value, "operate")}
                    />
                    <SelectionContainer>
                        {_renderTags(extraData.operate_locate, "operate")}
                    </SelectionContainer>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.idea_describe ? "text-red" : "form-question"}`}>{t("general.Describe the product/service idea")}</p>
                    <GeneralTextAreaFrom maxLength="500" defaultValue={extraData.idea_describe} placeholder={t("general.Description")} margin='0 0 0.5em 0' width="98.5%" height="6em" onChange={(e) => setExtraData({ ...extraData, idea_describe: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <GeneralInputForm value={extraData.number_of_employee} type='number' placeholder={t("general.Number of employees")} margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, number_of_employee: e.target.value })} />
                    {(isHasEmptyFields && !extraData.number_of_employee) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.turnover ? "text-red" : "form-question"}`}>{t("general.Total turnover from the last year?")}</p>
                    <GeneralInputForm value={extraData.turnover} type='number' placeholder="€" margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, turnover: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.profit ? "text-red" : "form-question"}`}>{t("general.Total net profit from the last year?")}</p>
                    <GeneralInputForm value={extraData.profit} type='number' placeholder="€" margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, profit: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.consultancy_needed) ? "text-red" : "form-question"}`}>{t("general.Which are your needs for consultancy?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Marketing & PR'
                            name='consultancyNeeded1'
                            defaultChecked={extraData.consultancy_needed.includes('Marketing & PR')}
                            onChange={(e) => selectMultipleChoice(extraData.consultancy_needed, e.target.value, 'consultancyNeeded')}
                            checkBoxRef={(ref) => checkBoxRef.consultancyNeeded1 = ref}
                        />
                        <p onClick={() => checkBoxRef.consultancyNeeded1.click()}>{t("general.Marketing & PR")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Legal'
                            name='consultancyNeeded2'
                            defaultChecked={extraData.consultancy_needed.includes('Legal')}
                            onChange={(e) => selectMultipleChoice(extraData.consultancy_needed, e.target.value, 'consultancyNeeded')}
                            checkBoxRef={(ref) => checkBoxRef.consultancyNeeded2 = ref}
                        />
                        <p onClick={() => checkBoxRef.consultancyNeeded2.click()}>{t("general.Legal")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Accounting & Audit'
                            name='consultancyNeeded3'
                            defaultChecked={extraData.consultancy_needed.includes('Accounting & Audit')}
                            onChange={(e) => selectMultipleChoice(extraData.consultancy_needed, e.target.value, 'consultancyNeeded')}
                            checkBoxRef={(ref) => checkBoxRef.consultancyNeeded3 = ref}
                        />
                        <p onClick={() => checkBoxRef.consultancyNeeded3.click()}>{t("general.Accounting & Audit")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Financial'
                            name='consultancyNeeded4'
                            defaultChecked={extraData.consultancy_needed.includes('Financial')}
                            onChange={(e) => selectMultipleChoice(extraData.consultancy_needed, e.target.value, 'consultancyNeeded')}
                            checkBoxRef={(ref) => checkBoxRef.consultancyNeeded4 = ref}
                        />
                        <p onClick={() => checkBoxRef.consultancyNeeded4.click()}>{t("general.Financial")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='IT'
                            name='consultancyNeeded5'
                            defaultChecked={extraData.consultancy_needed.includes('IT')}
                            onChange={(e) => selectMultipleChoice(extraData.consultancy_needed, e.target.value, 'consultancyNeeded')}
                            checkBoxRef={(ref) => checkBoxRef.consultancyNeeded5 = ref}
                        />
                        <p onClick={() => checkBoxRef.consultancyNeeded5.click()}>{t("general.IT")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Business plan development'
                            name='consultancyNeeded6'
                            defaultChecked={extraData.consultancy_needed.includes('Business plan development')}
                            onChange={(e) => selectMultipleChoice(extraData.consultancy_needed, e.target.value, 'consultancyNeeded')}
                            checkBoxRef={(ref) => checkBoxRef.consultancyNeeded6 = ref}
                        />
                        <p onClick={() => checkBoxRef.consultancyNeeded6.click()}>{t("general.Business plan development")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Innovation'
                            name='consultancyNeeded7'
                            defaultChecked={extraData.consultancy_needed.includes('Innovation')}
                            onChange={(e) => selectMultipleChoice(extraData.consultancy_needed, e.target.value, 'consultancyNeeded')}
                            checkBoxRef={(ref) => checkBoxRef.consultancyNeeded7 = ref}
                        />
                        <p onClick={() => checkBoxRef.consultancyNeeded7.click()}>{t("general.Innovation")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Other'
                            name='consultancyNeeded8'
                            defaultChecked={extraData.consultancy_needed.includes('Other')}
                            onChange={(e) => selectMultipleChoice(extraData.consultancy_needed, e.target.value, 'consultancyNeeded')}
                            checkBoxRef={(ref) => checkBoxRef.consultancyNeeded8 = ref}
                        />
                        <p onClick={() => checkBoxRef.consultancyNeeded8.click()}>{t("general.Other")}</p>
                        <GeneralInputForm
                            defaultValue={extraData.consultancy_needed.includes('Other') ? _.last(extraData.consultancy_needed) : ''}
                            placeholder={t("general.Describe")}
                            margin='0 0 0 0.5em'
                            width="98.5%"
                            onChange={(e) => setDescribeOtherChoie({ ...describeOtherChoie, consultancy_needed: e.target.value })}
                        />
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='None'
                            name='consultancyNeeded9'
                            defaultChecked={extraData.consultancy_needed.includes('None')}
                            onChange={(e) => setExtraData({ ...extraData, consultancy_needed: [e.target.value] })}
                            checkBoxRef={(ref) => checkBoxRef.consultancyNeeded9 = ref}
                        />
                        <p onClick={() => checkBoxRef.consultancyNeeded9.click()}>{t("general.None")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.about_me ? "text-red" : "form-question"}`}>
                        {t("general.About me")}
:
                    </p>
                    <GeneralTextAreaFrom maxLength="300" placeholder={t("general.About me")} margin='0 0 0.5em 0' width="98.5%" height="6em" defaultValue={extraData.about_me} onChange={(e) => setExtraData({ ...extraData, about_me: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.hear_about) ? "text-red" : "form-question"}`}>{t("general.Where did you hear about Dua Partner Invest platform?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Facebook'
                            name='hearAbout1'
                            defaultChecked={extraData.hear_about.includes('Facebook')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout1 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout1.click()}>Facebook</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Instagram'
                            name='hearAbout2'
                            defaultChecked={extraData.hear_about.includes('Instagram')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout2 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout2.click()}>Instagram</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Linkedin'
                            name='hearAbout3'
                            defaultChecked={extraData.hear_about.includes('Linkedin')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout3 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout3.click()}>Linkedin</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Website of Partners Albania'
                            name='hearAbout4'
                            defaultChecked={extraData.hear_about.includes('Website of Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout4 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout4.click()}>{t("general.Website of Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Activities organized by Partners Albania'
                            name='hearAbout5'
                            defaultChecked={extraData.hear_about.includes('Activities organized by Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout5 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout5.click()}>{t("general.Activities organized by Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Direct contact with the staff of Partners Albania'
                            name='hearAbout6'
                            defaultChecked={extraData.hear_about.includes('Direct contact with the staff of Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout6 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout6.click()}>{t("general.Direct contact with the staff of Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Annual report of Partners Albania'
                            name='hearAbout7'
                            defaultChecked={extraData.hear_about.includes('Annual report of Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout7 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout7.click()}>{t("general.Annual report of Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Other online platforms'
                            name='hearAbout8'
                            defaultChecked={extraData.hear_about.includes('Other online platforms')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout8 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout8.click()}>{t("general.Other online platforms")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Other'
                            name='hearAbout9'
                            defaultChecked={extraData.hear_about.includes('Other')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout9 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout9.click()}>{t("general.Other")}</p>
                        <GeneralInputForm
                            defaultValue={extraData.hear_about.includes('Other') ? _.last(extraData.hear_about) : ''}
                            placeholder={t("general.Describe")}
                            margin='0 0 0 0.5em'
                            width="98.5%"
                            onChange={(e) => setDescribeOtherChoie({ ...describeOtherChoie, hear_about: e.target.value })}
                        />
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.business_idea) ? "text-red" : "form-question"}`}>{t("general.Do you have a business idea you need funding for?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Yes, I have a new business idea.'
                            name='businessIdea1'
                            defaultChecked={extraData.business_idea === ('Yes, I have a new business idea.')}
                            onChange={(e) => setExtraData({ ...extraData, business_idea: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.businessIdea1 = ref}
                        />
                        <p onClick={() => checkBoxRef.businessIdea1.click()}>{t("general.Yes, I have a new business idea")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Yes, I have an idea to scale up my current business.'
                            name='businessIdea2'
                            defaultChecked={extraData.business_idea === ('Yes, I have an idea to scale up my current business.')}
                            onChange={(e) => setExtraData({ ...extraData, business_idea: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.businessIdea2 = ref}
                        />
                        <p onClick={() => checkBoxRef.businessIdea2.click()}>{t("general.Yes, I have an idea to scale up my current business")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='No.'
                            name='businessIdea3'
                            defaultChecked={extraData.business_idea === ('No.')}
                            onChange={(e) => setExtraData({ ...extraData, business_idea: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.businessIdea3 = ref}
                        />
                        <p onClick={() => checkBoxRef.businessIdea3.click()}>{t("general.No")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                {extraData.business_idea && extraData.business_idea !== 'No.' && (
                    <FormItem>
                        <p className={`${isHasEmptyFields && _.isEmpty(extraData.business_idea_name) ? "text-red" : "form-question"}`}>{t("general.Name of your business idea")}</p>
                        <GeneralInputForm value={extraData.business_idea_name || 'N/A'} placeholder={t("general.Name")} margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, business_idea_name: e.target.value })} />
                        <p className="line" />
                    </FormItem>
                )}
                {extraData.business_idea && extraData.business_idea !== 'No.' && (
                    <FormItem>
                        <p className={`${isHasEmptyFields && _.isEmpty(extraData.industry_belong) ? "text-red" : "form-question"}`}>{t("general.In which industry does your idea belong?")}</p>
                        <GeneralDropdownForm
                            defaultValue={extraData.industry_belong}
                            placeHolder={t("general.Choose industry")}
                            options={LIST.industryOptions}
                            onChange={(e) => setExtraData({ ...extraData, industry_belong: e.target.value })}
                            translatedropdown={true}
                        />
                        <p className="line" />
                    </FormItem>
                )}
                {extraData.business_idea && extraData.business_idea !== 'No.' && (
                    <FormItem>
                        <p className={`${isHasEmptyFields && !extraData.new_idea_describe ? "text-red" : "form-question"}`}>{t("general.Describe the product/service idea")}</p>
                        <GeneralTextAreaFrom maxLength="500" defaultValue={extraData.new_idea_describe} placeholder={t("general.Description")} margin='0 0 0.5em 0' width="98.5%" height="6em" onChange={(e) => setExtraData({ ...extraData, new_idea_describe: e.target.value })} />
                        <p className="line" />
                    </FormItem>
                )}
                <FormItem>
                    <p className={`${isHasEmptyFields && !formData.status ? "text-red" : "form-question"}`}>{t("general.User status")}</p>
                    <GeneralDropdownForm 
                        defaultValue={formData.status || ""} 
                        placeHolder={t("general.Choose user status")} 
                        options={LIST.getUserStatus} 
                        onChange={(e) => setFormData({ ...formData, status: e.target.value })} 
                        translatedropdown={true} 
                    />
                    <p className="line" />
                </FormItem>
                <SubmitButton type='button' onClick={() => submitForm()}>
                    {t("general.Update")}
                </SubmitButton>
            </FormContainer>
        );
    };

    return (_renderFormItemsForBusiness());
};

export default Business;
