import { combineReducers } from "redux";
import Authentication from "./Authentication";
import Message from "./Message";
import Register from "./Register";
import User from "./User";
import UserOverview from "./UserOverview";
import FundingApplication from "./FundingApplication";
import AdminOverView from "./AdminOverView";
import FundingApplicationsUser from "./FundingApplicationsUser";
import Judge from "./Judge";
import AdminFundingApplicationReport from "./AdminFundingApplicationReport";
import Search from "./Search";
import UserRegister from "./UserRegister";
import News from "./News";
import Community from "./Community";
import GuestAboutUsSetting from "./GuestAboutUsSetting";
import GuestSummarySetting from "./GuestSummarySetting";
import Library from "./Library";
import Event from "./Event";
import Process from "./Process";
import GuestDisclosureSetting from "./GuestDisclosureSetting";
import GuestPrivacyPolicySetting from "./GuestPrivacyPolicySetting";
import GuestTermSetting from "./GuestTermSetting";
import GuestTestimonialSetting from "./GuestTestimonialSetting";
import GuestApplySetting from "./GuestApplySetting";
import GuestDocumentTemplateSetting from "./GuestDocumentTemplateSetting";
import GuestAnnouncementSetting from "./GuestAnnouncementSetting";
import Sponsor from "./Sponsor";
import ContactUs from "./ContactUs";
import FAQ from "./FAQ";
import Introduce from "./Introduce";
import Criteria from "./Criteria";
import Export from "./Export";
import SearchHomepage from "./SearchHomepage";
import Subscribe from "./Subscribe";

export default combineReducers({
    Authentication,
    Message,
    Register,
    User,
    UserOverview,
    FundingApplication,
    AdminOverView,
    FundingApplicationsUser,
    Judge,
    AdminFundingApplicationReport,
    Search,
    SearchHomepage,
    UserRegister,
    News,
    Community,
    GuestAboutUsSetting,
    GuestSummarySetting,
    Library,
    Event,
    Process,
    GuestDisclosureSetting,
    GuestPrivacyPolicySetting,
    GuestTermSetting,
    GuestTestimonialSetting,
    GuestApplySetting,
    GuestDocumentTemplateSetting,
    Sponsor,
    ContactUs,
    FAQ,
    Introduce,
    Criteria,
    GuestAnnouncementSetting,
    Export,
    Subscribe
});
