import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    faq: {}
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.FAQ_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.FAQ_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.ADD_FAQ_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                faq: payload
            };
        case ActionTypes.GET_FAQ_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                faq: payload
            };
        default:
            return state;
    }
};
