// leave off @2x/@3x
const images = {
    // icon: require("../assets/Images/icon.png"),
    logo: require("../assets/images/logo.png"),
    logoFooter: require("../assets/images/logo-footer.png"),
    sliderItem: require("../assets/images/slider-item.png"),
    banner1: require("../assets/images/banner1.jpeg"),
    banner2: require("../assets/images/banner2.jpg"),
    banner: require("../assets/images_v2/banner.jpg"),
    invite1: require("../assets/images_v2/invite-1.jpg"),
    invite2: require("../assets/images_v2/invite-2.jpeg"),
    invite3: require("../assets/images_v2/invite-3.jpeg"),
    bannerHomepage: require("../assets/images_v2/bannerHomepage.jpg"),
    icon1: require("../assets/images/icon-1.png"),
    icon2: require("../assets/images/icon-2.png"),
    icon3: require("../assets/images/icon-3.png"),
    icon4: require("../assets/images/icon-4.png"),
    icon5: require("../assets/images/icon-5.png"),
    icon6: require("../assets/images/icon-7.png"),
    icon7: require("../assets/images/icon-8.png"),
    imageSlide: require("../assets/images_v2/slide.png"),
    investorHomepage: require("../assets/images/investor-homepage.jpg"),
    bannerLibrary: require("../assets/images_v2/BannerLibrary.jpg"),
    imageEvent: require("../assets/images_v2/imgEvent.png"),
    consultant: require("../assets/images_v2/consultant.jpg"),
    PDFIcon: require("../assets/images/pdf.png"),
    landingScreen: require("../assets/images_v2/landing-screen.jpg"),
    unsubscribe: require("../assets/images/unsubscribe.png"),
};

export default images;
