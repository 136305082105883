import styled from 'styled-components';
import {
    VerticalContainer, HorizontalContainer, BreadCrumb, GeneralButton
} from "../../../../../components/Common/CommonLayout";
import { ColorName } from '../../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .container-button{
        display: flex;
        & input{
            margin-right: 1em;
        }
    }
`;
const WrapperUserDetail = styled(HorizontalContainer)`
    align-items: center;
    margin-top: 2em;
    justify-content: end;
`;

const WrapperInput = styled(VerticalContainer)`
    &.absolute{
        position: absolute;
        right: 0;
    }
    flex: 1;
    display: flex;
    margin-bottom: 2em;
`;

const TitleInput = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${ColorName.chathamsBlueDark};
    display: flex;
    justify-content: space-between;
    & .language{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }
    & .switch{
        margin-right: 1em;
    }
`;

const InputContainer = styled.input`
    background: none;
    border: 1px solid ${ColorName.chathamsBlue};
    height: auto;
    width: ${(props) => props.width || "50%"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 5px 10px;
    outline: none;
`;
const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${ColorName.white};
    border-radius: 0;
    border: 2px solid ${ColorName.gallery};
    margin-bottom: 2em;
    position: relative;
    flex: 1;
    & .delete{
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        color: ${ColorName.redOrange};
    }
`;

const GeneralButtonLanguage = styled(GeneralButton)`
    border: 1px solid ${ColorName.chathamsBlueDark};    
    margin-right: 1em;
    background: ${ColorName.white};
    color: ${ColorName.chathamsBlueDark};
    &.active{
        background: ${ColorName.chathamsBlueDark};
        color: ${ColorName.white};
        &.disable{
            color: ${ColorName.white};
            background: #ccc;
            border: 1px solid #ccc;  
            cursor: not-allowed;
        }
    }
    &.disable-unclick{
        background: none;
        border: 1px solid #ccc;  
        color: #ccc;
        cursor: not-allowed;
    }
`;

export {
    BodyContainer,
    InputContainer,
    TitleInput,
    WrapperInput,
    WrapperUserDetail,
    ProgressBar,
    GeneralButtonLanguage
};
