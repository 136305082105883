import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";

const requestHasError = () => {
    return {
        type: ActionTypes.ANNOUNCEMENT_SETTING_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.ANNOUNCEMENT_SETTING_REQUEST
    };
};

const getAnnouncementSettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GET_ANNOUNCEMENT_SETTING_SUCCESS,
        payload
    };
};

const updateAnnouncementSettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.ANNOUNCEMENT_SETTING_SUCCESS,
        payload
    };
};

const setFilterByBusinessIdea = (payload) => {
    return {
        type: ActionTypes.SET_FILTER_SUCCESS,
        payload
    };
};

const getAnnouncementSettingData = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getMetaData(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getAnnouncementSettingDataSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateAnnouncementSettingData = (formPayload) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateMetaDataWithForm(formPayload)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(updateAnnouncementSettingDataSuccess(res.data.data));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const resetAnnouncementSettingData = () => {
    return {
        type: ActionTypes.ANNOUNCEMENT_SETTING_RESET
    };
};

export default {
    getAnnouncementSettingData,
    updateAnnouncementSettingData,
    resetAnnouncementSettingData,
    setFilterByBusinessIdea
};
