import styled from "styled-components";
import { ColorName } from "../../Variables";
import { HorizontalContainer } from "../CommonLayout";

const SearchInputContainer = styled(HorizontalContainer)`
    padding: 5px 15px;
    background: ${ColorName.white};
    border-radius: 25px;
    i {
        color: ${ColorName.chathamsBlue};
        cursor: pointer;
        padding-top: 2px;
    }
`;

const SearchInputComponent = styled.input`
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 15px;
    color: ${ColorName.chathamsBlue};
    &::placeholder {
        font-size: 13px;
        font-weight: 100;
        color: ${ColorName.chathamsBlue};
    }
`;

const SearchInputWrapper = styled.div`
    background: white;
    padding: 2px;
    width: 30%;
    border-radius: 25px;
`;

export { SearchInputComponent, SearchInputContainer, SearchInputWrapper };
