import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import _ from 'lodash';
import i18n from "../i18n";
import history from "../history";

const requestHasError = () => {
    return {
        type: ActionTypes.SUBSCRIBE_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.SUBSCRIBE_REQUEST
    };
};

const subscribeSuccess = (payload) => {
    return {
        type: ActionTypes.SUBSCRIBE_SUCCESS,
        payload
    };
};


const requestUnSubscribeHasError = () => {
  return {
      type: ActionTypes.UNSUBSCRIBE_FAILURE
  };
};

const requestUnSubscribeIsLoading = () => {
  return {
      type: ActionTypes.UNSUBSCRIBE_REQUEST
  };
};

const unsubscribeSuccess = (payload) => {
  return {
      type: ActionTypes.UNSUBSCRIBE_SUCCESS,
      payload
  };
};


const subscribe = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.subscribe(data)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.saveUserData(res.data.data);
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    dispatch(subscribeSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const subscribeWithRedirect = (data) => {
  return (dispatch) => {
      dispatch(requestIsLoading());
      API.subscribe(data)
          .then((res) => {
              if (res.data.status === true) {
                  Utils.saveUserData(res.data.data);
                  Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                  dispatch(subscribeSuccess(res.data.data));
                  history.push('/homepage/subscribe')
              } else {
                  dispatch(requestHasError());
                  Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
              }
          })
          .catch((e) => {
              dispatch(requestHasError());
              Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
          });
  };
};

const unsubscribe = (data) => {
  return (dispatch) => {
      dispatch(requestUnSubscribeIsLoading());
      API.unsubscribe(data)
          .then((res) => {
              if (res.data.status === true) {
                  const userData = Utils.getSavedUserData();
                  if(!_.isNull(userData)) Utils.saveUserData(res.data.data);
                  Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                  dispatch(unsubscribeSuccess(res.data.data));
              } else {
                  dispatch(requestUnSubscribeHasError());
                  Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
              }
          })
          .catch((e) => {
              dispatch(requestUnSubscribeHasError());
              Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
          });
  };
};

export default {
  subscribe,
  unsubscribe,
  subscribeWithRedirect
};
