import styled from 'styled-components';
import { ColorName } from "../../../../components/Variables";
import { HorizontalContainer, VerticalContainer } from "../../../../components/Common/CommonLayout";

const Wrapper = styled(VerticalContainer)`
    background: ${ColorName.white};
    padding: 2em;
`;

const BodyContainer = styled(HorizontalContainer)`
    background: ${ColorName.white};
`;

const BodyContent = styled.div`
    width: 80%;
`;

const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${ColorName.white};
    border-radius: 10px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    margin-left: ${(props) => props.marginLeft || "0px"};
    flex: 1;
    &.mb-2{
        margin-bottom: 20px;
    }
`;

const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || "0"};
    & .title-deadline {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
    }
    & .fa-trash-alt{
        cursor: pointer;
    }
    & .for-multile-files{
        display: flex;
        flex-direction: column;
    }
    & .fonts-status {
        font-weight: bold;
        font-size: 14px;
        line-height: 25px;
        color: ${ColorName.chathamsBlueDark};
    }
    & .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: ${ColorName.tickleMePink};
    }
    & .description{
        font-size: 12px;
        line-height: 1;
        color: ${ColorName.black};
        margin-top: 1.5em;
    }
    & .style-disabled{
        padding: 10px;
        border-radius: 10px;
    }
    & .style-disabled-div{
        padding: 10px;
        border-radius: 10px;
        border: 1px solid gray;
    }
    &.dropdownStatus{
        padding: 2em 0;
        & select{
            width: 200px;
            border-radius: 5px;
            border: 2px solid gray;
            height: 30px;
            outline: none;
            margin: 1em 0;
            & option{
                text-transform: uppercase;
            }
        }
    }
    & .btnFoot{
        display: flex;
    }
    a {
        text-decoration: none;
        width: max-content;
        color: ${ColorName.chathamsBlueDark};
        i {
            margin-right: 0.3em;
        }
    }
`;
const TitleCategory = styled.div`
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    color: #858585;
    margin: 1.5em 0;
    text-transform: uppercase;
    color: ${ColorName.chathamsBlueDark};
    &:firstChild{
        margin-top: 0;
    }
`;

const DetailTitle = styled.div`
    min-width: 150px;
    color: ${ColorName.chathamsBlueDark};
`;
const Column = styled.div`
    display: flex;
    padding: 10px;
`;

export {
    Wrapper,
    BodyContainer,
    BodyContent,
    ProgressBar,
    WrapperTitle,
    TitleCategory,
    DetailTitle,
    Column
};
