import { create } from "apisauce";
import Utils from "../libs/Utils";
import history from "../history";

let token = Utils.getSavedToken();
const BASE_URL = process.env.REACT_APP_API_URL;
const FE_URL = process.env.REACT_APP_FE_URL;
// define the api
const api = create({
    baseURL: `${BASE_URL}/api/`,
    headers: {
        Accept: "application/json",
        Client: FE_URL,
        Lang: Utils.getCurrentLanguage() || "en"
    }
});
api.setHeader('Authorization', `Bearer ${token}`);

const _handleResponse = (res, resolve, reject) => {
    if (res.status === 401) {
        Utils.clearAllSavedData();
        // eslint-disable-next-line max-len
        Utils.popupAlert({ title: "Error", text: "Your login session has expired or your token was invalid, please login again, system will redirect to login screen ..." }).then(() => {
            history.push("authentication");
        });
        // eslint-disable-next-line no-useless-return
        reject(res.data);
    } else if (res.status === 501 || res.status === 422 || res.status === 400 || res.status === 500) {
        reject(res);
    } else if (res.problem) {
        Utils.popupAlert({ title: `Server error`, text: `Error detail: ${res.problem}` });
        const error = "Server error";
        reject(error);
    } else {
        resolve(res);
    }
};

const authRequest = async (url, data = {}) => {
    token = Utils.getSavedToken();
    data = { ...data, token };
    const res = await api.post(url, data);
    return new Promise((resolve, reject) => {
        if (res.status === 401) {
            reject(res.data);
        } else if (res.status === 501 || res.status === 422) {
            reject(res);
        } else if (res.problem) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject("Server error");
        } else {
            resolve(res);
        }
    });
};

const post = async (url, data = {}) => {
    token = Utils.getSavedToken();
    data = { ...data };
    const res = await api.post(url, data);
    return new Promise((resolve, reject) => {
        _handleResponse(res, resolve, reject);
    });
};

const postFormData = async (url, data) => {
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    const res = await api.post(url, data, { headers });
    return new Promise((resolve, reject) => {
        _handleResponse(res, resolve, reject);
    });
};

const getFormData = async (url, data) => {
    const headers = {
        'Content-Type': 'multipart/form-data'
    };

    const res = await api.get(url, data, { headers });
    return new Promise((resolve, reject) => {
        _handleResponse(res, resolve, reject);
    });
};

const get = async (url, data = {}) => {
    const res = await api.get(url, data);
    return new Promise((resolve, reject) => {
        _handleResponse(res, resolve, reject);
    });
};

const put = async (url, data = {}) => {
    token = Utils.getSavedToken();
    data = { ...data };
    const res = await api.put(url, data);
    return new Promise((resolve, reject) => {
        _handleResponse(res, resolve, reject);
    });
};

const patch = async (url, data = {}) => {
    token = Utils.getSavedToken();
    data = { ...data };
    const res = await api.patch(url, data);
    return new Promise((resolve, reject) => {
        _handleResponse(res, resolve, reject);
    });
};

const del = async (url, data = {}) => {
    token = Utils.getSavedToken();
    data = { ...data };
    const res = api.delete(url, data);
    return new Promise((resolve, reject) => {
        _handleResponse(res, resolve, reject);
    });
};

const upload = (url, data) => {
    return api.put(url, data.payload, {
        onUploadProgress: data.onUploadProgress
    });
};

const setToken = (newToken) => {
    token = newToken;
    api.setHeader('Authorization', `Bearer ${token}`);
};

const getToken = () => {
    return token;
};

export default {
    postFormData,
    getFormData,
    post,
    put,
    del,
    patch,
    upload,
    get,
    authRequest
};

export { setToken, getToken };
