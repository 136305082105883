import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";

import {
    RowContainer,
    Item,
    AvatarContainer,
    Avatar,
    StatusContainer,
    AvatarWrapper
} from "./RoundedRow.styles";
import DropDownAction from "../DropDownAction";
import ApplicationStatus from "../ApplicationStatus";
import TableUtils from "../../../libs/TableUtils";
import CONSTANTS from "../../../constants/Constants";

const RoundedRow = (props) => {
    const { t, i18n } = useTranslation();
    const [rowData, setRowData] = useState([]);
    const [column, setColumn] = useState([]);
    const [className, setClassName] = useState([]);
    const [listOptions, setListOptions] = useState({});
    const listOptionForApprove = {
        1: "view"
    };
    useEffect(() => {
        setRowData(props.rowData);
        setColumn(props.column);
        setListOptions(props.listOptions);
        setClassName(props.className);
    }, [props]);

    const getActionName = (action) => {
        props.callBackFunction(action, rowData);
    };

    const _renderRowItems = () => {
        const render = _.map(column, (dataKey, index) => {
            const originalKey = dataKey;
            dataKey = dataKey.toLowerCase();
            if (dataKey.includes('status')) {
                const background = TableUtils.checkColorStatusCell(rowData.status);
                if (dataKey === "applications status") {
                    return (
                        <Item key={index} className="for-align-status">
                            {rowData.noBackground
                                && (
                                    t(`rounderTable.${rowData.status}`)
                                )
                            }
                            {!rowData.noBackground
                                && (
                                    <ApplicationStatus
                                        value={rowData.status}
                                        background={background}
                                        width={i18n.language === CONSTANTS.LANGUAGE_CODE.AL ? '12em' : ''}
                                    />
                                )
                            }
                        </Item>
                    );
                }
                return (
                    <Item key={index}>
                        <StatusContainer>
                            {!rowData.noBackground
                                && (
                                    <ApplicationStatus
                                        value={rowData.status}
                                        background={background}
                                        width={i18n.language === CONSTANTS.LANGUAGE_CODE.AL ? '12em' : ''}
                                    />
                                )
                            }
                        </StatusContainer>
                        {rowData.noBackground
                            && (
                                rowData.status
                            )
                        }
                    </Item>
                );
            } else if (dataKey === "image" || dataKey === "img") {
                return (
                    <Item key={index}>
                        <img src={rowData[dataKey] || "https://g.foolcdn.com/image/?url=https%3A%2F%2Fg.foolcdn.com%2Feditorial%2Fimages%2F446553%2Fmeeting_gettyimages-603992138.jpg&w=700&op=resize"} alt={rowData.title} />
                    </Item>
                );
            } else if (dataKey === 'user name') {
                return (
                    <Item key={index}>
                        <AvatarContainer>
                            <AvatarWrapper>
                                <Avatar>
                                    <img src={rowData.name.url || ""} alt='avatar' />
                                </Avatar>
                                <p>
                                    {rowData.name.name}
                                </p>
                            </AvatarWrapper>
                        </AvatarContainer>
                    </Item>
                );
            } else if (dataKey === 'activity' || dataKey === 'email' || dataKey === 'name') {
                if (rowData[dataKey].length > 76) {
                    return (
                        <Item key={index} className="text-left" title={rowData[dataKey]}>
                            {`${rowData[dataKey].substr(0, 76)}...`}
                        </Item>
                    );
                } else {
                    return (
                        <Item key={index} className="text-left">
                            {rowData[dataKey]}
                        </Item>
                    );
                }
            } else if (dataKey === 'description') {
                if (rowData[dataKey].length > 50) {
                    return (
                        <Item key={index} className="text-left">
                            <div className="text" dangerouslySetInnerHTML={{ __html: `${rowData[dataKey].substr(0, 50)}...` }} />
                        </Item>
                    );
                } else {
                    return (
                        <Item key={index} className="text-left">
                            <div className="text" dangerouslySetInnerHTML={{ __html: rowData[dataKey] }} />
                        </Item>
                    );
                }
            } else if (dataKey === 'created' || dataKey === 'date submitted') {
                return (
                    <Item key={index}>
                        {rowData.created}
                    </Item>
                );
            } else if (dataKey === 'date') {
                return (
                    <Item key={index} className="type">
                        {rowData[dataKey]}
                    </Item>
                );
            } else if (dataKey === 'title' || dataKey === "applicant name") {
                return (
                    <Item key={index} className="text-left">
                        {rowData.title}
                    </Item>
                );
            } else if (dataKey === 'number judge') {
                return (
                    <Item key={index}>
                        {`${rowData.numberJudge}`}
                    </Item>
                );
            } else if (dataKey === 'average score') {
                return (
                    <Item key={index}>
                        {rowData.averageScore}
                    </Item>
                );
            } else if (dataKey === "type") {
                return (
                    <Item key={index} className="type">
                        {t(`general.${rowData[dataKey]}`)}
                    </Item>
                );
            } else if (dataKey === "file_name") {
                return (
                    <Item key={index}>
                        {rowData[originalKey]}
                    </Item>
                );
            } else if (dataKey === "file_url") {
                return (
                    <Item key={index}>
                        <a href={rowData[originalKey]} rel="noopener noreferrer" target="_blank">{t("general.Open document")}</a>
                    </Item>
                );
            } else if (dataKey !== 'status' && dataKey !== 'action' && dataKey !== 'activity') {
                return (
                    <Item key={index}>
                        {rowData[dataKey]}
                    </Item>
                );
            } else {
                return (
                    <Item key={index} className="for-action">
                        <DropDownAction listOptions={_.has(rowData, "option") && rowData.option === "report" ? listOptionForApprove : listOptions} getActionName={getActionName} />
                    </Item>
                );
            }
        });
        return render;
    };

    return (
        <RowContainer className={className}>
            {(!_.isEmpty(rowData)) && _renderRowItems()}
        </RowContainer>
    );
};

RoundedRow.propTypes = {
    rowData: PropTypes.object,
    column: PropTypes.array,
    listOptions: PropTypes.object,
    callBackFunction: PropTypes.func,
    className: PropTypes.string
};

export default RoundedRow;
