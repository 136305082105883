import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import DefaultLayout from '../../../components/Layout/GuestTemplate/DefaultLayout';
import {
    MainContent,
    StepsContainer,
    StepsWrapperSteps,
    StepLine,
    Marker,
    MarkerTitle,
    MarkerText
} from "./Investors.styles";
import SummaryOverview from '../../../components/Common/SummaryOverview';
import Banner from '../../../components/Common/Banner';
import { Images } from '../../../themes';
import NavigationBar from '../../../components/Common/NavigationBar';
import ProcessActions from '../../../actions/Process';

const Process = () => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const isLoading = useSelector((state) => state.Process.isLoading);
    const dataProcess = useSelector((state) => state.Process.process);
    useEffect(() => {
        const dataGet = {
            type: "investor_process"
        };
        dispatch(ProcessActions.getProcess(dataGet));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listNav = [
        {
            title: t("general.What We Do"),
            isActive: false,
            path: "/homepage/investors/what-we-do"
        },
        {
            title: t("general.Community"),
            isActive: false,
            path: "/homepage/investors/community"
        },
        {
            title: t("general.Process"),
            isActive: true,
            path: "/homepage/investors/process"
        },
        {
            title: t("general.Apply"),
            isActive: false,
            path: "/homepage/investors/apply"
        }
    ];

    const _getDataLocalization = (dataContent, key) => {
        if (dataContent[i18n.language][key]) return dataContent[i18n.language][key];
        if (i18n.language === 'en') return dataContent.al[key];
        if (i18n.language === 'al') return dataContent.en[key];
    };

    const _renderStep = (item, index) => {
        return (
            <StepsWrapperSteps key={index}>
                <StepLine>
                    <Marker>
                        <p>{_.parseInt(index) + 1}</p>
                    </Marker>
                    <MarkerTitle className='fix-margin'>{!_.isUndefined(item[i18n.language]) ? _getDataLocalization(item, "title") : ""}</MarkerTitle>
                    <MarkerText>{!_.isUndefined(item[i18n.language]) ? _getDataLocalization(item, "description") : ""}</MarkerText>
                </StepLine>
            </StepsWrapperSteps>
        );
    };

    const _renderAllStep = () => {
        if (dataProcess.data_json) {
            const render = _.map(dataProcess.data_json, (item, index) => {
                return _renderStep(item, index);
            });
            return render;
        }
    };

    const _mainRender = () => {
        return (
            <div>
                <Banner
                    backgroundImg={Images.banner1}
                    title={t("homepageUser.Are you an Investor?")}
                    description={t("homepageUser.Fund the most promising startups in the region")}
                />
                <NavigationBar listNav={listNav} />
                <MainContent className='d-flex'>
                    <StepsContainer>
                        {dataProcess && _renderAllStep()}
                    </StepsContainer>
                </MainContent>
                <SummaryOverview />
            </div>
        );
    };
    return (
        <DefaultLayout
            context={_mainRender()}
            currentPage="investor"
            currentTop={true}
            isLoading={isLoading}
        />
    );
};

export default Process;
