import styled from 'styled-components';
import { HorizontalContainer } from "../CommonLayout";
import { ColorName } from "../../Variables";

const HeaderContainer = styled(HorizontalContainer)`
    p {
        margin: 0 auto 0 0;
        font-size: 23px;
        font-weight: 600;
        text-transform: uppercase;
    }
`;

const Option = styled.div`
    margin-left: auto;
    cursor: pointer;
`;

const TableContainer = styled.table`
    margin-top: 1em;
    border-collapse: separate;
    border-spacing: 0 1em;
    thead {
        tr {
            text-align: center;
            font-weight: 500;
            td {
                background: ${ColorName.chathamsBlueDark};
                font-weight: 600;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            td:first-child {
                text-align: left;
            }
        }
    }
    tbody {
        tr {
            text-align: center;
            td:first-child {
                text-align: left;
            }
            td:last-child {
                button {
                    margin-left: auto;
                }
            }
        }
    }
`;

const ButtonWrapper = styled(HorizontalContainer)`
    justify-content: center;
    align-items: center;
    & .mt-2 {
        margin-top: 2em
    }
`;

export {
    HeaderContainer,
    Option,
    TableContainer,
    ButtonWrapper
};
