import React, { useState, useEffect } from "react";
import { StaticDialog, ModalContent, ModalFooter, ModalButton } from 'react-st-modal';
import {
    ModalInnerContent
} from "./SimpleModal.styles"

const SimpleModal = (props) => {
    const [isOpen, setOpen] = useState(false);

    const [content, setContent] = useState();

    useEffect(() => {
        setContent(props.content);
        setOpen(props.showImageEditor);
    }, [props]);

    const renderContent = () => {
        return (
            <div>
                <ModalContent>
                    <ModalInnerContent>
                        {content}
                    </ModalInnerContent>
                </ModalContent>
                <ModalFooter>
                    <ModalButton
                        onClick={() => {
                            setOpen(false);
                            props.submitCallback();
                        }}
                    >
                    Save
                    </ModalButton>
                </ModalFooter>
            </div>
        )
    }
    const renderModal = () => {
        return (
            <div>
                <StaticDialog
                    isOpen={isOpen}
                    title="Image Editor"
                    onAfterClose={(result) => {
                        setOpen(false);
                        props.onClose()
                    }}
                >
                    {renderContent()}
                </StaticDialog>
            </div>
        )
    }

    return (
        <div>
            {renderModal()}
        </div>
    );
};
export default SimpleModal;
