import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import Constants from "../constants/Constants";
import i18n from "../i18n";
import history from "../history";

const requestHasError = () => {
  return {
    type: ActionTypes.NEWS_FAILURE,
  };
};

const requestIsLoading = () => {
  return {
    type: ActionTypes.NEWS_REQUEST,
  };
};

const addNewsSuccess = (payload) => {
  return {
    type: ActionTypes.ADD_NEWS_SUCCESS,
    payload,
  };
};

const updateNewsSuccess = (payload) => {
  return {
    type: ActionTypes.UPDATE_NEWS_SUCCESS,
    payload,
  };
};

const getNewsByIdSuccess = (payload) => {
  return {
    type: ActionTypes.GET_NEWS_BY_ID_SUCCESS,
    payload,
  };
};

const getAllNewsSuccess = (payload) => {
  return {
    type: ActionTypes.GET_ALL_NEWS_SUCCESS,
    payload,
  };
};
const setNewsId = (payload) => {
  return {
    type: ActionTypes.SET_NEWS_ID_SUCCESS,
    payload,
  };
};

const getNewsSlideSuccess = (payload) => {
  return {
    type: ActionTypes.GET_NEWS_FOR_SLIDE_SUCCESS,
    payload,
  };
};

// const resetNews = () => {
//   return {
//     type: ActionTypes.RESET_NEWS_SUCCESS,
//   };
// };

const addNews = (data) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.addNews(data)
      .then((res) => {
        if (res.data.status === true) {
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
          dispatch(addNewsSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const updateNews = (data, id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.updateNews(data, id)
      .then((res) => {
        if (res.data.status === true) {
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
          dispatch(updateNewsSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const getAllNews = (data) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.getAllNews(data)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getAllNewsSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const updateStatusNews = (data, id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.updateNews(data, id)
      .then((res) => {
        if (res.data.status === true) {
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
          const dataGet = {
            page: 1,
            item_per_page: Constants.ITEM_PER_PAGE,
            language: i18n.language,
          };
          dispatch(getAllNews(dataGet));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const getNewsById = (data, id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.getNewsById(data, id)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getNewsByIdSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const getNewsByIdForNewsPage = (data, id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.getNewsById(data, id)
      .then((res) => {
        if (res.data.data === null) {
          dispatch(requestHasError());
          return Utils.popupAlert({
            title: "Oops!",
            text: i18n.t("alert.This content has been removed"),
            type: "error",
          }).then(() => {
            history.push("/homepage/news");
          });
        }

        if (res.data.status === true) {
          if (res.data.data.status === 1)
            dispatch(getNewsByIdSuccess(res.data.data));
          else {
            dispatch(requestHasError());
            return Utils.popupAlert({
              title: "Oops!",
              text: "Access denied!",
              type: "error",
            }).then(() => {
              history.push("/homepage/news");
            });
          }
        } else {
          dispatch(requestHasError());
          return Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const deleteNews = (id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.deleteNews(id)
      .then((res) => {
        if (res.data.status === true) {
          const dataGet = {
            page: 1,
            item_per_page: Constants.ITEM_PER_PAGE,
            language: i18n.language,
          };
          dispatch(getAllNews(dataGet));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const getNewsSlide = (dataWithLanguage) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.getNewsSlide(dataWithLanguage)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getNewsSlideSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const sendMail = (id) => {
  const param = new FormData();
  param.append("id", id);
  return (dispatch) => {
    API.sendMailNews(param)
      .then((res) => {
        if (res.data.status === true) {
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const updateStatusSendMail = (data, id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.updateNews(data, id)
      .then((res) => {
        if (res.data.status === true) {
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
          const dataGet = {
            page: 1,
            item_per_page: Constants.ITEM_PER_PAGE,
            language: i18n.language,
          };
          dispatch(getAllNews(dataGet));
          dispatch(sendMail(id));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

export default {
  addNews,
  getNewsById,
  getAllNews,
  setNewsId,
  updateNews,
  deleteNews,
  updateStatusNews,
  getNewsSlide,
  updateStatusSendMail,
  getNewsByIdForNewsPage,
};
