import React from "react";
import moment from "moment";
import Avatar from "../../../Common/Avatar";
import Utils from "../../../../libs/Utils";

import { UsernameChatbox } from "./Message.styles";

const Message = ({ data, userReceiveData }) => {
  const { user_id_send, content, url_file_attach, file_attach, created_at } =
    data;
  const formatNameFile = () => { 
    if (file_attach) return file_attach.split("/")[1];
  };
  const userSend = Utils.getSavedUserData();

  const checkVideo = () => {
    if (url_file_attach) {
      const extension = url_file_attach.split(".").pop();
      if (
        extension.toLowerCase() === "mp4" ||
        extension.toLowerCase() === "ogg" ||
        extension.toLowerCase() === "webm"
      )
        return true;
    }
    return false;
  };

  return (
    <div className="messageWrapper">
      {userSend.id === user_id_send && (
        <div className="userReceive">
          <div className="container-img">
            <UsernameChatbox direction="left">
              {`${userSend.userData.first_name} ${userSend.userData.last_name}`}
            </UsernameChatbox>
            <Avatar userData={userSend} size="small" />
          </div>
          {content && <div className="content-message">{content}</div>}
          {checkVideo() && (
            <video width="320" height="240" controls>
              <source src={url_file_attach} type="video/mp4" />
            </video>
          )}
          {url_file_attach && !checkVideo() && (
            <a
              className="file-attach"
              href={url_file_attach}
              download={url_file_attach}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatNameFile()}
              <i className="fas fa-paperclip right" />
            </a>
          )}
          <span className="sent-timestamp">
            {moment(created_at).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
      )}
      {userSend.id !== user_id_send && (
        <div className="userSend">
          <div className="container-img">
            <Avatar userData={userReceiveData} size="small" />
            <UsernameChatbox direction="right">
              {`${userReceiveData.userData.first_name} ${userReceiveData.userData.last_name}`}
            </UsernameChatbox>
          </div>
          {content && <div className="content-message">{content}</div>}

          {checkVideo() && (
            <video width="320" height="240" controls>
              <source src={url_file_attach} type="video/mp4" />
            </video>
          )}
          {url_file_attach && !checkVideo() && (
            <a
              className="file-attach"
              href={url_file_attach}
              download={url_file_attach}
            >
              <i className="fas fa-paperclip left" />
              {formatNameFile()}
            </a>
          )}
          <span className="sent-timestamp">
            {moment(created_at).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
      )}
    </div>
  );
};

export default Message;
