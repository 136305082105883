import React from "react";
import _ from "lodash";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PropTypes from "prop-types";
import {
    RightArrowButton,
    LeftArrowButton,
    ItemCardLayout,
    SliderContainer
} from "./SlickCarouselSimple.styles";

const SlickCarouselSimple = (props) => {
    const { carouselItems } = props;
    const defaultSliderToShow = 4;
    const sliderToShow = (carouselItems && carouselItems.length < defaultSliderToShow) ? carouselItems.length : defaultSliderToShow;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: sliderToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: sliderToShow - 1,
                    slidesToScroll: 1,
                    initialSlide: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: sliderToShow - 1,
                    slidesToScroll: 1,
                    initialSlide: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: sliderToShow - 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false
                }
            }
        ],
        nextArrow: <RightArrowButton />,
        prevArrow: <LeftArrowButton />
    };

    const _generateItem = (item, index) => {
        return (
            <ItemCardLayout key={index}>
                <div className="item-avatar">
                    <img src={item.avatar} alt={item.name} />
                </div>
                <div className="item-username">{item.name}</div>
                <div className="item-title">{item.title}</div>
                <div className="item-description text" dangerouslySetInnerHTML={{ __html: item.description }} />
            </ItemCardLayout>
        );
    };

    const renderCarouselItems = () => {
        if (carouselItems) {
            return _.map(carouselItems, (item, index) => {
                return _generateItem(item, index);
            });
        }
    };

    const checkClass = () => {
        let className = '';
        if (carouselItems) {
            if (carouselItems.length === 1) return className = "center-1-item";
            if (carouselItems.length === 2) return className = "center-2-item";
        }
        return className;
    };

    return (
        <SliderContainer className={carouselItems && checkClass()}>
            <Slider {...settings}>{renderCarouselItems()}</Slider>
        </SliderContainer>
    );
};

SlickCarouselSimple.propTypes = {
    carouselItems: PropTypes.array
};

export default SlickCarouselSimple;
