import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";

const requestSuccess = (payload = false) => {
    return {
        type: ActionTypes.EXPORT_SUCCESS,
        payload
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.EXPORT_IS_LOADING
    };
};

const requestHasError = () => {
    return {
        type: ActionTypes.EXPORT_FAILURE
    };
};

const exportExcell = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.exportExcell(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestSuccess(res.data.data));
                    window.open(res.data.data);
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};


const exportUserExcell = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.exportUserExcell(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestSuccess(res.data.data));
                    window.open(res.data.data);
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const exportAllUserExcell = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.exportAllUserExcell(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestSuccess(res.data.data));
                    window.open(res.data.data);
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

export default {
    exportExcell,
    exportUserExcell,
    exportAllUserExcell
};
