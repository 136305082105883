import React from "react";
import PropTypes from "prop-types";

const GradientSVG = (props) => {
    const {
        startColor, endColor, idCSS, rotation, className
    } = props;

    const gradientTransform = `rotate(${rotation})`;
    return (
        <svg style={{ height: 0, visibility: "hidden", width: 0 }}>
            <defs>
                <linearGradient id={idCSS} gradientTransform={gradientTransform} className={className}>
                    <stop offset="0%" stopColor={startColor} />
                    <stop offset="100%" stopColor={endColor} />
                </linearGradient>
            </defs>
        </svg>
    );
};

GradientSVG.propTypes = {
    startColor: PropTypes.string,
    endColor: PropTypes.string,
    idCSS: PropTypes.string,
    className: PropTypes.string,
    rotation: PropTypes.number
};

export default GradientSVG;
