import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  ProfilePicture,
  UserInfomations,
  Infomation,
  WrapperModal,
  ModalContent,
  TextareaContainer
} from "./UserOverview.styles";
import { GeneralButton } from "../../../../components/Common/CommonLayout";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import MessageActions from "../../../../actions/Message";
import Utils from "../../../../libs/Utils";
import { ColorName } from "../../../../components/Variables";

const PopupUserExplore = (props) => {
  const { t } = useTranslation();
  const UserOverViewStorage = useSelector((state) => state.UserOverview);
  const [selectedExplore, setSelectedExplore] = useState({});
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [contentMessage, setContentMessage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = Utils.convertSnakeKeyToCamel(Utils.getSavedUserData());
    setUserData(_.assign(data, data.userData, data.userData.extraDataJson));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedExplore(UserOverViewStorage.selectedExplore);
    setIsLoading(UserOverViewStorage.isLoading);
  }, [UserOverViewStorage]);

  useEffect(() => {
    setSelectedExplore(props.dataItem);
  }, [props]);

  const hideModal = (event) => {
    if (event.target.classList.contains('wrapper')) {
      props.hideModal();
    }
  };

  const sendMessage = async () => {
    if (selectedExplore.id === userData.userId) {
      return Utils.popupAlert({ title: t("alert.Warning"), text: t("general.Can't chat with myself"), type: "warning" });
    }
    const param = new FormData();
    param.append('user_id_send', userData.userId);
    param.append('user_id_receive', selectedExplore.id);
    param.append('content', contentMessage);
    await dispatch(MessageActions.addMessage(param));
    Utils.popupAlert({
      type: "success",
      title: t("validation.Message sent"),
      buttons: [t("general.Cancel"), true],
      dangerMode: true
    }).then((willContinue) => {
      if (willContinue) {
        props.hideModal();
      }
    });
  };

  const renderTableContent = () => {
    if (_.isEmpty(selectedExplore)) return;
    return (
      <div>
        <ProfilePicture position="unset">
          <img alt='profile' src={selectedExplore.userData.url_avatar_aws || "https://awodev.com/images/default-forum-user.png"} />
          <p>
            {selectedExplore.userData.full_name}
          </p>
        </ProfilePicture>
        <UserInfomations margin="0.5em 0em 2em 0em">
          <Infomation />
          <Infomation position="unset" marginTop="0.5em">
            <TextareaContainer className="textarea-message" placeholder={t("general.Enter your message")} onChange={(e) => setContentMessage(e.target.value)} />
          </Infomation>
        </UserInfomations>
      </div>
    );
  };

  return (
    <WrapperModal onClick={(event) => hideModal(event)} className='wrapper'>
      {isLoading && <Loading />}
      <ModalContent>
        {renderTableContent()}
        <div className="style-button">
          <GeneralButton type="button" className="for-more message" background={ColorName.chathamsBlue} value={t("general.Send Message")} onClick={() => sendMessage()} />
        </div>
      </ModalContent>
    </WrapperModal>
  );
};

PopupUserExplore.propTypes = {
  hideModal: PropTypes.func,
  currentExploreID: PropTypes.string,
  dataItem: PropTypes.object
};

export default PopupUserExplore;
