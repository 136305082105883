import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isLogged: false,
    hasError: false,
    isLoading: false,
    name: "",
    username: "",
    password: ""
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.IS_LOGGED:
            return {
                ...state,
                isLogged: true,
                hasError: false,
                isLoading: false
            };
        case ActionTypes.LOGIN_HAS_ERROR:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.LOGIN_IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.LOGIN:
            return {
                ...state,
                isLogged: false,
                name: payload.name,
                username: payload.username,
                password: payload.password
            };
        case ActionTypes.LOGOUT:
            return {
                ...state,
                isLogged: false,
                name: "",
                username: "",
                password: ""
            };
        default:
            return state;
    }
};
