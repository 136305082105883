import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import {
    Container,
    GridColHomepage,
    FlexGridSixthTemplate
} from "./Subscribe.styles";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import SummryOverview from "../../../components/Common/SummaryOverview";
import Utils from "../../../libs/Utils";
import { Images } from "../../../themes";
import { ColorName } from "../../../components/Variables";
import Button from "../../../components/Common/ButtonFrontEnd";
import SubscribeActions from '../../../actions/Subscribe';
import queryString from 'query-string';

const Unsubscribe = (props) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.GuestAboutUsSetting.isLoading);
    const isLogged = useSelector((state) => state.Authentication.isLogged);
    const params = queryString.parse(props.location.search);
    const email = params.email;
    useEffect(() => {
        if (!_.isUndefined(email)) {
            const data = {
                email
            };
            dispatch(SubscribeActions.unsubscribe(data));
        } else {
            Utils.popupAlert({ title: "Oops...", text: "Please check URL again", type: "error" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged]);

    const subscribe = () => {
        if (!_.isUndefined(email)) {
            const data = {
                email
            };
            dispatch(SubscribeActions.subscribeWithRedirect(data));
        } else {
            Utils.popupAlert({ title: "Oops...", text: "Please check URL again", type: "error" });
        }
    };

    const _renderContentUnsubscrible = () => {
        return (
            <div className="content-page">
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <div className='container-sub'>
                            <div className="title">
                                {email ? "You have successfully unsubscribed from receiving automatic emails!" : "Please check your URL again, thanks!"}
                            </div>
                            {email && (
                                <>
                                    <img src={Images.unsubscribe} alt='unsubscribe' />
                                    <div className='text'>
                                    We’re sad to see you go, but we respect your choice! If you unsubscribed by accident, please subscribe again.
                                    </div>
                                    <div className='text'>
                                        <Button
                                            onClick={() => subscribe()}
                                            background={ColorName.chathamsBlueDark}
                                            color={ColorName.white}
                                            name="Subscribe"
                                        />
                                    </div>
                                </>
                            )}
                            
                        </div>
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                
            </div>
        );
    };

    const _renderSummaryOverview = () => {
        return <SummryOverview />;
    };

    const _renderMain = () => {
        return (
            <Container>
                {_renderContentUnsubscrible()}
                {_renderSummaryOverview()}
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
            currentPage="about"
            isLoading={isLoading}
        />
    );
};

Unsubscribe.propTypes = {
};

export default Unsubscribe;
