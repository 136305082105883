import styled from 'styled-components';
import { VerticalContainer, HorizontalContainer, BreadCrumb } from "../../../../../components/Common/CommonLayout";
import { ColorName } from '../../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 2em;
`;
const WrapperUserDetail = styled(HorizontalContainer)`
    align-items: center;
    margin-top: 2em;
    justify-content: end;
`;

const WrapperInput = styled(VerticalContainer)`
    flex: 1;
    display: flex;
    margin-bottom: 2em;
    flex-direction: row;
    justify-content: space-between;
    
    & .style-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
    }
    & .title-select{
        margin-left: 1em;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
    }
    & .jodit-wysiwyg{
        min-height: 300px !important;
    }
    & #uploadAvatar{
        display: none;
    }
    & .display-image-file{
        & img{
            max-width: 400px;
        }
    }
`;

const TitleInput = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${ColorName.chathamsBlueDark};
    min-width: 137px;
`;

const InputContainer = styled.input`
    background: none;
    border: 1px solid ${ColorName.chathamsBlue};
    height: auto;
    width: ${(props) => props.width || "15em"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 10px;
    outline: none;
    &::placeholder {
        text-transform: uppercase;
    }
    &.thead-title{
        border: none;
        text-align: center;
        color: ${ColorName.chathamsBlue};
        padding: 0;
        font-weight: 600;
        font-size: 17px;
    }
`;
const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${ColorName.white};
    border-radius: 10px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    margin-left: ${(props) => props.marginLeft || "0px"};
    flex: 1;
`;

export {
    BodyContainer,
    InputContainer,
    TitleInput,
    WrapperInput,
    WrapperUserDetail,
    ProgressBar
};
