import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import {
    WrapperModal,
    ModalContent,
    PageTitle,
    WrapperTitleContent
} from "./Report.styles";
import Button from "../../../../components/Common/Button";
import { ColorName } from "../../../../components/Variables";
import InputCalendar from "../../../../components/Common/InputCalendar";
import TextArea from "../../../../components/Common/TextArea";
import AdminReportActions from "../../../../actions/AdminFundingApplicationReport";
import Utils from "../../../../libs/Utils";

const PopupCreateReport = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const AdminReportStorage = useSelector((state) => state.AdminFundingApplicationReport);
    const [fieldData, setFieldData] = useState({
        dueDate: "",
        requestDescription: '',
        applyApplicationId: ''
    });
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setFieldData({ ...fieldData, applyApplicationId: AdminReportStorage.applyApplicationDetail.id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AdminReportStorage]);

    const hideModal = (event) => {
        if (event.target.classList.contains('wrapper')) {
            props.hideModal();
        }
    };

    const createNewReport = () => {
        if (fieldData.dueDate !== "" && fieldData.requestDescription !== '' && fieldData.applyApplicationId) {
            dispatch(AdminReportActions.reportApplyApplication(Utils.convertCamelKeyToSnake(fieldData)));
            props.hideModal();
        }
        setIsError(true);
    };

    const formatDate = (date) => {
        if (date) {
            const dateFormat = moment(date).format('YYYY-MM-DD');
            return dateFormat;
        }
    };

    return (
        <WrapperModal onClick={(event) => hideModal(event)} className='wrapper'>
            <ModalContent>
                <p>{t("general.Create new Report")}</p>
                <WrapperTitleContent marginTop="4em">
                    <PageTitle className="custom-title">{t("general.Due Date")}</PageTitle>
                    <InputCalendar
                        selectedDate={(date) => setFieldData({ ...fieldData, dueDate: date })}
                        value={formatDate(fieldData.dueDate) || ""}
                    />
                </WrapperTitleContent>
                <WrapperTitleContent>
                    <PageTitle className="custom-title">{t("general.Description")}</PageTitle>
                    <TextArea
                        height='15em'
                        width="24em"
                        onChange={(event) => setFieldData({ ...fieldData, requestDescription: event.target.value })}
                        defaultValue={fieldData.requestDescription}
                    />
                </WrapperTitleContent>
                {isError
                    && (
                        <WrapperTitleContent>
                            <div>{t("alert.Fields cannot be blank")}</div>
                        </WrapperTitleContent>
                    )
                }
                <WrapperTitleContent className="mt-2 justify-center">
                    <Button
                        background={ColorName.gray}
                        title={t("general.Cancel")}
                        height="35px"
                        width="11em"
                        margin="0 2em 0 0"
                        onClick={() => props.hideModal()}
                    />
                    <Button
                        background={ColorName.fern}
                        color="white"
                        title={t("general.Create")}
                        height="35px"
                        width="11em"
                        onClick={() => createNewReport()}
                    />
                </WrapperTitleContent>
            </ModalContent>
        </WrapperModal>
    );
};

PopupCreateReport.propTypes = {
    hideModal: PropTypes.func
};

export default PopupCreateReport;
