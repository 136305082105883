import styled from 'styled-components';
import {
    VerticalContainer, HorizontalContainer, Flex, BreadCrumb, GeneralButton
} from "../../../../components/Common/CommonLayout";
import { ColorName } from '../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 2em;
    & .ml-1 {
        margin-left: 1em
    }
    & .mb-2 {
        margin-bottom: 2em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
    & .wrapper-button {
        display: flex;
        margin-top: 4em;
        justify-content: center;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    
    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
    
    & .progress-bar-user-profile {
        width: 35%;
        @media (max-width: 1366px) {
            width: 60%
        }
        @media (max-width: 768px) {
            width: 90%
        }
    }
`;

const ProgressBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 20px 0px;
    background: ${ColorName.white};
    border-radius: 10px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    max-height: 90px;
    height: 90px;
    margin-left: ${(props) => props.marginLeft || "0px"};
    flex: 1;
    i {
        position: absolute;
        top: 5px;
        right: 20px;
        cursor: pointer;
    }
    & .text-progress {
        position: absolute;
        bottom: 5px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        left: 40px;
        line-height: 15px;
    }
`;

const ContentProgressBar = styled(HorizontalContainer)`
    flex: 1;
`;

const NumberProgressBar = styled(Flex)`
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    background: linear-gradient(163.97deg, #FC58B3 11.61%, #FEAE68 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
`;

const ProgressBarTitle = styled(Flex)`
    flex: 1;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: ${ColorName.chathamsBlue};
`;

const WrapperExplore = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid ${ColorName.gallery};
    border-radius: 25px;
    padding: 50px 20px 50px 50px;
    background: ${ColorName.white};
    margin-bottom: 2em;
    position: relative;
    & .header-explore {
        font-weight: 600;
        font-size: 23px;
        line-height: 22px;
        
    }
    & .d-none {
        display: none;
    }
`;

const WrapperNav = styled.div`
    display: flex;
    flex-direction: row;
    padding: 30px 30px 30px 0px;
`;

const WrapperContentExplore = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    button {
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        i {
            font-size: 25px;
            color: ${ColorName.chathamsBlueDark};
        }
    }
    & .bor-rad-25 {
        border-radius: 25px;
    }
    & .user-expolre-slider{
        width: 100%;
    }
    & .align-right{
        justify-content: right !important;
    }
    & .display-none{
        display: none;
    }
`;

const UserCardContainer = styled(VerticalContainer)`
    border: ${(props) => props.border || `2px solid ${ColorName.gallery}`};
    background: ${ColorName.white};
    width: ${(props) => props.width || "225px"};
    max-width: ${(props) => props.width || "225px"};
    height: ${(props) => props.height || "340px"};
    button {
        margin-right: auto;
    }
    & .container-button{
        display: flex;
        margin-top: auto;
        margin-left: 5px;
        margin-right: 5px;  
        margin-bottom: 10px;
        justify-content: center;
    }
    position: relative;
    border-radius: 25px;
    & .bor-rad-10 {
        border-radius: 10px;
    }
    & .position-contact-me {
        margin-right: 0;
    }
`;

const ProfilePicture = styled(VerticalContainer)`
    padding: ${(props) => props.padding || "0px 10px"};
    p {
        text-transform: uppercase;
        // text-align: center;
        margin: 0;
        color: ${ColorName.chathamsBlue};
    }
    img {
        object-fit: cover;
        height: 165px;
        width: 165px;
        border-radius: 10%;
    }
    place-items: center;
`;

const UserInfomations = styled(VerticalContainer)`
    margin: ${(props) => props.margin || "0"};
    line-height: 2;
`;

const Infomation = styled.div`
    color: ${ColorName.chathamsBlue};
    text-align: center;
    margin-top: ${(props) => props.marginTop || "0"};
    
    & .styled-user {
        color: ${ColorName.chathamsBlue};
        text-transform: capitalize;
    }

    & .textarea-message {
        border-radius: 10px;
        background: white;
        resize: none;
    }
    span {
        color: ${ColorName.chathamsBlue};
    }
    & [data-tooltip-text]:hover {
        overflow:visible;
    }
    & [data-tooltip-text]:hover:after {
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.8);
        margin-top: -30px;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
        -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
        box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
    
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    
        color: #FFFFFF;
        font-size: 12px;
        content: attr(data-tooltip-text);
    
        margin-bottom: 10px;
        left: 0;
        padding: 7px 12px;
        position: absolute;
        width: 90%;
        min-width: 70px;
        max-width: 215px;
        word-wrap: break-word;
        right: 0;
        z-index: 9999;
    }
`;

const WrapperInput = styled(VerticalContainer)`
    flex: ${(props) => props.flex || "1"};
    align-items: ${(props) => props.alignItems || ""};
    margin-top: ${(props) => props.marginTop || ""};
    &.center-content {
        justify-content: center;
        align-items: center;
    }
    & .style-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
    }
    & .d-none {
        display: none;
    }
    & .title-select{
        margin-left: 1em;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
    }
    & .style-img {
        width: 15em;
        border-radius: 50%;
        height: 15em;
    }
    & .img-wrapper {
        width: 15em;
        border-radius: 50%;
        height: 15em;
        background: white;
        border: 2px solid #F0F0F0;
        position: relative;
        span {
            position: absolute;
            bottom: 6em;
            right: 2.5em;
        }
    }
`;

const InputContainer = styled.input`
    background: none;
    border: 2px solid ${ColorName.gray};
    height: auto;
    width: ${(props) => props.width || "20em"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 10px;
    outline: none;
    border-radius: 15px;
`;

const TextareaContainer = styled.textarea`
    background: none;
    border: 1px solid ${ColorName.chathamsBlue};
    height: auto;
    width: ${(props) => props.width || "20em"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 10px;
    outline: none;
    height: 8em;
    &:placeholder{
        color: ${ColorName.chathamsBlue};
    }
`;

const TitleInput = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
`;

const CheckBoxContainer = styled(InputContainer)`
    padding: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    &:after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -4px;
        left: -4px;
        position: relative;
        background-color: none;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid ${ColorName.white};
    }
    &:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -4px;
        left: -4px;
        position: relative;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid ${ColorName.white};
    }
`;

const WrapperUserDetail = styled(HorizontalContainer)`
    align-items: center;
    margin-top: ${(props) => props.marginTop || "3em"};
    justify-content: center;
`;

const WrapperModal = styled.div`
    display: flex;
    position: fixed;
    line-height: 1.5;
    z-index: 9;
    background-color: rgba(16, 16, 16, 0.5);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
`;

const ModalContent = styled(HorizontalContainer)`
    background: ${ColorName.botticelli};
    border-radius: 0px;
    border: none;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding: 1em 2em;
    width: 300px;
    & .style-button {
        justify-content: center;
        display: flex;
    }
    // & .message {
    //     border-radius: 16px;
    // }
`;

const WrapperButtonReport = styled.div`
    display: flex;
    position: absolute;
    top: 1.5em;
    z-index: 999;
    border: 2px solid ${ColorName.gallery};
    border-radius: 5px;
    height: 3.5em;
    background-color: ${ColorName.white};
    right: -2em;
    width: auto;
`;
const GeneralButtonUser = styled(GeneralButton)`
    font-weight: 600;
    font-size: 0.9em;
    margin-right: 1em;
    padding: 0.5em 0.2em;
    border: 1px solid ${ColorName.chathamsBlue};
`;

export {
    BodyContainer,
    ProgressBar,
    ContentProgressBar,
    NumberProgressBar,
    ProgressBarTitle,
    WrapperExplore,
    WrapperNav,
    WrapperContentExplore,
    UserCardContainer,
    ProfilePicture,
    UserInfomations,
    Infomation,
    WrapperInput,
    TitleInput,
    InputContainer,
    CheckBoxContainer,
    WrapperUserDetail,
    WrapperModal,
    ModalContent,
    WrapperButtonReport,
    TextareaContainer,
    GeneralButtonUser
};
