import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import JoditEditor from 'jodit-react';
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput,
    SubInputTitle
} from "./TermSetting.styles";
import {
    PageTitle,
    GeneralButton,
    FlexGridSixth,
    GridCol
} from "../../../../../components/Common/CommonLayout";
import {
    SwitchButton,
    SwitchLanguageButton,
    EnableMultipleLanguageButtonContainer
} from "../../../../../components/Common/EnableMultipleLanguageButton";
import Loading from "../../../../../components/Layout/CommonLayoutPart/Loading";
import GuestTermSettingActions from "../../../../../actions/GuestTermSetting";
import { ColorName } from "../../../../../components/Variables";
import Utils from "../../../../../libs/Utils";
import Validation from "../../../../../libs/Validation";
import Constants from "../../../../../constants/Constants";

const TermSetting = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const editor = useRef(null);
    const editorAl = useRef(null);
    const isLoading = useSelector((state) => state.GuestTermSetting.isLoading);
    const guestTermSetting = useSelector((state) => state.GuestTermSetting);

    const [termContent, setTermContent] = useState(guestTermSetting.termContent);
    const [fileUrl, setFileUrl] = useState("");
    const [fileData, setFileData] = useState("");
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());

    const fileInput = useRef(null);

    useEffect(() => {
        dispatch(GuestTermSettingActions.getTermSettingData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(guestTermSetting)) {
            if (guestTermSetting.is_support_mul_lang) {
                if (i18n.language === Constants.LANGUAGE_CODE.AL) {
                    setLanguage(Constants.LANGUAGE_CODE.AL);
                } else {
                    setLanguage(Constants.LANGUAGE_CODE.EN);
                }
            } else if (guestTermSetting.termContent[i18n.language]) {
                setLanguage(i18n.language);
            } else if (i18n.language === Constants.LANGUAGE_CODE.EN) {
                setLanguage(Constants.LANGUAGE_CODE.AL);
            } else {
                setLanguage(Constants.LANGUAGE_CODE.EN);
            }
            setTermContent(guestTermSetting.termContent);
            setIsmultipleLanguage(guestTermSetting.isMultipleLanguage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guestTermSetting]);

    const createOrupdateAboutUs = () => {
        const defaultValidation = {
            [Constants.LANGUAGE_CODE.EN]: [
                {
                    name: `${t("general.Content")} ${t("general.English")}`,
                    value: _.get(editor, 'current.value', ""),
                    rule: ["required", { min: 50, max: 20000 }]
                }
            ],
            [Constants.LANGUAGE_CODE.AL]: [
                {
                    name: `${t("general.Content")} ${t("general.Albania")}`,
                    value: _.get(editorAl, 'current.value', ""),
                    rule: ["required", { min: 50, max: 20000 }]
                }
            ]
        };
        const validationRules = (isMultipleLanguage)
            ? [...defaultValidation[Constants.LANGUAGE_CODE.EN], ...defaultValidation[Constants.LANGUAGE_CODE.AL]]
            : [...defaultValidation[Constants.LANGUAGE_CODE.EN]];

        if (!Validation.formValidation(validationRules)) return;

        const processedContent = {
            en: _.get(editor, 'current.value', ""),
            al: _.get(editorAl, 'current.value', "")
        };
        const formData = new FormData();
        formData.append("data", JSON.stringify(processedContent));
        formData.append("is_support_mul_lang", isMultipleLanguage);

        if (fileData !== "") formData.append('img', fileData);
        dispatch(GuestTermSettingActions.updateTermSettingData(formData));
    };

    const handleFileChange = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        const file = e.target.files[0];
        setFileData(file);
        setFileUrl(url);
    };

    const deleteFileAttachment = () => {
        setFileData("");
        setFileUrl("");
        fileInput.current.value = "";
    };

    const _renderSwitchLangugage = () => {
        return (
            <EnableMultipleLanguageButtonContainer>
                <SwitchButton className="switch">
                    <input type="checkbox" value={isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} checked={isMultipleLanguage} />
                    <span className="slider round" />
                </SwitchButton>
                {isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                            />
                        </>
                    )}
                {!isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                disabled
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                disabled
                            />
                        </>
                    )}
            </EnableMultipleLanguageButtonContainer>
        );
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={false}
            page="term-setting"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("general.Terms")}
                    </PageTitle>
                    <ProgressBar className="mb-2">
                        <FlexGridSixth>
                            <GridCol colSize='7' gridTemplate='7' className="flex-end">
                                {_renderSwitchLangugage()}
                            </GridCol>
                        </FlexGridSixth>
                        <WrapperInput>
                            <TitleInput>
                                {t("general.Content")}
                                <SubInputTitle>Required: Min 50, Max 20000 Characters</SubInputTitle>
                            </TitleInput>
                            <div className={(language === Constants.LANGUAGE_CODE.EN) ? '' : 'visible-none'}>
                                <JoditEditor ref={editor} value={termContent.en} />
                            </div>
                            <div className={(language === Constants.LANGUAGE_CODE.AL) ? '' : 'visible-none'}>
                                <JoditEditor ref={editorAl} value={termContent.al} />
                            </div>
                        </WrapperInput>
                        <WrapperInput className="container-upload">
                            <input
                                ref={fileInput}
                                id='uploadAvatar'
                                type="file"
                                accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                                onChange={(e) => handleFileChange(e)}
                            />
                            {t("news.Featured Image")}
                            :
                            {' '}
                            <i className="fas fa-upload" onClick={() => fileInput.current.click()} />
                            {fileUrl
                                && (
                                    <div className="display-image-file">
                                        <img src={fileUrl} alt='file' />
                                        <div className="remove" onClick={() => deleteFileAttachment()}>X</div>
                                    </div>
                                )
                            }
                        </WrapperInput>
                        <div className="container-button">
                            <GeneralButton
                                type="button"
                                className="for-more"
                                background={ColorName.chathamsBlue}
                                value={t("general.Save")}
                                onClick={() => createOrupdateAboutUs()}
                            />
                        </div>
                    </ProgressBar>
                </BodyContainer>
            )}
        />
    );
};

TermSetting.propTypes = {

};

export default TermSetting;
