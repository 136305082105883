import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import MessageAction from "../../../../actions/Message";
import Button from "./form/Button";

const AddMessage = ({ userIdReceive, userIdSend }) => {
  const fileInput = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [content, setContent] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [fileData, setFileData] = useState("");

  const handleChange = ({ target }) => {
    const { value } = target;
    setContent(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (content === "" && !fileData) return;
    const param = new FormData();
    param.append("user_id_send", userIdSend);
    param.append("user_id_receive", userIdReceive);
    param.append("content", content);
    if (fileData) param.append("file_attach", fileData);
    dispatch(MessageAction.addMessage(param));
    setContent("");
    setFileData("");
    setFileUrl("");
    fileInput.current.value = "";
  };

  const onKeyEnterPress = (e) => {
    if (e.which === 13) {
      e.preventDefault();
      if (content === "" && !fileData) return;
      const param = new FormData();
      param.append("user_id_send", userIdSend);
      param.append("user_id_receive", userIdReceive);
      param.append("content", content);
      if (fileData) param.append("file_attach", fileData);
      dispatch(MessageAction.addMessage(param));
      setContent("");
      setFileData("");
      setFileUrl("");
      fileInput.current.value = "";
    }
  };

  const handleFileChange = (e) => {
    const url = e.target.files[0].name;
    setFileData(e.target.files[0]);
    setFileUrl(url);
  };

  const deleteFileAttachment = () => {
    setFileData("");
    setFileUrl("");
    fileInput.current.value = "";
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="textwrapper">
        <i
          className="fas fa-paperclip cursor attachment-btn"
          onClick={() => fileInput.current.click()}
        />
        <textarea
          onKeyPress={onKeyEnterPress}
          placeholder={`${t("general.Enter your message")} ...`}
          name="content"
          onChange={handleChange}
          value={content}
        />
        <input
          ref={fileInput}
          id="uploadAvatar"
          type="file"
          accept="*"
          onChange={(e) => handleFileChange(e)}
        />
        {fileUrl && (
          <div className="display-name-file">
            {`File Attachment: ${fileUrl} `}
            <div onClick={() => deleteFileAttachment()}>X</div>
          </div>
        )}
        <Button type="submit" className="buttonSend">
          <i className="fas fa-paper-plane" />
        </Button>
      </div>
    </form>
  );
};

export default AddMessage;
