import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import SlickCarouselSimple from '../SlickCarouselSimple';
import CONSTANTS from "../../../constants/Constants";
import GuestTestimonialSettingActions from "../../../actions/GuestTestimonialSetting";
import Utils from "../../../libs/Utils";

const TestimonialSlider = () => {
    const dispatch = useDispatch();
    const testimonialList = useSelector((state) => state.GuestTestimonialSetting.testimonials.data);
    const [carouselItems, setCarouselItems] = useState();

    useEffect(() => {
        const language = Utils.getCurrentLanguage();
        const data = {
            page: 1,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language
        };
        dispatch(GuestTestimonialSettingActions.fetchTestimonials(data));
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(testimonialList)) _processTestimonialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testimonialList]);

    const _processTestimonialData = () => {
        const processedItems = _.map(testimonialList, (testimonial) => {
            return {
                avatar: testimonial.url_img_aws,
                name: testimonial.name ? testimonial.name : (testimonial.name_json.en ? testimonial.name_json.en : testimonial.name_json.al),
                title: testimonial.title ? testimonial.title : (testimonial.title_json.en ? testimonial.title_json.en : testimonial.title_json.al),
                description: testimonial.description ? testimonial.description.replace(/<[^>]*>?/gm, '') : (testimonial.description_json.en ? testimonial.description_json.en.replace(/<[^>]*>?/gm, '') : testimonial.description_json.al.replace(/<[^>]*>?/gm, ''))
            };
        });
        setCarouselItems(processedItems);
    };

    return (
        <SlickCarouselSimple carouselItems={carouselItems} />
    );
};
export default TestimonialSlider;
