import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    FormItem,
    FormContainer,
    SubmitButton,
    SelectionTag,
    SelectionContainer
} from "../../../../GuestContainer/UserRegister/UserRegister.styles";
import GeneralCheckBoxForm from "../../../../../components/Common/GeneralCheckBoxForm";
import { GeneralInputForm, GeneralTextAreaFrom } from "../../../../../components/Common/CommonLayout";
import GeneralDropdownForm from "../../../../../components/Common/GeneralDropdownForm";
import LIST from "../ResolveList";
import Utils from "../../../../../libs/Utils";
import AdminOverViewAction from "../../../../../actions/AdminOverView";
import Validation from "../../../../../libs/Validation";

const Individual = (props) => {
    const { t } = useTranslation();
    const checkBoxRef = [];
    const dispatch = useDispatch();
    const locateOptions = LIST.resolveCountryList();
    const LanguageOptions = LIST.resolveLanguageList();
    const [userID, setUserID] = useState();
    const [formData, setFormData] = useState({
        email: ''
    });
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        address: '',
        phone_number: '',
        website: '',
        facebook: '',
        instagram: '',
        linkedin: ''
    });
    const [extraData, setExtraData] = useState({
        interested: [],
        located: '',
        city: '',
        language: [],
        employment_status: '',
        hear_about: [],
        is_has_invested: '',
        invest_platform: [],
        invest_money: '',
        minimum_money: '',
        operate_locate: [],
        // investing_individual: '',
        primarily_invest: '',
        about_me: ''
    });
    const [describeOtherChoice, setDescribeOtherChoice] = useState({});
    const [isHasEmptyFields, setIsHasEmptyFields] = useState(false);

    useEffect(() => {
        const { originalUserData } = props;
        if (!_.isEmpty(originalUserData)) {
            setUserID(originalUserData.id);
            setFormData({
                email: originalUserData.email,
                status: originalUserData.status.toString()
            });
            setUserData({
                first_name: originalUserData.userData.firstName,
                last_name: originalUserData.userData.lastName,
                address: originalUserData.userData.address,
                phone_number: originalUserData.userData.phoneNumber,
                website: originalUserData.userData.website,
                facebook: originalUserData.userData.facebook,
                instagram: originalUserData.userData.instagram,
                linkedin: originalUserData.userData.linkedin,
            });
            if (originalUserData.userData.extraDataJson) {
                const newExtraData = {
                    interested: originalUserData.userData.extraDataJson.interested.split(','),
                    located: originalUserData.userData.extraDataJson.located,
                    city: originalUserData.userData.extraDataJson.city,
                    language: originalUserData.userData.extraDataJson.language.split(','),
                    employment_status: originalUserData.userData.extraDataJson.employment_status,
                    hear_about: originalUserData.userData.extraDataJson.hear_about.split(','),
                    is_has_invested: originalUserData.userData.extraDataJson.is_has_invested,
                    invest_platform: resolveInvestFlatformValue(originalUserData.userData.extraDataJson.invest_platform),
                    invest_money: originalUserData.userData.extraDataJson.invest_money,
                    minimum_money: originalUserData.userData.extraDataJson.minimum_money,
                    operate_locate: originalUserData.userData.extraDataJson.operate_locate.split(','),
                    // investing_individual: originalUserData.userData.extraDataJson.investing_individual,
                    primarily_invest: originalUserData.userData.extraDataJson.primarily_invest,
                    about_me: originalUserData.userData.extraDataJson.about_me || ''
                };
                if (originalUserData.userData.extraDataJson.invested) newExtraData.invested = originalUserData.userData.extraDataJson.invested.split(',');
                if (originalUserData.userData.extraDataJson.employee_company) newExtraData.employee_company = originalUserData.userData.extraDataJson.employee_company;
                if (originalUserData.userData.extraDataJson.industry) newExtraData.industry = originalUserData.userData.extraDataJson.industry;
                setExtraData(newExtraData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.originalUserData]);

    useEffect(() => {
        if (props.avatar) setUserData({ ...userData, avatar: props.avatar });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const resolveInvestFlatformValue = (value) => {
        const replaceString = value.replaceAll(', ', '~');
        const splitValue = replaceString.split(',');
        const result = [];
        _.map(splitValue, (v) => {
            result.push(v.replaceAll('~', ', '));
        });
        return result;
    };

    const selectMultipleChoice = (parentData, value, dataType) => {
        let newData = [...parentData];
        if (!newData.includes("None")) {
            if (!newData.includes(value)) {
                newData.push(value);
            } else {
                newData = _.remove(newData, (el) => el !== value);
            }
        } else {
            newData = _.remove(newData, (el) => el !== "None");
            newData.push(value);
        }
        if (dataType === 'interested') setExtraData({ ...extraData, interested: newData });
        if (dataType === 'hearAbout') setExtraData({ ...extraData, hear_about: newData });
        if (dataType === 'investPlatform') setExtraData({ ...extraData, invest_platform: newData });
    };
    const validateForAbout = () => {
        const validationRules = [
            {
                name: t("general.About"),
                value: extraData.about_me,
                rule: [{ max: 300 }]
            }
        ];
        if (!Validation.formValidation(validationRules)) return true;
        return false;
    };
    const submitForm = () => {
        Utils.popupAlert({
            type: "warning",
            title: t("rounderTable.Do you want to update"),
            buttons: [t("general.Cancel"), true],
            dangerMode: true
        }).then((willContinue) => {
            if (willContinue) {
                let flag = false;
                const flagAbout = validateForAbout();
                if (flagAbout) {
                    return;
                }
                const submitFormData = new FormData();
                const notRequireFields = ['address', 'website', 'facebook', 'instagram', 'linkedin', 'about_me'];
                _.map(_.keys(formData), (item) => {
                    submitFormData.append(`${item}`, formData[item]);
                    if (!formData[item]) flag = true;
                });
                _.map(_.keys(userData), (item) => {
                    submitFormData.append(`user_data[${item}]`, userData[item]);
                    if (!userData[item] && !notRequireFields.includes(item)) flag = true;
                });
                _.map(_.keys(extraData), (item) => {
                    if (_.isArray(extraData[item])) {
                        const otherData = [...extraData[item]];
                        otherData.push(describeOtherChoice[item]);
                        !extraData[item].includes("Other")
                            ? submitFormData.append(`user_data[extra_data][${item}]`, extraData[item])
                            : submitFormData.append(`user_data[extra_data][${item}]`, otherData);
                    } else {
                        submitFormData.append(`user_data[extra_data][${item}]`, extraData[item]);
                    }
                    if ((!extraData[item] || (_.isArray(extraData[item]) && _.isEmpty(extraData[item]))) && !notRequireFields.includes(item)) flag = true;
                });
                if (flag) {
                    Utils.popupAlert({ title: t("alert.Warning"), text: t('alert.Found empty field(s)'), type: 'warning' });
                    setIsHasEmptyFields(true);
                    return;
                }
                dispatch(AdminOverViewAction.updateRoleForUser(submitFormData, userID));
                setIsHasEmptyFields(false);
            }
        });
        
    };

    const selectTag = (parentData, value, type) => {
        if (type === "invested") {
            value = t(`general.${value}`);
        }
        let newData = [];
        _.isArray(parentData) ? newData = [...parentData] : newData = [];
        if (!newData.includes(value)) {
            newData.push(value);
        } else {
            newData = _.remove(newData, (el) => el !== value);
        }
        if (type === 'operate') setExtraData({ ...extraData, operate_locate: newData });
        if (type === 'language') setExtraData({ ...extraData, language: newData });
        if (type === 'invested') setExtraData({ ...extraData, invested: newData });
    };

    const _renderTags = (data, type) => {
        const render = _.map(data, (tag, index) => {
            return (
                <SelectionTag key={index}>
                    <span>{tag}</span>
                    <i className="fas fa-times" onClick={() => selectTag(data, tag, type)} />
                </SelectionTag>
            );
        });
        return render;
    };

    const _renderFormItemsForIndividual = () => {
        return (
            <FormContainer>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.interested) ? "text-red" : "form-question"}`}>{t("general.Why are you interested in becoming a member of Dua Partner Invest?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want insider access to startups'
                            name='interested1'
                            defaultChecked={extraData.interested.includes('I want insider access to startups')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested1 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested1.click()}>{t("general.I want insider access to startups")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to invest to promising startups'
                            name='interested2'
                            defaultChecked={extraData.interested.includes('I want to invest to promising startups')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested2 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested2.click()}>{t("general.I want to invest to promising startups")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to support promising startups with expertise'
                            name='interested3'
                            defaultChecked={extraData.interested.includes('I want to support promising startups with expertise')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested3 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested3.click()}>{t("general.I want to support promising startups with expertise")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to have information on the latest trends of startups ecosystem'
                            name='interested4'
                            defaultChecked={extraData.interested.includes('I want to have information on the latest trends of startups ecosystem')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested4 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested4.click()}>{t("general.I want to have information on the latest trends of startups ecosystem")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to be part of the community of entrepreneurs'
                            name='interested5'
                            defaultChecked={extraData.interested.includes('I want to be part of the community of entrepreneurs')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested5 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested5.click()}>{t("general.I want to be part of the community of entrepreneurs")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to explore opportunities to join forces with other investors'
                            name='interested6'
                            defaultChecked={extraData.interested.includes('I want to explore opportunities to join forces with other investors')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested6 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested6.click()}>{t("general.I want to explore opportunities with other investors")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.primarily_invest ? "text-red" : "form-question"}`}>{t("general.How will you primarily invest as")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Business'
                            name='primarilyInvest1'
                            defaultChecked={extraData.primarily_invest === 'Business'}
                            onChange={(e) => setExtraData({ ...extraData, primarily_invest: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.primarilyInvest1 = ref}
                        />
                        <p onClick={() => checkBoxRef.primarilyInvest1.click()}>{t("general.Business")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Individual'
                            name='primarilyInvest2'
                            defaultChecked={extraData.primarily_invest === 'Individual'}
                            onChange={(e) => setExtraData({ ...extraData, primarily_invest: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.primarilyInvest2 = ref}
                        />
                        <p onClick={() => checkBoxRef.primarilyInvest2.click()}>{t("general.Individual")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Venture capital firm'
                            name='primarilyInvest3'
                            defaultChecked={extraData.primarily_invest === 'Venture capital firm'}
                            onChange={(e) => setExtraData({ ...extraData, primarily_invest: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.primarilyInvest3 = ref}
                        />
                        <p onClick={() => checkBoxRef.primarilyInvest3.click()}>{t("general.Venture capital firm")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Impact Investment Scheme'
                            name='primarilyInvest4'
                            defaultChecked={extraData.primarily_invest === 'Impact Investment Scheme'}
                            onChange={(e) => setExtraData({ ...extraData, primarily_invest: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.primarilyInvest4 = ref}
                        />
                        <p onClick={() => checkBoxRef.primarilyInvest4.click()}>{t("general.Impact Investment Scheme")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                {/* <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.investing_individual ? "text-red" : "form-question"}`}>{t("general.What is the name of the investing individual?")}</p>
                    <GeneralInputForm placeholder={t("general.Name of the investing individual")} defaultValue={extraData.investing_individual} margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, investing_individual: e.target.value })} />
                    {(isHasEmptyFields && !extraData.investing_individual) && (
                        <p className="hidden-text-red">
*
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem> */}
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.located ? "text-red" : "form-question"}`}>{t("general.Where are you located?")}</p>
                    <div className="d-flex">
                        <GeneralDropdownForm placeHolder={t("general.Choose country")} options={locateOptions} defaultValue={extraData.located} onChange={(e) => setExtraData({ ...extraData, located: e.target.value })} />
                        <GeneralDropdownForm placeHolder={t("general.Choose city")} options={LIST.getCityList(extraData.located)} defaultValue={extraData.city} onChange={(e) => setExtraData({ ...extraData, city: e.target.value })} />
                    </div>
                    {(isHasEmptyFields && !extraData.city) && (
                        <p className="hidden-text-red">
*
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <GeneralInputForm value={userData.address || 'N/A'} placeholder={t("general.Address")} margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, address: e.target.value })} />
                    <GeneralInputForm value={userData.phone_number || 'N/A'} placeholder={`${t("general.Phone number")} (${t("general.private")})`} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, phone_number: e.target.value })} />
                    {(isHasEmptyFields && !userData.phone_number) && (
                        <p className="hidden-text-red">
*
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <GeneralInputForm value={formData.email || 'N/A'} placeholder={`${t("general.Email address")} (${t("general.private")})`} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setFormData({ ...formData, email: e.target.value })} disabled />
                    {(isHasEmptyFields && !formData.email) && (
                        <p className="hidden-text-red">
*
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <GeneralInputForm value={userData.website || 'N/A'} placeholder={t("general.Internet Address")} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, website: e.target.value })} />
                    <GeneralInputForm value={userData.facebook || 'N/A'} placeholder="Facebook" margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, facebook: e.target.value })} />
                    <GeneralInputForm value={userData.instagram || 'N/A'} placeholder="Instagram" margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, instagram: e.target.value })} />
                    <GeneralInputForm value={userData.linkedin || 'N/A'} placeholder="Linkedin" margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, linkedin: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.operate_locate) ? "text-red" : "form-question"}`}>{t("general.Where do you operate?")}</p>
                    <GeneralDropdownForm placeHolder={t("general.Choose all that apply")} options={locateOptions} onChange={(e) => selectTag(extraData.operate_locate, e.target.value, "operate")} />
                    <SelectionContainer>
                        {_renderTags(extraData.operate_locate, "operate")}
                    </SelectionContainer>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.language) ? "text-red" : "form-question"}`}>{t("general.Which are the languages you prefer to interact?")}</p>
                    <GeneralDropdownForm placeHolder={t("general.Choose language")} options={LanguageOptions} onChange={(e) => selectTag(extraData.language, e.target.value, "language")} />
                    <SelectionContainer>
                        {_renderTags(extraData.language, 'language')}
                    </SelectionContainer>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.employment_status ? "text-red" : "form-question"}`}>{t("general.What is your employment status?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Employed'
                            name='employeeStatus1'
                            defaultChecked={extraData.employment_status === 'Employed'}
                            onChange={(e) => setExtraData({ ...extraData, employment_status: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.employeeStatus1 = ref}
                        />
                        <p onClick={() => checkBoxRef.employeeStatus1.click()}>{t("general.Employed")}</p>
                        <GeneralInputForm
                            placeholder={t("general.Company")}
                            margin='0 0 0 0.5em'
                            width="98.5%"
                            onChange={(e) => setExtraData({ ...extraData, employee_company: e.target.value })}
                            defaultValue={extraData.employee_company || ''}
                        />
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Self-Employed'
                            name='employeeStatus2'
                            defaultChecked={extraData.employment_status === 'Self-Employed'}
                            onChange={(e) => setExtraData({ ...extraData, employment_status: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.employeeStatus2 = ref}
                        />
                        <p onClick={() => checkBoxRef.employeeStatus2.click()}>{t("general.Self-Employed")}</p>
                        <GeneralInputForm
                            placeholder={t("homepageUser.Industry")}
                            margin='0 0 0 0.5em'
                            width="98.5%"
                            onChange={(e) => setExtraData({ ...extraData, industry: e.target.value })}
                            defaultValue={extraData.industry || ''}
                        />
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Unemployed'
                            name='employeeStatus3'
                            defaultChecked={extraData.employment_status === 'Unemployed'}
                            onChange={(e) => setExtraData({ ...extraData, employment_status: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.employeeStatus3 = ref}
                        />
                        <p onClick={() => checkBoxRef.employeeStatus3.click()}>{t("general.Unemployed")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Retired'
                            name='employeeStatus4'
                            defaultChecked={extraData.employment_status === 'Retired'}
                            onChange={(e) => setExtraData({ ...extraData, employment_status: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.employeeStatus4 = ref}
                        />
                        <p onClick={() => checkBoxRef.employeeStatus4.click()}>{t("general.Retired")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.is_has_invested ? "text-red" : "form-question"}`}>{t("general.Have you previously invested in startups?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Yes'
                            name='isHasInvested2'
                            defaultChecked={extraData.is_has_invested === 'Yes'}
                            onChange={(e) => setExtraData({ ...extraData, is_has_invested: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.isHasInvested2 = ref}
                        />
                        <p onClick={() => checkBoxRef.isHasInvested2.click()}>{t("general.Yes")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='No'
                            name='isHasInvested1'
                            defaultChecked={extraData.is_has_invested === 'No'}
                            onChange={(e) => setExtraData({ ...extraData, is_has_invested: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.isHasInvested1 = ref}
                        />
                        <p onClick={() => checkBoxRef.isHasInvested1.click()}>{t("general.No")}</p>
                    </span>
                    {extraData.is_has_invested === 'Yes' && (
                        <div className="yes-case">
                            <p>{t("general.If yes, where have you invested?")}</p>
                            <GeneralDropdownForm placeHolder={t("general.Choose industry")} options={LIST.industryOptions} onChange={(e) => selectTag(extraData.invested, e.target.value, "invested")} translatedropdown={true} />
                            <SelectionContainer>
                                {_renderTags(extraData.invested, "invested")}
                            </SelectionContainer>
                        </div>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.invest_platform) ? "text-red" : "form-question"}`}>{t("general.Where would you like to invest as part of Dua Partner Invest platform?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Agriculture, forestry and fishing'
                            name='investPlatform1'
                            defaultChecked={extraData.invest_platform.includes('Agriculture, forestry and fishing')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform1 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform1.click()}>{t("general.Agriculture, forestry and fishing")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Mining and quarrying'
                            name='investPlatform2'
                            defaultChecked={extraData.invest_platform.includes('Mining and quarrying')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform2 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform2.click()}>{t("general.Mining and quarrying")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Manufactured'
                            name='investPlatform3'
                            defaultChecked={extraData.invest_platform.includes('Manufactured')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform3 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform3.click()}>{t("general.Manufactured")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Electricity, gas, steam and air conditioning supply'
                            name='investPlatform4'
                            defaultChecked={extraData.invest_platform.includes('Electricity, gas, steam and air conditioning supply')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform4 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform4.click()}>{t("general.Electricity, gas, steam and air conditioning supply")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Water supply, sewerage, waste management and remediation activities'
                            name='investPlatform5'
                            defaultChecked={extraData.invest_platform.includes('Water supply, sewerage, waste management and remediation activities')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform5 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform5.click()}>{t("general.Water supply, sewerage, waste management and remediation activities")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Construction'
                            name='investPlatform6'
                            defaultChecked={extraData.invest_platform.includes('Construction')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform6 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform6.click()}>{t("general.Construction")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Wholesale and retail trade; repair of motor vehicles and motorcycles'
                            name='investPlatform7'
                            defaultChecked={extraData.invest_platform.includes('Wholesale and retail trade; repair of motor vehicles and motorcycles')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform7 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform7.click()}>{t("general.Wholesale and retail trade; repair of motor vehicles and motorcycles")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Transportation and storage'
                            name='investPlatform8'
                            defaultChecked={extraData.invest_platform.includes('Transportation and storage')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform8 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform8.click()}>{t("general.Transportation and storage")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Accommodation and food service activities'
                            name='investPlatform9'
                            defaultChecked={extraData.invest_platform.includes('Accommodation and food service activities')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform9 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform9.click()}>{t("general.Accommodation and food service activities")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Information and communication'
                            name='investPlatform10'
                            defaultChecked={extraData.invest_platform.includes('Information and communication')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform10 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform10.click()}>{t("general.Information and communication")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Real estate activities'
                            name='investPlatform11'
                            defaultChecked={extraData.invest_platform.includes('Real estate activities')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform11 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform11.click()}>{t("general.Real estate activities")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Professional, scientific and technical activities'
                            name='investPlatform12'
                            defaultChecked={extraData.invest_platform.includes('Professional, scientific and technical activities')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform12 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform12.click()}>{t("general.Professional, scientific and technical activities")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Administrative and support service activities'
                            name='investPlatform13'
                            defaultChecked={extraData.invest_platform.includes('Administrative and support service activities')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform13 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform13.click()}>{t("general.Administrative and support service activities")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Public administration and defence; compulsory social activities'
                            name='investPlatform14'
                            defaultChecked={extraData.invest_platform.includes('Public administration and defence; compulsory social activities')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform14 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform14.click()}>{t("general.Public administration and defence; compulsory social activities")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Education'
                            name='investPlatform15'
                            defaultChecked={extraData.invest_platform.includes('Education')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform15 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform15.click()}>{t("general.Education")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Human health and social work activities'
                            name='investPlatform16'
                            defaultChecked={extraData.invest_platform.includes('Human health and social work activities')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform16 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform16.click()}>{t("general.Human health and social work activities")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Arts, entertainment and recreation'
                            name='investPlatform17'
                            defaultChecked={extraData.invest_platform.includes('Arts, entertainment and recreation')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform17 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform17.click()}>{t("general.Arts, entertainment and recreation")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Other service activities'
                            name='investPlatform18'
                            defaultChecked={extraData.invest_platform.includes('Other service activities')}
                            onChange={(e) => selectMultipleChoice(extraData.invest_platform, e.target.value, 'investPlatform')}
                            checkBoxRef={(ref) => checkBoxRef.investPlatform18 = ref}
                        />
                        <p onClick={() => checkBoxRef.investPlatform18.click()}>{t("general.Other service activities")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.invest_money) ? "text-red" : "form-question"}`}>{t("general.How much money do you want to invest in startups over the next 12 months?")}</p>
                    <GeneralInputForm value={extraData.invest_money} type='number' placeholder="€ (private)" margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, invest_money: e.target.value })} />
                    <p className="line" />
                </FormItem>
                 {/* Add new */}
                 <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.minimum_money) ? "text-red" : "form-question"}`}>{t("general.What is the minimum amount per investment you are keen to consider?")}</p>
                    <GeneralInputForm value={extraData.minimum_money} type='number' placeholder="€" margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, minimum_money: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.about_me ? "text-red" : "form-question"}`}>
                        {t("general.About me")}
:
                    </p>
                    <GeneralTextAreaFrom maxLength="300" placeholder={t("general.About me")} margin='0 0 0.5em 0' width="98.5%" height="6em" defaultValue={extraData.about_me} onChange={(e) => setExtraData({ ...extraData, about_me: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.hear_about) ? "text-red" : "form-question"}`}>{t("general.Where did you hear about Dua Partner Invest platform?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Facebook'
                            name='hearAbout1'
                            defaultChecked={extraData.hear_about.includes('Facebook')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout1 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout1.click()}>Facebook</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Instagram'
                            name='hearAbout2'
                            defaultChecked={extraData.hear_about.includes('Instagram')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout2 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout2.click()}>Instagram</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Linkedin'
                            name='hearAbout3'
                            defaultChecked={extraData.hear_about.includes('Linkedin')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout3 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout3.click()}>Linkedin</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Website of Partners Albania'
                            name='hearAbout4'
                            defaultChecked={extraData.hear_about.includes('Website of Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout4 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout4.click()}>{t("general.Website of Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Activities organized by Partners Albania'
                            name='hearAbout5'
                            defaultChecked={extraData.hear_about.includes('Activities organized by Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout5 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout5.click()}>{t("general.Activities organized by Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Direct contact with the staff of Partners Albania'
                            name='hearAbout6'
                            defaultChecked={extraData.hear_about.includes('Direct contact with the staff of Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout6 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout6.click()}>{t("general.Direct contact with the staff of Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Annual report of Partners Albania'
                            name='hearAbout7'
                            defaultChecked={extraData.hear_about.includes('Annual report of Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout7 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout7.click()}>{t("general.Annual report of Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Other online platforms'
                            name='hearAbout8'
                            defaultChecked={extraData.hear_about.includes('Other online platforms')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout8 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout8.click()}>{t("general.Other online platforms")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Other'
                            name='hearAbout9'
                            defaultChecked={extraData.hear_about.includes('Other')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout9 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout9.click()}>{t("general.Other")}</p>
                        <GeneralInputForm
                            placeholder={t("general.Describe")}
                            margin='0 0 0 0.5em'
                            width="98.5%"
                            onChange={(e) => setDescribeOtherChoice({ ...describeOtherChoice, hear_about: e.target.value })}
                            defaultValue={(extraData.hear_about.includes("Other") ? _.last(extraData.hear_about) : '')}
                        />
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !formData.status ? "text-red" : "form-question"}`}>{t("general.User status")}</p>
                    <GeneralDropdownForm defaultValue={formData.status || ""} placeHolder={t("general.Choose user status")} options={LIST.getUserStatus} onChange={(e) => setFormData({ ...formData, status: e.target.value })} />
                    <p className="line" translatedropdown={true} />
                </FormItem>
                <SubmitButton type='button' onClick={() => submitForm()}>
                    {t("general.Update")}
                </SubmitButton>
            </FormContainer>
        );
    };

    return (
        _renderFormItemsForIndividual()
    );
};

export default Individual;
