import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput,
    InputContainer,
    GeneralButtonLanguage
} from "./ContactUs.styles";
import {
    PageTitle,
    GeneralButton,
    LabelSlide
} from "../../../../../components/Common/CommonLayout";
import ContactUsActions from "../../../../../actions/ContactUs";
import Loading from "../../../../../components/Layout/CommonLayoutPart/Loading";
import { ColorName } from "../../../../../components/Variables";
import Utils from "../../../../../libs/Utils";
import Validation from "../../../../../libs/Validation";
import Constants from "../../../../../constants/Constants";

const ContactUs = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.ContactUs.isLoading);
    const dataContactUs = useSelector((state) => state.ContactUs.contact);
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());
    const type = "information_contact_us";
    const [data, setData] = useState({
        email: {
            en: "",
            al: ""
        },
        phone: {
            en: "",
            al: ""
        },
        address: {
            en: "",
            al: ""
        },
        city: {
            en: "",
            al: ""
        }
    });

    const validateMutilpleLanguage = () => {
        const validationRules = [
            {
                name: `${t("general.Email")} ${t("general.English")}`,
                value: data.email.en,
                rule: ["email", { min: 10, max: 100 }]
            },
            {
                name: `${t("general.Email")} ${t("general.Albania")}`,
                value: data.email.al,
                rule: ["email", { min: 10, max: 100 }]
            },
            {
                name: `${t("general.Phone number")} ${t("general.English")}`,
                value: data.phone.en,
                rule: ["required", { min: 5, max: 50 }]
            },
            {
                name: `${t("general.Phone number")} ${t("general.Albania")}`,
                value: data.phone.al,
                rule: ["required", { min: 5, max: 50 }]
            },
            {
                name: `${t("general.Address")} ${t("general.English")}`,
                value: data.address.en,
                rule: ["required", { min: 5, max: 100 }]
            },
            {
                name: `${t("general.Address")} ${t("general.Albania")}`,
                value: data.address.al,
                rule: ["required", { min: 5, max: 100 }]
            },
            {
                name: `${t("general.City")} ${t("general.English")}`,
                value: data.city.en,
                rule: ["required", { min: 5, max: 100 }]
            },
            {
                name: `${t("general.City")} ${t("general.Albania")}`,
                value: data.city.al,
                rule: ["required", { min: 5, max: 100 }]
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const validateForLanguage = () => {
        const validationRules = [
            {
                name: t("general.Email"),
                value: data.email[language],
                rule: ["email", { min: 5, max: 100 }]
            },
            {
                name: t("general.Phone number"),
                value: data.phone[language],
                rule: ["required", { min: 5, max: 50 }]
            },
            {
                name: t("general.Address"),
                value: data.address[language],
                rule: ["required", { min: 5, max: 100 }]
            },
            {
                name: t("general.City"),
                value: data.city[language],
                rule: ["required", { min: 5, max: 100 }]
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const saveContactUs = () => {
        let flag = true;
        if (isMultipleLanguage) {
            flag = validateMutilpleLanguage();
        } else if (!validateForLanguage()) return;
        if (flag) {
            const param = new FormData();
            param.append('type', type);
            param.append('data', JSON.stringify(data));
            param.append('is_support_mul_lang', isMultipleLanguage);
            dispatch(ContactUsActions.addContactUs(param));
        }
    };

    useEffect(() => {
        const dataGet = {
            type,
            lang: language
        };
        dispatch(ContactUsActions.getContactUs(dataGet));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    useEffect(() => {
        if (!_.isEmpty(dataContactUs)) {
            setData(dataContactUs.data_json);
            setIsmultipleLanguage(dataContactUs.is_support_mul_lang);
        }
    }, [dataContactUs]);

    const onchangeData = (value, ctype) => {
        const temp = { ...data };
        temp[ctype][language] = value;
        setData(temp);
    };

    const _renderContactUs = () => {
        return (
            <ProgressBar>
                <WrapperInput className="absolute">
                    <TitleInput>
                        <div className="language">
                            <LabelSlide className="switch">
                                <input type="checkbox" checked={!!isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} />
                                <span className="slider round" />
                            </LabelSlide>
                            {isMultipleLanguage
                                && (
                                    <>
                                        <GeneralButtonLanguage
                                            type="button"
                                            className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                            value={t("general.English")}
                                            onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                        />
                                        <GeneralButtonLanguage
                                            type="button"
                                            className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                            value={t("general.Albanian")}
                                            onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                        />
                                    </>
                                )
                            }
                            {!isMultipleLanguage
                                && (
                                    <>
                                        <GeneralButtonLanguage
                                            type="button"
                                            className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                            value={t("general.English")}
                                            onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                            disabled
                                        />
                                        <GeneralButtonLanguage
                                            type="button"
                                            className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                            value={t("general.Albanian")}
                                            onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                            disabled
                                        />
                                    </>
                                )
                            }
                        </div>
                    </TitleInput>
                </WrapperInput>
                <WrapperInput>
                    <TitleInput>
                        {t("general.Email")}
                    </TitleInput>
                    <InputContainer
                        value={data.email[language]}
                        onChange={(event) => onchangeData(event.target.value, "email")}
                    />
                </WrapperInput>
                <WrapperInput>
                    <TitleInput>
                        {t("general.Phone number")}
                    </TitleInput>
                    <InputContainer
                        value={data.phone[language]}
                        onChange={(event) => onchangeData(event.target.value, "phone")}
                    />
                </WrapperInput>
                <WrapperInput>
                    <TitleInput>
                        {t("general.Address")}
                    </TitleInput>
                    <InputContainer
                        value={data.address[language]}
                        onChange={(event) => onchangeData(event.target.value, "address")}
                    />
                </WrapperInput>
                <WrapperInput>
                    <TitleInput>
                        {t("general.City")}
                    </TitleInput>
                    <InputContainer
                        value={data.city[language]}
                        onChange={(event) => onchangeData(event.target.value, "city")}
                    />
                </WrapperInput>
            </ProgressBar>
        );
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={false}
            page="contact-us"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("general.Contact Us")}
                    </PageTitle>
                    { _renderContactUs()}
                    <div className="container-button">
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("general.Save")}
                            onClick={() => saveContactUs()}
                        />
                    </div>
                </BodyContainer>
            )}
        />
    );
};

ContactUs.propTypes = {

};

export default ContactUs;
