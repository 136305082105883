import _ from "lodash";
import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Message from "./Message";

const Messages = ({ data, userReceiveData }) => {
  const { t } = useTranslation();
  const myRef = useRef(null);
  const scrollToMyRef = () => {
    const scroll = myRef.current.scrollHeight - myRef.current.clientHeight;
    myRef.current.scrollTo(0, scroll);
  };

  useEffect(() => {
    scrollToMyRef();
  }, [data]);

  return (
    <div className="messages" ref={myRef}>
      {_.isEmpty(data) && (
        <div className="p-2">{t("general.No Data Found")}</div>
      )}
      {data.map((message) => (
        <div className="p-2" key={message.id}>
          <Message data={message} userReceiveData={userReceiveData} />
        </div>
      ))}
    </div>
  );
};

export default Messages;
