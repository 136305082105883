import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput,
    InputContainer,
    GeneralButtonLanguage
} from "./Process.styles";
import {
    PageTitle,
    GeneralButton,
    LabelSlide
} from "../../../../components/Common/CommonLayout";
import ProcessActions from "../../../../actions/Process";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import { ColorName } from "../../../../components/Variables";
import Utils from "../../../../libs/Utils";
import Constants from "../../../../constants/Constants";

const AddProcess = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.Process.isLoading);
    const dataProcess = useSelector((state) => state.Process.process);
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());
    const [type, setType] = useState("entrepreneur_process");
    const [data, setData] = useState({
        0: {
            en: {
                title: "",
                description: ""
            },
            al: {
                title: "",
                description: ""
            }
        }
    });

    const validateFormMultiple = () => {
        let flag = false;
        _.each(data, (item) => {
            if (_.get(item.en, "title") === "" || _.get(item.en, "description") === "") {
                Utils.popupAlert({ title: "Opps..", text: t("alert.Fields cannot be blank"), type: "error" });
                flag = true;
            }
            if (_.get(item.al, "title") === "" || _.get(item.al, "description") === "") {
                Utils.popupAlert({ title: "Opps..", text: t("alert.Fields cannot be blank"), type: "error" });
                flag = true;
            }
        });
        return flag;
    };

    const validateFormSingleLanguage = () => {
        let flag = false;
        _.each(data, (item) => {
            if (_.get(item[language], "title") === "" || _.get(item.en, "description") === "") {
                Utils.popupAlert({ title: "Opps..", text: t("alert.Fields cannot be blank"), type: "error" });
                flag = true;
            }
        });
        return flag;
    };

    const addProcess = () => {
        let flag = true;
        if (isMultipleLanguage) {
            flag = validateFormMultiple();
        } else {
            flag = validateFormSingleLanguage();
        }
        if (!flag) {
            const param = new FormData();
            param.append('type', type);
            param.append('data', JSON.stringify(data));
            param.append('is_support_mul_lang', isMultipleLanguage);
            dispatch(ProcessActions.addProcess(param));
        }
    };

    useEffect(() => {
        const dataGet = {
            type
        };
        setData({
            0: {
                en: {
                    title: "",
                    description: ""
                },
                al: {
                    title: "",
                    description: ""
                }
            }
        });
        dispatch(ProcessActions.getProcess(dataGet));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    useEffect(() => {
        if (!_.isEmpty(dataProcess)) {
            setData(dataProcess.data_json);
            setIsmultipleLanguage(dataProcess.is_support_mul_lang);
        }
    }, [dataProcess]);

    const onChangeTitle = (value, index) => {
        const temp = { ...data };
        if (!_.has(temp, index)) {
            temp[index] = {};
            temp[index][language] = {};
        }
        temp[index][language].title = value;
        setData(temp);
    };

    const onChangeDesc = (value, index) => {
        const temp = { ...data };
        if (!_.has(temp, index)) {
            temp[index] = {};
            temp[index][language] = {};
        }
        temp[index][language].description = value;
        setData(temp);
    };

    const addStep = () => {
        const temp = { ...data };
        if (!_.isNull(temp)) {
            const number = _.size(temp);
            temp[number] = {
                en: {
                    title: "",
                    description: ""
                },
                al: {
                    title: "",
                    description: ""
                }
            };
            setData(temp);
        }
    };

    const deleteStep = (index) => {
        const temp = { ...data };
        delete temp[index];
        setData(temp);
    };

    const _renderNewStep = (item, index) => {
        if (!index) index = 0;
        return (
            <ProgressBar className="mb-2" key={index}>
                {(_.parseInt(index) >= 1 && _.parseInt(index) === _.size(data) - 1)
                    && (
                        <div className="delete" onClick={() => deleteStep(index)}><i className="fas fa-trash-alt" /></div>
                    )
                }
                <WrapperInput>
                    <TitleInput>
                        {t("general.Title")}
                        {_.parseInt(index) === 0 && (
                            <div className="language">
                                <LabelSlide className="switch">
                                    <input type="checkbox" checked={!!isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} />
                                    <span className="slider round" />
                                </LabelSlide>
                                {isMultipleLanguage
                                    && (
                                        <>
                                            <GeneralButtonLanguage
                                                type="button"
                                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                                value={t("general.English")}
                                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                            />
                                            <GeneralButtonLanguage
                                                type="button"
                                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                                value={t("general.Albanian")}
                                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                            />
                                        </>
                                    )
                                }
                                {!isMultipleLanguage
                                    && (
                                        <>
                                            <GeneralButtonLanguage
                                                type="button"
                                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                                value={t("general.English")}
                                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                                disabled
                                            />
                                            <GeneralButtonLanguage
                                                type="button"
                                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                                value={t("general.Albanian")}
                                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                                disabled
                                            />
                                        </>
                                    )
                                }
                            </div>
                        )}
                    </TitleInput>
                    <InputContainer
                        value={item[language] ? item[language].title : ""}
                        onChange={(event) => onChangeTitle(event.target.value, index)}
                    />
                </WrapperInput>
                <WrapperInput>
                    <TitleInput>{t("general.Description")}</TitleInput>
                    <textarea
                        value={item[language] ? item[language].description : ""}
                        onChange={(event) => onChangeDesc(event.target.value, index)}
                    />
                </WrapperInput>
            </ProgressBar>
        );
    };

    const _renderAllStep = () => {
        const render = _.map(data, (item, index) => {
            return _renderNewStep(item, index);
        });
        return render;
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={false}
            page="process"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("process.Add Process")}
                    </PageTitle>
                    <WrapperInput>
                        <TitleInput>{t("general.Type")}</TitleInput>
                        <select onChange={(e) => setType(e.target.value)} value={type}>
                            <option value="entrepreneur_process">
                                {t("general.Entrepreneurs")}
                                {' '}
                                ({t("general.INDIVIDUAL")})
                            </option>
                            <option value="entrepreneur_process_register">
                                {t("general.Entrepreneurs")}
                                {' '}
                                ({t("general.Register")})
                            </option>
                            <option value="investor_process">
                                {t("general.Investors")}
                            </option>
                            <option value="consultant_process">
                                {t("general.Consultants")}
                            </option>
                        </select>
                    </WrapperInput>
                    {!data
                        && _renderNewStep()
                    }
                    {data
                        && _renderAllStep()
                    }
                    <div className="container-button">
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("process.Add Step")}
                            onClick={() => addStep()}
                        />
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("general.Save")}
                            onClick={() => addProcess()}
                        />
                    </div>
                </BodyContainer>
            )}
        />
    );
};

AddProcess.propTypes = {

};

export default AddProcess;
