import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer, ContentBodyContainer } from "../../../Common/CommonLayout";
import { ColorName } from "../../../Variables";

const HeaderFrontEndContainer = styled(HorizontalContainer)`
    background: white;
    padding: 1em 0 0 1em;
    margin: 0 1em;
    position: relative;
    &.search {
        padding: 0.5em 0 1em 0;
        display: flex;
        justify-content: flex-end;
    }
`;

const Container = styled(ContentBodyContainer)`
    &.for-search{
        position: relative;
        display: flex;
        width: 250px;
        justify-content: flex-end;
        margin: 0;
    }
    color: ${ColorName.chathamsBlueDark};
    & .search-bar{
        width: 100%;
        border: 1px solid ${ColorName.chathamsBlueDark};
        outline: none;
        padding: 5px;
    }
    & i{
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
    }
    &.item{
        padding: 10px 0;
        border-bottom: 1px solid ${ColorName.chathamsBlueDark};
    }
    & .container-button{
        display: flex;
        justify-content: flex-end;
    }
`;


const Logo = styled.div`
    flex: 1;
    & .logo {
        cursor: pointer;
        height: 8em;
        @media (max-width: 1024px) {
            height: 7em;
        }
    }
`;

const HeaderContent = styled(VerticalContainer)`
    @media (max-width: 768px) {
        place-content: center;
    }
`;

const MobileMenu = styled.div`
    display: none;
    & i{
        font-size: 3em;
        color: ${ColorName.chathamsBlueDark};
        cursor: pointer;
    }
    @media (max-width: 768px) {
        display: flex;
    }
    & .container-menu{
        background: white;
        position: absolute;
        top: 80%;
        -webkit-box-shadow: 0px 4px 2px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 2px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 2px 0px rgba(0,0,0,0.75);
        top: 80%;
        left: -1em;
        right: -1em;
        padding: 1em;
        z-index: 9;
        & .itemMenu{
            cursor: pointer;
            & .space {
                width: 10px;
            }
            display: flex;
            padding: 1em 0;
            color: ${ColorName.chathamsBlueDark};
            border-top: 1px solid ${ColorName.chathamsBlueDark};
            &:hover{
                font-weight: 600;
            }
        }
        & .active{
            font-weight: 600;
        }
    }
`;

const HeaderAuthen = styled(HorizontalContainer)`
    flex: 1;
    justify-content: flex-end;
    & .space {
        width: 10px;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;

const HeaderMenu = styled(VerticalContainer)`
    
`;

const TopMenu = styled(HorizontalContainer)`
    flex: 1;
    border-bottom: 1px solid ${ColorName.bostonBlue};
    justify-content: flex-end;
    @media (max-width: 768px) {
        display: none;
    }
`;

const BottomMenu = styled(HorizontalContainer)`
    flex: 1;
    border-top: 1px solid ${ColorName.bostonBlue};
    justify-content: flex-end;
    @media (max-width: 768px) {
        display: none;
    }
`;
const ChildMenu = styled.div`
    &.display-none{
        display: none;
    }
    position: absolute;
    z-index: 9;
    top: 100%;
    & .child-item{
        background: white;
        text-align: left;
        padding: 0.5em 1em;
        text-shadow: none;
        border-bottom: 1px solid ${ColorName.bostonBlue};
        background: #E6E6E6;
        &:hover{
            text-shadow: 0 0 0px #1C4687, 0 0 0px #1C4687;
            background: ${ColorName.chathamsBlue};
            color: white;
        }
        &:last-child{
            border: none;
        }
    }
`;
const ItemMenu = styled.div`
    font-size: 16px;
    align-items: center;
    display: flex;
    padding: 10px 0;
    color: ${ColorName.chathamsBlueDark};
    font-weight: 600;
    text-align: center;
    &:hover{
        cursor: pointer;
        text-shadow: 0 0 0px #1C4687, 0 0 0px #1C4687;
    }
    &.active{
        text-shadow: 0 0 0px #1C4687, 0 0 0px #1C4687;
        border-bottom: 2px solid ${ColorName.chathamsBlueDark};
        cursor: pointer;
    }
    &.relative{
        position: relative;
    }
`;

const ContentLang = styled.div`
    margin-left: 10px;
    font-weight: 600;
    height: 35px;
    position: relative;
`;

export {
    HeaderFrontEndContainer,
    Logo,
    HeaderContent,
    HeaderAuthen,
    HeaderMenu,
    TopMenu,
    BottomMenu,
    ItemMenu,
    MobileMenu,
    ChildMenu,
    ContentLang,
    Container
};
