import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer } from '../../../../components/Common/CommonLayout';
import { ColorName } from '../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .ml-1 {
        margin-left: 1em
    }
    & .mb-2 {
        margin-bottom: 2em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
`;

const Report = styled(HorizontalContainer)`
    flex: 1;
    margin-bottom: 3em;
`;

const BlockReport = styled.div`
    margin-right: 40px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: ${ColorName.chathamsBlue};
    &.mt-title {
        margin-top: 3em
    }
    &.mb-title {
        margin-bottom: 2em
    }
`;

const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || "0"};
        span {
            width: 140px;
        }
    & .title-deadline {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: ${ColorName.chathamsBlueDark}
    }
    & .fonts-status {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        display: flex;
    }
    & .fonts-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: ${ColorName.chathamsBlueDark};
    }
    & .fonts-value{
        font-size: 14;
        line-height: 1;
        color: ${ColorName.black};
        margin-top: 1.5em;
    }
    & .color-green {
        color: ${ColorName.fern};
    }
`;

const WrapperButton = styled.div`
    display: flex;
    margin-top: 8em;
    justify-content: flex-end;
`;

export {
    BodyContainer,
    Report,
    BlockReport,
    Title,
    WrapperTitle,
    WrapperButton
};
