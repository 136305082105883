import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
    WrapperModal,
    ModalContent,
    FieldTitle,
    WrapperInput,
    WrapperTitleContent
} from "./FundingApplications.styles";
import Button from "../../../../components/Common/Button";
import { ColorName } from "../../../../components/Variables";

const PopupAddNewCategory = (props) => {
    const { t } = useTranslation();
    const [categorydData, setCategorydData] = useState({
        categoryName: '',
        fields: []
    });
    const [isError, setIsError] = useState(false);
    const hideModal = (event) => {
        if (event.target.classList.contains('wrapper')) {
            props.hideModal();
        }
    };

    const addNewField = () => {
        if (categorydData.categoryName !== '') {
            props.hideModal();
            props.callBackAddNewCategory(categorydData);
        }
        setIsError(true);
    };

    return (
        <WrapperModal onClick={(event) => hideModal(event)} className='wrapper'>
            <ModalContent>
                <WrapperTitleContent marginTop="4em">
                    <FieldTitle marginRight="3em" className="custom-title">
                        {t("fundingApplications.Category Name")}
                        :
                        {' '}
                    </FieldTitle>
                    <WrapperInput
                        marginBottom='0'
                        onChange={(event) => setCategorydData({ ...categorydData, categoryName: event.target.value })}
                    />
                </WrapperTitleContent>
                {isError
                    && (
                        <WrapperTitleContent>
                            <div>{t("fundingApplications.Category name should not be blank!")}</div>
                        </WrapperTitleContent>
                    )
                }
                <WrapperTitleContent className="mt-2 alignCenter">
                    <Button
                        background={ColorName.gray}
                        title={t("general.Cancel")}
                        height="35px"
                        width="11em"
                        margin="0 1.5em 0 0"
                        onClick={() => props.hideModal()}
                    />
                    <Button
                        background={ColorName.fruitSaladLight}
                        title={t("general.Add")}
                        height="35px"
                        width="11em"
                        margin="0 1.5em 0 0"
                        onClick={() => addNewField()}
                    />
                </WrapperTitleContent>
            </ModalContent>
        </WrapperModal>
    );
};

PopupAddNewCategory.propTypes = {
    hideModal: PropTypes.func,
    callBackAddNewCategory: PropTypes.func
};

export default PopupAddNewCategory;
