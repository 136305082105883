import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput,
    InputContainer
} from "./SummarySetting.styles";
import {
    PageTitle,
    GeneralButton
} from "../../../../../components/Common/CommonLayout";
import Loading from "../../../../../components/Layout/CommonLayoutPart/Loading";
import { ColorName } from "../../../../../components/Variables";
import SummarySettingActions from "../../../../../actions/GuestSummarySetting";

const SummarySetting = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.GuestSummarySetting.isLoading);
    const summarySetting = useSelector((state) => state.GuestSummarySetting.summaryData);

    const [informationOne, setInformationOne] = useState(summarySetting.informationOne);
    const [informationTwo, setInformationTwo] = useState(summarySetting.informationTwo);
    const [informationThree, setInformationThree] = useState(summarySetting.informationThree);
    const [informationFour, setInformationFour] = useState(summarySetting.informationFour);
    const [informationFive, setInformationFive] = useState(summarySetting.informationFive);

    useEffect(() => {
        dispatch(SummarySettingActions.getSummarySettingData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInformationOne(summarySetting.informationOne);
        setInformationTwo(summarySetting.informationTwo);
        setInformationThree(summarySetting.informationThree);
        setInformationFour(summarySetting.informationFour);
        setInformationFive(summarySetting.informationFive);
    }, [summarySetting]);

    const submitForm = () => {
        const data = {
            informationOne,
            informationTwo,
            informationThree,
            informationFour,
            informationFive
        };
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        dispatch(SummarySettingActions.updateSummarySettingData(formData));
    };

    const mainRender = () => {
        return (
            <BodyContainer>
                {isLoading && <Loading />}
                <PageTitle>
                    {t("summarySetting.Summary Setting")}
                </PageTitle>
                <ProgressBar className="mb-2">
                    <WrapperInput>
                        <TitleInput />
                        <InputContainer value={t("general.English")} className="thead-title" readOnly />
                        <InputContainer value={t("general.Albania")} className="thead-title" readOnly />
                        <InputContainer className="thead-title" />
                    </WrapperInput>
                    <WrapperInput>
                        {/* STARTUPS FUNDED */}
                        <TitleInput>
                            {t("summarySetting.Information")}
                            {' '}
                            1
                        </TitleInput>
                        <InputContainer
                            placeholder={t("summarySetting.Startups Funded")}
                            value={_.get(informationOne, 'name', '')}
                            onChange={(event) => setInformationOne({ ...informationOne, name: event.target.value })}
                        />
                        <InputContainer
                            placeholder={t("summarySetting.Startups Funded")}
                            value={_.get(informationOne, 'nameAl', '')}
                            onChange={(event) => setInformationOne({ ...informationOne, nameAl: event.target.value })}
                        />
                        <InputContainer
                            value={_.get(informationOne, 'value', 0)}
                            onChange={(event) => setInformationOne({ ...informationOne, value: event.target.value })}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        {/* STATUPS CONSULTED */}
                        <TitleInput>
                            {t("summarySetting.Information")}
                            {' '}
                            2
                        </TitleInput>
                        <InputContainer
                            placeholder={t("summarySetting.Startups Consulted")}
                            value={_.get(informationTwo, 'name', '')}
                            onChange={(event) => setInformationTwo({ ...informationTwo, name: event.target.value })}
                        />
                        <InputContainer
                            placeholder={t("summarySetting.Startups Consulted")}
                            value={_.get(informationTwo, 'nameAl', '')}
                            onChange={(event) => setInformationTwo({ ...informationTwo, nameAl: event.target.value })}
                        />
                        <InputContainer
                            value={_.get(informationTwo, 'value', 0)}
                            onChange={(event) => setInformationTwo({ ...informationTwo, value: event.target.value })}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        {/* INVESTORS */}
                        <TitleInput>
                            {t("summarySetting.Information")}
                            {' '}
                            3
                        </TitleInput>
                        <InputContainer
                            placeholder={t("general.Investors")}
                            value={_.get(informationThree, 'name', '')}
                            onChange={(event) => setInformationThree({ ...informationThree, name: event.target.value })}
                        />
                        <InputContainer
                            placeholder={t("general.Investors")}
                            value={_.get(informationThree, 'nameAl', '')}
                            onChange={(event) => setInformationThree({ ...informationThree, nameAl: event.target.value })}
                        />
                        <InputContainer
                            value={_.get(informationThree, 'value', 0)}
                            onChange={(event) => setInformationThree({ ...informationThree, value: event.target.value })}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        {/* COUNTRIES */}
                        <TitleInput>
                            {t("summarySetting.Information")}
                            {' '}
                            4
                        </TitleInput>
                        <InputContainer
                            placeholder={t("summarySetting.Countries")}
                            value={_.get(informationFour, 'name', '')}
                            onChange={(event) => setInformationFour({ ...informationFour, name: event.target.value })}
                        />
                        <InputContainer
                            placeholder={t("summarySetting.Countries")}
                            value={_.get(informationFour, 'nameAl', '')}
                            onChange={(event) => setInformationFour({ ...informationFour, nameAl: event.target.value })}
                        />
                        <InputContainer
                            value={_.get(informationFour, 'value', 0)}
                            onChange={(event) => setInformationFour({ ...informationFour, value: event.target.value })}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>
                            {t("summarySetting.Information")}
                            {' '}
                            5
                        </TitleInput>
                        <InputContainer
                            placeholder={t("general.Entrepreneurs")}
                            value={_.get(informationFive, 'name', '')}
                            onChange={(event) => setInformationFive({ ...informationFive, name: event.target.value })}
                        />
                        <InputContainer
                            placeholder={t("general.Entrepreneurs")}
                            value={_.get(informationFive, 'nameAl', '')}
                            onChange={(event) => setInformationFive({ ...informationFive, nameAl: event.target.value })}
                        />
                        <InputContainer
                            value={_.get(informationFive, 'value', 0)}
                            onChange={(event) => setInformationFive({ ...informationFive, value: event.target.value })}
                        />
                    </WrapperInput>
                    <div className="container-button">
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("general.Save")}
                            onClick={() => submitForm()}
                        />
                    </div>
                </ProgressBar>
            </BodyContainer>
        );
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={false}
            page="summary-setting"
            content={mainRender()}
        />
    );
};

SummarySetting.propTypes = {

};

export default SummarySetting;
