import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    PageTitle,
    GeneralButton
} from "../../../../components/Common/CommonLayout";
import {
    BodyContainer
} from "./Library.styles";
import history from '../../../../history';
import CONSTANTS from "../../../../constants/Constants";
import RoundedTable from "../../../../components/Common/RoundedTable";
import LibraryActions from "../../../../actions/Library";
import { ColorName } from '../../../../components/Variables';
import Pagination from "../../../../components/Common/NewPagination";

const Library = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const isLoading = useSelector((state) => state.Library.isLoading);
    const allLibraryData = useSelector((state) => state.Library.allLibraryData);
    const columns = ["image", 'title', "date", 'action'];
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const listOptions = {
        1: "edit",
        2: "delete"
    };

    useEffect(() => {
        const param = {
            page: 1,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language: i18n.language
        };
        dispatch(LibraryActions.fetchLibraryData(param));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(allLibraryData)) {
            setPage(allLibraryData.page);
            setLastPage(allLibraryData.lastPage);
        }
    }, [allLibraryData]);

    const clickPageOrLink = (currentPage) => {
        const data = {
            page: currentPage,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language: i18n.language
        };
        dispatch(LibraryActions.fetchLibraryData(data));
    };

    const callBackPrev = () => {
        setPage(page - 1);
        clickPageOrLink(page - 1);
    };

    const callBackNext = () => {
        setPage(page + 1);
        clickPageOrLink(page + 1);
    };

    const pageOnclick = (e) => {
        clickPageOrLink(_.parseInt(e));
    };

    const _renderPagination = () => {
        return (
            <Pagination page={page} lastPage={lastPage || 1} callBackPrev={callBackPrev} callBackNext={callBackNext} pageOnclick={pageOnclick} />
        );
    };

    const renderMain = () => {
        return (
            <BodyContainer>
                <PageTitle>{t("library.Library")}</PageTitle>
                <div className="container-button">
                    <GeneralButton
                        type="button"
                        className="add-book"
                        background={ColorName.chathamsBlue}
                        value={t("library.Add New Library")}
                        onClick={() => history.push("/admin/add-book")}
                    />
                </div>
                {
                    (allLibraryData.data) && (
                        <>
                            <RoundedTable
                                initialData={allLibraryData.data}
                                columns={columns}
                                tableTitle={t("library.Library")}
                                tableType={CONSTANTS.TABLE_TYPE.LIBRARY}
                                className="mt-2"
                                showNumberItems={CONSTANTS.ITEM_PER_PAGE}
                                listOptions={listOptions}
                            />
                            {_renderPagination()}
                        </>
                    )
                }
            </BodyContainer>
        );
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={isLoading}
            page="library"
            content={renderMain()}
        />
    );
};

export default Library;
