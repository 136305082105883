import styled from 'styled-components';
import { VerticalContainer, BreadCrumb } from "../../../../components/Common/CommonLayout";
import { ColorName } from '../../../../components/Variables';
const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .ml-1 {
        margin-left: 1em
    }
    & .mb-2 {
        margin-bottom: 2em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
    &.center-content {
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    & .d-none {
        display: none;
    }
    & .style-img{
        max-height: 200px;
    }
`;

const UserDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-top: ${(props) => props.marginTop || "0"};
    color: ${ColorName.chathamsBlue};
    &.fullname {
        width: 100%;
        align-items: center;
    }
`;
const DropDown = styled.select`
    display: flex;
    -webkit-appearance: inner-spin-button;
    padding: 5px;
    border: 1px solid ${ColorName.chathamsBlue};
    width: ${(props) => props.width || "20em"};
    margin-top: 0.6em;
`;
const PageTitle = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    text-transform: none;
    margin-bottom: ${(props) => props.marginBottom || "1.5em"};
    margin-right: ${(props) => props.marginRight || "1em"};
    
`;
const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${ColorName.white};
    border-radius: 10px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    margin-left: ${(props) => props.marginLeft || "0px"};
    flex: 1;
`;

const WrapperInput = styled.input`
    background: none;
    border: 1px solid ${ColorName.chathamsBlue};
    height: auto;
    width: ${(props) => props.width || "80%"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 10px;
    outline: none;
    margin: 1em 0;
    &#email{
        background-color: lightGray;
    }
`;

export {
    BodyContainer,
    UserDetailsContainer,
    Row,
    DropDown,
    PageTitle,
    ProgressBar,
    WrapperInput
};
