import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  FieldTitle,
  ProgressBar,
  WrapperInput,
  WrapperContent,
  WrapperButton,
  Category,
  Field,
  TitleNewField,
  WrapperTitleContent
} from "./FundingApplications.styles";
import {
  PageTitle,
  GeneralButton
} from "../../../../components/Common/CommonLayout";
import PopupAddNewField from "./PopupAddNewField";
import PopupEditField from "./PopupEditField";
import PopupAddNewCategory from "./PopUpAddNewCategory";
import { ColorName } from "../../../../components/Variables";
import history from "../../../../history";
import FundingApplicationActions from "../../../../actions/FundingApplication";
import Utils from "../../../../libs/Utils";

const CategoryForQuestionnaire = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const FundingApplicationStorage = useSelector((state) => state.FundingApplication.newFundingData);
  const actions = useSelector((state) => state.FundingApplication.actionFundingType);
  const questionnaireDataStorage = useSelector((state) => state.FundingApplication.newFundingData.questionnaire);
  const questionnaireDataRender = useSelector((state) => state.FundingApplication.questionnaireData);
  const isLoading = useSelector((state) => state.FundingApplication.isLoading);
  const [isShowPopUpAddField, setIsShowPopUpAddField] = useState(false);
  const [isShowPopUpEditField, setIsShowPopUpEditField] = useState(false);
  const [isShowPopUpAddCategory, setIsShowPopUpAddCategory] = useState(false);
  const [questionCategory, setQuestionCategory] = useState([]);
  const [questionDetail, setQuestionDetail] = useState([]);
  const [dataEditCurrent, setDataEditCurrent] = useState({});
  const [draggedIdx, setDraggedIdx] = useState(); // eslint-disable-line
  const [currentItemCategory, setCurrentItemCategory] = useState();
  const [draggedItem, setDraggedItem] = useState();
  const [questionnaireData, setQuestionnaireData] = useState([
    {
      categoryName: t("fundingApplications.Applicant information"),
      fields: [
        {
          title: t("fundingApplications.Project Title"),
          value: '',
          fieldType: '1',
          maxCharacter: '255',
          mandatory: true,
          isEdit: false
        },
        {
          title: t("general.Full Name"),
          value: '',
          fieldType: '1',
          maxCharacter: '255',
          mandatory: true,
          isEdit: false
        },
        {
          title: t("general.Email address"),
          value: '',
          fieldType: '1',
          maxCharacter: '255',
          mandatory: true,
          isEdit: false
        },
        {
          title: t("general.Phone number"),
          value: '',
          fieldType: '1',
          maxCharacter: '255',
          mandatory: true,
          isEdit: false
        },
        {
          title: t("general.Website"),
          value: '',
          fieldType: '1',
          maxCharacter: '255',
          mandatory: true,
          isEdit: false
        }
      ]
    }
  ]);
  const [currentSelectCategory, setCurrentSelectCategory] = useState();
  const isError = false;

  const convertInCommingDataToRenderData = (questionnaireDataIncomming) => {
    const data = [];
    _.map(questionnaireDataIncomming, (item) => {
      const fields = [];
      _.map(item.questionDetails, (child) => {
        const field = {
          id: child.id,
          title: child.title,
          fieldType: child.question_type,
          maxCharacter: child.max_characters,
          mandatory: child.is_mandatory,
          isEdit: child.is_edit
        };
        fields.push(field);
      });
      const categoryData = {
        id: item.id,
        categoryName: item.title,
        fields
      };
      data.push(categoryData);
    });
    setQuestionnaireData(data);
  };

  const removeQuotes = (str) => {
    if (str) return str.replace(/["]+/g, '');
  };

  useEffect(() => {
    if (!_.isEmpty(questionnaireDataRender)) {
      if (!_.isEqual(questionnaireDataRender, questionnaireData)) return setQuestionnaireData(questionnaireDataRender);
    }
    if (!_.isEmpty(questionnaireDataStorage)) convertInCommingDataToRenderData(questionnaireDataStorage.questionCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireDataRender, questionnaireDataStorage]);

  const onChangeField = (value, field, fields, category, id = false) => {
    if (id) {
      return;
    }
    const allQuestionnaireData = [...questionnaireData];
    const currentCategory = { ...category };
    const CurrentFields = [...fields];
    const currentData = { ...field };
    const findFieldIndex = _.findIndex(CurrentFields, field);
    const FindCategoryIndex = _.findIndex(allQuestionnaireData, currentCategory);
    currentData.value = value;
    CurrentFields.splice(findFieldIndex, 1, currentData);
    currentCategory.fields = CurrentFields;
    allQuestionnaireData.splice(FindCategoryIndex, 1, currentCategory);
    setQuestionnaireData(allQuestionnaireData);
  };

  const openPopUpAddField = (category) => {
    setCurrentSelectCategory(category);
    setIsShowPopUpAddField(true);
  };

  const addNewField = (data) => {
    const currentCategory = { ...currentSelectCategory };
    const allQuestionnaireData = [...questionnaireData];
    const FindCategoryIndex = _.findIndex(allQuestionnaireData, currentCategory);
    currentCategory.fields.push(data);
    allQuestionnaireData.splice(FindCategoryIndex, 1, currentCategory);
    setQuestionnaireData(allQuestionnaireData);
  };

  const createNewCategory = (data) => {
    const allQuestionnaireData = [...questionnaireData];
    allQuestionnaireData.push(data);
    setQuestionnaireData(allQuestionnaireData);
  };

  const setNewNameField = (e, index, category, indexCategory) => {
    const tempQuestionnaireData = [...questionnaireData];
    tempQuestionnaireData[indexCategory].fields[index].title = e.target.value;
    setQuestionnaireData(tempQuestionnaireData);
  };

  const deleteField = (category, index, indexCategory) => {
    const tempQuestionnaireData = [...questionnaireData];
    const tempQuestionDetail = [...questionDetail];
    if (_.has(tempQuestionnaireData[indexCategory].fields[index], "id")) {
      tempQuestionDetail.push(tempQuestionnaireData[indexCategory].fields[index].id);
      setQuestionDetail(tempQuestionDetail);
    }
    tempQuestionnaireData[indexCategory].fields.splice(index, 1);
    setQuestionnaireData(tempQuestionnaireData);
  };

  const editPopup = (field, key, fields, category, indexCategory) => {
    const data = {
      field,
      key,
      fields,
      category,
      indexCategory
    };
    setDataEditCurrent(data);
    setIsShowPopUpEditField(true);
  };

  const onDragStart = (e, key, indexCategory) => {
    setDraggedItem(questionnaireData[indexCategory].fields[key]);
    setCurrentItemCategory(indexCategory);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragOver = (e, key, indexCategory) => {
    const draggedOverItem = questionnaireData[indexCategory].fields[key];
    // if the item is dragged over itself, ignore
    if (draggedItem === draggedOverItem || indexCategory !== currentItemCategory) {
      return;
    }
    // filter out the currently dragged item
    let items = questionnaireData[indexCategory].fields.filter(item => item !== draggedItem);

    // add the dragged item after the dragged over item
    items.splice(key, 0, draggedItem);
    const data = [...questionnaireData];
    data[indexCategory].fields = items;
    setQuestionnaireData(data);
  };

  const onDragEnd = () => {
    setDraggedIdx(null);
  };

  const _renderField = (field, key, fields, category, indexCategory) => {
    if (!_.isEmpty(field)) {
      return (
        <Field key={key} onDragOver={(idx) => onDragOver(idx, key, indexCategory)} className='move'>
          <div draggable onDragStart={(e) => onDragStart(e, key, indexCategory)} onDragEnd={onDragEnd}>
            <FieldTitle marginBottom="0.6em" marginRight='auto' className="inputEdit move">
              {actions === "edit"
                && <TitleNewField type='text' value={field.title} onChange={(e) => setNewNameField(e, key, category, indexCategory)} readOnly={!field.isEdit} />
              }
              {actions !== "edit"
                && field.title
              }
              <div className="action-button">
                {field.isEdit === true && <i className="fas fa-minus-circle" onClick={() => deleteField(category, key, indexCategory)} />}
                {field.isEdit === true && <i className="fas fa-edit" onClick={() => editPopup(field, key, fields, category, indexCategory)} />}
              </div>
            </FieldTitle>
            {field.fieldType === '1' && (
              <WrapperInput
                defaultValue={field.value}
                type='text'
                maxLength={field.maxCharacter}
                required={field.mandatory === 'yes'}
                onChange={(event) => onChangeField(event.target.value, field, fields, category, indexCategory)}
                readOnly
                className='forQuestionnaire'
              />
            )}
          </div>
        </Field>
      );
    }
  };

  const setNewNameCategory = (e, index) => {
    const tempQuestionnaireData = [...questionnaireData];
    tempQuestionnaireData[index].categoryName = removeQuotes(e.target.value);
    setQuestionnaireData(tempQuestionnaireData);
  };

  const deleteCategory = (category, index) => {
    const tempQuestionnaireData = [...questionnaireData];
    const tempQuestionCategory = [...questionCategory];
    if (_.has(category, "id")) {
      tempQuestionCategory.push(category.id);
      setQuestionCategory(tempQuestionCategory);
    }
    tempQuestionnaireData.splice(index, 1);
    setQuestionnaireData(tempQuestionnaireData);
  };

  const _renderCategories = () => {
    if (!_.isEmpty(questionnaireData)) {
      const render = _.map(questionnaireData, (category, index) => {
        return (
          <Category key={index}>
            <FieldTitle marginBottom="0em">
              {`${index + 1}. `}
              {actions === "edit"
                && <TitleNewField type='text' value={removeQuotes(category.categoryName)} onChange={(e) => setNewNameCategory(e, index)} />
              }
              {actions !== "edit"
                && removeQuotes(category.categoryName)
              }
              {category.categoryName.toLowerCase() !== 'application information'
                && (
                  <i
                    className="fas fa-minus-circle"
                    onClick={() => deleteCategory(category, index)}
                  />
                )
              }
            </FieldTitle>
            <WrapperContent>
              {
                _.map(category.fields, (field, key) => {
                  // eslint-disable-next-line no-prototype-builtins
                  if (actions === "edit") return _renderField(field, key, category.fields, category, index);
                  return _renderField(field, key, category.fields, category, index);
                })
              }
            </WrapperContent>
            <GeneralButton
              type='button'
              className="buttonFundingApplication"
              background={ColorName.chathamsBlue}
              value={t("general.Add Field")}
              height="35px"
              width="11em"
              margin="0 1.5em 0 1em"
              onClick={() => openPopUpAddField(category)}
            />
          </Category>
        );
      });
      return render;
    }
  };

  const convertQuestionnaireDataToSaveData = () => {
    const data = {};
    _.map(questionnaireData, (questionnaire) => {
      const category = questionnaire.categoryName;
      data[`${category}`] = [];
      _.map(questionnaire.fields, (field, indexField) => {
        const newObjectData = {
          order_no: indexField,
          question_type: field.fieldType,
          title: field.title,
          max_characters: field.maxCharacter,
          is_mandatory: field.mandatory
        };
        data[`${category}`].push(newObjectData);
      });
    });
    return data;
  };

  const convertQuestionnaireDataToEditData = () => {
    const data = {};
    data.question_category = [];
    data.question_detail = [];
    data.new_field = {};
    data.new_field.add_question_detail = [];
    data.new_field.add_question_category_detail = [];
    _.map(questionnaireData, (questionnaire) => {
      if (_.has(questionnaire, "id")) {
        const objCategory = {
          id: questionnaire.id,
          title: removeQuotes(questionnaire.categoryName)
        };
        data.question_category.push(objCategory);

        _.map(questionnaire.fields, (field, indexField) => {
          const tempQuestionDetails = [];
          if (_.has(field, "id")) {
            const objField = {
              order_no: indexField,
              id: field.id,
              question_type: field.fieldType.toString(),
              title: field.title,
              max_characters: field.maxCharacter,
              is_mandatory: field.mandatory
            };
            return data.question_detail.push(objField);
          } else { 
            const question_details = {
              order_no: indexField,
              question_type: field.fieldType.toString(),
              title: field.title,
              max_characters: field.maxCharacter,
              is_mandatory: field.mandatory
            };
            tempQuestionDetails.push(question_details);
            const newObject = {
              category_id: questionnaire.id,
              question_details: tempQuestionDetails
            };
            data.new_field.add_question_detail.push(newObject);
          }
        });
      } else {
        const tempQuestionDetails = [];
        _.map(questionnaire.fields, (field, indexField) => {
          const objField = {
            order_no: indexField,
            question_type: field.fieldType.toString(),
            title: field.title,
            max_characters: field.maxCharacter,
            is_mandatory: field.mandatory
          };
          tempQuestionDetails.push(objField);
        });
        const newObject = {
          category_title: questionnaire.categoryName,
          question_details: tempQuestionDetails
        };
        data.new_field.add_question_category_detail.push(newObject);
      }
    });
    return data;
  };

  const validateDataEdit = (data) => {
    let flag = true;
    if (data.question_category) {
      _.map(data.question_category, (child) => {
        if (child.title === '') return flag = false;
      });
    }
    if (data.new_field.add_question_detail) {
      _.map(data.new_field.add_question_detail, (child) => {
        if (child.question_details) {
          _.map(child.question_details, (item) => {
            if (!item.title) return flag = false;
          });
        }
      });
    }
    if (data.new_field.add_question_category_detail) {
      _.map(data.new_field.add_question_category_detail, (child) => {
        if (child.question_details) {
          _.map(child.question_details, (item) => {
            if (!item.title) return flag = false;
          });
        }
      });
    }
    return flag;
  };

  const saveFundingAppilication = () => {
    const questionnaire = convertQuestionnaireDataToSaveData();
    const dataToSave = { ...FundingApplicationStorage, questionnaire, extra_field: FundingApplicationStorage.extraFieldJson };
    const formData = new FormData();
    formData.append('category', dataToSave.category);
    if (_.isArray(dataToSave.document_evaluation)) {
      _.map(dataToSave.document_evaluation, (child, index) => {
        formData.append(`document_evaluation[${index}]`, child);
      });
    }
    if (_.isArray(dataToSave.document_winner)) {
      _.map(dataToSave.document_winner, (child, index) => {
        formData.append(`document_winner[${index}]`, child);
      });
    }
    if (_.isArray(dataToSave.document_template)) {
      _.map(dataToSave.document_template, (child, index) => {
        formData.append(`document[${index}]`, child);
      });
    }
    formData.append('extra_field', JSON.stringify(dataToSave.extra_field));
    formData.append('questionnaire', JSON.stringify(dataToSave.questionnaire));
    formData.append('title', dataToSave.title);
    formData.append('direction_winner', dataToSave.directionWinner);
    formData.append('is_send_mail', dataToSave.is_send_mail || false);
    dispatch(FundingApplicationActions.createNewFundingApplication(formData));
  };

  const EditFundingAppilication = () => {
    const questionnaire = convertQuestionnaireDataToEditData();
    const dataToSave = {
      ...FundingApplicationStorage,
      question_category: questionnaire.question_category,
      question_detail: questionnaire.question_detail,
      new_field: questionnaire.new_field,
      extra_field: FundingApplicationStorage.extraFieldJson,
      remove_field: {
        question_category: questionCategory,
        question_detail: questionDetail
      }
    };
    const validate = validateDataEdit(dataToSave);
    if (validate) {
      const formData = new FormData();
      formData.append('question_category', JSON.stringify(dataToSave.question_category));
      if (dataToSave.remove_document_evaluation) formData.append('remove_document_evaluation', JSON.stringify(dataToSave.remove_document_evaluation));
      if (dataToSave.remove_document) formData.append('remove_document', JSON.stringify(dataToSave.remove_document));
      if (dataToSave.remove_document_winner) formData.append('remove_document_winner', JSON.stringify(dataToSave.remove_document_winner));
      formData.append('question_detail', JSON.stringify(dataToSave.question_detail));
      formData.append('new_field', JSON.stringify(dataToSave.new_field));
      formData.append('remove_field', JSON.stringify(dataToSave.remove_field));
      formData.append('extra_field', JSON.stringify(dataToSave.extra_field));
      formData.append('title', dataToSave.title);
      formData.append('direction_winner', dataToSave.directionWinner);
      formData.append('status', dataToSave.status);
      formData.append('category', dataToSave.category);
      if (_.isArray(dataToSave.document_evaluation)) {
        _.map(dataToSave.document_evaluation, (child, index) => {
          formData.append(`document_evaluation[${index}]`, child);
        });
      }
      if (_.isArray(dataToSave.document_winner)) {
        _.map(dataToSave.document_winner, (child, index) => {
          formData.append(`document_winner[${index}]`, child);
        });
      }
      if (_.isArray(dataToSave.document_template)) {
        _.map(dataToSave.document_template, (child, index) => {
          formData.append(`document[${index}]`, child);
        });
      }
      dispatch(FundingApplicationActions.editFundingApplication(formData, FundingApplicationStorage.id));
    } else {
      Utils.popupAlert({ title: "Oops...", text: "Category or field should not be blank!", type: "error" });
    }
  };

  const projectDescription = () => {
    dispatch(FundingApplicationActions.setNewFundingQuestionnaireData(questionnaireData));
    history.push('new-funding-application');
  };

  const onChangeEditField = (field, fields, category = false, key) => {
    const allQuestionnaireData = [...questionnaireData];
    const indexCategory = _.findIndex(allQuestionnaireData, (o) => { return o.categoryName === category.categoryName; });
    const indexField = _.findIndex(allQuestionnaireData[indexCategory].fields, (o) => { return o.id === field.id; });
    if (indexField) allQuestionnaireData[indexCategory].fields[indexField] = field;
    else allQuestionnaireData[indexCategory].fields[key] = field;
    setQuestionnaireData(allQuestionnaireData);
  };

  const callBackEditField = (data, key) => {
    onChangeEditField(data, dataEditCurrent.field, dataEditCurrent.category, key);
  };

  return (
    <DefaultLayout
      userRole='admin'
      isLoading={isLoading}
      page="fundingApplications"
      content={(
        <BodyContainer>
          {isShowPopUpAddField
            && (
              <PopupAddNewField
                hideModal={() => setIsShowPopUpAddField(false)}
                callBackAddNewField={(data) => addNewField(data)}
              />
            )}
          {isShowPopUpEditField
            && (
              <PopupEditField
                dataEditCurrent={dataEditCurrent}
                hideModal={() => setIsShowPopUpEditField(false)}
                callBackEditField={(data, key) => callBackEditField(data, key)}
              />
            )}
          {isShowPopUpAddCategory
            && (
              <PopupAddNewCategory
                hideModal={() => setIsShowPopUpAddCategory(false)}
                callBackAddNewCategory={(data) => createNewCategory(data)}
              />
            )}
          <PageTitle>
            {t("fundingApplications.New Funding Application")}
          </PageTitle>
          <FieldTitle>{t("fundingApplications.Questionnaire")}</FieldTitle>
          <ProgressBar className="mb-2">
            <Category>
              {_renderCategories()}
            </Category>
            <WrapperButton>
              <GeneralButton
                type='button'
                className="buttonFundingApplication"
                background={ColorName.chathamsBlue}
                value={t("general.Back")}
                margin="0 1.5em 0 0"
                onClick={() => projectDescription()}
              />
              <GeneralButton
                type='button'
                className="buttonFundingApplication"
                background={ColorName.chathamsBlue}
                value={t("fundingApplications.Create Category")}
                margin="0 1.5em 0 0"
                onClick={() => setIsShowPopUpAddCategory(true)}
              />
              <GeneralButton
                type='button'
                className="buttonFundingApplication"
                background={ColorName.chathamsBlue}
                value={t("general.Save")}
                margin="0 1.5em 0 0"
                onClick={actions === "edit" ? () => EditFundingAppilication() : () => saveFundingAppilication()}
              />
            </WrapperButton>
            {isError
              && (
                <WrapperTitleContent>
                  <div>{t("fundingApplications.Category name should not be blank!")}</div>
                </WrapperTitleContent>
              )
            }
          </ProgressBar>
        </BodyContainer>
      )}
    />
  );
};

CategoryForQuestionnaire.propTypes = {
};

export default CategoryForQuestionnaire;
