import ActionTypes from "../constants/ActionTypes";

const initialState = {
    hasError: false,
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    isSetNewData: false
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ActionTypes.SUBSCRIBE_REQUEST:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
                hasError: false,
                isSetNewData: false
            };
        case ActionTypes.SUBSCRIBE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                hasError: false,
                isSetNewData: true
            };
        case ActionTypes.SUBSCRIBE_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false,
                isSetNewData: false
            };
        case ActionTypes.UNSUBSCRIBE_REQUEST:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
                hasError: false,
                isSetNewData: false
            };
        case ActionTypes.UNSUBSCRIBE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                hasError: false,
                isSetNewData: true
            };
        case ActionTypes.UNSUBSCRIBE_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false,
                isSetNewData: false
            };
        default:
            return state;
    }
};
