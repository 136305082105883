import styled from 'styled-components';
import { ColorName } from "../../Variables";
import { VerticalContainer, HorizontalContainer } from "../CommonLayout";

const TableWrapper = styled(VerticalContainer)`
    padding: 50px;
    border-radius: 25px;
    border: 2px solid ${ColorName.gallery};
    background: ${ColorName.white};
    & .d-none {
        display: none;
    }
    & .mt-0 {
        margin-top: 0;
    }
    &.mt-2 {
        margin-top: 2em;
    }
    &.fundingApplicationTableOverview{
        & th{
           background: ${ColorName.chathamsBlueDark};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
        }
        & th:first-child{
            text-align: left;
            padding: 0px;
            width: 40%;
        }
        & tr{
            & td{
                border: none;
            }
            & td:first-child{
                text-align: left;
                padding: 0px;
            }
        }
    }
`;

const TableContainer = styled.table`
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: 1em;
    width: 100%;
`;

const TableColumn = styled.thead`
    padding: 20px;
`;

const ColumnItem = styled.th`
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    &:first-child{
        padding-left: 20px;
        width: 140px;
        max-width: 140px;
    }
    padding: 0 5px;
    color: ${ColorName.chathamsBlueDark};
    &.text-left {
        text-align: left;
    }
    &.text-right{
        text-align: right;
    }
    &.status{
        width: 160px;
    }
    &.for-email{
        max-width: 340px;
        width: 340px;
    }
    &.for-action{
        max-width: 100px;
    }
`;

const TableBody = styled.tbody`
`;

const TableTitle = styled.div`
    font-size: 23px;
    font-weight: 600;
    text-transform: uppercase;
    i {
        cursor: pointer;
    }
`;

const TableContentWrapper = styled.div`
    transition: max-height 0.15s;
`;

const Option = styled.div`
    margin-left: auto;
    cursor: pointer;
`;

const ButtonWrapper = styled(HorizontalContainer)`
    justify-content: center;
    align-items: center;
    & .mt-2 {
        margin-top: 2em
    }
`;

export {
    TableContainer,
    TableColumn,
    ColumnItem,
    TableBody,
    TableWrapper,
    TableTitle,
    Option,
    TableContentWrapper,
    ButtonWrapper
};
