import styled from 'styled-components';
import { ColorName } from "../../Variables";

export const ContainerCalendar = styled.div`
    position: relative;
    width: 50%;
    min-width: 20em;
    & .hidden{
        display: none;
    }
    & .inputCalendar{
        background: none;
        border: 1px solid ${ColorName.chathamsBlue};
        height: auto;
        width: 24em;
        margin-bottom: 2em;
        border-radius: 0;
        padding: 1em;
        outline: none;
        border-radius: 5px;
        margin-top: 0.6em;
    }
    & .dropDownCalendar{
        position: absolute;
        z-index: 9999;
    }
`;
