import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  Wrapper,
  ProgressBar,
  WrapperQuestionnaire,
  TitleCategory,
  QuestionnaireTable
} from "./Evaluation.styles";
import {
  BreadCrumb
} from "../../../../components/Common/CommonLayout";
import AdminOverViewActions from '../../../../actions/AdminOverView';
import Button from "../../../../components/Common/Button";

const ApplyApplicationDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.Judge.isLoading);
  const evaluationQuestionnaire = useSelector((state) => state.AdminOverView.evaluationQuestionnaire);
  const [dataQuestionnaire, setDataQuestionnaire] = useState([]);
  const newQuestion = {
    title: "",
    isImportant: false,
    max_score: ""
  };

  useEffect(() => {
    dispatch(AdminOverViewActions.getEvaluationQuestionnaire());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataQuestionnaire(evaluationQuestionnaire);
  }, [evaluationQuestionnaire]);

  const onChangeMaxScore = (e, index, mainIndex) => {
    const changeData = [...dataQuestionnaire];
    changeData[mainIndex].evaluationQuestions[index].max_score = e.target.value;
    setDataQuestionnaire(changeData);
  };

  const onChangeImportant = (e, index, mainIndex) => {
    const changeData = [...dataQuestionnaire];
    changeData[mainIndex].evaluationQuestions[index].isImportant = e.target.checked;
    setDataQuestionnaire(changeData);
  };

  const changeQuestion = (e, index, mainIndex) => {
    const changeData = [...dataQuestionnaire];
    changeData[mainIndex].evaluationQuestions[index].title = e.target.value;
    setDataQuestionnaire(changeData);
  };

  const addQuestionforSection = (mainIndex) => {
    const changeData = [...dataQuestionnaire];
    changeData[mainIndex].evaluationQuestions.push(newQuestion);
    setDataQuestionnaire(changeData);
  };

  const renderQuestionnaire = (evaluationQuestions, mainIndex) => {
    const renderEvaluationQuestions = _.map(evaluationQuestions, (question, index) => {
      return (
        <tbody key={index}>
          <tr>
            <td>{`${(index + 1 + 9).toString(36).toLocaleLowerCase()}.`}</td>
            <td className="titleQuestion"><textarea value={question.title} onChange={(e) => changeQuestion(e, index, mainIndex)} /></td>
            <td><input type="number" value={question.max_score} onChange={(e) => onChangeMaxScore(e, index, mainIndex)} /></td>
            <td><input type="checkBox" checked={question.isImportant} onChange={(e) => onChangeImportant(e, index, mainIndex)} /></td>
          </tr>
        </tbody>
      );
    });
    return renderEvaluationQuestions;
  };

  const renderEvaluation = () => {
    const render = _.map(dataQuestionnaire, (item, index) => {
      return (
        <WrapperQuestionnaire key={index}>
          <TitleCategory className="forQuestionnaire">
            <div className="index">
              {item.index}
              .
              {" "}
            </div>
            <div>{item.title}</div>
          </TitleCategory>
          <QuestionnaireTable>
            <thead>
              <tr>
                <th />
                <th>{t("general.Questions")}</th>
                <th>{t("general.Max Score")}</th>
                <th>{t("general.Important")}</th>
              </tr>
            </thead>
            {renderQuestionnaire(item.evaluationQuestions, index)}
            <tfoot>
              <tr>
                <td>
                  <Button
                    color="white"
                    background="gradient"
                    title={t("general.Add New")}
                    height="35px"
                    width="9em"
                    margin="1em 1.5em 0 0"
                    padding='1em'
                    onClick={() => addQuestionforSection(index)}
                  />
                </td>
              </tr>
            </tfoot>
          </QuestionnaireTable>
        </WrapperQuestionnaire>
      );
    });
    return render;
  };

  return (
    <DefaultLayout
      userRole='admin'
      isLoading={isLoading}
      page="applications"
      content={(
        <Wrapper>
          <BreadCrumb>
            Evaluation
          </BreadCrumb>
          <ProgressBar className="mb-2">
            {renderEvaluation()}
          </ProgressBar>
        </Wrapper>
      )}
    />
  );
};

export default ApplyApplicationDetails;
