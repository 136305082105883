import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    BodyContainer
} from "./Users.styles";
import {
    BreadCrumb,
    VerticalContainer
} from "../../../../components/Common/CommonLayout";
import NavigationBar from "../../../../components/Layout/PortalTemplate/NavigationBar";
import RoundedTable from "../../../../components/Common/RoundedTable";
import AdminOverViewAction from '../../../../actions/AdminOverView';
import CONSTANTS from "../../../../constants/Constants";
import Pagination from "../../../../components/Common/NewPagination";
const Users = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.AdminOverView.isLoading);
    const isLoadingExport = useSelector((state) => state.Export.isLoading);
    const [selectedTab, setSelectedTab] = useState("");
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const listOptions = {
        1: "view",
        3: "Export Information",
        2: "Send Message"
    };
    const listUsers = useSelector((state) => state.AdminOverView.listUsers);
    const filter = useSelector((state) => state.AdminOverView.filter);
    const columns = ['type', "user name", "email", 'account status', 'action'];

    const selectedTabPanel = (tab) => {
        setPage(1);
        setSelectedTab(tab);
    };

    useEffect(() => {
        const data = {
            page,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            filter: selectedTab
        };
        dispatch(AdminOverViewAction.getListUsers(data));
        setPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    useEffect(() => {
        setPage(listUsers.page);
        setLastPage(listUsers.lastPage);
    }, [listUsers]);

    const clickPageOrLink = (currentPage) => {
        const data = {
            page: currentPage,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            filter: selectedTab
        };
        dispatch(AdminOverViewAction.getListUsers(data));
    };

    const callBackPrev = () => {
        setPage(page - 1);
        clickPageOrLink(page - 1);
    };

    const callBackNext = () => {
        setPage(page + 1);
        clickPageOrLink(page + 1);
    };

    const pageOnclick = (e) => {
        clickPageOrLink(_.parseInt(e));
    };

    const _renderPagination = () => {
        return (
            <Pagination page={page} lastPage={lastPage || 1} callBackPrev={callBackPrev} callBackNext={callBackNext} pageOnclick={pageOnclick} />
        );
    };

    useEffect(() => {
        const data = {
            page,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            filter: filter || ""
        };
        dispatch(AdminOverViewAction.getListUsers(data));
        if (filter) setSelectedTab(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            dispatch(AdminOverViewAction.resetFilter());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DefaultLayout
            userRole='limited-admin'
            isLoading={isLoading || isLoadingExport}
            page="users"
            content={(
                <BodyContainer>
                    <BreadCrumb>
                        {t("usersManagement.Users Management")}
                    </BreadCrumb>
                    <VerticalContainer className="mb-2">
                        <NavigationBar type='limited-admin' selectedTab={selectedTabPanel} defaultTab={selectedTab} />
                        <RoundedTable
                            initialData={listUsers.data}
                            columns={columns}
                            hideTitleHeader
                            tableType={CONSTANTS.TABLE_TYPE.USER}
                            showNumberItems={10}
                            listOptions={listOptions}
                        />
                        {_renderPagination()}
                    </VerticalContainer>
                </BodyContainer>
            )}
        />
    );
};

Users.propTypes = {

};

export default Users;
