import styled from 'styled-components';
import { ColorName } from "../../Variables";

const ButtonDefault = styled.button`
    background: ${(props) => props.background || ColorName.white};
    color: ${(props) => props.color || ColorName.chathamsBlueDark};
    border: ${(props) => props.border || `1.5px solid ${ColorName.chathamsBlueDark}`};
    cursor: pointer;
    outLine: none;
    padding: 8px 15px;
    height: fit-content;
    width: ${(props) => props.width || 'auto'};
    font-weight: 600;
`;


export {
    ButtonDefault
};