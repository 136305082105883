import React, {
    useState, useRef, useEffect
} from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import ImageEditor from "../../../../components/Common/ImageEditor";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput,
    InputContainer,
    GeneralButtonLanguage
} from "./Sponsor.styles";
import {
    PageTitle,
    LabelSlide
} from "../../../../components/Common/CommonLayout";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import SponsorActions from "../../../../actions/Sponsor";
import { ColorName } from "../../../../components/Variables";
import Utils from "../../../../libs/Utils";
import Validation from "../../../../libs/Validation";
import history from "../../../../history";
import Constants from "../../../../constants/Constants";

const Edit = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const isLoading = useSelector((state) => state.Sponsor.isLoading);
    const [sponsorID, setSponsorID] = useState(null);
    const dataSponsor = useSelector((state) => state.Sponsor.dataSponsor);
    const fileInput = useRef(null);
    const [formData, setFormData] = useState({
        title: {
            en: "",
            al: ""
        }
    });
    const [fileUrl, setFileUrl] = useState("");
    const [fileData, setFileData] = useState("");
    const [type, setType] = useState(Constants.SPONSOR_TYPE.ENTREPRENEUR);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [isValidateCORS, setIsValidateCORS] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (!location.state.id) return history.push("/admin/sponsors");
        const data = {
        };
        dispatch(SponsorActions.getSponsorById(data, location.state.id));
        setSponsorID(location.state.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataSponsor) {
            setType(dataSponsor.type);
            setIsmultipleLanguage(dataSponsor.is_support_mul_lang);
        }
    }, [dataSponsor]);

    useEffect(() => {
        if (!_.isEmpty(dataSponsor)) {
            if (dataSponsor.is_support_mul_lang) {
                if (i18n.language === Constants.LANGUAGE_CODE.AL) {
                    setLanguage(Constants.LANGUAGE_CODE.AL);
                } else {
                    setLanguage(Constants.LANGUAGE_CODE.EN);
                }
            } else if (dataSponsor.title_json[i18n.language]) {
                setLanguage(i18n.language);
            } else if (i18n.language === Constants.LANGUAGE_CODE.EN) {
                setLanguage(Constants.LANGUAGE_CODE.AL);
            } else {
                setLanguage(Constants.LANGUAGE_CODE.EN);
            }

            const data = { ...formData };
            data.title.en = dataSponsor.title_json.en;
            data.title.al = dataSponsor.title_json.al;
            setFileUrl(dataSponsor.url_img_aws);
            Utils.checkImageCORS(dataSponsor.url_img_aws).then((response) => {
                setIsValidateCORS(true);
            }).catch((error) => {
                setIsValidateCORS(false);
            });
            setIsmultipleLanguage(dataSponsor.is_support_mul_lang);
            setFormData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSponsor]);

    const validateMutilpleLanguage = () => {
        const validationRules = [
            {
                name: `${t("general.Title")} ${t("general.English")}`,
                value: formData.title.en,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: `${t("general.Title")} ${t("general.Albania")}`,
                value: formData.title.al,
                rule: ["required", { min: 10, max: 100 }]
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const validateForLanguage = (lang) => {
        const validationRules = [
            {
                name: t("general.Title"),
                value: formData.title[lang],
                rule: ["required", { min: 10, max: 100 }]
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const paramPostSingleLanguage = (param) => {
        if (language === Constants.LANGUAGE_CODE.EN) {
            param.append('title', JSON.stringify({ en: formData.title.en }));
        } else {
            param.append('title', JSON.stringify({ al: formData.title.al }));
        }
    };

    const paramPostMultipleLanguage = (param) => {
        param.append('title', JSON.stringify({ en: formData.title.en, al: formData.title.al }));
    };

    const editSponsor = () => {
        let flag = true;
        if (isMultipleLanguage) {
            flag = validateMutilpleLanguage();
        } else if (!validateForLanguage(language)) return;
        if (flag) {
            const param = new FormData();
            if (isMultipleLanguage) paramPostMultipleLanguage(param);
            if (!isMultipleLanguage) paramPostSingleLanguage(param);
            param.append('is_support_mul_lang', isMultipleLanguage);
            param.append('type', type);
            if (fileData !== "") param.append('img', fileData);
            dispatch(SponsorActions.updateSponsor(param, sponsorID));
        }
    };

    const handleFileChange = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        const file = e.target.files[0];
        setFileData(file);
        setFileUrl(url);
        setIsValidateCORS(true);
    };

    const handleChange = (value, nameInput) => {
        const data = { ...formData };
        data[nameInput][language] = value;
        setFormData(data);
    };

    const deleteFileAttachment = () => {
        setFileData("");
        setFileUrl("");
        fileInput.current.value = "";
    };

    const onEditedImageCallback = async ({ callbackType, croppedImage }) => {
        switch (callbackType) {
            case 'image':
                setFileUrl(croppedImage);
                const newImage = await Utils.convertDataURLToFile(croppedImage, 'sponsor_image.jpg');
                setFileData(newImage);
                setShowImageEditor(false);
                break;
            case 'close':
                setShowImageEditor(false);
                break;
            default:
                break;
        }
    };
    return (
        <DefaultLayout
            userRole='admin'
            isLoading={false}
            page="sponsor"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("sponsor.Edit Sponsor")}
                    </PageTitle>
                    <ProgressBar className="mb-2">
                        <WrapperInput>
                            <TitleInput>
                                {t("general.Title")}
                                <div className="language">
                                    <LabelSlide className="switch">
                                        <input type="checkbox" checked={!!isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} />
                                        <span className="slider round" />
                                    </LabelSlide>
                                    {isMultipleLanguage
                                        && (
                                            <>
                                                <GeneralButtonLanguage
                                                    type="button"
                                                    className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                                    value={t("general.English")}
                                                    onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                                />
                                                <GeneralButtonLanguage
                                                    type="button"
                                                    className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                                    value={t("general.Albanian")}
                                                    onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                                />
                                            </>
                                        )
                                    }
                                    {!isMultipleLanguage
                                        && (
                                            <>
                                                <GeneralButtonLanguage
                                                    type="button"
                                                    className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                                    value={t("general.English")}
                                                    onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                                />
                                                <GeneralButtonLanguage
                                                    type="button"
                                                    className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                                    value={t("general.Albanian")}
                                                    onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                                />
                                            </>
                                        )
                                    }

                                </div>
                            </TitleInput>
                            <InputContainer
                                value={formData.title[language] || ""}
                                onChange={(event) => handleChange(event.target.value, "title")}
                            />
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>{t("general.Type")}</TitleInput>
                            <select onChange={(e) => setType(e.target.value)} value={type}>
                                <option value={Constants.SPONSOR_TYPE.ENTREPRENEUR}>
                                    {t("general.Entrepreneurs")}
                                </option>
                                <option value={Constants.SPONSOR_TYPE.INVESTOR}>
                                    {t("general.Investors")}
                                </option>
                                <option value={Constants.SPONSOR_TYPE.CONSULTANT}>
                                    {t("general.Consultants")}
                                </option>
                            </select>
                        </WrapperInput>
                        <WrapperInput className="container-upload">
                            <input
                                ref={fileInput}
                                id='uploadAvatar'
                                type="file"
                                accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                                onChange={(e) => handleFileChange(e)}
                            />
                            {t("sponsor.Featured Image")}
                            {' '}
                            <i className="fas fa-upload" onClick={() => fileInput.current.click()} />
                            {fileUrl
                                && (
                                    <div className="display-image-file">
                                        <img src={fileUrl} alt="Featured" />
                                        <div className="remove" onClick={() => deleteFileAttachment()}>X</div>
                                    </div>
                                )
                            }
                        </WrapperInput>
                        <div className="container-button">
                            <GeneralButtonLanguage
                                type="button"
                                className="for-more"
                                background={ColorName.chathamsBlue}
                                value={t("general.Save")}
                                onClick={() => editSponsor()}
                            />
                            <GeneralButtonLanguage
                                type="button"
                                className={(fileUrl && isValidateCORS) ? "active" : "active disable"}
                                value={t("general.Edit Image")}
                                onClick={() => setShowImageEditor(true)}
                            />
                        </div>
                    </ProgressBar>
                    {(fileUrl && isValidateCORS) && (
                        <ImageEditor imageSrc={fileUrl} showImageEditor={showImageEditor} croppedImageEditorCallback={(data) => onEditedImageCallback(data)} />
                    )}
                    <span>
                        *Note: &nbsp;
                        {t("general.Some images are uneditable, please upload new image to edit")}
                    </span>
                </BodyContainer>
            )}
        />
    );
};

Edit.propTypes = {

};

export default Edit;
