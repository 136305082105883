import styled from "styled-components";
import {
  VerticalContainer,
  HorizontalContainer,
} from "../../../Common/CommonLayout";
import { ColorName } from "../../../Variables";
const BodyContainer = styled(VerticalContainer)`
  flex-direction: column;
  border: 1px solid #f0f0f0;
  &.view-chat {
  }
  & .container-filter {
    padding: 5px 15px;
    background: #ffffff;
    border-radius: 25px;
    color: black;
    & input {
      border: none;
      outline: none;
    }
  }
  & .container-filter-dropdown {
    padding: 5px 15px;
    background: #ffffff;
    color: black;
    & input {
      border: none;
      outline: none;
      width: 95%;
    }
  }
  & .container-body-list {
    max-height: 900px;
    overflow-y: auto;
  }
  & .cursor {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  background: #ffffff;
  width: 100%;
  &.admin-manage {
    width: 350px !important;
  }
  &.listUsers {
    width: 280px;
    & .notFound {
      display: flex;
      place-content: center;
    }
    & .top-list-user {
      background: ${ColorName.kashmirBlue};
      color: white;
      display: flex;
      justify-content: space-between;
      padding: 1em;
      height: 1.5em;
    }
    background: ${ColorName.botticelli};
  }
  height: 915px;
  & .messages {
    display: flex;
    padding: 0px 1em;
    margin: 0px;
    overflow-y: auto;
    flex-direction: column;
  }
  & .wrapper-edit {
    position: relative;
  }
  & .admin-manage-message {
    max-height: 885px;
    min-height: 885px;
    overflow-y: scroll;
  }
  & .messageWrapper {
    & .sent-timestamp {
      color: gray !important;
      font-size: 10px;
      padding: 5px 0;
    }
    & .scroll-over {
      padding: 1em;
    }
    flex-direction: column;
    color: ${ColorName.chathamsBlue};
    font-weight: 600;
    & .container-img {
      display: flex;
      align-items: center;
    }
    & .file-attach {
      & i {
        &.right {
          margin-left: 5px;
        }
        &.left {
          margin-right: 5px;
        }
      }
    }
    & .userReceive {
      display: flex;
      width: 100%;
      flex-flow: row-reverse;
      & img {
        margin-left: 10px;
        margin-right: 10px;
      }
      & .content-message {
        margin-right: 47px;
        color: ${ColorName.white};
        background: ${ColorName.chathamsBlueDark};
        border-radius: 0.5em;
        padding: 0.3em 1em;
        font-weight: 300;
      }
      align-items: flex-end;
      flex-direction: column;
      & span {
        margin-right: 47px;
        color: ${ColorName.fern};
      }
      & .file-attach {
        margin-right: 40px;
      }
    }
    & .userSend {
      & .file-attach {
        margin-left: 40px;
      }
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      & .content-message {
        margin-left: 47px;
        background: ${ColorName.botticelli};
        color: ${ColorName.black};
        border-radius: 0.5em;
        padding: 0.3em 1em;
        font-weight: 300;
      }
      & span {
        margin-left: 47px;
        color: ${ColorName.bostonBlue};
      }
    }
    display: flex;
    flex: wrap;
    padding: 10px 0 20px 0;
    & img {
      margin-right: 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
`;

const Username = styled.div`
  padding-left: 1em;
`;

const MessageHorizontalContainer = styled(HorizontalContainer)`
  max-height: 915px;
  & .container-with-title {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const PageTitle = styled.div`
  font-weight: 300;
  font-size: 3em;
  color: ${ColorName.chathamsBlue};
`;

const MessageContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  & .messageWrapper {
    color: ${ColorName.chathamsBlue};
    font-weight: 600;
    & .container-img {
      display: flex;
      align-items: center;
    }
    & .file-attach {
      & i {
        &.right {
          margin-left: 5px;
        }
        &.left {
          margin-right: 5px;
        }
      }
    }
    & .userReceive {
      display: flex;
      width: 100%;
      flex-flow: row-reverse;
      & img {
        margin-left: 10px;
        margin-right: 10px;
      }
      & .content-message {
        margin-right: 47px;
        color: ${ColorName.white};
        background: ${ColorName.chathamsBlueDark};
        border-radius: 0.5em;
        padding: 0.3em 1em;
        font-weight: 300;
      }
      align-items: flex-end;
      flex-direction: column;
      & span {
        color: ${ColorName.fern};
      }
      & .file-attach {
        margin-right: 40px;
      }
    }
    & .userSend {
      & .file-attach {
        margin-left: 40px;
      }
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      & .content-message {
        margin-left: 47px;
        background: ${ColorName.botticelli};
        color: ${ColorName.black};
        border-radius: 0.5em;
        padding: 0.3em 1em;
        font-weight: 300;
      }
      & span {
        color: ${ColorName.bostonBlue};
      }
    }
    display: flex;
    flex: wrap;
    padding: 10px 0 20px 0;
    & img {
      margin-right: 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  & form {
    width: 100%;
    margin-top: auto;
    padding-bottom: 0.5em;
    & textarea {
      background: ${ColorName.concrete};
      outline: none;
      resize: none;
      display: flex;
      flex: 1;
      border: none;
      font-family: "Poppins", sans-serif;
      height: 20px;
      padding: 0.5em 1em;
      border-radius: 2em;
      &::placeholder {
        color: ${ColorName.grayDarker};
      }
    }
    & .textwrapper {
      position: relative;
      display: flex;
      align-items: center;

      & .attachment-btn {
        color: ${ColorName.chathamsBlueDark};
      }

      & .buttonSend {
        background: ${ColorName.white};
        height: 100%;
        border: none;
        outline: none;
        cursor: pointer;
        color: ${ColorName.chathamsBlueDark};
        font-weight: 500;
      }
      & i {
        display: flex;
        align-items: center;
        padding: 10px;
      }
      & #uploadAvatar {
        display: none;
      }
      & .display-name-file {
        position: absolute;
        bottom: 100%;
        right: 0;
        text-align: right;
        padding: 5px;
        background: #000000c5;
        color: white;
        display: flex;
      }
    }
  }
`;

const UserWrapper = styled.div`
  & .icon-delete {
    margin-left: auto;
    color: ${ColorName.chathamsBlue};
    cursor: pointer;
    font-size: 0.5em;
  }
  &.dropdown {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
    margin: 0;
  }
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 10px;
  margin: 5px;
  cursor: pointer;
  color: ${ColorName.chathamsBlue};
  & img {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  &.selectChat {
    background: ${ColorName.white};
    cursor: pointer;
  }
  &.noneSelect {
    cursor: pointer;
  }
  & .delete-chanel {
    color: ${ColorName.chathamsBlue};
    position: absolute;
    right: 0;
  }
`;

const UsernameChatbox = styled.div`
  padding-left: ${(props) => (props.direction === "right" ? "0.5em" : "0")};
  padding-right: ${(props) => (props.direction === "left" ? "0.5em" : "0")};
`;

export {
  BodyContainer,
  HorizontalContainer,
  MessageContainer,
  UserWrapper,
  MessageHorizontalContainer,
  PageTitle,
  Username,
  UsernameChatbox,
};
