import styled from 'styled-components';
import { VerticalContainer, HorizontalContainer } from "../../../Common/CommonLayout";
import { ColorName } from "../../../Variables";

const SideBarContainer = styled(VerticalContainer)`
    border-top: none;
    flex: 1;
    background: ${ColorName.fruitSaladLight};
    width: ${(props) => props.width || ''};
    border-bottom-right-radius: 10px;
    min-width: 260px;
`;

const ContentSideBar = styled.div`
`;

const Item = styled(HorizontalContainer)`
    & .ticker{
        display: flex;
        align-items: center;
        & span{
            font-size: 12px;
            background: white;
            color: ${ColorName.chathamsBlue};
            padding: 5px;
            border-radius: 50%;
            margin: 0;
            width: 15px;
            display: flex;
            align-items: center;
            place-content: center;
        }
    }
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 11px 0;
    cursor: pointer;
    font-weight: 600;
    color: white;
    height: 17px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
    i {
        margin-left: 0.2em;
        margin-right: 0.6em;
        width: 3px;
        height: 1.1em;
        background: white;
    }
    span {
        margin: 0 0.5em 0 2em;
    }
    & .hide-icon {
        visibility: hidden;
    }
    &.selected {
        background: ${ColorName.appleLight};
        color: white;
    }
    &:hover {
        background: ${ColorName.appleLight};
        color: white;
    }
    &:last-child {
        border-bottom-right-radius: 10px;
    }
`;

const Logout = styled(HorizontalContainer)`
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    font-weight: 200;
    max-height: 50px;
    &:hover {
        background: linear-gradient(178.43deg, ${ColorName.tickleMePink} -13.56%, ${ColorName.persianBlue} 158.3%);
        color: white;
    }
    flex: 1;
    cursor: pointer;
`;

export {
    ContentSideBar,
    SideBarContainer,
    Item,
    Logout
};
