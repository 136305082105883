import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  Wrapper,
  ProgressBar,
  WrapperTitle,
  TitleCategory,
} from "./Judge.styles";
import {
  BreadCrumb,
  VerticalContainer,
  Flex
} from "../../../../components/Common/CommonLayout";
import JudgeActions from "../../../../actions/Judge";
import FundingApplicationActions from "../../../../actions/FundingApplication";
import history from "../../../../history";
import Utils from "../../../../libs/Utils";
import CONSTANTS from "../../../../constants/Constants";
import Button from "../../../../components/Common/Button";
import { ColorName } from "../../../../components/Variables";
import moment from 'moment';

const ApplyApplicationDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.Judge.isLoading);
  const applyApplication = useSelector((state) => state.Judge.applyById);
  const applyId = useSelector((state) => state.AdminOverView.applyId);
  const [details, setDetails] = useState(false);
  const [status, setStatus] = useState(false);

  const convertDataCategory = () => {
    if (!_.isEmpty(applyApplication)) {
      const { questionCategories } = {
        ...applyApplication.fundingApplication.questionnaire,
      };
      const data = {};
      _.map(questionCategories, (child, index) => {
        if (!_.has(data, index)) data[index] = [];
        _.map(child.questionDetails, (detail) => {
          detail.title_category = child.title;
          data[index].push(detail);
        });
      });
      return data;
    }
    return false;
  };

  const convertDataAnwserForQuestion = (categories) => {
    const applyDetails = { ...applyApplication };
    if (categories) {
      const listQuestion = [];
      _.map(categories, (category) => {
        _.map(category, (cat) => {
          _.map(applyDetails.applyApplicationDetails, (child) => {
            if (child.question_detail_id === cat.id) {
              cat.awnser = child.answer;
              listQuestion.push(cat);
            }
          });
        });
      });
      const grouped = _.groupBy(listQuestion, (child) => {
        return child.title_category;
      });
      return grouped;
    }
    return false;
  };

  useEffect(() => {
    if (applyId === 0) return history.push("/admin/funding-applications");
    const data = convertDataCategory();
    const dataRender = convertDataAnwserForQuestion(data);
    setDetails(dataRender);
    setStatus(applyApplication.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyApplication]);

  const renderQuestionAndAnwser = (category) => {
    const render = _.map(category, (child, index) => {
      return (
        <WrapperTitle key={index} marginTop="1em" marginLeft="1em">
          <div className="fonts-status">{child.title}</div>
          {child.question_type === 1 && (
            <div className="style-input style-disabled-div">
              {child.awnser || ""}
            </div>
          )}
          {child.question_type === 2 && (
            <a
              href={applyApplication.file_url_aws}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-file-download fa-3x" />
              {applyApplication.file_url &&
                _.last(applyApplication.file_url.split("/"))}
            </a>
          )}
        </WrapperTitle>
      );
    });
    return render;
  };

  const renderCategory = () => {
    const render = _.map(details, (category, index) => {
      return (
        <div key={index}>
          <TitleCategory>{_.last(category).title_category || ""}</TitleCategory>
          {renderQuestionAndAnwser(category)}
        </div>
      );
    });
    return render;
  };

  const changeStatus = (e) => {
    setStatus(e.target.value);
  };

  const changeStatusApply = () => {
    const data = {
      status: status.toString(),
    };
    if (status !== "0") {
      Utils.popupAlert({
        type: "warning",
        title: t("rounderTable.Do you want to change status"),
        buttons: [t("general.Cancel"), true],
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          if (applyApplication.id) {
            dispatch(
              JudgeActions.changeStatusApplyApplication(
                data,
                applyApplication.id,
                applyApplication.funding_application_id
              )
            );
          } else {
            Utils.popupAlert({
              title: "Oops...",
              text: t("general.Apply application not found"),
              type: "error",
            });
          }
        }
      });
    } else {
      Utils.popupAlert({
        type: "warning",
        title: t("rounderTable.Do you want to delete"),
        buttons: [t("general.Cancel"), true],
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          if (applyApplication.id) {
            dispatch(
              JudgeActions.changeStatusApplyApplication(
                data,
                applyApplication.id,
                applyApplication.funding_application_id
              )
            );
          } else {
            Utils.popupAlert({
              title: "Oops...",
              text: t("general.Apply application not found"),
              type: "error",
            });
          }
        }
      });
    }
  };

  const evaluate = () => {
    history.push("/admin/evaluation-apply");
  };

  const renderDropdownStatus = () => {
    const render = (
      <WrapperTitle className="dropdownStatus">
        <div className="fonts-status">{t("general.Status")}</div>
        <select
          key={applyApplication.status}
          defaultValue={applyApplication.status || ""}
          onChange={(e) => changeStatus(e)}
        >
          <option value={CONSTANTS.APPLY_APPLICATION_STATUS.NOT_APPROVED}>
            {t("fundingApplications.NOT_APPROVED")}
          </option>
          <option value={CONSTANTS.APPLY_APPLICATION_STATUS.SUBMITTED}>
            {t("fundingApplications.SUBMITTED")}
          </option>
          <option value={CONSTANTS.APPLY_APPLICATION_STATUS.IN_REVIEW}>
            {t("fundingApplications.IN REVIEW")}
          </option>
          <option value={CONSTANTS.APPLY_APPLICATION_STATUS.INCOMPLETE}>
            {t("fundingApplications.INCOMPLETE")}
          </option>
          <option value={CONSTANTS.APPLY_APPLICATION_STATUS.APPROVED}>
            {t("fundingApplications.APPROVED")}
          </option>
        </select>
        <div className="btnFoot">
          <Button
            color={ColorName.white}
            background={ColorName.chathamsBlueDark}
            title={t("general.Back")}
            height="35px"
            width="9em"
            margin="1em 1.5em 0 0"
            padding="1em"
            onClick={() => history.push("/admin/funding-application-details")}
          />
          <Button
            color={ColorName.white}
            background={ColorName.chathamsBlueDark}
            title={t("general.Change Status")}
            height="35px"
            width="9em"
            margin="1em 1.5em 0 0"
            padding="1em"
            onClick={() => changeStatusApply()}
          />
          <Button
            color={ColorName.white}
            background={ColorName.chathamsBlueDark}
            title={t("general.Evaluate")}
            height="35px"
            width="9em"
            margin="1em 1.5em 0 0"
            padding="1em"
            onClick={() => evaluate()}
          />
        </div>
      </WrapperTitle>
    );
    return render;
  };

  const _renderListFile = (listFiles) => {
    const render = _.map(listFiles, (child, index) => {
      return (
        <a href={child} target="_blank" rel="noopener noreferrer" key={index}>
          {index && _.last(index.split("/"))}
        </a>
      );
    });
    return render;
  };

  const deleteDocumentWinner = (fileName) => {
    Utils.popupAlert({
      type: "warning",
      title: t("rounderTable.Do you want to delete"),
      buttons: [t("general.Cancel"), true],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        const formData = new FormData();
        formData.append('delete_files', JSON.stringify([fileName]));
        dispatch(FundingApplicationActions.deleteDocumentWinner(applyApplication.id, formData));
      }
    });
  }

  const _renderListFileWinner = (listFiles) => {
    const render = _.map(listFiles, (child, index) => {
      return (
        <Flex key={index}>
          <a href={child} target="_blank" rel="noopener noreferrer">
            {index && _.last(index.split("/"))}
            {_.map(applyApplication.doc_winner_updated_at, (item, key) => {
              if (key === index) return <span key={key}>&nbsp;- {moment(item).format("DD-MM-YYYY hh:mm")} </span>
            })}
          </a>
          <i onClick={() => deleteDocumentWinner(index)} className="fas fa-trash-alt" />
        </Flex>
      );
    });
    return render;
  };

  return (
    <DefaultLayout
      userRole="admin"
      isLoading={isLoading}
      page="fundingApplications"
      content={
        <Wrapper>
          <BreadCrumb>
            {t("fundingApplications.Applications Submitted Details")}
            <br />
            <br />
            {t("fundingApplications.Submitted by")}{" "}
            {_.get(applyApplication, "user.userData.full_name", "")}
          </BreadCrumb>
          <ProgressBar className="mb-2">
            <VerticalContainer>
              {renderCategory()}
              {applyApplication && applyApplication.document_json && (
                <WrapperTitle marginTop="1em" marginLeft="1em">
                  <div className="fonts-status for-multile-files">
                    {t("fundingApplications.Application Form Template")}:
                    {applyApplication.document_json &&
                      _renderListFile(applyApplication.document_json)
                    }
                  </div>
                  <div>
                    {_.isEmpty(applyApplication.document_json) &&
                      t("general.No Data Found")}
                  </div>
                </WrapperTitle>
              )}
              {applyApplication && applyApplication.document_winner_json && (
                <WrapperTitle marginTop="1em" marginLeft="1em">
                  <div className="fonts-status for-multile-files">
                    {t("general.Document for reporting")}:
                    {applyApplication.document_winner_json &&
                      _renderListFileWinner(applyApplication.document_winner_json)}
                  </div>
                  <div>
                    {_.isEmpty(applyApplication.document_winner_json) &&
                      t("general.No Data Found")}
                  </div>
                </WrapperTitle>
              )}
              {applyApplication && renderDropdownStatus()}
            </VerticalContainer>
          </ProgressBar>
        </Wrapper>
      }
    />
  );
};

export default ApplyApplicationDetails;
