import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import Pagination from "../../../../components/Common/NewPagination";
import {
  BodyContainer
} from "./Event.styles";
import {
  PageTitle,
  GeneralButton
} from "../../../../components/Common/CommonLayout";
import RoundedTable from "../../../../components/Common/RoundedTable";
import CONSTANTS from "../../../../constants/Constants";
import EventActions from "../../../../actions/Event";
import { ColorName } from '../../../../components/Variables';
import history from '../../../../history';

const AdminOverview = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const columns = ["image", "title", "date", "type", "location", "status", "action"];
  const listEvent = useSelector((state) => state.Event.events);
  const isLoading = useSelector((state) => state.Event.isLoading);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const listOptions = {
    1: "publish",
    2: "publish & send mail",
    3: "unpublish",
    4: "edit",
    5: "delete"
  };

  useEffect(() => {
    const data = {
      page,
      item_per_page: CONSTANTS.ITEM_PER_PAGE,
      language: i18n.language
    };
    dispatch(EventActions.getAllEvent(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = {
      page,
      item_per_page: CONSTANTS.ITEM_PER_PAGE,
      language: i18n.language
    };
    dispatch(EventActions.getAllEvent(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setPage(listEvent.page);
    setLastPage(listEvent.lastPage);
  }, [listEvent]);

  const clickPageOrLink = (currentPage) => {
    const data = {
      page: currentPage,
      item_per_page: CONSTANTS.ITEM_PER_PAGE,
      language: i18n.language
    };
    dispatch(EventActions.getAllEvent(data));
  };

  const callBackPrev = () => {
    setPage(page - 1);
    clickPageOrLink(page - 1);
  };

  const callBackNext = () => {
    setPage(page + 1);
    clickPageOrLink(page + 1);
  };

  const pageOnclick = (e) => {
    clickPageOrLink(_.parseInt(e));
  };

  const _renderPagination = () => {
    return (
      <Pagination page={page} lastPage={lastPage || 1} callBackPrev={callBackPrev} callBackNext={callBackNext} pageOnclick={pageOnclick} />
    );
  };

  return (
    <DefaultLayout
      userRole='limited-admin'
      isLoading={isLoading}
      page="events"
      content={(
        <BodyContainer>
          <PageTitle>
            {t("general.Events")}
          </PageTitle>
          <div className="container-button">
            <GeneralButton
              type="button"
              className="for-more"
              background={ColorName.chathamsBlue}
              value={t("event.Add Event")}
              onClick={() => history.push("/limited-admin/add-event")}
            />
          </div>
          <RoundedTable
            initialData={listEvent.data}
            columns={columns}
            tableTitle={t("general.Events")}
            tableType={CONSTANTS.TABLE_TYPE.EVENT}
            className="mt-2"
            showNumberItems={CONSTANTS.ITEM_PER_PAGE}
            listOptions={listOptions}
          />
          {_renderPagination()}
        </BodyContainer>
      )}
    />
  );
};

AdminOverview.propTypes = {

};

export default AdminOverview;
