import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
    BodyContainer
} from "./TestimonialSetting.styles";
import {
    PageTitle,
    GeneralButton
} from "../../../../../components/Common/CommonLayout";
import RoundedTable from "../../../../../components/Common/RoundedTable";
import Pagination from "../../../../../components/Common/NewPagination";
import CONSTANTS from "../../../../../constants/Constants";
import GuestTestimonialSettingActions from "../../../../../actions/GuestTestimonialSetting";
import { ColorName } from '../../../../../components/Variables';
import history from '../../../../../history';

const TestimonialSetting = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const columns = ["name", "image", "title", "description", 'action'];
    const testimonialList = useSelector((state) => state.GuestTestimonialSetting.testimonials);
    const isLoading = useSelector((state) => state.GuestTestimonialSetting.isLoading);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const listOptions = {
        1: "edit",
        2: "delete"
    };
    useEffect(() => {
        const data = {
            page: 1,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language: i18n.language
        };
        dispatch(GuestTestimonialSettingActions.fetchTestimonials(data));
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPage(testimonialList.page);
        setLastPage(testimonialList.lastPage);
    }, [testimonialList]);

    const clickPageOrLink = (currentPage) => {
        const data = {
            page: currentPage,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language: i18n.language
        };
        dispatch(GuestTestimonialSettingActions.fetchTestimonials(data));
    };

    const callBackPrev = () => {
        setPage(page - 1);
        clickPageOrLink(page - 1);
    };

    const callBackNext = () => {
        setPage(page + 1);
        clickPageOrLink(page + 1);
    };

    const pageOnclick = (e) => {
        clickPageOrLink(_.parseInt(e));
    };

    const _renderPagination = () => {
        return (
            <Pagination page={page} lastPage={lastPage || 1} callBackPrev={callBackPrev} callBackNext={callBackNext} pageOnclick={pageOnclick} />
        );
    };

    return (
        <DefaultLayout
            userRole='limited-admin'
            isLoading={isLoading}
            page="testimonial-setting"
            content={(
                <BodyContainer>
                    <PageTitle>
                        {t("testimonial.Testimonials")}
                    </PageTitle>
                    <div className="container-button">
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("testimonial.Add Testimonial")}
                            onClick={() => history.push("/limited-admin/add-testimonial")}
                        />
                    </div>
                    <RoundedTable
                        initialData={testimonialList.data || []}
                        columns={columns}
                        tableTitle={t("testimonial.Testimonial")}
                        tableType={CONSTANTS.TABLE_TYPE.TESTIMONIAL}
                        className="mt-2"
                        showNumberItems={CONSTANTS.ITEM_PER_PAGE}
                        listOptions={listOptions}
                    />
                    {_renderPagination()}
                </BodyContainer>
            )}
        />
    );
};

TestimonialSetting.propTypes = {

};

export default TestimonialSetting;
