import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import {
    OptionDropDown,
    DropDownLanguageHeader,
    ContainerDropDown
} from "./DropDownLanguage.styles";
import useOutsideClick from "./ClickOutside";
import Utils from "../../../libs/Utils";
import uk from "./img/uk.png";
import albania from "./img/albania.png";
const Dropdown = (props) => {
    const { flag } = props;
    const { i18n, t } = useTranslation();
    const [isShowDropDown, setIsShowDropDown] = useState(false);
    const refLanguage = useRef();

    const toggle = () => {
        setIsShowDropDown(false);
    };
    useOutsideClick(refLanguage, () => {
        toggle();
    });

    const isshowDropDownLang = () => {
        setIsShowDropDown(!isShowDropDown);
    };

    const changeLanguage = (lang) => {
        Utils.setCurrentLanguage(lang);
        i18n.changeLanguage(lang);
        window.location.reload(true);
    };
    return (
        <ContainerDropDown onClick={() => isshowDropDownLang()} ref={refLanguage}>
            {(Utils.getCurrentLanguage() === "en" || _.isUndefined(Utils.getCurrentLanguage()))
                && (
                    <div className="display-language">
                        {flag && <img src={uk} alt="" />}
                        <span>{t("general.English")}</span>
                        <i className="fas fa-caret-down" />
                    </div>
                )
            }
            {Utils.getCurrentLanguage() === "al"
                && (
                    <div className="display-language">
                        {flag && <img src={albania} alt="" />}
                        <span>{t("general.Albanian")}</span>
                        <i className="fas fa-caret-down" />
                    </div>
                )
            }
            {isShowDropDown && (
                <DropDownLanguageHeader>
                    <OptionDropDown onClick={() => changeLanguage("en")}>
                        <div>
                            {flag && <img src={uk} alt="" />}
                            {t("general.English")}
                        </div>
                    </OptionDropDown>
                    <OptionDropDown onClick={() => changeLanguage("al")}>
                        <div>
                            {flag && <img src={albania} alt="" />}
                            {t("general.Albanian")}
                        </div>
                    </OptionDropDown>
                </DropDownLanguageHeader>
            )
            }

        </ContainerDropDown>
    );
};

Dropdown.propTypes = {
    callbackSetCurrentLanguage: PropTypes.func,
    flag: PropTypes.bool
};

export default Dropdown;
