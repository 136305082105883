import Api from "./Base";

export function addNews(data) {
    return Api.postFormData("/news", data);
}

export function updateNews(data, id) {
    return Api.postFormData(`/news/${id}/update`, data);
}

export function sendMailNews(data) {
    return Api.postFormData('/news/sendMail', data);
}

export function deleteNews(id) {
    return Api.post(`/news/${id}/delete`);
}

export function getAllNews(data) {
    return Api.get("/news_fetch", data);
}

export function getNewsById(data, id) {
    return Api.get(`/news/${id}`, data);
}

export function getNewsSlide(dataWithLanguage) {
    return Api.get("/news_get_testimonial", dataWithLanguage);
}
