import React, {
    useState,
    useRef,
    useEffect
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import JoditEditor from "jodit-react";
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput,
    InputContainer
} from "./ApplySetting.styles";
import {
    PageTitle,
    GeneralButton,
    FlexGridSixth,
    GridCol
} from "../../../../../components/Common/CommonLayout";
import {
    SwitchButton,
    SwitchLanguageButton,
    EnableMultipleLanguageButtonContainer
} from "../../../../../components/Common/EnableMultipleLanguageButton";
import Loading from "../../../../../components/Layout/CommonLayoutPart/Loading";
import GuestAnnouncementSetting from "../../../../../actions/AnnouncementSetting";
import { ColorName } from "../../../../../components/Variables";
import Utils from "../../../../../libs/Utils";
import Validation from "../../../../../libs/Validation";
import Constants from "../../../../../constants/Constants";

const Announcement = () => {
    const { t, i18n } = useTranslation();
    const editorAl = useRef(null);
    const [contentEn, setContentEn] = useState('');
    const [contentAl, setContentAl] = useState('');
    const [form, setForm] = useState({
        en: {
            title: "",
            content: ""
        },
        al: {
            title: "",
            content: ""
        },
        link: ""
    });
    const dispatch = useDispatch();
    const editor = useRef([React.createRef(), React.createRef(), React.createRef()]);
    const isLoading = useSelector(
        (state) => state.GuestAnnouncementSetting.isLoading
    );
    const guestAnnouncementSetting = useSelector(
        (state) => state.GuestAnnouncementSetting.announcementData
    );

    useEffect(() => {
        setFileUrl("");
        const data = {
            type: "announcement_setting"
        };
        dispatch(GuestAnnouncementSetting.getAnnouncementSettingData(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [fileUrl, setFileUrl] = useState("");
    const [fileData, setFileData] = useState("");
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());

    const fileInput = useRef(null);

    useEffect(() => {
        if (guestAnnouncementSetting) {
            if (guestAnnouncementSetting.is_support_mul_lang) {
                if (i18n.language === Constants.LANGUAGE_CODE.AL) {
                    setLanguage(Constants.LANGUAGE_CODE.AL);
                } else {
                    setLanguage(Constants.LANGUAGE_CODE.EN);
                }
            } else if (guestAnnouncementSetting.data_json.form[i18n.language].title) {
                setLanguage(i18n.language);
            } else if (i18n.language === Constants.LANGUAGE_CODE.EN) {
                setLanguage(Constants.LANGUAGE_CODE.AL);
            } else {
                setLanguage(Constants.LANGUAGE_CODE.EN);
            }
            const data = { ...form };
            data.en.title = guestAnnouncementSetting.data_json.form.en.title;
            data.al.title = guestAnnouncementSetting.data_json.form.al.title;
            data.link = guestAnnouncementSetting.data_json.form.link;
            setContentAl(guestAnnouncementSetting.data_json.form.al.content);
            setContentEn(guestAnnouncementSetting.data_json.form.en.content);
            setIsmultipleLanguage(guestAnnouncementSetting.is_support_mul_lang);
            setFileUrl(guestAnnouncementSetting.data_media_json[0]);
            setForm(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guestAnnouncementSetting]);

    const validateMutilpleLanguage = () => {
        const validationRules = [
            {
                name: `${t("general.Title")} ${t("general.English")}`,
                value: form.en.title,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: `${t("general.Title")} ${t("general.Albanian")}`,
                value: form.al.title,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: t("general.Link"),
                value: form.link,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: `${t("general.Content")} ${t("general.English")}`,
                value: editor.current.value,
                rule: 'required'
            },
            {
                name: `${t("general.Content")} ${t("general.Albania")}`,
                value: editorAl.current.value,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const validateForLanguage = (lang) => {
        const textEditor = lang === Constants.LANGUAGE_CODE.EN ? editor : editorAl;
        const validationRules = [
            {
                name: t("general.Title"),
                value: form[lang].title,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: t("general.Link"),
                value: form.link,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: `${t("general.Content")} ${lang === Constants.LANGUAGE_CODE.EN ? t("general.English") : t("general.Albania")}`,
                value: textEditor.current.value,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const paramPostSingleLanguage = (param) => {
        const data = { ...form };
        if (language === Constants.LANGUAGE_CODE.EN) {
            data.en.content = editor.current.value;
            param.append('data', JSON.stringify({ form }));
        } else {
            data.al.content = editorAl.current.value;
            param.append('data', JSON.stringify({ form }));
        }
    };

    const paramPostMultipleLanguage = (param) => {
        const data = { ...form };
        data.en.content = editor.current.value;
        data.al.content = editorAl.current.value;
        param.append('data', JSON.stringify({ form }));
    };

    const createOrupdateAboutUs = () => {
        let flag = true;
        if (isMultipleLanguage) {
            flag = validateMutilpleLanguage();
        } else if (!validateForLanguage(language)) return;
        if (flag) {
            const formData = new FormData();
            if (isMultipleLanguage) paramPostMultipleLanguage(formData);
            if (!isMultipleLanguage) paramPostSingleLanguage(formData);
            formData.append("type", "announcement_setting");
            formData.append("is_support_mul_lang", isMultipleLanguage);
            if (fileData !== "") formData.append("data_media[0]", fileData);
            dispatch(GuestAnnouncementSetting.updateAnnouncementSettingData(formData));
        }
    };

    const handleFileChange = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        const file = e.target.files[0];
        setFileData(file);
        setFileUrl(url);
    };

    const deleteFileAttachment = () => {
        setFileData("");
        setFileUrl("");
        fileInput.current.value = "";
    };

    const _renderSwitchLangugage = () => {
        return (
            <EnableMultipleLanguageButtonContainer>
                <SwitchButton className="switch">
                    <input type="checkbox" value={isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} checked={isMultipleLanguage} />
                    <span className="slider round" />
                </SwitchButton>
                {isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                            />
                        </>
                    )}
                {!isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                disabled
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                disabled
                            />
                        </>
                    )}
            </EnableMultipleLanguageButtonContainer>
        );
    };

    const renderForm = () => {
        return (
            <WrapperInput>
                <TitleInput>{t("general.Content")}</TitleInput>
                <div className={language === Constants.LANGUAGE_CODE.EN ? "" : "hidden-content"}>
                    <JoditEditor
                        ref={editor}
                        value={contentEn}
                    />
                </div>
                <div className={language === Constants.LANGUAGE_CODE.AL ? "" : "hidden-content"}>
                    <JoditEditor
                        ref={editorAl}
                        value={contentAl}
                    />
                </div>
            </WrapperInput>
        );
    };

    const handleChangeTitle = (value) => {
        const data = { ...form };
        data[language].title = value;
        setForm(data);
    };

    const handleChangeLink = (value) => {
        const data = { ...form };
        data.link = value;
        setForm(data);
    };

    return (
        <DefaultLayout
            userRole="limited-admin"
            isLoading={false}
            page="announcement"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>{t("general.Guest Announcement Setting")}</PageTitle>
                    <ProgressBar className="mb-2">
                        <FlexGridSixth>
                            <GridCol colSize='7' gridTemplate='7' className="flex-end">
                                {_renderSwitchLangugage()}
                            </GridCol>
                        </FlexGridSixth>
                        <WrapperInput>
                            <TitleInput>{t("general.Title")}</TitleInput>
                            <InputContainer
                                value={form[language].title || ''}
                                onChange={(event) => handleChangeTitle(event.target.value)}
                            />
                        </WrapperInput>

                        <WrapperInput>
                            <div>
                                {renderForm()}
                            </div>
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>{t("general.Link")}</TitleInput>
                            <InputContainer
                                value={form.link || ''}
                                onChange={(event) => handleChangeLink(event.target.value, "link")}
                            />
                        </WrapperInput>
                        <WrapperInput className="container-upload">
                            <input
                                ref={fileInput}
                                id='uploadAvatar'
                                type="file"
                                accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                                onChange={(e) => handleFileChange(e)}
                            />
                            {t("event.Featured Image")}
                            {' '}
                            <i className="fas fa-upload" onClick={() => fileInput.current.click()} />
                            {fileUrl
                                && (
                                    <div className="display-image-file">
                                        <img src={fileUrl} alt="file"/>
                                        <div className="remove" onClick={() => deleteFileAttachment()}>X</div>
                                    </div>
                                )
                            }
                        </WrapperInput>
                        <div className="container-button">
                            <GeneralButton
                                type="button"
                                className="for-more"
                                background={ColorName.chathamsBlue}
                                value={t("general.Save")}
                                onClick={() => createOrupdateAboutUs()}
                            />
                        </div>
                    </ProgressBar>
                </BodyContainer>
            )}
        />
    );
};

Announcement.propTypes = {};

export default Announcement;
