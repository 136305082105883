import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import FAQActions from "../../../actions/FAQ";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import {
    FAQContainer,
    Title,
    Content
} from "./FAQ.styles";
import FAQDropdown from "../../../components/Common/FAQDropdown";
import CONSTANTS from "../../../constants/Constants";

const FAQ = () => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const isLoading = useSelector((state) => state.FAQ.isLoading);
    const dataProcess = useSelector((state) => state.FAQ.faq);
    const [dataFAQs, setDataFAQs] = useState([]);
    useEffect(() => {
        const dataGet = {
            type: "faqs"
        };
        dispatch(FAQActions.getFAQ(dataGet));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataProcess) {
            setDataFAQs(dataProcess);
        }
    }, [dataProcess]);

    const _title = () => {
        return (
            <Title>
                {t("faqs.Frequently Asked Questions (FAQ)")}
            </Title>
        );
    };

    const _content = () => {
        return (
            <Content>
                {_.map(dataFAQs.data_json, (data, key) => {
                    let { description, title } = data[i18n.language];
                    if (!data.al.title && !data.al.description && i18n.language === CONSTANTS.LANGUAGE_CODE.AL) { description = data.en.description; title = data.en.title; }
                    if (!data.en.title && !data.en.description && i18n.language === CONSTANTS.LANGUAGE_CODE.EN) { description = data.al.description; title = data.al.title; }
                    return (
                        <FAQDropdown key={key} questionCategory={title} questionnaire={description} />
                    );
                })}
            </Content>
        );
    };

    const _renderMain = () => {
        return (
            <FAQContainer>
                {_title()}
                {_content()}
            </FAQContainer>
        );
    };

    return (
        <DefaultLayout
            context={_renderMain()}
            currentPage="faq"
            isLoading={isLoading}
        />
    );
};

FAQ.propTypes = {
};

export default FAQ;
