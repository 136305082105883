import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import { BodyContainer } from "./Message.styles";
import {} from "../../../components/Common/CommonLayout";
import Message from "../../../components/Layout/PortalTemplate/Message/MessageManage";
import Utils from "../../../libs/Utils";
import CONSTANTS from "../../../constants/Constants";
import AdminOverViewAction from "../../../actions/AdminOverView";
import UserOverviewAction from "../../../actions/UserOverview";
const MessageManage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.Message.isLoading);
  const userData = Utils.getSavedUserData();

  useEffect(() => {
    if (userData.role.name ===  CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
      const data = {
        type: CONSTANTS.NOTIFICATION.NOTIFICATION_TYPE.RECENT_ACTIVITY,
      };
      dispatch(AdminOverViewAction.getActivitiesOverview(data));
    } else {
      const data = {
        type: CONSTANTS.NOTIFICATION.NOTIFICATION_TYPE.NOTIFICATION,
      };
      dispatch(UserOverviewAction.getNotificationsRequest(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRole = () => {
    if (userData) {
      if (userData.role.name ===  CONSTANTS.ROLE_NAME.SUPER_ADMIN) return "admin";
      if (userData.role.name ===  CONSTANTS.ROLE_NAME.JUDGE) return "judge";
      return "user";
    }
  };

  return (
    <DefaultLayout
      userRole={checkRole()}
      isLoading={isLoading}
      page="message-manage"
      content={
        <BodyContainer>
          <BodyContainer>
            <Message />
          </BodyContainer>
        </BodyContainer>
      }
    />
  );
};

export default MessageManage;
