import styled from 'styled-components';
import { VerticalContainer, BreadCrumb, HorizontalContainer } from "../../../../components/Common/CommonLayout";
import { ColorName } from '../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .ml-1 {
        margin-left: 1em
    }
    & .mb-2 {
        margin-bottom: 2em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
    & .title-header {
        margin-left: 3em;
        margin-bottom: 0em;
    }
`;

const PageTitle = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    text-transform: none;
    margin-bottom: ${(props) => props.marginBottom || "1.5em"};
    margin-right: ${(props) => props.marginRight || "1em"};
    margin-top: ${(props) => props.marginTop || ""};
`;

const ProgressBarFunding = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${ColorName.white};
    border-radius: 10px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    margin-left: ${(props) => props.marginLeft || "0px"};
    flex: 1;
`;

const WrapperInput = styled.input`
    background: none;
    border: 2px solid ${ColorName.gray};
    height: auto;
    width: ${(props) => props.width || "18em"};
    margin-bottom: ${(props) => props.marginBottom || "2em"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 10px;
    outline: none;
    border-radius: 15px;
    margin-top: 0.6em;
    cursor: ${(props) => props.cursor || "auto"};
    background-color: ${(props) => props.backgroundColor || ""};
`;

const CheckBoxContainer = styled(WrapperInput)`
    margin: 0 0 0 1em;
    padding: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    &:after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -4px;
        left: -4px;
        position: relative;
        background-color: none;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid ${ColorName.white};
    }
    &:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -4px;
        left: -4px;
        position: relative;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid ${ColorName.white};
    }
`;

const WrapperTextarea = styled.textarea`
    background-color: ${ColorName.whisper};
    border: none;
    height: 20em;
    width: 34em;
    margin-bottom: ${(props) => props.marginBottom || "2em"};
`;

const WrapperContent = styled(HorizontalContainer)`
    justify-content: flex-start;
    flex-wrap: wrap;
    // align-items: center;
    margin-top: 2em;
    &.for-winner{
        flex-direction: column;
        & .text-winner{
            display: flex;
            width: 100%;
            margin-top: 10px;
        }
    }
`;

const WrapperButton = styled.div`
    display: flex;
    margin-top: 1em;
    &.ml-1 {
        margin-left: 1em;
    }
`;

const WrapperModal = styled.div`
    display: flex;
    position: fixed;
    z-index: 9;
    background-color: rgba(16, 16, 16, 0.5);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
`;

const HideModalContainer = styled.div`
    position: absolute;
    top: 29%;
    left: 70.3%;
    cursor: pointer;
    i {
        color: ${ColorName.white};
    }
`;

const ModalContent = styled(HorizontalContainer)`
    background: ${ColorName.white};
    border-radius: 25px;
    border: 3px solid ${ColorName.gallery};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
`;

const WrapperTitleContent = styled(HorizontalContainer)`
    margin-bottom: 1em;
    align-items: center;
    width: 90%;
    & .title-select {
        font-size: 14px;
        font-weight: 600;
        text-transform: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & .style-checkbox {
        display: flex;
        margin-right: 1em;
    }
    & .custom-title {
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        width: 10em;
        margin-right: 3em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
`;

const Field = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: inherit;
    margin: 0 0 2em 1em;
    min-width: 300px;
    & .input-file-container{
        display: flex;
    }
    &.for-button-upload{
        align-items: flex-start;
    }
    &.breakField{
        margin: 0px;
        min-width: 100vw;
    }
    & .upload-file{
        width: 100%;
        display: flex;
        padding: 0.5em 0;
        & p{
            margin: 0;
            cursor: pointer;
        }
        & .noWrap {
            white-space: nowrap;
        }
    }
    & .action-upload{
        display: flex;
        & i{
            padding: 0 0.5em;
            cursor: pointer;
        }
        & span{
            display: flex;
            align-items: center;
        }
    }
    &.upload-container{    
        min-height: 155px;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        margin: 0;
    }
    & .list-files-default{
        display: flex;
        flex-direction: column;
        & a{
            padding: 0.5em 0;
        }
    }
`;

const TitleNewField = styled.input`
    background: none;
    border: none;
    outline: none;
    font-size: 17px;
    font-weight: bold;
    color: ${ColorName.gray}
`;

const Category = styled.div`
    & .m-bottom {
        margin-bottom: 4em;
    }
`;

const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || "0"};
    margin-left: ${(props) => props.marginLeft || "0"};
    color: ${ColorName.chathamsBlueDark};
    & .title-deadline {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
    }
    & .fonts-status {
        font-weight: bold;
        font-size: 14px;
        line-height: 25px;
        color: ${ColorName.chathamsBlueDark};
    }
    & .fonts-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: ${ColorName.tickleMePink};
    }
    & .style-input {
        background: none;
        border: 2px solid #858585;
        height: auto;
        width: 20em;
        border-radius: 0;
        padding: 10px;
        outline: none;
        border-radius: 15px;
        cursor: ${(props) => props.cursor || "auto"};
    }
    & .fonts-category {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
    }
    & .style-disabled{
        cursor: not-allowed;
        background-color: lightgray;
    }
    & .style-disabled-div{
        padding: 10px;
        border-radius: 10px;
        border: 1px solid gray;
    }
    & textarea{
        padding: 10px;
        border-radius: 10px;
        border: 1px solid gray;
    }
`;

const WrapperItemConent = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: ${ColorName.chathamsBlueDark};
    &.mt-title {
        margin-top: 3em
    }
    &.mb-title {
        margin-bottom: 2em
    }
`;

const Report = styled(HorizontalContainer)`
    flex: 1;
    margin-bottom: 3em;
`;

const BlockReport = styled.div`
    margin-right: 40px;
    flex: 1
`;

const UploadContainer = styled.div`
    display: flex;
    width: 100%;
`;

const UploadListItem = styled.div`
    flex: 1;
    & .upload-item {
        margin-bottom: 1em;
        padding: 5px 10px;
        a {
            text-decoration: none;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const UploadTemplate = styled.div`
    flex: 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    word-break: break-word;
    input {
        display: none;
    }
    & .input-file-container{
        display: flex;
        & .action-upload{
            display: flex;
            align-items: center;
            & i{
                padding: 0.5em;
            }
        }
    }
    & .upload-item {
        margin-bottom: 1em;
        padding: 5px 10px;
        a {
            text-decoration: none;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export {
    BodyContainer,
    PageTitle,
    ProgressBarFunding,
    WrapperInput,
    WrapperTextarea,
    WrapperContent,
    WrapperButton,
    WrapperModal,
    HideModalContainer,
    ModalContent,
    WrapperTitleContent,
    Field,
    TitleNewField,
    Category,
    CheckBoxContainer,
    WrapperTitle,
    WrapperItemConent,
    Report,
    BlockReport,
    Title,
    UploadContainer,
    UploadTemplate,
    UploadListItem
};
