import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  ProgressBar,
  WrapperContent,
  WrapperButton,
  Field,
  WrapperInput,
  TitleNewField,
  FieldTitle,
  WrapperSelect
} from "./FundingApplications.styles";
import {
  PageTitle,
  GeneralButton,
  LabelSlide,
  HorizontalContainer
} from "../../../../components/Common/CommonLayout";
import history from "../../../../history";
import { ColorName } from "../../../../components/Variables";
import TextArea from "../../../../components/Common/TextArea";
import FundingApplicationActions from "../../../../actions/FundingApplication";
import Utils from "../../../../libs/Utils";
import Constants from "../../../../constants/Constants";

const CreateNewFundingApplication = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const FundingApplicationStorage = useSelector((state) => state.FundingApplication);
  const isLoading = useSelector((state) => state.FundingApplication.isLoading);
  const [countNewFields, setCountNewFields] = useState(0);
  const [keyLastExtradata, setKeyLastExtraData] = useState(0);
  const [postData, setPostData] = useState({
    is_document: false,
    is_send_mail: false,
    document_template: [],
    document_evaluation: [],
    document_winner: []
  });
  // for evalute
  const [fileEvalutes, setFileEvalutes] = useState([]);
  const [fileEvalutesEdit, setFileEvalutesEdit] = useState([]);
  const [deleteEditFile, setDeleteEditFile] = useState([]);
  // for winner
  const [fileWinners, setFileWinners] = useState([]);
  const [fileWinnersEdit, setFileWinnersEdit] = useState([]);
  const [deleteFileWinnerEdit, setDeleteFileWinnerEdit] = useState([]);
  // for document
  const [fileDocuments, setFileDocuments] = useState([]);
  const [fileDocumentsEdit, setFileDocumentsEdit] = useState([]);
  const [deleteFileDocumentsEdit, setDeleteFileDocumentsEdit] = useState([]);

  const [extraData, setExtraData] = useState({});
  const [extraDataRender, setExtraDataRender] = useState({});
  const actions = useSelector((state) => state.FundingApplication.actionFundingType);
  const fundingApplicationID = useSelector((state) => state.FundingApplication.fundingId);

  useEffect(() => {
    if (actions === "edit" && _.isEmpty(FundingApplicationStorage.newFundingData)) {
      dispatch(FundingApplicationActions.getFundingApplicationById(fundingApplicationID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEqual(postData, FundingApplicationStorage.newFundingData) && actions !== "edit") {
      setPostData(FundingApplicationStorage.newFundingData);
    }
    if (!_.isEqual(postData, FundingApplicationStorage.newFundingData) && actions === "edit") {
      const editablePostData = { ...FundingApplicationStorage.newFundingData };
      if (!_.isEmpty(FundingApplicationStorage.newFundingData)) {
        const newData = { ...FundingApplicationStorage.newFundingData };
        if (_.has(newData, "documentEvaluationJson")) {
          const arrayTemp = [];
          _.map(newData.documentEvaluationJson, (child, index) => {
            if (newData.remove_document_evaluation) {
              setDeleteEditFile(newData.remove_document_evaluation);
              let flag = true;
              _.map(newData.remove_document_evaluation, (item) => {
                if (item === index) flag = false;
              });
              if (flag) {
                return arrayTemp.push({ [index]: child });
              }
            } else {
              return arrayTemp.push({ [index]: child });
            }
          });
          setFileEvalutesEdit(arrayTemp);
        }
        if (_.has(newData, "documentWinnerJson")) {
          const arrayTemp = [];
          _.map(newData.documentWinnerJson, (child, index) => {
            if (newData.remove_document_winner) {
              setDeleteFileWinnerEdit(newData.remove_document_winner);
              let flag = true;
              _.map(newData.remove_document_winner, (item) => {
                if (item === index) flag = false;
              });
              if (flag) {
                return arrayTemp.push({ [index]: child });
              }
            } else {
              return arrayTemp.push({ [index]: child });
            }
          });
          setFileWinnersEdit(arrayTemp);
        }
        if (_.has(newData, "documentJson")) {
          const arrayTemp = [];
          _.map(newData.documentJson, (child, index) => {
            if (newData.remove_document) {
              setDeleteFileDocumentsEdit(newData.remove_document);
              let flag = true;
              _.map(newData.remove_document, (item) => {
                if (item === index) flag = false;
              });
              if (flag) {
                return arrayTemp.push({ [index]: child });
              }
            } else {
              return arrayTemp.push({ [index]: child });
            }
          });
          setFileDocumentsEdit(arrayTemp);
        }
      }
      if (!_.isEmpty(editablePostData.documentJson)) {
        editablePostData.is_document = true;
      }
      setPostData(editablePostData);
    }
    if (!_.isEmpty(FundingApplicationStorage.newFundingData.extraFieldJson)) {
      const size = Object.keys(FundingApplicationStorage.newFundingData.extraFieldJson).length;
      if (size > 0) {
        const editablePostData = { ...FundingApplicationStorage.newFundingData };
        const last = _.findLastKey(FundingApplicationStorage.newFundingData.extraFieldJson);
        const lastNumber = _.parseInt(last);
        setExtraDataRender(FundingApplicationStorage.newFundingData.extraFieldJson);
        setExtraData(FundingApplicationStorage.newFundingData.extraFieldJson);
        setKeyLastExtraData(lastNumber);
        setCountNewFields(lastNumber);
        if (!_.isEmpty(editablePostData.documentJson)) {
          editablePostData.is_document = true;
        }
        setPostData(editablePostData);
      }
    }
    if (!_.isEmpty(FundingApplicationStorage.newFundingData)) {
      const newData = { ...FundingApplicationStorage.newFundingData };
      if (_.has(newData, "document_evaluation")) {
        setFileEvalutes(newData.document_evaluation);
      }
      if (_.has(newData, "document_winner")) {
        setFileWinners(newData.document_winner);
      }
      if (_.has(newData, "document_template")) {
        setFileDocuments(newData.document_template);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FundingApplicationStorage.newFundingData]);

  const addNewField = () => {
    setExtraDataRender(extraData);
    setCountNewFields(countNewFields + 1);
  };

  const validateData = () => {
    let messsage = "";
    if (!_.has(postData, 'title')) messsage += `${t("general.Title")} ${t("validation.is required")}  \n`;
    if (!_.has(postData, 'category') || postData.category === "3") messsage += `${t("general.Category")} ${t("validation.is required")}  \n`;
    if ((!_.has(postData, 'document') && postData.document === null) && postData.is_document) messsage += `${t("documentTemplate.Document")} ${t("validation.is required")} \n`;
    if (messsage === "") return true;
    Utils.popupAlert({ title: "Oops...", text: messsage, type: "error" });
    return false;
  };

  const validateExtraField = (extraField) => {
    let flag = true;
    if (_.isEmpty(extraField)) return flag;
    _.map(extraField, (field) => {
      if (field.title === "" || field.value === "") {
        Utils.popupAlert({ title: "Oops...", text: t("general.Field should not be blank"), type: "error" });
        return flag = false;
      }
    });
    return flag;
  };

  const openQuestionnaire = async () => {
    const extraField = { ...extraData };
    const valid = await validateExtraField(extraField);
    const dataFundingAppliactionDescription = {
      ...postData,
      extraFieldJson: extraField,
      remove_document_evaluation: deleteEditFile,
      remove_document: deleteFileDocumentsEdit,
      remove_document_winner: deleteFileWinnerEdit
    };
    const flag = await validateData();
    if (flag && valid) {
      dispatch(FundingApplicationActions.setNewFundingApplicationData(dataFundingAppliactionDescription));
      history.push('category-questionnaire');
    }
  };

  const setNewTitleData = (e, index) => {
    const data = { ...extraData };
    const indexObject = index;
    if (_.isUndefined(data[indexObject])) data[indexObject] = {};
    data[indexObject].title = e.target.value;
    setExtraData(data);
  };

  const setNewContentData = (e, index) => {
    const data = { ...extraData };
    const indexObject = index;
    if (_.isUndefined(data[indexObject])) return false;
    data[indexObject].value = e.target.value;
    setExtraData(data);
  };

  const deleteExtraData = (index) => {
    const data = { ...extraData };
    delete data[index];
    setExtraDataRender(data);
    setExtraData(data);
    setCountNewFields(countNewFields - 1);
  };

  const _renderNewField = () => {
    if (countNewFields > 0) {
      if (keyLastExtradata < countNewFields) {
        return (
          <Field key={countNewFields}>
            <FieldTitle marginBottom="0.6em" marginRight='auto' className="inputEdit">
              <TitleNewField type='text' placeholder={t("general.Enter Title Here")} onChange={(e) => setNewTitleData(e, countNewFields)} />
              <i className="fas fa-trash" onClick={() => deleteExtraData(countNewFields)} />
            </FieldTitle>
            <TextArea height='15em' width="18em" onChange={(e) => setNewContentData(e, countNewFields)} />
          </Field>
        );
      }
    }
  };

  const _renderExtraNewField = () => {
    const data = { ...extraDataRender };
    const render = _.map(data, (field, key) => {
      const index = _.parseInt(key);
      return (
        <Field key={index}>
          <FieldTitle marginBottom="0.6em" marginRight='auto' className="inputEdit">
            <TitleNewField type='text' placeholder={t("general.Enter Title Here")} onChange={(e) => setNewTitleData(e, index)} value={field.title} />
            <i className="fas fa-trash" onClick={() => deleteExtraData(index)} />
          </FieldTitle>
          <TextArea height='15em' width="20em" onChange={(e) => setNewContentData(e, index)} defaultValue={field.value} />
        </Field>
      );
    });
    return render;
  };

  const changeCategory = (event) => {
    setPostData({ ...postData, category: event.target.value });
  };

  const onChangeRequireTemplate = (e) => {
    const newPostData = { ...postData };
    if (!e.target.checked) {
      if (_.has(newPostData, 'document_template')) newPostData.document_template = '';
    }
    newPostData.is_document = !postData.is_document;
    setPostData(newPostData);
  };

  const handChangeFile = (e, index) => {
    if (e.target.files.length !== 0) {
      if (!_.has(postData, "document_template")) postData.document_template = [];
      const documentEvalute = { ...postData };
      documentEvalute.document_template[index] = e.target.files[0];
      setPostData(documentEvalute);
      setFileDocuments(documentEvalute.document_template);
    }
  };

  const handChangeFileForWinner = (e, index) => {
    if (e.target.files.length !== 0) {
      if (!_.has(postData, "document_winner")) postData.document_winner = [];
      const documentEvalute = { ...postData };
      documentEvalute.document_winner[index] = e.target.files[0];
      setPostData(documentEvalute);
      setFileWinners(documentEvalute.document_winner);
    }
  };

  const handChangeFileForEvalute = (e, index) => {
    if (e.target.files.length !== 0) {
      if (!_.has(postData, "document_evaluation")) postData.document_evaluation = [];
      const documentEvalute = { ...postData };
      documentEvalute.document_evaluation[index] = e.target.files[0];
      setPostData(documentEvalute);
      setFileEvalutes(documentEvalute.document_evaluation);
    }
  };

  // For Evalute
  const addNewFile = () => {
    if (!_.has(postData, "document_evaluation")) postData.document_evaluation = [];
    const documentEvalute = { ...postData };
    const index = documentEvalute.document_evaluation.length;
    documentEvalute.document_evaluation[index] = {};
    setPostData(documentEvalute);
    setFileEvalutes(documentEvalute.document_evaluation);
  };

  const removeNewFile = (index) => {
    const documentEvalute = { ...postData };
    if (index > -1) {
      documentEvalute.document_evaluation.splice(index, 1);
    }
    setPostData(documentEvalute);
    setFileEvalutes(documentEvalute.document_evaluation);
  };
  const removeNewFileEdit = (fileNameDelete, index) => {
    const temp = [...fileEvalutesEdit];
    if (index > -1) {
      temp.splice(index, 1);
    }
    setFileEvalutesEdit(temp);
    const fileDelete = [...deleteEditFile];
    fileDelete.push(fileNameDelete);
    setDeleteEditFile(fileDelete);
  };

  const _renderNewUpload = (file, index = 0) => {
    return (
      <Field className="full-width-upload-multiple">
        <FieldTitle marginBottom="0" marginRight='auto' />
        <div className="language">
          <LabelSlide className="switch upload-file">
            <input type="file" onChange={(e) => handChangeFileForEvalute(e, index)} />
            {!file
              && (
                <span>
                  <i className="fas fa-upload" />
                  {t("general.Choose file")}
                </span>
              )
            }
            {file
              && (
                <p>
                  <span>
                    <i className="fas fa-upload" />
                    {t("general.Choose file")}
                  </span>
                  :
                  {' '}
                  {!_.isUndefined(file.name) && Utils.getNameFileUpload(file.name)}
                </p>
              )}
          </LabelSlide>

          {(index === 0 && file)
            && (
              <div className="action-upload">
                <span onClick={() => addNewFile()}><i className="fas fa-plus" /></span>
                <span onClick={() => removeNewFile(index)}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
          {index !== 0
            && (
              <div className="action-upload">
                <span onClick={() => removeNewFile(index)}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
        </div>
      </Field>
    );
  };

  const _renderNewUploadEdit = (file, index = 0) => {
    return (
      <Field className="full-width-upload-multiple" key={index}>
        <FieldTitle marginBottom="0" marginRight='auto' />
        <div className="language">
          <LabelSlide className="switch upload-file">
            {file && (
              <p>
                {t("general.File name")}
                :
                {' '}
                {Utils.getNameFileUpload(_.keys(file).toString())}
              </p>
            )}
          </LabelSlide>
          <div className="action-upload">
            <span onClick={() => removeNewFileEdit(_.keys(file).toString(), index)}><i className="fas fa-trash-alt" /></span>
          </div>
        </div>
      </Field>
    );
  };

  const _renderMultipleUpload = () => {
    if (fileEvalutes.length === 0) {
      return _renderNewUpload();
    } else {
      const render = _.map(fileEvalutes, (file, index) => {
        return (
          <div key={index} className="full-width-upload-multiple">
            {_renderNewUpload(file, index)}
          </div>
        );
      });
      return render;
    }
  };

  const _renderMultipleUploadEdit = () => {
    if (fileEvalutesEdit.length !== 0) {
      const render = _.map(fileEvalutesEdit, (file, index) => {
        return (
          <div key={index} className="full-width-upload-multiple">
            {_renderNewUploadEdit(file, index)}
          </div>
        );
      });
      return render;
    }
  };

  // for document

  const addNewFileDocument = () => {
    if (!_.has(postData, "document_template")) postData.document_template = [];
    const documentEvalute = { ...postData };
    const index = documentEvalute.document_template.length;
    documentEvalute.document_template[index] = {};
    setPostData(documentEvalute);
    setFileDocuments(documentEvalute.document_template);
  };

  const removeNewFileDocument = (index) => {
    const documentEvalute = { ...postData };
    if (index > -1) {
      documentEvalute.document_template.splice(index, 1);
    }
    setPostData(documentEvalute);
    setFileDocuments(documentEvalute.document_template);
  };
  const removeNewFileEditDocument = (fileNameDelete, index) => {
    const temp = [...fileDocumentsEdit];
    if (index > -1) {
      temp.splice(index, 1);
    }
    setFileDocumentsEdit(temp);
    const fileDelete = [...deleteFileDocumentsEdit];
    fileDelete.push(fileNameDelete);
    setDeleteFileDocumentsEdit(fileDelete);
  };

  const _renderNewUploadDocument = (file, index = 0) => {
    return (
      <Field className="full-width-upload-multiple">
        <FieldTitle marginBottom="0" marginRight='auto' />
        <div className="language">
          <LabelSlide className="switch upload-file">
            <input type="file" onChange={(e) => handChangeFile(e, index)} />
            {!file
              && (
                <span>
                  <i className="fas fa-upload" />
                  {t("general.Choose file")}
                </span>
              )
            }
            {file
              && (
                <p>
                  <span>
                    <i className="fas fa-upload" />
                    {t("general.Choose file")}
                  </span>
                  :
                  {' '}
                  {!_.isUndefined(file.name) && Utils.getNameFileUpload(file.name)}
                </p>
              )}
          </LabelSlide>

          {(index === 0 && file)
            && (
              <div className="action-upload">
                <span onClick={() => addNewFileDocument()}><i className="fas fa-plus" /></span>
                <span onClick={() => removeNewFileDocument(index)}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
          {index !== 0
            && (
              <div className="action-upload">
                <span onClick={() => removeNewFileDocument(index)}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
        </div>
      </Field>
    );
  };

  const _renderNewUploadEditDocument = (file, index = 0) => {
    return (
      <Field className="full-width-upload-multiple" key={index}>
        <FieldTitle marginBottom="0" marginRight='auto' />
        <div className="language">
          <LabelSlide className="switch upload-file">
            {file && (
              <p>
                {t("general.File name")}
                :
                {' '}
                {Utils.getNameFileUpload(_.keys(file).toString())}
              </p>
            )}
          </LabelSlide>
          <div className="action-upload">
            <span onClick={() => removeNewFileEditDocument(_.keys(file).toString(), index)}><i className="fas fa-trash-alt" /></span>
          </div>
        </div>
      </Field>
    );
  };

  const _renderMultipleUploadDocument = () => {
    if (fileDocuments.length === 0 || _.isString(fileDocuments)) {
      return _renderNewUploadDocument();
    } else if (_.isArray(fileDocuments)) {
      const render = _.map(fileDocuments, (file, index) => {
        return (
          <div key={index} className="full-width-upload-multiple">
            {_renderNewUploadDocument(file, index)}
          </div>
        );
      });
      return render;
    }
  };

  const _renderMultipleUploadEditDocument = () => {
    if (fileDocumentsEdit.length !== 0) {
      const render = _.map(fileDocumentsEdit, (file, index) => {
        return (
          <div key={index} className="full-width-upload-multiple">
            {_renderNewUploadEditDocument(file, index)}
          </div>
        );
      });
      return render;
    }
  };

  // for winner

  const addNewFileWinner = () => {
    if (!_.has(postData, "document_winner")) postData.document_winner = [];
    const documentEvalute = { ...postData };
    const index = documentEvalute.document_winner.length;
    documentEvalute.document_winner[index] = {};
    setPostData(documentEvalute);
    setFileWinners(documentEvalute.document_winner);
  };

  const removeNewFileWinner = (index) => {
    const documentEvalute = { ...postData };
    if (index > -1) {
      documentEvalute.document_winner.splice(index, 1);
    }
    setPostData(documentEvalute);
    setFileWinners(documentEvalute.document_winner);
  };
  
  const removeNewFileEditWinner = (fileNameDelete, index) => {
    const temp = [...fileWinnersEdit];
    if (index > -1) {
      temp.splice(index, 1);
    }
    setFileWinnersEdit(temp);
    const fileDelete = [...deleteFileWinnerEdit];
    fileDelete.push(fileNameDelete);
    setDeleteFileWinnerEdit(fileDelete);
  };

  const _renderNewUploadWinner = (file, index = 0) => {
    return (
      <Field className="full-width-upload-multiple">
        <FieldTitle marginBottom="0" marginRight='auto' />
        <div className="language">
          <LabelSlide className="switch upload-file">
            <input type="file" onChange={(e) => handChangeFileForWinner(e, index)} />
            {!file
              && (
                <span>
                  <i className="fas fa-upload" />
                  {t("general.Choose file")}
                </span>
              )
            }
            {file
              && (
                <p>
                  <span>
                    <i className="fas fa-upload" />
                    {t("general.Choose file")}
                  </span>
                  :
                  {' '}
                  {!_.isUndefined(file.name) && Utils.getNameFileUpload(file.name)}
                </p>
              )}
          </LabelSlide>

          {(index === 0 && file)
            && (
              <div className="action-upload">
                <span onClick={() => addNewFileWinner()}><i className="fas fa-plus" /></span>
                <span onClick={() => removeNewFileWinner(index)}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
          {index !== 0
            && (
              <div className="action-upload">
                <span onClick={() => removeNewFileWinner(index)}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
        </div>
      </Field>
    );
  };

  const _renderNewUploadEditWinner = (file, index = 0) => {
    return (
      <Field className="full-width-upload-multiple" key={index}>
        <FieldTitle marginBottom="0" marginRight='auto' />
        <div className="language">
          <LabelSlide className="switch upload-file">
            {file && (
              <p>
                {t("general.File name")}
                :
                {' '}
                {Utils.getNameFileUpload(_.keys(file).toString())}
              </p>
            )}
          </LabelSlide>
          <div className="action-upload">
            <span onClick={() => removeNewFileEditWinner(_.keys(file).toString(), index)}><i className="fas fa-trash-alt" /></span>
          </div>
        </div>
      </Field>
    );
  };

  const _renderMultipleUploadWinner = () => {
    if (fileWinners.length === 0) {
      return _renderNewUploadWinner();
    } else {
      const render = _.map(fileWinners, (file, index) => {
        return (
          <div key={index} className="full-width-upload-multiple">
            {_renderNewUploadWinner(file, index)}
          </div>
        );
      });
      return render;
    }
  };

  const _renderMultipleUploadEditWinner = () => {
    if (fileWinnersEdit.length !== 0) {
      const render = _.map(fileWinnersEdit, (file, index) => {
        return (
          <div key={index} className="full-width-upload-multiple">
            {_renderNewUploadEditWinner(file, index)}
          </div>
        );
      });
      return render;
    }
  };

  return (
    <DefaultLayout
      userRole='admin'
      isLoading={isLoading}
      page="fundingApplications"
      content={(
        <BodyContainer>
          <PageTitle>
            {actions === "edit" ? t("fundingApplications.Edit Funding Application") : t("fundingApplications.New Funding Application")}
          </PageTitle>
          <FieldTitle>{t("fundingApplications.Project Description")}</FieldTitle>
          <ProgressBar className="mb-2">
            <WrapperContent>
              <Field>
                <FieldTitle marginBottom="0" marginRight='auto'>
                  {t("general.Title")}
                </FieldTitle>
                <WrapperInput
                  onChange={(event) => setPostData({ ...postData, title: event.target.value })}
                  defaultValue={postData.title}
                />
              </Field>
              <Field>
                <FieldTitle marginBottom="0" marginRight='auto'>
                  {t("general.Category")}
                </FieldTitle>
                <WrapperSelect
                  value={postData.category}
                  onChange={(event) => changeCategory(event)}
                >
                  <option value="3">{t("general.Category")}</option>
                  <option value={Constants.FUNDING_CATEGORY.ENTREPRENEUR}>{t("general.Entrepreneurs")}</option>
                  <option value={Constants.FUNDING_CATEGORY.LIMITED_USER}>{t("general.Limited User")}</option>
                </WrapperSelect>
              </Field>
              {actions !== 'edit'
                && (
                  <>
                    <Field className="breakField" />
                    <Field>
                      <HorizontalContainer>
                        <input
                          id='sendmail'
                          type="checkbox"
                          name="sendMail"
                          defaultValue={postData.is_send_mail}
                          onChange={(e) => setPostData({ ...postData, is_send_mail: e.target.checked })}
                        />
                        <label htmlFor="sendmail">Send mail</label>
                      </HorizontalContainer>
                    </Field>
                  </>
                )
              }

              <Field className="breakField" />
              <Field>
                <FieldTitle marginBottom="0.6em" marginRight='auto' className="inputEdit">
                  {t("general.Directions To The Winners")}
                </FieldTitle>
                <TextArea
                  defaultValue={postData.directionWinner}
                  height='15em'
                  width="18em"
                  onChange={(e) => setPostData({ ...postData, directionWinner: e.target.value })}
                />
              </Field>
              <Field className="breakField" />
              {_renderExtraNewField()}
              {_renderNewField()}
              <Field className="breakField" />
              <div className="test">
                <FieldTitle marginBottom="0" marginRight='auto' className="title-upload">
                  {t("general.Template For Evaluation")}
                  :
                </FieldTitle>
                {_renderMultipleUploadEdit()}
                {_renderMultipleUpload()}
              </div>
              <Field className="breakField" />
              <div className="test">
                <FieldTitle marginBottom="0" marginRight='auto' className="title-upload">
                  {t("general.Template for winner")}
                  :
                </FieldTitle>
                {_renderMultipleUploadEditWinner()}
                {_renderMultipleUploadWinner()}
              </div>
              <Field className="breakField" />
              <Field className="full-width-upload">
                <FieldTitle marginBottom="0" marginRight='auto'>
                  {t("general.Require templates")}
                  {' '}
                  :
                </FieldTitle>
                <div className="language">
                  <LabelSlide className="switch">
                    <input type="checkbox" onChange={(e) => onChangeRequireTemplate(e)} checked={!!postData.is_document} />
                    <span className="slider round" />
                  </LabelSlide>
                </div>
              </Field>
              <Field className="breakField" />
              {postData.is_document && (
                <div>
                  <FieldTitle marginBottom="0" marginRight='auto' className="title-upload">
                    {t("documentTemplate.Document")}
                    :
                  </FieldTitle>
                  {_renderMultipleUploadEditDocument()}
                  {_renderMultipleUploadDocument()}
                </div>
              )}
              <Field className="breakField" />
            </WrapperContent>
            <WrapperButton>
              <GeneralButton
                type="button"
                background={ColorName.chathamsBlue}
                value={t("general.Add Field")}
                height="35px"
                width="13em"
                margin="0 1.5em 0 0"
                onClick={() => addNewField()}
                className="buttonFundingApplication"
              />
              <GeneralButton
                type="button"
                background={ColorName.chathamsBlue}
                value={t("fundingApplications.Open Application Form")}
                height="35px"
                width="13em"
                margin="0 1.5em 0 0"
                onClick={() => openQuestionnaire()}
                className="buttonFundingApplication"
              />
            </WrapperButton>
          </ProgressBar>
        </BodyContainer>
      )}
    />
  );
};

export default CreateNewFundingApplication;
