import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import Constants from "../constants/Constants";
import i18n from "../i18n";
import history from "../history";

const requestHasError = () => {
  return {
    type: ActionTypes.EVENT_FAILURE,
  };
};

const requestIsLoading = () => {
  return {
    type: ActionTypes.EVENT_REQUEST,
  };
};

const addEventSuccess = (payload) => {
  return {
    type: ActionTypes.ADD_EVENT_SUCCESS,
    payload,
  };
};

const updateEventSuccess = (payload) => {
  return {
    type: ActionTypes.UPDATE_EVENT_SUCCESS,
    payload,
  };
};

const getEventByIdSuccess = (payload) => {
  return {
    type: ActionTypes.GET_EVENT_BY_ID_SUCCESS,
    payload,
  };
};

const getAllEventSuccess = (payload) => {
  return {
    type: ActionTypes.GET_ALL_EVENT_SUCCESS,
    payload,
  };
};
const setEventId = (payload) => {
  return {
    type: ActionTypes.SET_EVENT_ID_SUCCESS,
    payload,
  };
};

const getEventSlideSuccess = (payload) => {
  return {
    type: ActionTypes.GET_EVENT_FOR_SLIDE_SUCCESS,
    payload,
  };
};

const addEvent = (data) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.addEvent(data)
      .then((res) => {
        if (res.data.status === true) {
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
          dispatch(addEventSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const updateEvent = (data, id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.updateEvent(data, id)
      .then((res) => {
        if (res.data.status === true) {
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
          dispatch(updateEventSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const getAllEvent = (data) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.getAllEvent(data)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getAllEventSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const getEventById = (id, data) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.getEventById(id, data)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getEventByIdSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const getEventByIdForDetailPage = (id, data) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.getEventById(id, data)
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data.status === 1)
            dispatch(getEventByIdSuccess(res.data.data));
          else
            Utils.popupAlert({
              title: "Oops!",
              text: "Access denied!",
              type: "error",
            }).then(() => {
              history.push("/homepage/news");
            });
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const updateStatusEvent = (data, id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.updateEvent(data, id)
      .then((res) => {
        if (res.data.status === true) {
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
          const dataGet = {
            page: 1,
            item_per_page: Constants.ITEM_PER_PAGE,
            language: i18n.language,
          };
          dispatch(getAllEvent(dataGet));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const deleteEvent = (id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.deleteEvent(id)
      .then((res) => {
        if (res.data.status === true) {
          const dataGet = {
            page: 1,
            item_per_page: Constants.ITEM_PER_PAGE,
            language: i18n.language,
          };
          dispatch(getAllEvent(dataGet));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const getEventSlide = (param) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.getEventSlide(param)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getEventSlideSuccess(res.data.data));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const sendMail = (id) => {
  const param = new FormData();
  param.append("id", id);
  return (dispatch) => {
    API.sendMailEvents(param)
      .then((res) => {
        if (res.data.status === true) {
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

const updateStatusSendMail = (data, id) => {
  return (dispatch) => {
    dispatch(requestIsLoading());
    API.updateEvent(data, id)
      .then((res) => {
        if (res.data.status === true) {
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
          const dataGet = {
            page: 1,
            item_per_page: Constants.ITEM_PER_PAGE,
            language: i18n.language,
          };
          dispatch(getAllEvent(dataGet));
          dispatch(sendMail(id));
        } else {
          dispatch(requestHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: Utils.getMessage(res),
            type: "error",
          });
        }
      })
      .catch((e) => {
        dispatch(requestHasError());
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
      });
  };
};

export default {
  addEvent,
  getEventById,
  getAllEvent,
  setEventId,
  updateEvent,
  updateStatusEvent,
  deleteEvent,
  getEventSlide,
  updateStatusSendMail,
  getEventByIdForDetailPage,
};
