import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Content,
    Title,
    Fields,
    Field,
    Footer,
    InputContainer,
    ButtonFooter,
    Logo
} from "./Authentication.styles";
import RegiterActions from "../../../actions/Register";
import Button from "../../../components/Common/Button";
import history from "../../../history";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import UserPageTemplateV1 from "../../../components/Layout/GuestTemplate/DefaultLayout";
import Validation from "../../../libs/Validation";
const Register = () => {
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.Register.isLoading);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch();

    const inputEmail = (e) => {
        setEmail(e.target.value);
    };

    const inputPassword = (e) => {
        setPassword(e.target.value);
    };

    const inputConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    };

    const inputFirstName = (e) => {
        setFirstName(e.target.value);
    };

    const inputLastName = (e) => {
        setLastName(e.target.value);
    };

    const register = () => {
        const validationRules = [
            {
                name: t("general.Email"),
                value: email,
                rule: ["required", "email"]
            },
            {
                name: t("general.Password"),
                value: password,
                rule: "required"
            },
            {
                name: t("general.Confirm Password"),
                value: confirmPassword,
                rule: ["required", password]
            },
            {
                name: t("general.First Name"),
                value: firstName,
                rule: "required"
            },
            {
                name: t("general.Last Name"),
                value: lastName,
                rule: "required"
            },
            {
                name: t("general.Confirm Password"),
                value: confirmPassword,
                rule: "equal",
                ruleValue: password
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        const user_data = {
            first_name: firstName,
            last_name: lastName
        };
        const data = {
            email,
            password,
            password_confirmation: confirmPassword,
            user_data
        };
        dispatch(RegiterActions.registerUser(data));
    };

    const onKeyEnterPress = (e) => {
        if (e.which === 13) {
            register();
        }
    };

    const switchScreen = (screen) => {
        history.push(screen);
    };

    return (
        <UserPageTemplateV1 content={(
            <Content>
                {isLoading && <Loading />}
                <Logo>IDE Albania</Logo>
                <Title>Register</Title>
                <Fields>
                    <Field>
                        <InputContainer>
                            <input type='text' placeholder={t("general.Enter your email")} onChange={inputEmail} onKeyPress={onKeyEnterPress} />
                        </InputContainer>
                    </Field>
                    <Field>
                        <InputContainer>
                            <input type='password' placeholder={t("general.Enter your passwords")} onChange={inputPassword} onKeyPress={onKeyEnterPress} />
                        </InputContainer>
                    </Field>
                    <Field>
                        <InputContainer>
                            <input type='password' placeholder="Enter your confirm passwords" onChange={inputConfirmPassword} />
                        </InputContainer>
                    </Field>
                    <Field>
                        <InputContainer>
                            <input type='text' placeholder="Enter your first name" onChange={inputFirstName} onKeyPress={onKeyEnterPress} />
                        </InputContainer>
                    </Field>
                    <Field>
                        <InputContainer>
                            <input type='text' placeholder="Enter your last name" onChange={inputLastName} onKeyPress={onKeyEnterPress} />
                        </InputContainer>
                    </Field>
                    <Fields>
                        <Field className="alignCenter">
                            <Button
                                background='gradient'
                                title={t("general.Submit")}
                                height="40px"
                                width="6em"
                                onClick={() => register()}
                            />
                        </Field>
                    </Fields>
                </Fields>
                <Footer>
                    <ButtonFooter
                        onClick={() => switchScreen("/authentication")}
                    >
                        {t("general.Sign In")}
                    </ButtonFooter>
                </Footer>
            </Content>
        )}
        />
    );
};

export default Register;
