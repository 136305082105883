import styled from "styled-components";
import {
  VerticalContainer,
  BreadCrumb,
  HorizontalContainer,
  GeneralButton,
} from "../../../../components/Common/CommonLayout";
import { ColorName } from "../../../../components/Variables";

const BodyContainer = styled(VerticalContainer)`
  & .checkbox-export {
    -webkit-appearance: checkbox;
  }
  & .list-export {
    & li {
      display: flex;
      padding: 0.5em 0;
      align-items: center;
      & label {
        padding: 0 1em;
        cursor: pointer;
      }
    }
  }
  padding: 3em;
  & .ml-1 {
    margin-left: 1em;
  }
  & .mb-2 {
    margin-bottom: 2em;
  }
  & .mt-2 {
    margin-top: 2em;
  }
  & .title-header {
    margin-left: 3em;
    margin-bottom: 0em;
  }
  & .fa-minus-circle {
    margin-left: 10px;
  }
  & .fa-trash {
    cursor: pointer;
  }
  & textarea::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  & textarea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: transparent;
  }
  & textarea::-webkit-scrollbar-track {
    margin: 10px 0px;
    background-color: transparent;
  }
  & .buttonFundingApplication {
    // text-transform: uppercase;
  }
`;

const ContentDetail = styled(HorizontalContainer)`
  & .forDetails {
    width: 70%;
    min-width: 300px;
    margin-right: 3em;
  }
  & .forJudge {
    width: 30%;
    min-width: 260px;
  }
  flex: wrap;
  &.questionnaireDetails {
    flex-direction: column;
  }
  & .titleQuestionnaire {
    width: 300px;
    text-align: left;
  }
  & .category {
    padding: 20px 0;
    text-transform: capitalize;
    font-weight: bold;
    text-align: left;
    color: ${ColorName.chathamsBlueDark};
  }
  & table {
    & td {
      text-align: center;
    }
    & .title {
      text-align: left;
    }
  }
`;

const FieldTitle = styled(BreadCrumb)`
  font-size: 17px;
  font-weight: 600;
  text-transform: none;
  color: ${ColorName.chathamsBlue};
  margin-bottom: ${(props) => props.marginBottom || "1.5em"};
  margin-right: ${(props) => props.marginRight || "1em"};
  &.inputEdit {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-transform: none;
    &.move {
      padding: 15px 0;
    }
  }
  &.title-upload {
    margin-left: 1em;
    margin-bottom: -30px;
  }
  & .action-button {
    white-space: nowrap;
  }
  &.button-export {
    display: flex;
    margin-top: 1em;
    justify-content: flex-end;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: ${ColorName.white};
  border-radius: 10px;
  border: 1px solid ${ColorName.gallery};
  position: relative;
  margin-left: ${(props) => props.marginLeft || "0px"};
  flex: 1;
`;

const WrapperInput = styled.input`
  border: 1px solid ${ColorName.chathamsBlue};
  height: auto;
  width: ${(props) => props.width || "18em"};
  margin-bottom: ${(props) => props.marginBottom || "2em"};
  border-radius: ${(props) => props.borderRadius || "0"};
  padding: 1em;
  outline: none;
  border-radius: 5px;
  margin-top: 0.6em;
  &.forQuestionnaire {
    background: lightGray;
    cursor: move;
  }
`;

const WrapperSelect = styled.select`
  border: 1px solid ${ColorName.chathamsBlue};
  height: auto;
  width: 22em !important;
  padding: 1em;
  outline: none;
  border-radius: 5px;
  margin-top: 0.6em;
  background-color: white !important;
`;

const CheckBoxContainer = styled(WrapperInput)`
  margin: 0 1em 0 0;
  padding: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  &:after {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -2px;
    left: -2px;
    position: relative;
    background-color: none;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid ${ColorName.white};
  }
  &:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -2px;
    left: -2px;
    position: relative;
    background-color: #ffa500;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid ${ColorName.white};
  }
`;

const WrapperTextarea = styled.textarea`
  background-color: ${ColorName.whisper};
  border: none;
  height: 20em;
  width: 34em;
  margin-bottom: ${(props) => props.marginBottom || "2em"};
`;

const WrapperContent = styled(HorizontalContainer)`
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2em;
  &.question {
    flex-direction: column;
    padding-left: 3em;
    align-items: flex-start;
    & div {
      padding: 10px 0;
    }
  }
  & .test {
    margin-bottom: 30px;
  }
`;

const WrapperButton = styled.div`
  display: flex;
  margin: 4em 0 0 1em;
  &.wrap-reduce {
    margin: 1em 0 0 1em;
  }
  &.wrap-category-questionnaire {
    margin: 3em 0 0 1em;
  }
`;

const WrapperModal = styled.div`
  display: flex;
  position: fixed;
  z-index: 9;
  background-color: rgba(16, 16, 16, 0.5);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

const HideModalContainer = styled.div`
  position: absolute;
  top: 29%;
  left: 70.3%;
  cursor: pointer;
  i {
    color: ${ColorName.white};
  }
`;

const ModalContent = styled(HorizontalContainer)`
  background: ${ColorName.white};
  border-radius: 25px;
  border: 3px solid ${ColorName.gallery};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

const WrapperTitleContent = styled(HorizontalContainer)`
  margin-bottom: 1em;
  align-items: center;
  width: 90%;
  & .title-select {
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .style-checkbox {
    display: flex;
    margin: 0 10px 0 0;
  }
  & .custom-title {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    min-width: 10em;
  }
  & .mt-2 {
    margin-top: 2em;
  }
  &.alignCenter {
    place-content: center;
  }
  &.just-center {
    justify-content: center;
  }
`;

const Field = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: inherit;
  margin: 0 0 2em 1em;
  &.move {
    cursor: move;
  }
  & .language {
    display: flex;
    align-items: center;
    margin-top: 0em;
    width: 8em;
    & p {
      white-space: nowrap;
    }
  }
  & .switch {
    margin-right: 1em;
  }
  & .upload-file {
    width: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
    & p {
      cursor: pointer;
    }
    i {
      margin-right: 0.2em;
      cursor: pointer;
    }
  }
  &.breakField {
    margin: 0px;
    min-width: 100vw;
  }
  &.input-deadline {
    div {
      width: unset !important;
      min-width: unset !important;
    }
  }
  select {
    border: 1px solid ${ColorName.chathamsBlue};
    height: auto;
    width: ${(props) => props.width || "18em"};
    margin-bottom: ${(props) => props.marginBottom || "2em"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 1em;
    outline: none;
    border-radius: 5px;
    margin-top: 0.6em;
    background: lightGray;
    color: black;
  }
  &.full-width-upload {
    min-width: 380px;
    display: flex;
    flex-direction: row;
  }
  &.full-width-upload-multiple {
    min-width: 380px;
    display: flex;
    flex-direction: row;
    & span {
      white-space: nowrap;
    }
    & .action-upload {
      display: flex;
      & span {
        padding: 0 0.5em;
      }
    }
  }
  & #sendmail {
    -webkit-appearance: auto;
    cursor: pointer;
  }
  & label {
    white-space: nowrap;
    color: ${ColorName.chathamsBlue};
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
  }
`;

const TitleNewField = styled.input`
  background: none;
  border: none;
  outline: none;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: bold;
  color: ${ColorName.chathamsBlue};
`;

const Category = styled.div`
  margin-top: 2em;
  & i {
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
`;

const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop || "0"};
  & #sendmail {
    -webkit-appearance: auto;
    margin-bottom: 10px;
  }
  & .title-deadline {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: ${ColorName.chathamsBlueDark};
  }
  & .fonts-status {
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    & span {
      &.pink {
        color: ${ColorName.redOrange};
      }
      &.green {
        color: ${ColorName.fern};
      }
      &.red {
        color: ${ColorName.sunsetOrange};
      }
    }
  }
  & .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: ${ColorName.chathamsBlueDark};
    margin-bottom: 10px;
  }
  & .description {
    font-size: 14px;
    color: gray;
    margin-top: 0.5em;
  }
  & .wrapCheckbox {
    display: flex;
    width: 120px;
    & input {
      -webkit-appearance: checkbox;
    }
  }
  & .wrapCheckboxBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & .left {
      width: 50px;
    }
    & .right {
      width: 50px;
    }
  }
`;

const Report = styled(HorizontalContainer)`
  flex: 1;
  margin-bottom: 3em;
`;

const BlockReport = styled.div`
  margin-right: 40px;
  flex: 1;
`;

const DropDownJudge = styled.select`
  margin: 0 0 20px 0;
  width: 200px;
  display: flex;
  -webkit-appearance: inner-spin-button;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  option {
    text-transform: uppercase;
  }
`;

const NameJudge = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 10px;
  & i:hover {
    color: red;
    cursor: pointer;
  }
`;

const GeneralButtonLanguage = styled(GeneralButton)`
  border: 1px solid ${ColorName.chathamsBlueDark};
  margin-right: 1em;
  background: ${ColorName.white};
  color: ${ColorName.chathamsBlueDark};
  &.active {
    background: ${ColorName.chathamsBlueDark};
    color: ${ColorName.white};
    &.disable {
      color: ${ColorName.white};
      background: #ccc;
      border: 1px solid #ccc;
      cursor: not-allowed;
    }
  }
  &.disable-unclick {
    background: none;
    border: 1px solid #ccc;
    color: #ccc;
    cursor: not-allowed;
  }
`;

export {
  BodyContainer,
  FieldTitle,
  ProgressBar,
  WrapperInput,
  WrapperTextarea,
  WrapperContent,
  WrapperButton,
  WrapperModal,
  HideModalContainer,
  ModalContent,
  WrapperTitleContent,
  Field,
  TitleNewField,
  Category,
  CheckBoxContainer,
  WrapperTitle,
  Report,
  BlockReport,
  DropDownJudge,
  NameJudge,
  ContentDetail,
  WrapperSelect,
  GeneralButtonLanguage,
};
