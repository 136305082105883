import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import AdminOverViewAction from "../../../actions/AdminOverView";
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import { BodyContainer } from "./Notification.styles";
import RoundedTable from "../../../components/Common/RoundedTable";
import CONSTANTS from "../../../constants/Constants";
import Utils from "../../../libs/Utils";
import Pagination from "../../../components/Common/NewPagination";

const AdminOverview = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const activities = useSelector((state) => state.AdminOverView.activities);
  const columnsActivity = ["date", "activity", "status", "action"];
  const columnsActivityUser = ["date", "activity", "action"];
  const isLoading = useSelector((state) => state.AdminOverView.isLoading);
  const isLoadingActive = useSelector((state) => state.UserOverview.isLoading);
  const listOptionsNotification = {
    1: "view",
    2: "delete",
  };
  
  const userData = Utils.getSavedUserData();
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [dataNotifications, setDataNotifications] = useState([]);

  useEffect(() => {
    let data = {};
    if (userData.role.name ===  CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
      data = {
        type: CONSTANTS.NOTIFICATION.NOTIFICATION_TYPE.RECENT_ACTIVITY,
        language: i18n.language,
        page: 1,
        item_per_page: 20,
      };
    } else {
      data = {
        type: CONSTANTS.NOTIFICATION.NOTIFICATION_TYPE.NOTIFICATION,
        language: i18n.language,
        page: 1,
        item_per_page: 20,
      };
    }
    dispatch(AdminOverViewAction.getActivitiesOverview(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataNotifications(activities.data)
    setPage(activities.page);
    setLastPage(activities.lastPage);
}, [activities]);

  const checkRole = () => {
    if (userData) {
      if (userData.role.name ===  CONSTANTS.ROLE_NAME.SUPER_ADMIN) return "admin";
      if (userData.role.name ===  CONSTANTS.ROLE_NAME.JUDGE) return "judge";
      if (userData.role.name ===  CONSTANTS.ROLE_NAME.LIMITED_ADMIN) return "limited-admin";
      return "user";
    }
  };
  const clickPageOrLink = (currentPage) => {
    let data = {};
    if (userData.role.name ===  CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
      data = {
        type: CONSTANTS.NOTIFICATION.NOTIFICATION_TYPE.RECENT_ACTIVITY,
        language: i18n.language,
        page: currentPage,
        item_per_page: 20,
      };
    } else {
      data = {
        type: CONSTANTS.NOTIFICATION.NOTIFICATION_TYPE.NOTIFICATION,
        language: i18n.language,
        page: currentPage,
        item_per_page: 20,
      };
    }
    dispatch(AdminOverViewAction.getActivitiesOverview(data));
  };

  const callBackPrev = () => {
    setPage(page - 1);
    clickPageOrLink(page - 1);
  };

  const callBackNext = () => {
    setPage(page + 1);
    clickPageOrLink(page + 1);
  };

  const pageOnclick = (e) => {
    clickPageOrLink(_.parseInt(e));
  };

  const _renderPagination = () => {
    return (
      <Pagination
        page={page}
        lastPage={lastPage || 1}
        callBackPrev={callBackPrev}
        callBackNext={callBackNext}
        pageOnclick={pageOnclick}
      />
    );
  };

  return (
    <DefaultLayout
      userRole={checkRole()}
      isLoading={isLoading || isLoadingActive}
      page={
        userData.role.name ===  CONSTANTS.ROLE_NAME.SUPER_ADMIN ? "adminOverview" : "userOverview"
      }
      content={
        <BodyContainer>
          <RoundedTable
            initialData={dataNotifications}
            columns={
              userData.role.name ===  CONSTANTS.ROLE_NAME.SUPER_ADMIN
                ? columnsActivity
                : columnsActivityUser
            }
            tableTitle={
              userData.role.name ===  CONSTANTS.ROLE_NAME.SUPER_ADMIN
                ? t("general.Recent Activity")
                : t("general.Notification")
            }
            tableType={
              userData.role.name ===  CONSTANTS.ROLE_NAME.SUPER_ADMIN
                ? CONSTANTS.TABLE_TYPE.NOTIFICATION
                : CONSTANTS.TABLE_TYPE.NOTIFICATION_USER
            }
            className="mt-2"
            showNumberItems={20}
            listOptions={listOptionsNotification}
          />
          {_renderPagination()}
        </BodyContainer>
      }
    />
  );
};

AdminOverview.propTypes = {};

export default AdminOverview;
