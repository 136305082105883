import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AdminSideBar from "./AdminSidebar";
import LimitedAdminSidebar from "./LimitedAdminSidebar";
import UserSideBar from "./UserSideBar";
import JudgeSideBar from "./JudgeSideBar";
import { ContentSideBar } from "./SideBar.styles";
import MessageAction from '../../../../actions/Message';
import Utils from "../../../../libs/Utils";

const SideBar = (props) => {
    const [userRole, setUserRole] = useState();
    const [page, setPage] = useState();
    const dispatch = useDispatch();
    const userSend = Utils.getSavedUserData();
    useEffect(() => {
        setUserRole(props.userRole);
        setPage(props.page);
    }, [props]);

    
    useEffect(() => {
        dispatch(MessageAction.getUnreadMessage(userSend.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentSideBar>
            {
                userRole === "admin"
                && <AdminSideBar page={page} />
            }
            {
                userRole === "limited-admin"
                && <LimitedAdminSidebar page={page} />
            }
            {
                userRole === "user"
                && <UserSideBar page={page} />
            }
            {
                userRole === "judge"
                && <JudgeSideBar page={page} />
            }
        </ContentSideBar>
    );
};

SideBar.propTypes = {
    userRole: PropTypes.string,
    page: PropTypes.string
};

export default SideBar;
