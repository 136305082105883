import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  WrapperInput,
  TitleInput,
  WrapperUserDetail
} from "./UserOverview.styles";
import {
  HorizontalContainer,
  PageTitle
} from "../../../../components/Common/CommonLayout";
import UserOverviewActions from "../../../../actions/UserOverview";
import Utils from "../../../../libs/Utils";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import Constants from "../../../../constants/Constants";
import Entrepreneur from "./Entrepreneur";
import Consultant from "./Consultant";
import Investors from "./Investor";
import ProgressBar from "../../../../components/Common/ProgressBar";

const AddViewUser = () => {
  const fileInput = useRef(null);
  const { t } = useTranslation();
  const userData = useSelector((state) => state.UserOverview.userDetail);

  const [fileAttach, setFileAttach] = useState('');
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const RolesNameStorage = useSelector((state) => state.UserOverview);
  useEffect(() => {
    setIsLoading(RolesNameStorage.isLoading);
  }, [RolesNameStorage]);

  useEffect(() => {
    const originalData = Utils.getSavedUserData();
    // dispatch(UserOverviewActions.getCurrentUserDetail(originalData.id));
    dispatch(UserOverviewActions.getUserReportRequest());
    dispatch(UserOverviewActions.getRolesNameRequest());
    dispatch(UserOverviewActions.getUserDetail(originalData.id));
    setImageUrl(originalData.userData.url_avatar_aws);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setFileAttach(e.target.files[0]);
    setImageUrl(url);
  };

  const _renderProgressBar = () => {
    return (
      <HorizontalContainer>
        <div className="progress-bar-user-profile">
          <ProgressBar
            value={RolesNameStorage.dataUserReport.percent_user_profile || 0}
            title={t("general.User Profile")}
            description={RolesNameStorage.dataUserReport.percent_user_profile === 100 ? `${t("general.Your Profile Is Completed")}.` : t("validation.Your profile is not complete")}
            showViewMore={true}
            filter="user profile"
            typePercent={true}
            total={100}
            className="for-user-overview"
          />
        </div>
      </HorizontalContainer>
    );
  };

  const _renderAvatarField = () => {
    return (
      <WrapperInput className="center-content">
        <TitleInput>{userData.fullName}</TitleInput>
        <img
          alt='profile'
          className="style-img"
          src={imageUrl || "https://awodev.com/images/default-forum-user.png"}
          onClick={() => fileInput.current.click()}
        />
        <input
          ref={fileInput}
          type="file"
          accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
          className='d-none'
          onChange={(e) => handleFileChange(e)}
        />
      </WrapperInput>
    );
  };

  return (
    <DefaultLayout
      userRole='user'
      isLoading={isLoading}
      page="setting"
      content={(
        <BodyContainer>
          {isLoading && <Loading />}
          <PageTitle>
            {t("general.Settings")}
          </PageTitle>
          {(!_.isEmpty(userData) && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) && _renderProgressBar()}
          <WrapperUserDetail>
            {_renderAvatarField()}
          </WrapperUserDetail>
          {!_.isEmpty(userData) && userData.role.name === Constants.ROLE_NAME.ENTREPRENEUR && (<Entrepreneur userData={userData} avatar={fileAttach} />)}
          {!_.isEmpty(userData) && userData.role.name === Constants.ROLE_NAME.CONSULTANT && (<Consultant userData={userData} avatar={fileAttach} />)}
          {!_.isEmpty(userData) && userData.role.name === Constants.ROLE_NAME.INVESTOR && (<Investors userData={userData} avatar={fileAttach} />)}
        </BodyContainer>
      )}
    />
  );
};

AddViewUser.propTypes = {

};

export default AddViewUser;
