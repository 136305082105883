import styled from 'styled-components';
import { VerticalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

const FundingDetailApplicationsComponent = styled(VerticalContainer)`
    font-size: 16px;
    font-weight: 600;
        color: ${ColorName.chathamsBlueDark};
    span {
    color: ${ColorName.gray};
	}
	& .d-flex {
		display: flex;
	}
	& .mt-title {
		margin-top: 5px;
	}
	& .w-title {
		width: 140px;
	}
	& .color-green {
		color: ${ColorName.fern}
	}
`;

export {
    FundingDetailApplicationsComponent
};
