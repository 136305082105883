import styled from "styled-components";
import { ColorName } from "../../../components/Variables";
import {
    ContentBodyContainer,
    FlexGridSixth,
    ContainerFullWidth
} from "../../../components/Common/CommonLayout";

const FlexGridSixthTemplate = styled(FlexGridSixth)`
    margin: 4em 1em;
    & .img-criterial {
        align-items: center;
        @media (max-width: 768px) {
            width: 50% !important;
        }
        @media (max-width: 520px) {
            width: 100% !important;
            margin-bottom: 1em;
        }
    }
    @media (max-width: 520px) {
        flex-direction: column;
    }
`;

const MainContent = styled(ContentBodyContainer)`
    // align-items: center;
    & .community{
        @media (max-width: 1660px) and (min-width: 1365px){
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
            & .content-min-width{
                max-width: 1140px;
                margin-left: 270px;
                margin-right: 10px;
            }
        }
    }
    padding: 0 1em;
    &.d-flex-row{ 
        display: flex;
        flex-direction: row;
        @media (max-width: 1365px) {
            flex-direction: column;
        }
    }
    & .sponsor-small-screen{
        margin: 3em 0 1em 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        & .item-small-screen{
            margin: 1em;
            width: 20%;
            justify-content: center;
            display: flex;
            min-width: 210px;
            padding: 0.5em;
            border: 1px solid;
            img {
                width: 100%;
                object-fit: contain;
            }
            @media (max-width: 768px) {
                place-content: flex-start;
            }
        }
        @media (max-width: 768px) {
            place-content: flex-start;
        }
        @media (min-width: 1365px) {
            display: none;
        }
    }
`;

const StepsWrapperLine = styled.div`
    border-left: 1px solid #62af55;
`;

const StepsContainer = styled.div`
    padding: 100px 35px 0 35px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    ${StepsWrapperLine}:last-child {
        border-left: none;
    }
    max-width: 600px;
`;

const StepLine = styled.div`
    padding: 0 0 120px 60px;
    position: relative;
    @media (max-width: 768px) {
        width: 80%;
    }
`;

const StepsWrapperSteps = styled.div`
    position: relative;
    border-left: 1px solid #62af55;
`;

const Marker = styled.div`
    background: #ffffff;
    border-radius: 30px;
    border: 1px solid #7cb4c7;
    content: "";
    left: -30px;
    position: absolute;
    top: 0;
    width: 60px;
    height: 60px;
    opacity: 1;
    transform: translate(0) scale(1);
    text-align: center;
    p {
        text-align: center;
        font-size: 1.9em;
        color: #323b43;
        margin: 10px 0px 0px 0px;
        font-weight: 300;
        color: ${ColorName.bahamaBlue};
    }
`;

const MarkerLabel = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: #2668a2;
    position: relative;
    top: -5px;
`;

const MarkerTitle = styled.div`
    font-size: 2.5em;
    margin: 15px 0;
    min-width: 540px;
    line-height: 1;
    font-weight: 0;
    color: #323b43;
    &.fix-margin {
        margin-top: 0px;
    }
    font-weight: 300;
    color: ${ColorName.bahamaBlue};
    @media (max-width: 520px) {
        font-size: 2em;
    }
`;

const MarkerText = styled.div`
    position: relative;
    font-weight: 300;
    color: ${ColorName.bahamaBlue};
`;

const TitleCommunity = styled.div`
    color: ${ColorName.bahamaBlue};
    font-weight: 300;
    font-size: 3em;
    text-align: center;
    margin: 1em 0;
`;

const ContextCommunity = styled.div`
    color: ${ColorName.bahamaBlue};
    font-size: 1em;
    text-align: center;
    margin: 2em auto;
    width: 80%;
    line-height: 1.5;
    font-weight: 300;
`;

const Result = styled.div`
    font-size: 2em;
    color: ${ColorName.bahamaBlue};
    margin: 2em auto;
    font-weight: 300;
`;

const ContainerListCard = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ApplyContent = styled.div`
    font-size: 1.2em;
    color: ${ColorName.bahamaBlue};
`;

const ContainerButton = styled.div`
    margin-left: 1em;
`;

const Container = styled(ContentBodyContainer)`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 5em 0;
    @media (max-width: 768px) {
        padding: 3em 1em;
        width: unset;
    }
`;

const ChildContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`;

const ContentIntroduce = styled.div`
    color: ${ColorName.bahamaBlue};
    font-size: 1.2em;
    text-align: left;
    margin: 4em auto;
    width: 80%;
    line-height: 1.5;
    font-weight: 300;
`;

const TitleIntroduce = styled.div`
    color: ${ColorName.bahamaBlue};
    font-weight: 300;
    font-size: 3.5em;
    text-align: center;
    margin: 1em 0;
`;

const ContentProcess = styled.div`
    color: ${ColorName.bahamaBlue};
    font-size: 1em;
    text-align: left;
    margin: 4em auto 0 auto;
    width: 50%;
    line-height: 1.5;
    font-weight: 300;
`;

const TitleCriteria = styled.div`
    color: ${ColorName.bahamaBlue};
    font-weight: 300;
    font-size: 3.5em;
    text-align: left;
    // padding: 1.5em 0 0.5em 0;
    @media (max-width: 520px) {
        font-size: 2.5em;
    }
    &.for-entrepreneurs{
        margin-bottom: 1em;
        width: 100%;
        text-align: left;
    }
`;

const ContentCriteria = styled.div`
    color: ${ColorName.bahamaBlue};
    font-size: 1em;
    text-align: left;
    line-height: 1.5;
    font-weight: 300;
    & i{
        font-size: 0.5em;
        padding: 0 1.5em;
    }
`;

const IconImage = styled.img`
    width: 150px;
    height: 150px;
    &.fixed-height {
        height: 186px !important;
    }
`;

const ApplyContentContainer = styled.div`
    border: 1px solid #1b4687;
    padding: 1.5em;
`;

const VideoContainer = styled.video`
    height: 25em;
    margin-top: 6em;
`;

const Sponsor = styled.div`
    &.display-sponsor{
        display: flex;
        flex-direction: column;
        margin-left: 1em;
        position: absolute;
        top: 50px;
        left: -30px;
        @media (max-width: 1365px) {
            display: none;
        }
    }
`;

const ItemSponsor = styled.div`
    width: 250px; 
    padding: 10px;
    border: 1px solid ${ColorName.bahamaBlue};
    margin-bottom: 10px;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    height: 50px;
    display: flex;
    place-content: center;
    & img{
        width: 180px;
        object-fit: scale-down;
    }
`;

const ContainerFullWidthCommunity = styled(ContainerFullWidth)`
    position: relative;
    & .d-flex-row{ 
        display: flex;
        flex-direction: row;
        max-width: 1400px;
        margin: 0 auto;
        @media (max-width: 1365px) {
            flex-direction: column;
        }
    }
`;

const PageTitle = styled.div`
    text-transform: uppercase;
    color: ${ColorName.bahamaBlue};
    font-weight: 300;
    font-size: 3.5em;
    text-align: center;
    margin-top: 1em;
`;

export {
    MainContent,
    StepsContainer,
    StepsWrapperSteps,
    StepLine,
    Marker,
    MarkerLabel,
    MarkerTitle,
    MarkerText,
    TitleCommunity,
    ContextCommunity,
    Result,
    ContainerListCard,
    ApplyContent,
    ContainerButton,
    Container,
    ChildContainer,
    ContentIntroduce,
    TitleIntroduce,
    ContentProcess,
    TitleCriteria,
    ContentCriteria,
    IconImage,
    FlexGridSixthTemplate,
    ApplyContentContainer,
    VideoContainer,
    Sponsor,
    ItemSponsor,
    PageTitle,
    ContainerFullWidthCommunity
};
