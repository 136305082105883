import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import RegiterActions from "../../../../actions/Register";
import {
    ConfirmContainer
} from "./LoginAndRegister.styles";
import Utils from "../../../../libs/Utils";
import UserPageTemplateV1 from "../../../../components/Layout/GuestTemplate/DefaultLayout";

export const Authentication = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const isConfirm = useSelector((state) => state.Register.isConfirm);
    const params = queryString.parse(props.location.search);
    useEffect(() => {
        Utils.setCurrentLanguage(params.lang);
        i18n.changeLanguage(params.lang);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const data = {
            email: params.e,
            secret_code: params.sc,
        };
        dispatch(RegiterActions.confirmEmail(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    return (
        <UserPageTemplateV1 context={(
            <ConfirmContainer>
                {isConfirm
                    && <a href="/">{t("general.Return Home Page")}</a>
                }
                {!isConfirm
                    && <div>{t("general.Please waitting to active your account")}</div>
                }
            </ConfirmContainer>
        )}
        />
    );
};

export default Authentication;
