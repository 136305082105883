import styled from 'styled-components';
import { VerticalContainer, HorizontalContainer, BreadCrumb } from "../../../../../components/Common/CommonLayout";
import { ColorName } from '../../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .container-button{
        display: flex;
        flex-direction: row-reverse;
    }
`;
const WrapperUserDetail = styled(HorizontalContainer)`
    align-items: center;
    margin-top: 2em;
    justify-content: end;
`;

const WrapperInput = styled(VerticalContainer)`
    flex: 1;
    display: flex;
    margin-bottom: 2em;
    & .hidden{
        display: none;
    }
    & .style-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
    }
    & .title-select{
        margin-left: 1em;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
    }
    & .jodit-wysiwyg{
        min-height: 300px !important;
    }
    & #uploadAvatar{
        display: none;
    }
    & .display-image-file{
        & img{
            max-width: 400px;
        }
    }
    &.container-upload{
        flex-direction: row;
        & .fa-upload{
            padding: 0 1em;
            &:hover{
                cursor: pointer;
            }
        }
        & .display-image-file{
            position: relative;
            display: flex;
            font-weight: 600;
            color: white;
            border: 1px solid lightGray;
            & .remove{
                position: absolute;
                top: -10px;
                right: -10px;
                display: flex;
                width: 15px;
                height: 15px;
                font-size: 1em;
                background: #737373;
                padding: 3px;
                border-radius: 50%;
                align-items: center;
                place-content: center;
                cursor: pointer;
            }
        }
    }
`;

const TitleInput = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${ColorName.chathamsBlueDark};
`;

const InputContainer = styled.input`
    background: none;
    border: 1px solid ${ColorName.chathamsBlue};
    height: auto;
    width: ${(props) => props.width || "20em"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 10px;
    outline: none;
`;
const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${ColorName.white};
    border-radius: 10px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    margin-left: ${(props) => props.marginLeft || "0px"};
    flex: 1;
`;

const SubInputTitle = styled.div`
    font-size: 0.5em;
`;

export {
    BodyContainer,
    InputContainer,
    TitleInput,
    WrapperInput,
    WrapperUserDetail,
    ProgressBar,
    SubInputTitle
};
