import Api from "./Base";

export function addMetaData(data) {
    return Api.postFormData("/meta_data", data);
}

export function getMetaData(data) {
    return Api.get("/meta_data_by_type", data);
}

export function updateMetaData(data) {
    return Api.postFormData("/meta_data", data);
}

export function updateMetaDataWithForm(data) {
    return Api.postFormData("/meta_data", data);
}
