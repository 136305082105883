import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";
import map from "@highcharts/map-collection/custom/world.geo.json";
import _ from "lodash";
import {
  Container,
  GridColHomepage,
  FlexGridSixthTemplate,
  Content,
  ItemContainer,
} from "./Events.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import SummryOverview from "../../../components/Common/SummaryOverview";
import SlickCarouselNews from "../../../components/Common/SlickCarouselNews";
import { ColorName } from "../../../components/Variables/index.js";
import EventActions from "../../../actions/Event";
import history from "../../../history.js";
import Utils from "../../../libs/Utils";
import Helmet from "react-helmet";
import { ReactSEOMetaTags } from "react-seo-meta-tags";

HighchartsMap(Highcharts);

const EventDetail = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const event = useSelector((state) => state.Event.dataEvent);
  const isLoading = useSelector((state) => state.Event.isLoading);
  const listSlide = useSelector((state) => state.Event.eventSlide);
  const [listEvent, setListEvent] = useState();
  const [siteMetadata, setSiteMetadata] = useState({});
  useEffect(() => {
    if (!params.id) return history.push("/homepage/events");
    const data = {
      language: i18n.language,
    };
    dispatch(EventActions.getEventById(params.id, data));
    dispatch(EventActions.getEventSlide(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!params.id) return history.push("/homepage/events");
    const data = {
      language: i18n.language,
      item: 8,
    };
    dispatch(EventActions.getEventById(params.id, data));
    dispatch(EventActions.getEventSlide(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (_.isNull(event)) {
      Utils.popupAlert({
        title: "Oops!",
        text: t("alert.This content has been removed"),
        type: "error",
      }).then(() => {
        history.push("/homepage");
      });
    }
    setSiteMetadata({
      title: event.title,
      description: event.description,
      image: _.get(event, "url_img_aws"),
    });
    const list = _.filter(listSlide, (item) => {
      return item.id !== _.parseInt(params.id);
    });
    setListEvent(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  useEffect(() => {
    const list = _.filter(listSlide, (item) => {
      return item.id !== _.parseInt(params.id);
    });
    setListEvent(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSlide]);

  const mapData = [
    {
      code3: "ABW",
      z: 105,
      code: "AW",
    },
    {
      code3: "AFG",
      z: 34656,
      code: "AF",
    },
    {
      code3: "AGO",
      z: 28813,
      code: "AO",
    },
    {
      code3: "ALB",
      z: 2876,
      code: "AL",
    },
    {
      code3: "AND",
      z: 77,
      code: "AD",
    },
  ];
  const options = {
    chart: {
      map,
      backgroundColor: ColorName.chathamsBlue,
      border: "none",
    },
    title: {
      text: "Event Location",
      color: "white",
      align: "left",
    },
    subtitle: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    series: [
      {
        name: "Countries",
        color: "#E0E0E0",
        enableMouseTracking: false,
      },
      {
        type: "mapbubble",
        name: "Population 2016",
        joinBy: ["iso-a3", "code3"],
        data: mapData,
        minSize: "3%",
        maxSize: "3%",
        showInLegend: false,
        color: "#2C3E4C",
        tooltip: {
          pointFormat: "{point.properties.hc-a2}: {point.z}",
        },
      },
    ],
    tooltip: {
      backgroundColor: null,
      borderWidth: 0,
      hideDelay: 1,
      shadow: false,
      useHTML: true,
      headerFormat: "",
      pointFormat: ``,
      footerFormat: "",
      crosshairs: [
        {
          zIndex: 4,
          dashStyle: "dot",
          snap: false,
          color: "gray",
        },
        {
          zIndex: 4,
          dashStyle: "dot",
          snap: false,
          color: "gray",
        },
      ],
    },
  };
  const formatType = (type) => {
    if (type === 1) return t("event.Online");
    return t("event.Offline");
  };
  const _renderContent = () => {
    if (event) {
      return (
        <div className="content-page">
          <FlexGridSixthTemplate>
            <GridColHomepage colSize="6" gridTemplate="6">
              <Content>
                <ItemContainer className=" abolute-width vertical">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    constructorType="mapChart"
                    containerProps={{ className: "chartContainer" }}
                    allowChartUpdate
                    immutable={false}
                    updateArgs={[true, true, true]}
                  />
                </ItemContainer>
              </Content>
              <Content>
                <div className="title">{event.title}</div>
                <div className="detail">
                  <span className="date-created">
                    {moment(event.date_event).format("DD-MM-YYYY")}
                  </span>
                  <span className="list-style">{event.location}</span>
                  <span className="list-style">{formatType(event.type)}</span>
                  {/* <span className="date-created list-style">Portfolio Company Event</span> */}
                </div>
                {_.get(event, "url_img_aws") && (
                  <div className="cover-image">
                    <img
                      src={_.get(event, "url_img_aws", "")}
                      alt={event.title}
                    />
                  </div>
                )}
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: event.content }}
                />
                {event.file_url && (
                  <div className="text">
                    {t("news.Attach File")}:
                    <a className="attach-file" href={event.file_url_aws}>
                      {_.last(event.file_url.split("/"))}
                    </a>
                  </div>
                )}
              </Content>
              <div className="line-page margin-line" />
              <div className="relate-post">
                &#8226;
                {t("event.Related Events")}
              </div>
              <SlickCarouselNews listData={listEvent} type="event" />
            </GridColHomepage>
          </FlexGridSixthTemplate>
        </div>
      );
    }
  };

  const _renderSummaryOverview = () => {
    return <SummryOverview />;
  };

  const _renderMain = () => {
    return (
      <Container>
        {siteMetadata && (
          <ReactSEOMetaTags
            render={(el) => <Helmet>{el}</Helmet>}
            website={{ ...siteMetadata }}
          />
        )}
        {_renderContent()}
        {_renderSummaryOverview()}
      </Container>
    );
  };

  return (
    <DefaultLayout
      context={_renderMain()}
      currentPage="news"
      isLoading={isLoading}
    />
  );
};

export default EventDetail;
