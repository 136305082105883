import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    applyApplicationDetail: {},
    applyApplicationReports: []
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.REPORT_APPLY_FUNDING_APPLICATION_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.REPORT_APPLY_FUNDING_APPLICATION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.REPORT_APPLY_FUNDING_APPLICATION_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                applyApplicationReports: payload
            };
        case ActionTypes.GET_REPORT_APPLY_FUNDING_APPLICATION_BY_ID:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                applyApplicationDetail: payload
            };
        default:
            return state;
    }
};
