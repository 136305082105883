import styled from 'styled-components';
import { ColorName } from "../../Variables";

const OptionDropDown = styled.div`
    border: 1px solid #e8e8e8;
    border-bottom: 0px;
    padding: 0.5em 1em;
    cursor: pointer;
    color: ${ColorName.chathamsBlueDark};
    &:last-child{
        border-bottom: 1px solid #e8e8e8;
    }
    &.new{
        background-color: #f9ebeb;
    }
    &:hover{
        background: #fee7ff;
    }
    &.titleNotification{
        display: flex;
        justify-content: space-between;
        background: #f9ebeb;
        font-size: 12px;
        font-weight: 600;
        cursor: default;
        & i{
            cursor: pointer;
            font-size: 12px;
        }
    }
`;

const DropDownUserHeader = styled.div`
    position: absolute;
    z-index: 9;
    top: 55px;
    width: 376px;
    right: 0;
    background: ${ColorName.white};
    &.hidden{
        display: none;
    }
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
`;
const DropDownContainer = styled.div`
    cursor: pointer;
`;
const Arrow = styled.div`
    position: absolute;
    right: 10px;
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 21px solid #f9ebeb;
`;

export {
    OptionDropDown,
    DropDownUserHeader,
    DropDownContainer,
    Arrow
};
