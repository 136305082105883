import _ from "lodash";
import ActionTypes from "../constants/ActionTypes";
import MetaTypes from "../constants/MetaTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";

const requestHasError = () => {
    return {
        type: ActionTypes.GUEST_DOCUMENT_TEMPLATE_SETTING_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.GUEST_DOCUMENT_TEMPLATE_SETTING_REQUEST
    };
};

const getDocumentTemplateSettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GUEST_DOCUMENT_TEMPLATE_SETTING_SUCCESS,
        payload
    };
};

const updateDocumentTemplateSettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GUEST_DOCUMENT_TEMPLATE_SETTING_SUCCESS,
        payload
    };
};

const getDocumentTemplateSettingData = () => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.fetchDocumentTemplate()
            .then((res) => {
                if (res.data.status === true) {
                    const documentTemplates = (res && res.data && res.data.data) ? res.data.data.data : [];
                    dispatch(getDocumentTemplateSettingDataSuccess(documentTemplates));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateDocumenTemplateSettingData = (formPayload) => {
    formPayload.append("type", MetaTypes.GUEST_DOCUMENT_TEMPLATE);
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateMetaDataWithForm(formPayload)
            .then((res) => {
                if (res.data.status === true) {
                    const metaData = _.get(res, 'data.data.data_json.aboutUsContent', null);
                    dispatch(updateDocumentTemplateSettingDataSuccess(metaData));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const addDocumentTemplateSettingData = (formPayload) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addDocumentTemplate(formPayload)
            .then((res) => {
                if (res.data.status === true) {
                    const documentTemplates = (res && res.data && res.data.data) ? res.data.data.data : [];
                    dispatch(getDocumentTemplateSettingDataSuccess(documentTemplates));
                    dispatch(getDocumentTemplateSettingData());
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const deleteDocumentTemplateSettingData = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.deleteDocumentTemplate(id)
            .then((res) => {
                if (res.data.status === true) {
                    const documentTemplates = (res && res.data && res.data.data) ? res.data.data.data : [];
                    dispatch(getDocumentTemplateSettingDataSuccess(documentTemplates));
                    dispatch(getDocumentTemplateSettingData());
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    getDocumentTemplateSettingData,
    updateDocumenTemplateSettingData,
    addDocumentTemplateSettingData,
    deleteDocumentTemplateSettingData
};
