import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from '../../../components/Layout/GuestTemplate/DefaultLayout';
import { GridCol } from '../../../components/Common/CommonLayout';
import {
    ContentCriteria,
    TitleCriteria,
    MainContent,
    IconImage,
    FlexGridSixthTemplate
} from './Investors.styles';
import NavigationBar from '../../../components/Common/NavigationBar';
import SummaryOverview from '../../../components/Common/SummaryOverview';
import Banner from '../../../components/Common/Banner';
import { Images } from '../../../themes';
const listNav = [
    {
        title: "What We Do",
        isActive: false,
        path: "/homepage/investors/what-we-do"
    },
    {
        title: "Community",
        isActive: false,
        path: "/homepage/investors/community"
    },
    {
        title: "Process",
        isActive: false,
        path: "/homepage/investors/process"
    },
    {
        title: "Apply",
        isActive: false,
        path: "/homepage/investors/apply"
    }
];
const _contentRender = () => {
    const { t } = useTranslation();
    return (
        <MainContent>
            <FlexGridSixthTemplate>
                <GridCol colSize='2' gridTemplate='6' className="flex-center img-criterial">
                    <IconImage src={Images.icon1} />
                </GridCol>
                <GridCol colSize='4' gridTemplate='6'>
                    <TitleCriteria>
                        {t("homepageUser.Conceptual Stage")}
                    </TitleCriteria>
                    <ContentCriteria>
                        This refers to individuals who have business proposals that are in a conceptual embryonic phase and have not
                        registered their business yet.
                    </ContentCriteria>
                </GridCol>
            </FlexGridSixthTemplate>
            <FlexGridSixthTemplate>
                <GridCol colSize='2' gridTemplate='6' className="flex-center img-criterial">
                    <IconImage src={Images.icon2} />
                </GridCol>
                <GridCol colSize='4' gridTemplate='6'>
                    <TitleCriteria>
                        Early Stage
                    </TitleCriteria>
                    <ContentCriteria>
                        This refers to entities being businesses, social enterprises that are already in the market, in early stages of
                        development, but existing no more than 10 years.
                    </ContentCriteria>
                </GridCol>
            </FlexGridSixthTemplate>
            <FlexGridSixthTemplate>
                <GridCol colSize='2' gridTemplate='6' className="flex-center img-criterial">
                    <IconImage src={Images.icon3} />
                </GridCol>
                <GridCol colSize='4' gridTemplate='6'>
                    <TitleCriteria>
                        Geographical Coverage
                    </TitleCriteria>
                    <ContentCriteria>
                        Dua Partner Invest welcomes startups located in Western Balkans including Albania, Serbia, North Macedonia,
                        Montenegro, Bosnia & Hercegovina and Kosovo.
                    </ContentCriteria>
                </GridCol>
            </FlexGridSixthTemplate>
            <FlexGridSixthTemplate>
                <GridCol colSize='2' gridTemplate='6' className="flex-center img-criterial">
                    <IconImage src={Images.icon4} className='fixed-height' />
                </GridCol>
                <GridCol colSize='4' gridTemplate='6'>
                    <TitleCriteria>
                        Innovation
                    </TitleCriteria>
                    <ContentCriteria>
                        We prioritize startups, which embrace innovation in terms of not only the main product/service, but also related to the
                        type of production, their chosen business model, channels and market.
                    </ContentCriteria>
                </GridCol>
            </FlexGridSixthTemplate>
            <FlexGridSixthTemplate>
                <GridCol colSize='2' gridTemplate='6' className="flex-center img-criterial">
                    <IconImage src={Images.icon5} className='fixed-height' />
                </GridCol>
                <GridCol colSize='4' gridTemplate='6'>
                    <TitleCriteria>
                        Feasibility
                    </TitleCriteria>
                    <ContentCriteria>
                        We look for feasible business ideas, which possess data that prove a product-market fit. Business ideas should have
                        tested their market and possess data on their early customers.
                    </ContentCriteria>
                </GridCol>
            </FlexGridSixthTemplate>
            <FlexGridSixthTemplate>
                <GridCol colSize='2' gridTemplate='6' className="flex-center img-criterial">
                    <IconImage src={Images.icon6} />
                </GridCol>
                <GridCol colSize='4' gridTemplate='6'>
                    <TitleCriteria>
                        Visionary Entrepreneurs
                    </TitleCriteria>
                    <ContentCriteria>
                        We are looking for visionary entrepreneurs who are constantly thinking of new ideas on how to improve their business
                        model, scale up their capacities beyond the region and create additional jobs.
                    </ContentCriteria>
                </GridCol>
            </FlexGridSixthTemplate>
            <FlexGridSixthTemplate className='custom-criteria'>
                <GridCol colSize='2' gridTemplate='6' className="flex-center img-criterial">
                    <IconImage src={Images.icon7} />
                </GridCol>
                <GridCol colSize='4' gridTemplate='6'>
                    <TitleCriteria>
                        Social and Environmental Impact
                    </TitleCriteria>
                    <ContentCriteria>
                        Startups must have a positive social impact. These ideas can mitigate a social problem or serve to a specific target
                        group. Their products/services should be eco-friendly and not harmful for the environment.
                    </ContentCriteria>
                </GridCol>
            </FlexGridSixthTemplate>
        </MainContent>
    );
};

const _mainRender = () => {
    return (
        <div>
            <Banner
                backgroundImg={Images.banner2}
                title="What are we looking for?"
            />
            <NavigationBar listNav={listNav} />
            {_contentRender()}
            <SummaryOverview />
        </div>
    );
};
const Criteria = () => {
    return (
        <DefaultLayout context={_mainRender()} currentPage="investor" currentTop={true} />
    );
};

export default Criteria;
