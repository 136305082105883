import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import {
    Tab,
    Tabs
} from "./NavigationBar.styles";

const UserOverviewNavigationBar = (props) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('all');

    const switchTab = (tab) => {
        setSelectedTab(tab);
        props.getCurrentTab(tab);
    };

    return (
        <Tabs>
            <Tab
                className={selectedTab === 'all' ? 'selected-tap' : ''}
                onClick={() => switchTab('all')}
                marginLeft="0"
            >
                {t("general.All")}
            </Tab>
            <Tab
                className={selectedTab === 'entrepreneur' ? 'selected-tap' : ''}
                onClick={() => switchTab('entrepreneur')}
            >
                {t("general.Entrepreneurs")}
            </Tab>
            <Tab
                className={selectedTab === 'investor' ? 'selected-tap' : ''}
                onClick={() => switchTab('investor')}
            >
                {t("general.Investors")}
            </Tab>
            <Tab
                className={selectedTab === 'consultant' ? 'selected-tap' : ''}
                onClick={() => switchTab('consultant')}
            >
                {t("general.Consultants")}
            </Tab>
        </Tabs>
    );
};

UserOverviewNavigationBar.propTypes = {
    title: PropTypes.string,
    selectedTab: PropTypes.string,
    getCurrentTab: PropTypes.func
};

export default UserOverviewNavigationBar;
