import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import connection from "../../../../libs/socket";
import MessageAction from "../../../../actions/Message";
import Messages from "./Messages";
import AddMessage from "./AddMessage";
import Utils from "../../../../libs/Utils";
import { MessageContainer } from "./Message.styles";
let subscription;

const Chat = (props) => {
  const [id, setId] = useState("");
  const messageStorage = useSelector((state) => state.Message.dataMessage);
  const listUsers = useSelector((state) => state.Message.listUsers);
  const [messages, setMessages] = useState([]);
  const [userReceiveData, setUserReceiveData] = useState("");
  const dispatch = useDispatch();

  const fetchMessages = async () => {
    const send_id = Utils.getSavedUserData().id;
    const data = {
      user_id_send: send_id,
      user_id_receive: userReceiveData.id,
    };
    if (!_.isUndefined(userReceiveData.id)) {
      await dispatch(MessageAction.getMessage(data));
    }
  };

  const fetchMessagesBackground = async () => {
    const send_id = Utils.getSavedUserData().id;
    const data = {
      user_id_send: send_id,
      user_id_receive: userReceiveData.id,
    };
    if (!_.isUndefined(userReceiveData.id)) {
      // TODO: add fucntion to fetch channel background when get new messages
      const existUser = _.find(listUsers, { 'id': userReceiveData.id });
      if (!existUser)
        await dispatch(MessageAction.getListUserMessage());
      await dispatch(MessageAction.getMessageBackground(data));
    }
  };

  useEffect(() => {
    setMessages(messageStorage);
  }, [messageStorage]);

  const handleMessageAdd = (mess) => {
    const { type } = mess;
    switch (type) {
      case "newMessage":
        fetchMessagesBackground();
        break;
      default:
    }
  };

  useEffect(() => {
    connection.connect();
    if (subscription) {
      subscription.close();
    }
    setUserReceiveData(props.userReceiveData);
    fetchMessages();
    if (props.channelId !== 0)
      subscription = connection.subscribe(
        `message:${props.channelId}`,
        handleMessageAdd
      );
    setId(Utils.getSavedUserData().id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    connection.connect();
    if (subscription) {
      subscription.close();
    }
    fetchMessages();
    if (props.channelId !== 0)
      subscription = connection.subscribe(
        `message:${props.channelId}`,
        handleMessageAdd
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.channelId]);

  useEffect(() => {
    setUserReceiveData(props.userReceiveData);
  }, [props.userReceiveData]);

  useEffect(() => () => {
    if (subscription) {
      subscription.close();
    }
    connection.close();
  }, []);

  return (
    <MessageContainer>
      <Messages data={messages} userReceiveData={userReceiveData} />
      <AddMessage userIdReceive={userReceiveData.id} userIdSend={id} />
    </MessageContainer>
  );
};

Chat.propTypes = {
  userReceiveData: PropTypes.object,
  channelId: PropTypes.number,
};

export default Chat;
