import React from "react";
import propType from "prop-types";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    PaginationContainer
} from './Pagination.styles';
const Pagination = (props) => {
    const { t } = useTranslation();
    const { page, lastPage } = props;

    const pageOnclick = (event) => {
        props.pageOnclick(event.target.dataset.page);
    };  
    const _renderNumberPage = () => {
        return Array.from(Array(_.parseInt(lastPage)), (e, i) => { // eslint-disable-line
            if(i < page - 5 || i > page + 3) {
            }
            else {
                if ((i + 1) === page) return <div className="page active" key={i}>{i + 1}</div>;
                return <div key={i} className="page" data-page={i + 1} onClick={(c) => pageOnclick(c)}>{i + 1}</div>;
            }
        });
    };

    const callBackPrev = () => {
        props.callBackPrev();
    };

    const callBackNext = () => {
        props.callBackNext();
    };
    return (
        <PaginationContainer>
            {page !== 1
                && (
                    <div className='prev' onClick={() => callBackPrev()}>
                        {t("general.Prev")}
                    </div>
                )
            }
            {page + 3 > 5 && <div>...</div>}
            {lastPage !== 1 && _renderNumberPage()}
            {page < lastPage - 5 && <div>...</div>}
            {(page >= 1 && page !== lastPage)
                && (
                    <div className='next' onClick={() => callBackNext()}>
                        {t("general.Next")}
                    </div>
                )
            }
        </PaginationContainer>
    );
};

Pagination.propTypes = {
    lastPage: propType.number,
    page: propType.number,
    callBackPrev: propType.func,
    pageOnclick: propType.func,
    callBackNext: propType.func
};

export default Pagination;
