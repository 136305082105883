import styled from 'styled-components';
import { ContentBodyContainer, FlexGridSixth } from '../../../components/Common/CommonLayout';
import { ColorName } from "../../../components/Variables";

const Container = styled(ContentBodyContainer)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &.pb-2 {
        padding-bottom: 2em;
    }
    @media (max-width: 768px) {
        margin: 0 1em;
    }
`;

const FlexGridSixthTemplate = styled(FlexGridSixth)`
    padding: 0 1em;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const HeaderTitle = styled.div`
    font-size: 3.2em;
    text-align: left;
    color: ${ColorName.chathamsBlueDark};
    background: ${ColorName.casablanca};
    font-weight: 300;
    text-align: center;
    padding: 1em 0;
    span {
        font-weight: 400;
    }
`;

const PageTitle = styled.div`
    text-align: center;
    color: ${ColorName.chathamsBlueDark};
    font-weight: 300;
    width: max-content;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    &.m-0 {
        margin: 0;
    }
    &.mb-0 {
        margin-bottom: 0;
    }
    &.w-60 {
        width: 60%;
    }
    &.w-70 {
        width: 70%;
    }
    & .page-title-header {
        font-size: 3.2em;
        margin: 0;
        letter-spacing: 4px;
        text-transform: uppercase;
        @media (max-width: 768px) {
            font-size: 2.2em;
        }
    }
    & .page-title-footer {
        font-size: 1em;
        margin: 0;
        border-bottom: 1px solid ${ColorName.fern};
        padding-bottom: 1.5em;
    }
    & .choose-type {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5em;
        justify-content: space-around;
    }
    & .type {
        display: flex;
        span {
            margin-left: 2em;
            padding-right: 1em;
        }
    }
    & .line-header {
        margin: 1em auto;
        width: 100%;
        border-bottom: 1px solid ${ColorName.fern};
    }
    & .footer-checkbox {
        display: flex;
        margin-top: 2em;
        justify-content: space-between;
        align-items: center;
        span {
            display: flex;
            align-items: center;
            margin-bottom: 1em;
            p {
                margin: 0 0 0 2em;
            }
        }
        div {
            margin-left: 2em;
        }
        div:first-child {
            margin-left: 0;
        }
    }
    & .bold{
        font-weight: 600;
    }
    & .disable {
        color: gray;
        label {
            border: 1px solid gray;
            &:hover::after {
                opacity: 0;
            }
        }
    }
`;

const FormItem = styled.div`
    &.center-content {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    & .d-none {
        display: none;
    }
    & .style-img{
        max-height: 200px;
    }
    margin-right: auto;
    width: 100%;
    & .wrapper-password {
      display: flex;
      align-items: center;
      position: relative;
      & .icon-eye {
	      color : #1B4687;
	      cursor: pointer;
	      margin-right: 5px;
        position: absolute;
		    right: 8px;
		    top: 5px;
      }
    }
    & .form-question {
        font-weight: 600;
        font-size: 1.2em;
        margin-bottom: 1em;
    }

    & .text-red {
        color: red;
        font-weight: 600;
        font-size: 1.2em;
        margin-bottom: 1em;
    }

    & .hidden-text-red {
        color: red;
        font-weight: 600;
        font-size: 0.7em;
    }
    & .link-highlight{
        color: ${ColorName.fern};
        margin: 0;
        text-decoration: underline;
    }
    span {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        p {
            margin: 0 0 0 2em;
            cursor: pointer;
        }
        & .condition{
            display: flex;
        }
    }
    & .line {
        margin: 2em auto;
        width: 60%;
        border-bottom: 1px solid ${ColorName.fern};
    }
    & .yes-case {
        p {
            margin: 0 0 0.5em 0;
            font-weight: 600;
        }
        margin-left: 2em;
        display: flex;
        flex-direction: column;
    }
    &.disable-row {
        p {
            color: gray;
        }
        input {
            border: 1px solid gray;
            &::placeholder {
                color: gray;
            }
        }
    }
    & .d-flex {
        display: flex;
        justify-content: space-between;
        div:first-child {
            flex: 2;
            margin-right: 1em;
        }
        div {
            flex: 1;
        }
    }
`;

const FormContainer = styled(Container)`
    color: ${ColorName.chathamsBlueDark};
    width: 70%;
    padding-bottom: 2em;
    @media (max-width: 768px) {
        width: 100%;
        padding: 2em 0em;
    }
`;

const SubmitButton = styled.button`
    border: none;
    padding: 0.5em 1.5em;
    color: white;
    background: ${ColorName.chathamsBlueDark};
    font-weight: 600;
    font-size: 1em;
    border: 1px solid ${ColorName.chathamsBlueDark};
    cursor: pointer;
    &.ml-2 {
        margin-left: 2em;
    }
    &.bg-white {
        background: white;
        color: ${ColorName.chathamsBlueDark};
        border: 1px solid ${ColorName.chathamsBlueDark};
    }
`;

const SelectionTag = styled.div`
    background: ${ColorName.chathamsBlueDark};
    color: white;
    display: flex;
    align-items: center;
    padding: 5px 1em;
    cursor: pointer;
    margin-right: 1em;
    margin-top: 1em;
    span {
        margin: 0;
    }
    i {
        margin-left: 1em;
    }
    
    @media (max-width: 420px) {
        margin-top: 1em;
    }
`;

const SelectionContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    @media (max-width: 420px) {
        flex-wrap: wrap;
    }
`;

const UploadContainer = styled.div`
    display: flex;
    width: 100%;
`;

const UploadListItem = styled.div`
    flex: 1;
    & .upload-item {
        background: ${ColorName.chathamsBlueDark};
        color: white;
        margin-bottom: 1em;
        padding: 5px 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const UploadTemplate = styled.div`
    background: ${ColorName.chathamsBlueDark};
    color: white;
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2em;
    input {
        display: none;
    }
`;

const UserTypeLabel = styled.span`
    text-transform: uppercase;
`;

export {
    Container,
    HeaderTitle,
    FlexGridSixthTemplate,
    PageTitle,
    FormItem,
    FormContainer,
    SubmitButton,
    SelectionTag,
    SelectionContainer,
    UploadContainer,
    UploadListItem,
    UploadTemplate,
    UserTypeLabel
};
