import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
    Wrapper,
    Container
} from "./LoginAndRegister.styles";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

const LoginAndRegister = (props) => {
    const [showScreen, setShowScreen] = useState('login');
    const isLogged = useSelector((state) => state.Authentication.isLogged);
    const isRegister = useSelector((state) => state.Register.isRegister);

    useEffect(() => {
        if (isLogged) return props.hideModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged]);

    useEffect(() => {
        if (isRegister) return props.hideModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRegister]);

    const switchScreen = (screen) => {
        setShowScreen(screen);
    };

    const hideModal = (event) => {
        if (event.target.classList.contains('wrapper')) {
            props.hideModal();
        }
    };

    return (
        <Wrapper onClick={(event) => hideModal(event)} className='wrapper'>
            <Container>
                <Login
                    callBackSwitchScreen={(screen) => switchScreen(screen)}
                    width={showScreen === 'login' ? "100%" : "0"}
                />
                <Register
                    callBackSwitchScreen={(screen) => switchScreen(screen)}
                    width={showScreen === 'register' ? "100%" : "0"}
                />
                <ForgotPassword
                    callBackSwitchScreen={(screen) => switchScreen(screen)}
                    width={showScreen === 'forgotPassword' ? "100%" : "0"}
                />
            </Container>
        </Wrapper>
    );
};

LoginAndRegister.propTypes = {
    hideModal: PropTypes.func
};

export default LoginAndRegister;
