import _ from "lodash";
import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";
import i18n from "../i18n";

const requestIsSuccess = () => {
    return {
        type: ActionTypes.FUNDING_APPLICATION_SUCCESS
    };
};

const requestHasError = () => {
    return {
        type: ActionTypes.FUNDING_APPLICATION_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.FUNDING_APPLICATION_REQUEST
    };
};

const requestJudgeIsLoading = () => {
    return {
        type: ActionTypes.JUDGE_REQUEST
    };
};

const setNewFundingApplicationData = (payload) => {
    return {
        type: ActionTypes.SET_NEW_FUNDING_APPLICATION_DATA,
        payload
    };
};

const setNewFundingQuestionnaireData = (payload) => {
    return {
        type: ActionTypes.SET_NEW_FUNDING_QUESTIONNAIRE_DATA,
        payload
    };
};

const getFundingApplicationByIdSuccess = (payload) => {
    return {
        type: ActionTypes.GET_FUNDING_APPLICATION_BY_ID,
        payload
    };
};

const setNewFieldCategory = (payload) => {
    return {
        type: ActionTypes.SET_NEW_FIELD_CATEGORY_FUNDING_APPLICATION_DATA,
        payload
    };
};

const setActionForCreateFunding = (payload) => {
    return {
        type: ActionTypes.SET_ACTION_CREATE_FUNDING,
        payload
    };
};

const setActionForEditOrViewFunding = (payload, id) => {
    return {
        type: ActionTypes.SET_ACTION_EDIT_OR_VIEW_FUNDING,
        payload,
        id
    };
};

const getListFundingApplicationIsSuccess = (payload) => {
    return {
        type: ActionTypes.GET_LIST_FUNDING_APPLICATION,
        payload
    };
};
const editFundingApplicationSuccess = (payload) => {
    return {
        type: ActionTypes.EDIT_FUNDING_APPLICATION,
        payload
    };
};

const getListJudgeSuccess = (payload) => {
    return {
        type: ActionTypes.GET_LIST_JUDGE,
        payload
    };
};

const addJudgeToApplicationSuccess = (payload) => {
    return {
        type: ActionTypes.ADD_JUDGE_TO_APPLICATION,
        payload
    };
};


const deleteDocumentSuccess = (payload) => {
    return {
        type: ActionTypes.GET_APPLY_BY_ID,
        payload
    };
};

const createNewFundingApplication = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.createNewFundingApplication(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestIsSuccess());
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    history.push("funding-applications");
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError(true));
            });
    };
};

const getListFundingApplication = () => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getListFundingApplication()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getListFundingApplicationIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const getListFundingApplicationHasData = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getFundingApplication(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getListFundingApplicationIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const getFundingApplicationById = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getFundingApplicationById(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getFundingApplicationByIdSuccess(Utils.convertSnakeKeyToCamelWithoutDocument(res.data.data)));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const editFundingApplication = (data, id, showPopup = false) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.editFundingApplication(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(editFundingApplicationSuccess(Utils.convertSnakeKeyToCamelWithoutDocument(res.data.data)));
                    if (showPopup) {
                        Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    } else {
                        history.push("funding-application-details");
                    }
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const getListJudge = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getListJudge(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getListJudgeSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const addJudgeToApplication = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addJudgeToApplication(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(addJudgeToApplicationSuccess(Utils.convertSnakeKeyToCamel(res.data.data)));
                    API.getFundingApplicationById(res.data.data.funding_application_id)
                        .then((respone) => {
                            if (respone.data.status === true) {
                                dispatch(getFundingApplicationByIdSuccess(Utils.convertSnakeKeyToCamel(respone.data.data)));
                                Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                            } else {
                                Utils.popupAlert({ title: "Oops...", text: respone.data.message, type: "error" });
                                dispatch(requestHasError());
                            }
                        }).catch((e) => {
                            const messageError = _.map(e.data, 'message').toString();
                            Utils.popupAlert({ title: "Oops...", text: messageError, type: "error" });
                            dispatch(requestHasError());
                        });
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const removeJudge = (id, fundingId) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.removeJudge(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestIsSuccess());
                    API.getFundingApplicationById(fundingId)
                        .then((respone) => {
                            if (respone.data.status === true) {
                                dispatch(getFundingApplicationByIdSuccess(Utils.convertSnakeKeyToCamel(respone.data.data)));
                            } else {
                                Utils.popupAlert({ title: "Oops...", text: respone.data.message, type: "error" });
                                dispatch(requestHasError());
                            }
                        }).catch((e) => {
                            const messageError = _.map(e.data, 'message').toString();
                            Utils.popupAlert({ title: "Oops...", text: messageError, type: "error" });
                            dispatch(requestHasError());
                        });
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const deleteApplyApplication = (id, fundingID) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.deleteApplyApplication(id)
            .then((res) => {
                if (res.data.status === true) {
                    API.getFundingApplicationById(fundingID)
                        .then((response) => {
                            if (res.data.status === true) {
                                dispatch(getFundingApplicationByIdSuccess(Utils.convertSnakeKeyToCamel(response.data.data)));
                            } else {
                                Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                                dispatch(requestHasError());
                            }
                        }).catch((e) => {
                            Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                            dispatch(requestHasError());
                        });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: e.data.message, type: "error" });
            });
    };
};

const deleteDocumentWinner = (id, data) => {
    return (dispatch) => {
        dispatch(requestJudgeIsLoading());
        API.deleteDocumentWinner(id, data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(deleteDocumentSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

export default {
    createNewFundingApplication,
    setNewFundingApplicationData,
    setNewFieldCategory,
    getListFundingApplication,
    setNewFundingQuestionnaireData,
    getFundingApplicationById,
    setActionForCreateFunding,
    setActionForEditOrViewFunding,
    editFundingApplication,
    getListJudge,
    addJudgeToApplication,
    removeJudge,
    getListFundingApplicationHasData,
    deleteApplyApplication,
    getFundingApplicationByIdSuccess,
    deleteDocumentWinner
};
