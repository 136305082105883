import API from "../api";
import Utils from "../libs/Utils";
import ActionTypes from "../constants/ActionTypes";

const getActivitiesIsSuccess = (payload) => {
    return {
        type: ActionTypes.GET_ACTIVITIES_SUCCESS,
        payload
    };
};

const adminOverViewIsLoading = () => {
    return {
        type: ActionTypes.ADMIN_OVER_VIEW_IS_LOADING
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.USER_OVERVIEW_REQUEST
    };
};

const setNotifications = (data) => {
    return {
        type: ActionTypes.GET_NOTIFICATIONS,
        data
    };
};

const updateNotification = (id) => {
    return () => {
        API.updateNotification(id)
            .then(() => {
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const deleteNotification = (id) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.deleteNotification(id)
            .then((res) => {
                dispatch(getActivitiesIsSuccess(res.data.data));
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const deleteNotificationUser = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.deleteNotification(id)
            .then((res) => {
                dispatch(setNotifications(res.data.data));
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    updateNotification,
    deleteNotification,
    deleteNotificationUser
};
