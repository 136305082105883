import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    sponsors: [],
    dataSponsor: {},
    sponsorID: 0
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.ADD_SPONSOR_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataSponsor: payload
            };
        case ActionTypes.SPONSOR_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.SPONSOR_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.GET_SPONSOR_BY_ID_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataSponsor: payload
            };
        case ActionTypes.GET_ALL_SPONSOR_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                sponsors: payload
            };
        case ActionTypes.SET_SPONSOR_ID_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                sponsorID: payload,
                dataSponsor: {}
            };
        case ActionTypes.UPDATE_SPONSOR_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataSponsor: payload
            };
        default:
            return state;
    }
};
