import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  Report,
  BlockReport,
  WrapperTitle,
  WrapperButton
} from "./Funding.styles";
import Button from '../../../../components/Common/Button';
import Constants from "../../../../constants/Constants";
import FundingApplicationUserActions from "../../../../actions/FundingApplicationsUser";
import history from "../../../../history";
import ProgressBar from "../../../../components/Common/ProgressBar";
import { ColorName } from "../../../../components/Variables";
import Utils from "../../../../libs/Utils";

const ApplicationsDetail = () => {
  const { t } = useTranslation();
  const userData = Utils.getSavedUserData();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const FundingStorage = useSelector((state) => state.FundingApplicationsUser);
  const [dataFundingUserReport, setDataFundingUserReport] = useState(0);
  const [dataOpenApplicationUserItem, setDataOpenApplicationUserItem] = useState();

  useEffect(() => {
    dispatch(FundingApplicationUserActions.getFundingUserReportRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_.isEmpty(FundingStorage.dataOpenApplicationUserItem)) {
      redirectPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FundingStorage.dataOpenApplicationUserItem]);

  useEffect(() => {
    setDataOpenApplicationUserItem(FundingStorage.dataOpenApplicationUserItem);
    setDataFundingUserReport(FundingStorage.dataFundingUserReport);
    setIsLoading(FundingStorage.isLoading);
  }, [FundingStorage]);

  const getApplicationUserByIDRequest = (id) => {
    dispatch(FundingApplicationUserActions.getOpenApplicationUserByIDRequest(id));
    dispatch(FundingApplicationUserActions.getFundingApplicationByIDRequest(id));
    history.push('user-apply-application');
  };

  const renderOtherRequirements = () => {
    if (_.isEmpty(dataOpenApplicationUserItem)) return;
    const render = _.map(dataOpenApplicationUserItem.extra_field_json, (item, index) => {
      return (
        <WrapperTitle marginTop="2em" key={index}>
          <div className="fonts-title">
            {item.title}
          </div>
          <div>{item.value}</div>
        </WrapperTitle>
      );
    });
    return render;
  };

  const scrollVertical = async (title) => {
    if (userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
      await history.push("user-funding");
      window.location.href = `${title}`;
    } else {
      history.push("user-overview");
    }
  };

  const redirectPage = () => {
    if (userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
      history.push("user-funding");
    } else {
      history.push("user-overview");
    }
  };

  return (
    <DefaultLayout
      userRole='user'
      isLoading={isLoading}
      page="userFunding"
      content={(
        <BodyContainer>
          <Report>
            <BlockReport>
              <ProgressBar
                value={dataFundingUserReport.my_application_amount || 0}
                title={t("general.My Funding Applications")}
                callBackAction={() => scrollVertical("#my-applications")}
                filter="user overview"
                total={100}
              />
            </BlockReport>
            {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER && (
              <BlockReport>
                <ProgressBar
                  value={dataFundingUserReport.funding_application_amount || 0}
                  title={t("fundingApplications.Available Funding Opportunities")}
                  callBackAction={() => scrollVertical("#apply-applications")}
                  filter="user overview"
                  total={100}
                />
              </BlockReport>
            )}
            <BlockReport />
          </Report>
          <WrapperTitle>
            <div className="title-deadline">{!_.isEmpty(dataOpenApplicationUserItem) && dataOpenApplicationUserItem.title}</div>
            <div className="fonts-status">
              <span>{t("general.Status")}</span>
              <div className="color-green">
                {!_.isEmpty(dataOpenApplicationUserItem)
                  && t(`rounderTable.${_.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, dataOpenApplicationUserItem.status))}`)}
              </div>
            </div>
          </WrapperTitle>
          <div>
            {renderOtherRequirements()}
          </div>
          {!_.isEmpty(dataOpenApplicationUserItem)
            && _.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, dataOpenApplicationUserItem.status)) === "ANNOUNCED"
            ? (
              <WrapperButton>
                <Button
                  background={ColorName.chathamsBlueDark}
                  title={t("general.Back")}
                  height="35px"
                  width="8em"
                  margin="0 1.5em 0 0"
                  onClick={() => history.go(-1)}
                />
              </WrapperButton>
            ) : (
              <WrapperButton>
                <Button
                  background={ColorName.chathamsBlueDark}
                  title={t("general.Back")}
                  height="35px"
                  width="8em"
                  margin="0 1.5em 0 0"
                  onClick={() => redirectPage()}
                />
                <Button
                  background={ColorName.chathamsBlueDark}
                  title={t("general.Apply Now")}
                  height="35px"
                  width="8em"
                  onClick={() => getApplicationUserByIDRequest(dataOpenApplicationUserItem.id)}
                />
              </WrapperButton>
            )
          }
        </BodyContainer>
      )}
    />
  );
};

export default ApplicationsDetail;
