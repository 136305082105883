import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    allFundingData: [],
    newFundingData: {},
    questionnaireData: {},
    actionFundingType: "",
    fundingId: "",
    newFieldData: {
        title: "",
        value: '',
        fieldType: '',
        maxCharacter: '',
        mandatory: ''
    },
    listJudge: []
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.FUNDING_APPLICATION_REQUEST:
            return {
                ...state,
                isLoading: true,
                hasError: false,
                isSuccess: false
            };
        case ActionTypes.FUNDING_APPLICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                isSuccess: true
            };
        case ActionTypes.FUNDING_APPLICATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasError: true,
                isSuccess: false
            };
        case ActionTypes.GET_LIST_FUNDING_APPLICATION:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                isSuccess: true,
                allFundingData: action.payload
            };
        case ActionTypes.SET_NEW_FUNDING_APPLICATION_DATA:
            return {
                ...state,
                newFundingData: payload
            };
        case ActionTypes.SET_NEW_FIELD_CATEGORY_FUNDING_APPLICATION_DATA:
            return {
                ...state,
                newFieldData: payload
            };
        case ActionTypes.SET_NEW_FUNDING_QUESTIONNAIRE_DATA:
            return {
                ...state,
                questionnaireData: payload
            };
        case ActionTypes.GET_FUNDING_APPLICATION_BY_ID:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                isSuccess: true,
                newFundingData: payload
            };
        case ActionTypes.SET_ACTION_CREATE_FUNDING:
            return {
                ...state,
                actionFundingType: payload,
                newFundingData: {},
                questionnaireData: {}
            };
        case ActionTypes.SET_ACTION_EDIT_OR_VIEW_FUNDING:
            return {
                ...state,
                actionFundingType: payload,
                fundingId: action.id,
                newFundingData: payload === "edit" ? {} : state.newFundingData
            };
        case ActionTypes.EDIT_FUNDING_APPLICATION:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                isSuccess: true,
                newFundingData: payload,
                fundingId: payload.id
            };
        case ActionTypes.GET_LIST_JUDGE:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                isSuccess: true,
                listJudge: payload
            };
        case ActionTypes.ADD_JUDGE_TO_APPLICATION:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                isSuccess: true,
                fundingId: payload.fundingApplicationId
            };
        default:
            return state;
    }
};
