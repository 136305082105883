import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";
import i18n from "../i18n";

const requestSuccess = (data = false) => {
    return {
        type: ActionTypes.REGISTER_SUCCESS,
        data
    };
};

const requestHasError = () => {
    return {
        type: ActionTypes.REGISTER_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.REGISTER_REQUEST
    };
};

const resetIsRegister = () => {
    return {
        type: ActionTypes.RESET_IS_REGISTER
    };
};

const confirmSuccess = () => {
    return {
        type: ActionTypes.CONFIRM_SUCCESS
    };
};

const registerUser = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addNewUser(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestSuccess(res.data.data));
                    Utils.popupAlert({
                        title: i18n.t("alert.Success"),
                        text: res.data.message,
                        type: "success"
                    }).then(() => {
                        dispatch(resetIsRegister());
                        history.push("/authentication");
                    });
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const confirmEmail = (data) => {
    return async (dispatch) => {
        dispatch(requestIsLoading());
        await API.confirmEmail(data)
            .then(async (res) => {
                if (res.data.status === true) {
                    await dispatch(confirmSuccess());
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" })
                        .then(() => {
                            dispatch(resetIsRegister());
                            history.push("/authentication");
                        });
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

export default {
    registerUser,
    confirmEmail
};
