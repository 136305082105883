import styled from 'styled-components';
import { ColorName } from '../../Variables';
import { Images } from '../../../themes';
const RightArrowButton = styled.div`
    display: block;
    margin: 30px auto;
    width: 25px;
    height: 25px;
    border-top: 2px solid #2D4567;
    border-left: 2px solid #2D4567;
    transform: rotate(135deg);
    &:before {
        content: '';
    }
`;

const LeftArrowButton = styled.div`
    display: block;
    margin: 30px auto;
    width: 25px;
    height: 25px;
    border-top: 2px solid #2D4567;
    border-left: 2px solid #2D4567;
    transform: rotate(-45deg);
    
    &:before {
        content: '';
    }
`;

const ItemCardLayout = styled.div`
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
    background-color: ${ColorName.oysterBay};
    .item-avatar {
        text-align: center;
        height: 230px;
        img {
            width: 100% !important;
            height: 100%;
            object-fit: scale-down;
        }
    }
    .item-username {
        padding: 1em 1em 0 1em;
        font-weight: 300;
        color: ${ColorName.chathamsBlueLighter};
    }
    .item-title {
        font-size: 0.7em;
        padding: 0 1.3em 0 1.4em;
        font-weight: 600;
        color: ${ColorName.bostonBlue};
        text-transform: uppercase;
        height: 35px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .item-description {
        color: ${ColorName.chathamsBlueLighter};
        font-size: 1em;
        line-height: 1.4em;
        font-weight: 300;
        padding: 1.5em 0.8em 0.4em 0.8em;
        background-image: url(${Images.sliderItem});
        background-position: -2px -33px;
        background-repeat: no-repeat;
        min-height: 320px;
        max-height: 320px;
    }
`;

const SliderContainer = styled.div`
    &.center-1-item {
        display: flex !important;
        justify-content: center !important;
        .slick-list{
            max-width: 350px !important;
        }
    }
    &.center-2-item {
        display: flex !important;
        justify-content: center !important;
        .slick-list{
            max-width: 700px !important;
        }
    }
    .slick-list{
        ${'' /* max-height: 400px; */}
        padding: 4em 0 4em 0;
    }
    .slick-slide {
        padding: 0 1em;
        box-sizing: border-box;
    }
    
`;

export {
    RightArrowButton,
    LeftArrowButton,
    ItemCardLayout,
    SliderContainer
};
