import styled from 'styled-components';
import { ColorName } from "../../Variables";
import {ContentBodyContainer} from "../CommonLayout";

const NavigationBarComponent = styled.div`
    margin: 0 auto;
    background-color: ${ColorName.botticelli};
    padding: 0 1em;z
`;

const ContentBody = styled(ContentBodyContainer)`
    display: flex;
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

const NavContent = styled.div`
    display: flex;
    flex: 1;
    margin: 0 auto;
    min-width: 450px;
    place-content: center;
    flex-wrap: wrap;
    & .d-flex {
        display: flex;
    }
    @media (max-width: 1024px) {
        min-width: unset;
    }
`;

const NavItem = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    padding: 20px 0 15px 0;
    margin: 0px 15px;
    cursor: pointer;
    color: ${ColorName.chathamsBlueDark};
    &.active{
        font-weight: 600;
        border-bottom: 3px solid ${ColorName.chathamsBlueDark};
    }
`;

const ButtonJoin = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
        justify-content: center;
        padding: 1em;
    }
`;


export {
    NavigationBarComponent,
    NavContent,
    NavItem,
    ButtonJoin,
    ContentBody
};
