import Api from "./Base";

export function getMessage(data) {
    return Api.get("/messages/get_messages", data);
}

export function addMessage(data) {
    return Api.postFormData("/messages", data);
}

export function getListUsers(data) {
    return Api.get("/users", data);
}

export function getListUserMessage() {
    return Api.get("/messages");
}

export function getChannel(data) {
    return Api.get("/messages/get_channel", data);
}

export function getBasicInfomation(id) {
    return Api.get(`/users/${id}/basic_information`);
}

export function deleteChannel(id) {
    return Api.post(`/messages/deleteChanel/${id}`);
}

export function getListUserMessageAdmin() {
    return Api.get("/messages/get_list_users_for_admin/");
}

export function updateReadedMessage(id, data) {
    return Api.post(`/messages/${id}/update_readed_message`, data);
}

export function getUnreadMessage(id) {
    return Api.get(`/messages/${id}/get_number_messages_unread`);
}

export function getListUsersAdmin(data) {
    return Api.get("/users_dashboard", data);
}