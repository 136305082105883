import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Container,
    GridColHomepage,
    Title,
    FlexGridSixthTemplate,
    ButtonJoin,
    ContainerFull
} from "./Homepage.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import GuestAnnouncementSetting from "../../../actions/AnnouncementSetting";
import SummryOverview from "../../../components/Common/SummaryOverview";
import { ColorName } from "../../../components/Variables/index.js";
import TestimonialSlider from "../../../components/Common/TestimonialSlider";
import Images from "../../../themes/Images";
import history from "../../../history";
const Homepage = () => {
    const dispatch = useDispatch();
    const guestAnnouncementSetting = useSelector(
        (state) => state.GuestAnnouncementSetting.announcementData
    );
    useEffect(() => {
        const data = {
            type: "announcement_setting"
        };
        dispatch(GuestAnnouncementSetting.getAnnouncementSettingData(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { t, i18n } = useTranslation();
    const _renderBannerHomepage = () => {
        return (
            <ContainerFull>
                <Container className="content-left">
                    <FlexGridSixthTemplate>
                        <GridColHomepage colSize="2" gridTemplate="6">
                            <div className="cta-header">{t("homepage.Access to finance and knowledge is rated as one of the biggest barriers for startups in the region today")}</div>
                            <div className="sologan">{t("homepage.We help you overcome that barrier")}</div>
                            <div className="container-button">
                                <ButtonJoin
                                    className="button-banner"
                                    value={t("general.Join the community")}
                                    type="button"
                                    color={ColorName.oysterBay}
                                    background={ColorName.chathamsBlueDark}
                                    onClick={() => history.push("/homepage/register")}
                                />
                                <ButtonJoin
                                    className="button-banner"
                                    value={t("homepage.Search new business ideas")}
                                    type="button"
                                    color={ColorName.chathamsBlueDark}
                                    background="transparent"
                                    onClick={() => history.push("/homepage/entrepreneurs/community")}
                                />
                            </div>
                        </GridColHomepage>
                    </FlexGridSixthTemplate>
                </Container>
            </ContainerFull>
        );
    };

    const _exploreNow = async () => {
        await dispatch(GuestAnnouncementSetting.setFilterByBusinessIdea(true));
        history.push("/homepage/entrepreneurs/community");
    };

    const _renderNewBusinessIdea = () => {
        return (
            <ContainerFull className="for-business-idea">
                <Container>
                    <FlexGridSixthTemplate>
                        <GridColHomepage colSize="6" gridTemplate="6" className="content-business-idea">
                            {t("homepage.New Business Ideas")}
                            <ButtonJoin
                                className="button-explore"
                                value={t("homepage.Explore now")}
                                type="button"
                                color={ColorName.chathamsBlueDark}
                                background="transparent"
                                onClick={() => _exploreNow()}
                            />
                        </GridColHomepage>
                    </FlexGridSixthTemplate>
                </Container>
            </ContainerFull>
        );
    };

    const _renderAnnoucement = () => {
        if (guestAnnouncementSetting) {
            return (
                <ContainerFull className="for-announcement">
                    <Container>
                        <FlexGridSixthTemplate>
                            <GridColHomepage colSize="4" gridTemplate="6" className="left-content">
                                <div className="title-announcement">{i18n.language === "en" ? guestAnnouncementSetting.data_json.form.en.title ? guestAnnouncementSetting.data_json.form.en.title : guestAnnouncementSetting.data_json.form.al.title : guestAnnouncementSetting.data_json.form.al.title ? guestAnnouncementSetting.data_json.form.al.title : guestAnnouncementSetting.data_json.form.en.title}</div>
                                <div className="content-announcement text" dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? guestAnnouncementSetting.data_json.form.en.content ? guestAnnouncementSetting.data_json.form.en.content : guestAnnouncementSetting.data_json.form.al.content : guestAnnouncementSetting.data_json.form.al.content ? guestAnnouncementSetting.data_json.form.al.content : guestAnnouncementSetting.data_json.form.en.content }} />
                                <div className="button-announcement">
                                    <ButtonJoin
                                        className="button-banner"
                                        value={t("general.Learn more")}
                                        type="button"
                                        color={ColorName.chathamsBlueDark}
                                        background="transparent"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = guestAnnouncementSetting.data_json.form.link;
                                        }}
                                    />
                                </div>
                            </GridColHomepage>
                            <GridColHomepage colSize="2" gridTemplate="6">
                                <img className="image-invite" src={guestAnnouncementSetting.data_media_json[0] || "https://via.placeholder.com/400x200.png"} alt="temp" />
                            </GridColHomepage>
                        </FlexGridSixthTemplate>
                    </Container>
                </ContainerFull>
            );
        }
    };
    const _renderInviteEntrepreneur = (reverse) => {
        return (
            <div className="invite-item">
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6"><Title>{t("homepage.Join as an entrepreneur")}</Title></GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="4" gridTemplate="6" className={reverse ? "border-top order-2" : "border-top order-1"}>
                        <ul>
                            <li>
                                <span className="title">{t("homepage.Free membership")}</span>
                                <span className="description">{t("homepage.Dua Partner Invest does not charge any fee for the members")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Inside access to investors")}</span>
                                <span className="description">{t("homepage.Dua Partner Invest provides you access to a rich and diverse pool of innovative startups form Western Balkans region")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Validate your idea")}</span>
                                <span className="description">{t("homepage.Use our community of Entrepreneurs, investors, consultants to validate your business idea and more them forward")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Get funding for your startup")}</span>
                                <span className="description">{t("homepage.Access funds from a community of investors with a successful business management and investment experience")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Get specialized consultancy for you startup")}</span>
                                <span className="description">{t("homepage.Find professional expertise from a pool of experienced consultants")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Meet other entrepreneurs")}</span>
                                <span className="description">{t("homepage.Build relationships through our community of entrepreneurs and explore opportunity together")}</span>
                            </li>
                        </ul>

                    </GridColHomepage>
                    <GridColHomepage colSize="2" gridTemplate="6" className={reverse ? "reverse" : ""}>
                        <img className="image-invite" src={Images.imageSlide} alt="temp" />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6" className={reverse ? "reverse" : ""}>
                        <ButtonJoin
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => history.push("/homepage/register")}
                        />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
            </div>
        );
    };
    const _renderInviteinvestor = (reverse) => {
        return (
            <div className="invite-item">
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6"><Title>{t("homepage.Join as an investor")}</Title></GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="4" gridTemplate="6" className={reverse ? "border-top order-2" : "border-top order-1"}>
                        <ul>
                            <li>
                                <span className="title">{t("homepage.Free membership")}</span>
                                <span className="description">{t("homepage.Dua Partner Invest does not charge any fee for the members")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Insider access to investors")}</span>
                                <span className="description">{t("homepage.Dua Partner Invest provides you a database of innovative startups form Western Balkans region")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Invest Promising Startups")}</span>
                                <span className="description">{t("homepage.Use our community of startups to invest and get satisfying return on investment rates")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Join forces with other investors")}</span>
                                <span className="description">{t("homepage.Build relationships through our community of investors and explore opportunities together")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Support promising startups")}</span>
                                <span className="description">{t("homepage.Dua Partner Invest provides you a diversity of ideas where you as an impact investment scheme, development program or donors can offer funds, know-how and networking opportunities aligning to your mission")}</span>
                            </li>
                        </ul>
                    </GridColHomepage>
                    <GridColHomepage colSize="2" gridTemplate="6" className={reverse ? "reverse" : ""}>
                        <img className="image-invite" src={Images.investorHomepage} alt="temp" />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6" className={reverse ? "reverse" : ""}>
                        <ButtonJoin
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => history.push("/homepage/register")}
                        />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
            </div>
        );
    };
    const _renderInviteConsultant = (reverse) => {
        return (
            <div className="invite-item">
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6"><Title>{t("homepage.Join as an consultant")}</Title></GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="4" gridTemplate="6" className={reverse ? "border-top order-2" : "border-top order-1"}>
                        <ul>
                            <li>
                                <span className="title">{t("homepage.Free membership")}</span>
                                <span className="description">{t("homepage.Dua Partner Invest does not charge any fee for the members")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Promote your consultant profile")}</span>
                                <span className="description">{t("homepage.Dua Partner Invest provides you space to promote your consultancy work to a pool of potential clients")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Support promising startups")}</span>
                                <span className="description">{t("homepage.Dua Partner Invest provides you a diversity of innovative startups where you can provide pro-bono consultancy")}</span>
                            </li>
                            <li>
                                <span className="title">{t("homepage.Be a part of a startup ecosystem")}</span>
                                <span className="description">{t("homepage.Build relationships through our community of entrepreneurs, investors and other experts and explore opportunity together")}</span>
                            </li>
                        </ul>
                    </GridColHomepage>
                    <GridColHomepage colSize="2" gridTemplate="6" className={reverse ? "reverse" : ""}>
                        <img className="image-invite" src={Images.invite3} alt="temp" />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6" className={reverse ? "reverse" : ""}>
                        <ButtonJoin
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => history.push("/homepage/register")}
                        />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
            </div>
        );
    };

    const _renderSummaryOverview = () => {
        return <SummryOverview />;
    };
    const _renderMain = () => {
        return (
            <Container>
                {_renderBannerHomepage()}
                {_renderNewBusinessIdea()}
                {_renderAnnoucement()}
                {_renderInviteEntrepreneur()}
                {_renderInviteinvestor(true)}
                {_renderInviteConsultant()}
                {_renderSummaryOverview()}
                <TestimonialSlider />
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
        />
    );
};

Homepage.propTypes = {
};

export default Homepage;
