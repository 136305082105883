import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import MessageAction from "../../../../actions/Message";
import MessageBoth from "./MessageBoth";
import useOutsideClick from "./ClickOutside";
import {
  BodyContainer,
  UserWrapper,
  MessageHorizontalContainer,
} from "./Message.styles";

const MessageManage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();
  const messageStorage = useSelector((state) => state.Message);
  const [listUser, setListUser] = useState([]);
  const [isShowDropDown, setIsshowDropDown] = useState(false);
  const [nameChannel, setNameChannel] = useState("");
  const [dataChannel, setDataChannel] = useState({});
  useOutsideClick(ref, () => {
    setIsshowDropDown(false);
  });

  useEffect(() => {
    dispatch(MessageAction.getListUserMessageAdmin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setListUser(messageStorage.listUserForAdmin);
  }, [messageStorage.listUserForAdmin]);

  const setChanelMessage = (channel) => {
    setNameChannel(getFullName(channel));
    setDataChannel(channel);
    setIsshowDropDown(false);
  };

  const getFullName = (channel) => {
    return `${`${channel.userSend.userData.first_name} ${channel.userSend.userData.last_name}`}
         - ${`${channel.userReceive.userData.first_name} ${channel.userReceive.userData.last_name}`}`;
  };

  const renderListUsersDropDown = () => {
    const render = _.map(listUser, (channel, index) => {
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <UserWrapper
          className="dropdown"
          key={index}
          onClick={() => setChanelMessage(channel)}
        >
          {getFullName(channel)} 
        </UserWrapper>
      );
    });
    return _.compact(render);
  };

  const filterListUser = (e) => {
    const filter = e.target.value;
    const listArrayUser = messageStorage.listUserForAdmin.filter(
      (o) =>
        o.userSend.userData.full_name
          .toLowerCase()
          .indexOf(filter.toLowerCase()) !== -1 ||
        o.userReceive.userData.full_name
          .toLowerCase() 
          .indexOf(filter.toLowerCase()) !== -1
    );
    setListUser(listArrayUser);
    setNameChannel(filter);
    setIsshowDropDown(true);
  };

  const _renderFilter = () => {
    return (
      <div className="container-filter-dropdown">
        <input
          type="text"
          value={nameChannel}
          placeholder={t("homepageUser.Search")}
          onChange={(e) => filterListUser(e)}
        />
        <i
          className={isShowDropDown ? "fa fa-sort-up" : "fa fa-sort-down"}
          onClick={() => setIsshowDropDown(!isShowDropDown)}
        />
      </div>
    );
  };

  return (
    <MessageHorizontalContainer>
      <div>
        <BodyContainer className="listUsers admin-manage">
          <div className="container-body-list" ref={ref}>
            {_renderFilter()}
            {listUser && isShowDropDown && renderListUsersDropDown()}
            {_.isEmpty(listUser) && (
              <div className="notFound">{t("general.No Data Found")}</div>
            )}
          </div>
        </BodyContainer>
      </div>
      <div className="container-with-title">
        <BodyContainer className="view-chat">
          {!_.isEmpty(dataChannel) && <MessageBoth dataChannel={dataChannel} />}
        </BodyContainer>
      </div>
    </MessageHorizontalContainer>
  );
};

export default MessageManage;
