import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    events: [],
    dataEvent: {},
    eventID: 0,
    eventSlide: []
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.ADD_EVENT_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataEvent: payload
            };
        case ActionTypes.EVENT_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.EVENT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.GET_EVENT_BY_ID_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataEvent: payload
            };
        case ActionTypes.GET_ALL_EVENT_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                events: payload
            };
        case ActionTypes.GET_EVENT_FOR_SLIDE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                eventSlide: payload
            };
        case ActionTypes.SET_EVENT_ID_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                eventID: payload,
                dataEvent: {}
            };
        case ActionTypes.UPDATE_EVENT_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataEvent: payload
            };
        default:
            return state;
    }
};
