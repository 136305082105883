const createActionMap = (actionList) => {
  const map = {};
  const actions = actionList.split("\n");
  actions.forEach((action) => {
    const name = action.trim();
    if (name !== "") {
      map[`${name}_REQUEST`] = `${name}_REQUEST`;
      map[`${name}_SUCCESS`] = `${name}_SUCCESS`;
      map[`${name}_FAILURE`] = `${name}_FAILURE`;
    }
  });
  return map;
};

const asyncActions = createActionMap(`
    LOGIN
    LOGOUT
    MESSAGE
    UPDATE_USER
    REGISTER
    USER_OVERVIEW
    FUNDING_APPLICATION
    ADMIN_OVER_VIEW
    FUNDING_APPLICATION_USER
    JUDGE
    REPORT_APPLY_FUNDING_APPLICATION
    SEARCH
    EXPORT
    USER_REGISTER
    PAGE_COMMUNITY
    NEWS
    PAGE_LIBRARY
    EVENT
    PROCESS
    GUEST_SUMMARY_SETTING
    GUEST_ABOUT_US_SETTING
    GUEST_DISCLOSURE_SETTING
    GUEST_TERM_SETTING
    GUEST_PRIVACY_POLICY_SETTING
    GUEST_TESTIMONIAL_SETTING
    GUEST_TESTIMONIAL_SETTING_LIST
    GUEST_APPLY_SETTING
    GUEST_DOCUMENT_TEMPLATE_SETTING
    SPONSOR
    CONTACT
    FAQ
    INTRODUCE
    CRITERIA
    ANNOUNCEMENT_SETTING
    SEARCH_HOMEPAGE
    SUBSCRIBE
    UNSUBSCRIBE
`);

const singleActions = {
  IS_LOGGED: "IS_LOGGED",
  LOGIN_IS_LOADING: "LOGIN_IS_LOADING",
  LOGIN_HAS_ERROR: "LOGIN_HAS_ERROR",
  LOGOUT: "LOGOUT",
  REQUEST_UPDATE_PASSWORD_USER_SUCCESS: "REQUEST_UPDATE_PASSWORD_USER_SUCCESS",
  MESSAGE_IS_LOADING: "MESSAGE_IS_LOADING",
  GET_LIST_USERS_SUCCESS: "GET_LIST_USERS_SUCCESS",
  GET_CHANNEL_SUCCESS: "GET_CHANNEL_SUCCESS",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  CONFIRM_SUCCESS: "CONFIRM_SUCCESS",
  GET_USER_EXPLORE: "GET_USER_EXPLORE",
  GET_USERS_FOR_MESSAGE: "GET_USERS_FOR_MESSAGE",
  GET_FUNDING_APPLICATION: "GET_FUNDING_APPLICATION",
  RESET_IS_REGISTER: "RESET_IS_REGISTER",
  SET_USER_RECEIVE: "SET_USER_RECEIVE",
  SET_NEW_FUNDING_APPLICATION_DATA: "SET_NEW_FUNDING_APPLICATION_DATA",
  SET_NEW_FIELD_CATEGORY_FUNDING_APPLICATION_DATA:
    "SET_NEW_FIELD_CATEGORY_FUNDING_APPLICATION_DATA",
  GET_USER_EXPLORE_BY_ID: "GET_USER_EXPLORE_BY_ID",
  CREATE_USER_EXPLORE: "CREATE_USER_EXPLORE",
  UPDATE_USER_EXPLORE: "UPDATE_USER_EXPLORE",
  GET_ROLES_NAME: "GET_ROLES_NAME",
  GET_ALL_LIST_USERS_SUCCESS: "GET_ALL_LIST_USERS_SUCCESS",
  ADMIN_OVER_VIEW_IS_LOADING: "ADMIN_OVER_VIEW_IS_LOADING",
  GET_LIST_FUNDING_APPLICATION: "GET_LIST_FUNDING_APPLICATION",
  SET_FILTER_USERS_SUCCESS: "SET_FILTER_USERS_SUCCESS",
  REPORT_ADMIN_SUCCESS: "REPORT_ADMIN_SUCCESS",
  GET_USER_REPORT: "GET_USER_REPORT",
  GET_APPLY_APPLICATION: "GET_APPLY_APPLICATION",
  GET_FUNDING_USER_REPORT: "GET_FUNDING_USER_REPORT",
  GET_OPEN_APPLICATION_USER: "GET_OPEN_APPLICATION_USER",
  GET_OPEN_APPLICATION_USER_BY_ID: "GET_OPEN_APPLICATION_USER_BY_ID",
  GET_USER_DETAILS_BY_ID: "GET_USER_DETAILS_BY_ID",
  GET_ROLES: "GET_ROLES",
  GET_ACTIVITIES_SUCCESS: "GET_ACTIVITIES_SUCCESS",
  GET_APPLY_APPLICATION_BY_ID: "GET_APPLY_APPLICATION_BY_ID",
  GET_REPORT_STATUS: "GET_REPORT_STATUS",
  SUBMIT_REPORT_STATUS: "SUBMIT_REPORT_STATUS",
  SET_NEW_FUNDING_QUESTIONNAIRE_DATA: "SET_NEW_FUNDING_QUESTIONNAIRE_DATA",
  GET_FUNDING_APPLICATION_BY_ID: "GET_FUNDING_APPLICATION_BY_ID",
  SET_ACTION_EDIT_OR_VIEW_FUNDING: "SET_ACTION_EDIT_OR_VIEW_FUNDING",
  SET_ACTION_CREATE_FUNDING: "SET_ACTION_CREATE_FUNDING",
  EDIT_FUNDING_APPLICATION: "EDIT_FUNDING_APPLICATION",
  GET_LIST_JUDGE: "GET_LIST_JUDGE",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  GET_FUNDING_APPLY_APPLICATION_BY_ID: "GET_FUNDING_APPLY_APPLICATION_BY_ID",
  SUBMIT_APPLY_APPLICATION: "SUBMIT_APPLY_APPLICATION",
  ADD_JUDGE_TO_APPLICATION: "ADD_JUDGE_TO_APPLICATION",
  UPDATE_APPLY_APPLICATION_DETAIL: "UPDATE_APPLY_APPLICATION_DETAIL",
  GET_APPLICATIONS_SUCCESS: "GET_APPLICATIONS_SUCCESS",
  GET_JUDGE_APPLY_APPLICATION_BY_ID: "GET_JUDGE_APPLY_APPLICATION_BY_ID",
  GET_APPLY_BY_ID: "GET_APPLY_BY_ID",
  RESET_FILTER: "RESET_FILTER",
  CHANGE_STATUS_APPLY_SUCCESS: "CHANGE_STATUS_APPLY_SUCCESS",
  GET_MESSAGE_BACKGROUND_SUCCESS: "GET_MESSAGE_BACKGROUND_SUCCESS",
  SET_USER_ID: "SET_USER_ID",
  ADMIN_OVERVIEW_SET_APPLY_ID: "ADMIN_OVERVIEW_SET_APPLY_ID",
  GET_REPORT_APPLY_FUNDING_APPLICATION_BY_ID:
    "GET_REPORT_APPLY_FUNDING_APPLICATION_BY_ID",
  SEARCH_IS_LOADING: "SEARCH_IS_LOADING",
  EXPORT_IS_LOADING: "EXPORT_IS_LOADING",
  GET_EVALUATION_QUESTIONNAIRE_SUCCESS: "GET_EVALUATION_QUESTIONNAIRE_SUCCESS",
  ADMIN_GET_EVALUATION_QUESTIONNAIRE_SUCCESS:
    "ADMIN_GET_EVALUATION_QUESTIONNAIRE_SUCCESS",
  GET_EVALUATION_QUESTIONNAIRE_BY_ID_SUCCESS:
    "GET_EVALUATION_QUESTIONNAIRE_BY_ID_SUCCESS",
  SET_EVALUATION_ID: "SET_EVALUATION_ID",
  RESET_EVALUATION_ID: "RESET_EVALUATION_ID",
  ADMIN_GET_EVALUATION_APPLY_SUCCESS: "ADMIN_GET_EVALUATION_APPLY_SUCCESS",
  USER_REGISTER_SET_USER_ROLE: "USER_REGISTER_SET_USER_ROLE",
  DELETE_CHANNEL_SUCCESS: "DELETE_CHANNEL_SUCCESS",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  GET_LIST_USERS_ADMIN_SUCCESS: "GET_LIST_USERS_ADMIN_SUCCESS",

  GET_NEWS_BY_ID_SUCCESS: "GET_NEWS_BY_ID_SUCCESS",
  ADD_NEWS_SUCCESS: "ADD_NEWS_SUCCESS",
  GET_ALL_NEWS_SUCCESS: "GET_ALL_NEWS_SUCCESS",
  SET_NEWS_ID_SUCCESS: "SET_NEWS_ID_SUCCESS",
  UPDATE_NEWS_SUCCESS: "UPDATE_NEWS_SUCCESS",
  PAGE_LIBRARY_SET_DETAIL_DATA: "PAGE_LIBRARY_SET_DETAIL_DATA",
  PAGE_LIBRARY_SET_ALL_DATA: "PAGE_LIBRARY_SET_ALL_DATA",
  GET_EVENT_BY_ID_SUCCESS: "GET_EVENT_BY_ID_SUCCESS",
  ADD_EVENT_SUCCESS: "ADD_EVENT_SUCCESS",
  GET_ALL_EVENT_SUCCESS: "GET_ALL_EVENT_SUCCESS",
  SET_EVENT_ID_SUCCESS: "SET_EVENT_ID_SUCCESS",
  UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
  GET_PROCESS_BY_TYPE_SUCCESS: "GET_PROCESS_BY_TYPE_SUCCESS",
  ADD_PROCESS_SUCCESS: "ADD_PROCESS_SUCCESS",
  GET_EVENT_FOR_SLIDE_SUCCESS: "GET_EVENT_FOR_SLIDE_SUCCESS",
  GET_NEWS_FOR_SLIDE_SUCCESS: "GET_NEWS_FOR_SLIDE_SUCCESS",
  GET_TESTIMONIAL_BY_ID_SUCCESS: "GET_TESTIMONIAL_BY_ID_SUCCESS",
  SET_TESTIMONIAL_ID_SUCCESS: "SET_TESTIMONIAL_ID_SUCCESS",
  GET_GUEST_APPLY_SETTING_SUCCESS: "GET_GUEST_APPLY_SETTING_SUCCESS",
  GET_PROCESS_BY_TYPE_REGISTER_SUCCESS: "GET_PROCESS_BY_TYPE_REGISTER_SUCCESS",
  GET_GUEST_DOCUMENT_TEMPLATE_SETTING_SUCCESS:
    "GET_GUEST_DOCUMENT_TEMPLATE_SETTING_SUCCESS",
  USER_OVERVIEW_SET_USER_DETAIL: "USER_OVERVIEW_SET_USER_DETAIL",
  ADD_SPONSOR_SUCCESS: "ADD_SPONSOR_SUCCESS",
  UPDATE_SPONSOR_SUCCESS: "UPDATE_SPONSOR_SUCCESS",
  GET_SPONSOR_BY_ID_SUCCESS: "GET_SPONSOR_BY_ID_SUCCESS",
  GET_ALL_SPONSOR_SUCCESS: "GET_ALL_SPONSOR_SUCCESS",
  SET_SPONSOR_ID_SUCCESS: "SET_SPONSOR_ID_SUCCESS",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
  ADD_FAQ_SUCCESS: "ADD_FAQ_SUCCESS",
  GET_INTRODUCE_BY_TYPE_SUCCESS: "GET_INTRODUCE_BY_TYPE_SUCCESS",
  ADD_INTRODUCE_SUCCESS: "ADD_INTRODUCE_SUCCESS",
  ADD_CRITERIA_SUCCESS: "ADD_CRITERIA_SUCCESS",
  UPDATE_CRITERIA_SUCCESS: "UPDATE_CRITERIA_SUCCESS",
  GET_CRITERIA_BY_ID_SUCCESS: "GET_CRITERIA_BY_ID_SUCCESS",
  GET_ALL_CRITERIA_SUCCESS: "GET_ALL_CRITERIA_SUCCESS",
  SET_CRITERIA_ID_SUCCESS: "SET_CRITERIA_ID_SUCCESS",
  GENERATE_LINK_SUCCESS: "GENERATE_LINK_SUCCESS",
  GUEST_APPLY_SETTING_RESET: "GUEST_APPLY_SETTING_RESET",
  GET_ANNOUNCEMENT_SETTING_SUCCESS: "GET_ANNOUNCEMENT_SETTING_SUCCESS",
  SET_FILTER_SUCCESS: "SET_FILTER_SUCCESS",
  GET_CRITERIA_SETTING_SUCCESS: "GET_CRITERIA_SETTING_SUCCESS",
  SET_SEARCH_HOMEPAGE_DEFAULT: "SET_SEARCH_HOMEPAGE_DEFAULT",
  SET_VALUE_SEARCH: "SET_VALUE_SEARCH",
  SET_NUMBER_UNREAD_MESSAGE_SUCCESS: "SET_NUMBER_UNREAD_MESSAGE_SUCCESS",
  RESET_NEWS_SUCCESS: "RESET_NEWS_SUCCESS",
  GET_ALL_ACTIVITIES_SUCCESS: "GET_ALL_ACTIVITIES_SUCCESS"
};

const types = {
  ...asyncActions,
  ...singleActions,
};

export default types;
