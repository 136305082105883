import React from "react";
import { Route } from "react-router-dom";

import Homepage from "../containers/GuestContainer/Homepage";
import About from "../containers/GuestContainer/About";
import Footer from "../components/Layout/GuestTemplate/Footer";
import Register from "../containers/GuestContainer/UserRegister";
import Investors from "../containers/GuestContainer/UserRegister/Investors/index";
import Entrepreneur from "../containers/GuestContainer/UserRegister/Entrepreneur/index";
import Consultant from "../containers/GuestContainer/UserRegister/Consultant/index";
// import SearchBar from "../components/Common/SearchBar";
import CompanyCard from "../components/Common/CompanyCard";
import CommunityConsultants from "../containers/GuestContainer/Consultants/Community";
import ApplyConsultants from "../containers/GuestContainer/Consultants/Apply";
import IntroduceConsultants from "../containers/GuestContainer/Consultants/Introduce";
import ProcessConsultants from "../containers/GuestContainer/Consultants/Process";
import CommunityEntrepreneurs from "../containers/GuestContainer/Entrepreneurs/Community";
import ApplyEntrepreneurs from "../containers/GuestContainer/Entrepreneurs/Apply";
import IntroduceEntrepreneurs from "../containers/GuestContainer/Entrepreneurs/Introduce";
import ProcessEntrepreneurs from "../containers/GuestContainer/Entrepreneurs/Process";
import CriteriaEntrepreneurs from "../containers/GuestContainer/Entrepreneurs/Criteria";
import CommunityInvestors from "../containers/GuestContainer/Investors/Community";
import ApplyInvestors from "../containers/GuestContainer/Investors/Apply";
import IntroduceInvestors from "../containers/GuestContainer/Investors/Introduce";
import ProcessInvestors from "../containers/GuestContainer/Investors/Process";
import CriteriaInvestors from "../containers/GuestContainer/Investors/Criteria";

import Events from "../containers/GuestContainer/Events";
import EventsDetail from "../containers/GuestContainer/Events/Detail";
import Publications from "../containers/GuestContainer/Publications";
import News from "../containers/GuestContainer/News";
import Terms from "../containers/GuestContainer/Terms";
import PrivacyPolicy from "../containers/GuestContainer/PrivacyPolicy";
import Disclosure from "../containers/GuestContainer/Disclosure";
import FAQ from "../containers/GuestContainer/FAQ";
import Library from "../containers/GuestContainer/Library";
import LibraryDetail from "../containers/GuestContainer/Library/Details";
import NewsDetail from "../containers/GuestContainer/News/Detail";
import LimitedUser from "../containers/GuestContainer/LimitedUser";
import ForgotPassword from "../containers/GuestContainer/LimitedUser/ForgotPassword";
import LandingPage from "../containers/GuestContainer/LandingPage";
import RegisterLimitedUser from "../containers/GuestContainer/LimitedUser/RegisterLimitedUser";
import ChangePassword from "../containers/PortalContainer/Authentication/ChangePassword";
import ChangePasswordLimited from "../containers/PortalContainer/Authentication/ChangePasswordLimited";
import Search from "../containers/GuestContainer/Search";
import Subscribe from "../containers/GuestContainer/Subscribe";
import Unsubscribe from "../containers/GuestContainer/Subscribe/Unsubscribe";

const GuestRoute = () => (
    <div>
        <Route exact path="/homepage/component-footer" component={Footer} />
        <Route exact path="/homepage/search" component={Search} />
        <Route exact path="/homepage/card" component={CompanyCard} />
        <Route exact path="/homepage/change-password" component={ChangePassword} />
        <Route exact path="/homepage/change-password-limited" component={ChangePasswordLimited} />
        <Route exact path="/" component={Homepage} />
        <Route exact path="/homepage" component={Homepage} />
        <Route exact path="/homepage/about" component={About} />
        <Route exact path="/homepage/register/Investor" component={Investors} />
        <Route exact path="/homepage/register" component={Register} />
        <Route exact path="/homepage/register/Entrepreneur" component={Entrepreneur} />
        <Route exact path="/homepage/register/Consultant" component={Consultant} />

        <Route exact path="/homepage/consultants/process" component={ProcessConsultants} />
        <Route exact path="/homepage/consultants/community" component={CommunityConsultants} />
        <Route exact path="/homepage/consultants/apply" component={ApplyConsultants} />
        <Route exact path="/homepage/consultants/what-we-do" component={IntroduceConsultants} />
        {/* <Route exact path="/homepage/consultants/criteria" component={CriteriaConsultant} /> */}

        <Route exact path="/homepage/entrepreneurs/process" component={ProcessEntrepreneurs} />
        <Route exact path="/homepage/entrepreneurs/community" component={CommunityEntrepreneurs} />
        <Route exact path="/homepage/entrepreneurs/apply" component={ApplyEntrepreneurs} />
        <Route exact path="/homepage/entrepreneurs/what-we-do" component={IntroduceEntrepreneurs} />
        <Route exact path="/homepage/entrepreneurs/criteria" component={CriteriaEntrepreneurs} />

        <Route exact path="/homepage/investors/process" component={ProcessInvestors} />
        <Route exact path="/homepage/investors/community" component={CommunityInvestors} />
        <Route exact path="/homepage/investors/apply" component={ApplyInvestors} />
        <Route exact path="/homepage/investors/what-we-do" component={IntroduceInvestors} />
        <Route exact path="/homepage/investors/criteria" component={CriteriaInvestors} />

        <Route exact path="/homepage/events" component={Events} />
        <Route exact path="/homepage/events/detail" component={EventsDetail} />
        <Route exact path="/homepage/publications" component={Publications} />
        <Route exact path="/homepage/news" component={News} />
        <Route exact path="/homepage/news/detail" component={NewsDetail} />
        <Route exact path="/homepage/terms" component={Terms} />
        <Route exact path="/homepage/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/homepage/disclosure" component={Disclosure} />
        <Route exact path="/homepage/faq" component={FAQ} />
        <Route exact path="/homepage/library" component={Library} />
        <Route exact path="/homepage/library/book-detail" component={LibraryDetail} />

        <Route exact path="/homepage/update-limited-user" component={LimitedUser} />
        <Route exact path="/landing-page" component={LandingPage} />
        <Route exact path="/homepage/add-limited-user" component={RegisterLimitedUser} />
        <Route exact path="/homepage/forgot-password" component={ForgotPassword} />
        <Route exact path="/homepage/subscribe" component={Subscribe} />
        <Route exact path="/homepage/unsubscribe" component={Unsubscribe} />
    </div>
);

export default GuestRoute;
