import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import JoditEditor from "jodit-react";
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput
} from "./Criteria.styles";
import {
    PageTitle,
    GeneralButton,
    FlexGridSixth,
    GridCol
} from "../../../../../components/Common/CommonLayout";
import RoundedTable from "../../../../../components/Common/RoundedTable";
// import Pagination from "../../../../../components/Common/NewPagination";
import Constants from "../../../../../constants/Constants";
import CriteriaActions from "../../../../../actions/Criteria";
import { ColorName } from '../../../../../components/Variables';
import history from '../../../../../history';
import Utils from '../../../../../libs/Utils';
import {
    SwitchButton,
    SwitchLanguageButton,
    EnableMultipleLanguageButtonContainer
} from "../../../../../components/Common/EnableMultipleLanguageButton";
import Validation from '../../../../../libs/Validation';

const Criteria = () => {
    const { t, i18n } = useTranslation();
    const editorAl = useRef(null);
    const editor = useRef(null);
    const [contentEn, setContentEn] = useState('');
    const [contentAl, setContentAl] = useState('');
    const dispatch = useDispatch();
    const columns = ["image", "title", "action"];
    const listCriteria = useSelector((state) => state.Criteria.criteria);
    const criteriaSetting = useSelector((state) => state.Criteria.criteriaSetting);
    const isLoading = useSelector((state) => state.Criteria.isLoading);
    const [page, setPage] = useState(1);
    // const [lastPage, setLastPage] = useState(0);
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());

    const listOptions = {
        1: "edit",
        2: "delete"
    };
    useEffect(() => {
        const data = {
            page: 1,
            item_per_page: Constants.ITEM_PER_PAGE,
            language
        };
        const data_setting = {
            type: "criteria_setting"
        };
        dispatch(CriteriaActions.getCriteriaSettingData(data_setting));
        dispatch(CriteriaActions.getAllCriteria(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const data = {
            page,
            item_per_page: Constants.ITEM_PER_PAGE,
            language: i18n.language
        };
        dispatch(CriteriaActions.getAllCriteria(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        if (criteriaSetting && !_.isEmpty(criteriaSetting)) {
            if (criteriaSetting.is_support_mul_lang) {
                if (i18n.language === Constants.LANGUAGE_CODE.AL) {
                    setLanguage(Constants.LANGUAGE_CODE.AL);
                } else {
                    setLanguage(Constants.LANGUAGE_CODE.EN);
                }
            } else if (criteriaSetting.data_json.data[i18n.language].title) {
                setLanguage(i18n.language);
            } else if (i18n.language === Constants.LANGUAGE_CODE.EN) {
                setLanguage(Constants.LANGUAGE_CODE.AL);
            } else {
                setLanguage(Constants.LANGUAGE_CODE.EN);
            }
            setContentAl(criteriaSetting.data_json.data.al);
            setContentEn(criteriaSetting.data_json.data.en);
            setIsmultipleLanguage(criteriaSetting.is_support_mul_lang);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteriaSetting]);

    useEffect(() => {
        setPage(listCriteria.page);
        // setLastPage(listCriteria.lastPage);
    }, [listCriteria]);

    const validateMutilpleLanguage = () => {
        const validationRules = [
            {
                name: `${t("general.Title")} ${t("general.English")}`,
                value: editor.current.value,
                rule: ["required", { min: 10, max: 300 }]
            },
            {
                name: `${t("general.Title")} ${t("general.Albanian")}`,
                value: editorAl.current.value,
                rule: ["required", { min: 10, max: 300 }]
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const validateForLanguage = (lang) => {
        const textEditor = lang === Constants.LANGUAGE_CODE.EN ? editor : editorAl;
        const validationRules = [
            {
                name: `${t("general.Title")} ${lang === Constants.LANGUAGE_CODE.EN ? t("general.English") : t("general.Albania")}`,
                value: textEditor.current.value,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const paramPostSingleLanguage = (param) => {
        const data = {
            en: editor.current.value || "",
            al: editorAl.current.value || ""
        };
        if (language === Constants.LANGUAGE_CODE.EN) {
            data.en.title = editor.current.value;
            param.append('data', JSON.stringify({ data }));
        } else {
            data.al.title = editorAl.current.value;
            param.append('data', JSON.stringify({ data }));
        }
    };

    const paramPostMultipleLanguage = (param) => {
        const data = {
            en: editor.current.value,
            al: editorAl.current.value
        };
        param.append('data', JSON.stringify({ data }));
    };

    const createOrupdateAboutUs = () => {
        let flag = true;
        if (isMultipleLanguage) {
            flag = validateMutilpleLanguage();
        } else if (!validateForLanguage(language)) return;
        if (flag) {
            const formData = new FormData();
            if (isMultipleLanguage) paramPostMultipleLanguage(formData);
            if (!isMultipleLanguage) paramPostSingleLanguage(formData);
            formData.append("type", "criteria_setting");
            formData.append("is_support_mul_lang", isMultipleLanguage);
            dispatch(CriteriaActions.updateCriteriaSettingData(formData));
        }
    };

    const _renderSwitchLangugage = () => {
        return (
            <EnableMultipleLanguageButtonContainer>
                <SwitchButton className="switch">
                    <input type="checkbox" value={isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} checked={isMultipleLanguage} />
                    <span className="slider round" />
                </SwitchButton>
                {isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                            />
                        </>
                    )}
                {!isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                disabled
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                disabled
                            />
                        </>
                    )}
            </EnableMultipleLanguageButtonContainer>
        );
    };

    const renderForm = () => {
        return (
            <WrapperInput>
                <TitleInput>{t("general.Title")}</TitleInput>
                <div className={language === Constants.LANGUAGE_CODE.EN ? "" : "hidden-content"}>
                    <JoditEditor
                        ref={editor}
                        value={contentEn}
                    />
                </div>
                <div className={language === Constants.LANGUAGE_CODE.AL ? "" : "hidden-content"}>
                    <JoditEditor
                        ref={editorAl}
                        value={contentAl}
                    />
                </div>
            </WrapperInput>
        );
    };

    return (
        <DefaultLayout
            userRole='limited-admin'
            isLoading={isLoading}
            page="criteria"
            content={(
                <BodyContainer>
                    <PageTitle>
                        {t("criteria.Criteria")}
                    </PageTitle>
                    <ProgressBar className="btn-save">
                        <FlexGridSixth>
                            <GridCol colSize='7' gridTemplate='7' className="flex-end">
                                {_renderSwitchLangugage()}
                            </GridCol>
                        </FlexGridSixth>
                        <FlexGridSixth>
                            <GridCol colSize='7' gridTemplate='7' className="flex-end">
                                {renderForm()}
                            </GridCol>
                        </FlexGridSixth>
                        <div className="container-button">
                            <GeneralButton
                                type="button"
                                className="for-more"
                                background={ColorName.chathamsBlue}
                                value={t("general.Save")}
                                onClick={() => createOrupdateAboutUs()}
                            />
                        </div>
                    </ProgressBar>
                    <div className="container-button">
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("criteria.Add Criteria")}
                            onClick={() => history.push("/limited-admin/add-criteria")}
                        />
                    </div>
                    <RoundedTable
                        initialData={listCriteria || []}
                        columns={columns}
                        tableTitle={t("criteria.Criteria")}
                        tableType={Constants.TABLE_TYPE.CRITERIA}
                        className="mt-2"
                        showNumberItems={Constants.ITEM_PER_PAGE}
                        listOptions={listOptions}
                    />
                    {/* {_renderPagination()} */}
                </BodyContainer>
            )}
        />
    );
};

Criteria.propTypes = {

};

export default Criteria;
