import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";

const requestHasError = () => {
    return {
        type: ActionTypes.GUEST_APPLY_SETTING_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.GUEST_APPLY_SETTING_REQUEST
    };
};

const getApplySettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GET_GUEST_APPLY_SETTING_SUCCESS,
        payload
    };
};

const updateApplySettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GUEST_APPLY_SETTING_SUCCESS,
        payload
    };
};

const getApplySettingData = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getMetaData(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getApplySettingDataSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateApplySettingData = (formPayload) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateMetaDataWithForm(formPayload)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(updateApplySettingDataSuccess(res.data.data));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const resetApplySettingData = () => {
    return {
        type: ActionTypes.GUEST_APPLY_SETTING_RESET
    };
};

export default {
    getApplySettingData,
    updateApplySettingData,
    resetApplySettingData
};
