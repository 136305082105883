import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Container,
    GridColHomepage,
    FlexGridSixthTemplate,
    Button
} from "./Disclosure.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import SummryOverview from "../../../components/Common/SummaryOverview";
import { ColorName } from "../../../components/Variables/index.js";
import GuestDisclosureSettingActions from "../../../actions/GuestDisclosureSetting";
import history from "../../../history";
import Utils from "../../../libs/Utils";
import CONSTANTS from "../../../constants/Constants";

const Disclosure = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.GuestDisclosureSetting.isLoading);
    const guestDisclosureSetting = useSelector((state) => state.GuestDisclosureSetting);

    const [disclosureContent, setDisclosureContent] = useState(guestDisclosureSetting.disclosureContent);

    useEffect(() => {
        dispatch(GuestDisclosureSettingActions.getDisclosureSettingData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDisclosureContent(guestDisclosureSetting.disclosureContent);
    }, [guestDisclosureSetting]);

    const _renderContent = () => {
        const currentLang = Utils.getCurrentLanguage();
        const currentContent = currentLang === CONSTANTS.LANGUAGE_CODE.EN ? disclosureContent.en : disclosureContent.al;
        return (
            <div className="content-page">
                {isLoading && <Loading />}
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <div className="text" dangerouslySetInnerHTML={{ __html: currentContent }} />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate className="container-button">
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <Button
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => history.push("/homepage/register")}
                        />
                        <Button
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.chathamsBlueDark}
                            background={ColorName.white}
                            onClick={() => history.push("/homepage/register")}
                        />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
            </div>
        );
    };

    const _renderSummaryOverview = () => {
        return <SummryOverview />;
    };

    const _renderMain = () => {
        return (
            <Container>
                {_renderContent()}
                {_renderSummaryOverview()}
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
            currentPage="privacyPolicy"
        />
    );
};

Disclosure.propTypes = {
};

export default Disclosure;
