import React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { logger } from "redux-logger";
import { Router, Route } from "react-router";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import HttpsRedirect from 'react-https-redirect';

// eslint-disable-next-line no-unused-vars
import swal from 'sweetalert';
import "./App.css";
import reducers from "./reducers";
import Authentication from "./containers/PortalContainer/Authentication";
import history from "./history";
import {
    GuestRoute,
    PortalRoute,
    TestComponentRoute,
    ValidationRoute
} from './routes';
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk, logger, routerMiddleware(history)))
);

// Create your own root div in the body element before rendering into it
const root = document.createElement("div");

// Add id 'root' and append the div to body element
root.id = "root";
document.body.appendChild(root);

// Render the application into the DOM, the div inside index.html
render(
    <HttpsRedirect>
        <Provider store={store}>
            <Router history={history}>
                <I18nextProvider i18n={i18n}>
                    <TestComponentRoute />
                    <GuestRoute />
                    <ValidationRoute />
                    <PortalRoute />
                    <Route path="/authentication" component={Authentication} />
                </I18nextProvider>
            </Router>
        </Provider>
    </HttpsRedirect>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
