import React, { useState } from 'react';
import PropTypes from "prop-types";
import {
    FAQDropdownComponent,
    QuestionCategory,
    QuestionDetail,
    Content
} from "./FAQDropdown.styles";

const FAQDropdown = (props) => {
    const [isShow, setIsShow] = useState(false);
    const { questionCategory, questionnaire } = props;
    return (
        <FAQDropdownComponent>
            <QuestionCategory onClick={() => setIsShow(!isShow)}>
                <span className="flex-1">{questionCategory}</span>
                <i className={`fas ${isShow ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
            </QuestionCategory>
            <QuestionDetail className={isShow ? '' : 'd-none'}>
                <Content>{questionnaire}</Content>
            </QuestionDetail>
        </FAQDropdownComponent>
    );
};

FAQDropdown.propTypes = {
    questionCategory: PropTypes.string,
    questionnaire: PropTypes.string
};
export default FAQDropdown;
