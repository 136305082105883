import styled from 'styled-components';
import { ColorName } from "../../Variables";

const DropDownActionButton = styled.i`
    position: relative;
    &.fa-ellipsis-h:before{
        float: right;
    }
    &:hover{
        & .hidden{
            display: block
        }
    }
`;
const DropDown = styled.div`
    position: absolute;
    top: 16px;
    right: 0;
    z-index: 9;
    color: ${ColorName.gray};
    &.hidden{
        display: none;
    }
    & div{
        background: white;
        white-space: nowrap;
        text-align: center;
        padding: 5px 20px;
        font-weight: 500;
        font-size: 1em;
        text-transform: capitalize;
        border: 1px solid ${ColorName.chathamsBlueDark};;
            border-bottom: 0px;
        &:hover{
            background: ${ColorName.chathamsBlueDark};
            color:white;
        }
        &:last-child{
            border-bottom: 1px solid ${ColorName.chathamsBlueDark};;
        }
    }
`;
export {
    DropDownActionButton,
    DropDown
};
