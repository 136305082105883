import styled from 'styled-components';
import { ColorName } from "../../Variables";
import { HorizontalContainer, VerticalContainer } from "../../Common/CommonLayout";

const Wrapper = styled(VerticalContainer)`
    background: ${ColorName.white};
`;

const BodyContainer = styled(HorizontalContainer)`
    background: ${ColorName.white};
`;

const BodyContent = styled.div`
    width: 80%;
    & .jodit-wysiwyg{
        max-height: 350px;
        span {
            display: unset;
        }
    }
`;

export {
    Wrapper,
    BodyContainer,
    BodyContent
};
