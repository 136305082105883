import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";
import CONSTANTS from "../constants/Constants";
import i18n from "../i18n";

const isLogged = () => {
    return {
        type: ActionTypes.IS_LOGGED
    };
};

const loginHasError = () => {
    return {
        type: ActionTypes.LOGIN_HAS_ERROR
    };
};

const loginIsLoading = () => {
    return {
        type: ActionTypes.LOGIN_IS_LOADING
    };
};

const login = (email, password) => {
    return async (dispatch) => {
        dispatch(loginIsLoading(true));
        if (!email || !password) {
            Utils.popupAlert({ title: "Oops...", text: i18n.t('alert.Found empty field(s)'), type: "error" });
            dispatch(loginHasError());
            return;
        }
        const data = {
            email,
            password
        };
        await API.login(data)
            .then(async (res) => {
                if (res.data.status === true) {
                    const { token, role } = res.data.data;
                    dispatch(isLogged());
                    await Utils.saveToken(token);
                    await Utils.saveUserName(data.email);
                    await Utils.saveUserData(res.data.data);
                    await API.setToken(token);
                    if (role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                        history.push("/admin/overview");
                    } else if (role.name === CONSTANTS.ROLE_NAME.LIMITED_ADMIN) {
                        history.push("/limited-admin/overview");
                    } else if (role.name === CONSTANTS.ROLE_NAME.JUDGE) {
                        history.push("/judge/applications");
                    } else {
                        history.push("/user-overview");
                    }
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(loginHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(loginHasError());
            });
    };
};

const logout = () => {
    Utils.clearAllSavedData();
    history.push('/authentication');
    return {
        type: ActionTypes.LOGOUT
    };
};

const logoutLimitedUser = () => {
    Utils.clearAllSavedData();
    history.push('/homepage/add-limited-user');
    return {
        type: ActionTypes.LOGOUT
    };
};

const logoutWithOutRedirect = () => {
    Utils.clearAllSavedData();
    return {
        type: ActionTypes.LOGOUT
    };
};

export default {
    isLogged,
    loginHasError,
    loginIsLoading,
    login,
    logout,
    logoutWithOutRedirect,
    logoutLimitedUser
};
