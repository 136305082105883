import _ from "lodash";
import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";

const requestHasError = () => {
    return {
        type: ActionTypes.USER_OVERVIEW_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.USER_OVERVIEW_REQUEST
    };
};

const setNotifications = (data) => {
    return {
        type: ActionTypes.GET_NOTIFICATIONS,
        data
    };
};

const setUserExplore = (data) => {
    return {
        type: ActionTypes.GET_USER_EXPLORE,
        data
    };
};

const setFundingApplication = (data) => {
    return {
        type: ActionTypes.GET_FUNDING_APPLICATION,
        data
    };
};

const setUserExploreById = (data) => {
    return {
        type: ActionTypes.GET_USER_EXPLORE_BY_ID,
        data
    };
};

const setUserForMessage = (data) => {
    return {
        type: ActionTypes.GET_USERS_FOR_MESSAGE,
        data
    };
};

const getNotificationsRequest = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getNotifications(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setNotifications(res.data.data.data));
                } else {
                    dispatch(requestHasError(true));
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError(true));
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getUserExploreRequest = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getUserExplore(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setUserExplore(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
}; 

const getFundingApplicationRequest = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getFundingApplication(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setFundingApplication(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};
 
const getUserExploreById = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getUserExploreById(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setUserExploreById(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const createUserExplore = (data) => {
    return {
        type: ActionTypes.CREATE_USER_EXPLORE,
        data
    };
};

const createUserExploreRequest = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.createUserExplore(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(createUserExplore(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateUserExplore = (data) => {
    return {
        type: ActionTypes.UPDATE_USER_EXPLORE,
        data
    };
};

const updateUserExploreRequest = (id, data) => {
    return async (dispatch) => {
        dispatch(requestIsLoading());
        await API.updateUserOverview(id, data)
            .then(async (res) => {
                if (res.data.status === true) {
                    dispatch(updateUserExplore(res.data.data));
                    const oldData = Utils.getSavedUserData();
                    const newData = _.merge(oldData, res.data.data);
                    await Utils.saveUserData(newData);
                    Utils.popupAlert({ text: res.data.message, type: "success" })
                        .then((isSuccess) => {
                            if (isSuccess) {
                                history.push("user-overview");
                                dispatch(getUserExploreRequest());
                                dispatch(getUserDetail(newData.id));
                            }
                        });
                } else {
                    const messageError = _.map(res.data, 'message').toString();
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: messageError, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const setRolesName = (data) => {
    return {
        type: ActionTypes.GET_ROLES_NAME,
        data
    };
};

const getRolesNameRequest = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getRolesName(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setRolesName(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getUserReport = (data) => {
    return {
        type: ActionTypes.GET_USER_REPORT,
        data
    };
};

const getUserReportRequest = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.userReport(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getUserReport(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const setUserDetail = (data) => {
    return {
        type: ActionTypes.USER_OVERVIEW_SET_USER_DETAIL,
        data
    };
};

const getUserDetail = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getUserExploreById(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setUserDetail(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getListAdmin = () => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getListAdmin()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setUserExplore(res.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(requestHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const getListUserMessageByRole = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getUsersForMessage(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setUserForMessage(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getFundingApplicationDashboard = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getFundingApplicationDashboard(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setFundingApplication(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getCurrentUserDetail = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getUserExploreById(id)
            .then( async (res) => {
                if (res.data.status === true) {
                    await Utils.saveUserData(res.data.data);
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    getNotificationsRequest,
    getUserExploreRequest,
    getFundingApplicationRequest,
    getUserExploreById,
    createUserExploreRequest,
    updateUserExploreRequest,
    getRolesNameRequest,
    getUserReportRequest,
    getUserDetail,
    getListAdmin,
    getListUserMessageByRole,
    getFundingApplicationDashboard,
    getCurrentUserDetail
};
