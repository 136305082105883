import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import {
    Wrapper,
    WrapperGradient
} from "./ApplicationStatus.styles";

const ApplicationStatus = (props) => {
    const { t } = useTranslation();
    const [value, setValue] = useState();
    const [width, setWidth] = useState();
    const [background, setBackground] = useState();

    useEffect(() => {
        setValue(props.value);
        setWidth(props.width);
        setBackground(props.background);
    }, [props]);

    return (
        (background === 'gradient')
            ? (
                <WrapperGradient width={width}>
                    {value && t(`rounderTable.${value}`)}
                </WrapperGradient>
            )
            : (
                <Wrapper background={background} width={width}>
                    {value && t(`rounderTable.${value}`)}
                </Wrapper>
            )
    );
};

ApplicationStatus.propTypes = {
    value: PropTypes.string,
    width: PropTypes.string,
    background: PropTypes.string
};

export default ApplicationStatus;
