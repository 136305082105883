import styled from 'styled-components';
import { VerticalContainer } from "../../../Common/CommonLayout";
import { ColorName } from "../../../Variables";

const Wrapper = styled.div`
    display: flex;
    position: fixed;
    z-index: 9;
    background-color: rgba(16, 16, 16, 0.5);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
`;

const Container = styled(VerticalContainer)`
    width: 50%;
    background: ${ColorName.white};
    border-radius: 5px;
    border: 2px solid ${ColorName.chathamsBlueDark};
    padding: 1em;
    align-items: center;
    height: 450px;
    overflow: auto;
    p {
        color: ${ColorName.chathamsBlueDark};
        font-size: 1.3em;
        border-bottom: 1px solid ${ColorName.chathamsBlueDark};
        width: 50%;
        text-align: center;
        padding-bottom: 0.3em;
        margin: 0;
    }
    div:first-child {
        margin-bottom: 0.3em;
    }
    input[type='button'] {
        width: 6em;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: ${ColorName.chathamsBlueDark};
        outline: none;
        border-radius: 10px;
    }
`;

const FormItem = styled(VerticalContainer)`
    width: 100%;
    margin: 0.5em 0;
    input {
        height: 30px;
        width: auto;
    }
`;

const Label = styled.div`
    text-align: left;
    color: ${ColorName.chathamsBlue};
    margin-bottom: 0.7em;
    &.text-bold {
        font-weight: bold;
        color: ${ColorName.chathamsBlueDark};
    }
`;

export {
    Wrapper,
    Container,
    FormItem,
    Label
};
