/* eslint-disable no-useless-escape */
import _ from "lodash";
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
import history from "../history";

const SAVED_NAME_KEY = "@IDEALBANIA:name";
const SAVED_USER_DATA_KEY = "@IDEALBANIA:udata";
const SAVE_USER_EXTRA_DATA_KEY = "@IDEALBANIA:uxdata";
const SAVE_BASIC_USER_DATA_KEY = "@IDEALBANIA:budata";
const SAVED_SECURE_TOKEN = "@IDEALBANIA:secure_token";
const SAVED_READED_PUBLISH_NOTIFICATION = "@IDEALBANIA:readed_notifycation";

const CURRENT_LANGUAGE = "@IDEALBANIA:current_language";

const cookies = new Cookies();
const saveToken = (token) => {
    cookies.set(SAVED_SECURE_TOKEN, token, { path: '/' });
};

const getSavedToken = () => {
    const token = cookies.get(SAVED_SECURE_TOKEN);
    return token;
};

const authenticationValidate = (params) => {
    const token = cookies.get(SAVED_SECURE_TOKEN);
    if (token) {
        const decodedToken = jwt_decode(token);
        const dateNow = new Date();
        if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000)) {
            clearAllSavedData();
            return history.push("/authentication");
        }
        params.found(token);
    } else {
        params.notFound();
    }
};

const saveUserName = (name) => {
    if (name) {
        const firstname = name.split(" ")[0];
        cookies.set(SAVED_NAME_KEY, firstname, { path: '/' });
    }
};

const getSavedUserName = () => {
    cookies.get(SAVED_NAME_KEY);
};

const saveUserData = (userData) => {
    if (userData) {
        const basicUserData = {};
        let getUserData = { ...userData };
        getUserData = _.omit(getUserData.userData, ['userData', 'extra_data_json']);
        const getExtraData = userData.userData.extra_data_json;
        _.map(_.keys(userData), (key) => {
            if (key !== "userData") basicUserData[key] = userData[key];
        });
        cookies.set(SAVE_BASIC_USER_DATA_KEY, basicUserData, { path: '/' });
        cookies.set(SAVED_USER_DATA_KEY, getUserData, { path: '/' });
        // cookies.set(SAVE_USER_EXTRA_DATA_KEY, getExtraData, { path: '/' });

        // Fixed over memory for cookie, changed to localstorage instead - 20210501 - BaoNguyen
        localStorage.setItem(SAVE_USER_EXTRA_DATA_KEY, getExtraData);
    }
};

const getSavedUserData = () => {
    const basicUserData = cookies.get(SAVE_BASIC_USER_DATA_KEY);
    const userData = cookies.get(SAVED_USER_DATA_KEY);
    // const extraData = cookies.get(SAVE_USER_EXTRA_DATA_KEY);
    // Fixed over memory for cookie, changed to localstorage instead - 20210501 - BaoNguyen
    const extraData = localStorage.getItem(SAVE_USER_EXTRA_DATA_KEY);
    if (!_.isEmpty(basicUserData) && !_.isEmpty(userData) && !_.isEmpty(extraData)) {
        const result = { ...basicUserData };
        result.userData = userData;
        result.userData.extra_data_json = extraData;
        // return cookies.get(SAVED_USER_DATA_KEY);
        return result;
    }
    return null;
};

const updateSavedUserData = (dataName, newData) => {
    const userSavedData = getSavedUserData();
    switch (dataName) {
        case 'email':
            const { email } = newData;
            const dataEmailUpdated = { ...userSavedData.data, email };
            saveUserData({ ...userSavedData, data: dataEmailUpdated });
            break;
        case 'phone':
            const { data_detail } = userSavedData.data;
            data_detail.phone_number = newData.phone_number;
            const dataPhoneUpdated = { ...userSavedData.data, data_detail };
            saveUserData({ ...userSavedData, data: dataPhoneUpdated });
            break;
        case 'status':
            const newUserData = { ...userSavedData, status: parseInt(newData) };
            saveUserData(newUserData);
            break;

        default:
            break;
    }
};

const setCurrentLanguage = (lang = 'en') => {
    cookies.set(CURRENT_LANGUAGE, lang, { path: '/' });
};

const getCurrentLanguage = () => {
    if (!cookies.get(CURRENT_LANGUAGE)) return "en";
    return cookies.get(CURRENT_LANGUAGE);
};

const getReadedPublishNotification = () => {
    return cookies.get(SAVED_READED_PUBLISH_NOTIFICATION);
};

const setReadedPublishNotification = (data) => {
    cookies.set(SAVED_READED_PUBLISH_NOTIFICATION, data, { path: '/' });
};

const clearAllSavedData = async () => {
    cookies.remove(SAVED_SECURE_TOKEN, { path: '/' });
    cookies.remove(SAVED_NAME_KEY, { path: '/' });
    cookies.remove(SAVED_USER_DATA_KEY, { path: '/' });
    cookies.remove(SAVE_BASIC_USER_DATA_KEY, { path: '/' });
    // Fixed over memory for cookie, changed to localstorage instead - 20210501 - BaoNguyen
    localStorage.removeItem(SAVE_USER_EXTRA_DATA_KEY);
    cookies.remove(SAVE_USER_EXTRA_DATA_KEY, { path: '/' });
    cookies.remove(SAVED_READED_PUBLISH_NOTIFICATION, { path: '/' });
};

const _camelToSnake = (string) => {
    if (!string) return string;
    return string
        .replace(/[\w]([A-Z])/g, (m) => {
            return `${m[0]}_${m[1]}`;
        })
        .toLowerCase();
};

const _convertObjectToSnake = (obj) => {
    const b = {};
    _.each(obj, (value, key) => {
        key = _camelToSnake(key) || key;
        b[key] = value;
    });
    return b;
};

const convertCamelKeyToSnake = (obj) => {
    const b = {};
    _.each(obj, (value, key) => {
        key = _camelToSnake(key) || key;
        b[key] = value;

        if (_.isObject(b[key]) && !_.isArray(b[key])) {
            b[key] = _convertObjectToSnake(b[key]);
        }

        if (_.isArray(b[key])) {
            _.each(b[key], (value1, key1) => {
                b[key][key1] = _convertObjectToSnake(b[key][key1]);
            });
        }
    });
    return b;
};

const _snakeToCamel = (string) => {
    if (!string) return string;
    return string.replace(/([-_][a-z])/g, (group) => group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""));
};

const _convertObjectToCamel = (obj) => {
    const b = {};
    _.each(obj, (value, key) => {
        key = _snakeToCamel(key) || key;
        b[key] = value;
    });
    return b;
};

const convertSnakeKeyToCamel = (obj) => {
    const b = {};
    _.each(obj, (value, key) => {
        key = _snakeToCamel(key) || key;
        b[key] = value;

        if (_.isObject(b[key]) && !_.isArray(b[key])) {
            b[key] = _convertObjectToCamel(b[key]);
        }

        if (_.isArray(b[key])) {
            _.each(b[key], (value1, key1) => {
                b[key][key1] = _convertObjectToCamel(b[key][key1]);
            });
        }
    });
    return b;
};

const convertSnakeKeyToCamelWithoutDocument = (obj) => {
    const b = {};
    _.each(obj, (value, key) => {
        key = _snakeToCamel(key) || key;
        b[key] = value;
        if (key !== "documentJson" && key !== "documentEvaluationJson" && key !== "documentWinnerJson") {
            if (_.isObject(b[key]) && !_.isArray(b[key])) {
                b[key] = _convertObjectToCamel(b[key]);
            } else b[key] = value;

            if (_.isArray(b[key])) {
                _.each(b[key], (value1, key1) => {
                    b[key][key1] = _convertObjectToCamel(b[key][key1]);
                });
            }
        }
    });
    return b;
};

const popupAlert = (data) => {
    // type: success, error, warning, info, question
    const {
        type, title, text, buttons
    } = data;
    return new Promise((resolve) => {
        // eslint-disable-next-line no-undef
        swal({
            title, text, icon: type, buttons
        }).then((response) => resolve(response));
    });
};

function currencyFormat(num) {
    return `$${num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

function getMessage(e) {
    if (!_.isUndefined(e.data)) {
        if (!_.isUndefined(_.find(e.data, 'message'))) return _.find(e.data, 'message').message.toString();
        return e.data.message;
    }
    return e.message;
}

const truncateText = (source, size = 20) => {
    return source.length > size ? `${source.slice(0, size - 1)}…` : source;
};

const removeSpecialCharacter = (source) => {
    const a = source.replace(/(?:&nbsp;)/g, '');
    return a.replace(/ /g, '');
};

const removeHTMLTags = (source) => {
    return source.replace(/<[^>]*>?/gm, '');
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 Params: Array Object, callback function, withPopup Boolean
 Return: boolean
 */
const formValidation = (payload, callback = false, withPopup = true) => {
    const _validationProcess = () => {
        let errorFound = [];
        _.each(payload, (field) => {
            if (Array.isArray(field.rule)) {
                errorFound = [...errorFound, ..._multipleRuleProcess(field)];
            } else {
                errorFound = [...errorFound, ..._singleRuleProcess(field)];
            }
        });
        return _response(errorFound);
    };

    const _multipleRuleProcess = (field) => {
        let errorFound = [];
        _.each(field.rule, (rule) => {
            if (typeof rule === 'object') {
                _.forOwn(rule, (ruleValue, ruleName) => {
                    field.rule = ruleName;
                    field.ruleValue = ruleValue;
                    const validationResult = _singleRuleProcess(field);
                    errorFound = [...errorFound, ...validationResult];
                });
            } else {
                field.rule = rule;
                const validationResult = _singleRuleProcess(field);
                errorFound = [...errorFound, ...validationResult];
            }
        });
        return errorFound;
    };

    const _singleRuleProcess = (field) => {
        if (!Array.isArray(field.ruleValue)) {
            // Process single field
            const result = _simpleValidation(field);
            const errorFound = [];
            if (!result.status) errorFound.push({ field, text: result.message });
            return errorFound;
        } else {
            // Process array fields example library have multiple chapters
            const errorFound = [];
            const processedFields = _generateFieldFromArrayField(field);
            _.each(processedFields, (eachField, eachFieldIndex) => {
                // if (!_.isEmpty(errorFound)) return false;
                const result = _simpleValidation(eachField);
                if (!result.status) errorFound.push({ field, text: result.message });
            });
            return errorFound;
        }
    };

    const _generateFieldFromArrayField = (field) => {
        const processedFields = [];
        _.each(field.value, (ruleBlock, ruleBlockIndex) => {
            _.each(field.ruleValue, (eachField, eachFieldIndex) => {
                const processedField = {
                    name: `${field.name}-${ruleBlockIndex + 1} ${capitalizeFirstLetter(eachField)}`,
                    rule: field.rule,
                    value: ruleBlock[eachField]
                };
                processedFields.push(processedField);
            });
        });

        return processedFields;
    };

    const _response = (errorFound) => {
        if (!_.isEmpty(errorFound)) {
            const text = _(errorFound).flatMapDeep().map('text').value();

            if (withPopup) popupAlert({ title: "Validation error:", text: text.join('\n'), type: "error" });
            if (callback) return callback({ errors: errorFound });
            return false;
        }
        return true;
    };

    const _simpleValidation = (field) => {
        if (field.rule === 'required' && _.isEmpty(field.value) && field.value === "") return { status: false, message: `${field.name} is required` };
        if (field.rule === 'number' && _.isNumeric(field.value)) return { status: false, message: `${field.name} is not a number` };
        if (field.rule === 'email' && !_patternValidation('email', field.value)) return { status: false, message: `${field.name} is not an email` };
        if (field.rule === 'min' && removeSpecialCharacter(removeHTMLTags(field.value)).length < field.ruleValue) return { status: false, message: `${field.name} min length is ${field.ruleValue}` };
        if (field.rule === 'max' && removeSpecialCharacter(removeHTMLTags(field.value)).length > field.ruleValue) return { status: false, message: `${field.name} max length is ${field.ruleValue}` };
        if (field.rule === 'equal' && field.value !== field.ruleValue) return { status: false, message: `${field.name} is not matched` };
        return { status: true, message: "" };
    };

    const _patternValidation = (type, data) => {
        if (type === 'email') {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(data);
        }
    };

    return _validationProcess();
};

const checkImageCORS = (imageURL) => {
    return new Promise((resolve, reject) => {
        try {
            const image = new Image();
            image.addEventListener('load', () => {
                resolve(image);
            });
            image.addEventListener('error', (error) => reject(error));
            image.src = imageURL;
            image.setAttribute("crossOrigin", "*");
        } catch (error) {
            reject();
        }
    });
};

const convertDataURLToFile = async (dataURL, fileName) => {
    const res = await fetch(dataURL);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/jpg' });
};

const resolveSpaceTextEditor = (content) => {
    const resolveText = removeHTMLTags(content);
    const chunkText = resolveText.split(' ');
    let totalCharacter = 0;
    let processedText = "";
    _.map(chunkText, (each, index) => {
        if (totalCharacter > 250) return;
        totalCharacter += each.length;
        processedText += `${each} `;
    });
    return processedText.trim();
};

const getNameFileUpload = (str) => {
    if (_.isString(str)) {
        const arrayStr = str.split("/");
        return arrayStr[arrayStr.length - 1];
    }
};

export default {
    saveToken,
    getSavedToken,
    saveUserName,
    getSavedUserName,
    clearAllSavedData,
    convertCamelKeyToSnake,
    convertSnakeKeyToCamel,
    saveUserData,
    getSavedUserData,
    setCurrentLanguage,
    getCurrentLanguage,
    popupAlert,
    authenticationValidate,
    updateSavedUserData,
    currencyFormat,
    getMessage,
    truncateText,
    removeHTMLTags,
    formValidation,
    capitalizeFirstLetter,
    getReadedPublishNotification,
    setReadedPublishNotification,
    checkImageCORS,
    convertDataURLToFile,
    resolveSpaceTextEditor,
    getNameFileUpload,
    convertSnakeKeyToCamelWithoutDocument
};
