import styled from 'styled-components';
import { ColorName } from "../../../components/Variables";
import { ContentBodyContainer } from "../../../components/Common/CommonLayout";

const MainContent = styled(ContentBodyContainer)`
    flex-direction: column;
    align-items: center;
    padding: 0 1em;
    img {
        width: 30%;
    }
    video {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1em auto;
        width: 50%;
    }
    & .d-flex{
        padding-top: 1em;
        margin-top: 2em;
        border-top: 1px solid lightGray;
        display: flex;
        justify-content: space-between;
        & .pdf{
            width: 25px;
            margin-right: 1em;
        }
        & .title-file{
            text-transform: capitalize;
        }
        & a{
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: ${ColorName.fern};
            & i{
                margin-right: 1em;
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        & div{
            display: flex;
            align-items: center;
        }
    }

    & .flex-link {
        display: flex;
        margin: 1em auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    & .youtube {
        color: ${ColorName.chathamsBlueDark};
        font-size: 1.2em;
    }
`;

const BookList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
`;

const Book = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 250px;
    margin: 1em;
    position: relative;
    cursor: pointer;
    img {
        width: 100%;
        max-height: 325px;
        min-height: 325px;
        object-fit: scale-down;
    }
    b {
        color: ${ColorName.tuna};
        margin-top: 1em;
        // height: 60px;
    }
    & .comming-soon {
        position: absolute;    
        width: initial;
        right: 0;
    }
    &:hover {
        b {
            color: ${ColorName.pictonBlue};
        }
    }
    @media (max-width: 600px) {
        max-width: initial;
    }
`;

const Pagination = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em;
    color: white;
    div {
        padding: 0.2em 0.3em;
        background: ${ColorName.botticelli};
        cursor: pointer;
        height: 18px;    
        display: flex;
        align-items: center;
    }
    & .prev {
        position: relative;
        background: ${ColorName.chathamsBlueDark};
        &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent ${ColorName.chathamsBlueDark} transparent transparent;
            content: '';
            display: block;
            left: -24px;
            position: absolute;
            z-index: 2;
            border-width: 12px;
            top: 0px;
            bottom: 0;
        }
    }
    & .next {
        position: relative;
        background: ${ColorName.chathamsBlueDark};
        &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent transparent  transparent ${ColorName.chathamsBlueDark};
            content: '';
            display: block;
            right: -24px;
            position: absolute;
            z-index: 2;
            border-width: 12px;
            top: 0px;
            bottom: 0;
        }
    }
    & .active {
        background: ${ColorName.chathamsBlueDark};
    }
    & .page {
        padding: 0.2em 0.5em;
        margin: 0 0.5em;
    }
`;

const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    hr {
        width: 50%;
        border-top: 1px solid ${ColorName.chathamsBlueDark};
    }
    & .chap-action {
        border-bottom: 1px solid ${ColorName.chathamsBlueDark};
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.5em;
    }
`;

const Details = styled.div`
    font-size: 1.5em;
    margin-top: 1.2em;
    &.chapter {
        color: #3F92AD;
        font-size: 1em;
        margin-top: 0.5em;
    }
    ${'' /* p:first-child {
        color: black;
        font-weight: 600;
    } */}
    ul {
        li {
            cursor: pointer;
            color: ${ColorName.gray};
            a {
                text-decoration: none;
                color: ${ColorName.gray};
            }
            a:hover {
                text-decoration: underline;
            }
        }
        & .active {
            color: ${ColorName.chathamsBlueDark};
        }
    }
`;

const NextChap = styled.div`
    display: flex;
    color: ${ColorName.chathamsBlueDark};
    align-items: center;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    margin-left: auto;
    p {
        margin: 0;
    }
    & .ml-1 {
        margin-left: 1em;
    }
    & .mr-1 {
        margin-right: 1em;
    }
`;

const PrevChap = styled(NextChap)`
    margin-right: auto;
    margin-left: unset;
`;

const NotFoundData = styled.div`
    margin: auto;
`;

const ChaperInfor = styled.div`
    p {
        color: ${ColorName.chathamsBlueDark};
        font-size: 1.2em;
        margin: 1em 0 0 0;
    }
    span {
        margin-bottom: 1em;
        font-size: 0.8em;
    }
`;

export {
    MainContent,
    BookList,
    Book,
    Pagination,
    DetailContainer,
    Details,
    NextChap,
    NotFoundData,
    PrevChap,
    ChaperInfor
};
