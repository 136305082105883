import styled from 'styled-components';
import { ColorName } from "../../Variables";

const TextAreaComponent = styled.textarea`
    background: none;
    border-radius: 5px;
    outline: none;
    border: 1px solid ${ColorName.chathamsBlue};
    width: ${(props) => props.width || 'auto'};
    height: ${(props) => props.height || 'auto'};
    margin: ${(props) => props.margin || 0};
    padding: 1em;
    resize: none;
    cursor: ${(props) => props.cursor || "auto"};
    background-color: ${(props) => props.backgroundColor || ""};
    font-family: unset;
`;

export {
    TextAreaComponent
};
