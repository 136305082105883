import styled from 'styled-components';

const EditorControl = styled.div`
    box-sizing: border-box;
    position: absolute;
    bottom: 56px;
    left: 0%;
    width: 100%;
    padding: 0 16px;
    height: 24px;
    display: flex;
    align-items: center;
    background: #f6f6f6;
    .slider {
        padding: 22px 0px;
    }
`;

export {
    EditorControl
};
