import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
    Container,
    PageTitle
} from "../../../../GuestContainer/UserRegister/UserRegister.styles";
import CONSTANTS from "../../../../../constants/Constants";
import Individual from "./Individual";
import Business from "./Business";
import VentureImpact from "./VentureImpact";

const Investors = (props) => {
    const { t } = useTranslation();
    const pageType = {
        1: "INDIVIDUAL",
        2: "BUSINESS",
        3: "VENTURE FIRM/IMPACT INVESTMENT"
    };
    const { userData, avatar } = props;
    const renderMain = () => {
        return (
            <div>
                <Container>
                    <PageTitle>
                        <p className="page-title-header">{t("general.INVESTOR")}</p>
                        <p className="page-title-footer">
                            {t(`general.${pageType[userData.propertyType]}`)}
                        </p>
                    </PageTitle>
                    {userData.propertyType === CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL && <Individual originalUserData={userData} avatar={avatar} />}
                    {userData.propertyType === CONSTANTS.USER_PROPERTY_TYPE.BUSINESS && <Business originalUserData={userData} avatar={avatar} />}
                    {userData.propertyType === CONSTANTS.USER_PROPERTY_TYPE.VENTURE_INVESTMENT && <VentureImpact originalUserData={userData} avatar={avatar} />}
                </Container>
            </div>
        );
    };

    return (renderMain());
};

Investors.propTypes = {
    userData: PropTypes.object,
    avatar: PropTypes.any
};

export default Investors;
