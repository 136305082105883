import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import {
    Wrapper
} from "./Judge.styles";
import {
    PageTitle
} from "../../../components/Common/CommonLayout";
import RoundedTable from "../../../components/Common/RoundedTable";
import JudgeActions from '../../../actions/Judge';
import CONSTANTS from "../../../constants/Constants";

const Judge = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.Judge.isLoading);
    const listFundingApplication = useSelector((state) => state.Judge.listApplications);
    const columnsApplication = ['Funding status', 'name', "submitted", 'action'];
    const listOptionsFunding = {
        1: 'view'
    };
    const [showNumber, setShowNumber] = useState(10);
    const [dataFilter, setDataFilter] = useState([]);

    useEffect(() => {
        dispatch(JudgeActions.getApplicationsJudge());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDataFilter(listFundingApplication);
    }, [listFundingApplication]);

    const clickViewMoreFunding = () => {
        setShowNumber(showNumber + 10);
    };

    return (
        <DefaultLayout
            userRole='judge'
            isLoading={isLoading}
            page="applications"
            content={(
                <Wrapper>
                    <PageTitle>
                        {t("fundingApplications.Funding Applications Management")}
                    </PageTitle>
                    <RoundedTable
                        initialData={dataFilter || []}
                        columns={columnsApplication}
                        hideTitleHeader
                        tableType={CONSTANTS.TABLE_TYPE.JUDGE_APPLICATION}
                        listOptions={listOptionsFunding}
                        showNumberItems={showNumber}
                        buttonTilte="View More"
                        handleClick={clickViewMoreFunding}
                    />
                </Wrapper>
            )}
        />
    );
};

export default Judge;
