/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Whirligig from 'react-whirligig';
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  WrapperExplore,
  WrapperNav,
  WrapperContentExplore,
  UserCardContainer,
  ProfilePicture,
  UserInfomations,
  Infomation,
  GeneralButtonUser
} from "./UserOverview.styles";
import {
  HorizontalContainer,
  PageTitle
} from "../../../../components/Common/CommonLayout";
import RoundedTable from "../../../../components/Common/RoundedTable";
import FundingApplications from "../../../../components/Common/FundingApplications";
import NavigationBar from "../../../../components/Layout/PortalTemplate/NavigationBar";
import Button from "../../../../components/Common/Button";
import UserOverviewActions from "../../../../actions/UserOverview";
import Constants from "../../../../constants/Constants";
import history from "../../../../history";
import PopupUserExplore from "./PopupUserExplore";
import Utils from "../../../../libs/Utils";
import { ColorName } from "../../../../components/Variables";
import ProgressBar from "../../../../components/Common/ProgressBar";
import UserDetailPopup from "../../../../components/Layout/PortalTemplate/UserDetailPopup";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowDimensions;
};

const UserOverview = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [numberShowItems, setNumberShowItems] = useState(4);
  const NotificationsStorage = useSelector((state) => state.AdminOverView);
  let nextButton = null;
  const prevButton = null;
  let whirligig;
  const next = () => whirligig.next();
  const prev = () => whirligig.prev();
  const columnsActivity = ['date', 'activity', 'action'];
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataUserExplore, setDataUserExplore] = useState([]);
  const [currentTab, setCurrentTab] = useState('all');
  const [isShowPopUpSendMessage, setIsShowPopSendMessage] = useState(false);
  const UserExploreStorage = useSelector((state) => state.UserOverview);
  const [selectedExplore, setSelectedExplore] = useState();
  const [dataNotification, setDataNotification] = useState();
  const listOptions = {
    1: 'view',
    2: 'delete'
  };
  const [widthItem, setWidthItem] = useState('225px');
  const [userData, setUserData] = useState(Utils.getSavedUserData());
  const [isShowUserDetailPopup, setIsShowUserDetailPopup] = useState(false);
  const [selectedUserData, setDelectedUserData] = useState({});

  const checkRoleUser = () => {
    if (!_.isNil(userData) && userData.role.name === Constants.ROLE_NAME.UNASSIGNED) {
      return 'add-view-user';
    }
    return true;
  };
  useEffect(() => {
    const check = checkRoleUser();
    dispatch(UserOverviewActions.getCurrentUserDetail(userData.id));
    if (check === 'add-view-user') {
      return history.push('add-view-user');
    } else {
      if (userData && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
        dispatch(UserOverviewActions.getUserExploreRequest({ page: 1, item_per_page: 10 }));
        dispatch(UserOverviewActions.getUserReportRequest());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentTab('all');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    setDataNotification(NotificationsStorage.allActivities.data);
  }, [NotificationsStorage]);

  const selectedTabPanel = (tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (width < 1024) {
      setNumberShowItems(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _processItemDisplayInSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const openPopUpSendMessage = (data) => {
    setSelectedExplore(data);
    setIsShowPopSendMessage(true);
  };

  useEffect(() => {
    if (!_.isEmpty(dataUserExplore)) {
      _processItemDisplayInSlider();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUserExplore]);

  useEffect(() => {
    setIsLoading(UserExploreStorage.isLoading);
    setDataUserExplore(UserExploreStorage.dataUserExplore.data);
  }, [UserExploreStorage]);

  const getCurrentTab = (tab) => {
    const result = [];
    _.map(UserExploreStorage.dataUserExplore.data, (row) => {
      if (tab !== 'all') {
        if (row.role.name.toLowerCase() === tab) {
          result.push(row);
        }
      } else {
        result.push(row);
      }
    });
    setDataUserExplore(result);
    setCurrentTab(tab);
  };

  const _processItemDisplayInSlider = () => {
    if (width >= 1920) {
      setNumberShowItems(4);
      setWidthItem('280px');
    }
    if (width < 1920 && width >= 1620) {
      setNumberShowItems(4);
      setWidthItem('250px');
    }
    if (width < 1620 && width >= 1440) {
      setNumberShowItems(4);
      setWidthItem('205px');
    }
    if (width < 1440 && width >= 1366) {
      setNumberShowItems(3);
      setWidthItem('225px');
    }
    if (width < 1024) {
      setNumberShowItems(2);
      setWidthItem('220px');
    }
    if (width <= 768) {
      setNumberShowItems(1);
      setWidthItem('220px');
    }
  };

  const _getItemShouldDisplay = (defaultDisplayItem) => {
    if (dataUserExplore) {
      if (dataUserExplore.length === 1 && dataUserExplore.length < defaultDisplayItem) {
        return 1;
      }
      if (dataUserExplore.length === 2 && dataUserExplore.length < defaultDisplayItem) {
        return 2;
      }

      if (dataUserExplore.length === 3 && dataUserExplore.length < defaultDisplayItem) {
        return 3;
      }
      if (dataUserExplore.length > 2 && dataUserExplore.length > defaultDisplayItem) {
        if (width < 1366) {
          return defaultDisplayItem;
        } else {
          return 4;
        }
      }
      return defaultDisplayItem;
    }
    return defaultDisplayItem;
  };

  const showUserDetail = (data) => {
    setDelectedUserData(data);
    setIsShowUserDetailPopup(true);
  };

  const _getSecondTooltip = (row) => {
    if (row.userData.extra_data_json) {
      if (row.role.name === Constants.ROLE_NAME.CONSULTANT && row.userData.extra_data_json.about_me) return row.userData.extra_data_json.about_me;
      if (row.role.name === Constants.ROLE_NAME.ENTREPRENEUR && row.userData.extra_data_json.about_me) return row.userData.extra_data_json.about_me;
      if (row.role.name === Constants.ROLE_NAME.INVESTOR && row.userData.extra_data_json.about_me) return row.userData.extra_data_json.about_me;
    }
    return null;
  };

  const renderTableContent = () => {
    if (!_.isEmpty(dataUserExplore)) {
      const render = _.map(dataUserExplore, (row, index) => {
        return (
          <UserCardContainer key={index} width={widthItem}>
            <ProfilePicture>
              <img
                alt='profile'
                src={!_.isEmpty(row) && row.userData.url_avatar_aws ? row.userData.url_avatar_aws : "https://awodev.com/images/default-forum-user.png"}
              />
              <p>
                {!_.isEmpty(row) && row.role.name}
              </p>
            </ProfilePicture>
            <UserInfomations>
              <Infomation>
                <div className="styled-user" data-tooltip-text={!_.isEmpty(row) && row.userData.full_name}>
                  {!_.isEmpty(row) && (
                    row.userData.full_name.length > 18
                      ? `${row.userData.full_name.substr(0, 18)}...`
                      : `${row.userData.full_name.substr(0, 18)}`
                  )}
                </div>
              </Infomation>
              <Infomation />
              <Infomation>
                <div
                  className={`${row.role.name === Constants.ROLE_NAME.ENTREPRENEUR ? "styled-interests" : "styled-description"}`}
                  data-tooltip-text={_getSecondTooltip(row)}
                >
                  <span>
                    {row.role.name === Constants.ROLE_NAME.CONSULTANT && t("general.About me")}
                    {row.role.name === Constants.ROLE_NAME.ENTREPRENEUR && t("general.About")}
                    {row.role.name === Constants.ROLE_NAME.INVESTOR && t("general.About me")}
                    :
                    {" "}
                  </span>
                  {(row.role.name === Constants.ROLE_NAME.CONSULTANT) && (Utils.truncateText(_.get(row.userData.extra_data_json, "about_me", ""), 50))}
                  {(row.role.name === Constants.ROLE_NAME.ENTREPRENEUR) && (Utils.truncateText(_.get(row.userData.extra_data_json, "about_me", ""), 50))}
                  {(row.role.name === Constants.ROLE_NAME.INVESTOR) && (Utils.truncateText(_.get(row.userData.extra_data_json, "about_me", ""), 50))}
                </div>
              </Infomation>
            </UserInfomations>
            <div className="container-button">
              <GeneralButtonUser type="button" className="for-more bor-rad-10" background={ColorName.chathamsBlueDark} value={t("homepageUser.More")} onClick={() => showUserDetail(row)} />
              <GeneralButtonUser type="button" className="bor-rad-10 position-contact-me" background='white' color={ColorName.chathamsBlueDark} value={t("general.Contact me")} height="40px" onClick={() => openPopUpSendMessage(row)} />
            </div>
          </UserCardContainer>
        );
      });
      return render;
    }
  };

  const getActionName = () => {
    history.push("add-view-user");
  };

  const clickViewAllActivity = () => {
    history.push("notifications");
  };

  return (
    <DefaultLayout
      userRole='user'
      isLoading={isLoading}
      page="userOverview"
      content={(
        <BodyContainer>
          {isShowPopUpSendMessage
            && (
              <PopupUserExplore
                hideModal={() => setIsShowPopSendMessage(false)}
                dataItem={selectedExplore}
              />
            )}
          {isShowUserDetailPopup && (<UserDetailPopup userDetail={selectedUserData} hideModal={() => setIsShowUserDetailPopup(false)} />)}
          <PageTitle>
            <span>{t("general.Overview")}</span>
          </PageTitle>
          {(!_.isEmpty(userData) && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) && (
            <HorizontalContainer className="mb-2">
              <div className="progress-bar-user-profile">
                <ProgressBar
                  value={UserExploreStorage.dataUserReport.percent_user_profile || 0}
                  title={t("general.User Profile")}
                  description={UserExploreStorage.dataUserReport.percent_user_profile === 100 ? "Your profile is completed" : t("validation.Your profile is not complete")}
                  callBackAction={getActionName}
                  filter="user profile"
                  typePercent={true}
                  total={100}
                  className="for-user-overview"
                />
              </div>
            </HorizontalContainer>
          )
          }
          {
            (!_.isEmpty(userData)
              && (userData.role.name === Constants.ROLE_NAME.ENTREPRENEUR || userData.role.name === Constants.ROLE_NAME.LIMITED_USER)
              && userData.status === Constants.USER_STATUS.APPROVED)
            && (
              <FundingApplications />
            )
          }
          {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER
            && (
              <WrapperExplore>
                <div className="header-explore">{t("general.Explore")}</div>
                {
                  (!_.isEmpty(userData) && userData.status === Constants.USER_STATUS.APPROVED && dataUserExplore)
                    ? (
                      <div>
                        <WrapperNav>
                          <NavigationBar type='user over view' selectedTab={selectedTabPanel} title="" getCurrentTab={(tab) => getCurrentTab(tab)} />
                        </WrapperNav>
                        <WrapperContentExplore>
                          <button
                            type="button"
                            ref={(ref) => nextButton = ref}
                            onClick={prev}
                            className={_getItemShouldDisplay(4) >= dataUserExplore.length ? "display-none" : ""}
                          >
                            <i className="fas fa-arrow-left" size="7x" />
                          </button>
                          <Whirligig
                            visibleSlides={numberShowItems}
                            gutter={(numberShowItems > 2) ? '1em' : "2em"}
                            ref={(_whirligigInstance) => { whirligig = _whirligigInstance; }}
                            infinite
                            animationDuration={1000}
                            preventScroll
                            className={`user-expolre-slider ${dataUserExplore.length <= 4 ? "align-right" : ""}`}
                            slideBy={_getItemShouldDisplay(numberShowItems)}
                          >
                            {renderTableContent()}
                          </Whirligig>
                          <button type="button" ref={(ref) => nextButton = ref} onClick={next} className={_getItemShouldDisplay(numberShowItems) >= dataUserExplore.length ? "display-none" : ""}>
                            <i className="fas fa-arrow-right" size="7x" />
                          </button>
                        </WrapperContentExplore>
                        <div className="wrapper-button">
                          <Button background={ColorName.chathamsBlueDark} title={t("general.View All")} height="35px" width="10em" onClick={() => history.push("user-explore")} />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <br />
                        {t("validation.You will be able to see other people once the admin approved your account")}
                      </div>
                    )
                }

              </WrapperExplore>
            )
          }
          {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER
            && (
              <RoundedTable
                initialData={dataNotification || []}
                columns={columnsActivity}
                tableTitle={t("general.Notification")}
                tableType={Constants.TABLE_TYPE.NOTIFICATION_USER}
                showNumberItems={10}
                className="mt-2"
                handleClick={clickViewAllActivity}
                listOptions={listOptions}
                buttonTilte={t("general.View All")}
              />
            )}
        </BodyContainer>
      )}
    />
  );
};

UserOverview.propTypes = {

};

export default UserOverview;
