import Api from "./Base";

export function addDocumentTemplate(data) {
    return Api.postFormData("/document", data);
}

export function getDocumentTemplate(id) {
    return Api.get(`/document/${id}`);
}

export function updateDocumentTemplate(data) {
    return Api.postFormData("/document", data);
}

export function fetchDocumentTemplate(data) {
    return Api.get("/document", data);
}

export function deleteDocumentTemplate(id) {
    return Api.post(`/document/${id}/delete`);
}
