import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ROLE from "../../../../constants/Role";
import {
    Content,
    Title,
    Fields,
    Field,
    Footer,
    InputContainer
} from "./LoginAndRegister.styles";
import RegiterActions from "../../../../actions/Register";
import Button from "../../../Common/Button";
import Validation from "../../../../libs/Validation";
const Register = (props) => {
    const { t } = useTranslation();
    const { width } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch();

    const inputEmail = (e) => {
        setEmail(e.target.value);
    };

    const inputPassword = (e) => {
        setPassword(e.target.value);
    };

    const inputConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    };

    const inputFirstName = (e) => {
        setFirstName(e.target.value);
    };

    const inputLastName = (e) => {
        setLastName(e.target.value);
    };

    const switchScreen = () => {
        props.callBackSwitchScreen('login');
    };

    const register = () => {
        const validationRules = [
            {
                name: t("general.Email"),
                value: email,
                rule: ["required", "email"]
            },
            {
                name: t("general.Password"),
                value: password,
                rule: "required"
            },
            {
                name: t("general.Confirm Password"),
                value: confirmPassword,
                rule: ["required", password]
            },
            {
                name: t("general.First Name"),
                value: firstName,
                rule: "required"
            },
            {
                name: t("general.Last Name"),
                value: lastName,
                rule: "required"
            },
            {
                name: t("general.Confirm Password"),
                value: confirmPassword,
                rule: "equal",
                ruleValue: password
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        const user_data = {
            first_name: firstName,
            last_name: lastName
        };
        const data = {
            email,
            password,
            password_confirmation: confirmPassword,
            role_id: ROLE.BUSINESS,
            user_data
        };
        dispatch(RegiterActions.registerUser(data));
    };

    const onKeyEnterPress = (e) => {
        if (e.which === 13) {
            register();
        }
    };

    return (
        <Content width={width}>
            <Title>Register</Title>
            <Fields>
                <Field>
                    <InputContainer>
                        <input type='text' placeholder={t("general.Enter your email")} onChange={inputEmail} onKeyPress={onKeyEnterPress} />
                    </InputContainer>
                </Field>
                <Field>
                    <InputContainer>
                        <input type='password' placeholder={t("general.Enter your passwords")} onChange={inputPassword} onKeyPress={onKeyEnterPress} />
                    </InputContainer>
                </Field>
                <Field>
                    <InputContainer>
                        <input type='password' placeholder="Enter your confirm passwords here" onChange={inputConfirmPassword} />
                    </InputContainer>
                </Field>
                <Field>
                    <InputContainer>
                        <input type='text' placeholder="Enter your first name" onChange={inputFirstName} onKeyPress={onKeyEnterPress} />
                    </InputContainer>
                </Field>
                <Field>
                    <InputContainer>
                        <input type='text' placeholder="Enter your last name" onChange={inputLastName} onKeyPress={onKeyEnterPress} />
                    </InputContainer>
                </Field>
                <Fields>
                    <Field className="alignCenter">
                        <Button
                            background='gradient'
                            title={t("general.Submit")}
                            height="40px"
                            width="6em"
                            onClick={() => register()}
                        />
                    </Field>
                </Fields>
            </Fields>
            <Footer>
                <p
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role='button'
                    onClick={() => switchScreen()}
                    tabIndex={0}
                    onKeyDown={() => switchScreen()}
                >
                    {t("general.Sign In")}
                </p>
            </Footer>
        </Content>
    );
};

Register.propTypes = {
    width: PropTypes.string,
    callBackSwitchScreen: PropTypes.func
};

export default Register;
