import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    BodyContainer
} from "./News.styles";
import {
    PageTitle,
    GeneralButton
} from "../../../../components/Common/CommonLayout";
import RoundedTable from "../../../../components/Common/RoundedTable";
import Pagination from "../../../../components/Common/NewPagination";
import CONSTANTS from "../../../../constants/Constants";
import NewsActions from "../../../../actions/News";
import { ColorName } from '../../../../components/Variables';
import history from '../../../../history';
import Utils from '../../../../libs/Utils';

const AdminOverview = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const columns = ["image", "title", "date", "status", "action"];
    const listNews = useSelector((state) => state.News.news);
    const isLoading = useSelector((state) => state.News.isLoading);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [language] = useState(Utils.getCurrentLanguage());
    const listOptions = {
        1: "publish",
        2: "publish & send mail",
        3: "unpublish",
        4: "edit",
        5: "delete"
    };
    useEffect(() => {
        const data = {
            page: 1,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language
        };
        dispatch(NewsActions.getAllNews(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const data = {
            page,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language: i18n.language
        };
        dispatch(NewsActions.getAllNews(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        setPage(listNews.page);
        setLastPage(listNews.lastPage);
    }, [listNews]);

    const clickPageOrLink = (currentPage) => {
        const data = {
            page: currentPage,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language
        };
        dispatch(NewsActions.getAllNews(data));
    };

    const callBackPrev = () => {
        setPage(page - 1);
        clickPageOrLink(page - 1);
    };

    const callBackNext = () => {
        setPage(page + 1);
        clickPageOrLink(page + 1);
    };

    const pageOnclick = (e) => {
        clickPageOrLink(_.parseInt(e));
    };

    const _renderPagination = () => {
        return (
            <Pagination page={page} lastPage={lastPage || 1} callBackPrev={callBackPrev} callBackNext={callBackNext} pageOnclick={pageOnclick} />
        );
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={isLoading}
            page="news"
            content={(
                <BodyContainer>
                    <PageTitle>
                        {t("general.News")}
                    </PageTitle>
                    <div className="container-button">
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("news.Add News")}
                            onClick={() => history.push("/admin/add-news")}
                        />
                    </div>
                    <RoundedTable
                        initialData={listNews.data || []}
                        columns={columns}
                        tableTitle={t("general.News")}
                        tableType={CONSTANTS.TABLE_TYPE.NEWS}
                        className="mt-2"
                        showNumberItems={CONSTANTS.ITEM_PER_PAGE}
                        listOptions={listOptions}
                    />
                    {_renderPagination()}
                </BodyContainer>
            )}
        />
    );
};

AdminOverview.propTypes = {

};

export default AdminOverview;
