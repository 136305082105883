/* eslint-disable no-useless-escape */
import _ from "lodash";
import i18n from "../i18n";
/**
 Params: Array Object, callback function, withPopup Boolean
 Return: boolean
 */
const formValidation = (payload, callback = false, withPopup = true) => {
    const _validationProcess = () => {
        let errorFound = [];
        _.each(payload, (field) => {
            if (Array.isArray(field.rule)) {
                errorFound = [...errorFound, ..._multipleRuleProcess(field)];
            } else {
                errorFound = [...errorFound, ..._singleRuleProcess(field)];
            }
        });
        return _response(errorFound);
    };

    const _multipleRuleProcess = (field) => {
        let errorFound = [];
        _.each(field.rule, (rule) => {
            if (typeof rule === 'object') {
                _.forOwn(rule, (ruleValue, ruleName) => {
                    field.rule = ruleName;
                    field.ruleValue = ruleValue;
                    const validationResult = _singleRuleProcess(field);
                    errorFound = [...errorFound, ...validationResult];
                });
            } else {
                field.rule = rule;
                const validationResult = _singleRuleProcess(field);
                errorFound = [...errorFound, ...validationResult];
            }
        });
        return errorFound;
    };

    const _singleRuleProcess = (field) => {
        if (!Array.isArray(field.ruleValue)) {
            const result = _simpleValidation(field);
            const errorFound = [];
            if (!result.status) errorFound.push({ field, text: result.message });
            return errorFound;
        } else {
            const errorFound = [];
            const processedFields = _generateFieldFromArrayField(field);
            _.each(processedFields, (eachField) => {
                const result = _simpleValidation(eachField);
                if (!result.status) errorFound.push({ field, text: result.message });
            });
            return errorFound;
        }
    };

    const _generateFieldFromArrayField = (field) => {
        const processedFields = [];
        _.each(field.value, (ruleBlock, ruleBlockIndex) => {
            _.each(field.ruleValue, (eachField) => {
                const processedField = {
                    name: `${field.name}-${ruleBlockIndex + 1} ${capitalizeFirstLetter(eachField)}`,
                    rule: field.rule,
                    value: ruleBlock[eachField]
                };
                processedFields.push(processedField);
            });
        });

        return processedFields;
    };

    const _response = (errorFound) => {
        if (!_.isEmpty(errorFound)) {
            const text = _(errorFound).flatMapDeep().map('text').value();

            if (withPopup) popupAlert({ title: i18n.t("validation.Validation error"), text: text.join('\n'), type: "error" });
            if (callback) return callback({ errors: errorFound });
            return false;
        }
        return true;
    };

    const _simpleValidation = (field) => {
        if (field.rule === 'required' && _.isEmpty(field.value) && field.value === "") return { status: false, message: `${field.name} ${i18n.t("validation.is required")}` };
        if (field.rule === 'number' && !_.isNumber(field.value)) return { status: false, message: `${field.name} ${i18n.t("validation.is not a number")}` };
        if (field.rule === 'email' && !_patternValidation('email', field.value)) return { status: false, message: `${i18n.t("validation.Invalid email address")}` };
        if (field.rule === 'min' && removeSpecialCharacter(removeHTMLTags(field.value)).length < field.ruleValue) return { status: false, message: i18n.language === "en" ? `${field.name} ${i18n.t("validation.min length is")} ${field.ruleValue}` : `${i18n.t("validation.min length is")} ${field.name} është ${field.ruleValue}` };
        if (field.rule === 'max' && removeSpecialCharacter(removeHTMLTags(field.value)).length > field.ruleValue) return { status: false, message: i18n.language === "en" ? `${field.name} ${i18n.t("validation.max length is")} ${field.ruleValue}` : `${i18n.t("validation.max length is")} ${field.name} është ${field.ruleValue}` };
        if (field.rule === 'equal' && field.value !== field.ruleValue) return { status: false, message: `${field.name} ${i18n.t("validation.is not matched")}` };
        return { status: true, message: "" };
    };

    const _patternValidation = (type, data) => {
        if (type === 'email') {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(data);
        }
    };

    return _validationProcess();
};

const resolveSpaceTextEditor = (content) => {
    const resolveText = removeHTMLTags(content);
    const chunkText = resolveText.split(' ');
    let totalCharacter = 0;
    let processedText = "";
    _.map(chunkText, (each) => {
        if (totalCharacter > 250) return;
        totalCharacter += each.length;
        processedText += `${each} `;
    });
    return processedText.trim();
};

const truncateText = (source, size = 20) => {
    return source.length > size ? `${source.slice(0, size - 1)}…` : source;
};

const removeSpecialCharacter = (source) => {
    const a = source.replace(/(?:&nbsp;)/g, '');
    return a.replace(/ /g, '');
};

const removeHTMLTags = (source) => {
    if (source) return source.replace(/<[^>]*>?/gm, '');
    return "";
};

const popupAlert = (data) => {
    // type: success, error, warning, info, question
    const {
        type, title, text, buttons
    } = data;
    return new Promise((resolve) => {
        // eslint-disable-next-line no-undef
        swal({
            title, text, icon: type, buttons
        }).then((response) => resolve(response));
    });
};
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export default {
    formValidation,
    resolveSpaceTextEditor,
    truncateText,
    removeHTMLTags,
    popupAlert,
    capitalizeFirstLetter
};
