import styled from 'styled-components';
import { ColorName } from '../../Variables';

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em;
    color: white;
    text-transform: uppercase;
    div {
        padding: 0.2em 0.3em;
        background: ${ColorName.botticelli};
        cursor: pointer;
        height: 18px;    
        display: flex;
        align-items: center;
    }
    & .prev {
        position: relative;
        background: ${ColorName.chathamsBlueDark};
        &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent ${ColorName.chathamsBlueDark} transparent transparent;
            content: '';
            display: block;
            left: -24px;
            position: absolute;
            z-index: 2;
            border-width: 12px;
            top: 0px;
            bottom: 0;
        }
    }
    & .next {
        position: relative;
        background: ${ColorName.chathamsBlueDark};
        &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent transparent  transparent ${ColorName.chathamsBlueDark};
            content: '';
            display: block;
            right: -24px;
            position: absolute;
            z-index: 2;
            border-width: 12px;
            top: 0px;
            bottom: 0;
        }
    }
    & .active {
        background: ${ColorName.chathamsBlueDark};
    }
    & .page {
        padding: 0.2em 0.5em;
        margin: 0 0.2em;
    }
`;

export {
    PaginationContainer
};
