import styled from 'styled-components';
import { ColorName } from "../../Variables";
const FAQDropdownComponent = styled.div`
    color: ${ColorName.chathamsBlueDark}
`;

const QuestionCategory = styled.div`
    border-bottom: 1px solid gray;
    display: flex;
    padding: 1em 0;
    align-items: center;
    & .flex-1 {
        flex: 1
    }
`;

const QuestionDetail = styled.div`
    padding: 0 1em;
    &.d-none {
        display: none;   
    }
`;

const Content = styled.div`
    padding: 1em 1em 0 1em;
`;

export {
    FAQDropdownComponent,
    QuestionCategory,
    QuestionDetail,
    Content
}