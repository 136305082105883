// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
    Content,
    Title,
    Fields,
    Field,
    Footer,
    InputContainer
} from "./LoginAndRegister.styles";
import Button from "../../../Common/Button";
import LoginActions from "../../../../actions/Authentication";

const Login = (props) => {
    const { t } = useTranslation();
    const { width } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const switchScreen = (screen) => {
        props.callBackSwitchScreen(screen);
    };

    const inputEmail = (e) => {
        setEmail(e.target.value);
    };

    const inputPassword = (e) => {
        setPassword(e.target.value);
    };

    const login = () => {
        dispatch(LoginActions.login(email, password));
    };

    const onKeyEnterPress = (e) => {
        if (e.which === 13) {
            dispatch(LoginActions.login(email, password));
        }
    };

    return (
        <Content width={width}>
            <Title>{t("general.Login")}</Title>
            <Fields>
                <Field>
                    <InputContainer widthInput={width}>
                        <input type='text' placeholder={t("general.Enter your email")} onChange={inputEmail} onKeyPress={onKeyEnterPress} />
                    </InputContainer>
                </Field>
                <Field>
                    <InputContainer widthInput={width}>
                        <input type='password' placeholder={t("general.Enter your passwords")} onChange={inputPassword} onKeyPress={onKeyEnterPress} />
                    </InputContainer>
                </Field>
            </Fields>
            <Fields>
                <Field className="alignCenter">
                    <Button
                        background='gradient'
                        title={t("general.Submit")}
                        height="40px"
                        width="6em"
                        onClick={() => login()}
                    />
                </Field>
            </Fields>
            <Footer>
                <p
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role='button'
                    onClick={() => switchScreen("register")}
                    tabIndex={0}
                    onKeyDown={() => switchScreen("register")}
                >
                    {t("general.Sign Up")}
                </p>
                <p
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role='button'
                    onClick={() => switchScreen("forgotPassword")}
                    tabIndex={0}
                    onKeyDown={() => switchScreen("forgotPassword")}
                >
                    {t("general.Forgot password")}
                    {' '}
?
                </p>
            </Footer>
        </Content>
    );
};

Login.propTypes = {
    width: PropTypes.string,
    callBackSwitchScreen: PropTypes.func
};

export default Login;
