import Api from "./Base";

export function createNewFundingApplication(data) {
    return Api.postFormData("/funding_applications", data);
}

export function getListFundingApplication() {
    return Api.get("/funding_applications");
}

export function getFundingApplication(data) {
    return Api.get("/funding_applications", data);
}

export function getFundingApplicationDashboard(data) {
    return Api.get("/funding_applications_dashboard_user", data);
}

export function getFundingApplicationById(id) {
    return Api.get(`/funding_applications/${id}`);
}

export function deleteDocumentWinner(id, data) {
    return Api.postFormData(`apply_application/${id}/delete_document_winner`, data);
}

export function editFundingApplication(data, id) {
    return Api.postFormData(`/funding_applications/${id}/update`, data);
}

export function getListJudge(data) {
    return Api.get('/users/', data);
}

export function addJudgeToApplication(data) {
    return Api.post('/application_judges', data);
}
export function removeJudge(id) {
    return Api.post(`/application_judges/${id}/delete`);
}

export function getListFundingApplicationLimited(data) {
    return Api.get("/funding_applications", data);
}

export function getListFundingApplicationAdmin() {
    return Api.get("/funding_applications_dashboard");
}
