import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import { GridCol, ContentBodyContainer } from '../../../Common/CommonLayout';
import {
    Container,
    Wrapper,
    TitleFooter,
    LinkFooter,
    ItemLink,
    WrapperLink,
    Button,
    ImagesLogo
} from './Footer.styles';
import { Images } from '../../../../themes';
import { ColorName } from '../../../Variables';
import ContactUsActions from "../../../../actions/ContactUs";
import history from '../../../../history';
const Footer = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const dataContactUs = useSelector((state) => state.ContactUs.contact);
    const type = "information_contact_us";
    const [data, setData] = useState({
        email: {
            en: "",
            al: ""
        },
        phone: {
            en: "",
            al: ""
        },
        address: {
            en: "",
            al: ""
        },
        city: {
            en: "",
            al: ""
        }
    });
    const listEntrepreneurs = [
        {
            title: t("general.What We Do"),
            path: "/homepage/entrepreneurs/what-we-do#main-content"
        },
        {
            title: t("general.Community"),
            path: "/homepage/entrepreneurs/community#main-content"
        },
        {
            title: t("general.Criteria"),
            path: "/homepage/entrepreneurs/criteria#main-content"
        },
        {
            title: t("general.Process"),
            path: "/homepage/entrepreneurs/process#main-content"
        }
    ];
    const listInvestors = [
        {
            title: t("general.What We Do"),
            path: "/homepage/investors/what-we-do#main-content"
        },
        {
            title: t("general.Community"),
            path: "/homepage/investors/community#main-content"
        },
        {
            title: t("general.Process"),
            path: "/homepage/investors/process#main-content"
        }
    ];
    const listConsultants = [
        {
            title: t("general.What We Do"),
            path: "/homepage/consultants/what-we-do#main-content"
        },
        {
            title: t("general.Community"),
            path: "/homepage/consultants/community#main-content"
        },
        {
            title: t("general.Process"),
            path: "/homepage/consultants/process#main-content"
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            const dataGet = {
                type
            };
            await dispatch(ContactUsActions.getContactUs(dataGet));
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(dataContactUs)) {
            setData(dataContactUs.data_json);
        }
    }, [dataContactUs]);

    return (
        <Container>
            <ContentBodyContainer>
                <Wrapper>
                    <GridCol colSize='7' gridTemplate='7'>
                        <WrapperLink>
                            <GridCol colSize='1' gridTemplate='7' direction='column' className='custom-width'>
                                <TitleFooter>
                                    {t("general.Entrepreneurs")}
                                </TitleFooter>
                                <LinkFooter>
                                    {listEntrepreneurs.map((value, index) => (
                                        <ItemLink key={index} href={value.path}>{value.title}</ItemLink>
                                    ))}
                                    <Button
                                        color={ColorName.bahamaBlue}
                                        background={ColorName.oysterBay}
                                        width='3.5em'
                                        margin='1em 0'
                                        href="/homepage/entrepreneurs/apply#main-content"
                                    >
                                        {t("general.Apply")}
                                    </Button>
                                </LinkFooter>
                            </GridCol>
                            <GridCol colSize='1' gridTemplate='7' className='custom-width'>
                                <TitleFooter>
                                    {t("general.Investors")}
                                </TitleFooter>
                                <LinkFooter>
                                    {listInvestors.map((value, index) => (
                                        <ItemLink key={index} href={value.path}>{value.title}</ItemLink>
                                    ))}
                                    <ItemLink />
                                    <Button
                                        color={ColorName.bahamaBlue}
                                        background={ColorName.oysterBay}
                                        width='3.5em'
                                        margin='1em 0'
                                        href="/homepage/investors/apply#main-content"
                                    >
                                        {t("general.Apply")}
                                    </Button>
                                </LinkFooter>
                            </GridCol>
                            <GridCol colSize='1' gridTemplate='7' className='custom-width'>
                                <TitleFooter>
                                    {t("general.Consultants")}
                                </TitleFooter>
                                <LinkFooter>
                                    {listConsultants.map((value, index) => (
                                        <ItemLink key={index} href={value.path}>{value.title}</ItemLink>
                                    ))}
                                    <ItemLink />
                                    <Button
                                        color={ColorName.bahamaBlue}
                                        background={ColorName.oysterBay}
                                        width='3.5em'
                                        margin='1em 0'
                                        href="/homepage/consultants/apply#main-content"
                                    >
                                        {t("general.Apply")}
                                    </Button>
                                </LinkFooter>
                            </GridCol>
                            <GridCol colSize='1' gridTemplate='7' className='custom-width'>
                                <TitleFooter>
                                    {t("general.Resources")}
                                </TitleFooter>
                                <LinkFooter>
                                    <ItemLink onClick={() => history.push('/homepage/library')}>
                                        {t("general.Library")}
                                    </ItemLink>
                                    <ItemLink onClick={() => history.push('/homepage/events')}>
                                        {t("general.Events")}
                                    </ItemLink>
                                    <ItemLink onClick={() => history.push('/homepage/news')}>
                                        {t("general.News")}
                                    </ItemLink>
                                    <ItemLink onClick={() => history.push('/homepage/faq')}>
                                        {t("general.FAQs")}
                                    </ItemLink>
                                </LinkFooter>
                            </GridCol>
                            <GridCol colSize='1' gridTemplate='7' className='custom-width'>
                                <TitleFooter>
                                    {t("general.About Us")}
                                </TitleFooter>
                                <LinkFooter>
                                    <ItemLink onClick={() => history.push('/homepage/about')}>
                                        {t("general.Dua Partner Invest")}
                                    </ItemLink>
                                    <ItemLink onClick={() => history.push('/homepage/subscribe')}>
                                        Subscribe
                                    </ItemLink>
                                    <div>
                                        <i
                                            className="fab fa-facebook-square"
                                            onClick={() => window.open("https://www.facebook.com/duapartnerinvest/")}
                                        />
                                        <i
                                            className="fab fa-instagram"
                                            onClick={() => window.open("https://www.instagram.com/duapartnerinvest/")}
                                        />
                                        <i
                                            className="fab fa-linkedin"
                                            onClick={() => window.open("https://www.linkedin.com/company/dua-partner-invest")}
                                        />
                                    </div>
                                </LinkFooter>
                            </GridCol>
                            <GridCol colSize='1' gridTemplate='7' className='custom-width'>
                                <TitleFooter>
                                    {t("general.Legal")}
                                </TitleFooter>
                                <LinkFooter>
                                    <ItemLink onClick={() => history.push('/homepage/terms')}>
                                        {t("general.Terms")}
                                    </ItemLink>
                                    <ItemLink onClick={() => history.push('/homepage/privacy-policy')}>
                                        {t("general.Privacy Policy")}
                                    </ItemLink>
                                </LinkFooter>
                            </GridCol>
                            <GridCol colSize='1' gridTemplate='7' className='custom-width'>
                                <TitleFooter>
                                    {t("general.Contact Us")}
                                </TitleFooter>
                                <LinkFooter>
                                    {data && (
                                        <>
                                            <ItemLink>
                                                {data.email[i18n.language] || ""}
                                            </ItemLink>
                                            <ItemLink>
                                                {data.phone[i18n.language] || ""}
                                            </ItemLink>
                                            <ItemLink>
                                                {data.address[i18n.language] || ""}
                                            </ItemLink>
                                            <ItemLink>
                                                {data.city[i18n.language] || ""}
                                            </ItemLink>
                                        </>
                                    )}

                                </LinkFooter>
                            </GridCol>
                        </WrapperLink>
                    </GridCol>
                </Wrapper>
                <div className="footer-logo-image">
                    <ImagesLogo src={Images.logoFooter} onClick={() => history.push('/homepage/')} />
                    <div className="copyright">&copy; 2021 Partners Albania for Change and Development</div>
                    <div className="copyright">Designed, Built and Maintained by <a href="http://idradigis.al" target="_blank" rel="noopener noreferrer"> IDRA DiGIS</a></div>
                </div>
            </ContentBodyContainer>
        </Container>
    );
};

export default Footer;
