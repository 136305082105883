import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import JoditEditor from 'jodit-react';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    PageTitle,
    GeneralButton,
    LabelSlide
} from "../../../../components/Common/CommonLayout";
import {
    BodyContainer,
    LibraryContainer,
    WrapperInput,
    TitleInput,
    InputContainer,
    GeneralButtonLanguage
} from "./Library.styles";
import { ColorName } from "../../../../components/Variables";
import LibraryActions from "../../../../actions/Library";
import Utils from "../../../../libs/Utils";
import Validation from "../../../../libs/Validation";
import GeneralCheckBoxForm from "../../../../components/Common/GeneralCheckBoxForm";
import CONSTANTS from "../../../../constants/Constants";
import { Images } from "../../../../themes";
import Constants from '../../../../constants/Constants';

const EditBook = () => {
    const checkBoxRef = [];
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const isLoading = useSelector((state) => state.Library.isLoading);
    const bookData = useSelector((state) => state.Library.libraryDetailData);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());
    const [imageUrl, setImageUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState("");
    const [postData, setPostData] = useState({});
    const editor = useRef(null);
    const editorAl = useRef(null);
    const [detailData, setDetailData] = useState([]);
    const [ALDetailData, setALDetailData] = useState([
        {
            title: "",
            introduction: "",
            content: ""
        }
    ]);
    const [removeChapterID, setRemoveChapterID] = useState([]);
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);

    useEffect(() => {
        dispatch(LibraryActions.getLibraryDetailData(location.state.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(bookData)) {
            if (bookData.is_support_mul_lang) {
                if (i18n.language === Constants.LANGUAGE_CODE.AL) {
                    setLanguage(Constants.LANGUAGE_CODE.AL);
                } else {
                    setLanguage(Constants.LANGUAGE_CODE.EN);
                }
            } else if (bookData.title_json[i18n.language]) {
                setLanguage(i18n.language);
            } else if (i18n.language === Constants.LANGUAGE_CODE.EN) {
                setLanguage(Constants.LANGUAGE_CODE.AL);
            } else {
                setLanguage(Constants.LANGUAGE_CODE.EN);
            }
            setIsmultipleLanguage(bookData.is_support_mul_lang);

            const currentData = {
                title: bookData.title_json,
                description: bookData.description_json,
                link_youtube: bookData.link_youtube,
                is_incoming: bookData.is_incoming,
                is_support_mul_lang: bookData.is_support_mul_lang,
                type: bookData.type,
                media: bookData.url_media_aws,
                media_al: bookData.url_media_al_aws,
                cover_img: bookData.url_cover_img
            };
            setPostData({ ...postData, ...currentData });
            if (bookData.type === CONSTANTS.LIBRARY_TYPE.BOOK) {
                if (!bookData.is_support_mul_lang) {
                    const data = [];
                    _.map(bookData.libraryDetails, (detail) => {
                        data.push({
                            title: detail.title,
                            introduction: detail.introduction,
                            content: detail.content,
                            id: detail.id
                        });
                    });
                    setDetailData(data);
                } else {
                    const data = [];
                    const ALData = [];
                    _.map(bookData.libraryDetails, (detail) => {
                        if (detail.title_json.en && detail.introduction_json.en && detail.content_json.en) {
                            data.push({
                                title: detail.title_json.en,
                                introduction: detail.introduction_json.en,
                                content: detail.content_json.en,
                                id: detail.id
                            });
                        }
                        if (detail.title_json.al && detail.introduction_json.al && detail.content_json.al) {
                            ALData.push({
                                title: detail.title_json.al,
                                introduction: detail.introduction_json.al,
                                content: detail.content_json.al,
                                id: detail.id
                            });
                        }
                    });
                    setDetailData(data);
                    setALDetailData(ALData);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookData]);

    const addNewChapter = () => {
        const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? [...detailData] : [...ALDetailData];
        const checkEmptyField = _.find(
            currentDetailData,
            (el) => el.title === "" || el.content === "" || el.introduction === ""
        );
        if (checkEmptyField) {
            return Utils.popupAlert({
                title: t("alert.Warning"),
                text: t("alert.Please fill all field before add new chapter!"),
                type: "warning"
            });
        }
        currentDetailData.push({
            title: "",
            introduction: "",
            content: ""
        });
        language === CONSTANTS.LANGUAGE_CODE.EN ? setDetailData(currentDetailData) : setALDetailData(currentDetailData);
    };

    const handleChangeDetailData = (original, value, field) => {
        const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? [...detailData] : [...ALDetailData];
        const editedRow = _.find(currentDetailData, original);
        editedRow[field] = value;
        const index = _.findIndex(currentDetailData, original);
        currentDetailData.splice(index, 1, editedRow);
        language === CONSTANTS.LANGUAGE_CODE.EN ? setDetailData(currentDetailData) : setALDetailData(currentDetailData);
    };

    const handleChangeFile = (event, type) => {
        if (event.target.files.length !== 0) {
            const url = URL.createObjectURL(event.target.files[0]);
            if (type === 'image') {
                setPostData({ ...postData, cover_img: event.target.files[0] });
                setImageUrl(url);
            } else if (type === 'video') {
                setVideoUrl(url);
                setPostData({ ...postData, media: event.target.files[0] });
            } else {
                setPostData({ ...postData, media: event.target.files[0] });
            }
        }
    };

    const onChangePostData = (value, type) => {
        const currentPostData = { ...postData };
        if (type === "link_youtube") return setPostData({ ...currentPostData, link_youtube: value });
        language === CONSTANTS.LANGUAGE_CODE.EN ? currentPostData[type].en = value : currentPostData[type].al = value;
        setPostData(currentPostData);
    };

    const removeChapter = (data) => {
        const currentRemoveChapterID = [...removeChapterID];
        if (_.has(data, 'id') && !currentRemoveChapterID.includes(data.id)) currentRemoveChapterID.push(data.id);
        const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? [...detailData] : [...ALDetailData];
        const index = _.findIndex(currentDetailData, data);
        currentDetailData.splice(index, 1);
        language === CONSTANTS.LANGUAGE_CODE.EN ? setDetailData(currentDetailData) : setALDetailData(currentDetailData);
        setRemoveChapterID(currentRemoveChapterID);
    };

    const _resolveDetailData = () => {
        const result = {};
        if (postData.is_support_mul_lang) {
            _.map(detailData, (data, index) => {
                const resolveData = {};
                _.map(_.keys(data), (key) => {
                    if (key !== "id") resolveData[key] = { en: data[key] };
                    if (key === "id") resolveData[key] = data[key];
                });
                result[index] = resolveData;
            });
            _.map(ALDetailData, (data, index) => {
                _.map(_.keys(data), (key) => {
                    if (key !== "id") result[index][key] = { ...result[index][key], al: data[key] };
                    if (key === "id") result[index][key] = data[key];
                });
            });
        } else {
            const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? detailData : ALDetailData;
            _.map(currentDetailData, (data, index) => {
                const resolveData = {};
                _.map(_.keys(data), (key) => {
                    resolveData[key] = { [language]: data[key] };
                    if (key === "id") resolveData[key] = data[key];
                });
                result[index] = resolveData;
            });
        }
        return result;
    };

    const updateBook = () => {
        let validationRules = [];
        if (postData.is_support_mul_lang) {
            validationRules = [
                {
                    name: `${t("general.Title")} ${t("general.English")}`,
                    value: postData.title.en,
                    rule: ["required", { min: 10, max: 200 }]
                },
                {
                    name: `${t("general.Title")} ${t("general.Albania")}`,
                    value: postData.title.al,
                    rule: ["required", { min: 10, max: 200 }]
                },
                {
                    name: t("library.Cover Image"),
                    value: postData.cover_img,
                    rule: "required"
                },
                {
                    name: t("library.Incoming"),
                    value: postData.is_incoming,
                    rule: "required"
                },
                {
                    name: `${t("general.Description")} ${t("general.English")}`,
                    value: editor.current.value,
                    rule: "required"
                },
                {
                    name: `${t("general.Description")} ${t("general.Albania")}`,
                    value: editorAl.current.value,
                    rule: "required"
                }
            ];
        } else {
            const textEditor = language === CONSTANTS.LANGUAGE_CODE.EN ? editor : editorAl;
            validationRules = [
                {
                    name: t("general.Title"),
                    value: postData.title[language],
                    rule: ["required", { min: 10, max: 200 }]
                },
                {
                    name: t("library.Cover Image"),
                    value: postData.cover_img,
                    rule: "required"
                },
                {
                    name: t("library.Incoming"),
                    value: postData.is_incoming,
                    rule: "required"
                },
                {
                    name: t("general.Description"),
                    value: textEditor.current.language,
                    rule: "required"
                }
            ];
        }
        if (!postData.is_incoming && postData.type === CONSTANTS.LIBRARY_TYPE.BOOK) {
            if (postData.is_support_mul_lang) {
                validationRules.push({
                    name: "EN Chapter",
                    value: detailData,
                    rule: [{ required: ["title", "content", "introduction"] }]
                });
                validationRules.push({
                    name: "AL Chapter",
                    value: ALDetailData,
                    rule: [{ required: ["title", "content", "introduction"] }]
                });
            } else {
                const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? detailData : ALDetailData;
                validationRules.push({
                    name: "Chapter",
                    value: currentDetailData,
                    rule: [{ required: ["title", "content", "introduction"] }]
                });
            }
        }
        if (!Validation.formValidation(validationRules)) return;
        const formData = new FormData();
        if (postData.media === "") {
            formData.append("remove_media", true);
        } else {
            formData.append("media", postData.media);
        }
        if (postData.media_al === "") {
            formData.append("remove_media_al", true);
        } else {
            formData.append("media_al", postData.media_al);
        }
        const descriptionData = {
            en: _.get(editor, 'current.value', ""),
            al: _.get(editorAl, 'current.value', "")
        };
        formData.append('description', JSON.stringify(descriptionData));
        _.map(_.keys(postData), (key) => {
            if (key !== 'description') {
                if (key === "title") {
                    formData.append(`${key}`, JSON.stringify(postData[key]));
                } else {
                    formData.append(`${key}`, postData[key]);
                }
            }
        });
        if (!postData.is_incoming && postData.type === CONSTANTS.LIBRARY_TYPE.BOOK) {
            const resolveData = _resolveDetailData();
            _.map(resolveData, (data, index) => {
                let addIndex = 0;
                if (_.has(data, 'id')) {
                    _.map(_.keys(data), (key) => {
                        if (key !== "id") formData.append(`data_detail[${index}][${key}]`, JSON.stringify(data[key]));
                        if (key === "id") formData.append(`data_detail[${index}][${key}]`, data[key]);
                    });
                } else {
                    _.map(_.keys(data), (key) => {
                        formData.append(`data_detail_add[${addIndex}][${key}]`, JSON.stringify(data[key]));
                    });
                    addIndex += 1;
                }
            });
            if (!_.isEmpty(removeChapterID)) {
                _.map(removeChapterID, (id, index) => {
                    formData.append(`data_detail_remove[${index}]`, id);
                });
            }
        }
        dispatch(LibraryActions.updateBook(location.state.id, formData));
    };

    const _renderCreateButton = () => {
        return (
            <div className="container-button mt-2">
                <GeneralButton
                    type="button"
                    className="for-more"
                    background={ColorName.chathamsBlue}
                    value={t("general.Update")}
                    onClick={() => updateBook()}
                />
            </div>
        );
    };

    const _renderAddNewChapter = () => {
        return (
            <div className="container-button">
                <GeneralButton
                    type="button"
                    className="for-more"
                    background={ColorName.chathamsBlue}
                    value={t("library.Add New Chapter")}
                    onClick={() => addNewChapter()}
                />
            </div>
        );
    };

    const _renderDetailContent = () => {
        const renderDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? detailData : ALDetailData;
        const render = _.map(renderDetailData, (detail, index) => {
            return (
                <div key={`${index}${language}`}>
                    <WrapperInput>
                        <TitleInput className="chapter">
                            {t("library.Chapter")}
                            {' '}
                            {index + 1}
                            {' '}
                            :
                        </TitleInput>
                        {index !== 0 && <i className="fas fa-trash-alt" onClick={() => removeChapter(detail)} />}
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("general.Title")}</TitleInput>
                        <InputContainer
                            defaultValue={detail.title || ''}
                            onChange={(event) => handleChangeDetailData(detail, event.target.value, 'title')}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("library.Introduction")}</TitleInput>
                        <InputContainer
                            defaultValue={detail.introduction || ''}
                            onChange={(event) => handleChangeDetailData(detail, event.target.value, 'introduction')}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("general.Content")}</TitleInput>
                        <JoditEditor
                            value={detail.content || ''}
                            onChange={(newContent) => handleChangeDetailData(detail, newContent, 'content')}
                        />
                    </WrapperInput>
                </div>
            );
        });
        return render;
    };

    const _renderCheckBoxInComming = () => {
        return (
            <span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={postData.is_incoming}
                    onChange={() => setPostData({
                        ...postData,
                        is_incoming: !postData.is_incoming
                    })
                    }
                    name="yes"
                    checkBoxRef={(ref) => (checkBoxRef.yes = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.yes.click()}>{t("general.Yes")}</span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={!postData.is_incoming}
                    onChange={() => setPostData({
                        ...postData,
                        is_incoming: !postData.is_incoming
                    })
                    }
                    name="no"
                    className="ml-2"
                    checkBoxRef={(ref) => (checkBoxRef.no = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.no.click()}>{t("general.No")}</span>
            </span>
        );
    };

    const _renderSwitchLangugage = () => {
        return (
            <div className="language">
                <LabelSlide className="switch">
                    <input type="checkbox" defaultChecked={isMultipleLanguage} value={isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} />
                    <span className="slider round" />
                </LabelSlide>
                {isMultipleLanguage
                    && (
                        <>
                            <GeneralButtonLanguage
                                type="button"
                                className={(language === CONSTANTS.LANGUAGE_CODE.EN) ? "active" : ""}
                                value={t("general.English")}
                                onClick={() => setLanguage(CONSTANTS.LANGUAGE_CODE.EN)}
                            />
                            <GeneralButtonLanguage
                                type="button"
                                className={(language === CONSTANTS.LANGUAGE_CODE.AL) ? "active" : ""}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(CONSTANTS.LANGUAGE_CODE.AL)}
                            />
                        </>
                    )}
                {!isMultipleLanguage
                    && (
                        <>
                            <GeneralButtonLanguage
                                type="button"
                                className={(language === CONSTANTS.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                value={t("general.English")}
                                onClick={() => setLanguage(CONSTANTS.LANGUAGE_CODE.EN)}
                                disabled
                            />
                            <GeneralButtonLanguage
                                type="button"
                                className={(language === CONSTANTS.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(CONSTANTS.LANGUAGE_CODE.AL)}
                                disabled
                            />
                        </>
                    )}
            </div>
        );
    };

    const _renderCheckBoxType = () => {
        return (
            <span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={postData.type === CONSTANTS.LIBRARY_TYPE.BOOK}
                    onChange={() => setPostData({
                        ...postData,
                        type: CONSTANTS.LIBRARY_TYPE.BOOK
                    })
                    }
                    name="book"
                    checkBoxRef={(ref) => (checkBoxRef.book = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.book.click()}>{t("library.Library")}</span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={postData.type === CONSTANTS.LIBRARY_TYPE.VIDEO}
                    onChange={() => setPostData({
                        ...postData,
                        type: CONSTANTS.LIBRARY_TYPE.VIDEO
                    })
                    }
                    name="video"
                    className="ml-2"
                    checkBoxRef={(ref) => (checkBoxRef.video = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.video.click()}>{t("general.Video")}</span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={postData.type === CONSTANTS.LIBRARY_TYPE.PDF}
                    onChange={() => setPostData({
                        ...postData,
                        type: CONSTANTS.LIBRARY_TYPE.PDF
                    })
                    }
                    name="pdf"
                    className="ml-2"
                    checkBoxRef={(ref) => (checkBoxRef.pdf = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.pdf.click()}>PDF</span>
            </span>
        );
    };

    const _renderVideoUploadContainer = () => {
        return (
            <WrapperInput>
                <TitleInput>{t("general.Video")}</TitleInput>
                <input
                    type="file"
                    accept="video/*"
                    onChange={(event) => handleChangeFile(event, "video")}
                />
                {(videoUrl || bookData.url_media_aws) && (
                    <video controls>
                        <source src={videoUrl || bookData.url_media_aws} />
                    </video>
                )}
            </WrapperInput>
        );
    };

    const handleChangeFilePDFAl = (event) => {
        if (event.target.files.length > 0) {
            setPostData({ ...postData, media_al: event.target.files[0] });
        }
    };

    const deletePDF = () => {
        setPostData({ ...postData, media: "" });
    };

    const deletePDFAl = () => {
        setPostData({ ...postData, media_al: "" });
    };

    const _renderUploadPDF = () => {
        return (
            <>
            <WrapperInput className={language === CONSTANTS.LANGUAGE_CODE.AL ? "hidden" : ""}>
                <TitleInput>PDF</TitleInput>
                <input
                    type="file"
                    accept=".pdf"
                    onChange={(event) => handleChangeFile(event, "pdf")}
                />
                {postData.media
                    && (
                        <>
                            <div className="contain-remove">
                                <a href={bookData.url_media_aws} target="_blank" rel="noopener noreferrer">
                                    <img className="pdf" src={Images.PDFIcon} alt='pdf' />
                                </a>
                                <span onClick={() => deletePDF()}>X</span>
                            </div>

                        </>
                    )
                }
                {postData.media && bookData.url_media && (
                    <p>{postData.media.name || bookData.url_media ? _.last(bookData.url_media_aws.split("/")) : ""}</p>
                )
                }
            </WrapperInput>

            <WrapperInput className={language === CONSTANTS.LANGUAGE_CODE.EN ? "hidden" : ""}>
                <TitleInput>PDF</TitleInput>
                <input
                    type="file"
                    accept=".pdf"
                    onChange={(event) => handleChangeFilePDFAl(event)}
                />
                {postData.media_al
                    && (
                        <>
                            <div className="contain-remove">
                                <a href={bookData.url_media_al_aws} target="_blank" rel="noopener noreferrer">
                                    <img className="pdf" src={Images.PDFIcon} alt='pdf' />
                                </a>
                                <span onClick={() => deletePDFAl()}>X</span>
                            </div>

                        </>
                    )
                }
                {postData.media_al && bookData.url_media_al_aws && (
                    <p>{postData.media_al.name || bookData.url_media_al_aws ? _.last(bookData.url_media_al_aws.split("/")) : ""}</p>
                )
                }
            </WrapperInput>
            </>
        );
    };

    const renderMain = () => {
        if (!_.isEmpty(postData)) {
            return (
                <BodyContainer>
                    <PageTitle>{t("library.Edit Library")}</PageTitle>
                    <LibraryContainer className="mb-2">
                        <WrapperInput>
                            <TitleInput>
                                {t("general.Title")}
                                {_renderSwitchLangugage()}
                            </TitleInput>
                            <InputContainer
                                type='text'
                                value={postData.title[language] || ''}
                                onChange={(event) => onChangePostData(event.target.value, 'title')}
                            />
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>{t("general.Description")}</TitleInput>
                            <div className={language === CONSTANTS.LANGUAGE_CODE.EN ? "" : "hidden-content"}>
                                <JoditEditor
                                    ref={editor}
                                    value={postData.description["en"]}
                                />
                            </div>
                            <div className={language === CONSTANTS.LANGUAGE_CODE.AL ? "" : "hidden-content"}>
                                <JoditEditor
                                    ref={editorAl}
                                    value={postData.description["al"]}
                                />
                            </div>
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>{t("general.Youtube URL")}</TitleInput>
                            <InputContainer value={postData.link_youtube} height="200px" onChange={(event) => onChangePostData(event.target.value, 'link_youtube')} />
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>{t("general.Type")}</TitleInput>
                            {_renderCheckBoxType()}
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>{t("library.Incoming")}</TitleInput>
                            {_renderCheckBoxInComming()}
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>{t("library.Cover Image")}</TitleInput>
                            <input type='file' accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png" onChange={(event) => handleChangeFile(event, "image")} />
                            <img src={imageUrl || bookData.url_cover_img} alt='cover' />
                        </WrapperInput>
                        {postData.type === CONSTANTS.LIBRARY_TYPE.VIDEO && _renderVideoUploadContainer()}
                        {!postData.is_incoming && postData.type === CONSTANTS.LIBRARY_TYPE.BOOK && _renderDetailContent()}
                        {!postData.is_incoming && postData.type === CONSTANTS.LIBRARY_TYPE.BOOK && _renderAddNewChapter()}
                        {postData.type === CONSTANTS.LIBRARY_TYPE.PDF && _renderUploadPDF()}
                    </LibraryContainer>
                    {_renderCreateButton()}
                </BodyContainer>
            );
        }
        return <BodyContainer />;
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={isLoading}
            page="library"
            content={renderMain()}
        />
    );
};

export default EditBook;
