import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";
import _ from "lodash";
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  ProgressBar,
  WrapperInput,
  TitleInput,
  InputContainer,
  GeneralButtonLanguage,
} from "./Criteria.styles";
import {
  PageTitle,
  LabelSlide,
} from "../../../../../components/Common/CommonLayout";
import Loading from "../../../../../components/Layout/CommonLayoutPart/Loading";
import CriteriaActions from "../../../../../actions/Criteria";
import { ColorName } from "../../../../../components/Variables";
import Utils from "../../../../../libs/Utils";
import Validation from "../../../../../libs/Validation";
import history from "../../../../../history";
import Constants from "../../../../../constants/Constants";

const Edit = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const editor = useRef(null);
  const editorAl = useRef(null);
  const [contentEn, setContentEn] = useState("");
  const [contentAl, setContentAl] = useState("");
  const isLoading = useSelector((state) => state.Criteria.isLoading);
  const criteriaID = useSelector((state) => state.Criteria.criteriaID);
  const dataCriteria = useSelector((state) => state.Criteria.dataCriteria);
  const fileInput = useRef(null);
  const [formData, setFormData] = useState({
    name: {
      en: "",
      al: "",
    },
    content: {
      en: "",
      al: "",
    },
  });
  const [fileUrl, setFileUrl] = useState("");
  const [fileData, setFileData] = useState("");
  const [language, setLanguage] = useState(Utils.getCurrentLanguage());
  const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);

  useEffect(() => {
    if (!criteriaID) return history.push("/limited-admin/criteria");
    const data = {};
    dispatch(CriteriaActions.getCriteriaById(data, criteriaID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(dataCriteria)) {
      if (dataCriteria.is_support_mul_lang) {
        if (i18n.language === Constants.LANGUAGE_CODE.AL) {
          setLanguage(Constants.LANGUAGE_CODE.AL);
        } else {
          setLanguage(Constants.LANGUAGE_CODE.EN);
        }
      } else if (dataCriteria.content_json[i18n.language]) {
        setLanguage(i18n.language);
      } else if (i18n.language === Constants.LANGUAGE_CODE.EN) {
        setLanguage(Constants.LANGUAGE_CODE.AL);
      } else {
        setLanguage(Constants.LANGUAGE_CODE.EN);
      }

      const data = { ...formData };
      data.name.en = dataCriteria.name_json.en;
      data.name.al = dataCriteria.name_json.al;
      setContentEn(dataCriteria.content_json.en);
      setContentAl(dataCriteria.content_json.al);
      setFileUrl(dataCriteria.img_url_aws);
      setIsmultipleLanguage(dataCriteria.is_support_mul_lang);
      setFormData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCriteria]);

  const validateMutilpleLanguage = () => {
    const validationRules = [
      {
        name: `${t("general.Title")} ${t("general.English")}`,
        value: formData.name.en,
        rule: ["required", { min: 5, max: 100 }],
      },
      {
        name: `${t("general.Title")} ${t("general.Albania")}`,
        value: formData.name.al,
        rule: ["required", { min: 5, max: 100 }],
      },
      {
        name: `${t("general.Content")} ${t("general.English")}`,
        value: editor.current.value,
        rule: "required",
      },
      {
        name: `${t("general.Content")} ${t("general.Albania")}`,
        value: editorAl.current.value,
        rule: "required",
      },
    ];
    if (!Validation.formValidation(validationRules)) return;
    return true;
  };

  const validateForLanguage = (lang) => {
    const textEditor = lang === Constants.LANGUAGE_CODE.EN ? editor : editorAl;
    const validationRules = [
      {
        name: t("general.Title"),
        value: formData.name[lang],
        rule: ["required", { min: 5, max: 100 }],
      },
      {
        name: t("general.Content"),
        value: textEditor.current.value,
        rule: "required",
      },
    ];
    if (!Validation.formValidation(validationRules)) return;
    return true;
  };

  const paramPostSingleLanguage = (param) => {
    if (language === Constants.LANGUAGE_CODE.EN) {
      param.append("name", JSON.stringify({ en: formData.name.en }));
      param.append("content", JSON.stringify({ en: editor.current.value }));
    } else {
      param.append("name", JSON.stringify({ al: formData.name.al }));
      param.append("content", JSON.stringify({ al: editorAl.current.value }));
    }
  };

  const paramPostMultipleLanguage = (param) => {
    param.append(
      "name",
      JSON.stringify({ en: formData.name.en, al: formData.name.al })
    );
    param.append(
      "content",
      JSON.stringify({ en: editor.current.value, al: editorAl.current.value })
    );
  };

  const editCriteria = () => {
    let flag = true;
    if (isMultipleLanguage) {
      flag = validateMutilpleLanguage();
    } else if (!validateForLanguage(language)) return;
    if (flag) {
      const param = new FormData();
      if (isMultipleLanguage) paramPostMultipleLanguage(param);
      if (!isMultipleLanguage) paramPostSingleLanguage(param);
      param.append("is_support_mul_lang", isMultipleLanguage);
      if (fileData !== "") param.append("img", fileData);
      dispatch(CriteriaActions.updateCriteria(param, criteriaID));
    }
  };

  const handleFileChange = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    const file = e.target.files[0];
    setFileData(file);
    setFileUrl(url);
  };

  const handleChange = (value, nameInput) => {
    const data = { ...formData };
    data[nameInput][language] = value;
    setFormData(data);
  };

  const deleteFileAttachment = () => {
    setFileData("");
    setFileUrl("");
    fileInput.current.value = "";
  };

  return (
    <DefaultLayout
      userRole="limited-admin"
      isLoading={false}
      page="criteria"
      content={
        <BodyContainer>
          {isLoading && <Loading />}
          <PageTitle>{t("criteria.Edit Criteria")}</PageTitle>
          <ProgressBar className="mb-2">
            <WrapperInput>
              <TitleInput>
                {t("general.Title")}
                <div className="language">
                  <LabelSlide className="switch">
                    <input
                      type="checkbox"
                      checked={!!isMultipleLanguage}
                      onChange={() =>
                        setIsmultipleLanguage(!isMultipleLanguage)
                      }
                    />
                    <span className="slider round" />
                  </LabelSlide>
                  {isMultipleLanguage && (
                    <>
                      <GeneralButtonLanguage
                        type="button"
                        className={
                          language === Constants.LANGUAGE_CODE.EN
                            ? "active"
                            : ""
                        }
                        value={t("general.English")}
                        onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                      />
                      <GeneralButtonLanguage
                        type="button"
                        className={
                          language === Constants.LANGUAGE_CODE.AL
                            ? "active"
                            : ""
                        }
                        value={t("general.Albanian")}
                        onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                      />
                    </>
                  )}
                  {!isMultipleLanguage && (
                    <>
                      <GeneralButtonLanguage
                        type="button"
                        className={
                          language === Constants.LANGUAGE_CODE.EN
                            ? "active disable"
                            : "disable disable-unclick"
                        }
                        value={t("general.English")}
                        onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                        disabled
                      />
                      <GeneralButtonLanguage
                        type="button"
                        className={
                          language === Constants.LANGUAGE_CODE.AL
                            ? "active disable"
                            : "disable disable-unclick"
                        }
                        value={t("general.Albanian")}
                        onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                        disabled
                      />
                    </>
                  )}
                </div>
              </TitleInput>
              <InputContainer
                value={formData.name[language] || ""}
                onChange={(event) => handleChange(event.target.value, "name")}
                maxLength={50}
              />
            </WrapperInput>
            <WrapperInput className="content">
              <TitleInput>{t("general.Content")}</TitleInput>
              <div
                className={
                  language === Constants.LANGUAGE_CODE.EN
                    ? ""
                    : "hidden-content"
                }
              >
                <JoditEditor ref={editor} value={contentEn} />
              </div>
              <div
                className={
                  language === Constants.LANGUAGE_CODE.AL
                    ? ""
                    : "hidden-content"
                }
              >
                <JoditEditor ref={editorAl} value={contentAl} />
              </div>
            </WrapperInput>
            <WrapperInput className="container-upload">
              <input
                ref={fileInput}
                id="uploadAvatar"
                type="file"
                accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                onChange={(e) => handleFileChange(e)}
              />
              {t("news.Featured Image")}{" "}
              <i
                className="fas fa-upload"
                onClick={() => fileInput.current.click()}
              />
              {fileUrl && (
                <div className="display-image-file">
                  <img src={fileUrl} alt="Featured" />
                  <div
                    className="remove"
                    onClick={() => deleteFileAttachment()}
                  >
                    X
                  </div>
                </div>
              )}
            </WrapperInput>
            <div className="container-button">
              <GeneralButtonLanguage
                type="button"
                className="for-more"
                background={ColorName.chathamsBlue}
                value={t("general.Save")}
                onClick={() => editCriteria()}
              />
            </div>
          </ProgressBar>
        </BodyContainer>
      }
    />
  );
};

Edit.propTypes = {};

export default Edit;
