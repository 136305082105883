import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import {
    Container,
    GridColHomepage,
    FlexGridSixthTemplate,
    Button,
    Title
} from "./LimitedUser.styles.js";
import { GeneralInputForm } from "../../../components/Common/CommonLayout";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout/NoHeader";
// import SummryOverview from "../../../components/Common/SummaryOverview";
import { ColorName } from "../../../components/Variables/index.js";
import UserRegisterAction from "../../../actions/UserRegister";
import Validation from "../../../libs/Validation.js";

const LimitedUser = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.UserRegister.isLoading);
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: ''
    });

    const updateDataForLimitedUser = () => {
        const params = queryString.parse(props.location.search);
        const validationRules = [
            {
                name: t("general.Password"),
                value: userData.password,
                rule: ["required", { min: 6 }]
            },
            {
                name: t("general.Confirm Password"),
                value: userData.password_confirmation,
                rule: ["required", { min: 6 }]
            },
            {
                name: t("general.First Name"),
                value: userData.first_name,
                rule: 'required'
            },
            {
                name: t("general.Last Name"),
                value: userData.last_name,
                rule: 'required'
            },
            {
                name: t("general.Confirm Password"),
                value: userData.password_confirmation,
                rule: "equal",
                ruleValue: userData.password
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        const formData = new FormData();
        formData.append('user_data[first_name]', userData.first_name);
        formData.append('user_data[last_name]', userData.last_name);
        formData.append('email', params.e);
        formData.append('secret_code', params.sc);
        formData.append('password', userData.password);
        formData.append('password_confirmation', userData.password_confirmation);
        dispatch(UserRegisterAction.limitedUserRegister(formData));
    };

    const _renderContent = () => {
        return (
            <div className="content-page">
                <FlexGridSixthTemplate className="container-button">
                    <Title>{t("general.Please complete your information")}</Title>
                    <GeneralInputForm type='password' placeholder={t("general.Password")} onChange={(e) => setUserData({ ...userData, password: e.target.value })} />
                    <GeneralInputForm
                        type='password'
                        placeholder={t("join.Re-enter password")}
                        onChange={(e) => setUserData({ ...userData, password_confirmation: e.target.value })}
                    />
                    <GeneralInputForm type='text' placeholder={t("general.First Name")} onChange={(e) => setUserData({ ...userData, first_name: e.target.value })} />
                    <GeneralInputForm type='text' placeholder={t("general.Last Name")} onChange={(e) => setUserData({ ...userData, last_name: e.target.value })} />
                    <GridColHomepage colSize="6" gridTemplate="6" className="btn-save">
                        <Button
                            value={t("general.Save")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => updateDataForLimitedUser()}
                        />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
            </div>
        );
    };

    // const _renderSummaryOverview = () => {
    //     return <SummryOverview />;
    // };

    const _renderMain = () => {
        return (
            <Container>
                {_renderContent()}
                {/* {_renderSummaryOverview()} */}
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
            isLoading={isLoading}
        />
    );
};

LimitedUser.propTypes = {
};

export default LimitedUser;
