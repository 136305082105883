import _ from "lodash";
import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";
import i18n from "../i18n";
import Constants from "../constants/Constants";

const requestHasError = () => {
    return {
        type: ActionTypes.GUEST_TESTIMONIAL_SETTING_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.GUEST_TESTIMONIAL_SETTING_REQUEST
    };
};

const addTestimonialSuccess = (payload) => {
    return {
        type: ActionTypes.GUEST_TESTIMONIAL_SETTING_SUCCESS,
        payload
    };
};

const updateTestimonialSuccess = (payload) => {
    return {
        type: ActionTypes.GUEST_TESTIMONIAL_SETTING_SUCCESS,
        payload
    };
};

const getTestimonialByIDSuccess = (payload, isMultipleLanguage) => {
    return {
        type: ActionTypes.GET_TESTIMONIAL_BY_ID_SUCCESS,
        payload,
        isMultipleLanguage
    };
};

const fetchTestimonialsSuccess = (payload) => {
    return {
        type: ActionTypes.GUEST_TESTIMONIAL_SETTING_LIST_SUCCESS,
        payload
    };
};

const setTestimonialID = (payload) => {
    return {
        type: ActionTypes.SET_TESTIMONIAL_ID_SUCCESS,
        payload
    };
};

const addTestimonial = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addTestimonial(data)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    dispatch(addTestimonialSuccess(res.data.data));
                    history.push('testimonial-setting');
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateTestimonial = (data, id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateTestimonial(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    dispatch(updateTestimonialSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const fetchTestimonials = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.fetchTestimonials(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(fetchTestimonialsSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getTestimonialByID = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getTestimonialByID(id)
            .then((res) => {
                if (res.data.status === true) {
                    const isMultipleLanguage = _.get(res, `data.data.is_support_mul_lang`, null);
                    dispatch(getTestimonialByIDSuccess(res.data.data, isMultipleLanguage));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const deleteTestimonial = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.deleteTestimonial(id)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    const dataGet = {
                        page: 1,
                        item_per_page: Constants.ITEM_PER_PAGE,
                        language: i18n.language
                    };
                    dispatch(fetchTestimonials(dataGet));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    addTestimonial,
    getTestimonialByID,
    fetchTestimonials,
    setTestimonialID,
    updateTestimonial,
    deleteTestimonial
};
