import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    HeaderContainer,
    AvatarContainer,
    Avatar,
    AlertContainer,
    AvatarWrapper,
    FullWidth
} from "./Header.styles";
import SearchInput from "../../../Common/SearchInput";
import DropDownHeader from "../../../Common/DropDownHeader";
import NotificationDropDown from "../../../Common/NotificationDropDown";
import Utils from "../../../../libs/Utils";
import Constants from "../../../../constants/Constants";

const Header = () => {
    const userData = Utils.getSavedUserData();
    const [isShowDropDown, setIsShowDropDown] = useState(false);
    const showDropDown = () => {
        setIsShowDropDown(!isShowDropDown);
    };
    const isShowDropDownHeader = () => {
        showDropDown();
    };

    return (
        <FullWidth>
            <HeaderContainer>
                <div className="container-header">
                    <AvatarContainer>
                        <AvatarWrapper
                            onClick={() => showDropDown()}
                        >
                            <Avatar>
                                <img
                                    src={!_.isNull(userData)
                                        && !_.isUndefined(userData)
                                        && userData.userData.url_avatar_aws ? userData.userData.url_avatar_aws : "https://awodev.com/images/default-forum-user.png"}
                                    alt='avatar'
                                />
                            </Avatar>
                            <p>
                                {!_.isNull(userData) && !_.isUndefined(userData)
                                    && `${userData.userData.first_name} ${userData.userData.last_name}`
                                }
                            </p>
                            {isShowDropDown
                                && (
                                    <DropDownHeader isShowDropDownHeader={isShowDropDownHeader} />
                                )
                            }
                        </AvatarWrapper>
                    </AvatarContainer>
                    {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER
                        && (
                            <>

                                <SearchInput />
                                <AlertContainer>
                                    <NotificationDropDown />
                                </AlertContainer>
                            </>
                        )
                    }
                </div>
            </HeaderContainer>
        </FullWidth>
    );
};

Header.propTypes = {
    callBackHandleClickAvatar: PropTypes.func
};

export default Header;
