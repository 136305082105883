import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    process: {},
    processRegister: {}
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.PROCESS_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.PROCESS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.ADD_PROCESS_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                process: payload
            };
        case ActionTypes.GET_PROCESS_BY_TYPE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                process: payload
            };
        case ActionTypes.GET_PROCESS_BY_TYPE_REGISTER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                processRegister: payload
            };
        default:
            return state;
    }
};
