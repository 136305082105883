import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";

const requestIsLoading = () => {
    return {
        type: ActionTypes.INTRODUCE_REQUEST
    };
};

const requestHasError = () => {
    return {
        type: ActionTypes.INTRODUCE_FAILURE
    };
};

const getIntroduceByTypeSuccess = (payload) => {
    return {
        type: ActionTypes.GET_INTRODUCE_BY_TYPE_SUCCESS,
        payload
    };
};

const addIntroduceSuccess = (payload) => {
    return {
        type: ActionTypes.ADD_INTRODUCE_SUCCESS,
        payload
    };
};

const addIntroduce = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addMetaData(data)
            .then((res) => {
                dispatch(addIntroduceSuccess(res.data.data));
                Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
            }).catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getIntroduce = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getMetaData(data)
            .then((res) => {
                dispatch(getIntroduceByTypeSuccess(res.data.data));
            }).catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    addIntroduce,
    getIntroduce
};
