import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    dataSearchHompage: {},
    search: ''
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.SEARCH_HOMEPAGE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataSearchHompage: payload
            };
        case ActionTypes.SEARCH_HOMEPAGE_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.SEARCH_HOMEPAGE_REQUEST:
            return {
                ...state,
                hasError: false,
                isLoading: true
            };
        case ActionTypes.SET_SEARCH_HOMEPAGE_DEFAULT:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                dataSearchHompage: {}
            };
        case ActionTypes.SET_VALUE_SEARCH:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                search: payload
            };
        default:
            return state;
    }
};
