import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import _ from "lodash";
import {
  Container,
  GridColHomepage,
  Title,
  FlexGridSixthTemplate,
  Content,
} from "./News.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import SummryOverview from "../../../components/Common/SummaryOverview";
import NewsActions from "../../../actions/News";
import SlickCarouselNews from "../../../components/Common/SlickCarouselNews";
import history from "../../../history.js";
import Helmet from "react-helmet";
import { ReactSEOMetaTags } from "react-seo-meta-tags";

const NewsDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const params = queryString.parse(location.search);
  const isLoading = useSelector((state) => state.News.isLoading);
  const dataNews = useSelector((state) => state.News.dataNews);
  const listNewsSlide = useSelector((state) => state.News.newSlide);
  const [listNews, setListNews] = useState();
  const [siteMetadata, setSiteMetadata] = useState({});

  useEffect(() => {
    if (!params.id) return history.push("/homepage/news");
    const data = {
      language: i18n.language,
    };
    dispatch(NewsActions.getNewsById(data, params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!params.id) return history.push("/homepage/news");
    const data = {
      language: i18n.language,
      item: 8,
    };
    dispatch(NewsActions.getNewsSlide(data));
    dispatch(NewsActions.getNewsByIdForNewsPage(data, params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (_.isNull(dataNews)) {
    } else {
      const list = _.filter(listNewsSlide, (item) => {
        return item.id !== _.parseInt(params.id);
      });
      setListNews(list);

      setSiteMetadata({
        title: dataNews.title,
        description: dataNews.description,
        image: _.get(dataNews, "url_img_aws"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNews]);

  useEffect(() => {
    const list = _.filter(listNewsSlide, (item) => {
      return item.id !== _.parseInt(params.id);
    });
    setListNews(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listNewsSlide]);

  const _renderContent = () => {
    if (dataNews) {
      return (
        <div className="content-page">
          <FlexGridSixthTemplate>
            <GridColHomepage colSize="6" gridTemplate="6">
              <Title>{t("general.News")}</Title>
              <Content>
                <div className="title">{dataNews.title}</div>
                <div className="detail">
                  <span className="date-created">
                    {moment(dataNews.created_at).format("DD-MM-YYYY hh:mm")}
                  </span>
                  <br />
                  <span className="date-created">{dataNews.description}</span>
                </div>
                <div className="cover-image">
                  <img
                    src={_.get(dataNews, "url_img_aws", "")}
                    alt={dataNews.title}
                  />
                </div>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: dataNews.content }}
                />
                {dataNews.file_url && (
                  <div className="text">
                    {t("news.Attach File")}:
                    <a className="attach-file" href={dataNews.file_url_aws}>
                      {_.last(dataNews.file_url.split("/"))}
                    </a>
                  </div>
                )}
              </Content>
              <div className="line-page margin-line" />
              <div className="relate-post">
                &#8226; {t("general.Related Posts")}
              </div>
              <SlickCarouselNews listData={listNews} type="news" />
            </GridColHomepage>
          </FlexGridSixthTemplate>
        </div>
      );
    }
  };

  const _renderSummaryOverview = () => {
    return <SummryOverview />;
  };

  const _renderMain = () => {
    return (
      <Container>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{ ...siteMetadata }}
        />

        {_renderContent()}
        {_renderSummaryOverview()}
      </Container>
    );
  };

  return (
    <DefaultLayout
      context={_renderMain()}
      currentPage="news"
      isLoading={isLoading}
    />
  );
};

export default NewsDetail;
