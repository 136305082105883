import Api from "./Base";

export function userRegister(data) {
    return Api.postFormData("/users", data);
}

export function getUserRoles() {
    return Api.get("/roles");
}

export function limitedUserRegister(data) {
    return Api.postFormData("/update_limited_user", data);
}

export function generateLink(data) {
    return Api.post("/general_link_funding", data);
}
