import styled from 'styled-components';
import { ColorName } from "../../../components/Variables";
import { ContentBodyContainer } from '../../../components/Common/CommonLayout';

const FAQContainer = styled(ContentBodyContainer)`
    padding: 2em;
`;

const Title = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: 600;
    color: ${ColorName.chathamsBlueDark}
`;

const Content = styled.div`
    margin: 2em;
`;

export {
    FAQContainer,
    Title,
    Content
};
