import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import DefaultLayout from '../../../components/Layout/GuestTemplate/DefaultLayout';
import { GridCol } from '../../../components/Common/CommonLayout';
import {
    ContentCriteria,
    TitleCriteria,
    MainContent,
    FlexGridSixthTemplate
} from './Entrepreneurs.styles';
import NavigationBar from '../../../components/Common/NavigationBar';
import SummaryOverview from '../../../components/Common/SummaryOverview';
import Banner from '../../../components/Common/Banner';
import { Images } from '../../../themes';
import CriteriaActions from "../../../actions/Criteria";
import Constants from '../../../constants/Constants';
import Utils from '../../../libs/Utils';

const Criteria = () => {
    const listCriteria = useSelector((state) => state.Criteria.criteria);
    const criteriaSetting = useSelector((state) => state.Criteria.criteriaSetting);
    const isLoading = useSelector((state) => state.Criteria.isLoading);
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [language] = useState(Utils.getCurrentLanguage());
    const listNav = [
        {
            title: t("general.What We Do"),
            isActive: false,
            path: "/homepage/entrepreneurs/what-we-do"
        },
        {
            title: t("general.Community"),
            isActive: false,
            path: "/homepage/entrepreneurs/community"
        },
        {
            title: t("general.Criteria"),
            isActive: true,
            path: "/homepage/entrepreneurs/criteria"
        },
        {
            title: t("general.Process"),
            isActive: false,
            path: "/homepage/entrepreneurs/process"
        },
        {
            title: t("general.Apply"),
            isActive: false,
            path: "/homepage/entrepreneurs/apply"
        }
    ];
    const [title, setTitle] = useState("");

    useEffect(() => {
        const data = {
            page: 1,
            item_per_page: Constants.ITEM_PER_PAGE,
            language
        };
        const data_setting = {
            type: "criteria_setting"
        };
        dispatch(CriteriaActions.getCriteriaSettingData(data_setting));
        dispatch(CriteriaActions.getAllCriteria(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setMainTitle = () => {
        let stringTitle = "";
        if (language === "en") {
            stringTitle = criteriaSetting.data_json.data.en ? criteriaSetting.data_json.data.en : criteriaSetting.data_json.data.al;
        } else {
            stringTitle = criteriaSetting.data_json.data.al ? criteriaSetting.data_json.data.al : criteriaSetting.data_json.data.aen;
        }
        setTitle(stringTitle);
    };

    useEffect(() => {
        if (criteriaSetting && !_.isEmpty(criteriaSetting)) setMainTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteriaSetting]);

    const _renderItem = (item, index) => {
        return (
            <FlexGridSixthTemplate key={index}>
                <GridCol colSize='6' gridTemplate='6'>
                    <TitleCriteria>
                        {item.name_json[i18n.language]}
                    </TitleCriteria>
                    <ContentCriteria className="text" dangerouslySetInnerHTML={{ __html: item.content_json[i18n.language] }} />
                </GridCol>
            </FlexGridSixthTemplate>
        );
    };
    const _contentRender = () => {
        if (!_.isEmpty(listCriteria)) {
            return (
                <MainContent>
                    {_.map(listCriteria, (item, index) => {
                        return _renderItem(item, index);
                    })}
                </MainContent>
            );
        }
    };

    const _renderContentCriteria = () => {
        return (
            <MainContent />
        );
    };

    const _mainRender = () => {
        return (
            <div>
                <Banner
                    backgroundImg={Images.banner2}
                    title={t("homepageUser.Are you an Entrepreneur?")}
                    description={t("homepageUser.Access funds and knowledge from a rich and diverse pool of investors and consultants")}
                />
                <NavigationBar listNav={listNav} />
                <FlexGridSixthTemplate>
                    <MainContent>
                        <GridCol colSize='6' gridTemplate='6'>
                            <div className="text" dangerouslySetInnerHTML={{ __html: title }} />
                        </GridCol>
                    </MainContent>
                </FlexGridSixthTemplate>
                {_renderContentCriteria()}
                {_contentRender()}
                <SummaryOverview />
            </div>
        );
    };

    return (
        <DefaultLayout context={_mainRender()} currentPage="entrepreneur" currentTop={true} isLoading={isLoading} />
    );
};

export default Criteria;
