import React from "react";
import { Route } from "react-router-dom";

import Homepage from "../containers/GuestContainer/Homepage";
import About from "../containers/GuestContainer/About";
import Footer from "../components/Layout/GuestTemplate/Footer";

const TestComponentRoute = () => (
    <div>
        <Route exact path="/test/component-footer" component={Footer} />
        <Route exact path="/test" component={Homepage} />
        <Route exact path="/test/about" component={About} />
    </div>
);

export default TestComponentRoute;
