import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import JoditEditor from 'jodit-react';
import _ from 'lodash';
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput,
    InputContainer,
    SubInputTitle
} from "./TestimonialSetting.styles";
import {
    PageTitle,
    GeneralButton,
    FlexGridSixth,
    GridCol
} from "../../../../../components/Common/CommonLayout";
import {
    SwitchButton,
    SwitchLanguageButton,
    EnableMultipleLanguageButtonContainer
} from "../../../../../components/Common/EnableMultipleLanguageButton";
import Loading from "../../../../../components/Layout/CommonLayoutPart/Loading";
import GuestTestimonialSettingActions from "../../../../../actions/GuestTestimonialSetting";
import { ColorName } from "../../../../../components/Variables";
import Utils from "../../../../../libs/Utils";
import Validation from "../../../../../libs/Validation";
import Constants from "../../../../../constants/Constants";

const AddTestimonial = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const editor = useRef(null);
    const editorAl = useRef(null);
    const isLoading = useSelector((state) => state.GuestTestimonialSetting.isLoading);
    const [content, setContent] = useState({});
    const fileInput = useRef(null);
    const [personalName, setPersonalName] = useState({});
    const [title, setTitle] = useState({});
    const [fileUrl, setFileUrl] = useState("");
    const [fileData, setFileData] = useState("");
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());

    const addTestimonial = () => {
        const defaultValidation = {
            [Constants.LANGUAGE_CODE.EN]: [
                {
                    name: `${t("general.Title")} ${t("general.English")}`,
                    value: _.get(title, Constants.LANGUAGE_CODE.EN, ""),
                    rule: ["required", { min: 10, max: 200 }]
                },
                {
                    name: `${t("general.Content")} ${t("general.English")}`,
                    value: _.get(content, Constants.LANGUAGE_CODE.EN, ""),
                    rule: ["required", { min: 10, max: 350 }]
                },
                {
                    name: 'Avatar',
                    value: fileData,
                    rule: 'required'
                },
                {
                    name: `${t("testimonial.PERSONAL NAME")} ${t("general.English")}`,
                    value: _.get(personalName, Constants.LANGUAGE_CODE.EN, ""),
                    rule: 'required'
                }
            ],
            [Constants.LANGUAGE_CODE.AL]: [
                {
                    name: `${t("general.Title")} ${t("general.Albania")}`,
                    value: _.get(title, Constants.LANGUAGE_CODE.AL, ""),
                    rule: "required"
                },
                {
                    name: `${t("general.Content")} ${t("general.Albania")}`,
                    value: _.get(content, Constants.LANGUAGE_CODE.AL, ""),
                    rule: ["required", { min: 10, max: 350 }]
                },
                {
                    name: `${t("testimonial.PERSONAL NAME")} ${t("general.Albania")}`,
                    value: _.get(personalName, Constants.LANGUAGE_CODE.AL, ""),
                    rule: 'required'
                }
            ]
        };
        const validationRules = (isMultipleLanguage)
            ? [...defaultValidation[Constants.LANGUAGE_CODE.EN], ...defaultValidation[Constants.LANGUAGE_CODE.AL]]
            : [...defaultValidation[Constants.LANGUAGE_CODE.EN]];

        if (!Validation.formValidation(validationRules)) return;

        const param = new FormData();
        param.append('name', JSON.stringify(personalName));
        param.append('title', JSON.stringify(title));
        param.append('description', JSON.stringify(content));
        param.append('img', fileData);
        param.append("is_support_mul_lang", isMultipleLanguage);

        dispatch(GuestTestimonialSettingActions.addTestimonial(param));
    };

    const handleFileChange = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        const file = e.target.files[0];
        setFileData(file);
        setFileUrl(url);
    };

    const deleteFileAttachment = () => {
        setFileData("");
        setFileUrl("");
        fileInput.current.value = "";
    };

    const _renderSwitchLangugage = () => {
        return (
            <EnableMultipleLanguageButtonContainer>
                <SwitchButton className="switch">
                    <input type="checkbox" value={isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} />
                    <span className="slider round" />
                </SwitchButton>
                {isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                            />
                        </>
                    )}
                {!isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                disabled
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                disabled
                            />
                        </>
                    )}
            </EnableMultipleLanguageButtonContainer>
        );
    };

    return (
        <DefaultLayout
            userRole='limited-admin'
            isLoading={false}
            page="testimonial-setting"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("testimonial.Add Testimonial")}
                    </PageTitle>
                    <ProgressBar className="mb-2">
                        <FlexGridSixth>
                            <GridCol colSize='7' gridTemplate='7' className="flex-end">
                                {_renderSwitchLangugage()}
                            </GridCol>
                        </FlexGridSixth>
                        <WrapperInput>
                            <TitleInput>{t("testimonial.PERSONAL NAME")}</TitleInput>
                            <InputContainer
                                value={
                                    (language === Constants.LANGUAGE_CODE.EN)
                                        ? _.get(personalName, Constants.LANGUAGE_CODE.EN, '')
                                        : _.get(personalName, Constants.LANGUAGE_CODE.AL, '')
                                }
                                onChange={
                                    (language === Constants.LANGUAGE_CODE.EN)
                                        ? (event) => setPersonalName({ ...personalName, ...{ [Constants.LANGUAGE_CODE.EN]: event.target.value } })
                                        : (event) => setPersonalName({ ...personalName, ...{ [Constants.LANGUAGE_CODE.AL]: event.target.value } })
                                }
                            />
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>{t("general.Title")}</TitleInput>
                            <InputContainer
                                value={
                                    (language === Constants.LANGUAGE_CODE.EN)
                                        ? _.get(title, Constants.LANGUAGE_CODE.EN, '')
                                        : _.get(title, Constants.LANGUAGE_CODE.AL, '')
                                }
                                onChange={
                                    (language === Constants.LANGUAGE_CODE.EN)
                                        ? (event) => setTitle({ ...title, ...{ [Constants.LANGUAGE_CODE.EN]: event.target.value } })
                                        : (event) => setTitle({ ...title, ...{ [Constants.LANGUAGE_CODE.AL]: event.target.value } })
                                }
                            />
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>
                                {t("general.Description")}
                                <SubInputTitle>Required: Min 10, Max 350 Characters</SubInputTitle>
                            </TitleInput>
                            {(language === Constants.LANGUAGE_CODE.EN) && (
                                <JoditEditor
                                    ref={editor}
                                    value={_.get(content, Constants.LANGUAGE_CODE.EN, '')}
                                    onChange={(newContent) => setContent({ ...content, ...{ [Constants.LANGUAGE_CODE.EN]: newContent } })}
                                />
                            )}
                            {(language === Constants.LANGUAGE_CODE.AL) && (
                                <JoditEditor
                                    ref={editorAl}
                                    value={_.get(content, Constants.LANGUAGE_CODE.AL, '')}
                                    onChange={(newContent) => setContent({ ...content, ...{ [Constants.LANGUAGE_CODE.AL]: newContent } })}
                                />
                            )}
                        </WrapperInput>
                        <WrapperInput className="container-upload">
                            <input
                                ref={fileInput}
                                id='uploadAvatar'
                                type="file"
                                accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                                onChange={(e) => handleFileChange(e)}
                            />
                            Avatar:
                            {' '}
                            <i className="fas fa-upload" onClick={() => fileInput.current.click()} />
                            {fileUrl
                                && (
                                    <div className="display-image-file">
                                        <img src={fileUrl} alt='file'/>
                                        <div className="remove" onClick={() => deleteFileAttachment()}>X</div>
                                    </div>
                                )
                            }
                        </WrapperInput>
                        <div className="container-button">
                            <GeneralButton
                                type="button"
                                className="for-more"
                                background={ColorName.chathamsBlue}
                                value={t("general.Save")}
                                onClick={() => addTestimonial()}
                            />
                        </div>
                    </ProgressBar>
                </BodyContainer>
            )}
        />
    );
};

AddTestimonial.propTypes = {

};

export default AddTestimonial;
