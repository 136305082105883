import React, {
    useState,
    useEffect
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import DefaultLayout from '../../../components/Layout/GuestTemplate/DefaultLayout';
import Button from '../../../components/Common/ButtonFrontEnd';
import { ColorName } from '../../../components/Variables';
import {
    ApplyContent,
    ContainerButton,
    Container,
    ChildContainer,
    ApplyContentContainer,
    VideoContainer
} from './Consultants.styles';
import Banner from '../../../components/Common/Banner';
import { Images } from '../../../themes';
import NavigationBar from '../../../components/Common/NavigationBar';
import history from '../../../history';
import GuestApplySettingActions from "../../../actions/GuestApplySetting";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import Utils from "../../../libs/Utils";

const Apply = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector(
        (state) => state.GuestApplySetting.isLoading
    );
    const guestApplySetting = useSelector(
        (state) => state.GuestApplySetting.applyData
    );
    const [fileUrl, setFileUrl] = useState("");

    useEffect(() => {
        const data = {
            type: "guest_apply_consultant"
        };
        dispatch(GuestApplySettingActions.resetApplySettingData());
        dispatch(GuestApplySettingActions.getApplySettingData(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFileUrl("");
        if (guestApplySetting) {
            setFileUrl(guestApplySetting.data_media_json[0] || "");
        }
    }, [guestApplySetting]);

    const listNav = [
        {
            title: t("general.What We Do"),
            isActive: false,
            path: "/homepage/consultants/what-we-do"
        },
        {
            title: t("general.Community"),
            isActive: false,
            path: "/homepage/consultants/community"
        },
        {
            title: t("general.Process"),
            isActive: false,
            path: "/homepage/consultants/process"
        },
        {
            title: t("general.Apply"),
            isActive: true,
            path: "/homepage/consultants/apply"
        }
    ];

    const _renderMain = () => {
        return (
            <div>
                {isLoading && <Loading />}
                <Banner
                    backgroundImg={Images.banner1}
                    title={t("homepageUser.Are you a Consultant?")}
                    description={t("homepageUser.Consult and provide mentoring to a pool of rich and diverse startups in the region")}
                />
                <NavigationBar listNav={listNav} />
                <Container>
                    <ApplyContentContainer>
                        <ApplyContent>
                            <div className="text" dangerouslySetInnerHTML={{ __html: _.get(guestApplySetting, `data_json.${Utils.getCurrentLanguage()}`, "") }} />
                        </ApplyContent>
                        <br />
                        <br />
                        <ChildContainer>
                            <ApplyContent>
                                {t("homepageUser.If yes, please")}
                            </ApplyContent>
                            <ContainerButton>
                                <Button
                                    name={t("general.Join the community")}
                                    background={ColorName.chathamsBlueDark}
                                    color={ColorName.white}
                                    onClick={() => history.push("/homepage/register")}
                                />
                            </ContainerButton>
                        </ChildContainer>
                    </ApplyContentContainer>
                    {
                        fileUrl && (
                            <VideoContainer autoPlay={false} loop controls>
                                <source src={fileUrl} type="video/mp4" />
                                {t("general.Your browser does not support the video tag")}
                            </VideoContainer>
                        )
                    }
                </Container>
            </div>
        );
    };

    return (
        <DefaultLayout context={_renderMain()} currentPage="consultant" currentTop={true} />
    );
};

export default Apply;
