import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
    Container,
    GridColHomepage,
    Title,
    FlexGridSixthTemplate
} from "./Events.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import SummryOverview from "../../../components/Common/SummaryOverview";
import Pagination from "../../../components/Common/NewPagination";
import history from "../../../history.js";
import EventActions from "../../../actions/Event";
import Constants from "../../../constants/Constants.js";
const Events = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const listEvent = useSelector((state) => state.Event.events);
    const isLoading = useSelector((state) => state.Event.isLoading);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    useEffect(() => {
        const data = {
            page,
            item_per_page: Constants.ITEM_PER_PAGE,
            filter: Constants.EVENT_STATUS.PUBLISHED,
            language: i18n.language
        };
        dispatch(EventActions.getAllEvent(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatType = (type) => {
        if (type === 1) return "Online";
        return "Offline";
    };

    const viewEvent = (id) => {
        history.replace({
            search: `?id=${id}`,
            pathname: "/homepage/events/detail"
        });
    };

    const _renderItemEvent = (event, reverse) => {
        return (
            <FlexGridSixthTemplate
                className={reverse ? "flex-end" : "flex-start"}
                key={event.id}
                onClick={() => viewEvent(event.id)}
            >
                <GridColHomepage colSize="5" gridTemplate="6">
                    <div
                        className={
                            reverse
                                ? "container-hexagon"
                                : "container-hexagon reverse"
                        }
                        onClick={() => history.push("/homepage/events/detail")}
                    >
                        <div
                            className={reverse ? "hexagon" : "hexagon reverse"}
                        >
                            <div className="hexagon inner">
                                <div className="number-container">
                                    <div className="number">
                                        <span>
                                            {moment(event.date_event).format(
                                                "DD"
                                            )}
                                        </span>
                                        <span className="description">
                                            {`${moment(
                                                event.date_event
                                            ).format("MMM")}, ${moment(
                                                event.date_event
                                            ).format("YYYY")}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="shadow">
                                <span>10</span>
                            </div>
                            <div className="shadow bottom-shadow">
                                <span>10</span>
                            </div>
                            <div className="information">
                                <div className="content">
                                    <div className="text">
                                        {event.title.length > 80 ? `${event.title.substring(0, 80)}...` : event.title}
                                        <div className="description">
                                            {event.description.length > 100 ? `${event.description.substring(0, 100)}...` : event.description}
                                        </div>
                                        <div className="text-online">
                                            {formatType(event.type)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hexagon-image">
                                <div className="img-container">
                                    <div className="background-image">
                                        <img
                                            src={event.url_img_aws || "https://via.placeholder.com/245x120.png"}
                                            alt={event.title_json[i18n.language]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={reverse ? "outer reverse" : "outer"}
                            />
                            <div
                                className={
                                    reverse
                                        ? "outer bottom reverse"
                                        : "outer bottom"
                                }
                            />
                        </div>
                    </div>
                </GridColHomepage>
            </FlexGridSixthTemplate>
        );
    };

    useEffect(() => {
        setPage(listEvent.page);
        setLastPage(listEvent.lastPage);
    }, [listEvent]);

    const clickPageOrLink = (currentPage) => {
        const data = {
            page: currentPage,
            item_per_page: Constants.ITEM_PER_PAGE
        };
        dispatch(EventActions.getAllEvent(data));
    };

    const callBackPrev = () => {
        setPage(page - 1);
        clickPageOrLink(page - 1);
    };

    const callBackNext = () => {
        setPage(page + 1);
        clickPageOrLink(page + 1);
    };

    const pageOnclick = (e) => {
        clickPageOrLink(_.parseInt(e));
    };

    const _renderPagination = () => {
        return (
            <Pagination
                page={page}
                lastPage={lastPage || 1}
                callBackPrev={callBackPrev}
                callBackNext={callBackNext}
                pageOnclick={pageOnclick}
            />
        );
    };

    const _renderContent = () => {
        return (
            <div className="content-page">
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <Title>{t("general.Events")}</Title>
                        {_.isEmpty(listEvent.data) && (
                            <GridColHomepage colSize="6" gridTemplate="6" key="0">
                                {t("event.There is no Event for the moment, please comeback later")}
                            </GridColHomepage>
                        )}
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                {_.map(listEvent.data, (event, index) => {
                    if (index % 2 === 0) return _renderItemEvent(event, true);
                    return _renderItemEvent(event);
                })}
                {_renderPagination()}
            </div>
        );
    };

    const _renderSummaryOverview = () => {
        return <SummryOverview />;
    };

    const _renderMain = () => {
        return (
            <Container>
                {_renderContent()}
                {_renderSummaryOverview()}
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
            currentPage="events"
            isLoading={isLoading}
        />
    );
};

Events.propTypes = {};

export default Events;
