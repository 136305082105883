import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    WrapperInput,
    TitleInput,
    InputContainer,
    DropDownSelect
} from "./LimitedUser.styles";
import {
    PageTitle,
    GeneralButton
} from "../../../../components/Common/CommonLayout";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import AdminOverViewAction from '../../../../actions/AdminOverView';
import { ColorName } from "../../../../components/Variables";
import Validation from "../../../../libs/Validation";
import Constants from "../../../../constants/Constants";

const AddProcess = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const listFundingApplication = useSelector((state) => state.FundingApplication.allFundingData);
    const isLoading = useSelector((state) => state.AdminOverView.isLoading);
    const [email, setEmail] = useState("");
    const [fundingId, setFundingId] = useState("");

    useEffect(() => {
        const data = {
            category: Constants.FUNDING_CATEGORY.LIMITED_USER
        };
        dispatch(AdminOverViewAction.getListFundingApplicationLimited(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderOption = (item, index) => {
        return <option value={item.id} key={index}>{item.title}</option>;
    };

    const renderDropdownFundingApplication = () => {
        if (listFundingApplication) {
            const render = _.map(listFundingApplication, (item, index) => {
                return renderOption(item, index);
            });
            return render;
        }
    };

    const addLimitedUser = () => {
        const validationRules = [
            {
                name: t("general.Email"),
                value: email,
                rule: 'email'
            },
            {
                name: t("fundingApplications.funding opportunities"),
                value: fundingId,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        const data = {
            email,
            limited_funding: fundingId
        };
        dispatch(AdminOverViewAction.addLimitedUser(data));
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={false}
            page="limited-user"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("general.Add Limited User")}
                    </PageTitle>
                    <WrapperInput>
                        <TitleInput>{t("general.Email address")}</TitleInput>
                        <InputContainer
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("fundingApplications.funding opportunities")}</TitleInput>
                        <DropDownSelect onChange={(e) => setFundingId(e.target.value)}>
                            <option>
                                {t("fundingApplications.Please Choose Funding Opportunities")}
...
                            </option>
                            {renderDropdownFundingApplication()}
                        </DropDownSelect>
                    </WrapperInput>
                    <div className="container-button">
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("general.Save")}
                            onClick={() => addLimitedUser()}
                        />
                    </div>
                </BodyContainer>
            )}
        />
    );
};

export default AddProcess;
