import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";

const requestSuccess = (payload = false) => {
    return {
        type: ActionTypes.REPORT_APPLY_FUNDING_APPLICATION_SUCCESS,
        payload
    };
};

const requestHasError = () => {
    return {
        type: ActionTypes.REPORT_APPLY_FUNDING_APPLICATION_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.REPORT_APPLY_FUNDING_APPLICATION_REQUEST
    };
};

const setApplyFundingApplicationByID = (payload) => {
    return {
        type: ActionTypes.GET_REPORT_APPLY_FUNDING_APPLICATION_BY_ID,
        payload
    };
};

const getApplyApplicationById = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getApplyApplicationById(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setApplyFundingApplicationByID(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: e.data.message, type: "error" });
                dispatch(requestHasError());
            });
    };
};

const reportApplyApplication = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.reportApplyApplcation(data)
            .then((res) => {
                if (res.data.status === true) {
                    history.push('funding-applications');
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const getFundingApplicationReports = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getReportStatus(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: e.data.message, type: "error" });
            });
    };
};

export default {
    getApplyApplicationById,
    reportApplyApplication,
    getFundingApplicationReports
};
