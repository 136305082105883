import React from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import {
    DropDownActionButton,
    DropDown
} from "./DropDownAction.styles";

const DropDownAction = (props) => {
    const { t } = useTranslation();
    const chooseOption = (option) => {
        props.getActionName(option);
    };

    const { direction, listOptions } = props;

    const renderOption = (options) => {
        const rederOption = _.map(options, (option, key) => {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div key={key} onClick={() => chooseOption(option)}>{t(`rounderTable.${option}`)}</div>
            );
        });
        return rederOption;
    };

    return (
        <DropDownActionButton className={`fas ${direction === 'progressBar' ? 'fa-ellipsis-v' : 'fa-ellipsis-h'}`}>
            <DropDown className="hidden">
                {
                    // eslint-disable-next-line react/destructuring-assignment
                    renderOption(listOptions)
                }
            </DropDown>
        </DropDownActionButton>
    );
};

DropDownAction.propTypes = {
    listOptions: PropTypes.object,
    getActionName: PropTypes.func,
    direction: PropTypes.string
};

export default DropDownAction;
