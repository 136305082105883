import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer
} from "./FundingApplications.styles";
import {
  PageTitle,
  VerticalContainer
} from "../../../../components/Common/CommonLayout";
import NavigationBar from "../../../../components/Layout/PortalTemplate/NavigationBar";
import RoundedTable from "../../../../components/Common/RoundedTable";
import AdminOverViewAction from '../../../../actions/AdminOverView';
import CONSTANTS from "../../../../constants/Constants";
import history from "../../../../history";

const FundingApplications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.AdminOverView.isLoading);
  const listFundingApplication = useSelector((state) => state.FundingApplication.allFundingData);
  const activities = useSelector((state) => state.AdminOverView.allActivities);
  const columnsActivity = ['date', 'activity', "status", 'action'];
  const columnsApplication = ['applications status', 'name', "submitted", 'action'];
  const listOptionsFunding = {
    1: "view",
    2: "edit"
  };
  const listOptionsNotification = {
    1: 'view',
    2: 'delete'
  };
  const [showNumber, setShowNumber] = useState(10);
  const [dataFilter, setDataFilter] = useState([]);
  useEffect(() => {
    dispatch(AdminOverViewAction.getListFundingApplicationAdminWithLoading());
    setDataFilter(listFundingApplication);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataFilter(listFundingApplication);
  }, [listFundingApplication]);

  const filterData = (filter) => {
    const listFilter = [];
    _.map(listFundingApplication, (funding) => {
      if (filter === "open") {
        if (funding.status === CONSTANTS.FUNDING_APPLICATION_STATUS.OPEN) {
          listFilter.push(funding);
        }
      } else if (filter === "closed") {
        if (funding.status === CONSTANTS.FUNDING_APPLICATION_STATUS.CLOSED) {
          listFilter.push(funding);
        }
      } else if (filter === "announced") {
        if (funding.status === CONSTANTS.FUNDING_APPLICATION_STATUS.ANNOUNCED) {
          listFilter.push(funding);
        }
      } else {
        listFilter.push(funding);
      }
    });
    setDataFilter(listFilter);
  };

  const clickViewMoreFunding = () => {
    setShowNumber(showNumber + 10);
  };
  const clickViewMoreNotification = () => {
    history.push("/admin/notifications");
  };

  return (
    <DefaultLayout
      userRole='admin'
      isLoading={isLoading}
      page="fundingApplications"
      content={(
        <BodyContainer>
          <PageTitle>
            {t("fundingApplications.Applications Management")}
          </PageTitle>
          <VerticalContainer className="mb-2">
            <NavigationBar
              type='funding opportunities'
              selectedTab={filterData}
            />
            <RoundedTable
              initialData={dataFilter}
              columns={columnsApplication}
              hideTitleHeader
              tableType={CONSTANTS.TABLE_TYPE.APPLICATION}
              listOptions={listOptionsFunding}
              showNumberItems={showNumber}
              buttonTilte={t("general.View More")}
              handleClick={clickViewMoreFunding}
            />
            <RoundedTable
              initialData={activities.data}
              columns={columnsActivity}
              tableTitle={t("general.Recent Activity")}
              tableType={CONSTANTS.TABLE_TYPE.NOTIFICATION}
              className="mt-2"
              showNumberItems={6}
              buttonTilte={t("general.View More")}
              listOptions={listOptionsNotification}
              handleClick={clickViewMoreNotification}
            />
          </VerticalContainer>
        </BodyContainer>
      )}
    />
  );
};

FundingApplications.propTypes = {

};

export default FundingApplications;
