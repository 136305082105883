import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    WrapperModal,
    ModalContent,
    FieldTitle,
    WrapperInput,
    WrapperTitleContent,
    CheckBoxContainer
} from "./FundingApplications.styles";
import Button from "../../../../components/Common/Button";
import { ColorName } from "../../../../components/Variables";
import FundingApplicationActions from "../../../../actions/FundingApplication";

const PopupPreview = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [fieldData, setFieldData] = useState({
        title: "",
        value: '',
        fieldType: '1',
        maxCharacter: '',
        mandatory: true,
        isEdit: true
    });
    const [isError, setIsError] = useState(false);

    const hideModal = (event) => {
        if (event.target.classList.contains('wrapper')) {
            props.hideModal();
        }
    };

    const addNewField = () => {
        if (fieldData.title === "" || fieldData.maxCharacter === '') {
            setIsError(true);
        } else {
            dispatch(FundingApplicationActions.setNewFieldCategory(fieldData));
            props.hideModal();
            props.callBackAddNewField(fieldData);
        }
    };

    return (
        <WrapperModal onClick={(event) => hideModal(event)} className='wrapper'>
            <ModalContent>
                <WrapperTitleContent marginTop="4em">
                    <FieldTitle className="custom-title">{t("general.Title")}</FieldTitle>
                    <WrapperInput
                        marginBottom='0'
                        defaultValue={fieldData.title}
                        onChange={(event) => setFieldData({ ...fieldData, title: event.target.value })}
                    />
                </WrapperTitleContent>
                {fieldData.fieldType === '1' && (
                    <WrapperTitleContent>
                        <FieldTitle className="custom-title">{t("fundingApplications.Max Characters")}</FieldTitle>
                        <WrapperInput
                            type='number'
                            width="4em"
                            marginBottom='0'
                            defaultValue={fieldData.maxCharacter}
                            onChange={(event) => setFieldData({ ...fieldData, maxCharacter: event.target.value })}
                        />
                    </WrapperTitleContent>
                )}
                <WrapperTitleContent>
                    <FieldTitle className="custom-title">{t("fundingApplications.Mandatory")}</FieldTitle>
                    <div className="style-checkbox">
                        <CheckBoxContainer
                            type="radio"
                            width="auto"
                            checked={fieldData.mandatory === true}
                            onChange={() => setFieldData({ ...fieldData, mandatory: true })}
                        />
                        <div className="title-select">{t("general.Yes")}</div>
                    </div>
                    <div className="style-checkbox">
                        <CheckBoxContainer
                            type="radio"
                            width="auto"
                            checked={fieldData.mandatory === false}
                            onChange={() => setFieldData({ ...fieldData, mandatory: false })}
                        />
                        <div className="title-select">{t("general.No")}</div>
                    </div>
                </WrapperTitleContent>
                {isError
                    && (
                        <WrapperTitleContent>
                            <div>{t("general.Title or Max character should not be blank")}</div>
                        </WrapperTitleContent>
                    )
                }
                <WrapperTitleContent className="mt-2 just-center">
                    <Button
                        background={ColorName.gray}
                        title={t("general.Cancel")}
                        height="35px"
                        width="11em"
                        margin="0 1.5em 0 0"
                        onClick={() => props.hideModal()}
                    />
                    <Button
                        background={ColorName.fruitSaladLight}
                        title={t("general.Add")}
                        height="35px"
                        width="11em"
                        margin="0 1.5em 0 0"
                        onClick={() => addNewField()}
                    />
                </WrapperTitleContent>
            </ModalContent>
        </WrapperModal>
    );
};

PopupPreview.propTypes = {
    hideModal: PropTypes.func,
    callBackAddNewField: PropTypes.func
};

export default PopupPreview;
