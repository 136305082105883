import styled from 'styled-components';
import { HorizontalContainer } from "../../../Common/CommonLayout";
import { ColorName } from "../../../Variables";

const Wrapper = styled.div`
    width: 100%;
`;

const NavigationBarContainer = styled(HorizontalContainer)`
    padding: 15px 0px;
    &.forFundingApplication{
        flex-direction: column;
        padding: 0px;
    }
`;

const Title = styled.div`
    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
`;

const Tabs = styled(HorizontalContainer)`
    width: 65%;
    padding: 10px 0px;
    &.tabForFundingApplication{
        & div:first-child{
            margin: 10px 0px;
        }
        & div:first-child{
            margin: 0px;
        }
    }
    &.for-user-admin{
        width: 100%;
        flex-wrap: wrap;
        & div{
            margin-top: 0.5em;
        }
    }
`;

const Tab = styled.div`
    margin-left: ${(props) => props.marginLeft || "1em"};
    text-transform: uppercase;
    padding: 0.5em 1.5em;
    /* min-width: 100px; */
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1em;
    background: ${ColorName.botticelli};
    color: ${ColorName.chathamsBlue};
    border-radius: 25px;
    justify-content: center;
    white-space: nowrap;
    &:hover {
        background: ${ColorName.fern};
        color: white;
    }
    &.selected-tap {
        background: ${ColorName.fern};
        color: white;
    }
`;

const AddUserTab = styled(Tab)`
    background: ${ColorName.chathamsBlue};
    color: ${ColorName.white};
`;

export {
    Title,
    Wrapper,
    NavigationBarContainer,
    Tab,
    Tabs,
    AddUserTab
};
