import Api from "./Base";

export function addEvent(data) {
    return Api.postFormData("/event", data);
}

export function updateEvent(data, id) {
    return Api.postFormData(`/event/${id}/update`, data);
}

export function sendMailEvents(data) {
    return Api.postFormData('/event/sendMail', data);
}

export function deleteEvent(id) {
    return Api.post(`/event/${id}/delete`);
}

export function getAllEvent(data) {
    return Api.get("/event_fetch", data);
}

export function getEventById(id, data) {
    return Api.get(`/event/${id}`, data);
}

export function getEventSlide(param) {
    return Api.get("/event_get_testimonial", param);
}
