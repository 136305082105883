import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Content,
    Fields,
    Field,
    InputContainerNewLayout
} from "./Authentication.styles";
import Button from "../../../components/Common/ButtonFrontEnd";
import LoginActions from "../../../actions/Authentication";
import history from "../../../history";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import UserPageTemplateV1 from "../../../components/Layout/GuestTemplate/DefaultLayout";
import Banner from "../../../components/Common/Banner";
import { ColorName } from "../../../components/Variables";

const Authentication = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.Authentication.isLoading);
    const isLogged = useSelector((state) => state.Authentication.isLogged);

    useEffect(() => {
    }, [isLogged]);

    const inputEmail = (e) => {
        setEmail(e.target.value);
    };

    const inputPassword = (e) => {
        setPassword(e.target.value);
    };

    const login = () => {
        dispatch(LoginActions.login(email, password));
    };

    const onKeyEnterPress = (e) => {
        if (e.which === 13) {
            dispatch(LoginActions.login(email, password));
        }
    };

    const showPasswordHandle = () => {
        setShowPassword(!showPassword)
    }

    return (
        <UserPageTemplateV1 context={(
            <div>
                <div className="full-width">
                    <Banner type="signIn" />
                </div>
                <Content>
                    {isLoading && <Loading />}
                    <Fields>
                        <Field className="mt-input">
                            <InputContainerNewLayout>
                                <input type='text' placeholder={t("general.Enter your email")} onChange={inputEmail} onKeyPress={onKeyEnterPress} />
                            </InputContainerNewLayout>
                        </Field>
                        <Field className="mt-input">
                            <InputContainerNewLayout className='align-center'>
                                <input type={showPassword ? 'text' : 'password'} placeholder={t("general.Enter your passwords")} onChange={inputPassword} onKeyPress={onKeyEnterPress} />
                                <i className={`far ${showPassword ? 'fa-eye' : 'fa-eye-slash'} icon-eye`} onClick={() => showPasswordHandle()} />
                            </InputContainerNewLayout>
                        </Field>
                    </Fields>
                    <Fields className="mt-section">
                        <div className="line" />
                        <Field className="alignCenter">
                            <Button
                                onClick={() => login()}
                                name={t("general.Sign In")}
                                background={ColorName.bahamaBlue}
                                color={ColorName.white}
                            />
                        </Field>
                        <div className="forgot-pass" onClick={() => history.push('/forgot-password')}>
                            {t("general.Forgot password")}
                            ?
                        </div>
                        <div className="line" />
                        <Field className="alignCenter">
                            <Button
                                onClick={() => history.push('/homepage/register')}
                                name={t("general.Join")}
                                background={ColorName.bostonBlueDarker}
                                color={ColorName.white}
                                border="none"
                            />
                        </Field>
                    </Fields>
                </Content>
            </div>
        )}
        />
    );
};

export default Authentication;
