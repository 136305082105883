const MetaTypes = {
    GUEST_SUMMARY: 'guest_summary',
    GUEST_ABOUT_US: 'guest_about_us',
    GUEST_DISCLOSURE: 'guest_disclosure',
    GUEST_PRIVACY_POLICY: 'guest_privacy_policy',
    GUEST_TERM: 'guest_term',
    GUEST_APPLY: 'guest_apply',
    GUEST_DOCUMENT_TEMPLATE: "guest_document_template"
};

export default MetaTypes;
