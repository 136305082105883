import Api from "./Base";

export function addNewUser(data) {
    return Api.postFormData("/users", data);
}

export function confirmEmail(data) {
    return Api.post("/confirm_email", data);
}

export function getAllUsers(data) {
    return Api.get("/getUser", data);
}

export function getUser(data) {
    return Api.get("/getUser", data);
}

export function updateUser(data) {
    return Api.put("/updateUser", data);
}

export function changePassword(data) {
    return Api.post("/change_password", data);
}

export function changePasswordUser(data) {
    return Api.post("/users/update_password", data);
}
