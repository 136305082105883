import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import {
    SearchInputComponent,
    SearchInputContainer,
    SearchInputWrapper
} from "./SearchInput.styles";
import Utils from "../../../libs/Utils";
import SearchActions from "../../../actions/Search";
import history from "../../../history";
import Constants from "../../../constants/Constants";

const SearchInput = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userData = Utils.getSavedUserData();
    const [searchParam, setSearchParam] = useState({
        keyword: ''
    });
    const checkRole = () => {
        if (userData) {
            if (userData.role.name === Constants.ROLE_NAME.SUPER_ADMIN) return "admin";
            if (userData.role.name === Constants.ROLE_NAME.JUDGE) return "judge";
            if (userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN) return "limited-admin";
            return "user";
        }
    };
    const onKeyEnterPress = (e) => {
        if (e.which === 13) {
            if (e.target.value.trim() === "") return Utils.popupAlert({ title: "Oops...", text: t("validation.Please enter a key to search"), type: "error" });
            const data = {
                keyword: e.target.value
            };
            dispatch(SearchActions.search(data));
            const role = checkRole();
            if (role === "user") return history.push("/search");
            return history.push(`/${role}/search`);
        }
    };

    const search = () => {
        if (!searchParam.keyword) return Utils.popupAlert({ title: "Oops...", text: t("validation.Please enter a key to search"), type: "error" });
        dispatch(SearchActions.search(searchParam));
        const role = checkRole();
        if (role === "user") return history.push("/search");
        if (role === "user") return history.push("/search");
        return history.push(`/${role}/search`);
    };

    return (
        <SearchInputWrapper>
            <SearchInputContainer>
                <SearchInputComponent
                    placeholder={t("general.Quick search")}
                    onKeyPress={onKeyEnterPress}
                    onChange={(e) => setSearchParam({ ...searchParam, keyword: e.target.value })}
                />
                <i className="fas fa-search" onClick={() => search()} />
            </SearchInputContainer>
        </SearchInputWrapper>
    );
};

export default SearchInput;
