import styled from 'styled-components';
import { ColorName } from "../../Variables";

const GeneralDropdownFormContainer = styled.div`
    display: flex;
    border: 1px solid ${ColorName.chathamsBlueDark};
    margin-bottom: 0.5em;
    position: relative;
    select {
        flex: 2;
        border: none;
        padding: 5px;
        color: ${ColorName.chathamsBlueDark};
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        z-index: 3;
        background: none;
    }
`;

const DropDownIcon = styled.div`
    background: ${ColorName.chathamsBlueDark};
    padding: 0 5px;
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: 1;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    i {
        border: none;
        color: white;
    }
`;

export {
    GeneralDropdownFormContainer,
    DropDownIcon
};
