import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import {
    Wrapper,
    ProgressBar,
    DetailTitle,
    Column,
    FieldTitle
} from "./Judge.styles";
import {
    PageTitle
} from "../../../components/Common/CommonLayout";
import CONSTANTS from "../../../constants/Constants";
import RoundedTable from "../../../components/Common/RoundedTable";
import JudgeActions from '../../../actions/Judge';
import Button from "../../../components/Common/ButtonFrontEnd";
import { ColorName } from "../../../components/Variables";
import history from "../../../history";

const ApplyApplicationDetails = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.Judge.isLoading);
    const applyApplication = useSelector((state) => state.Judge.applyApplication);
    const [details, setDetails] = useState([]);
    const columnsApplication = ['applications status', 'Applicant name', 'Date submitted', 'action'];
    const [initData, setInitData] = useState({});
    const listOptions = {
        1: 'view'
    };
    const [showNumber, setShowNumber] = useState(10);

    useEffect(() => {
        dispatch(JudgeActions.getApplicationsJudgeById(location.state.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(applyApplication)) {
            const applyData = [...applyApplication.fundingApplication.applyApplications];
            const newInitData = [];
            _.map(applyData, (data) => {
                const newData = { ...data };
                newData.judgeApplicationID = applyApplication.id;
                newInitData.push(newData);
            });
            setDetails(applyApplication);
            setInitData(newInitData);
        }
    }, [applyApplication]);

    const clickViewMoreFunding = () => {
        setShowNumber(showNumber + 10);
    };

    const checkColorStatusCell = (status) => {
        if (CONSTANTS.COLOR_STATUS.GRADIENT_VALUE.includes(status)) {
            return 'pink';
        } else if (CONSTANTS.COLOR_STATUS.GREEN_VALUE.includes(status)) {
            return 'green';
        } else if (CONSTANTS.COLOR_STATUS.RED_VALUE.includes(status)) {
            return 'red';
        }
    };

    return (
        <DefaultLayout
            userRole='judge'
            isLoading={isLoading}
            page="applications"
            content={(
                <Wrapper>
                    <PageTitle>
                        {t("fundingApplications.Funding Application Details")}
                    </PageTitle>
                    <ProgressBar className="mb-2">
                        <Column>
                            <DetailTitle>
                                {t("fundingApplications.Funding Opportunity’s name")}
                                : &nbsp;
                                <span className="color-title">{!_.isUndefined(details.fundingApplication) ? details.fundingApplication.title : ""}</span>
                            </DetailTitle>

                        </Column>
                        <Column>
                            <DetailTitle>
                                {t("general.Status")}
                                :
                            </DetailTitle>
                            <span className={!_.isUndefined(details.fundingApplication) ? checkColorStatusCell(details.fundingApplication.status) : ""}>
                                {!_.isUndefined(details.fundingApplication)
                                    ? t(`rounderTable.${_.findKey(CONSTANTS.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, details.fundingApplication.status))}`) : ""
                                }
                            </span>
                        </Column>
                    </ProgressBar>
                    <PageTitle className='title'>
                        {t("fundingApplications.Submitted Applications")}
                    </PageTitle>
                    <RoundedTable
                        initialData={!_.isUndefined(details.fundingApplication) ? initData : []}
                        columns={columnsApplication}
                        hideTitleHeader
                        tableType={CONSTANTS.TABLE_TYPE.APPLY_APPLICATION}
                        listOptions={listOptions}
                        showNumberItems={showNumber}
                        buttonTilte={t("general.View More")}
                        handleClick={clickViewMoreFunding}
                    />
                    <FieldTitle className="button-export">
                        <Button
                            color={ColorName.white}
                            background={ColorName.chathamsBlueDark}
                            name={t("fundingApplications.Export Submitted Applications")}
                            height="40px"
                            width="auto"
                            padding="0 1em"
                            onClick={() => history.replace({
                                state: { id: details.id },
                                pathname: "/judge/export-submitted"
                            })}
                        />
                    </FieldTitle>
                </Wrapper>
            )}
        />
    );
};

export default ApplyApplicationDetails;
