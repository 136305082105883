import styled from 'styled-components';
import {
    GeneralButton, ContentBodyContainer, GridCol, FlexGridSixth
} from '../../../components/Common/CommonLayout';
import { ColorName } from "../../../components/Variables";

const Container = styled(ContentBodyContainer)`
    & .content-page{
        margin: 3em 0;
        min-height: 50vh;
    }
    padding: 0 4em;
    & .invite-item{
        margin: 2em 0;
    }
    color: ${ColorName.chathamsBlueDark};
    @media (max-width: 768px) {
        padding: 0;
    }
`;

const Button = styled(GeneralButton)`
    margin-right: 1em;
    border: 1px solid ${ColorName.chathamsBlueDark};
    margin-top: 1em;
`;

const FlexGridSixthTemplate = styled(FlexGridSixth)`
    &.container-button {
        margin-top: 2em;
    }
    margin: 0 1em;
    & .border-top{
        border-top: 1px solid ${ColorName.chathamsBlueDark};
    }
    & .order-2{
        order: 2;
        padding-left: 2em;
    }
    & .order-1{
        padding-right: 2em;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        & .order-2{
            padding: 0;
        }
        & .order-1{
            padding: 0;
        }
        & .reverse{
            order: 2;
        }
    }
`;
const GridColHomepage = styled(GridCol)`
    & .description{
        margin-top: 2em;
    }
    & ul{
        list-style: none;
        padding-inline-start: 0;
        & li{
            display: flex;
            flex-direction: column;
            padding-bottom: 1em;
        }
        & .title{
            color: ${ColorName.fern};
            font-size: 1.5em;
            font-weight: 600;
        }
        & .description{
            color: ${ColorName.chathamsBlueDark};
            font-size: 0.8em;
        }
    }
    & .image-invite{
        width: 100%;
        border: 1px solid ${ColorName.chathamsBlueDark};
        // border-top: none;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Title = styled.div`
    font-size: 2.5em;
    font-weight: 600;
    text-align: left;
    & .content{
        font-weight: 200;
    }
`;

export {
    Container,
    GridColHomepage,
    Title,
    FlexGridSixthTemplate,
    Button
};
