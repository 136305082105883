import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    criteria: [],
    dataCriteria: {},
    criteriaID: 0,
    criteriaSetting: {}
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.ADD_CRITERIA_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataCriteria: payload
            };
        case ActionTypes.CRITERIA_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.CRITERIA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.GET_CRITERIA_BY_ID_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataCriteria: payload
            };
        case ActionTypes.GET_ALL_CRITERIA_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                criteria: payload
            };
        case ActionTypes.SET_CRITERIA_ID_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                criteriaID: payload,
                dataCriteria: {}
            };
        case ActionTypes.UPDATE_CRITERIA_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                dataCriteria: payload
            };
        case ActionTypes.GET_CRITERIA_SETTING_SUCCESS:
            return {
                ...state,
                criteriaSetting: action.payload,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        default:
            return state;
    }
};
