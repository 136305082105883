import Api from "./Base";

export function getApplicationsJudge() {
    return Api.get("/application_judges");
}

export function getApplicationsJudgeById(id) {
    return Api.get(`/application_judges/${id}`);
}

export function getApplyApplicationById(id) {
    return Api.get(`/apply_application/${id}`);
}

export function changeStatusApplyApplication(data, id) {
    return Api.post(`/apply_application/${id}/update`, data);
}

export function getEvaluationQuestionnaire() {
    return Api.get("/evaluation_questionnaire");
}

export function saveEvaluation(data) {
    return Api.postFormData("/evaluation_apply", data);
}

export function getEvaluationById(id) {
    return Api.get(`/evaluation_apply/${id}`);
}

export function updateEvaluation(data, id) {
    return Api.postFormData(`/evaluation_apply/${id}/update`, data);
}
