import styled from 'styled-components';
import { ColorName } from "../../Variables";

const OptionDropDown = styled.div`
    border: 1px solid gray;
    border-bottom: 0px;
    padding: 0.5em 1em;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;
    color: ${ColorName.chathamsBlueDark};
    &:last-child{
        border-bottom: 1px solid gray;
    }
    &:hover{
        background: ${ColorName.lightcyan}
    }
    background: white;
`;

const DropDownUserHeader = styled.div`
    position: absolute;
    top: 50px;
`;

export {
    OptionDropDown,
    DropDownUserHeader
};
