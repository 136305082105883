import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import {
    OptionDropDown,
    DropDownUserHeader,
    DropDownContainer,
    Arrow
} from "./NotificationDropDown.styles";
import useOutsideClick from "./ClickOutside";
import AdminOverViewAction from '../../../actions/AdminOverView';
import Constants from "../../../constants/Constants";
import Utils from "../../../libs/Utils";
import NotificationActions from '../../../actions/Notifications';
import FundingApplicationActions from '../../../actions/FundingApplication';
import NewsActions from '../../../actions/News';
import EventActions from '../../../actions/Event';
import JudgeActions from '../../../actions/Judge';
import AdminReportActions from "../../../actions/AdminFundingApplicationReport";
import history from "../../../history";
import FundingApplicationUserActions from "../../../actions/FundingApplicationsUser";
import MessageActions from "../../../actions/Message";

const NotificationDropDown = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const ref = useRef();
    const [isShowNotification, setIsShowNotification] = useState(false);
    const [countNotification, setCountNotification] = useState(0);
    const [dataNotification, setDataNotification] = useState(false);
    const userData = Utils.getSavedUserData();
    const activities = useSelector((state) => state.AdminOverView.allActivities);

    const fecthDataNotification = async () => {
        let data = {};
        if (userData.role.name === Constants.ROLE_NAME.ADMIN) {
            data = {
                type: Constants.NOTIFICATION.NOTIFICATION_TYPE.RECENT_ACTIVITY,
                language: i18n.language,
                page: 1,
                item_per_page: 20
            };
            // await dispatch(AdminOverViewAction.getAllActivitiesOverview(data))
        } else {
            data = {
                type: Constants.NOTIFICATION.NOTIFICATION_TYPE.NOTIFICATION,
                language: i18n.language,
                page: 1,
                item_per_page: 20
            };
            // await dispatch(AdminOverViewAction.getActivitiesOverview(data))
        }
        if (userData)
            await dispatch(AdminOverViewAction.getAllActivitiesOverview(data))
    };

    useEffect(() => {
        if (userData) fecthDataNotification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDataNotification(activities.data);
        setCountNotification(_.parseInt(activities.countUnread) || 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activities]);

    const toggle = () => {
        setIsShowNotification(false);
    };

    useOutsideClick(ref, () => {
        toggle();
    });

    const viewNotification = async (rowData) => {
        await dispatch(NotificationActions.updateNotification(rowData.id));
        await fecthDataNotification();
        if (userData.role.name === Constants.ROLE_NAME.SUPER_ADMIN) {
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.REPORT) {
                const param = {
                    applyApplicationId: rowData.data_id
                };
                await dispatch(AdminReportActions.getApplyApplicationById(rowData.data_id));
                await dispatch(AdminReportActions.getFundingApplicationReports(Utils.convertCamelKeyToSnake(param)));
                history.push({
                    state: { id: rowData.data_id },
                    pathname: "/admin/report"
                });
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.USER) {
                await dispatch(AdminOverViewAction.setUserId(rowData.data_id));
                history.push({
                    state: { id: rowData.data_id },
                    pathname: "/admin/user-details"
                });
            } else if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.FUNDING_APPLICATION) {
                await dispatch(FundingApplicationActions.setActionForEditOrViewFunding("view", rowData.data_id));
                history.push({
                    state: { id: rowData.data_id },
                    pathname: "/admin/funding-application-details"
                });
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.APPLY_APPLICATION) {
                await dispatch(AdminOverViewAction.setApplyApplicationId(rowData.data_id));
                history.push({
                    state: { id: rowData.data_id },
                    pathname: "/admin/applications/details"
                });
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.EVALUATION) {
                await dispatch(AdminOverViewAction.setApplyApplicationId(rowData.data_id));
                await dispatch(JudgeActions.getApplyApplicationById(rowData.data_id));
                history.push({
                    state: { id: rowData.data_id },
                    pathname: "/admin/evaluation-apply"
                });
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.NEWS) {
                history.push({
                    state: { id: rowData.data_id },
                    search: `?id=${rowData.data_id}`,
                    pathname: "/homepage/news/detail"
                });
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.EVENT) {
                history.push({
                    state: { id: rowData.data_id },
                    search: `?id=${rowData.data_id}`,
                    pathname: "/homepage/events/detail"
                });
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.LIBRARY) {
                history.push({
                    state: { id: rowData.data_id },
                    search: `?id=${rowData.data_id}`,
                    pathname: "/homepage/library/book-detail"
                });
            }
        }
        if (userData.role.name !== Constants.ROLE_NAME.SUPER_ADMIN && userData.role.name !== Constants.ROLE_NAME.JUDGE) {
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.REPORT) {
                const param = {
                    applyApplicationId: rowData.data_id
                };
                dispatch(FundingApplicationUserActions.getReportStatusRequest(Utils.convertCamelKeyToSnake(param)));
                history.push("user-funding-report");
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.APPLY_APPLICATION && rowData.is_see_data) {
                dispatch(FundingApplicationUserActions.getApplyApplicationByIDRequest(rowData.data_id));
                history.push("user-funding-detail");
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.USER) {
                if (userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN) {
                    history.push({
                        state: { id: rowData.id },
                        pathname: "/limited-admin/update"
                    });
                } else {
                    history.push({
                        state: { id: rowData.id },
                        pathname: "/add-view-user"
                    });
                }
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.MESSAGE) {
                await dispatch(MessageActions.getBasicInfomation(rowData.data_id));
                history.push("messages");
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.NEWS) {
                await dispatch(NewsActions.setNewsId(rowData.data_id));
                history.push({
                    state: { id: rowData.data_id },
                    search: `?id=${rowData.data_id}`,
                    pathname: "/homepage/news/detail"
                });
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.EVENT) {
                await dispatch(EventActions.setEventId(rowData.data_id));
                history.push({
                    state: { id: rowData.data_id },
                    search: `?id=${rowData.data_id}`,
                    pathname: "/homepage/events/detail"
                });
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.LIBRARY) {
                history.push({
                    state: { id: rowData.data_id },
                    search: `?id=${rowData.data_id}`,
                    pathname: "/homepage/library/book-detail"
                });
            }
        }
        if (userData.role.name === Constants.ROLE_NAME.JUDGE) {
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.USER) {
                history.push("settings");
            }
            if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.APPLICATION_JUDGE) {
                if (rowData.is_see_data) {
                    history.push({
                        state: { id: rowData.data_id },
                        pathname: "/judge/applications/list-applies"
                    });
                }
            }
        }
        if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.NEWS) {
            history.push({
                state: { id: rowData.data_id },
                search: `?id=${rowData.data_id}`,
                pathname: "/homepage/news/detail"
            });
        }
        if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.EVENT) {
            history.push({
                state: { id: rowData.data_id },
                search: `?id=${rowData.data_id}`,
                pathname: "/homepage/events/detail"
            });
        }
        if (rowData.table_type === Constants.NOTIFICATION.TABLE_TYPE.LIBRARY) {
            history.push({
                state: { id: rowData.data_id },
                search: `?id=${rowData.data_id}`,
                pathname: "/homepage/library/book-detail"
            });
        }
    };

    const isShowNotificationContent = () => {
        setIsShowNotification(!isShowNotification);
    };

    const renderNotification = () => {
        const render = _.map(dataNotification, (notification, index) => {
                return (
                    <OptionDropDown
                        className={notification.is_read ? "old" : "new"}
                        key={index}
                        onClick={() => viewNotification(notification)}
                    >
                        <span title={notification.content.length < 80 ? "" : notification.content}>{notification.content.length < 80 ? notification.content : `${notification.content.substr(0, 80)}...`}</span>
                    </OptionDropDown>
                );
        });
        return render;
    };

    const checkRole = () => {
        if (userData) {
            if (userData.role.name === Constants.ROLE_NAME.SUPER_ADMIN) return "admin";
            if (userData.role.name === Constants.ROLE_NAME.JUDGE) return "judge";
            if (userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN) return "limited-admin";
            return "user";
        }
    };

    const viewMoreNotification = () => {
        const role = checkRole();
        if (role === "user") return history.push("/notifications");
        return history.push(`/${role}/notifications`);
    };

    return (
        <DropDownContainer onClick={() => isShowNotificationContent()} ref={ref}>
            <i className="far fa-bell fa-xl" />
            <span
                className="badge"
            >
                {countNotification > 20 && "20+"}
                {countNotification <= 20 && countNotification}
            </span>

            <DropDownUserHeader className={isShowNotification ? "" : "hidden"}>
                <Arrow />
                <OptionDropDown className="titleNotification">
                    <span>{t("general.Notification")}</span>
                </OptionDropDown>
                {renderNotification()}
                <OptionDropDown onClick={() => viewMoreNotification()}>
                    <span>
                        {t("general.View More")}
                        ...
                    </span>
                </OptionDropDown>
            </DropDownUserHeader>
        </DropDownContainer>
    );
};

NotificationDropDown.propTypes = {
    isShowDropDownNotification: PropTypes.func
};

export default NotificationDropDown;
