import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
import {
    NavigationBarComponent,
    NavContent,
    NavItem,
    ButtonJoin,
    ContentBody
} from "./NavigationBar.styles";
import history from "../../../history";
import IconDiamond from "../IconDiamond";
import Button from "../ButtonFrontEnd";
import { ColorName } from "../../Variables";

const NavigationBar = (props) => {
    const { t } = useTranslation();
    const { listNav } = props;
    return (
        <NavigationBarComponent className="full-width" id="main-content" name="main-content">
            <ContentBody>
                <NavContent>
                    {_.map(listNav, (nav, key) => {
                        return (
                            <div className="d-flex" key={key}>
                                <NavItem
                                    className={nav.isActive ? "active" : ""}
                                    onClick={() => { history.push(nav.path); }}
                                >
                                    {nav.title}
                                </NavItem>
                                {(listNav.length - 1) !== key
                                    && <IconDiamond />
                                }
                            </div>
                        );
                    })}
                </NavContent>
                <ButtonJoin>
                    <Button
                        name={t("general.Join the community")}
                        background={ColorName.chathamsBlueDark}
                        color={ColorName.white}
                        onClick={() => history.push("/homepage/register")}
                    />
                </ButtonJoin>
            </ContentBody>
        </NavigationBarComponent>
    );
};

NavigationBar.propTypes = {
    listNav: propTypes.array
};

export default NavigationBar;
