import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Container,
    GridColHomepage,
    FlexGridSixthTemplate,
    Button,
    Title
} from "./LimitedUser.styles.js";
import { Fields } from "./Authentication.styles";
import { GeneralInputForm } from "../../../components/Common/CommonLayout";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout/NoHeader";
import { ColorName } from "../../../components/Variables/index.js";
import AdminOverView from "../../../actions/AdminOverView";
import LoginActions from "../../../actions/Authentication";
import Utils from "../../../libs/Utils.js";
import history from "../../../history.js";

const RegisterLimitedUser = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.AdminOverView.isLoading);
    const isLoadingLogin = useSelector((state) => state.Authentication.isLoading);
    const [email, setEmail] = useState();
    const [emailAdd, setEmailAdd] = useState();
    const [password, setPassword] = useState('');

    const registerLimitedUser = () => {
        // const limited_funding = props.location.search.replace("?id=", "");
        if (!emailAdd) return Utils.popupAlert({ title: "Oops...", text: t('alert.Found empty field(s)'), type: "error" });
        const data = {
            email: emailAdd
        };
        dispatch(AdminOverView.addLimitedUser(data));
    };

    const login = () => {
        dispatch(LoginActions.login(email, password));
    };

    const _renderContent = () => {
        return (
            <div className="content-page">
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="3" gridTemplate="6">
                        <Title>{t("general.To create an account please add your email address")}</Title>
                        <GeneralInputForm placeholder={t("general.Email")} onChange={(e) => setEmailAdd(e.target.value)} />
                        <Button
                            value={t("general.Add")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => registerLimitedUser()}
                            className="for-limited"
                        />
                    </GridColHomepage>
                    <GridColHomepage colSize="3" gridTemplate="6">
                        <Title>{t("general.Sign In")}</Title>
                        <GeneralInputForm placeholder={t("general.Email")} onChange={(e) => setEmail(e.target.value)} />
                        <GeneralInputForm type="password" placeholder={t("general.Password")} onChange={(e) => setPassword(e.target.value)} />
                        <Button
                            value={t("general.Sign In")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => login()}
                            className="for-limited"
                        />
                        <Fields>
                            <div className="forgot-pass" onClick={() => history.push('/homepage/forgot-password')}>
                                {t("general.Forgot password")}
?
                            </div>
                            <div className="line" />
                        </Fields>
                    </GridColHomepage>
                </FlexGridSixthTemplate>
            </div>
        );
    };

    const _renderMain = () => {
        return (
            <Container>
                {_renderContent()}
                {/* {_renderSummaryOverview()} */}
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
            isLoading={isLoading || isLoadingLogin}
        />
    );
};

RegisterLimitedUser.propTypes = {
};

export default RegisterLimitedUser;
