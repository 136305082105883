import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";

const requestIsLoading = () => {
    return {
        type: ActionTypes.PROCESS_REQUEST
    };
};

const requestHasError = () => {
    return {
        type: ActionTypes.PROCESS_FAILURE
    };
};

const getProcessByTypeSuccess = (payload) => {
    return {
        type: ActionTypes.GET_PROCESS_BY_TYPE_SUCCESS,
        payload
    };
};

const getProcessByTypeRegisterSuccess = (payload) => {
    return {
        type: ActionTypes.GET_PROCESS_BY_TYPE_REGISTER_SUCCESS,
        payload
    };
};

const addProcessSuccess = (payload) => {
    return {
        type: ActionTypes.ADD_PROCESS_SUCCESS,
        payload
    };
};

const addProcess = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addMetaData(data)
            .then((res) => {
                dispatch(addProcessSuccess(res.data.data));
                Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
            }).catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getProcess = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getMetaData(data)
            .then((res) => {
                dispatch(getProcessByTypeSuccess(res.data.data));
            }).catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getProcessRegister = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getMetaData(data)
            .then((res) => {
                dispatch(getProcessByTypeRegisterSuccess(res.data.data));
            }).catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    addProcess,
    getProcess,
    getProcessRegister
};
