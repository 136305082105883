import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import SearchBar from "../../../components/Common/SearchBar";
import DefaultLayout from '../../../components/Layout/GuestTemplate/DefaultLayout';
import {
    TitleCommunity,
    Result,
    MainContent,
    ContainerListCard,
    Sponsor,
    ItemSponsor,
    ContainerFullWidthCommunity
} from './Consultants.styles';
import CompanyCard from '../../../components/Common/CompanyCard';
import Banner from '../../../components/Common/Banner';
import { Images } from '../../../themes';
import NavigationBar from '../../../components/Common/NavigationBar';
import SummaryOverview from '../../../components/Common/SummaryOverview';
import CONSTANTS from "../../../constants/Constants";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import CommunityActions from "../../../actions/Community";
import SponsorActions from "../../../actions/Sponsor";

const Community = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const refMain = useRef();
    const isLoading = useSelector((state) => state.Community.isLoading);
    const communityData = useSelector((state) => state.Community.communityData);
    const listSponsor = useSelector((state) => state.Sponsor.sponsors);
    // const [scroll, setScroll] = useState(false);
    const listNav = [
        {
            title: t("general.What We Do"),
            isActive: false,
            path: "/homepage/consultants/what-we-do"
        },
        {
            title: t("general.Community"),
            isActive: true,
            path: "/homepage/consultants/community"
        },
        {
            title: t("general.Process"),
            isActive: false,
            path: "/homepage/consultants/process"
        },
        {
            title: t("general.Apply"),
            isActive: false,
            path: "/homepage/consultants/apply"
        }
    ];

    useEffect(() => {
        const param = { role_name: CONSTANTS.ROLE_NAME.CONSULTANT };
        dispatch(CommunityActions.fetchCommunity(param));
        const data = {
            type: CONSTANTS.SPONSOR_TYPE.CONSULTANT
        };
        dispatch(SponsorActions.getAllSponsor(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _renderListCard = () => {
        return (
            <ContainerListCard>
                { _.map(communityData, (data) => {
                    return <CompanyCard data={data} key={data.id} />;
                })}
            </ContainerListCard>
        );
    };

    const _renderItemSponsor = () => {
        const render = _.map(listSponsor.data, (item, index) => {
            return <ItemSponsor key={index}><img src={item.url_img_aws} alt={item.name} /></ItemSponsor>;
        });
        return render;
    };

    const _renderSponsor = () => {
        return (
            <Sponsor className="display-sponsor">
                {_renderItemSponsor()}
            </Sponsor>
        );
    };

    const _renderSponsorSmallScreen = () => {
        const render = _.map(listSponsor.data, (item, index) => {
            return <div className="item-small-screen" key={index}><img src={item.url_img_aws} alt={item.name} /></div>;
        });
        return render;
    };

    const _renderMain = () => {
        return (
            <MainContent>
                {isLoading && <Loading />}
                <Banner
                    backgroundImg={Images.banner1}
                    title={t("homepageUser.Join our community of Consultants")}
                    description={t("homepageUser.Consult and provide mentoring to a pool of rich and diverse startups in the region")}
                />
                <NavigationBar listNav={listNav} />
                <ContainerFullWidthCommunity>
                    {_renderSponsor()}
                </ContainerFullWidthCommunity>
                <div ref={refMain} className="community">
                    <div className="content-min-width">
                        <TitleCommunity>
                            {t("homepageUser.Here you will find a list of professional experts part of our community")}
                        </TitleCommunity>
                        <SearchBar pageType="consultants" roleName={CONSTANTS.ROLE_NAME.CONSULTANT} />
                        <Result>
                            {communityData.length}
                            {' '}
                            {t("general.Results found")}
                        </Result>
                        {_renderListCard()}
                    </div>
                </div>
                <div className="sponsor-small-screen">
                    {_renderSponsorSmallScreen()}
                </div>
                <SummaryOverview />
            </MainContent>
        );
    };
    return (
        <DefaultLayout context={_renderMain()} currentPage="consultant" currentTop={true} />
    );
};

export default Community;
