import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Utils from "../../../libs/Utils";
import history from "../../../history";
import Constants from "../../../constants/Constants";
export const Homepage = () => {
    const isLogged = useSelector((state) => state.Authentication.isLogged);
    useEffect(() => {
        if (!isLogged) return history.push("/authentication");
        const userData = Utils.getSavedUserData();
        if (userData.role.name ===  Constants.ROLE_NAME.SUPER_ADMIN) return history.push("/admin/overview");
        history.push("/user-overview");
    }, [isLogged]);

    return (
        <div />
    );
};

export default Homepage;
