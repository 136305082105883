import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput,
    InputContainer,
    GeneralButtonLanguage
} from "./Sponsor.styles";
import {
    PageTitle,
    LabelSlide
} from "../../../../components/Common/CommonLayout";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import SponsorActions from "../../../../actions/Sponsor";
import Utils from "../../../../libs/Utils";
import Validation from "../../../../libs/Validation";
import Constants from "../../../../constants/Constants";
import ImageEditor from "../../../../components/Common/ImageEditor";

const Sponsor = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.Sponsor.isLoading);
    const fileInput = useRef(null);
    const [formData, setFormData] = useState({
        title: {
            en: "",
            al: ""
        }
    });
    const [type, setType] = useState(Constants.SPONSOR_TYPE.ENTREPRENEUR);
    const [fileUrl, setFileUrl] = useState("");
    const [fileData, setFileData] = useState("");
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);

    const validateMutilpleLanguage = () => {
        const validationRules = [
            {
                name: `${t("general.Title")} ${t("general.English")}`,
                value: formData.title.en,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: `${t("general.Title")} ${t("general.Albania")}`,
                value: formData.title.al,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: 'Featured image',
                value: fileData.name,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const validateForLanguage = (lang) => {
        const validationRules = [
            {
                name: t("general.Title"),
                value: formData.title[lang],
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: 'Featured image',
                value: fileData.name,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const paramPostSingleLanguage = (param) => {
        if (language === Constants.LANGUAGE_CODE.EN) {
            param.append('title', JSON.stringify({ en: formData.title.en }));
        } else {
            param.append('title', JSON.stringify({ al: formData.title.al }));
        }
    };

    const paramPostMultipleLanguage = (param) => {
        param.append('title', JSON.stringify({ en: formData.title.en, al: formData.title.al }));
    };

    const AddSponsor = () => {
        let flag = true;
        if (isMultipleLanguage) {
            flag = validateMutilpleLanguage();
        } else if (!validateForLanguage(language)) return;
        if (flag) {
            const param = new FormData();
            if (isMultipleLanguage) paramPostMultipleLanguage(param);
            if (!isMultipleLanguage) paramPostSingleLanguage(param);
            param.append('is_support_mul_lang', isMultipleLanguage);
            param.append('type', type);
            param.append('img', fileData);
            dispatch(SponsorActions.addSponsor(param));
        }
    };

    const handleChange = (value, nameInput) => {
        const data = { ...formData };
        data[nameInput][language] = value;
        setFormData(data);
    };

    const handleFileChange = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        const file = e.target.files[0];
        setFileData(file);
        setFileUrl(url);
    };

    const deleteFileAttachment = () => {
        setFileData("");
        setFileUrl("");
        fileInput.current.value = "";
    };

    const onEditedImageCallback = async ({ callbackType, croppedImage }) => {
        switch (callbackType) {
            case 'image':
                setFileUrl(croppedImage);
                // const newImage = await Utils.convertDataURLToFile(croppedImage, 'sponsor_image.jpg');
                setShowImageEditor(false);
                break;
            case 'close':
                setShowImageEditor(false);
                break;
            default:
                break;
        }
    };

    return (
        <DefaultLayout
            userRole='limited-admin'
            isLoading={false}
            page="sponsor"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("sponsor.Add Sponsor")}
                    </PageTitle>
                    <ProgressBar className="mb-2">
                        <WrapperInput>
                            <TitleInput>
                                {t("general.Title")}
                                <div className="language">
                                    <LabelSlide className="switch">
                                        <input type="checkbox" value={isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} />
                                        <span className="slider round" />
                                    </LabelSlide>
                                    {isMultipleLanguage
                                        && (
                                            <>
                                                <GeneralButtonLanguage
                                                    type="button"
                                                    className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                                    value={t("general.English")}
                                                    onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                                />
                                                <GeneralButtonLanguage
                                                    type="button"
                                                    className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                                    value={t("general.Albanian")}
                                                    onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                                />
                                            </>
                                        )
                                    }
                                    {!isMultipleLanguage
                                        && (
                                            <>
                                                <GeneralButtonLanguage
                                                    type="button"
                                                    className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                                    value={t("general.English")}
                                                    onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                                    disabled
                                                />
                                                <GeneralButtonLanguage
                                                    type="button"
                                                    className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                                    value={t("general.Albanian")}
                                                    onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                                    disabled
                                                />
                                            </>
                                        )
                                    }

                                </div>
                            </TitleInput>
                            <InputContainer
                                value={formData.title[language] || ""}
                                onChange={(event) => handleChange(event.target.value, "title")}
                            />
                        </WrapperInput>
                        <WrapperInput>
                            <TitleInput>{t("general.Type")}</TitleInput>
                            <select onChange={(e) => setType(e.target.value)} value={type}>
                                <option value={Constants.SPONSOR_TYPE.ENTREPRENEUR}>
                                    {t("general.Entrepreneurs")}
                                </option>
                                <option value={Constants.SPONSOR_TYPE.INVESTOR}>
                                    {t("general.Investors")}
                                </option>
                                <option value={Constants.SPONSOR_TYPE.CONSULTANT}>
                                    {t("general.Consultants")}
                                </option>
                            </select>
                        </WrapperInput>
                        <WrapperInput className="container-upload">
                            <input
                                ref={fileInput}
                                id='uploadAvatar'
                                type="file"
                                accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                                onChange={(e) => handleFileChange(e)}
                            />
                            {t("sponsor.Featured Image")}
                                :
                            {' '}
                            <i className="fas fa-upload" onClick={() => fileInput.current.click()} />
                            {fileUrl
                                && (
                                    <div className="display-image-file">
                                        <img src={fileUrl} alt='sponsor' />
                                        <div className="remove" onClick={() => deleteFileAttachment()}>X</div>
                                    </div>
                                )
                            }
                        </WrapperInput>
                        <div className="container-button">
                            <GeneralButtonLanguage
                                type="button"
                                className="active"
                                value={t("general.Save")}
                                onClick={() => AddSponsor()}
                            />
                            <GeneralButtonLanguage
                                type="button"
                                className={fileUrl ? "active" : "active disable"}
                                value={t("general.Edit Image")}
                                onClick={() => setShowImageEditor(true)}
                            />
                        </div>
                    </ProgressBar>
                    {fileUrl && (
                        <ImageEditor imageSrc={fileUrl} showImageEditor={showImageEditor} croppedImageEditorCallback={(data) => onEditedImageCallback(data)} />
                    )}
                </BodyContainer>
            )}
        />
    );
};

Sponsor.propTypes = {

};

export default Sponsor;
