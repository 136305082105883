import styled from 'styled-components';
import { ColorName } from "../../Variables";

const BannerJoinComponent = styled.div`
    background: ${ColorName.casablanca};
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${ColorName.chathamsBlueDark};
    font-size: 55px;
    font-weight: 300;
    & .strong {
        font-weight: 500
    }
    @media (max-width: 920px) {
        flex-direction: column;
        padding: 1em;
        font-size: 3em;
        text-align: center;
    }
`;

const BannerImgComponent = styled.div`
    height: 600px;
    &.none-height{
        height: unset;
    }
`;

const ContentImgContent = styled.div`
    background-image: url(${(props) => props.backgroundImg || 'none'});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: center;
    color: ${ColorName.chathamsBlueDark};
    padding: 0 1em;
    & .title {
        margin: 0 20px;
        display: flex;
        justify-content: center;
        font-size: 50px;
        font-weight: 300;
        margin-top: 80px;
        flex-direction: column;
        @media (max-width: 1024px) {
            margin-top: 40px;
        }
    }
    & .description {
        margin: 0 20px;
        display: flex;
        font-size: 27px;
        font-weight: 600;
        margin-top: 28px;
    }
    & .container{
        max-width: 1140px;
        width: 100%;
    }
    & .library-container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        max-width: 1140px;
    }
    & .library-title {
        font-size: 2em;
        font-weight: 800;
        margin-top: 2em;
        @media (max-width: 1024px) {
            margin-top: 40px;
        }
    }
    & .search {
        margin-top: 4em;
        input {
            padding: 10px;
            border: none;
            min-width: 300px;
            outline: none;
        }
        i {
            background: ${ColorName.botticelli};
            padding: 8px;
            height: 19px;
        }
    }
    & .library-detail {
        flex-direction: column;
        justify-content: center;
    }
    & .bold-text {
        font-weight: 800;
        font-size: 0.7em;
    }
    &.bg-color {
        background: ${ColorName.saffron};
    }
    & .title-library {
        font-weight: 400;
        font-size: 1.5em;
        margin-top: 2em;

        .released-date {
            font-size: 0.5em;
            margin-top: 0.4em;
        }
    }
    & .description-library {
        color: white;
        font-weight: 300;
        font-size: 1em;
        line-height: 1.5em;
        display: block;
        .cover-image {
            width: 25%;
            float: left;
            margin: 0 2em 1em 0;
            img {
                width: 100%;
            }
        }
        .description-context {
            flex: 1;
            span, p {
                background: none !important;
                margin: 0;
            }
        }
    }
    &.detail-library {
        padding-bottom: 2em
    }
`;

export {
    BannerJoinComponent,
    BannerImgComponent,
    ContentImgContent
};
