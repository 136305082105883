import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import {
  Wrapper,
  ProgressBar,
  WrapperTitle,
  TitleCategory,
  DetailTitle,
  Column
} from "./Judge.styles";
import {
  VerticalContainer, PageTitle, Flex
} from "../../../components/Common/CommonLayout";
import JudgeActions from '../../../actions/Judge';
import FundingApplicationActions from "../../../actions/FundingApplication";
import Utils from "../../../libs/Utils";
import CONSTANTS from "../../../constants/Constants";
import Button from "../../../components/Common/Button";
import { ColorName } from "../../../components/Variables";
import history from "../../../history";
import moment from 'moment';

const ApplyApplicationDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.Judge.isLoading);
  const applyApplication = useSelector((state) => state.Judge.applyById);
  const [details, setDetails] = useState([]);
  const userData = Utils.getSavedUserData();
  const [fundingDetail, setFundingDetail] = useState();

  useEffect(() => {
    dispatch(JudgeActions.getApplyApplicationById(location.state.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = convertDataCategory();
    if (data) {
      const dataRender = convertDataAnwserForQuestion(data);
      setDetails(dataRender);
      setFundingDetail(applyApplication.fundingApplication);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyApplication]);

  const convertDataCategory = () => {
    if (!_.isEmpty(applyApplication)) {
      const { questionCategories } = { ...applyApplication.fundingApplication.questionnaire };
      const data = {};
      _.map(questionCategories, (child, index) => {
        if (!_.has(data, index)) data[index] = [];
        _.map(child.questionDetails, (detail) => {
          detail.title_category = child.title;
          data[index].push(detail);
        });
      });
      return data;
    }
    return false;
  };

  const convertDataAnwserForQuestion = (categories) => {
    const applyDetails = { ...applyApplication };
    if (categories) {
      const listQuestion = [];
      _.map(categories, (category) => {
        _.map(category, (cat) => {
          _.map(applyDetails.applyApplicationDetails, (child) => {
            if (child.question_detail_id === cat.id) {
              cat.awnser = child.answer;
              listQuestion.push(cat);
            }
          });
        });
      });
      const grouped = _.groupBy(listQuestion, (child) => {
        return child.title_category;
      });
      return grouped;
    }
    return false;
  };

  const renderQuestionAndAnwser = (category) => {
    const render = _.map(category, (child, index) => {
      return (
        <WrapperTitle key={index} marginTop="1em" marginLeft="1em">
          <div className="fonts-status">
            {child.title}
          </div>
          {child.question_type === 1 && (
            <input
              disabled
              className="style-input style-disabled"
              value={child.awnser || ''}
            />
          )}
        </WrapperTitle>
      );
    });
    return render;
  };

  const renderCategory = () => {
    const render = _.map(details, (category, index) => {
      return (
        <div key={index}>
          <TitleCategory>
            {_.last(category).title_category || ""}
          </TitleCategory>
          {renderQuestionAndAnwser(category)}
        </div>
      );
    });
    return render;
  };

  const evaluation = async () => {
    const evaluationID = _.find(applyApplication.evaluationApplies, (o) => { return o.applicationJudge.user_id === userData.userData.user_id; }) || 0;
    history.replace({
      state: { id: evaluationID.id || evaluationID, fundingID: location.state.id, judgeApplicationID: location.state.judgeApplicationID },
      pathname: "/judge/evaluation"
    });
  };

  const viewAllApply = () => {
    history.replace({
      state: { id: location.state.judgeApplicationID },
      pathname: "/judge/applications/list-applies"
    });
  };

  const renderDropdownStatus = () => {
    const render = (
      <WrapperTitle className="dropdownStatus">
        <div className="btnBottom">
          <Button
            color={ColorName.white}
            background={ColorName.chathamsBlueDark}
            title={t("general.Back")}
            height="35px"
            width="9em"
            margin="1em 1.5em 0 0"
            padding='1em'
            onClick={() => viewAllApply()}
          />
          <Button
            color={ColorName.white}
            background={ColorName.chathamsBlueDark}
            title={t("fundingApplications.Evaluation")}
            height="35px"
            width="9em"
            margin="1em 1.5em 0 0"
            padding='1em'
            onClick={() => evaluation()}
          />
        </div>
      </WrapperTitle>
    );
    return render;
  };

  const checkColorStatusCell = (st) => {
    if (CONSTANTS.COLOR_STATUS.GRADIENT_VALUE.includes(st)) {
      return 'pink';
    } else if (CONSTANTS.COLOR_STATUS.GREEN_VALUE.includes(st)) {
      return 'green';
    } else if (CONSTANTS.COLOR_STATUS.RED_VALUE.includes(st)) {
      return 'red';
    }
  };

  const _renderListFile = (listFiles) => {
    const render = _.map(listFiles, (child, index) => {
      return (
        <a href={child} target="_blank" rel="noopener noreferrer" key={index}>
          {index && _.last(index.split("/"))}
        </a>
      );
    });
    return render;
  };

  const deleteDocumentWinner = (fileName) => {
    Utils.popupAlert({
      type: "warning",
      title: t("rounderTable.Do you want to delete"),
      buttons: [t("general.Cancel"), true],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        const formData = new FormData();
        formData.append('delete_files', JSON.stringify([fileName]));
        dispatch(FundingApplicationActions.deleteDocumentWinner(applyApplication.id, formData));
      }
    });
  }

  const _renderListFileWinner = (listFiles) => {
    const render = _.map(listFiles, (child, index) => {
      return (
        <Flex key={index}>
          <a href={child} target="_blank" rel="noopener noreferrer">
            {index && _.last(index.split("/"))}
            {_.map(applyApplication.doc_winner_updated_at, (item, key) => {
              if (key === index) return <span key={key}>&nbsp;- {moment(item).format("DD-MM-YYYY hh:mm")} </span>
            })}
          </a>
          <i onClick={() => deleteDocumentWinner(index)} className="fas fa-trash-alt" />
        </Flex>
      );
    });
    return render;
  };

  return (
    <DefaultLayout
      userRole='judge'
      isLoading={isLoading}
      page="applications"
      content={(
        <Wrapper>
          <PageTitle>
            {t("fundingApplications.Funding Application Details")}
          </PageTitle>
          <ProgressBar className="mb-2">
            <Column>
              <DetailTitle>
                {t("fundingApplications.Funding Opportunity’s name")}
                : &nbsp;
                <span className="color-title">{!_.isUndefined(fundingDetail) ? fundingDetail.title : ""}</span>
              </DetailTitle>
              
            </Column>
            <Column>
              <DetailTitle>
                {t("general.Status")}
                :
              </DetailTitle>
              <span className={!_.isUndefined(fundingDetail) ? checkColorStatusCell(fundingDetail.status) : ""}>
                {!_.isUndefined(fundingDetail)
                  ? _.findKey(CONSTANTS.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, fundingDetail.status)) : ""
                }
              </span>
            </Column>
          </ProgressBar>
          <PageTitle className='title'>
            {t("fundingApplications.Apply Application Details")}
          </PageTitle>
          <ProgressBar className="mb-2">
            <VerticalContainer>
              {renderCategory()}
              {
                (applyApplication && applyApplication.document_json) && (
                  <WrapperTitle marginTop="1em" marginLeft="1em">
                    <div className="fonts-status for-multile-files">
                      {t("fundingApplications.Application Form Template")}
                      :
                      {applyApplication.document_json && (
                        _renderListFile(applyApplication.document_json)
                      )}
                    </div>
                    <div>
                      {_.isEmpty(applyApplication.document_json) && (
                        t("general.No Data Found")
                      )}
                    </div>
                  </WrapperTitle>
                )
              }
              {
                (applyApplication && applyApplication.document_winner_json) && (
                  <WrapperTitle marginTop="1em" marginLeft="1em">
                    <div className="fonts-status for-multile-files">
                      {t("general.Document for reporting")}
                      :
                      {applyApplication.document_winner_json && (
                        _renderListFileWinner(applyApplication.document_winner_json)
                      )}

                    </div>
                    <div>
                      {_.isEmpty(applyApplication.document_winner_json) && (
                        t("general.No Data Found")
                      )}
                    </div>
                  </WrapperTitle>
                )
              }
              {renderDropdownStatus()}
            </VerticalContainer>
          </ProgressBar>
        </Wrapper>
      )}
    />
  );
};

export default ApplyApplicationDetails;
