import React, { useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import useOutsideClick from "./ClickOutside";
import { ContainerCalendar } from './InputCalendar.styles';
const InputCalendar = (props) => {
    const [date, setDate] = useState("");
    const [isShow, setIsShow] = useState(false);
    const { value } = props;
    const ref = useRef();

    useOutsideClick(ref, () => {
        setIsShow(false);
    });

    const onChange = (newDate) => {
        const day = newDate.getDate().toString().padStart(2, '0');
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
        const year = newDate.getFullYear().toString();
        const dateConvert = `${year}-${month}-${day}`;
        setDate(dateConvert);
        setIsShow(false);
        props.selectedDate(dateConvert);
    };

    const isShowCalendar = () => {
        setIsShow(!isShow);
    };

    return (
        <ContainerCalendar ref={ref}>
            <input className="inputCalendar" type="text" onClick={() => isShowCalendar()} value={value || date} readOnly />
            <Calendar
                key={value}
                className={isShow ? "dropDownCalendar" : "hidden dropDownCalendar"}
                onChange={onChange}
                defaultValue={value ? new Date(value) : new Date()}
            />
        </ContainerCalendar>
    );
};

export default InputCalendar;
