const ColorName = {
    gray: "#858585",
    mercury: "#E5E5E5",
    white: "#FFFFFF",
    black: "#000000",
    gallery: "#F0F0F0",
    limeade: "#1CB404",
    torchRed: "#F52121",
    screaminGreen: "#5DF888",
    carnation: "#F65656",
    tickleMePink: "#FD749B",
    sunsetOrange: "#FF4949",
    alabaster: "#F7F7F7",
    persianBlue: "#281AC8",
    tutu: "#FFF9FE",
    lavenderPink: "#FAA9C6",
    purpleHeart: "#891AC8",
    hotPink: "#FC58B3",
    koromiko: "#FEAE68",
    whisper: "#E6E2E2",
    harlequin: "#3CFD1C",
    redOrange: "#FD3232",
    silver: "#C4C4C4",
    mineShaft: "#333333",
    lightcyan: "lightcyan",
    linearGradient: "linear-gradient(178.18deg, #FF4949 -13.56%, #281AC8 158.3%)",
    darkorange: "darkorange",
    apple: "#43B02A",
    lochmara: "#0B75C5",
    mirage: "#1F303C",
    tuna: "#30353E",
    limedSpruce: "#323B44",
    cornflowerBlue: "#64A8EC",
    pictonBlue: "#3794E9",
    geyser: "#CED7DF",
    aquaHaze: "#F4F7F9",
    chathamsBlue: "#174369",
    bahamaBlue: "#1B4688",
    oysterBay: "#D1E5EB",
    chathamsBlueDark: "#1B4687",
    bostonBlue: "#3E92AC",
    casablanca: "#F8CB46",
    fern: "#62AF56",
    glacier: "#6FABBF",
    botticelli: "#D1E4EB",
    chathamsBlueLighter: "#1B4483",
    bostonBlueLighter: '#3F91AB',
    saffron: '#F5C024',
    bostonBlueDarker: '#3F92AD',
    fruitSalad: '#5CA253',
    scorpion: '#565656',
    fruitSaladLight: '#62AC55',
    appleLight: '#438C30',
    fruitSaladDarker: '#60ad54',
    bourbon: '#C66D1E',
    wellRead: '#BC3333',
    fuchsiaBlue: '#7347C6',
    studio: '#5946AA',
    chestnut: '#B54D4D',
    harlequinLight: '#33F613',
    concrete: '#F2F2F2',
    grayDarker: '#7E7E7E',
    kashmirBlue: '#446892',
    pelorous: '#33A7B7'
};

export default ColorName;
