import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import _ from "lodash";
import { ContainerBody } from "./DefaultLayout.styles";
import Loading from "../../CommonLayoutPart/Loading";
import DropDownLanguage from "../../../Common/DropDownLanguage";

const NoHeader = (props) => {
    useEffect(() => {
        if (!props.currentTop || _.isUndefined(props.currentTop)) return window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const {
        context,
        isLoading
    } = props;

    return (
        <div>
            {isLoading && <Loading />}
            <ContainerBody className="no-header">
                <div className="header-limited">
                    <DropDownLanguage />
                </div>
                {context}
            </ContainerBody>
        </div>
    );
};

NoHeader.propTypes = {
    context: PropTypes.element.isRequired,
    currentPage: PropTypes.string,
    currentTop: PropTypes.bool,
    isLoading: PropTypes.bool
};

export default NoHeader;
