import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import _ from 'lodash';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
    RightArrowButton, LeftArrowButton, ItemCardLayout, SliderContainer
} from './SlickCarouselNews.styles';
import history from "../../../history";
import NewsActions from "../../../actions/News";

import EventActions from "../../../actions/Event";

const SlickCarouselNews = (props) => {
    const { listData, type } = props;
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const defaultSliderToShow = 4;
    const sliderToShow = (listData && listData.length < defaultSliderToShow) ? listData.length : defaultSliderToShow;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: sliderToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: sliderToShow - 1,
                    slidesToScroll: 1,
                    initialSlide: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: sliderToShow - 1,
                    slidesToScroll: 1,
                    initialSlide: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: sliderToShow - 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: sliderToShow - 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false
                }
            }
        ],
        nextArrow: <RightArrowButton />,
        prevArrow: <LeftArrowButton />
    };

    const readMore = (id) => {
        dispatch(NewsActions.setNewsId(id));
        const data = {
            language: i18n.language
        };
        dispatch(NewsActions.getNewsById(data, id));
        history.push({
            state: { id },
            search: `?id=${id}`,
            pathname: "/homepage/news/detail"
        });
    };

    const readMoreEvent = (id) => {
        dispatch(EventActions.setEventId(id));
        const data = {
            language: i18n.language
        };
        dispatch(NewsActions.getNewsById(data, id));
        dispatch(EventActions.getEventById(id, data));
        history.push({
            state: { id },
            search: `?id=${id}`,
            pathname: "/homepage/events/detail"
        });
    };

    const _generateItem = (item, index) => {
        return (
            <ItemCardLayout key={index} onClick={type === "news" ? (() => readMore(item.id)) : (() => readMoreEvent(item.id))}>
                <div className="item-avatar">
                    <img src={item.url_img_aws || "https://via.placeholder.com/245x120.png"} alt={item.title} />
                </div>
                <div className="item-title">
                    <div className="insde-title">
                        {item.title}
                    </div>
                    <div className="description">
                        {item.description}
                    </div>
                </div>

                <div className="item-footer">
                    <div className="read-more" onClick={type === "news" ? (() => readMore(item.id)) : (() => readMoreEvent(item.id))}>
                        {t("general.READ MORE")}
                        <div className="read-more-line" />
                    </div>
                    <div className="date-created">
                        {type === "news" ? moment(item.created_at).format("DD-MM-YYYY") : moment(item.date_event).format("DD-MM-YYYY")}
                    </div>
                </div>
            </ItemCardLayout>
        );
    };

    const renderCarouselItems = () => {
        if (listData) {
            return (
                _.map(listData, (item, index) => {
                    return _generateItem(item, index);
                })
            );
        }
    };

    return (
        <SliderContainer>
            <Slider {...settings}>
                {renderCarouselItems()}
            </Slider>
        </SliderContainer>
    );
};
export default SlickCarouselNews;
