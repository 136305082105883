import styled from 'styled-components';
import { ColorName } from "../../Variables";

const IconDiamondComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    & .diamond {
        width: 0px;
        height: 0;
        border: 7px solid transparent;
        border-bottom-color: ${ColorName.bostonBlue};
        position: relative;
        top: -7px;
    }
    & .diamond:after {
        content: '';
        position: absolute;
        left: -7px;
        top: 7px;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top-color: ${ColorName.bostonBlue};
    }
`;
export {
    IconDiamondComponent
};