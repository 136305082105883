import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import {
    SideBarContainer,
    Item
} from "./SideBar.styles";
import history from "../../../../history";
import LoginActions from "../../../../actions/Authentication";

const AdminSideBar = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedPage, setSelectedPage] = useState('adminOverview');
    const numberUnread = useSelector((state) => state.Message.numberUnread);
    useEffect(() => {
        setSelectedPage(props.page);
    }, [props]);

    const switchPage = (page) => {
        history.push(page);
    };

    const logout = () => {
        return dispatch(LoginActions.logout());
    };

    return (
        <SideBarContainer>
            <Item
                className={selectedPage === 'adminOverview' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/overview')}
            >
                <span>{t("general.Overview")}</span>
                <i className={`${selectedPage === 'adminOverview' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'users' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/users')}
            >
                <span>{t("general.Users")}</span>
                <i className={`${selectedPage === 'users' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'messages' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/messages')}
            >
                <span>{t("general.Messages")}</span>
                <div className='ticker'><span>{_.parseInt(numberUnread) > 0 ? numberUnread : ''}</span><i className={`${selectedPage === 'messages' ? '' : 'hide-icon'}`} /></div>
            </Item>
           
            <Item
                className={selectedPage === 'news' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/news')}
            >
                <span>{t("general.News")}</span>
                <i className={`${selectedPage === 'news' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'events' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/events')}
            >
                <span>{t("general.Events")}</span>
                <i className={`${selectedPage === 'events' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'library' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/library')}
            >
                <span>{t("general.Library")}</span>
                <i className={`${selectedPage === 'library' ? '' : 'hide-icon'}`} />
            </Item>
            {/* Guest Portal Settings */}
            <Item
                className={selectedPage === 'about-us-setting' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/about-us-setting')}
            >
                <span>{t("general.Guest About Us Setting")}</span>
                <i className={`${selectedPage === 'about-us-setting' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'summary-setting' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/summary-setting')}
            >
                <span>{t("general.Guest Summary Setting")}</span>
                <i className={`${selectedPage === 'summary-setting' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'term-setting' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/term-setting')}
            >
                <span>{t("general.Guest Term Setting")}</span>
                <i className={`${selectedPage === 'term-setting' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'privacy-policy-setting' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/privacy-policy-setting')}
            >
                <span>{t("general.Guest Privacy Policy Setting")}</span>
                <i className={`${selectedPage === 'privacy-policy-setting' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'apply-setting' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/apply-setting')}
            >
                <span>{t("general.Guest Apply Setting")}</span>
                <i className={`${selectedPage === 'apply-setting' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'testimonial-setting' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/testimonial-setting')}
            >
                <span>{t("general.Testimonial Setting")}</span>
                <i className={`${selectedPage === 'testimonial-setting' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'process' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/process')}
            >
                <span>{t("general.Process")}</span>
                <i className={`${selectedPage === 'process' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'announcement' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/announcement-setting')}
            >
                <span>{t("general.Announcement")}</span>
                <i className={`${selectedPage === 'announcement' ? '' : 'hide-icon'}`} />
            </Item>
         
            <Item
                className={selectedPage === 'sponsor' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/sponsors')}
            >
                <span>{t("sponsor.Sponsors")}</span>
                <i className={`${selectedPage === 'sponsor' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'contact-us' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/contact-us-setting')}
            >
                <span>{t("general.Contact Us")}</span>
                <i className={`${selectedPage === 'contact-us' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'faq' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/faqs')}
            >
                <span>{t("general.FAQs")}</span>
                <i className={`${selectedPage === 'faq' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'introduce' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/introduce-manage')}
            >
                <span>{t("introduce.Guest What We Do Setting")}</span>
                <i className={`${selectedPage === 'introduce' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'criteria' ? 'selected' : ''}
                onClick={() => switchPage('/limited-admin/criteria')}
            >
                <span>{t("criteria.Criteria")}</span>
                <i className={`${selectedPage === 'criteria' ? '' : 'hide-icon'}`} />
            </Item>
            <Item onClick={logout}>
                <span>{t("general.Logout")}</span>
                <i className={`${selectedPage === '' ? '' : 'hide-icon'}`} />
            </Item>
        </SideBarContainer>
    );
};

AdminSideBar.propTypes = {
    page: PropTypes.string
};

export default AdminSideBar;
