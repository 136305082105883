import Api from "./Base";


export function subscribe(data) {
    return Api.post("subscribe_receive_auto_mail", data);
}

export function unsubscribe(data) {
    return Api.post("unsubscribe_receive_auto_mail", data);
}
