import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import {
    NavigationBarContainer,
    Tab,
    Tabs,
} from "./NavigationBar.styles";
import { useDispatch } from 'react-redux';
import ExportActions from '../../../../actions/Export';
import Utils from '../../../../libs/Utils';
import Constants from '../../../../constants/Constants';

const LimitedOverviewNavigationBar = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [title, setTitle] = useState(''); // eslint-disable-line
    const [selectedTab, setSelectedTab] = useState('');
    const [defaultTab, setDefaultTab] = useState('');
    const userData = Utils.getSavedUserData();
    useEffect(() => {
        setTitle(props.title);
        props.selectedTab(selectedTab);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    useEffect(() => {
        setDefaultTab(props.defaultTab);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const switchTap = (tap) => {
        setSelectedTab(tap);
    };

    const exportALluser = () => {
        const data = {
            userId: "all"
        }
        dispatch(ExportActions.exportAllUserExcell(data));
    };

    return (
        <NavigationBarContainer className="forFundingApplication">
            <Tabs>
                <Tab onClick={() => exportALluser()}>
                    {t("general.Export All Information")}
                </Tab>
            </Tabs>
            <Tabs className="for-user-admin">
                <Tab
                    className={(selectedTab === '' || selectedTab === 'all') && (defaultTab === "" || defaultTab === 'all') ? 'selected-tap' : ''}
                    onClick={() => switchTap('all')}
                >
                    {t("general.All")}
                </Tab>
                <Tab
                    className={selectedTab === 'entrepreneur' || defaultTab === "entrepreneur" ? 'selected-tap' : ''}
                    onClick={() => switchTap('entrepreneur')}
                >
                    {t("general.Entrepreneurs")}
                </Tab>
                <Tab
                    className={selectedTab === 'investor' || defaultTab === "investor" ? 'selected-tap' : ''}
                    onClick={() => switchTap('investor')}
                >
                    {t("general.Investors")}
                </Tab>
                <Tab
                    className={selectedTab === 'consultant' || defaultTab === "consultant" ? 'selected-tap' : ''}
                    onClick={() => switchTap('consultant')}
                >
                    {t("general.Consultants")}
                </Tab>
                {userData.role.name === Constants.ROLE_NAME.SUPER_ADMIN && (
                    <Tab
                        className={selectedTab === 'limited user' || defaultTab === "limited user" ? 'selected-tap' : ''}
                        onClick={() => switchTap('limited user')}
                    >
                        {t("general.Limited user")}
                    </Tab>
                )}
                <Tab
                    className={selectedTab === 'pending' || defaultTab === "pending" ? 'selected-tap' : ''}
                    onClick={() => switchTap('pending')}
                >
                    {t("general.Pending")}
                </Tab>
            </Tabs>

        </NavigationBarContainer>
    );
};

LimitedOverviewNavigationBar.propTypes = {
    title: PropTypes.string,
    selectedTab: PropTypes.func,
    defaultTab: PropTypes.string
};

export default LimitedOverviewNavigationBar;
