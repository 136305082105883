import styled from 'styled-components';
import { ColorName } from "../../Variables";
import { HorizontalContainer } from '../CommonLayout';

const TableContainer = styled.table`
    border-collapse: separate;
    border-spacing: 0 1em;
    thead {
        tr {
            text-align: center;
            font-weight: bold;
            td {
                // background: linear-gradient(178.43deg, ${ColorName.tickleMePink} -13.56%, ${ColorName.persianBlue} 158.3%);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                color: ${ColorName.chathamsBlueDark}
            }
            td:first-child {
                text-align: left;
            }
        }
    }
    tbody {
        tr {
            text-align: center;
            font-weight: 500;
            td:first-child {
                text-align: left;
            }
            td:last-child {
                button {
                    margin-left: auto;
                }
            }
        }
    }
`;

const Amount = styled.div`
    background: linear-gradient(163.97deg, ${ColorName.screaminGreen} 11.61%, ${ColorName.koromiko} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    font-size: 17px;
    font-weight: bold;
`;

const ButtonWrapper = styled(HorizontalContainer)`
    justify-content: center;
    align-items: center;
    & .mt-2 {
        margin-top: 2em
    }
`;

export {
    TableContainer,
    Amount,
    ButtonWrapper
};
