import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    UserDetailsContainer,
    Row,
    DropDown,
    ProgressBar,
    WrapperInput
} from "./Users.styles";
import {
    PageTitle,
    GeneralButton,
    VerticalContainer
} from "../../../../components/Common/CommonLayout";
import AdminOverViewAction from '../../../../actions/AdminOverView';
import { ColorName } from "../../../../components/Variables";
import history from "../../../../history";
import Constants from "../../../../constants/Constants";
import Entrepreneur from "./Entrepreneur";
import Consultant from "./Consultant";
import Investors from "./Investor";
import Validation from "../../../../libs/Validation";
import Utils from "../../../../libs/Utils";
import { useLocation } from "react-router";

const UserDetails = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const fileInput = useRef(null);
    const dispatch = useDispatch();
    const [roleID, setRoleID] = useState(false);
    const [statusUser, setStatusUser] = useState(false);
    const isLoading = useSelector((state) => state.AdminOverView.isLoading);
    const userId = useSelector((state) => state.AdminOverView.userId);
    const userDetails = useSelector((state) => state.AdminOverView.userDetails);
    const [userData, setUserData] = useState({});
    const [fileAttach, setFileAttach] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    useEffect(() => {
        dispatch(AdminOverViewAction.getRoles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setRoleID(userDetails.roleId);
        setStatusUser(userDetails.status);
        setUserData(userDetails.userData);
        setImageUrl(_.get(userDetails.userData, 'urlAvatarAws') || '');
    }, [userDetails]);

    useEffect(() => {
        if (!userId && !_.has(location.state, 'id')) return history.push("/admin/users");
        dispatch(AdminOverViewAction.getUserDetailsByID(userId || location.state.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);


    const setStatus = (e) => {
        setStatusUser(e.target.value);
    };

    const renderDropDownStatus = () => {
        return (
            <DropDown value={statusUser} onChange={(e) => setStatus(e)}>
                <option value={Constants.USER_STATUS.APPROVED}>{t(`rounderTable.${_.findKey(Constants.USER_STATUS, (v) => { return v === Constants.USER_STATUS.APPROVED; })}`)}</option>
                <option value={Constants.USER_STATUS.NOT_APPROVED}>{t(`rounderTable.${_.findKey(Constants.USER_STATUS, (v) => { return v === Constants.USER_STATUS.NOT_APPROVED; }).replace("_", " ")}`)}</option>
                <option value={Constants.USER_STATUS.VERIFIED}>{t(`rounderTable.${_.findKey(Constants.USER_STATUS, (v) => { return v === Constants.USER_STATUS.VERIFIED; })}`)}</option>
                <option value={Constants.USER_STATUS.USER_UPDATE}>{t(`rounderTable.${_.findKey(Constants.USER_STATUS, (v) => { return v === Constants.USER_STATUS.USER_UPDATE; })}`)}</option>
                <option value={Constants.USER_STATUS.UNVERIFIED}>{t(`rounderTable.${_.findKey(Constants.USER_STATUS, (v) => { return v === Constants.USER_STATUS.UNVERIFIED; })}`)}</option>
                <option value={Constants.USER_STATUS.DELETED}>{t(`rounderTable.${_.findKey(Constants.USER_STATUS, (v) => { return v === Constants.USER_STATUS.DELETED; })}`)}</option>
            </DropDown>
        );
    };

    const validateForm = () => {
        const validationRules = [
            {
                name: t("general.First Name"),
                value: userData.firstName,
                rule: "required"
            },
            {
                name: t("general.Last Name"),
                value: userData.lastName,
                rule: "required"
            },
            {
                name: t("general.Address"),
                value: userData.address,
                rule: "required"
            },
            {
                name: t("general.Phone number"),
                value: userData.phoneNumber,
                rule: "required"
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const changeRole = () => {
        Utils.popupAlert({
            type: "warning",
            title: t("rounderTable.Do you want to update"),
            buttons: [t("general.Cancel"), true],
            dangerMode: true
        }).then((willContinue) => {
            if (willContinue) {
                const flag = validateForm();
                if (flag) {
                    if (roleID !== userData.roleId || userData.status !== statusUser) {
                        const formData = new FormData();
                        formData.append('role_id', roleID);
                        formData.append('status', statusUser);
                        formData.append('user_data[first_name]', userData.firstName);
                        formData.append('user_data[last_name]', userData.lastName);
                        formData.append('user_data[address]', userData.address);
                        formData.append('user_data[phone_number]', userData.phoneNumber);
                        formData.append('user_data[avatar]', fileAttach);
                        dispatch(AdminOverViewAction.updateRoleForUser(formData, userDetails.id));
                    }
                }
            }
        });

    };

    const handleFileChange = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setFileAttach(e.target.files[0]);
        setImageUrl(url);
    };

    const _renderAvatarField = () => {
        return (
            <BodyContainer className="center-content">
                <img
                    alt='profile'
                    className="style-img"
                    src={imageUrl || "https://awodev.com/images/default-forum-user.png"}
                    onClick={() => fileInput.current.click()}
                />
                <input
                    ref={fileInput}
                    type="file"
                    accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                    className='d-none'
                    onChange={(e) => handleFileChange(e)}
                />
            </BodyContainer>
        );
    };


    const _renderName = () => {
        return (
            <>
                <Row className="fullname">
                    {userData.fullName}
                </Row>
            </>
        )
    }

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={isLoading}
            page="users"
            content={(
                <BodyContainer>
                    <PageTitle>
                        {t("general.User Details")}
                    </PageTitle>
                    <ProgressBar className="mb-2">
                        <VerticalContainer>
                            {(!_.isEmpty(userData) && userDetails)
                                && (
                                    <div>
                                        {_renderAvatarField()}
                                        {userDetails.role.name === Constants.ROLE_NAME.ENTREPRENEUR && (
                                            <>
                                                {_renderName()}
                                                <Entrepreneur userData={userDetails} avatar={fileAttach} />
                                            </>
                                        )}
                                        {userDetails.role.name === Constants.ROLE_NAME.CONSULTANT && (
                                            <>
                                                {_renderName()}
                                                <Consultant userData={userDetails} avatar={fileAttach} />
                                            </>
                                        )}
                                        {userDetails.role.name === Constants.ROLE_NAME.INVESTOR && (
                                            <>
                                                {_renderName()}
                                                <Investors userData={userDetails} avatar={fileAttach} />
                                            </>
                                        )}
                                        {userDetails.role.name !== Constants.ROLE_NAME.ENTREPRENEUR && userDetails.role.name !== Constants.ROLE_NAME.CONSULTANT && userDetails.role.name !== Constants.ROLE_NAME.INVESTOR && (
                                            <UserDetailsContainer>
                                                <Row>
                                                    <label htmlFor="firstName">{t("general.First Name")}</label>
                                                    <WrapperInput
                                                        type="text"
                                                        id="firstName"
                                                        value={userData.firstName || ""}
                                                        onChange={(event) => setUserData({ ...userData, firstName: event.target.value })}
                                                    />
                                                </Row>
                                                <Row>
                                                    <label htmlFor="lastName">
                                                        {t("general.Last Name")}
                                                        {' '}
                                                    </label>
                                                    <WrapperInput
                                                        type="text"
                                                        id="lastName"
                                                        value={userData.lastName || ""}
                                                        onChange={(event) => setUserData({ ...userData, lastName: event.target.value })}
                                                    />
                                                </Row>
                                                <Row>
                                                    <label htmlFor="email">
                                                        {t("general.Email")}
                                                        {' '}
                                                    </label>
                                                    <WrapperInput type="text" id="email" value={userDetails.email || ""} disabled />
                                                </Row>
                                                <Row>
                                                    <label htmlFor="Phone">
                                                        {t("general.Phone number")}
                                                        {' '}
                                                    </label>
                                                    <WrapperInput
                                                        type="text"
                                                        id="Phone"
                                                        value={userData.phoneNumber || ""}
                                                        onChange={(event) => setUserData({ ...userData, phoneNumber: event.target.value })}
                                                    />
                                                </Row>
                                                <Row>
                                                    <label htmlFor="address">
                                                        {t("general.Address")}
                                                        {' '}
                                                    </label>
                                                    <WrapperInput
                                                        type="text"
                                                        id="address"
                                                        value={userData.address || ""}
                                                        onChange={(event) => setUserData({ ...userData, address: event.target.value })}
                                                    />
                                                </Row>
                                            </UserDetailsContainer>
                                        )}
                                        {/* <Row>
                                            <label htmlFor="role">Role: </label>
                                            <DropDown value={roleID} onChange={(e) => setRole(e)}>
                                                {
                                                    renderDropDownRoles()
                                                }
                                            </DropDown>
                                        </Row> */}
                                        {userDetails.role.name !== Constants.ROLE_NAME.ENTREPRENEUR && userDetails.role.name !== Constants.ROLE_NAME.CONSULTANT && userDetails.role.name !== Constants.ROLE_NAME.INVESTOR && (
                                            <Row marginTop="2em">
                                                <label htmlFor="role">
                                                    {t("general.Status")}
                                                    {' '}
                                                </label>
                                                {
                                                    renderDropDownStatus()
                                                }
                                            </Row>
                                        )}
                                        {userDetails.role.name !== Constants.ROLE_NAME.ENTREPRENEUR && userDetails.role.name !== Constants.ROLE_NAME.CONSULTANT && userDetails.role.name !== Constants.ROLE_NAME.INVESTOR && (
                                            <Row marginTop="4em">
                                                <GeneralButton
                                                    type="button"
                                                    value={t("general.Update")}
                                                    background={ColorName.chathamsBlue}
                                                    onClick={() => changeRole()}
                                                />
                                            </Row>
                                        )}
                                    </div>
                                )
                            }
                        </VerticalContainer>
                    </ProgressBar>
                </BodyContainer>
            )}
        />
    );
};

export default UserDetails;
