import styled from 'styled-components';
import { ColorName } from "../../../../components/Variables";
import { HorizontalContainer, VerticalContainer } from "../../../../components/Common/CommonLayout";

const Wrapper = styled(VerticalContainer)`
    background: ${ColorName.white};
    padding: 2em;
`;

const BodyContainer = styled(HorizontalContainer)`
    background: ${ColorName.white};
`;

const BodyContent = styled.div`
    width: 80%;
`;

const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${ColorName.white};
    border-radius: 10px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    margin-left: ${(props) => props.marginLeft || "0px"};
    flex: 1;
    overflow: auto;
    max-height: 700px;
    &.mb-2{
        margin-bottom: 20px;
    }
    & input{
        -webkit-appearance: auto;
    }
`;

const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || "0"};
    & .title-deadline {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
    }
    & .fonts-status {
        font-weight: bold;
        font-size: 14px;
        line-height: 25px;
    }
    & .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: ${ColorName.tickleMePink};
    }
    & .description{
        font-size: 12px;
        line-height: 1;
        color: ${ColorName.black};
        margin-top: 1.5em;
    }
    & .style-disabled{
        padding: 10px;
        border-radius: 10px;
    }
    &.dropdownStatus{
        padding: 2em 0;
        & select{
            width: 200px;
            border-radius: 5px;
            border: 2px solid gray;
            height: 30px;
            outline: none;
            margin: 1em 0;
        }
    }
    & .btnBottom{
        display: flex;
    }
`;
const TitleCategory = styled.div`
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    color: #858585;
    margin: 1.5em 0;
    text-transform: uppercase;
    &:firstChild{
        margin-top: 0;
    }
    &.forQuestionnaire{
        font-size: 15px;
        display: flex;
        & .index{
            margin-right: 5px;
        }
        font-weight: 600;
    }
`;

const DetailTitle = styled.div`
    min-width: 150px;
    padding: 1em 0em;
    &.title-name{
        font-size: 1.5em;
        color: ${ColorName.fern};
    }
`;
const Column = styled.div`
    display: flex;
    padding: 10px;
`;

const QuestionnaireTable = styled.table`
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
    & th{
        border: 1px solid black;
        text-align: left;
        padding: 10px;
        white-space: nowrap;
        &.maxScore{
            width: 150px
        }
        font-weight: 600;
    }
    & td:first-child{
        max-width: 20px;
    }
    & td{
        border: 1px solid black;
        &.titleQuestion{
            width: 70%
        }
        vertical-align: top;
        padding: 10px;
        & input{
            width: 70px;
            border-radius: 5px;
            border: 1px solid lightGray;
            padding: 5px;
        }
        & textarea{
            width: 95%;
            height: 75px;
            resize: none;
            border-radius: 5px;
            border: 1px solid lightGray;
            padding: 10px;
        }
    }
`;

const WrapperQuestionnaire = styled.div`
    padding-bottom: 1em;
    border-bottom: 1px solid lightGray;
`;

export {
    Wrapper,
    BodyContainer,
    BodyContent,
    ProgressBar,
    WrapperTitle,
    TitleCategory,
    DetailTitle,
    Column,
    QuestionnaireTable,
    WrapperQuestionnaire
};
