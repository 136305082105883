import Api from "./Base";

export function addTestimonial(data) {
    return Api.postFormData("/testimonial", data);
}

export function updateTestimonial(data, id) {
    return Api.postFormData(`/testimonial/${id}/update`, data);
}

export function deleteTestimonial(id) {
    return Api.post(`/testimonial/${id}/delete`);
}

export function fetchTestimonials(data) {
    return Api.get("/testimonial_fetch", data);
}

export function getTestimonialByID(id) {
    return Api.get(`/testimonial/${id}`);
}
