import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer } from '../../../components/Common/CommonLayout';
import { ColorName } from '../../../components/Variables'

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .ml-1 {
        margin-left: 1em
    }
    & .mb-2 {
        margin-bottom: 2em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
`;

const Report = styled(HorizontalContainer)`
    flex: 1;
    margin-bottom: 3em;
`;

const BlockReport = styled.div`
    margin-right: 40px;
    flex: 1
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 3em;
    color: ${ColorName.chathamsBlueDark};
`;

const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || "0"};
    margin-left: ${(props) => props.marginLeft || "2em"};
    color: ${ColorName.chathamsBlueDark};
    span {
        width: 140px;
    }
    & .title-deadline {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: ${ColorName.chathamsBlueDark}
    }
    & .fonts-status {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        display: flex;
    }
    & .fonts-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: ${ColorName.tickleMePink};
    }
    & .fonts-category {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        margin-top: 2em;
    }
    & .style-input {
        background: none;
        border: 1px solid ${ColorName.chathamsBlueDark};
        height: auto;
        width: 25em;
        padding: 10px;
        outline: none;
        border-radius: 5px;
    }
    & .color-text {
        color: ${ColorName.fern}
    }
    & textarea{
        padding: 10px;
        border-radius: 10px;
        border: 1px solid gray;
    }
`;

const WrapperContent = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid #F0F0F0;
    border-radius: 25px;
    padding: 50px;
    background: #FFFFFF;
    margin-bottom: 2em;
`;

const WrapperItemConent = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;
const WrapperButton = styled.div`
    justify-content: center;
    text-align: center;
    display: flex;
    margin-top: 3em;
`;

const UploadContainer = styled.div`
    display: flex;
    width: 100%;
`;

const UploadListItem = styled.div`
    flex: 1;
    & .upload-item {
        color: white;
        margin-bottom: 1em;
        padding: 5px 10px;
        a {
            text-decoration: none;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const UploadTemplate = styled.div`
    flex: 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    word-break: break-word;
    input {
        display: none;
    }
    & .input-file-container{
        display: flex;
        & .action-upload{
            display: flex;
            align-items: center;
            & i{
                padding: 0.5em;
            }
        }
        & span{
            width: unset;
        }
    }
`;

export {
    BodyContainer,
    Report,
    BlockReport,
    Title,
    WrapperTitle,
    WrapperContent,
    WrapperItemConent,
    WrapperButton,
    UploadContainer,
    UploadListItem,
    UploadTemplate
};
