import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TableContainer } from './UserFundingOpenApplication.styles';
import { RoundedRowContainer } from "../CommonLayout";
import FundingApplicationUserActions from "../../../actions/FundingApplicationsUser";
import Constants from "../../../constants/Constants";
import Loading from "../../Layout/CommonLayoutPart/Loading";
import Button from "../Button";
import { ColorName } from "../../Variables";
import Utils from "../../../libs/Utils";

const UserFundingOpenApplication = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const FundingStorage = useSelector((state) => state.FundingApplicationsUser);
    const [dataOpenApplicationUser, setDataOpenApplicationUser] = useState();

    useEffect(() => {
        dispatch(FundingApplicationUserActions.getOpenApplicationUserRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoading(FundingStorage.isLoading);
        setDataOpenApplicationUser(FundingStorage.dataOpenApplicationUser);
    }, [FundingStorage]);

    const getApplicationUserByIDRequest = async (id, isApplied = false, row = {}) => {
        if (isApplied) {
            const userData = Utils.getSavedUserData();
            const findApplication = _.find((row.applyApplications), (el) => el.user_id === userData.userData.user_id);
            if (_.isUndefined(findApplication)) return Utils.popupAlert({ type: 'warning', title: "Your application has been declined" });
            await dispatch(FundingApplicationUserActions.getApplyApplicationByIDRequestWithRedirect(findApplication.id));
        } else {
            await dispatch(FundingApplicationUserActions.getOpenApplicationUserByIDRequestWithRedirect(id));
        }
    };

    const renderTableContent = () => {
        if (_.isEmpty(dataOpenApplicationUser)) {
            return (
                <div>There are no open funding opportunities at this time. Please check again later.</div>
            );
        }
        return _.map(dataOpenApplicationUser, (row, index) => {
            return (
                <TableContainer key={index}>
                    <thead>
                        <tr>
                            <td>{t("general.Name")}</td>
                            <td>{t("general.Category")}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="name">
                                {row.title}
                            </td>
                            <td className="description">
                                {_.isUndefined(row.category) ? "--" : t(`general.${_.findKey(Constants.FUNDING_CATEGORY, (v) => { return v === row.category; })}`)}
                            </td>
                            {row.isApplied === true && (
                                <td className="button-action">
                                    <Button
                                        background={ColorName.pelorous}
                                        title={t('general.Applied')}
                                        width='7em'
                                        height='35px'
                                        onClick={() => getApplicationUserByIDRequest(row.id, true, row)}
                                    />
                                </td>
                            )}
                            {(row.isApplied !== true && row.status === Constants.FUNDING_APPLICATION_STATUS.ANNOUNCED) && (
                                <td className="button-action">
                                    <Button
                                        background={ColorName.chathamsBlueDark}
                                        title={t('general.Announced')}
                                        width='7em'
                                        onClick={() => getApplicationUserByIDRequest(row.id)}
                                    />
                                </td>
                            )}
                            {row.isApplied !== true && _.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status)) === "OPEN" && (
                                <td className="button-action">
                                    <Button
                                        background={ColorName.chathamsBlueDark}
                                        title={t('general.Open')}
                                        width='7em'
                                        onClick={() => getApplicationUserByIDRequest(row.id)}
                                    />
                                </td>
                            )}
                            {row.isApplied !== true && _.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status)) === "CLOSED" && (
                                <td className="button-action">
                                    <Button
                                        background={ColorName.wellRead}
                                        title={t('general.Closed')}
                                        width='7em'
                                        onClick={() => console.log("CLOSE")}
                                    />
                                </td>
                            )}
                        </tr>
                    </tbody>
                </TableContainer>
            );
        });
    };

    return (
        <RoundedRowContainer direction='column' padding='30px' marginBottom="5em" className="scrollbar">
            {isLoading && <Loading />}
            {renderTableContent()}
        </RoundedRowContainer>
    );
};

export default UserFundingOpenApplication;
