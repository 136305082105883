import ActionTypes from "../constants/ActionTypes";
import Constants from "../constants/Constants";

const initialState = {
    hasError: false,
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    aboutUsContent: {
        [Constants.LANGUAGE_CODE.AL]: '',
        [Constants.LANGUAGE_CODE.EN]: ''
    },
    isMultipleLanguage: false
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ActionTypes.GUEST_ABOUT_US_SETTING_REQUEST:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
                hasError: false
            };
        case ActionTypes.GUEST_ABOUT_US_SETTING_SUCCESS:
            return {
                ...state,
                aboutUsContent: action.payload || state.aboutUsContent,
                isMultipleLanguage: action.isMultipleLanguage,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GUEST_ABOUT_US_SETTING_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};
