import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    WrapperInput,
    TitleInput,
    InputContainer,
    WrapperUserDetail,
    ProgressBar
} from "../Admin/Settings/AdminUpdateInfomation.styles";
import {
    PageTitle,
    GeneralButton
} from "../../../components/Common/CommonLayout";
import history from "../../../history";
import JudgeActions from "../../../actions/Judge";
import Utils from "../../../libs/Utils";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import { ColorName } from "../../../components/Variables";

const JudgeSettings = () => {
    const [userData, setUserData] = useState([]);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isLoading = useSelector((state) => state.Judge.isLoading);

    useEffect(() => {
        const data = Utils.convertSnakeKeyToCamel(Utils.getSavedUserData());
        setUserData(_.assign(data, data.userData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateUserExplore = () => {
        const formData = new FormData();
        formData.append('user_data[first_name]', userData.firstName);
        formData.append('user_data[last_name]', userData.lastName);
        formData.append('user_data[address]', userData.address);
        formData.append('user_data[phone_number]', userData.phoneNumber);
        if (_.isEmpty(userData.firstName) || _.isEmpty(userData.lastName) || _.isEmpty(userData.address) || _.isEmpty(userData.phoneNumber)) {
            return Utils.popupAlert({ title: "Oops...", text: t('alert.Found empty field(s)'), type: "error" });
        }
        dispatch(JudgeActions.updateJugdeInfomations(userData.id, formData));
    };

    return (
        <DefaultLayout
            userRole='judge'
            isLoading={false}
            page=""
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("general.Settings")}
                    </PageTitle>
                    <ProgressBar className="mb-2">
                        <WrapperUserDetail>
                            <WrapperInput>
                                <TitleInput>{t("general.First Name")}</TitleInput>
                                <InputContainer
                                    value={userData.firstName || ''}
                                    onChange={(event) => setUserData({ ...userData, firstName: event.target.value })}
                                />
                            </WrapperInput>
                            <WrapperInput>
                                <TitleInput>{t("general.Last Name")}</TitleInput>
                                <InputContainer
                                    value={userData.lastName || ''}
                                    onChange={(event) => setUserData({ ...userData, lastName: event.target.value })}
                                />
                            </WrapperInput>
                        </WrapperUserDetail>
                        <WrapperUserDetail>
                            <WrapperInput>
                                <TitleInput>{t("general.Address")}</TitleInput>
                                <InputContainer
                                    value={userData.address || ''}
                                    onChange={(event) => setUserData({ ...userData, address: event.target.value })}
                                />
                            </WrapperInput>
                            <WrapperInput>
                                <TitleInput>{t("general.Phone number")}</TitleInput>
                                <InputContainer
                                    type="number"
                                    value={userData.phoneNumber || ''}
                                    onChange={(event) => setUserData({ ...userData, phoneNumber: event.target.value })}
                                />
                            </WrapperInput>
                        </WrapperUserDetail>
                        <WrapperUserDetail>
                            <GeneralButton type="button" background={ColorName.chathamsBlue} value={t("general.Back")} margin="0 15px 0 0" onClick={() => history.push("/admin/overview")} />
                            <GeneralButton type="button" background={ColorName.chathamsBlue} value={t("general.Submit")} onClick={() => updateUserExplore(userData.id)} />
                        </WrapperUserDetail>
                    </ProgressBar>
                </BodyContainer>
            )}
        />
    );
};

JudgeSettings.propTypes = {

};

export default JudgeSettings;
