import ActionTypes from "../constants/ActionTypes";

const initialState = {
    hasError: false,
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    summaryData: {
        informationOne: {
            name: '',
            value: 0
        },
        informationTwo: {
            name: '',
            value: 0
        },
        informationThree: {
            name: '',
            value: 0
        },
        informationFour: {
            name: '',
            value: 0
        },
        informationFive: {
            name: '',
            value: 0
        }
    }
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ActionTypes.GUEST_SUMMARY_SETTING_REQUEST:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
                hasError: false
            };
        case ActionTypes.GUEST_SUMMARY_SETTING_SUCCESS:
            return {
                ...state,
                summaryData: action.payload || state.summaryData,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GUEST_SUMMARY_SETTING_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};
