import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import {
    TableContainer,
    TableColumn,
    ColumnItem,
    TableBody,
    TableWrapper,
    TableTitle,
    TableContentWrapper,
    ButtonWrapper
} from "./RoundedTable.styles";
import { HorizontalContainer } from "../CommonLayout";
import RoundedRow from "../RoundedRow";
import CONSTANTS from "../../../constants/Constants";
import TableUtils from "../../../libs/TableUtils";
import Button from "../Button";
import AdminOverViewActions from '../../../actions/AdminOverView';
import NotificationActions from '../../../actions/Notifications';
import MessageActions from '../../../actions/Message';
import FundingApplicationActions from '../../../actions/FundingApplication';
import JudgeActions from '../../../actions/Judge';
import history from "../../../history";
import Utils from "../../../libs/Utils";
import AdminReportActions from "../../../actions/AdminFundingApplicationReport";
import FundingApplicationUserActions from "../../../actions/FundingApplicationsUser";
import NewsActions from "../../../actions/News";
import EventActions from "../../../actions/Event";
import LibraryActions from "../../../actions/Library";
import GuestTestimonialSettingActions from "../../../actions/GuestTestimonialSetting";
import GuestDocumentTemplateSettingActions from "../../../actions/GuestDocumentTemplateSetting";
import SponsorActions from "../../../actions/Sponsor";
import CriteriaActions from "../../../actions/Criteria";
import ExportActions from "../../../actions/Export";
import { ColorName } from "../../Variables";

const RoundedTable = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [initialData, setInitialData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [tableTitle, setTableTitle] = useState('');
    const [tableType, setTableType] = useState('');
    const [isExpand, setIsExpand] = useState(false);
    const [buttonTilte, setButtonTilte] = useState("View more");
    const [className, setClassName] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [showExpandButton, setShowExpandButton] = useState(false);
    const [hideTitleHeader, setHideTitleHeader] = useState(false);
    const [listOptions, setListOptions] = useState({});
    const [showNumberItems, setShowNumberItems] = useState(0);
    const userData = Utils.getSavedUserData();
    useEffect(() => {
        setInitialData(props.initialData);
        setColumns(props.columns);
        setTableTitle(props.tableTitle);
        setTableType(props.tableType);
        setIsExpand(props.isExpand);
        setClassName(props.className);
        setShowExpandButton(props.showExpandButton);
        setHideTitleHeader(props.hideTitleHeader);
        setListOptions(props.listOptions);
        setButtonTilte(props.buttonTilte);
        setShowNumberItems(props.showNumberItems);
    }, [props]);

    const callBackFunction = async (action, rowData) => {

        if (tableType === CONSTANTS.TABLE_TYPE.LIBRARY) {
            const path = userData.role.name ===  CONSTANTS.ROLE_NAME.LIMITED_ADMIN ? '/limited-admin/edit-book' : '/admin/edit-book';
            if (action === "edit") {
                history.push({
                    state: { id: rowData.id },
                    pathname: path
                });
            }
            if (action === "delete") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(LibraryActions.deleteBook(rowData.id));
                    }
                });
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.DOCUMENT_TEMPLATE) {
            if (action === "delete") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(GuestDocumentTemplateSettingActions.deleteDocumentTemplateSettingData(rowData.id));
                    }
                });
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.EVENT) {
            if (action === "publish") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to continue"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        const param = new FormData();
                        param.append('status', CONSTANTS.EVENT_STATUS.PUBLISHED);
                        dispatch(EventActions.updateStatusEvent(param, rowData.id));
                    }
                });
            }
            if (action === "publish & send mail") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to continue"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        const param = new FormData();
                        param.append('status', CONSTANTS.EVENT_STATUS.PUBLISHED);
                        dispatch(EventActions.updateStatusSendMail(param, rowData.id));
                    }
                });
            }
            if (action === "unpublish") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to continue"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        const param = new FormData();
                        param.append('status', CONSTANTS.EVENT_STATUS.UNPUBLISHED);
                        dispatch(EventActions.updateStatusEvent(param, rowData.id));
                    }
                });
            }
            if (action === "edit") {
                const path = userData.role.name ===  CONSTANTS.ROLE_NAME.LIMITED_ADMIN ? '/limited-admin/edit-event' : '/admin/edit-event';
                dispatch(EventActions.setEventId(rowData.id));
                history.push(path);
            }
            if (action === "delete") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(EventActions.deleteEvent(rowData.id));
                    }
                });
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.NEWS) {
            if (action === "publish") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to continue"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        const param = new FormData();
                        param.append('status', CONSTANTS.NEWS_STATUS.PUBLISHED);
                        dispatch(NewsActions.updateStatusNews(param, rowData.id));
                    }
                });
            }
            if (action === "publish & send mail") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to continue"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        const param = new FormData();
                        param.append('status', CONSTANTS.EVENT_STATUS.PUBLISHED);
                        dispatch(NewsActions.updateStatusSendMail(param, rowData.id));
                    }
                });
            }
            if (action === "unpublish") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to continue"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        const param = new FormData();
                        param.append('status', CONSTANTS.NEWS_STATUS.UNPUBLISHED);
                        dispatch(NewsActions.updateStatusNews(param, rowData.id));
                    }
                });

            }
            if (action === "edit") {
                const path = userData.role.name ===  CONSTANTS.ROLE_NAME.LIMITED_ADMIN ? '/limited-admin/edit-news' : '/admin/edit-news';
                dispatch(NewsActions.setNewsId(rowData.id));
                history.push(path);
            }
            if (action === "delete") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(NewsActions.deleteNews(rowData.id));
                    }
                });
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.SPONSOR) {
            if (action === "edit") {
                const path = userData.role.name ===  CONSTANTS.ROLE_NAME.LIMITED_ADMIN ? '/limited-admin/edit-sponsor' : '/admin/edit-sponsor';
                dispatch(SponsorActions.setSponsorId(rowData.id));
                history.push({
                    state: { id: rowData.id },
                    pathname: path
                });
            }
            if (action === "delete") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(SponsorActions.deleteSponsor(rowData.id));
                    }
                });
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.CRITERIA) {
            if (action === "edit") {
                const path = userData.role.name ===  CONSTANTS.ROLE_NAME.LIMITED_ADMIN ? '/limited-admin/edit-criteria' : '/admin/edit-criteria';
                dispatch(CriteriaActions.setCriteriaId(rowData.id));
                history.push(path);
            }
            if (action === "delete") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(CriteriaActions.deleteCriteria(rowData.id));
                    }
                });
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.TESTIMONIAL) {
            if (action === "edit") {
                const path = userData.role.name ===  CONSTANTS.ROLE_NAME.LIMITED_ADMIN ? '/limited-admin/edit-testimonial' : '/admin/edit-testimonial';
                dispatch(GuestTestimonialSettingActions.setTestimonialID(rowData.id));
                history.push({
                    state: { id: rowData.id },
                    pathname: path
                });
            }
            if (action === "delete") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(GuestTestimonialSettingActions.deleteTestimonial(rowData.id));
                    }
                });
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.JUDGE_APPLICATION) {
            if (action === "view") {
                if (userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                    await dispatch(FundingApplicationActions.getFundingApplicationById(rowData.fundingApplicationId));
                    history.push("/admin/applications/list-applies");
                } else {
                    history.replace({
                        state: { id: rowData.fundingApplicationId },
                        pathname: "/judge/applications/list-applies"
                    });
                }
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.APPLY_APPLICATION) {
            if (action === "view") {
                await dispatch(AdminOverViewActions.setApplyApplicationId(rowData.id));
                if (userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                    await dispatch(JudgeActions.getApplyApplicationById(rowData.id));
                    history.push("/admin/applications/details");
                } else {
                    history.replace({
                        state: { id: rowData.id, judgeApplicationID: rowData.judgeApplicationID },
                        pathname: "/judge/applications/details"
                    });
                }
            }
            if (action === "evaluation") {
                await dispatch(AdminOverViewActions.setApplyApplicationId(rowData.id));
                await dispatch(JudgeActions.getApplyApplicationById(rowData.id));
                if (userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                    history.push("/admin/evaluation");
                } else {
                    history.push("/judge/evaluation");
                }
            }
            if (action === "report") {
                if (userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                    const param = {
                        applyApplicationId: rowData.id
                    };
                    await dispatch(AdminReportActions.getApplyApplicationById(rowData.id));
                    dispatch(AdminReportActions.getFundingApplicationReports(Utils.convertCamelKeyToSnake(param)));
                    history.push("/admin/report");
                }
            }
            if (action === 'delete' && userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(FundingApplicationActions.deleteApplyApplication(rowData.id, rowData.fundingApplicationId));
                    }
                });
            }
            if (action === 'Recover' && userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                const data = {
                    status: CONSTANTS.APPLY_APPLICATION_STATUS.SUBMITTED.toString()
                };
                dispatch(JudgeActions.changeStatusApplyApplication(data, rowData.id, rowData.fundingApplicationId));
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.USER) {
            if (action === 'view') {
                await dispatch(AdminOverViewActions.setUserId(rowData.id));
                if (userData.role.name === CONSTANTS.ROLE_NAME.LIMITED_ADMIN) {
                    history.push({
                        state: { id: rowData.id },
                        pathname: "/limited-admin/user-details"
                    });
                } else {
                    history.push({
                        state: { id: rowData.id },
                        pathname: "/admin/user-details"
                    });
                }

            }
            if (action === 'Send Message') {
                await dispatch(MessageActions.setUserReceive(rowData.user));
                await history.push("messages");
            }
            if (action === 'Export Information') {
                const data = {
                    userId: rowData.id
                }
                await dispatch(ExportActions.exportUserExcell(data));
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.APPLICATION || tableType === CONSTANTS.TABLE_TYPE.APPLICATION_ADMIN_OVERVIEW) {
            if (action === "edit") {
                await dispatch(FundingApplicationActions.setActionForEditOrViewFunding(action, rowData.id));
                history.push("new-funding-application");
            }
            if (action === "view") {
                if (userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                    await dispatch(FundingApplicationActions.setActionForEditOrViewFunding(action, rowData.id));
                    history.push("funding-application-details");
                } else {
                    await dispatch(FundingApplicationUserActions.getOpenApplicationUserByIDRequestWithRedirect(rowData.id));
                }
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.NOTIFICATION) {
            if (action === "view") {
                dispatch(NotificationActions.updateNotification(rowData.id));
                if (userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.REPORT) {
                        const param = {
                            applyApplicationId: rowData.data_id
                        };
                        await dispatch(AdminReportActions.getApplyApplicationById(rowData.data_id));
                        dispatch(AdminReportActions.getFundingApplicationReports(Utils.convertCamelKeyToSnake(param)));
                        history.push("/admin/report");
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.USER) {
                        await dispatch(AdminOverViewActions.setUserId(rowData.data_id));
                        history.push("/admin/user-details");
                    } else if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.FUNDING_APPLICATION) {
                        await dispatch(FundingApplicationActions.setActionForEditOrViewFunding(action, rowData.data_id));
                        history.push("/admin/funding-application-details");
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.APPLY_APPLICATION) {
                        await dispatch(AdminOverViewActions.setApplyApplicationId(rowData.data_id));
                        await dispatch(JudgeActions.getApplyApplicationById(rowData.data_id));
                        if (userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                            history.push("/admin/applications/details");
                        } else {
                            history.push("/judge/applications/details");
                        }
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.EVALUATION) {
                        await dispatch(AdminOverViewActions.setApplyApplicationId(rowData.data_id));
                        await dispatch(JudgeActions.getApplyApplicationById(rowData.data_id));
                        history.push("/admin/evaluation-apply");
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.NEWS) {
                        await dispatch(NewsActions.setNewsId(rowData.data_id));
                        history.push({
                            state: { id: rowData.data_id },
                            search: `?id=${rowData.data_id}`,
                            pathname: "/homepage/news/detail"
                        });
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.EVENT) {
                        await dispatch(EventActions.setEventId(rowData.data_id));
                        history.push({
                            state: { id: rowData.data_id },
                            search: `?id=${rowData.data_id}`,
                            pathname: "/homepage/events/detail"
                        });
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.LIBRARY) {
                        history.push({
                            state: { id: rowData.data_id },
                            search: `?id=${rowData.data_id}`,
                            pathname: "/homepage/library/book-detail"
                        });
                    }
                }
            }
            if (action === "delete") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(NotificationActions.deleteNotification(rowData.id));
                    }
                });
            }
        }
        if (tableType === CONSTANTS.TABLE_TYPE.NOTIFICATION_USER) {

            if (action === "delete") {
                Utils.popupAlert({
                    type: "warning",
                    title: t("rounderTable.Do you want to delete"),
                    buttons: [t("general.Cancel"), true],
                    dangerMode: true
                }).then((willContinue) => {
                    if (willContinue) {
                        dispatch(NotificationActions.deleteNotification(rowData.id));
                    }
                });
            }
            if (action === "view") {
                await dispatch(NotificationActions.updateNotification(rowData.id));
                if (userData.role.name !== CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.REPORT) {
                        const param = {
                            applyApplicationId: rowData.data_id
                        };
                        dispatch(FundingApplicationUserActions.getReportStatusRequest(Utils.convertCamelKeyToSnake(param)));
                        history.push("user-funding-report");
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.APPLY_APPLICATION) {
                        dispatch(FundingApplicationUserActions.getApplyApplicationByIDRequest(rowData.data_id));
                        history.push("user-funding-detail");
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.USER) {
                        await dispatch(AdminOverViewActions.setUserId(rowData.id));
                        if (userData.role.name === CONSTANTS.ROLE_NAME.LIMITED_ADMIN) {
                            history.push({
                                state: { id: rowData.id },
                                pathname: "/limited-admin/update"
                            });
                        } else {
                            history.push({
                                state: { id: rowData.id },
                                pathname: "/add-view-user"
                            });
                        }
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.MESSAGE) {
                        await dispatch(MessageActions.getBasicInfomation(rowData.data_id));
                        history.push("messages");
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.NEWS) {
                        await dispatch(NewsActions.setNewsId(rowData.data_id));
                        history.push({
                            state: { id: rowData.data_id },
                            search: `?id=${rowData.data_id}`,
                            pathname: "/homepage/news/detail"
                        });
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.EVENT) {
                        await dispatch(EventActions.setEventId(rowData.data_id));
                        history.push({
                            state: { id: rowData.data_id },
                            search: `?id=${rowData.data_id}`,
                            pathname: "/homepage/events/detail"
                        });
                    }
                    if (rowData.table_type === CONSTANTS.NOTIFICATION.TABLE_TYPE.LIBRARY) {
                        history.push({
                            state: { id: rowData.data_id },
                            search: `?id=${rowData.data_id}`,
                            pathname: "/homepage/library/book-detail"
                        });
                    }
                }
            }
        }
    };

    const renderRows = () => {
        const rows = TableUtils.resolvedTableData(initialData, tableType);
        const render = _.map(rows, (row, key) => {
            if (key < showNumberItems) {
                if (row.status === _.findKey(CONSTANTS.APPLY_APPLICATION_STATUS, (v) => { return v === CONSTANTS.APPLY_APPLICATION_STATUS.FAILED; })) {
                    return (
                        <RoundedRow key={key} rowData={row} column={columns} listOptions={{ 4: "Recover" }} callBackFunction={callBackFunction} />
                    );
                }
                if (_.has(row, "is_read") && !row.is_read) {
                    return <RoundedRow key={key} rowData={row} column={columns} listOptions={listOptions} callBackFunction={callBackFunction} className="unread" />;
                }
                return (
                    <RoundedRow key={key} rowData={row} column={columns} listOptions={listOptions} callBackFunction={callBackFunction} />
                );
            }
        });
        return render;
    };

    const renderColumnCell = () => {
        const render = _.map(columns, (column, key) => {
            const listTitleBasic = ['activity', 'type', 'date', 'name'];
            if (column === 'action') {
                return (
                    <ColumnItem key={key} className={column === 'action' ? 'text-right for-action' : 'text-left'}>
                        {t(`rounderTable.${column}`)}
                    </ColumnItem>
                );
            } else if (column.includes('status')) {
                return (
                    <ColumnItem key={key} className={column === 'status' ? '' : 'status'}>
                        {t(`rounderTable.${column}`)}
                    </ColumnItem>
                );
            } else if (column === 'Applicant name') {
                return (
                    <ColumnItem key={key} className={column === 'status' ? '' : 'text-left'}>
                        {t(`rounderTable.${column}`)}
                    </ColumnItem>
                );
            } else if ((column.includes('user name') || column.includes('name')) && column !== "file_name") {
                return (
                    <ColumnItem key={key} className={(column === 'user name' || column === "name") ? 'text-left for-name' : 'status'}>
                        {t(`rounderTable.Name`)}
                    </ColumnItem>
                );
            } else if (column.includes('email')) {
                return (
                    <ColumnItem key={key} className={column === 'email' ? 'text-left for-email' : 'status'}>
                        {t(`rounderTable.${column}`)}
                    </ColumnItem>
                );
            } else if (column.includes('description') || column.includes('title')) {
                return (
                    <ColumnItem key={key} className='text-left'>
                        {t(`rounderTable.${column}`)}
                    </ColumnItem>
                );
            } else {
                return (
                    <ColumnItem key={key} className={listTitleBasic.includes(column) ? 'text-left' : ''}>
                        {t(`rounderTable.${column}`)}
                    </ColumnItem>
                );
            }
        });
        return render;
    };

    const handleClick = () => {
        props.handleClick();
    };

    return (
        <TableWrapper className={className}>
            <HorizontalContainer className={hideTitleHeader ? 'd-none' : ''}>
                <TableTitle>
                    {tableTitle}
                </TableTitle>
            </HorizontalContainer>
            <TableContentWrapper className={isExpand ? 'expand' : 'collapse'}>
                <TableContainer className={hideTitleHeader ? "mt-0" : ''}>
                    <TableColumn>
                        {_.isEmpty(initialData)
                            && <tr><td>{t("general.No Data Found")}</td></tr>
                        }
                        {!_.isEmpty(initialData)
                            && (
                                <tr>
                                    {renderColumnCell()}
                                </tr>
                            )
                        }
                    </TableColumn>
                    <TableBody>
                        {renderRows()}
                    </TableBody>
                </TableContainer>
            </TableContentWrapper>
            {
                !_.isEmpty(initialData) && initialData.length > showNumberItems
                && (
                    <ButtonWrapper>
                        <Button
                            background={ColorName.chathamsBlueDark}
                            title={buttonTilte}
                            width='10em'
                            height='35px'
                            className="mt-2"
                            onClick={() => handleClick()}
                        />
                    </ButtonWrapper>
                )
            }

        </TableWrapper>
    );
};

RoundedTable.propTypes = {
    initialData: PropTypes.array,
    columns: PropTypes.array,
    tableTitle: PropTypes.string,
    tableType: PropTypes.string,
    className: PropTypes.string,
    isExpand: PropTypes.bool,
    showExpandButton: PropTypes.bool,
    hideTitleHeader: PropTypes.bool,
    listOptions: PropTypes.object,
    handleClick: PropTypes.func,
    buttonTilte: PropTypes.string,
    showNumberItems: PropTypes.number
};

export default RoundedTable;
