import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    userRoles: [],
    linkRegister: ""
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.USER_REGISTER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false
            };
        case ActionTypes.USER_REGISTER_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false
            };
        case ActionTypes.USER_REGISTER_REQUEST:
            return {
                ...state,
                isLoading: true,
                hasError: false,
                isSuccess: false
            };
        case ActionTypes.USER_REGISTER_SET_USER_ROLE:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                hasError: false,
                userRoles: payload
            };
        case ActionTypes.GENERATE_LINK_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                hasError: false,
                linkRegister: payload
            };
            
        default:
            return state;
    }
};
