import React from "react";
import PropTypes from "prop-types";
import {
    TextAreaComponent
} from "./TextArea.styles";

const TextArea = (props) => {
    const {
        defaultValue,
        width,
        height,
        margin,
        onChange,
        cursor,
        disabled,
        backgroundColor
    } = props;

    return (
        <TextAreaComponent
            defaultValue={defaultValue}
            width={width}
            height={height}
            margin={margin}
            onChange={onChange}
            cursor={cursor}
            disabled={disabled}
            backgroundColor={backgroundColor}
        />
    );
};

TextArea.propTypes = {
    defaultValue: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    margin: PropTypes.string,
    cursor: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    backgroundColor: PropTypes.string
};

export default TextArea;
