import Api from "./Base";

export function getApplyApplication(data) {
    return Api.get("apply_application_fetch", data);
}

export function getFundingUserReport(data) {
    return Api.get("user_report_funding", data);
}

export function getOpenApplicationUser(data) {
    return Api.get("funding_applications", data);
}

export function getOpenApplicationUserByID(id, data) {
    return Api.get(`funding_applications/${id}`, data);
}

export function getApplyApplicationByID(id, data) {
    return Api.get(`apply_application/${id}`, data);
}

export function getReportStatus(data) {
    return Api.get("report_statuses", data);
}

export function updateReportStatus(id, data) {
    return Api.postFormData(`report_statuses/${id}/update`, data);
}

export function fetchFundingApplicationByID(id, data) {
    return Api.get(`funding_applications/${id}`, data);
}

export function submitApplyApplication(data) {
    return Api.postFormData("apply_application", data);
}

export function updateApplyApplicationDetail(id, data) {
    return Api.postFormData(`apply_application/${id}/update_detail`, data);
}

export function updateApplyApplicationDetailWinner(id, data) {
    return Api.postFormData(`apply_application/${id}/upload_document_winner`, data);
}
