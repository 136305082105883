import { setToken, getToken } from "./Base";
import { login } from "./AuthApi";
import {
  getMessage,
  addMessage,
  getListUsers,
  getChannel,
  getListUserMessage,
  getBasicInfomation,
  deleteChannel,
  getListUserMessageAdmin,
  updateReadedMessage,
  getUnreadMessage,
  getListUsersAdmin
} from "./Message";
import { requestResetPassword, requestChangePassword } from "./ResetPassword";
import {
  getNotifications,
  getUserExplore,
  getUserExploreById,
  updateUserOverview,
  createUserExplore,
  getRolesName,
  userReport,
  getListAdmin,
  getUsersForMessage,
} from "./UserOverview";
import {
  addNewUser,
  getAllUsers,
  getUser,
  updateUser,
  confirmEmail,
  changePassword,
  changePasswordUser,
} from "./User";
import {
  userRegister,
  getUserRoles,
  limitedUserRegister,
  generateLink,
} from "./UserRegister";
import {
  getApplicationsJudge,
  getApplicationsJudgeById,
  getApplyApplicationById,
  changeStatusApplyApplication,
  getEvaluationQuestionnaire,
  saveEvaluation,
  getEvaluationById,
  updateEvaluation,
} from "./Judge";
import {
  createNewFundingApplication,
  getFundingApplication,
  getListFundingApplication,
  getFundingApplicationById,
  editFundingApplication,
  getListJudge,
  addJudgeToApplication,
  removeJudge,
  getListFundingApplicationLimited,
  getListFundingApplicationAdmin,
  getFundingApplicationDashboard,
  deleteDocumentWinner,
} from "./FundingApplicationAPI";
import {
  getApplyApplication,
  getOpenApplicationUser,
  getFundingUserReport,
  getOpenApplicationUserByID,
  getApplyApplicationByID,
  getReportStatus,
  updateReportStatus,
  fetchFundingApplicationByID,
  submitApplyApplication,
  updateApplyApplicationDetail,
  updateApplyApplicationDetailWinner,
} from "./FundingApplicationsUser";
import {
  reportUsers,
  getUserDetailsByID,
  getRoles,
  updateRoleForUser,
  getActivitiesOverview,
  getEvaluationApply,
  addLimitedUser,
  getAllActivitiesOverview,
  getAllActivitiesForDropdown
} from "./AdminOverview";
import {
  reportApplyApplcation,
  deleteApplyApplication,
} from "./AdminFundingApplicationReport";
import { updateNotification, deleteNotification } from "./Notifications";
import { search, searchHomepage } from "./Search";
import { fetchCommunity } from "./Community";
import {
  addNews,
  getNewsById,
  getAllNews,
  updateNews,
  deleteNews,
  getNewsSlide,
  sendMailNews,
} from "./News";

import {
  addEvent,
  getEventById,
  getAllEvent,
  updateEvent,
  deleteEvent,
  getEventSlide,
  sendMailEvents,
} from "./Event";

import {
  fetchAllLibrary,
  getLibraryDataByID,
  createNewBook,
  deleteBook,
  updateBook,
  searchLibrary,
} from "./Library";

import {
  addMetaData,
  getMetaData,
  updateMetaData,
  updateMetaDataWithForm,
} from "./MetaData";

import {
  addTestimonial,
  updateTestimonial,
  deleteTestimonial,
  fetchTestimonials,
  getTestimonialByID,
} from "./Testimonial";

import {
  addSponsor,
  getSponsorById,
  getAllSponsor,
  updateSponsor,
  deleteSponsor,
} from "./Sponsor";

import {
  addCriteria,
  getCriteriaById,
  getAllCriteria,
  updateCriteria,
  deleteCriteria,
} from "./Criteria";

import {
  addDocumentTemplate,
  getDocumentTemplate,
  updateDocumentTemplate,
  fetchDocumentTemplate,
  deleteDocumentTemplate,
} from "./DocumentTemplate";

import { exportExcell, exportUserExcell, exportAllUserExcell } from "./Export";

import { subscribe, unsubscribe } from "./Subscribe";

export default {
  // Authentication
  setToken,
  getToken,
  login,
  // Register & Update User
  requestResetPassword,
  requestChangePassword,
  addNewUser,
  getAllUsers,
  getUser,
  updateUser,
  confirmEmail,
  changePassword,
  changePasswordUser,
  // Chat Realtime Socket
  getMessage,
  addMessage,
  getListUsers,
  getChannel,
  getListUserMessage,
  getBasicInfomation,
  deleteChannel,
  getListUserMessageAdmin,
  getListAdmin,
  updateReadedMessage,
  getUnreadMessage,
  getListUsersAdmin,
  // UserOverview
  getNotifications,
  getUserExplore,
  getUserExploreById,
  updateUserOverview,
  createUserExplore,
  getRolesName,
  userReport,
  getUsersForMessage,
  // FundingApplication
  createNewFundingApplication,
  getFundingApplication,
  getListFundingApplication,
  editFundingApplication,
  getListJudge,
  addJudgeToApplication,
  removeJudge,
  getFundingApplicationDashboard,
  deleteDocumentWinner,
  // FundingApplicationUser
  getApplyApplication,
  getFundingUserReport,
  getOpenApplicationUser,
  getOpenApplicationUserByID,
  getApplyApplicationByID,
  getReportStatus,
  updateReportStatus,
  fetchFundingApplicationByID,
  submitApplyApplication,
  updateApplyApplicationDetail,
  getListFundingApplicationLimited,
  updateApplyApplicationDetailWinner,
  getListFundingApplicationAdmin,
  // Admin overview
  getUserDetailsByID,
  reportUsers,
  getRoles,
  updateRoleForUser,
  getActivitiesOverview,
  getFundingApplicationById,
  getEvaluationApply,
  addLimitedUser,
  getAllActivitiesOverview,
  getAllActivitiesForDropdown,
  // Judge
  getApplicationsJudge,
  getApplicationsJudgeById,
  getApplyApplicationById,
  changeStatusApplyApplication,
  getEvaluationQuestionnaire,
  saveEvaluation,
  getEvaluationById,
  updateEvaluation,
  // Admin Report Apply Application
  reportApplyApplcation,
  deleteApplyApplication,
  // Notifications
  updateNotification,
  deleteNotification,
  // Search
  search,
  searchHomepage,
  // User register
  userRegister,
  getUserRoles,
  limitedUserRegister,
  generateLink,
  // News
  addNews,
  getNewsById,
  getAllNews,
  updateNews,
  deleteNews,
  getNewsSlide,
  sendMailNews,
  // Event
  addEvent,
  getEventById,
  getAllEvent,
  updateEvent,
  deleteEvent,
  getEventSlide,
  sendMailEvents,
  // Community
  fetchCommunity,
  // Library
  fetchAllLibrary,
  getLibraryDataByID,
  createNewBook,
  deleteBook,
  updateBook,
  searchLibrary,
  // Process
  addMetaData,
  getMetaData,
  updateMetaData,
  updateMetaDataWithForm,
  addTestimonial,
  updateTestimonial,
  deleteTestimonial,
  fetchTestimonials,
  getTestimonialByID,
  // Sponsor
  addSponsor,
  getSponsorById,
  getAllSponsor,
  updateSponsor,
  deleteSponsor,
  // Document Template
  addDocumentTemplate,
  getDocumentTemplate,
  updateDocumentTemplate,
  fetchDocumentTemplate,
  deleteDocumentTemplate,
  // Criteria
  addCriteria,
  getCriteriaById,
  getAllCriteria,
  updateCriteria,
  deleteCriteria,
  // Export
  exportExcell,
  exportUserExcell,
  exportAllUserExcell,
  // Subscribe
  subscribe,
  unsubscribe,
};
