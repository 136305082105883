import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    WrapperInput,
    TitleInput,
    DropDownSelect
} from "./LimitedUser.styles";
import {
    PageTitle,
    GeneralButton
} from "../../../../components/Common/CommonLayout";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import AdminOverViewAction from '../../../../actions/AdminOverView';
import UserRegister from '../../../../actions/UserRegister';
import { ColorName } from "../../../../components/Variables";
import Validation from "../../../../libs/Validation";
import Constants from "../../../../constants/Constants";

const GenerateLink = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const listFundingApplication = useSelector((state) => state.FundingApplication.allFundingData);
    const linkRegister = useSelector((state) => state.UserRegister.linkRegister);
    const isLoading = useSelector((state) => state.AdminOverView.isLoading);
    const [fundingId, setFundingId] = useState("");

    useEffect(() => {
        const data = {
            category: Constants.FUNDING_CATEGORY.LIMITED_USER
        };
        dispatch(AdminOverViewAction.getListFundingApplicationLimited(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderOption = (item, index) => {
        return <option value={item.id} key={index}>{item.title}</option>;
    };

    const renderDropdownFundingApplication = () => {
        if (listFundingApplication) {
            const render = _.map(listFundingApplication, (item, index) => {
                return renderOption(item, index);
            });
            return render;
        }
    };

    const generateLink = () => {
        const validationRules = [
            {
                name: t("general.Funding Applications"),
                value: fundingId,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        const data = {
            funding_id: fundingId
        };
        dispatch(UserRegister.generateLink(data));
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={false}
            page="limited-user"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("general.Generate Link")}
                    </PageTitle>
                    <WrapperInput>
                        <TitleInput>{t("general.Funding Applications")}</TitleInput>
                    </WrapperInput>
                    <div className="container-button">
                        <DropDownSelect onChange={(e) => setFundingId(e.target.value)}>
                            <option>
                                {t("fundingApplications.Please Choose Funding Opportunities")}
...
                            </option>
                            {renderDropdownFundingApplication()}
                        </DropDownSelect>
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("general.Generate")}
                            onClick={() => generateLink()}
                        />
                    </div>
                    <div className="container-button">
                        {linkRegister && (
                            <div className="for-link">
                                <TitleInput>
                                    {t("general.Link")}
:
                                </TitleInput>
                                <div className="link-register">{linkRegister}</div>
                            </div>
                        )}
                    </div>
                </BodyContainer>
            )}
        />
    );
};

export default GenerateLink;
