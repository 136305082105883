import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    communityData: []
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.PAGE_COMMUNITY_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                communityData: payload
            };
        case ActionTypes.PAGE_COMMUNITY_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false
            };
        case ActionTypes.PAGE_COMMUNITY_REQUEST:
            return {
                ...state,
                isLoading: true,
                hasError: false,
                isSuccess: false
            };
        default:
            return state;
    }
};
