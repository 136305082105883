import styled from 'styled-components';
import { ColorName } from '../../Variables';
import { Images } from '../../../themes';
const RightArrowButton = styled.div`
    display: block;
    margin: 30px auto;
    width: 25px;
    height: 25px;
    border-top: 2px solid #2D4567;
    border-left: 2px solid #2D4567;
    transform: rotate(135deg);
    &:before {
        content: '';
    }
`;

const LeftArrowButton = styled.div`
    display: block;
    margin: 30px auto;
    width: 25px;
    height: 25px;
    border-top: 2px solid #2D4567;
    border-left: 2px solid #2D4567;
    transform: rotate(-45deg);
    
    &:before {
        content: '';
    }
`;

const ItemCardLayout = styled.div`
    cursor: pointer;
    background-color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    .item-avatar {
        text-align: center;
        img {
            width: 100% !important;
            object-fit: scale-down;
            height: 160px;
        }
    }
    outline: none;
    .item-username {
        padding: 1em 1em 0 1em;
        font-weight: 300;
        color: ${ColorName.chathamsBlueLighter};
    }
    .item-title {
        .insde-title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
        }
        padding: 0.5em 0;
        font-weight: 600;
        color: black;
        height: 90px;
        line-height: 1.5;
        .description{
            margin-top: 0;
            color: gray;
            font-weight: 500;
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .item-description {
        color: ${ColorName.chathamsBlueLighter};
        font-size: 1.2em;
        line-height: 1.4em;
        font-weight: 300;
        padding: 1.5em 0.8em 1.2em 0.8em;
        background-image: url(${Images.sliderItem});
        background-position: -2px -33px;
        background-repeat: no-repeat;
    }

    .item-footer {
        display: flex;
        padding: 0;
    }
    
    .read-more {
        cursor: pointer;
        font-size: 15px;
    }
    
    .read-more-line {
        width: 15px;
        height: 1px;
        background: ${ColorName.chathamsBlueDark};
    }
    
    .date-created {
        color: gray;
        font-size: 14px;
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
`;

const SliderContainer = styled.div`
    .slick-list{
        max-height: 400px;
        padding: 4em 0 4em 0;
    }

    .slick-slide {
        padding: 0 1em;
        box-sizing: border-box;
        max-width: 277px;
    }
    .slick-next, .slick-prev {
        top: 40%;
    }
`;

export {
    RightArrowButton,
    LeftArrowButton,
    ItemCardLayout,
    SliderContainer
};
