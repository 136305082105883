import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
    MainContent, BookList, Book, NotFoundData
} from "./Library.styles";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import Banner from "../../../components/Common/Banner";
import SummryOverview from "../../../components/Common/SummaryOverview";
import Pagination from "../../../components/Common/NewPagination";
import { Images } from "../../../themes";
import history from "../../../history";
import LibraryActions from "../../../actions/Library";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import Constants from "../../../constants/Constants";
import Utils from "../../../libs/Utils";

const Library = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const allData = useSelector((state) => state.Library.allLibraryData);
    const isLoading = useSelector((state) => state.Library.isLoading);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const language = Utils.getCurrentLanguage();
    useEffect(() => {
        const param = {
            page,
            item_per_page: Constants.ITEM_PER_PAGE,
            language
        };
        dispatch(LibraryActions.fetchLibraryData(param));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickBook = (id) => {
        history.push({
            state: { id },
            search: `?id=${id}`,
            pathname: "/homepage/library/book-detail"
        });
    };

    const _renderBookList = () => {
        const render = _.map(allData.data, (data) => {
            if (!data.is_incoming) {
                return (
                    <Book
                        onClick={() => handleClickBook(data.id)}
                        key={data.id}
                    >
                        <img alt="book" src={data.url_cover_img} />
                        <b>{language === "en" ? data.title ? data.title : data.title_json.al : data.title ? data.title : data.title_json.en}</b>
                    </Book>
                );
            } else {
                return (
                    <Book key={data.id}>
                        <img alt="book" src={data.url_cover_img} />
                        <img
                            alt="comming soon"
                            className="comming-soon"
                            src="https://d288gf2k0wd772.cloudfront.net/static/img/icons/education-center/coming-soon-placeholder.f50a52494f59.png"
                        />
                        <b>{language === "en" ? data.title ? data.title : data.title_json.al : data.title ? data.title : data.title_json.en}</b>
                    </Book>
                );
            }
        });
        return render;
    };

    useEffect(() => {
        setPage(allData.page);
        setLastPage(allData.lastPage);
    }, [allData]);

    const clickPageOrLink = (currentPage) => {
        const data = {
            page: currentPage,
            item_per_page: Constants.ITEM_PER_PAGE
        };
        dispatch(LibraryActions.fetchLibraryData(data));
    };

    const callBackPrev = () => {
        setPage(page - 1);
        clickPageOrLink(page - 1);
    };

    const callBackNext = () => {
        setPage(page + 1);
        clickPageOrLink(page + 1);
    };

    const pageOnclick = (e) => {
        clickPageOrLink(_.parseInt(e));
    };

    const _renderPagination = () => {
        return (
            <Pagination
                page={page}
                lastPage={lastPage || 1}
                callBackPrev={callBackPrev}
                callBackNext={callBackNext}
                pageOnclick={pageOnclick}
            />
        );
    };

    const renderMain = () => {
        return (
            <MainContent>
                {isLoading && <Loading />}
                <Banner type="library" backgroundImg={Images.bannerLibrary} />
                <BookList>
                    {(allData.data && !_.isEmpty(allData.data)) && _renderBookList()}
                    {(allData.data && _.isEmpty(allData.data)) && (
                        <NotFoundData>{t("library.No Book found in the moment, please comeback later")}</NotFoundData>
                    )}
                </BookList>
                {_renderPagination()}
                <SummryOverview />
            </MainContent>
        );
    };

    return <DefaultLayout context={renderMain()} currentPage="library" />;
};

export default Library;
