import React, { useState, useEffect } from "react";
import Cropper from 'react-easy-crop';
import SimpleModal from "../SimpleModal";
import Slider from '@material-ui/core/Slider';
import {
    EditorControl
} from "./ImageEditor.styles"

const ImageEditor = (props) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [aspect, setAspect] = useState(4/3); // eslint-disable-line
    const [cropSize, setCropSize] = useState({width: 300, height: 225}); // eslint-disable-line
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null); // eslint-disable-line
    const [showImageEditor, setShowImageEditor] = useState(false);

    useEffect(() => {
        setImageSrc(props.imageSrc);
        setShowImageEditor(props.showImageEditor);
    }, [props]);

    const onCropChange = (crop) => {
        setCrop(crop);
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const onZoomChange = (zoom) => {
        setZoom(zoom)
    }

    const createImage = url =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', error => reject(error))
            image.setAttribute('crossOrigin', '*') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })

        /**
        * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
        * @param {File} image - Image File url
        * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
        */
        const getCroppedImg = async (imageSrc, pixelCrop) => {
            const image = await createImage(imageSrc)
            const canvas = document.createElement('canvas')
            canvas.width = pixelCrop.width
            canvas.height = pixelCrop.height
            const ctx = canvas.getContext('2d')

            ctx.fillStyle = '#ffffff'
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
            )

            // As Base64 string
            return canvas.toDataURL('image/jpeg');
        }

    const showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels
        )
        props.croppedImageEditorCallback({callbackType: "image", croppedImage: croppedImage})
        setCroppedImage({ croppedImage })
    }

    const onSubmitModalHandler = async () => {
        await showCroppedImage();
    }

    const onClose = () => {
        setCroppedImage(null)
        props.croppedImageEditorCallback({callbackType: "close"})
    }

    const renderCropper = () => {
        return (
            <div>
                {imageSrc && (
                    <>
                        <Cropper
                            minZoom={0.4}
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            onCropChange={onCropChange}
                            onCropComplete={onCropComplete}
                            onZoomChange={onZoomChange}
                            restrictPosition={false}
                            zoomWithScroll={false}
                            zoomSpeed={0.5}
                            onMediaLoaded={(imageSize) => {
                                // setCrop({x: 0, y: 0})
                            }}
                        />
                        <EditorControl>
                            <Slider
                                value={zoom}
                                min={0.4}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => onZoomChange(zoom)}
                            />
                        </EditorControl>
                    </>
                )}
            </div>
        )
    }

    return (
        <div>
            {(imageSrc && showImageEditor) &&(
                <SimpleModal onClose={onClose} showImageEditor={showImageEditor} content={renderCropper()} submitCallback={() => onSubmitModalHandler()}/>
            )}
        </div>
    )
}

export default ImageEditor;