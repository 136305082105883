import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import MessageAction from "../../../../actions/Message";
import UserOverviewActions from "../../../../actions/UserOverview";
import Chat from "./Chat";
import {
  BodyContainer,
  UserWrapper,
  MessageHorizontalContainer,
  Username,
} from "./Message.styles";
import Avatar from "../../../Common/Avatar";
import Utils from "../../../../libs/Utils";
import Constants from "../../../../constants/Constants";

const Message = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const messageStorage = useSelector((state) => state.Message);
  const UserExploreStorage = useSelector((state) => state.UserOverview);
  const channelId = useSelector((state) => state.Message.channel);
  const userReceive = useSelector((state) => state.Message.userReceive);
  const userSend = Utils.getSavedUserData();
  const [userReceiveData, setUserReceiveData] = useState({});
  const [listUser, setListUser] = useState([]);
  const [newChat, setNewChat] = useState(false);
  const [edit, setEdit] = useState(false);
  const [listUserExplore, setListUserExplore] = useState(false);
  const userData = Utils.getSavedUserData();
  const chatWith = async (user) => {
    const data = {
      user_id_receive: user.id,
      user_id_send: userSend.id,
    };
    dispatch(MessageAction.getChannel(data));
    dispatch(
      MessageAction.updateReadedMessage(userSend.id, {
        user_id_receive: user.id,
      })
    );
    dispatch(MessageAction.getUnreadMessage(userSend.id));
    await setUserReceiveData(user);
    await setNewChat(false);
    await setEdit(false);
  };

  useEffect(() => {
    if (!_.isEmpty(userReceive)) setUserReceiveData(userReceive);
    if (!_.isEmpty(userReceive)) chatWith(userReceive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReceive]);

  useEffect(() => {
    dispatch(MessageAction.getListUserMessage());
    if (userData.role.name === Constants.ROLE_NAME.LIMITED_USER)
      return dispatch(
        UserOverviewActions.getListUserMessageByRole({
          roles: [
            Constants.ROLE_NAME.SUPER_ADMIN,
            Constants.ROLE_NAME.CONSULTANT,
            Constants.ROLE_NAME.INVESTOR,
            Constants.ROLE_NAME.ENTREPRENEUR,
          ],
          page: 1,
          item_per_page: 20,
        })
      );
    else if (userData.role.name === Constants.ROLE_NAME.ENTREPRENEUR)
      return dispatch(
        UserOverviewActions.getListUserMessageByRole({
          roles: [
            Constants.ROLE_NAME.CONSULTANT,
            Constants.ROLE_NAME.INVESTOR,
            Constants.ROLE_NAME.ENTREPRENEUR,
            Constants.ROLE_NAME.JUDGE,
          ],
          page: 1,
          item_per_page: 20,
        })
      );
    else if (userData.role.name === Constants.ROLE_NAME.JUDGE)
      return dispatch(
        UserOverviewActions.getListUserMessageByRole({
          roles: [
            Constants.ROLE_NAME.SUPER_ADMIN,
            Constants.ROLE_NAME.LIMITED_USER,
            Constants.ROLE_NAME.ENTREPRENEUR,
          ],
          page: 1,
          item_per_page: 20,
        })
      );
    else if (
      userData.role.name === Constants.ROLE_NAME.SUPER_ADMIN
    )
      return dispatch(
        UserOverviewActions.getListUserMessageByRole({
          roles: [
            Constants.ROLE_NAME.SUPER_ADMIN,
            Constants.ROLE_NAME.LIMITED_USER,
            Constants.ROLE_NAME.ENTREPRENEUR,
            Constants.ROLE_NAME.LIMITED_ADMIN,
            Constants.ROLE_NAME.JUDGE,
          ],
          page: 1,
          item_per_page: 20,
        })
      );
    else if (
      userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN
    )
      return dispatch(
        UserOverviewActions.getListUserMessageByRole({
          roles: [
            Constants.ROLE_NAME.SUPER_ADMIN,
            Constants.ROLE_NAME.CONSULTANT,
            Constants.ROLE_NAME.INVESTOR,
            Constants.ROLE_NAME.ENTREPRENEUR,
            Constants.ROLE_NAME.LIMITED_ADMIN,
          ],
          page: 1,
          item_per_page: 20,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setListUserExplore(UserExploreStorage.usersForMessage.data);
  }, [UserExploreStorage]);

  useEffect(() => {
    setListUser(messageStorage.listUsers);
  }, [messageStorage.listUsers]);

  useEffect(() => {

  }, [setNewChat]);

  const getFullName = (userData) => {
    if (userData) return `${userData.first_name} ${userData.last_name}`;
  };

  const deleteChanel = (id) => {
    Utils.popupAlert({
      type: "warning",
      title: t("rounderTable.Do you want to delete"),
      buttons: [t("general.Cancel"), true],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        dispatch(MessageAction.deleteChannel(id));
      }
    });
  };

  const renderListUsers = () => {
    if (listUser) {
      const render = _.map(listUser, (user, index) => {
        if (user.userSend.id !== user.userReceive.id) {
          if (!edit) {
            return (
              <UserWrapper
                className={
                  user.userSend.id === userReceiveData.id ||
                    user.userReceive.id === userReceiveData.id
                    ? "selectChat"
                    : "noneSelect"
                }
                key={index}
                onClick={
                  userSend.id === user.userSend.id
                    ? () => chatWith(user.userReceive)
                    : () => chatWith(user.userSend)
                }
              >
                {userSend.id === user.userSend.id && (
                  <Avatar userData={user.userReceive} />
                )}
                {userSend.id !== user.userSend.id && (
                  <Avatar userData={user.userSend} />
                )}
                <Username>
                  {userSend.id === user.userSend.id
                    ? Utils.truncateText(
                      getFullName(user.userReceive.userData),
                      20
                    )
                    : Utils.truncateText(
                      getFullName(user.userSend.userData),
                      20
                    )}
                </Username>
              </UserWrapper>
            );
          } else {
            return (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <UserWrapper
                className={
                  user.userSend.id === userReceiveData.id ||
                    user.userReceive.id === userReceiveData.id
                    ? "selectChat"
                    : "noneSelect"
                }
                key={index}
              >
                {userSend.id === user.userSend.id && (
                  <Avatar userData={user.userReceive} />
                )}
                {userSend.id !== user.userSend.id && (
                  <Avatar userData={user.userSend} />
                )}
                <Username>
                  {userSend.id === user.userSend.id
                    ? Utils.truncateText(getFullName(user.userReceive.userData))
                    : Utils.truncateText(getFullName(user.userSend.userData))}
                </Username>
                {edit && (
                  <span
                    className="icon-delete"
                    onClick={() => deleteChanel(user.id)}
                  >
                    X
                  </span>
                )}
              </UserWrapper>
            );
          }
        }
      });
      return _.compact(render);
    }
  };

  const renderListUsersExplore = () => {
    const render = _.map(listUserExplore, (user, index) => {
      if (user.id !== userSend.id) {
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <UserWrapper key={index} onClick={() => chatWith(user)}>
            <Avatar userData={user} />
            <Username>
              {Utils.truncateText(getFullName(user.userData))}
            </Username>
          </UserWrapper>
        );
      }
    });
    return _.compact(render);
  };

  const editChatChanel = () => {
    setEdit(!edit);
  };

  const filterListUser = (e) => {
    const filter = e.target.value;
    if (userData.role.name === Constants.ROLE_NAME.LIMITED_USER)
      return dispatch(
        UserOverviewActions.getListUserMessageByRole({
          roles: [Constants.ROLE_NAME.SUPER_ADMIN, Constants.ROLE_NAME.JUDGE],
          page: 1,
          item_per_page: 20,
          keyword: filter,
        })
      );
    else if (userData.role.name === Constants.ROLE_NAME.ENTREPRENEUR)
      return dispatch(
        UserOverviewActions.getListUserMessageByRole({
          roles: [
            Constants.ROLE_NAME.CONSULTANT,
            Constants.ROLE_NAME.INVESTOR,
            Constants.ROLE_NAME.ENTREPRENEUR,
            Constants.ROLE_NAME.JUDGE,
          ],
          page: 1,
          item_per_page: 20,
          keyword: filter,
        })
      );
    else if (userData.role.name === Constants.ROLE_NAME.JUDGE)
      return dispatch(
        UserOverviewActions.getListUserMessageByRole({
          roles: [
            Constants.ROLE_NAME.SUPER_ADMIN,
            Constants.ROLE_NAME.LIMITED_USER,
            Constants.ROLE_NAME.ENTREPRENEUR,
          ],
          page: 1,
          item_per_page: 20,
          keyword: filter,
        })
      );
    else if (
      userData.role.name === Constants.ROLE_NAME.SUPER_ADMIN ||
      userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN
    )
      return dispatch(
        UserOverviewActions.getListUserMessageByRole({
          roles: [
            Constants.ROLE_NAME.SUPER_ADMIN,
            Constants.ROLE_NAME.LIMITED_USER,
            Constants.ROLE_NAME.ENTREPRENEUR,
            Constants.ROLE_NAME.LIMITED_ADMIN,
            Constants.ROLE_NAME.JUDGE,
          ],
          page: 1,
          item_per_page: 20,
          keyword: filter,
        })
      );

    let listArrayUser = _.get(
      UserExploreStorage.usersForMessage,
      "data"
    ).filter(
      (o) =>
        o.userData.full_name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
    return setListUserExplore(listArrayUser);
  };

  const _renderFilter = () => {
    return (
      <div className="container-filter">
        <input
          type="text"
          placeholder={t("homepageUser.Search")}
          onChange={(e) => filterListUser(e)}
        />
        <i className="fa fa-search" />
      </div>
    );
  };

  const addNewChat = () => {
    setNewChat(!newChat);
    setEdit(false);
  };

  return (
    <MessageHorizontalContainer>
      <div>
        <BodyContainer className="listUsers">
          <div className="top-list-user">
            <span className="cursor " onClick={() => addNewChat()}>
              {!newChat && (
                <>
                  <i className="fa fa-search" />{" "}
                </>
              )}
              {!!newChat && (
                <>
                  <i className="fa fa-arrow-left" />{" "}
                </>
              )}
            </span>
            <span
              onClick={!newChat ? () => editChatChanel() : null}
              className={!newChat ? "cursor" : ""}
            >
              {!newChat ? t("general.Edit") : _renderFilter()}
            </span>
          </div>
          <div className="container-body-list">
            {messageStorage.listUsers && !newChat && renderListUsers()}
            {newChat && renderListUsersExplore()}
            {_.isEmpty(messageStorage.listUsers) && (
              <div className="notFound">{t("general.No Data Found")}</div>
            )}
          </div>
        </BodyContainer>
      </div>
      <div className="container-with-title">
        <BodyContainer className="view-chat">
          {!_.isEmpty(userReceiveData) && (
            <Chat channelId={channelId} userReceiveData={userReceiveData} />
          )}
        </BodyContainer>
      </div>
    </MessageHorizontalContainer>
  );
};

export default Message;
