import _ from "lodash";
import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";
import Constants from "../constants/Constants";

const requestHasError = () => {
    return {
        type: ActionTypes.FUNDING_APPLICATION_USER_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.FUNDING_APPLICATION_USER_REQUEST
    };
};

const getApplyApplication = (payload) => {
    return {
        type: ActionTypes.GET_APPLY_APPLICATION,
        payload
    };
};

const getApplyApplicationRequest = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getApplyApplication(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getApplyApplication(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getFundingUserReport = (payload) => {
    return {
        type: ActionTypes.GET_FUNDING_USER_REPORT,
        payload
    };
};

const getFundingUserReportRequest = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getFundingUserReport(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getFundingUserReport(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getOpenApplicationUser = (payload) => {
    return {
        type: ActionTypes.GET_OPEN_APPLICATION_USER,
        payload
    };
};

const getOpenApplicationUserRequest = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getOpenApplicationUser(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getOpenApplicationUser(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getOpenApplicationUserByID = (payload) => {
    return {
        type: ActionTypes.GET_OPEN_APPLICATION_USER_BY_ID,
        payload
    };
};

const getOpenApplicationUserByIDRequest = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getOpenApplicationUserByID(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getOpenApplicationUserByID(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};
const getOpenApplicationUserByIDRequestWithRedirect = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getOpenApplicationUserByID(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getOpenApplicationUserByID(res.data.data));
                    history.push('user-applications-detail');
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getApplyApplicationByID = (payload) => {
    return {
        type: ActionTypes.GET_APPLY_APPLICATION_BY_ID,
        payload
    };
};

const getApplyApplicationByIDRequest = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getApplyApplicationByID(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getApplyApplicationByID(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};
const getApplyApplicationByIDRequestWithRedirect = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getApplyApplicationByID(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getApplyApplicationByID(res.data.data));
                    history.push("user-funding-detail");
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getReportStatus = (payload, id) => {
    return {
        type: ActionTypes.GET_REPORT_STATUS,
        payload,
        id
    };
};

const getReportStatusRequest = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getReportStatus(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getReportStatus(res.data.data, id));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateReportStatus = (payload) => {
    return {
        type: ActionTypes.SUBMIT_REPORT_STATUS,
        payload
    };
};

const updateReportStatusRequest = (id, data, applyApplicationId) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateReportStatus(id, data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(updateReportStatus(res.data.data));
                    Utils.popupAlert({ text: res.data.message, type: "success" })
                        .then((isSuccess) => {
                            if (isSuccess) {
                                dispatch(getReportStatusRequest(applyApplicationId));
                            }
                        });
                } else {
                    dispatch(requestHasError());
                    const messageError = _.map(res.data, 'message').toString();
                    Utils.popupAlert({ title: "Oops...", text: messageError, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getFundingApplicationByID = (payload) => {
    return {
        type: ActionTypes.GET_FUNDING_APPLY_APPLICATION_BY_ID,
        payload
    };
};

const getFundingApplicationByIDRequest = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.fetchFundingApplicationByID(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getFundingApplicationByID(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const submitApplyApplication = (payload) => {
    return {
        type: ActionTypes.SUBMIT_APPLY_APPLICATION,
        payload
    };
};

const submitApplyApplicationRequest = (data) => {
    const userData = Utils.getSavedUserData();
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.submitApplyApplication(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(submitApplyApplication(res.data.data));
                    Utils.popupAlert({ text: res.data.message, type: "success" })
                        .then((isSuccess) => {
                            if (isSuccess && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
                                history.push("user-funding");
                            } else {
                                history.push("user-overview");
                            }
                        });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateApplyApplicationDetail = (payload) => {
    return {
        type: ActionTypes.UPDATE_APPLY_APPLICATION_DETAIL,
        payload
    };
};

const updateApplyApplicationDetailRequest = (id, data) => {
    const userData = Utils.getSavedUserData();
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateApplyApplicationDetail(id, data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(updateApplyApplicationDetail(res.data.data));
                    Utils.popupAlert({ text: res.data.message, type: "success" })
                        .then((isSuccess) => {
                            if (isSuccess && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
                                history.push("user-funding");
                            } else {
                                history.push("user-overview");
                            }
                        });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateApplyApplicationDetailWinner = (id, data) => {
    const userData = Utils.getSavedUserData();
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateApplyApplicationDetailWinner(id, data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(updateApplyApplicationDetail(res.data.data));
                    Utils.popupAlert({ text: res.data.message, type: "success" })
                        .then((isSuccess) => {
                            if (isSuccess && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
                                history.push("user-funding");
                            } else {
                                history.push("user-overview");
                            }
                        });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    getApplyApplicationRequest,
    getFundingUserReportRequest,
    getOpenApplicationUserRequest,
    getOpenApplicationUserByIDRequest,
    getApplyApplicationByIDRequest,
    getReportStatusRequest,
    updateReportStatusRequest,
    getFundingApplicationByIDRequest,
    submitApplyApplicationRequest,
    updateApplyApplicationDetailRequest,
    getOpenApplicationUserByIDRequestWithRedirect,
    getApplyApplicationByIDRequestWithRedirect,
    updateApplyApplicationDetailWinner
};
