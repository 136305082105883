import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import {
    SideBarContainer,
    Item
} from "./SideBar.styles";
import history from '../../../../history';
import LoginActions from "../../../../actions/Authentication";

const UserSideBar = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedPage, setSelectedPage] = useState('overView');
    const numberUnread = useSelector((state) => state.Message.numberUnread);
    useEffect(() => {
        setSelectedPage(props.page);
    }, [props]);

    const switchPage = (page) => {
        history.push(page);
    };

    const logout = () => {
        dispatch(LoginActions.logout());
    };

    return (
        <SideBarContainer>
            <Item
                className={selectedPage === 'applications' ? 'selected' : ''}
                onClick={() => switchPage('/judge/applications')}
            >
                <span>{t("fundingApplications.funding opportunities")}</span>
                <i className={`${selectedPage === 'applications' ? '' : 'hide-icon'}`} />
            </Item>
            <Item
                className={selectedPage === 'messages' ? 'selected' : ''}
                onClick={() => switchPage('/judge/messages')}
            >
                <span>{t("general.Messages")}</span>
                <div className='ticker'><span>{_.parseInt(numberUnread) > 0 ? numberUnread : ''}</span><i className={`${selectedPage === 'messages' ? '' : 'hide-icon'}`} /></div>
            </Item>
            <Item onClick={logout}>
                <span>{t("general.Logout")}</span>
                <i className={`${selectedPage === '' ? '' : 'hide-icon'}`} />
            </Item>
        </SideBarContainer>
    );
};

UserSideBar.propTypes = {
    page: PropTypes.string
};

export default UserSideBar;
