import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import Pagination from "../../../../components/Common/NewPagination";
import {
    BodyContainer
} from "./LimitedUser.styles";
import {
    PageTitle
} from "../../../../components/Common/CommonLayout";
import RoundedTable from "../../../../components/Common/RoundedTable";
import CONSTANTS from "../../../../constants/Constants";
import EventActions from "../../../../actions/Event";
import AdminOverViewAction from '../../../../actions/AdminOverView';

const LimitedUser = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const columns = ['type', "user name", "email", 'account status', 'action'];
    const isLoading = useSelector((state) => state.AdminOverView.isLoading);
    const listUsers = useSelector((state) => state.AdminOverView.listUsers);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const listOptions = {
        1: 'view',
        2: "Send Message"
    };

    useEffect(() => {
        const data = {
            page,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            filter: "limited user"
        };
        dispatch(AdminOverViewAction.getListUsers(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const data = {
            page,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language: i18n.language
        };
        dispatch(EventActions.getAllEvent(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        setPage(listUsers.page);
        setLastPage(listUsers.lastPage);
    }, [listUsers]);

    const clickPageOrLink = (currentPage) => {
        const data = {
            page: currentPage,
            item_per_page: CONSTANTS.ITEM_PER_PAGE,
            language: i18n.language
        };
        dispatch(EventActions.getAllEvent(data));
    };

    const callBackPrev = () => {
        setPage(page - 1);
        clickPageOrLink(page - 1);
    };

    const callBackNext = () => {
        setPage(page + 1);
        clickPageOrLink(page + 1);
    };

    const pageOnclick = (e) => {
        clickPageOrLink(_.parseInt(e));
    };

    const _renderPagination = () => {
        return (
            <Pagination page={page} lastPage={lastPage || 1} callBackPrev={callBackPrev} callBackNext={callBackNext} pageOnclick={pageOnclick} />
        );
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={isLoading}
            page="limited-user"
            content={(
                <BodyContainer>
                    <PageTitle>
                        {t("general.Limited User")}
                    </PageTitle>
                    <div className='share-link'>Share link to register new Limited User: <a href={`${process.env.REACT_APP_FE_URL}/homepage/add-limited-user`}>{`${process.env.REACT_APP_FE_URL}/homepage/add-limited-user`}</a></div>
                    <RoundedTable
                        initialData={listUsers.data}
                        columns={columns}
                        tableTitle={t("general.Limited User")}
                        tableType={CONSTANTS.TABLE_TYPE.USER}
                        className="mt-2"
                        showNumberItems={CONSTANTS.ITEM_PER_PAGE}
                        listOptions={listOptions}
                    />
                    {_renderPagination()}
                </BodyContainer>
            )}
        />
    );
};

LimitedUser.propTypes = {

};

export default LimitedUser;
