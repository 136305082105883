import styled from 'styled-components';
import {
    GeneralButton, ContentBodyContainer, GridCol, FlexGridSixth, HorizontalContainer
} from '../../../components/Common/CommonLayout';
import { ColorName } from "../../../components/Variables";

const ItemContainer = styled(HorizontalContainer)`
    justify-content: center;
    align-content: center;
    align-items: center;
    &.abolute-width {
        max-width: 1140px;
        margin: auto;
        justify-content: space-between;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    &.books-container {
        justify-content: space-around;
        @media (max-width: 768px) {
            padding: 2em 1em;
        }
        flex-wrap: wrap;
    }
    &.vertical {
        flex-direction: column;
    }
    & .chartContainer {
        width: 100%;
        height: 400px;
        background: ${ColorName.chathamsBlue};
    }
    & .highcharts-title{
        color: white !important;
        fill: white !important;
    }
`;

const Container = styled(ContentBodyContainer)`
    & .content-page{
        margin: 3em 0 0 0;
    }
    & .invite-item{
        margin: 2em 0;
    }
    
    & .relate-post {
        margin-left: 1em;
        font-weight: 600;
        
    }
    & .line-page {
        width: 100%;
        height: 1px;
        background: gray;
        clear: both;
    }
    & .margin-line {
        margin: 1em 0;
    } 
    color: ${ColorName.chathamsBlueDark};
    @media (max-width: 768px) {
        padding: 0;
    }
`;

const Content = styled.div`
    margin-top: 2em;
    & .title {
        font-size: 18px;
        font-weight: 700;
    }
    & .detail {
        padding: 3px 0 15px 0;
        display: flex;
        & .list-style:before{
            content: "•";
            color: gray;
            padding : 0 1em;
        }
        & .list-style{
            font-size: 13px;
            color: gray;
        }
    }
    & .author {
        font-size: 13px;
        color: #57B6B6
    }
    & .date-created {
        font-size: 13px;
        color: gray;
        white-space: nowrap;
        margin-right: 1em;
    }
    & .text {
        font-size: 15px;
        color: ${ColorName.scorpion};
        & .attach-file{
            margin-left: 1em;
        }
        p {
            margin-top: 0px !important;
        }
    }
    & .cover-image {
        width: 25%;
        float: left;
        margin: 0 2em 1em 0;
        img {
            width: 100%;
        }
    }
`;

const Button = styled(GeneralButton)`
    margin-right: 1em;
    border: 1px solid ${ColorName.chathamsBlueDark};
    margin-top: 1em;
`;

const FlexGridSixthTemplate = styled(FlexGridSixth)`
    &.flex-end{
        justify-content: flex-end;
    }
    &.flex-start{
        justify-content: flex-start;
    }
    &.container-button {
        margin-top: 2em;
    }
    margin: 3em 1em;
    padding-bottom: 1em;
    & .border-top{
        border-top: 1px solid ${ColorName.chathamsBlueDark};
    }
    & .order-2{
        order: 2;
        padding-left: 2em;
    }
    & .order-1{
        padding-right: 2em;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        & .order-2{
            padding: 0;
        }
        & .order-1{
            padding: 0;
        }
        & .reverse{
            order: 2;
        }
    }
`;
const GridColHomepage = styled(GridCol)`
    & .container-hexagon{
        position: relative;
        display: flex;
        place-content: center;
        cursor: pointer;
        &:after{
            content: "";
            position: absolute;
            border-left: 40px solid transparent;
            border-right: 40px solid transparent;
            top: 98%;
            width: calc(100% - 177px);
            border-top: 50px solid ${ColorName.bostonBlue};
        }
        &.reverse{
            &:after{
                border-top: 50px solid ${ColorName.chathamsBlueDark};
            }
        }
    }
    & .information{
        position: relative;
        width: 100%;
        & .content{
            position: absolute;
            z-index: 999;
            right: 0;
            left: 0;
            top: -25px;    
            padding: 0 10em 0 9em;
            height: 40px; 
            & .text{
                padding: 0 5px; 
                color: white;
                border-left: 3px solid white; 
                & .description{
                    font-size: 0.8em;
                    line-height: 1.8;
                    color: lightgray;
                }
            }
            & .text-online{
                color: white;
                font-size: 0.5em;
            }
        }
    }
    & .hexagon {
        & .shadow{
            position: absolute;
            background: red;
            z-index: 6;
            left: 60px;
            box-shadow: 5px 15px 2px 5px rgba(0,0,0,1);
        }
        & .bottom-shadow{
            box-shadow: 11px -15px 2px 15px rgba(0,0,0,1);
            transform: rotateZ(40deg);
        }
        position: relative;
        width: 100%;
        background-color: ${ColorName.chathamsBlue};
        margin: 50px 0;
        display: flex;
        place-content: center;
        & .number-container{
            position: relative;
            & .number{ 
                text-align: center;
                width: 40px;
                left: 27px;
                position: absolute;
                color: ${ColorName.chathamsBlue};
                height: 50px;
                display: flex;
                flex-direction: column;
                z-index: 11;
                top: -27px;
                place-content: center;
                align-items: center;
                & span{
                    color: ${ColorName.chathamsBlue};
                    font-weight: 600;
                    font-size: 2em;
                    &.description{
                        font-weight: 900;
                        font-size: 0.5em;
                    }
                }
            }
        }
        &.reverse{
            &:before{
                border-bottom: 50px solid ${ColorName.bostonBlue};
            }
            &:after{
                border-top: 50px solid ${ColorName.bostonBlue};
            }
        }
        &:before{
            content: "";
            position: absolute;
            width: calc(100% - 100px);
            border-left: 40px solid transparent;
            border-right: 40px solid transparent;
            bottom: 100%;
            border-bottom: 50px solid ${ColorName.chathamsBlueDark};
        }
        &:after{
            content: "";
            position: absolute;
            border-left: 40px solid transparent;
            border-right: 40px solid transparent;
            top: 100%;
            width: calc(100% - 100px);
            border-top: 50px solid ${ColorName.chathamsBlueDark};
        }
        &.inner{
            position: absolute;
            width: 150px;
            margin: 0;
            left: 25px;
            place-content: start;
            z-index: 9;
            &:before{
                border-bottom: 40px solid white;
                width: calc(100% - 120px);
                border-left: 33px solid transparent;
                border-right: 33px solid transparent;
            }
            &:after{
                width: calc(100% - 120px);
                border-top: 40px solid white;
                border-left: 33px solid transparent;
                border-right: 33px solid transparent;
            }
        }
        & .outer{
            margin: 0;
            z-index: 10;
            position: absolute;
            height: 40px;
            left: 13px;
            top: -34px;
            transform: rotate(40deg);
            border-right: 4px solid ${ColorName.chathamsBlue};
            &.bottom{
                border-right: 4px solid ${ColorName.chathamsBlue};
                transform: rotate(-40deg);
                top: -5px;
                &.reverse{
                    right: 13px;
                    left: unset;
                    transform: rotate(40deg);
                    border-right: 4px solid ${ColorName.bostonBlue};
                }
            }
            &.reverse{
                border-right: 4px solid ${ColorName.bostonBlue};
                right: 13px;
                left: unset;
                transform: rotate(-40deg);
            }
        }
        & .hexagon-image{
            position: absolute;
            right: -5px;
            top: -40px;
            z-index: 12;
            & .img-container{
                position: relative;
                width: 120px;
                & .background-image{
                    width: 80px;
                    height: 80px;
                    right: 40px;
                    position: absolute;
                    & img{
                        object-fit: cover;
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        box-shadow: 0px 0px 5px 1px rgba(0,0,0,1);
                    }
                    
                }
            }
        }
    }
`;

const Pagination = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em;
    color: white;
    div {
        padding: 0.2em 0.3em;
        background: ${ColorName.botticelli};
        cursor: pointer;
        height: 18px;    
        display: flex;
        align-items: center;
    }
    & .prev {
        position: relative;
        background: ${ColorName.chathamsBlueDark};
        &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent ${ColorName.chathamsBlueDark} transparent transparent;
            content: '';
            display: block;
            left: -24px;
            position: absolute;
            z-index: 2;
            border-width: 12px;
            top: 0px;
            bottom: 0;
        }
    }
    & .next {
        position: relative;
        background: ${ColorName.chathamsBlueDark};
        &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent transparent  transparent ${ColorName.chathamsBlueDark};
            content: '';
            display: block;
            right: -24px;
            position: absolute;
            z-index: 2;
            border-width: 12px;
            top: 0px;
            bottom: 0;
        }
    }
    & .active {
        background: ${ColorName.chathamsBlueDark};
    }
    & .page {
        padding: 0.2em 0.5em;
        margin: 0 0.5em;
    }
`;

const Title = styled.div`
    font-size: 2.5em;
    font-weight: 800;
    text-align: left;
    border-bottom: 3px solid;
    & .content{
        font-weight: 200;
    }
`;

export {
    Container,
    GridColHomepage,
    Title,
    FlexGridSixthTemplate,
    Button,
    Pagination,
    Content,
    ItemContainer
};
