import Api from "./Base";

export function getNotifications(data) {
    return Api.get("/notifications", data);
}

export function getUserExplore(data) {
    return Api.get("/users", data);
}

export function getUserExploreById(id, data) {
    return Api.get(`users/${id}`, data);
}

export function createUserExplore(data) {
    return Api.post("/users", data);
}

export function updateUserOverview(id, data) {
    return Api.postFormData(`/users/${id}/update`, data);
}

export function getRolesName(data) {
    return Api.get("roles", data);
}

export function userReport(data) {
    return Api.get("user_report", data);
}

export function getApplyApplication(data) {
    return Api.get("apply_application", data);
}

export function getListAdmin() {
    return Api.get("/get_list_super_admin");
}

export function getUsersForMessage(data) {
    return Api.get("users/get_list_users/multiple_roles", data);
}
