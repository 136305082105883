import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Container,
    GridColHomepage,
    FlexGridSixthTemplate,
    Button
} from "./PrivacyPolicy.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import SummryOverview from "../../../components/Common/SummaryOverview";
import { ColorName } from "../../../components/Variables/index.js";
import GuestPrivacyPolicySettingActions from "../../../actions/GuestPrivacyPolicySetting";
import history from "../../../history";

const PrivacyPolicy = () => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const isLoading = useSelector((state) => state.GuestPrivacyPolicySetting.isLoading);
    const guestPrivacyPolicySetting = useSelector((state) => state.GuestPrivacyPolicySetting);

    const [privacyPolicyContent, setPrivacyPolicyContent] = useState("");

    useEffect(() => {
        dispatch(GuestPrivacyPolicySettingActions.getPrivacyPolicySettingData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPrivacyPolicyContent(guestPrivacyPolicySetting.privacyPolicyContent);
    }, [guestPrivacyPolicySetting]);

    const _renderContent = () => {
        return (
            <div className="content-page">
                {isLoading && <Loading />}
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <div className="text" dangerouslySetInnerHTML={{ __html: privacyPolicyContent[i18n.language] }} />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate className="container-button">
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <Button
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => history.push("/homepage/register")}
                        />
                        <Button
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.chathamsBlueDark}
                            background={ColorName.white}
                            onClick={() => history.push("/homepage/register")}
                        />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
            </div>
        );
    };

    const _renderSummaryOverview = () => {
        return <SummryOverview />;
    };

    const _renderMain = () => {
        return (
            <Container>
                {_renderContent()}
                {_renderSummaryOverview()}
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
            currentPage="privacyPolicy"
        />
    );
};

PrivacyPolicy.propTypes = {
};

export default PrivacyPolicy;
