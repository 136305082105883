import React from "react";
import { useSelector } from "react-redux";
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import { BodyContainer } from "./Message.styles";
import {} from "../../../components/Common/CommonLayout";
import Message from "../../../components/Layout/PortalTemplate/Message";
import Utils from "../../../libs/Utils";
import Constants from "../../../constants/Constants";
const Messages = () => {
  const isLoading = useSelector((state) => state.Message.isLoading);
  const isLoadingUserOverview = useSelector((state) => state.UserOverview.isLoading);
  const userData = Utils.getSavedUserData();

  const checkRole = () => {
    if (userData) {
      if (userData.role.name === Constants.ROLE_NAME.SUPER_ADMIN) return "admin";
      if (userData.role.name === Constants.ROLE_NAME.JUDGE) return "judge";
      if (userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN) return "limited-admin";
      return "user";
    }
  };

  return (
    <DefaultLayout
      userRole={checkRole()}
      isLoading={isLoading || isLoadingUserOverview}
      page="messages"
      content={
        <BodyContainer>
          <Message />
        </BodyContainer>
      }
    />
  );
};

export default Messages;
