/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  PageTitle,
  ProgressBarFunding,
  WrapperButton,
  Category,
  WrapperTitle,
  WrapperItemConent,
  Report,
  BlockReport,
  Title,
  UploadContainer,
  UploadTemplate,
  UploadListItem
} from "./FundingDetail.styles";
import Button from "../../../../components/Common/Button";
import history from "../../../../history";
import FundingApplicationUserActions from "../../../../actions/FundingApplicationsUser";
import Utils from "../../../../libs/Utils";
import UserFundingDetailApplications from "../../../../components/Common/UserFundingDetailApplications";
import GuestDocumentTemplateSettingActions from "../../../../actions/GuestDocumentTemplateSetting";
import ProgressBar from "../../../../components/Common/ProgressBar";
import { ColorName } from "../../../../components/Variables";
import Constants from "../../../../constants/Constants";
import { LabelSlide } from "../../../../components/Common/CommonLayout";

const ViewCategoryQuestionnaire = () => {
  const { t } = useTranslation();
  const userData = Utils.getSavedUserData();
  const isLoading = useSelector((state) => state.FundingApplicationsUser.isLoading);
  const dispatch = useDispatch();
  const FundingStorage = useSelector((state) => state.FundingApplicationsUser);
  const [dataApplyApplicationItem, setDataApplyApplicationItem] = useState();
  const [itemApplicationDetails, setItemApplicationDetails] = useState();
  const [dataQuestionDetails, setDataQuestionDetails] = useState();
  const [dataAnswerForQuestions, setDataAnswerForQuestions] = useState([]);
  const [uploadFileDocument, setUploadFileDocument] = useState({
    name: '',
    url: ''
  });

  const [applyDetailUpdate, setApplyDetailUpdate] = useState([]);
  const [applyDetailStore, setApplyDetailStore] = useState([]);
  const [listFile, setListFile] = useState([]);
  const [listFileRemove, setListFileRemove] = useState([]);
  const [titleApply, setTitleApply] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const [dataFundingUserReport, setDataFundingUserReport] = useState();

  useEffect(() => {
    if (isSave) return;
    dispatch(FundingApplicationUserActions.getFundingUserReportRequest());
    dispatch(GuestDocumentTemplateSettingActions.getDocumentTemplateSettingData());
    if (_.isEmpty(FundingStorage.dataApplyApplicationItem) && !isLoading) {
      history.push("user-overview");
    }
    if (!_.isEmpty(FundingStorage.dataApplyApplicationItem.document_json)) {
      const files = [];
      _.map(FundingStorage.dataApplyApplicationItem.document_json, (item, i) => {
        return files.push({ [i]: item });
      });
      if (_.isEmpty(files)) files.push({});
      setListFile(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSave) return;
    setDataFundingUserReport(FundingStorage.dataFundingUserReport);
    setDataApplyApplicationItem(FundingStorage.dataApplyApplicationItem);
    setTitleApply(FundingStorage.dataApplyApplicationItem.title);
    setItemApplicationDetails(FundingStorage.dataApplyApplicationItem.applyApplicationDetails);
    if (!_.isEmpty(FundingStorage.dataApplyApplicationItem)) {
      setDataQuestionDetails(FundingStorage.dataApplyApplicationItem.fundingApplication.questionnaire.questionCategories);
      if (!_.isEmpty(FundingStorage.dataApplyApplicationItem.document_json)) {
        const files = [];
        _.map(FundingStorage.dataApplyApplicationItem.document_json, (item, i) => {
          return files.push({ [i]: item });
        });
        if (_.isEmpty(files)) files.push({});
        setListFile(files);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FundingStorage]);

  useEffect(() => {
    if (isSave) return;
    if (dataApplyApplicationItem) {
      const files = [];
      _.map(dataApplyApplicationItem.document_json, (item, i) => {
        return files.push({ [i]: item });
      });
      if (_.isEmpty(files)) files.push({});
      setListFile(files);
      if (dataApplyApplicationItem.status === Constants.APPLY_APPLICATION_STATUS.APPROVED) setIsApproved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataApplyApplicationItem]);

  useEffect(() => {
    if (isSave) return;
    const arrFundingDetail = [];
    const newApplyDetailUpdate = [...applyDetailUpdate];
    const newApplyDetailStore = [...applyDetailStore];
    _.map(dataQuestionDetails, (questionCategory) => {
      _.map(questionCategory.questionDetails, (questionDetail) => {
        let flag = true;
        _.map(itemApplicationDetails, (item) => {
          if (questionDetail.id === item.question_detail_id) {
            flag = false;
            return arrFundingDetail.push({
              title: questionCategory.title,
              questionDetail,
              question_detail_id: item.question_detail_id,
              answer: item.answer,
              idAnswer: item.id
            });
          }
        });
        if (flag) {
          return arrFundingDetail.push({
            title: questionCategory.title,
            questionDetail
          });
        }
      });
      setDataAnswerForQuestions(arrFundingDetail);
    });
    _.map(arrFundingDetail, (item) => {
      if (item.idAnswer !== '' && !_.isNil(item.idAnswer)) {
        return newApplyDetailUpdate.push({
          apply_detail_id: item.idAnswer,
          answer: item.answer,
          is_mandatory: item.questionDetail.is_mandatory,
          type: item.questionDetail.question_type
        });
      } else if (item.idAnswer === '' || _.isNil(item.idAnswer)) {
        return newApplyDetailStore.push({
          question_detail_id: item.questionDetail.id,
          answer: item.answer || '',
          is_mandatory: item.questionDetail.is_mandatory,
          type: item.questionDetail.question_type
        });
      }
    });
    setApplyDetailUpdate(newApplyDetailUpdate);
    setApplyDetailStore(newApplyDetailStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataApplyApplicationItem]);

  const convertDataQuestion = () => {
    const arrayList = {};
    _.map(dataAnswerForQuestions, (item) => {
      const category = item.title;
      if (!_.has(arrayList, category)) arrayList[category] = [];
      arrayList[category].push(item);
    });
    return arrayList;
  };

  const onChangeField = (value, questionDetailId, idAnswer, answerDetailId, isMandetory) => {
    const createAnswer = [...applyDetailStore];
    const updateAnswer = [...applyDetailUpdate];
    if (_.isEmpty(createAnswer) && _.isEmpty(updateAnswer)) {
      if (!answerDetailId) {
        createAnswer.push({
          question_detail_id: questionDetailId,
          answer: value || '',
          is_mandatory: isMandetory
        });
      } else if (answerDetailId) {
        updateAnswer.push({
          apply_detail_id: idAnswer,
          answer: value || '',
          is_mandatory: isMandetory
        });
      }
    } else if (!answerDetailId) {
      let checkIdQuestion = false;
      _.map(createAnswer, (answer) => {
        if (answer.question_detail_id === questionDetailId) {
          checkIdQuestion = true;
          answer.answer = value;
        }
      });
      if (!checkIdQuestion) {
        createAnswer.push({
          question_detail_id: questionDetailId,
          answer: value || '',
          is_mandatory: isMandetory
        });
      }
    } else if (answerDetailId) {
      let checkanswerDetailId = false;
      _.map(updateAnswer, (answer) => {
        if (answer.apply_detail_id === idAnswer) {
          checkanswerDetailId = true;
          answer.answer = value;
        }
      });
      if (!checkanswerDetailId) {
        updateAnswer.push({
          apply_detail_id: idAnswer,
          answer: value || '',
          is_mandatory: isMandetory
        });
      }
    }
    setApplyDetailUpdate(updateAnswer);
    setApplyDetailStore(createAnswer);
  };

  const _renderField = (field) => {
    if (!_.isNil(field)) {
      if (dataApplyApplicationItem.status !== Constants.APPLY_APPLICATION_STATUS.INCOMPLETE) {
        return (
          <WrapperTitle key={field.questionDetail.id} marginTop="1em" marginLeft="1em">
            <div className="fonts-status">
              {field.questionDetail.title}
            </div>
            <div
              className="style-disabled-div"
            >{field.questionDetail.question_type === 1 ? field.answer || '' : _.last(dataApplyApplicationItem.file_url.split("/"))}</div>
          </WrapperTitle>
        );
      } else {
        return (
          <WrapperTitle key={field.questionDetail.id} marginTop="1em" marginLeft="1em">
            <div className="fonts-status">
              {field.questionDetail.title}
              {' '}
              {field.questionDetail.is_mandatory === true ? "*" : ''}
            </div>
            {field.questionDetail.question_type === 1 && (
              <textarea
                defaultValue={field.answer || ''}
                onChange={(event) => onChangeField(event.target.value, field.questionDetail.id,
                  field.idAnswer, field.question_detail_id, field.questionDetail.is_mandatory)
                }
              />
            )}
          </WrapperTitle>
        );
      }
    }
  };

  const _renderCategories = () => {
    if (!_.isEmpty(dataAnswerForQuestions)) {
      let count = 0;
      const render = _.map(convertDataQuestion(dataAnswerForQuestions), (category, index) => {
        count += 1;
        return (
          <div key={index} className="m-bottom">
            <WrapperTitle>
              <div className="fonts-category">{`${count}. ${index}`}</div>
            </WrapperTitle>
            <WrapperItemConent>
              {
                _.map(category, (field) => {
                  return _renderField(field);
                })
              }
            </WrapperItemConent>
          </div>
        );
      });
      return render;
    }
  };

  const submitAnswerDetail = (idApply) => {
    const newAnswer = {
      apply_detail_update: [...applyDetailUpdate],
      apply_detail_store: [...applyDetailStore]
    };
    if (_.isEmpty(applyDetailStore) && applyDetailStore.length !== 0) {
      return Utils.popupAlert({ title: "Oops...", text: t("alert.Fields cannot be blank"), type: "error" });
    } else {
      let error = 0;
      let isCheckNull = 0;
      _.map(newAnswer, (item) => {
        _.map(item, (value) => {
          if (value.is_mandatory === true) {
            if (value.answer === '' && value.type === 1) {
              error += 1;
            } else if (value.answer !== '' && _.isNil(value.answer) && value.type === 1) {
              isCheckNull += 1;
            }
          }
        });
      });
      if (isCheckNull !== 0) return Utils.popupAlert({ title: "Oops...", text: t("alert.Fields cannot be blank"), type: "error" });
      const formData = new FormData();
      formData.append("apply_detail_update", JSON.stringify(newAnswer.apply_detail_update));
      formData.append("apply_detail_store", JSON.stringify(newAnswer.apply_detail_store));
      formData.append("remove_document", JSON.stringify(listFileRemove));
      formData.append("title", titleApply);
      if (uploadFileDocument.url) formData.append("file", uploadFileDocument.url);
      if (_.isArray(listFile)) {
        _.map(listFile, (child, index) => {
          formData.append(`document[${index}]`, child);
        });
      }
      if (error === 0) setIsSave(true);
      error === 0
        ? dispatch(FundingApplicationUserActions.updateApplyApplicationDetailRequest(idApply, formData))
        : Utils.popupAlert({ title: "Oops...", text: t("alert.Fields cannot be blank"), type: "error" });
    }
  };

  const scrollVertical = async (title) => {
    if (userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
      await history.push("user-funding");
      window.location.href = `${title}`;
    } else {
      history.push("user-overview");
    }
  };

  const _renderTemplates = () => {
    const renderDocument = _.map(dataApplyApplicationItem.fundingApplication.document_json, (child, index) => {
      return (
        <div className="upload-item" key={index}>
          <a href={child} target="_blank">
            {_.last(index.split("/"))}
          </a>
        </div>
      );
    });
    return renderDocument;
  };

  const handChangeFileForWinner = (event, index, nameFile) => {
    if (event.target.files.length !== 0) {
      if (!listFileRemove.includes(nameFile)) listFileRemove.push(nameFile);
      const temp = [...listFile];
      temp[index] = event.target.files[0];
      setListFile(temp);
    }
  };

  const addNewFile = () => {
    const temp = [...listFile];
    temp.push({});
    setListFile(temp);
  };

  const removeNewFile = (index, file) => {
    const arrayRemove = [];
    if (!_.isEmpty(file)) {
      if (!_.has(file, "name")) {
        arrayRemove.push(_.keys(file).toString());
      }
    }
    const files = [...listFile];
    if (index > -1) {
      files.splice(index, 1);
    }
    if (_.isEmpty(files)) files.push({});
    setListFile(files);
    setListFileRemove(arrayRemove);
  };

  const _renderUploadTemplate = () => {
    const renderNew = _.map(listFile, (e, i) => {
      const nameFile = !_.isUndefined(listFile[i].name) ? listFile[i].name : _.keys(listFile[i]).toString();
      return (
        <div key={i} className="input-file-container">
          <LabelSlide className="upload-file">
            <input type="file" onChange={(event) => handChangeFileForWinner(event, i, nameFile)} />
            {listFile[i] && (
              <p>
                <i className="fas fa-upload" />
                {!_.isUndefined(listFile[i].name) ? Utils.getNameFileUpload(listFile[i].name) : Utils.getNameFileUpload(_.keys(listFile[i]).toString()) || t("general.Choose file")}
              </p>
            )}
          </LabelSlide>
          {(i === 0 && listFile[i])
            && (
              <div className="action-upload">
                <span onClick={() => addNewFile()}><i className="fas fa-plus" /></span>
                <span onClick={() => removeNewFile(i, listFile[i])}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
          {i !== 0
            && (
              <div className="action-upload">
                <span onClick={() => removeNewFile(i, listFile[i])}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
        </div>
      );
    });
    return renderNew;
  };

  const _renderUploadTemplateNotEdit = () => {
    const renderDocument = _.map(listFile, (child, index) => {
      if (!_.isEmpty(child)) {
        return (
          <div className="upload-item" key={index}>
            <a href={_.values(child)} target="_blank">
              {_.last(_.keys(child)[0].split("/"))}
            </a>
          </div>
        );
      }
    });
    return renderDocument;
  };

  return (
    <DefaultLayout
      userRole='user'
      isLoading={isLoading}
      page="userFundingDetail"
      content={(
        <BodyContainer>
          {_.isEmpty(dataFundingUserReport) ? t("general.Data Not Found") : (
            <Report>
              <BlockReport>
                <ProgressBar
                  value={!_.isEmpty(dataFundingUserReport) ? dataFundingUserReport.my_application_amount === 0 ? '0' : dataFundingUserReport.my_application_amount : ''}
                  title={t("general.My Funding Applications")}
                  callBackAction={() => scrollVertical("#my-applications")}
                  filter="user overview"
                  total={100}
                />
              </BlockReport>
              {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER && (
                <BlockReport>
                  <ProgressBar
                    value={!_.isEmpty(dataFundingUserReport) ? dataFundingUserReport.funding_application_amount === 0 ? '0' : dataFundingUserReport.funding_application_amount : ''}
                    title={t("fundingApplications.Available Funding Opportunities")}
                    callBackAction={() => scrollVertical("#apply-applications")}
                    filter="user overview"
                    total={100}
                  />
                </BlockReport>
              )}
              <BlockReport />
            </Report>
          )}
          <UserFundingDetailApplications />
          <PageTitle marginTop="3em">{t("fundingApplications.Questionnaire")}</PageTitle>
          <ProgressBarFunding className="mb-2">
            <Category>
              {_renderCategories()}
              {
                !_.isEmpty(dataApplyApplicationItem) && dataApplyApplicationItem.document_json && (
                  <UploadContainer className="m-bottom">
                    <UploadListItem>
                      <Title>{t("documentTemplate.Document Template")}</Title>
                      {dataApplyApplicationItem.document_json && (_renderTemplates())}
                    </UploadListItem>
                    <UploadTemplate>
                      <Title>{t("general.Upload Document")}</Title>
                      {dataApplyApplicationItem.status === Constants.APPLY_APPLICATION_STATUS.INCOMPLETE
                        && _renderUploadTemplate()
                      }
                      {dataApplyApplicationItem.status !== Constants.APPLY_APPLICATION_STATUS.INCOMPLETE && _renderUploadTemplateNotEdit()}
                    </UploadTemplate>
                  </UploadContainer>
                )
              }
            </Category>
            <WrapperButton>
              <Button
                title={t("general.Back")}
                height="35px"
                width="10em"
                margin="0 1.5em 0 0"
                onClick={() => history.push("user-funding-detail")}
                background={ColorName.chathamsBlueDark}
              />
              {(!_.isEmpty(dataApplyApplicationItem) && dataApplyApplicationItem.status !== Constants.APPLY_APPLICATION_STATUS.APPROVED) && (
                <Button
                  title={t("general.Save")}
                  height="35px"
                  width="10em"
                  margin="0 1.5em 0 0"
                  onClick={() => submitAnswerDetail(dataApplyApplicationItem.id)}
                  background={ColorName.chathamsBlueDark}
                />
              )}
            </WrapperButton>
          </ProgressBarFunding>
        </BodyContainer>
      )}
    />
  );
};

ViewCategoryQuestionnaire.propTypes = {

};

export default ViewCategoryQuestionnaire;
