import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    allLibraryData: [],
    libraryDetailData: {}
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.PAGE_LIBRARY_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false
            };
        case ActionTypes.PAGE_LIBRARY_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false
            };
        case ActionTypes.PAGE_LIBRARY_REQUEST:
            return {
                ...state,
                isLoading: true,
                hasError: false,
                isSuccess: false
            };
        case ActionTypes.PAGE_LIBRARY_SET_DETAIL_DATA:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                libraryDetailData: payload
            };
        case ActionTypes.PAGE_LIBRARY_SET_ALL_DATA:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                allLibraryData: payload
            };
        default:
            return state;
    }
};
