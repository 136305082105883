import ActionTypes from "../constants/ActionTypes";

const initialState = {
    hasError: false,
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    testimonials: [],
    testimonialData: {},
    selectedTestimonialID: 0,
    isMultipleLanguage: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.GUEST_TESTIMONIAL_SETTING_REQUEST:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
                hasError: false
            };
        case ActionTypes.GUEST_TESTIMONIAL_SETTING_SUCCESS:
            return {
                ...state,
                isSuccess: false,
                isLoading: false,
                hasError: false,
                isMultipleLanguage: action.isMultipleLanguage || state.isMultipleLanguage
            };
        case ActionTypes.GUEST_TESTIMONIAL_SETTING_LIST_REQUEST:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
                hasError: false
            };
        case ActionTypes.GUEST_TESTIMONIAL_SETTING_LIST_SUCCESS:
            return {
                ...state,
                testimonials: action.payload || state.testimonials,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GUEST_TESTIMONIAL_SETTING_LIST_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false,
                errorMessage: action.errorMessage
            };
        case ActionTypes.GET_TESTIMONIAL_BY_ID_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                testimonialData: payload,
                isMultipleLanguage: action.isMultipleLanguage || state.isMultipleLanguage
            };
        case ActionTypes.SET_TESTIMONIAL_ID_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                selectedTestimonialID: payload,
                testimonialData: {}
            };
        default:
            return state;
    }
};
