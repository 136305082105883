import ActionTypes from "../constants/ActionTypes";

const initialState = {
    hasError: false,
    isLoading: false,
    isSuccess: false,
    dataApplyApplication: {},
    dataFundingUserReport: {},
    dataOpenApplicationUser: {},
    dataOpenApplicationUserItem: {},
    dataApplyApplicationItem: {},
    dataReportStatus: {},
    applyApplication: {}
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ActionTypes.GET_APPLY_APPLICATION:
            return {
                ...state,
                dataApplyApplication: action.payload,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GET_FUNDING_USER_REPORT:
            return {
                ...state,
                dataFundingUserReport: action.payload,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GET_OPEN_APPLICATION_USER_BY_ID:
            return {
                ...state,
                dataOpenApplicationUserItem: action.payload,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.SUBMIT_REPORT_STATUS:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GET_OPEN_APPLICATION_USER:
            return {
                ...state,
                dataOpenApplicationUser: action.payload,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GET_APPLY_APPLICATION_BY_ID:
            return {
                ...state,
                dataApplyApplicationItem: action.payload,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GET_REPORT_STATUS:
            return {
                ...state,
                dataReportStatus: action.payload,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GET_FUNDING_APPLY_APPLICATION_BY_ID:
            return {
                ...state,
                applyApplication: action.payload,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.UPDATE_APPLY_APPLICATION_DETAIL:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.SUBMIT_APPLY_APPLICATION:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.FUNDING_APPLICATION_USER_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false,
            };
        case ActionTypes.FUNDING_APPLICATION_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
                hasError: false,
                isSuccess: false
            };
        case ActionTypes.FUNDING_APPLICATION_USER_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                isSuccess: true
            };
        default:
            return state;
    }
};
