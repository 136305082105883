import Api from "./Base";

export function search(data) {
    return Api.get("/search", data);
}


export function searchHomepage(data) {
    return Api.get("/general_search", data);
}
