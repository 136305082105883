import styled from 'styled-components';
import { ColorName } from "../../Variables";
import { HorizontalContainer } from "../CommonLayout";

const ProgressBarContainer = styled(HorizontalContainer)`
    padding: 15px;
    background: ${ColorName.white};
    border-radius: 100px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    width: 250px;
    &.for-user-overview{
        flex: 1;
        width: auto;
    }
    & .CircularProgressbar-text {
        fill: ${ColorName.chathamsBlueDark};
    }
    & .CircularProgressbar-trail {
        stroke: ${ColorName.mercury};
    }
    & .CircularProgressbar .CircularProgressbar-path {
        stroke: ${ColorName.chathamsBlueDark};
    }
    & .CircularProgressbar {
        max-width: 60px;
    }
    margin: 5px;
    @media (max-width: 1169px) {
        width: 40%;
    }
    @media (max-width: 1024px) {
        width: auto;
    }
`;

const Title = styled.div`
    margin: 0 15px;
    font-size: 17px;
    font-weight: 600;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    place-content: center;
    & .description {
        font-size: 11px;
    }
    @media (max-width: 1024px) {
        margin-left: 1em !important;
        margin-top: 0em !important;
    }
`;

const ViewMore = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-right: 10px;
    @media (max-width: 1024px) {
        margin-top: 0em !important;
    }
`;

export {
    ProgressBarContainer,
    Title,
    ViewMore
};
