import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { ButtonDefault } from "./ButtonFrontEnd.styles";

const Button = (props) => {
    const [background, setBackground] = useState();
    const [color, setColor] = useState();
    const [name, setTitle] = useState();
    const [width, setWidth] = useState();
    const [border, setBorder] = useState();

    useEffect(() => {
        setBackground(props.background);
        setTitle(props.name);
        setColor(props.color);
        setWidth(props.width);
        setBorder(props.border);
    }, [props]);

    const handleOnClick = () => {
        props.onClick();
    };

    return (
        <ButtonDefault
            onClick={handleOnClick}
            background={background}
            color={color}
            width={width}
            border={border}
        >
            {name}
        </ButtonDefault>
    )
};

Button.propTypes = {
    background: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
    width: PropTypes.string
};

export default Button;
