const Entrepreneur = {
    business: {
        identity_type: "Are you",
        located: "Where are you located Country?",
        city: "City",
        address: "Address",
        website: "Internet Address",
        facebook: "Facebook",
        instagram: "Instagram",
        language: "Which are the languages you prefer to interact?",
        establishment_year: "Year of establishment",
        industry_operate: "In which industry do you operate?",
        operate_locate: "Where do you operate?",
        idea_describe: "Describe the product/service idea",
        number_of_employee: "Number of employees",
        turnover: "Total turn over from the last year?",
        profit: "Total net profit from the last year?",
        consultancy_needed: "Which are your needs for consultancy?",
        business_idea: "Do you have a business idea you need funding for?"
    },
    individual: {
        identity_type: "Are you",
        located: "Where are you located Country?",
        city: "City",
        address: "Address",
        website: "Internet Address",
        facebook: "Facebook",
        instagram: "Instagram",
        language: "Which are the languages you prefer to interact?",
        employment_status: "What is your employment status?",
        industry_operate: "In which industry does your idea belong?",
        business_idea_name: "Name of your business idea",
        idea_describe: "Describe the product/service idea",
        consultancy_needed: "Which are your needs for consultancy?"
    }
};

const Investor = {
    individual: {
        primarily_invest: "How will you primarily invest as",
        // investing_individual: "What is the name of the investing individual?",
        located: "Where are you located Country?",
        city: "City",
        address: "Address",
        website: "Internet Address",
        facebook: "Facebook",
        instagram: "Instagram",
        operate_locate: "Where do you operate?",
        language: "Which are the languages you prefer to interact?",
        employment_status: "What is your employment status?",
        is_has_invested: "Have you previously invested in startups?",
        invest_platform: "Where would you like to invest as part of Dua Partner Invest platform?",
        minimum_money: "What is the minimum amount per investment you are keen to consider?"
        // about_me: "About me:"
    },
    business_venture_firm: {
        primarily_invest: "How will you primarily invest as",
        // investing_individual: "What is the name of the investing entity",
        located: "Where are you located Country?",
        city: "City",
        address: "Address",
        website: "Internet Address",
        facebook: "Facebook",
        instagram: "Instagram",
        operate_locate: "Where do you operate?",
        language: "Which are the languages you prefer to interact?",
        industry_operate: "In which industry do you currently operate?",
        is_has_invested: "Have you previously invested in startups?",
        invest_platform: "Where would you like to invest as part of Dua Partner Invest platform?",
        minimum_money: "What is the minimum amount per investment you are keen to consider?"
        // about_me: "About me"
    }
};

const Consultant = {
    individual: {
        capacity_provide: "On what capacity do you provide consultancy to startups?",
        located: "Where are you located Country?",
        city: "City",
        address: "Address",
        website: "Internet Address",
        facebook: "Facebook",
        instagram: "Instagram",
        language: "Which are the languages you prefer to interact?",
        expertise: "Which is your field of expertise?",
        is_provided: "Have you ever provided consultancy for startups?",
        is_offer: "Do you apply pro-bono consultancy in your field of expertise?"
    },
    entity: {
        capacity_provide: "On what capacity do you provide consultancy to startups?",
        located: "Where are you located Country?",
        city: "City",
        address: "Address",
        website: "Internet Address",
        facebook: "Facebook",
        instagram: "Instagram",
        language: "Which are the languages you prefer to interact?",
        expertise: "Which is your field of expertise?",
        is_provided: "Have you ever provided consultancy for startups?",
        is_offer: "Do you apply pro-bono consultancy in your field of expertise?"
    }
};

const Judge = {
    first_name: "First Name",
    last_name: "Last Name",
    address: "Address",
    phone_number: "Phone Number"
};

export default {
    Entrepreneur,
    Investor,
    Consultant,
    Judge
};
