import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import { BodyContainer } from "./Search.styles";
import { PageTitle } from "../../../components/Common/CommonLayout";
import RoundedTable from "../../../components/Common/RoundedTable";
import CONSTANTS from "../../../constants/Constants";
import history from "../../../history";
import Utils from "../../../libs/Utils";

const Search = () => {
  const { t } = useTranslation();
  const dataSearch = useSelector((state) => state.Search.dataSearch);
  const columns = ["type", "user name", "email", "account status", "action"];
  const columnsApplication = [
    "applications status",
    "name",
    "submitted",
    "action",
  ];
  const isLoading = useSelector((state) => state.Search.isLoading);
  const isLoadingFunding = useSelector((state) => state.FundingApplicationsUser.isLoading);
  const [listFundingApplication, setListFundingApplication] = useState([]);
  const [listUser, setListUser] = useState([]);
  const userData = Utils.getSavedUserData();
  const [userRole, setUserRole] = useState("");
  const [tableType, setTableType] = useState("");
  const listOptions = {
    2: "Send Message",
  };

  const listOptionsAdmin = {
    1: "view",
    2: "Send Message",
  };

  const listOptionsFunding = {
    1: "view",
  };

  const listOptionsFundingAdmin = {
    1: "view",
    2: "edit",
  };

  useEffect(() => {
    if (userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN) {
      setUserRole("admin");
      setTableType(CONSTANTS.TABLE_TYPE.APPLICATION_ADMIN_OVERVIEW);
    }
    if (userData.role.name === CONSTANTS.ROLE_NAME.JUDGE) {
      setUserRole("judge");
      setTableType(CONSTANTS.TABLE_TYPE.JUDGE_APPLICATION);
    }
    if (
      userData.role.name !== CONSTANTS.ROLE_NAME.JUDGE &&
      userData.role.name !== CONSTANTS.ROLE_NAME.SUPER_ADMIN
    ) {
      setUserRole("user");
      setTableType(CONSTANTS.TABLE_TYPE.APPLICATION_ADMIN_OVERVIEW);
    }
    if (userData.role.name === CONSTANTS.ROLE_NAME.LIMITED_ADMIN) {
      setUserRole("limited-admin");
      setTableType(CONSTANTS.TABLE_TYPE.APPLICATION_ADMIN_OVERVIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setListFundingApplication(dataSearch.searchFunding);
    setListUser(dataSearch.searchUser);
  }, [dataSearch]);

  const clickViewAllUser = () => {
    history.push("users");
  };

  const clickViewAllFundingApplication = () => {
    history.push("funding-applications");
  };

  return (
    <DefaultLayout
      userRole={userRole}
      isLoading={isLoading || isLoadingFunding}
      page="search"
      content={
        <BodyContainer>
          <PageTitle>{t("homepageUser.Search")}</PageTitle>
          {userRole === "admin" && (
            <RoundedTable
              className="fundingApplicationTableOverview"
              initialData={listFundingApplication || []}
              columns={columnsApplication}
              tableTitle={t("fundingApplications.funding opportunities")}
              tableType={tableType}
              listOptions={listOptionsFundingAdmin}
              handleClick={clickViewAllFundingApplication}
              showNumberItems={1000}
            />
          )}
          {(userRole === "judge" || userRole === "user") && (
            <RoundedTable
              className="fundingApplicationTableOverview"
              initialData={listFundingApplication || []}
              columns={columnsApplication}
              tableTitle={t("fundingApplications.funding opportunities")}
              tableType={tableType}
              listOptions={listOptionsFunding}
              handleClick={clickViewAllFundingApplication}
              showNumberItems={1000}
            />
          )}
          {userRole === "admin" && (
            <RoundedTable
              initialData={listUser || []}
              columns={columns}
              tableTitle={t("general.Users")}
              tableType={CONSTANTS.TABLE_TYPE.USER}
              listOptions={listOptionsAdmin}
              className="mt-2"
              handleClick={clickViewAllUser}
              showNumberItems={1000}
            />
          )}
          {userRole === "user" && (
            <RoundedTable
              initialData={listUser || []}
              columns={columns}
              tableTitle={t("general.Users")}
              tableType={CONSTANTS.TABLE_TYPE.USER}
              listOptions={listOptions}
              className="mt-2"
              handleClick={clickViewAllUser}
              showNumberItems={1000}
            />
          )}
          {userRole === "limited-admin" && (
            <RoundedTable
              initialData={listUser || []}
              columns={columns}
              tableTitle={t("general.Users")}
              tableType={CONSTANTS.TABLE_TYPE.USER}
              listOptions={listOptions}
              className="mt-2"
              handleClick={clickViewAllUser}
              showNumberItems={1000}
            />
          )}
        </BodyContainer>
      }
    />
  );
};

Search.propTypes = {};

export default Search;
