import styled from 'styled-components';
import { VerticalContainer, HorizontalContainer } from "../../../../components/Common/CommonLayout";

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .ml-1 {
        margin-left: 1em
    }
    & .mb-2 {
        margin-bottom: 2em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
    & .ml-3 {
        margin-left: 3em
    }
    & .progress {
        @media (max-width: 1024px) {
            flex-direction: column;
            div {
                margin-left: 0;
                margin-top: 15px;
            }
        }
    }
`;

const HorizontalContainerProgress = styled(HorizontalContainer)`
    flex-wrap: wrap;
`;

export {
    BodyContainer,
    HorizontalContainerProgress
};
