import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";

const requestHasError = () => {
    return {
        type: ActionTypes.PAGE_COMMUNITY_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.PAGE_COMMUNITY_REQUEST
    };
};

const requestIsSuccess = (payload) => {
    return {
        type: ActionTypes.PAGE_COMMUNITY_SUCCESS,
        payload
    };
};

const setCommunityData = (payload) => {
    return {
        type: ActionTypes.PAGE_COMMUNITY_SUCCESS,
        payload
    };
};

const fetchCommunity = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.fetchCommunity(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestIsSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    fetchCommunity,
    setCommunityData
};
