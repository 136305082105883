import _ from "lodash";
import moment from "moment";
import { ColorName } from "../components/Variables";
import CONSTANTS from "../constants/Constants";
import i18n from "../i18n";
import Utils from "./Utils";

const checkColorStatusCell = (status) => {
    if (CONSTANTS.COLOR_STATUS.GRADIENT_VALUE.includes(status)) {
        return ColorName.studio;
    } else if (CONSTANTS.COLOR_STATUS.GREEN_VALUE.includes(status)) {
        return ColorName.fruitSaladLight;
    } else if (CONSTANTS.COLOR_STATUS.RED_VALUE.includes(status)) {
        return ColorName.wellRead;
    } else if (CONSTANTS.COLOR_STATUS.ORANGE_VALUE.includes(status)) {
        return ColorName.bourbon;
    } else if (CONSTANTS.COLOR_STATUS.BLUE_VALUE.includes(status)) {
        return ColorName.pelorous;
    }
    return ColorName.fruitSaladLight;
};

const convertStatusUser = (status) => {
    const listStatus = CONSTANTS.USER_STATUS;
    let result = "";
    _.map(listStatus, (item, key) => {
        if (item === status) {
            return result = key;
        }
    });
    return result;
};

const formatStatus = (status) => {
    return status.replace(/[_]+/g, ' ');
};

const _getResolvedUserData = (UserData) => {
    const resolvedData = [];
    _.map(UserData, (data) => {
        const statusUser = convertStatusUser(data.status);
        resolvedData.push({
            id: data.id,
            status: formatStatus(statusUser),
            type: data.role.name,
            name: {
                url: data.userData.url_avatar_aws || "https://awodev.com/images/default-forum-user.png",
                name: data.userData.full_name
            },
            email: data.email,
            user: data
        });
    });
    return resolvedData;
};

const convertStatusApplication = (status) => {
    const listStatus = CONSTANTS.FUNDING_APPLICATION_STATUS;
    let result = "";
    _.map(listStatus, (item, key) => {
        if (item === status) {
            return result = key;
        }
    });
    return result;
};
const _getResolvedFundingApplicationData = (applicationData) => {
    const resolvedData = [];
    _.map(applicationData, (data) => {
        const statusFunding = convertStatusApplication(data.status);
        resolvedData.push({
            id: data.id,
            status: formatStatus(statusFunding),
            name: data.title,
            submitted: data.submitted
        });
    });
    return resolvedData;
};

const _getResolvedJudgeApplication = (applicationData) => {
    const resolvedData = [];
    _.map(applicationData, (data) => {
        if (data.fundingApplication) {
            const statusFunding = convertStatusApplication(data.fundingApplication.status);
            resolvedData.push({
                id: data.fundingApplication.id,
                status: formatStatus(statusFunding),
                name: data.fundingApplication.title,
                submitted: data.fundingApplication.applyApplications.length,
                fundingApplicationId: data.id
            });
        }
    });
    return resolvedData;
};

const convertStatusApplies = (status) => {
    const listStatus = CONSTANTS.APPLY_APPLICATION_STATUS;
    let result = "";
    _.map(listStatus, (item, key) => {
        if (item === status) {
            return result = key;
        }
    });
    return result;
};

const _getResolvedApplyApplication = (applicationData) => {
    const resolvedData = [];
    _.map(applicationData, (data) => {
        const statusFunding = convertStatusApplies(data.status);
        if (_.has(data, "totalEvaluate")) {
            resolvedData.push({
                id: data.id,
                status: formatStatus(statusFunding),
                created: moment(data.created_at || data.createdAt).format("YYYY-MM-DD"),
                option: statusFunding === "APPROVED" ? "report" : "",
                averageScore: data.averageScore,
                numberJudge: data.numberJudge,
                judgeApplicationID: data.judgeApplicationID,
                fundingApplicationId: data.fundingApplicationId,
                title: data.user.userData.full_name
            });
        } else if (_.has(data, "user")) {
            resolvedData.push({
                id: data.id,
                status: formatStatus(statusFunding),
                created: moment(data.created_at || data.createdAt).format("YYYY-MM-DD"),
                option: statusFunding === "APPROVED" ? "report" : "",
                title: data.user.userData.full_name,
                judgeApplicationID: data.judgeApplicationID
            });
        }
    });
    return resolvedData;
};

const _getResolvedFundingApplicationDataAdminOverview = (applicationData) => {
    const resolvedData = [];
    _.map(applicationData, (data) => {
        const statusFunding = convertStatusApplication(data.status);
        resolvedData.push({
            id: data.id,
            name: data.title,
            status: formatStatus(statusFunding),
            submitted: data.submitted,
            noBackground: true
        });
    });
    return resolvedData;
};

const convertStatusActivities = (status, type) => {
    const listTableType = CONSTANTS.NOTIFICATION.TABLE_TYPE;
    let resultType = "";
    let resultStatus = "";
    let listStatus = {};
    _.each(listTableType, (child) => {
        if (child === type) return resultType = child;
    });
    if (resultType === CONSTANTS.NOTIFICATION.TABLE_TYPE.USER) {
        listStatus = CONSTANTS.USER_STATUS;
    } else if (resultType === CONSTANTS.NOTIFICATION.TABLE_TYPE.FUNDING_APPLICATION) {
        listStatus = CONSTANTS.FUNDING_APPLICATION_STATUS;
    }
    if (resultType === CONSTANTS.NOTIFICATION.TABLE_TYPE.APPLY_APPLICATION) {
        listStatus = CONSTANTS.APPLY_APPLICATION_STATUS;
    }
    if (resultType === CONSTANTS.NOTIFICATION.TABLE_TYPE.REPORT) {
        listStatus = CONSTANTS.REPORT_STATUS;
    }
    if (resultType === CONSTANTS.NOTIFICATION.TABLE_TYPE.EVALUATION) {
        listStatus = CONSTANTS.COMMON;
    }
    if (resultType === CONSTANTS.NOTIFICATION.TABLE_TYPE.NEWS) {
        listStatus = CONSTANTS.NEWS_STATUS;
    }
    if (resultType === CONSTANTS.NOTIFICATION.TABLE_TYPE.EVENT) {
        listStatus = CONSTANTS.EVENT_STATUS;
    }
    if (resultType === CONSTANTS.NOTIFICATION.TABLE_TYPE.LIBRARY) {
        listStatus = CONSTANTS.COMMON;
    }
    _.map(listStatus, (item, key) => {
        if (item === status) {
            return resultStatus = key;
        }
    });
    return resultStatus;
};

const _getResolvedActivityData = (activityData) => {
    const resolvedData = [];
    _.map(activityData, (data) => {
        const statusNotification = convertStatusActivities(data.status, data.table_type);
        resolvedData.push({
            id: data.id,
            status: formatStatus(statusNotification),
            activity: data.activity,
            return: data.return,
            date: data.date,
            data_id: data.data_id,
            table_type: data.table_type
        });
    });
    return resolvedData;
};

const _getResolvedNotificationData = (notificationData) => {
    const resolvedData = [];
    _.map(notificationData, (data) => {
        const statusNotification = convertStatusActivities(data.status, data.table_type);
        resolvedData.push({
            id: data.id,
            status: formatStatus(statusNotification),
            activity: data.content,
            date: moment(data.updatedAt).format("YYYY-MM-DD"),
            data_id: data.data_id,
            table_type: data.table_type,
            is_read: data.is_read
        });
    });
    return resolvedData;
};

const _getResolvedNotificationUser = (notificationData) => {
    const resolvedData = [];
    _.map(notificationData, (data) => {
        resolvedData.push({
            id: data.id,
            status: data.status === CONSTANTS.NOTIFICATION.NOTIFICATION_TYPE.NOTIFICATION ? "OPEN" : "CLOSED",
            activity: data.content,
            date: moment(data.created_at).format("YYYY-MM-DD"),
            data_id: data.data_id,
            table_type: data.table_type,
            type: data.type,
            is_read: data.is_read
        });
    });
    return resolvedData;
};

const getStatusNews = (status) => {
    if (status === CONSTANTS.NEWS_STATUS.PUBLISHED) return "PUBLISHED";
    if (status === CONSTANTS.NEWS_STATUS.UNPUBLISHED) return "UNPUBLISHED";
    return "DELETE";
};

const _getResolvedNews = (newsData) => {
    const resolvedData = [];
    _.map(newsData, (data) => {
        resolvedData.push({
            id: data.id,
            title: data.title,
            status: getStatusNews(data.status),
            image: data.url_img_aws,
            date: moment(data.created_at).format("YYYY-MM-DD")
        });
    });
    return resolvedData;
};

const _getResolvedTestimonial = (testimonialList) => {
    const resolvedData = [];
    _.map(testimonialList, (data) => {
        resolvedData.push({
            id: data.id,
            title: data.title,
            image: data.url_img_aws,
            description: Utils.removeHTMLTags(data.description),
            name: data.name,
            date: moment(data.created_at).format("YYYY-MM-DD")
        });
    });
    return resolvedData;
};

const _getResolvedDocumentTemplate = (documentTemplateList) => {
    const resolvedData = [];
    _.map(documentTemplateList, (data) => {
        resolvedData.push({
            id: data.id,
            name: data.name,
            file_name: data.file_name,
            file_url: data.file_url_aws
        });
    });
    return resolvedData;
};

const getTypeEvent = (type) => {
    if (type === CONSTANTS.EVENT_TYPE.ONLINE) return "ONLINE";
    if (type === CONSTANTS.EVENT_TYPE.OFFLINE) return "OFFLINE";
    return "DELETE";
};

const _getResolvedEvent = (newsData) => {
    const resolvedData = [];
    _.map(newsData, (data) => {
        resolvedData.push({
            id: data.id,
            title: data.title,
            type: getTypeEvent(data.type),
            location: data.location,
            image: data.url_img_aws,
            status: _.findKey(CONSTANTS.EVENT_STATUS, _.partial(_.isEqual, data.status)),
            date: moment(data.created_at).format("YYYY-MM-DD")
        });
    });
    return resolvedData;
};

const _getResolvedLibrary = (initialData) => {
    const resolvedData = [];
    _.map(initialData, (data) => {
        resolvedData.push({
            id: data.id,
            title: data.title ? data.title : i18n.language === "en" ? data.title_json.al : data.title_json.en,
            image: data.url_cover_img,
            date: moment(data.created_at).format("YYYY-MM-DD")
        });
    });
    return resolvedData;
};

const _getResolvedCriteria = (initialData) => {
    const resolvedData = [];
    _.map(initialData, (data) => {
        resolvedData.push({
            id: data.id,
            title: data.name,
            description: data.content,
            image: data.url_img_aws,
            date: moment(data.created_at).format("YYYY-MM-DD")
        });
    });
    return resolvedData;
};

const _getResolvedSponsors = (newsData) => {
    const resolvedData = [];
    _.map(newsData, (data) => {
        resolvedData.push({
            id: data.id,
            title: data.title,
            type: _.findKey(CONSTANTS.SPONSOR_TYPE, (o) => { return o === data.type; }),
            image: data.url_img_aws,
            date: moment(data.created_at).format("YYYY-MM-DD")
        });
    });
    return resolvedData;
};

const resolvedTableData = (initialData, type) => {
    if (type === CONSTANTS.TABLE_TYPE.USER) {
        return _getResolvedUserData(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.ACTIVITY) {
        return _getResolvedActivityData(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.NOTIFICATION) {
        return _getResolvedNotificationData(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.NOTIFICATION_USER) {
        return _getResolvedNotificationUser(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.APPLICATION) {
        return _getResolvedFundingApplicationData(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.APPLICATION_ADMIN_OVERVIEW) {
        return _getResolvedFundingApplicationDataAdminOverview(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.APPLICATION_OVERVIEW) {
        return _getResolvedFundingApplicationData(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.JUDGE_APPLICATION) {
        return _getResolvedJudgeApplication(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.APPLY_APPLICATION) {
        return _getResolvedApplyApplication(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.NEWS) {
        return _getResolvedNews(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.EVENT) {
        return _getResolvedEvent(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.LIBRARY) {
        return _getResolvedLibrary(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.TESTIMONIAL) {
        return _getResolvedTestimonial(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.DOCUMENT_TEMPLATE) {
        return _getResolvedDocumentTemplate(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.SPONSOR) {
        return _getResolvedSponsors(initialData);
    }
    if (type === CONSTANTS.TABLE_TYPE.CRITERIA) {
        return _getResolvedCriteria(initialData);
    }
};

export default {
    checkColorStatusCell,
    resolvedTableData
};
