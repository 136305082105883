import ActionTypes from "../constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  dataNotification: [],
  dataUserExplore: [],
  dataFundingApplication: [],
  selectedExplore: {},
  dataRolesName: {},
  dataUserReport: {},
  updateData: {},
  userDetail: {},
  usersForMessage: [],
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ActionTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        dataNotification: action.data,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.GET_USER_EXPLORE:
      return {
        ...state,
        dataUserExplore: action.data,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.GET_USERS_FOR_MESSAGE:
      return {
        ...state,
        usersForMessage: action.data,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.GET_FUNDING_APPLICATION:
      return {
        ...state,
        dataFundingApplication: action.data,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.GET_USER_EXPLORE_BY_ID:
      return {
        ...state,
        selectedExplore: action.data,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.CREATE_USER_EXPLORE:
      return {
        ...state,
        dataUserExplore: action.data,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.UPDATE_USER_EXPLORE:
      return {
        ...state,
        updateData: action.data,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.GET_ROLES_NAME:
      return {
        ...state,
        dataRolesName: action.data,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.GET_USER_REPORT:
      return {
        ...state,
        dataUserReport: action.data,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    case ActionTypes.USER_OVERVIEW_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.USER_OVERVIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.USER_OVERVIEW_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.USER_OVERVIEW_SET_USER_DETAIL: {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
        userDetail: action.data,
      };
    }
    default:
      return state;
  }
};
