import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    MainContent,
    ContentIntroduce,
    TitleIntroduce
}
    from './Investors.styles';
import { Images } from '../../../themes';
import NavigationBar from '../../../components/Common/NavigationBar';
import SummaryOverview from '../../../components/Common/SummaryOverview';
import Banner from '../../../components/Common/Banner';
import DefaultLayout from '../../../components/Layout/GuestTemplate/DefaultLayout';
import TestimonialSlider from '../../../components/Common/TestimonialSlider';
import IntroduceActions from "../../../actions/Introcude";

const Introduce = () => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const isLoading = useSelector((state) => state.Introduce.isLoading);
    const dataIntroduce = useSelector((state) => state.Introduce.introduce);
    const [data, setData] = useState({
        en: {
            title: "",
            description: "",
            content: ""
        },
        al: {
            title: "",
            description: "",
            content: ""
        }
    });
    useEffect(() => {
        const dataGet = {
            type: "investor_introduce"
        };
        dispatch(IntroduceActions.getIntroduce(dataGet));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isUndefined(dataIntroduce.data_json)) {
            setData(dataIntroduce.data_json);
        }
    }, [dataIntroduce]);

    const listNav = [
        {
            title: t("general.What We Do"),
            isActive: true,
            path: "/homepage/investors/what-we-do"
        },
        {
            title: t("general.Community"),
            isActive: false,
            path: "/homepage/investors/community"
        },
        {
            title: t("general.Process"),
            isActive: false,
            path: "/homepage/investors/process"
        },
        {
            title: t("general.Apply"),
            isActive: false,
            path: "/homepage/investors/apply"
        }
    ];

    const _renderContent = () => {
        return (
            <div>
                {data[i18n.language]
                    && <ContentIntroduce className="text" dangerouslySetInnerHTML={{ __html: data[i18n.language].content }} />
                }
                <TitleIntroduce>
                    {t("homepageUser.Here's what some of our members have to say")}
                </TitleIntroduce>
                <TestimonialSlider />
            </div>
        );
    };
    const _renderMain = () => {
        return (
            <MainContent>
                <Banner
                    backgroundImg={Images.banner2}
                    title={data[i18n.language].title || ""}
                    description={data[i18n.language].description || ""}
                />
                <NavigationBar listNav={listNav} />
                {_renderContent()}
                <SummaryOverview />
            </MainContent>
        );
    };

    return (
        <DefaultLayout context={_renderMain()} currentPage="investor" currentTop={true} isLoading={isLoading} />
    );
};

export default Introduce;
