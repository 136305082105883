import Api from "./Base";

export function signup(data) {
    return Api.post("/accounts/create_user", data);
}

export function loadUser(data) {
    return Api.post("/accounts/get_user", data);
}

export function getAllUser(data) {
    return Api.post("/accounts/search_user", data);
}

export function getUserList(data) {
    return Api.post("/users/get_by_ids", data);
}

export function login(data) {
    return Api.authRequest("/login", data);
}

export function updateUser(data) {
    return Api.put("/accounts/update_user", data);
}
