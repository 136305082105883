import ActionTypes from "../constants/ActionTypes";

const initialState = {
    hasError: false,
    isLoading: false,
    listUsers: [],
    listFunding: [],
    filter: "",
    reportUser: {
        account_verified_amount: 0,
        all_account_amount: 0,
        business_amount: 0,
        investor_amount: 0,
        consultant_amount: 0
    },
    userDetails: [],
    userId: 0,
    roles: [],
    activities: [],
    allActivities: [],
    applyApplication: {},
    applyId: 0,
    evaluationQuestionnaire: [],
    evaluationApply: []
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ActionTypes.ADMIN_OVER_VIEW_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.ADMIN_OVER_VIEW_IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.ADMIN_OVER_VIEW_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false
            };
        case ActionTypes.SET_FILTER_USERS_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                filter: action.filter
            };
        case ActionTypes.GET_ALL_LIST_USERS_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                listUsers: action.payload
            };
        case ActionTypes.REPORT_ADMIN_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                reportUser: action.payload
            };
        case ActionTypes.GET_USER_DETAILS_BY_ID:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                userDetails: action.payload
            };
        case ActionTypes.SET_USER_ID:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                userId: action.payload
            };
        case ActionTypes.GET_ROLES:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                roles: action.payload
            };
        case ActionTypes.GET_ACTIVITIES_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                activities: action.payload
            };
        case ActionTypes.GET_ALL_ACTIVITIES_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                allActivities: action.payload
            };
        case ActionTypes.RESET_FILTER:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                filter: 'all'
            };
        case ActionTypes.ADMIN_OVERVIEW_SET_APPLY_ID:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                applyId: action.payload
            };
        case ActionTypes.ADMIN_GET_EVALUATION_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                evaluationQuestionnaire: action.payload
            };
        case ActionTypes.ADMIN_GET_EVALUATION_APPLY_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                evaluationApply: action.payload
            };
        default:
            return state;
    }
};
