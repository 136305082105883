import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import Constants from "../constants/Constants";
import i18n from "../i18n";

const requestHasError = () => {
    return {
        type: ActionTypes.CRITERIA_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.CRITERIA_REQUEST
    };
};

const addCriteriaSuccess = (payload) => {
    return {
        type: ActionTypes.ADD_CRITERIA_SUCCESS,
        payload
    };
};

const updateCriteriaSuccess = (payload) => {
    return {
        type: ActionTypes.UPDATE_CRITERIA_SUCCESS,
        payload
    };
};

const getCriteriaByIdSuccess = (payload) => {
    return {
        type: ActionTypes.GET_CRITERIA_BY_ID_SUCCESS,
        payload
    };
};

const getAllCriteriaSuccess = (payload) => {
    return {
        type: ActionTypes.GET_ALL_CRITERIA_SUCCESS,
        payload
    };
};
const setCriteriaId = (payload) => {
    return {
        type: ActionTypes.SET_CRITERIA_ID_SUCCESS,
        payload
    };
};

const getCriteriaSettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GET_CRITERIA_SETTING_SUCCESS,
        payload
    };
};

const updateCriteriaSettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GET_CRITERIA_SETTING_SUCCESS,
        payload
    };
};

const addCriteria = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addCriteria(data)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    dispatch(addCriteriaSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateCriteria = (data, id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateCriteria(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    dispatch(updateCriteriaSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getAllCriteria = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getAllCriteria(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getAllCriteriaSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateStatusCriteria = (data, id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateCriteria(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    const dataGet = {
                        page: 1,
                        item_per_page: Constants.ITEM_PER_PAGE,
                        language: i18n.language
                    };
                    dispatch(getAllCriteria(dataGet));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getCriteriaById = (data, id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getCriteriaById(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getCriteriaByIdSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getCriteriaSettingData = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getMetaData(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getCriteriaSettingDataSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateCriteriaSettingData = (formPayload) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateMetaDataWithForm(formPayload)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(updateCriteriaSettingDataSuccess(res.data.data));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const deleteCriteria = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.deleteCriteria(id)
            .then((res) => {
                if (res.data.status === true) {
                    const dataGet = {
                        page: 1,
                        item_per_page: Constants.ITEM_PER_PAGE,
                        language: i18n.language
                    };
                    dispatch(getAllCriteria(dataGet));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};
export default {
    addCriteria,
    getCriteriaById,
    getAllCriteria,
    setCriteriaId,
    updateCriteria,
    deleteCriteria,
    updateStatusCriteria,
    getCriteriaSettingData,
    updateCriteriaSettingData
};
