import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import {
    Wrapper,
    ProgressBar,
    DetailTitle,
    Column,
    Field
} from "./Judge.styles";
import {
    BreadCrumb, LabelSlide
} from "../../../components/Common/CommonLayout";
import CONSTANTS from "../../../constants/Constants";
import JudgeActions from '../../../actions/Judge';
import Button from "../../../components/Common/Button";
import { ColorName } from "../../../components/Variables";
import history from "../../../history";
import Utils from "../../../libs/Utils";
import Validation from "../../../libs/Validation";

const ApplyApplicationDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const isLoading = useSelector((state) => state.Judge.isLoading);
    const applyApplication = useSelector((state) => state.Judge.applyById);
    const [details, setDetails] = useState([]);
    const [listFile, setListFile] = useState([]);
    const [listFileRemove, setListFileRemove] = useState([]);
    const [totalPoint, setTotalPoint] = useState("");
    const userData = Utils.getSavedUserData();

    useEffect(() => {
        if (location.state.id !== 0) {
            dispatch(JudgeActions.getEvaluationById(location.state.id));
        }
        dispatch(JudgeActions.getApplyApplicationById(location.state.fundingID));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (applyApplication) {
            setDetails(applyApplication);
        }
    }, [applyApplication]);

    useEffect(() => {
        if (applyApplication.fundingApplication) {
            if (location.state.id === 0) {
                if (listFile.length < Object.keys(applyApplication.fundingApplication.document_evaluation_json).length) {
                    Array.from(Array(Object.keys(applyApplication.fundingApplication.document_evaluation_json).length), (e, i) => {
                        return listFile.push({});
                    });
                }
            } else if (applyApplication.evaluationApplies) {
                let evalute = {};
                const files = [];
                _.map(applyApplication.evaluationApplies, (child, index) => {
                    if (child.applicationJudge.user_id === userData.id) return evalute = child;
                });
                if (!_.isEmpty(evalute)) {
                    _.map(evalute.document_evaluation_json, (item, i) => {
                        return files.push({ [i]: item });
                    });
                    if (_.isEmpty(files)) files.push({});
                    setListFile(files);
                    setTotalPoint(evalute.total_point);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyApplication]);

    useEffect(() => {
        return () => {
            dispatch(JudgeActions.resetEvaluation());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const viewApply = () => {
        history.replace({
            state: { id: location.state.fundingID, judgeApplicationID: location.state.judgeApplicationID },
            pathname: "/judge/applications/details"
        });
    };

    const validate = () => {
        const validationRules = [
            {
                name: t("general.Total"),
                value: _.parseInt(totalPoint),
                rule: "number"
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const saveEvaluation = () => {
        let flag = false;
        flag = validate();
        if (flag) {
            const formData = new FormData();
            formData.append("application_judge_id", location.state.judgeApplicationID);
            formData.append("apply_application_id", applyApplication.id);
            formData.append("total_point", totalPoint);
            if (_.isArray(listFile)) {
                _.map(listFile, (child, index) => {
                    if (child.name) formData.append(`document_evaluation[${index}]`, child);
                });
            }
            dispatch(JudgeActions.saveEvaluation(formData, applyApplication.id));
        }
    };

    const updateEvaluation = () => {
        let flag = false;
        flag = validate();
        if (flag) {
            const formData = new FormData();
            formData.append("application_judge_id", location.state.judgeApplicationID);
            formData.append("apply_application_id", applyApplication.id);
            formData.append("total_point", totalPoint);
            formData.append("remove_document_evaluation", JSON.stringify(listFileRemove));
            if (_.isArray(listFile)) {
                _.map(listFile, (child, index) => {
                    formData.append(`document_evaluation[${index}]`, child);
                });
            }
            dispatch(JudgeActions.updateEvaluation(formData, location.state.id));
        }
    };

    const _renderTemplateFile = () => {
        const render = _.map(details.fundingApplication.document_evaluation_json, (child, index) => {
            return (
                <p key={index}>
                    <a href={child}>
                        {Utils.getNameFileUpload(index)}
                    </a>
                </p>
            );
        });
        return render;
    };

    const handChangeFileForWinner = (event, index, nameFile) => {
        if (event.target.files.length !== 0) {
            if (!listFileRemove.includes(nameFile)) listFileRemove.push(nameFile);
            const temp = [...listFile];
            temp[index] = event.target.files[0];
            setListFile(temp);
        }
    };

    const addNewFile = () => {
        const temp = [...listFile];
        temp.push({});
        setListFile(temp);
    };

    const removeNewFile = (index, file) => {
        const arrayRemove = [];
        if (!_.isEmpty(file)) {
            if (!_.has(file, "name")) {
                arrayRemove.push(_.keys(file).toString());
            }
        }
        const files = [...listFile];
        if (index > -1) {
            files.splice(index, 1);
        }
        if (_.isEmpty(files)) files.push({});
        setListFile(files);
        setListFileRemove(arrayRemove);
    };

    const _renderUploadTemplate = () => {
        const render = _.map(listFile, (e, i) => {
            const nameFile = !_.isUndefined(listFile[i].name) ? listFile[i].name : _.keys(listFile[i]).toString();
            return (
                <div key={i} className="input-file-container">
                    <LabelSlide className="upload-file">
                        <input type="file" onChange={(event) => handChangeFileForWinner(event, i, nameFile)} />
                        {listFile[i] && (
                            <p>
                                <i className="fas fa-upload" />
                                {!_.isUndefined(listFile[i].name) ? Utils.getNameFileUpload(listFile[i].name) : Utils.getNameFileUpload(_.keys(listFile[i]).toString()) || t("general.Choose file")}
                            </p>
                        )}
                    </LabelSlide>
                    {(i === 0 && listFile[i])
                        && (
                            <div className="action-upload">
                                <span onClick={() => addNewFile()}><i className="fas fa-plus" /></span>
                                <span onClick={() => removeNewFile(i, listFile[i])}><i className="fas fa-trash-alt" /></span>
                            </div>
                        )
                    }
                    {i !== 0
                        && (
                            <div className="action-upload">
                                <span onClick={() => removeNewFile(i, listFile[i])}><i className="fas fa-trash-alt" /></span>
                            </div>
                        )
                    }
                </div>
            );
        });
        return render;
    };

    return (
        <DefaultLayout
            userRole='judge'
            isLoading={isLoading}
            page="applications"
            content={(
                <Wrapper>
                    <BreadCrumb>
                        {t("fundingApplications.Funding Application Details")}
                    </BreadCrumb>
                    <ProgressBar className="mb-2">
                        <Column>
                            <DetailTitle>
                                {t("fundingApplications.Funding Opportunity’s name")}
                                : &nbsp;
                                <span className="color-title">{!_.isUndefined(details.fundingApplication) ? details.fundingApplication.title : ""}</span>
                            </DetailTitle>
                        </Column>
                        <Column>
                            <DetailTitle>
                                {t("general.Status")}
                                :
                            </DetailTitle>
                            <span className="green">
                                {!_.isUndefined(details.fundingApplication)
                                    ? _.findKey(CONSTANTS.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, details.fundingApplication.status)) : ""
                                }
                            </span>
                        </Column>
                    </ProgressBar>
                    <BreadCrumb>
                        {t("fundingApplications.Evaluation")}
                    </BreadCrumb>
                    <ProgressBar className="mb-2">
                        <div className="group-input">
                            <Field className="field">
                                {t("general.Total Score")}
                            </Field>
                            <Field className="field">
                                <input
                                    type="number"
                                    value={totalPoint}
                                    className="style-input"
                                    onChange={(e) => setTotalPoint(e.target.value)}
                                />
                            </Field>
                        </div>
                        <div className="container-upload">
                            <Field className="upload-container">
                                <div>
                                    {t("general.Template For Evaluation")}
                                </div>
                                {!_.isUndefined(details.fundingApplication)
                                    && _renderTemplateFile()
                                }
                            </Field>
                            <Field className="upload-container">
                                <div>
                                    {t("general.Document for evaluation")}
                                </div>
                                {_renderUploadTemplate()}
                            </Field>
                        </div>

                        <div className="btn-footer">
                            <Button
                                color={ColorName.white}
                                background={ColorName.chathamsBlueDark}
                                title={t("general.Back")}
                                height="35px"
                                width="9em"
                                margin="1em 1.5em 0 0"
                                padding='1em'
                                onClick={() => viewApply()}
                            />
                            <Button
                                color={ColorName.white}
                                background={ColorName.chathamsBlueDark}
                                title={t("general.Save")}
                                height="35px"
                                width="9em"
                                margin="1em 1.5em 0 0"
                                padding='1em'
                                onClick={location.state.id === 0 ? () => saveEvaluation() : () => updateEvaluation()}
                            />
                        </div>
                    </ProgressBar>
                </Wrapper>
            )}
        />
    );
};

export default ApplyApplicationDetails;
