import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  Container,
  GridColHomepage,
  FlexGridTwelveTemplate,
  MainContent,
} from "./SearchBar.styles.js";

import { SwitchButton } from "../EnableMultipleLanguageButton";
import LIST from "./list";
import CountryList from "../../../constants/CountryList";
import CONSTANTS from "../../../constants/Constants";
import CommunityActions from "../../../actions/Community";

const cities = require("country-city");

const SearchBar = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { noDropdown, pageType, roleName, withFilter } = props;
  const listEntrepreneurs = [
    { Industry: LIST.industryOptions, key: "industry_operate" },
    { "Year of establishment": LIST.getYear(), key: "establishment_year" },
    { Country: CountryList.countryListDefault, key: "located" },
  ];
  const listInvestor = [
    { Type: LIST.investorTypeList, key: "property_type" },
    { Country: CountryList.countryListDefault, key: "located" },
  ];
  const listConsultants = [
    { Type: LIST.consultantsTypeList, key: "property_type" },
    { Country: CountryList.countryListDefault, key: "located" },
  ];

  const [listSearch, setListSearch] = useState([]);

  const [searchIdea, setSearchIdea] = useState("");

  const [arraySearch, setArraySearch] = useState({});

  const [isBusinessIdea, setIsBusinessIdea] = useState(withFilter);

  const setTypeValue = (value) => {
    let result = "";
    switch (value) {
      case (value = "INDIVIDUAL"):
        result = CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL;
        break;
      case (value = "BUSINESS"):
        result = CONSTANTS.USER_PROPERTY_TYPE.BUSINESS;
        break;
      case (value = "VENTURE FIRM/IMPACT INVESTMENT"):
        result = CONSTANTS.USER_PROPERTY_TYPE.VENTURE_INVESTMENT;
        break;
      case (value = "PRIVATE ENTITY"):
        result = CONSTANTS.USER_PROPERTY_TYPE.PRIVATE_ENTITY;
        break;
      default:
        return (result = "");
    }
    return result;
  };

  useEffect(() => {
    if (pageType === "entrepreneurs") setListSearch(listEntrepreneurs);
    if (pageType === "investor") setListSearch(listInvestor);
    if (pageType === "consultants") setListSearch(listConsultants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _selectWithDropdown = (e, index, type) => {
    const arrayTemp = { ...arraySearch };
    arrayTemp[index] = e.target.value;
    setArraySearch(arrayTemp);

    if (type === "Country") {
      const cityArrays = cities.getCities(e.target.value);
      const listCity = { City: cityArrays, key: "city" };
      let currentList = [...listSearch];
      currentList = _.remove(currentList, (el) => _.keys(el)[0] !== "City");
      currentList.push(listCity);
      setListSearch(_.uniq(currentList));
    }
  };

  const getResult = () => {
    if (withFilter) {
      const data = {
        business_idea_name: searchIdea,
        role_name: "Entrepreneur",
        is_business_idea: true,
        property_type: CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL,
      };
      dispatch(CommunityActions.fetchCommunity(data));
    } else {
      const currentSearchArray = { ...arraySearch };
      if (_.has(currentSearchArray, "property_type"))
        currentSearchArray.property_type = setTypeValue(
          currentSearchArray.property_type
        );
      else if(pageType === "entrepreneurs") currentSearchArray.property_type = "2";
      currentSearchArray.role_name = roleName;
      dispatch(CommunityActions.fetchCommunity(currentSearchArray));
    }
  };

  const onKeyEnterPress = (e) => {
    if (e.which === 13) {
      getResult();
    }
  };

  const _renderDropdownItem = (child) => {
    const render = _.map(child, (item, key) => {
      if (key === "Industry" || key === "Type") {
        return _.map(item, (value, index) => {
          return (
            <option key={index} value={value}>
              {t(`general.${value}`)}
            </option>
          );
        });
      }
      if (key !== "key") {
        return _.map(item, (value, index) => {
          return (
            <option key={index} value={value}>
              {value}
            </option>
          );
        });
      }
    });
    return render;
  };

  const _deleteItemSearch = (key) => {
    const newData = _.omit(arraySearch, key);
    setArraySearch(newData);
  };

  const _deleteAllSearchItem = () => {
    setArraySearch({});
  };

  const _renderListDropdown = () => {
    const render = _.map(listSearch, (child, index) => {
      const valueItem = _.head(_.keys(child));
      return (
        <select
          value={valueItem}
          key={index}
          onChange={(e) => _selectWithDropdown(e, child.key, valueItem)}
        >
          <option value={valueItem} disabled>
            {t(`homepageUser.${valueItem}`)}
          </option>
          {_renderDropdownItem(child)}
        </select>
      );
    });
    return render;
  };

  const _renderItemSearch = () => {
    const render = _.map(arraySearch, (child, key) => {
      let labelSearch = "";
      if (key === "industry_operate" || key === "property_type") {
        labelSearch = t(`general.${child}`);
      } else {
        labelSearch = child;
      }
      return (
        <div className="container-label" key={key}>
          <span className="lable-search">
            {labelSearch.substring(0, 30)}
            {labelSearch.length > 30 && "..."}
          </span>
          <span onClick={() => _deleteItemSearch(key)} className="delete-icon">
            X
          </span>
        </div>
      );
    });
    return render;
  };

  const _renderInputSearch = () => {
    return (
      <input
        type="text"
        className="input-search"
        onKeyPress={onKeyEnterPress}
        placeholder={t("homepageUser.Search")}
        onChange={(e) => setSearchIdea(e.target.value)}
      />
    );
  };

  const setIsBusinessIdeaButton = () => {
    if (!isBusinessIdea) setArraySearch([]);
    setIsBusinessIdea(!isBusinessIdea);
    props.filterBy(!isBusinessIdea);
  };

  const _renderContent = () => {
    return (
      <div className="content-page">
        <FlexGridTwelveTemplate className="justify-content">
          <GridColHomepage
            colSize="10"
            gridTemplate="12"
            className="container-top-search"
          >
            <i className="fas fa-search" onClick={() => getResult()} />
            {!noDropdown && _renderItemSearch()}
            {noDropdown && _renderInputSearch()}
            {noDropdown && searchIdea && (
              <span className="button-search-idea" onClick={() => getResult()}>
                {t("homepageUser.Search")}
              </span>
            )}
            {!_.isEmpty(arraySearch) && (
              <span onClick={() => getResult()}>
                {t("homepageUser.Search")}
              </span>
            )}
          </GridColHomepage>
          <div
            className="clear-item-search"
            onClick={() => _deleteAllSearchItem()}
          >
            <span>{noDropdown ? "" : t("general.Clear criteria")}</span>
          </div>
        </FlexGridTwelveTemplate>
        {!noDropdown && (
          <FlexGridTwelveTemplate className="container-dropdown">
            <GridColHomepage colSize="12" gridTemplate="12">
              {_renderListDropdown()}
            </GridColHomepage>
          </FlexGridTwelveTemplate>
        )}
      </div>
    );
  };

  const _renderMain = () => {
    return (
      <Container className={withFilter ? "" : "no-filter"}>
        {_renderContent()}
      </Container>
    );
  };
  return (
    <MainContent>
      {_renderMain()}
      {pageType === "entrepreneurs" && (
        <div className="for-filter">
          <SwitchButton className="switch search-bar">
            <input
              type="checkbox"
              checked={withFilter}
              onChange={() => setIsBusinessIdeaButton()}
              value={isBusinessIdea}
            />
            <span className="slider round" />
          </SwitchButton>
          {t("general.Filter By Business Idea")}
        </div>
      )}
    </MainContent>
  );
};

SearchBar.propTypes = {
  noDropdown: PropTypes.bool,
  withFilter: PropTypes.bool,
  pageType: PropTypes.string,
  roleName: PropTypes.string,
  filterBy: PropTypes.func,
};

export default SearchBar;
