import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer, BreadCrumb } from '../../../../components/Common/CommonLayout';
import { ColorName } from "../../../../components/Variables";

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .ml-1 {
        margin-left: 1em;
    }
    & .mb-2 {
        margin-bottom: 2em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
`;

const Report = styled(HorizontalContainer)`
    flex: 1;
    margin-bottom: 3em;
`;

const BlockReport = styled.div`
    margin-right: 40px;
    flex: 1;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    &.report-status {
        display: flex;
        margin-top: 3em;
        margin-bottom: 2em;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const WrapperModal = styled.div`
    display: flex;
    position: fixed;
    z-index: 9;
    background-color: rgba(16, 16, 16, 0.5);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
`;

const ModalContent = styled(HorizontalContainer)`
    background: ${ColorName.white};
    border-radius: 25px;
    border: 3px solid ${ColorName.gallery};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
`;

const WrapperTitleContent = styled(HorizontalContainer)`
    margin-bottom: 1em;
    width: 100%;
    & .custom-title {
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        min-width: 10em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
    &.justify-center {
        justify-content: center;
    }
    & input{
        &.inputCalendar{
            margin-top: 0px;
        }
    }
`;

const PageTitle = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    text-transform: none;
    margin-bottom: ${(props) => props.marginBottom || "1.5em"};
    margin-right: ${(props) => props.marginRight || "1em"};
    &.inputEdit{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`;

const FundingDetailApplicationsComponent = styled(VerticalContainer)`
    font-size: 16px;
    font-weight: 600;
    & .d-flex {
        display: flex;
    }
    & .mt-title {
        margin-top: 5px;
    }
    & .w-title {
        width: 140px;
    }
    & .color-green {
        color: ${ColorName.fern}
    }
`;

export {
    BodyContainer,
    Report,
    BlockReport,
    Title,
    WrapperModal,
    ModalContent,
    WrapperTitleContent,
    PageTitle,
    FundingDetailApplicationsComponent
};
