import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import {
    Container,
    GridColHomepage,
    FlexGridSixthTemplate
} from "./Subscribe.styles";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import SummryOverview from "../../../components/Common/SummaryOverview";
import Utils from "../../../libs/Utils";
import { ColorName } from "../../../components/Variables";
import Button from "../../../components/Common/ButtonFrontEnd";
import SubscribeActions from '../../../actions/Subscribe';
import history from "../../../history";

const About = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.Subscribe.isLoading);
    const isSetNewData = useSelector((state) => state.Subscribe.isSetNewData);
    const [userData, setUserData] = useState(Utils.getSavedUserData());

    useEffect(() => {
        if (_.isNull(userData)) return history.push('/authentication');
    }, [userData]);
    
    useEffect(() => {
        if (isSetNewData) return setUserData(Utils.getSavedUserData());
    }, [isSetNewData]);

    const subscribe = () => {
        const data = {
            email: userData.email
        };
        dispatch(SubscribeActions.subscribe(data));
    };

    const unsubscribe = () => {
        const data = {
            email: userData.email
        };
        dispatch(SubscribeActions.unsubscribe(data));
    };

    const _renderContentSubscribe = () => {
        return (
            <div className="content-page">
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <div className='container-sub'>
                            <div className="title">
                                {userData.is_receive_auto_mail ? 'You have already subscribed to receive automatic emails from Dua Partner Invest!' : 'Subscribe to Dua Partner Invest!'}
                            </div>
                            <div className='text'>
                                <Button
                                    onClick={() => userData.is_receive_auto_mail ? unsubscribe() : subscribe()}
                                    background={ColorName.chathamsBlueDark}
                                    color={ColorName.white}
                                    name={userData.is_receive_auto_mail ? "Unsubscribe" : "Subscribe"}
                                />
                            </div>
                        </div>
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                
            </div>
        );
    };

    const _renderSummaryOverview = () => {
        return <SummryOverview />;
    };

    const _renderMain = () => {
        return (
            <Container>
                {userData && _renderContentSubscribe()}
                {/* {_.isNull(userData) && _renderContentUnsubscribe()} */}
                {_renderSummaryOverview()}
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
            currentPage="about"
            isLoading={isLoading}
        />
    );
};

About.propTypes = {
};

export default About;
