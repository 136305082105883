import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  PageTitle,
  ProgressBarFunding,
  WrapperContent,
  WrapperButton,
  Field,
  Report,
  BlockReport,
  Title
} from "./FundingDetail.styles";
import Button from "../../../../components/Common/Button";
import history from "../../../../history";
import { ColorName } from "../../../../components/Variables";
import TextArea from "../../../../components/Common/TextArea";
import UserFundingDetailApplications from "../../../../components/Common/UserFundingDetailApplications";
import FundingApplicationUserActions from "../../../../actions/FundingApplicationsUser";
import ProgressBar from "../../../../components/Common/ProgressBar";
import Utils from "../../../../libs/Utils";
import Constants from "../../../../constants/Constants";
import { LabelSlide } from "../../../../components/Common/CommonLayout";

import moment from 'moment';

const UserFundingDetail = () => {
  const { t } = useTranslation();
  const userData = Utils.getSavedUserData();
  const isLoading = useSelector((state) => state.FundingApplicationsUser.isLoading);
  const dispatch = useDispatch();
  const FundingStorage = useSelector((state) => state.FundingApplicationsUser);
  const [dataApplyApplicationItem, setDataApplyApplicationItem] = useState();
  const [dataFundingUserReport, setDataFundingUserReport] = useState(0);
  const [listFile, setListFile] = useState([]);
  const [listFileRemove, setListFileRemove] = useState([]);

  useEffect(() => {
    dispatch(FundingApplicationUserActions.getFundingUserReportRequest());
    if (_.isEmpty(FundingStorage.dataApplyApplicationItem) && !isLoading) {
      if (userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
        history.push("user-funding");
      } else {
        history.push("user-overview");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataApplyApplicationItem(FundingStorage.dataApplyApplicationItem);
    setDataFundingUserReport(FundingStorage.dataFundingUserReport);
    const files = [];
    _.map(FundingStorage.dataApplyApplicationItem.document_winner_json, (item, i) => {
      return files.push({ [i]: item });
    });
    if (_.isEmpty(files)) files.push({});
    setListFile(files);
  }, [FundingStorage]);

  const openQuestionnaire = () => {
    history.push('view-category-questionnaire');
  };

  const extraFields = () => {
    if (_.isEmpty(dataApplyApplicationItem)) return;
    const render = _.map(!_.isEmpty(dataApplyApplicationItem) && dataApplyApplicationItem.fundingApplication.extra_field_json, (data, index) => {
      return (
        <Field key={index}>
          <PageTitle marginBottom="0.6em" marginRight='auto'>
            {data.title}
          </PageTitle>
          <TextArea
            backgroundColor="lightgray"
            disabled={true}
            cursor="not-allowed"
            height='15em'
            width="95%"
            defaultValue={data.value || ''}
          />
        </Field>
      );
    });
    return render;
  };

  const scrollVertical = async (title) => {
    if (userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
      await history.push("user-funding");
      window.location.href = `${title}`;
    } else {
      history.push("user-overview");
    }
  };

  const redirectPage = () => {
    if (userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
      history.push("user-funding");
    } else {
      history.push("user-overview");
    }
  };

  const uploadTemplate = () => {
    const formData = new FormData();
    formData.append("remove_document_winner", JSON.stringify(listFileRemove));
    if (_.isArray(listFile)) {
      _.map(listFile, (child, index) => {
        if (_.isEmpty(child)) {
          formData.append(`document_winner[${index}]`, child);
        }
      });
    }
    dispatch(FundingApplicationUserActions.updateApplyApplicationDetailWinner(dataApplyApplicationItem.id, formData));
  };

  const _renderListFile = (listFiles) => {
    const render = _.map(listFiles, (child, index) => {
      return (
        <a href={child} target="_blank" rel="noopener noreferrer" key={index}>
          {index && _.last(index.split("/"))}
        </a>
      );
    });
    return render;
  };

  const handChangeFileForWinnerUpload = (event, index, nameFile) => {
    if (event.target.files.length !== 0) {
      if (!listFileRemove.includes(nameFile)) listFileRemove.push(nameFile);
      const temp = [...listFile];
      temp[index] = event.target.files[0];
      setListFile(temp);
    }
  };

  const addNewFile = () => {
    const temp = [...listFile];
    temp.push({});
    setListFile(temp);
  };

  const removeNewFile = (index, file) => {
    const arrayRemove = [];
    if (!_.isEmpty(file)) {
      if (!_.has(file, "name")) {
        arrayRemove.push(_.keys(file).toString());
      }
    }
    const files = [...listFile];
    if (index > -1) {
      files.splice(index, 1);
    }
    if (_.isEmpty(files)) files.push({});
    setListFile(files);
    setListFileRemove(arrayRemove);
  };

  const _renderUploadTemplate = () => {
    const render = _.map(listFile, (e, i) => {
      const nameFile = !_.isUndefined(listFile[i].name) ? listFile[i].name : _.keys(listFile[i]).toString();
      return (
        <div key={i} className="input-file-container">
          <LabelSlide className="upload-file">
            <input type="file" onChange={(event) => handChangeFileForWinnerUpload(event, i, nameFile)} />
            {listFile[i] && (
              <p className="noWrap">
                <i className="fas fa-upload" />
                {!_.isUndefined(listFile[i].name) ? Utils.getNameFileUpload(listFile[i].name) : Utils.getNameFileUpload(_.keys(listFile[i]).toString()) || t("general.Choose file")}
              </p>
            )}
            <div className="noWrap">
              {_.map(dataApplyApplicationItem.doc_winner_updated_at, (item, key) => {
                if (key === _.keys(listFile[i]).toString()) return <span>&nbsp;- {moment(item).format("DD-MM-YYYY hh:mm")}</span>
              })}
            </div>
          </LabelSlide>

          {(i === 0 && listFile[i])
            && (
              <div className="action-upload">
                <span onClick={() => addNewFile()}><i className="fas fa-plus" /></span>
                <span onClick={() => removeNewFile(i, listFile[i])}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
          {i !== 0
            && (
              <div className="action-upload">
                <span onClick={() => removeNewFile(i, listFile[i])}><i className="fas fa-trash-alt" /></span>
              </div>
            )
          }
        </div>
      );
    });
    return render;
  };

  return (
    <DefaultLayout
      userRole='user'
      isLoading={isLoading}
      page="userFundingDetail"
      content={(
        <BodyContainer>
          {_.isEmpty(dataFundingUserReport) ? t("general.Data Not Found") : (
            <Report>
              <BlockReport>
                <ProgressBar
                  value={dataFundingUserReport.my_application_amount || "0"}
                  title={t("general.My Funding Applications")}
                  callBackAction={() => scrollVertical("#my-applications")}
                  filter="user overview"
                  total={100}
                />
              </BlockReport>
              {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER && (
                <BlockReport>
                  <ProgressBar
                    value={dataFundingUserReport.funding_application_amount || "0"}
                    title={t("fundingApplications.Available Funding Opportunities")}
                    callBackAction={() => scrollVertical("#apply-applications")}
                    filter="user overview"
                    total={100}
                  />
                </BlockReport>
              )}
              <BlockReport />
            </Report>
          )}
          <Title>
            {!_.isEmpty(dataApplyApplicationItem) && dataApplyApplicationItem.fundingApplication.title}
          </Title>
          <UserFundingDetailApplications />
          <PageTitle marginTop="3em">{t("fundingApplications.Project Description")}</PageTitle>
          <ProgressBarFunding className="mb-2">
            <WrapperContent>
              {extraFields()}
            </WrapperContent>
            <WrapperButton className="ml-1">
              <Button
                title={t("general.Back")}
                height="35px"
                width="10em"
                margin="0 1.5em 0 0"
                padding='10px'
                onClick={() => redirectPage()}
                background={ColorName.chathamsBlueDark}
              />
              <Button
                title={t("fundingApplications.Application Form")}
                height="35px"
                width="10em"
                margin="0 1.5em 0 0"
                padding='10px'
                onClick={() => openQuestionnaire(dataApplyApplicationItem.status)}
                background={ColorName.chathamsBlueDark}
                className="none-capital"
              />
            </WrapperButton>
          </ProgressBarFunding>
          {(!_.isUndefined(dataApplyApplicationItem) && dataApplyApplicationItem.status === Constants.APPLY_APPLICATION_STATUS.APPROVED)
            && (
              <ProgressBarFunding className="mb-2">
                <WrapperContent className="for-winner">
                  <PageTitle marginBottom="0" marginRight='auto'>
                    {t("general.Directions To The Winners")}
                  </PageTitle>
                  <div className="text-winner">{dataApplyApplicationItem.fundingApplication.direction_winner}</div>
                </WrapperContent>
                <WrapperContent>
                  <Field className="upload-container">
                    <PageTitle marginBottom="0" marginRight='auto'>
                      {t("general.Document for reporting")}
                    </PageTitle>
                    {!_.isEmpty(dataApplyApplicationItem)
                      && (
                        <p className="list-files-default">
                          {_renderListFile(dataApplyApplicationItem.fundingApplication.document_winner_json)}
                        </p>
                      )}
                  </Field>
                  <Field className="upload-container">
                    <PageTitle marginBottom="1em" marginRight='auto'>
                      {`${t("general.Upload")} ${t("general.Document for reporting")}`}
                    </PageTitle>
                    {_renderUploadTemplate()}
                    <Button
                      title={t("general.Upload")}
                      height="35px"
                      width="10em"
                      margin="1em 1.5em 0 0"
                      padding='10px'
                      onClick={() => uploadTemplate()}
                      background={ColorName.chathamsBlueDark}
                    />
                  </Field>

                </WrapperContent>
              </ProgressBarFunding>
            )
          }

        </BodyContainer>
      )}
    />
  );
};

UserFundingDetail.propTypes = {
  categoryQuestionnaire: PropTypes.func
};

export default UserFundingDetail;
