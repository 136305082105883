import Api from "./Base";

export function fetchAllLibrary(data) {
    return Api.get(`/library_fetch`, data);
}

export function getLibraryDataByID(id) {
    return Api.get(`/library/${id}`);
}

export function createNewBook(data) {
    return Api.postFormData(`/library`, data);
}

export function deleteBook(id) {
    return Api.post(`/library/${id}/delete`);
}

export function updateBook(id, data) {
    return Api.postFormData(`/library/${id}/update`, data);
}

export function searchLibrary(data) {
    return Api.get(`/library_fetch`, data);
}
