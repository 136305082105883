import styled from 'styled-components';
import { ContentBodyContainer, GridCol, FlexGridSixth } from '../CommonLayout';
import { ColorName } from "../../Variables";

const Container = styled(ContentBodyContainer)`
    width: 100%;
    &.no-filter{
        width: 100%;
    }
    & .content-page{
        border: 1px solid ${ColorName.chathamsBlueDark};
    }
    color: ${ColorName.chathamsBlueDark};
    margin: unset;
    margin-right: 0.6em;
`;

const FlexGridTwelveTemplate = styled(FlexGridSixth)`
    padding: 0.3em 1em;
    position: relative;
    & .border-top{
        border-top: 1px solid ${ColorName.chathamsBlueDark};
    }
    &.justify-content{
        justify-content: space-between;
    }
    &.container-dropdown{
        background: ${ColorName.chathamsBlueDark};
        color: ${ColorName.oysterBay};
        & select{
            color: ${ColorName.oysterBay};
            background: ${ColorName.chathamsBlueDark};
            border: none;
            outline: none;
            margin-right: 2em;
            font: 300 1em 'Lato', sans-serif;
            cursor: pointer;
            max-width: 170px;
        & option{
            cursor: pointer;
        }
        }
    }
    & .clear-item-search{
        font-size: 1em; 
        font-weight: 300;
        cursor: pointer;
        display: flex;
        align-items: center;
        & span{
            border-left: 1px solid ${ColorName.chathamsBlueDark};
        }
    }
`;
const GridColHomepage = styled(GridCol)`
    font-size: 1em; 
    font-weight: 300;
    &.flex-end{
        display: flex;
        justify-content: flex-end;
    }
    & i{
        &.fa-search{
            cursor: pointer;
        }
    }
    & .button-search-idea{
        position: absolute;
        right: 1em;
    }
    &.container-top-search{
        display: flex;
        align-items: center;
        & .container-label{
            color: ${ColorName.oysterBay};
            margin: 0 0.5em;
            padding: 0 0.5em;
            min-width: 6em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            background: ${ColorName.fern};
            & .delete-icon{
                cursor: pointer;
                margin-left: 1em;
                color: black;
                font-weight: bold;
            }
        }
        & span{
            cursor: pointer;
        }
        & .input-search{
            border: none;
            margin: 0 0.5em;
            outline: none;
            width: 100%;
        }
    }
`;

const MainContent = styled.div`
    display: flex;
    & .content-filter{
        outline: none;
        border: none;
        border: 1px solid #1B4687;
        width: 22%;
        display: flex;
        align-items: center;
        padding: 0 1em;
        height: 28px;
        color: ${ColorName.chathamsBlueDark};
    }
    color: ${ColorName.chathamsBlueDark};
    & .label{
        border: none !important;
    }
    & .for-filter{
        min-width: 270px;
        height: 30px;
        display: flex;
        align-items: center;
        & label{
            margin-right: 0.5em;
        }
    }
`;

export {
    Container,
    GridColHomepage,
    FlexGridTwelveTemplate,
    MainContent
};
