import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";

const requestIsLoading = () => {
    return {
        type: ActionTypes.CONTACT_REQUEST
    };
};

const requestHasError = () => {
    return { 
        type: ActionTypes.CONTACT_FAILURE
    };
};

const getContactSuccess = (payload) => {
    return {
        type: ActionTypes.GET_CONTACT_SUCCESS,
        payload
    };
};

const addContactSuccess = (payload) => {
    return {
        type: ActionTypes.ADD_CONTACT_SUCCESS,
        payload
    };
};

const addContactUs = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addMetaData(data)
            .then((res) => {
                dispatch(addContactSuccess(res.data.data));
                Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
            }).catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getContactUs = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getMetaData(data)
            .then((res) => {
                dispatch(getContactSuccess(res.data.data));
            }).catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    addContactUs,
    getContactUs
};
