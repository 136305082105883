import ActionTypes from "../constants/ActionTypes";

const initialState = {
    hasError: false,
    isLoading: false,
    dataMessage: [],
    listUsers: [],
    userReceive: {},
    channel: 0,
    listUserForAdmin: [],
    numberUnread: 0,
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ActionTypes.MESSAGE_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.MESSAGE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.MESSAGE_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                dataMessage: action.data
            };
        case ActionTypes.GET_LIST_USERS_ADMIN_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                listUserForAdmin: action.data
            };
        case ActionTypes.GET_LIST_USERS_SUCCESS:
            return {
                ...state,
                hasError: false,
                listUsers: action.data
            };
        case ActionTypes.GET_LIST_USERS_BACKGROUND_SUCCESS:
            return {
                ...state,
                hasError: false,
                listUsers: action.data
            };
        case ActionTypes.GET_CHANNEL_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                channel: action.data
            };
        case ActionTypes.SET_USER_RECEIVE:
            return {
                ...state,
                hasError: false,
                // isLoading: false,
                userReceive: action.data
            };
        case ActionTypes.GET_MESSAGE_BACKGROUND_SUCCESS:
            return {
                ...state,
                hasError: false,
                dataMessage: action.data
            };
        case ActionTypes.DELETE_CHANNEL_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false
            };
        case ActionTypes.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false
            };
        case ActionTypes.SET_NUMBER_UNREAD_MESSAGE_SUCCESS:
            return {
                ...state,
                hasError: false,
                numberUnread: action.data
            };
        default:
            return state;
    }
};
