import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container,
    PageTitle,
    SubmitButton,
    UserTypeLabel
} from "./UserRegister.styles";
import GeneralCheckBoxForm from "../../../components/Common/GeneralCheckBoxForm";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import history from "../../../history";
import Banner from "../../../components/Common/Banner";
import CONSTANTS from "../../../constants/Constants";
import Utils from "../../../libs/Utils";

const Register = () => {
    const { t, i18n } = useTranslation();
    const [pageType, setPageType] = useState('');
    const [selectedPage, setSelectedPage] = useState('');

    const selectPageType = (type, page) => {
        setPageType(type);
        setSelectedPage(page);
    };

    const redirectPage = () => {
        if (!pageType) return Utils.popupAlert({ text: t("validation.Please select type of user first"), type: "warning" });
        history.push({
            pathname: `register/${selectedPage}`,
            state: { type: pageType }
        });
    };

    const renderMain = () => {
        return (
            <div>
                <Banner type='join' />
                <Container className="pb-2">
                    <PageTitle className="mb-0">
                        <p className="page-title-header">{t("general.ENTREPRENEUR")}</p>
                        <div className="choose-type">
                            <div className="type">
                                <GeneralCheckBoxForm
                                    value={CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL.toString()}
                                    defaultChecked={pageType === CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL.toString() && selectedPage === CONSTANTS.ROLE_NAME.ENTREPRENEUR}
                                    onChange={(e) => selectPageType(e.target.value, CONSTANTS.ROLE_NAME.ENTREPRENEUR)}
                                    name='entrepreneurIndividual'
                                />
                                <UserTypeLabel>{t("general.Individual with a business idea")}</UserTypeLabel>
                            </div>
                            <div className="type">
                                <GeneralCheckBoxForm
                                    value={CONSTANTS.USER_PROPERTY_TYPE.BUSINESS.toString()}
                                    defaultChecked={pageType === CONSTANTS.USER_PROPERTY_TYPE.BUSINESS.toString() && selectedPage === CONSTANTS.ROLE_NAME.ENTREPRENEUR}
                                    onChange={(e) => selectPageType(e.target.value, CONSTANTS.ROLE_NAME.ENTREPRENEUR)}
                                    name='entrepreneurBusiness'
                                />
                                <UserTypeLabel>{t("general.Registered startup")}</UserTypeLabel>
                            </div>
                        </div>
                        <p className="line-header" />
                    </PageTitle>
                    <PageTitle className={i18n.language === CONSTANTS.LANGUAGE_CODE.EN ? "m-0 w-60" : "m-0 w-70"}>
                        <p className="page-title-header">{t("general.INVESTOR")}</p>
                        <div className="choose-type">
                            <div className="type">
                                <GeneralCheckBoxForm
                                    value={CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL.toString()}
                                    defaultChecked={pageType === CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL.toString() && selectedPage === CONSTANTS.ROLE_NAME.INVESTOR}
                                    onChange={(e) => selectPageType(e.target.value, CONSTANTS.ROLE_NAME.INVESTOR)}
                                    name='investorIndividual'
                                />
                                <UserTypeLabel>{t("general.INDIVIDUAL")}</UserTypeLabel>
                            </div>
                            <div className="type">
                                <GeneralCheckBoxForm
                                    value={CONSTANTS.USER_PROPERTY_TYPE.BUSINESS.toString()}
                                    defaultChecked={pageType === CONSTANTS.USER_PROPERTY_TYPE.BUSINESS.toString() && selectedPage === CONSTANTS.ROLE_NAME.INVESTOR}
                                    onChange={(e) => selectPageType(e.target.value, CONSTANTS.ROLE_NAME.INVESTOR)}
                                    name='investorBusiness'
                                />
                                <UserTypeLabel>{t("general.BUSINESS")}</UserTypeLabel>
                            </div>
                            <div className="type">
                                <GeneralCheckBoxForm
                                    value={CONSTANTS.USER_PROPERTY_TYPE.VENTURE_INVESTMENT.toString()}
                                    defaultChecked={pageType === CONSTANTS.USER_PROPERTY_TYPE.VENTURE_INVESTMENT.toString() && selectedPage === CONSTANTS.ROLE_NAME.INVESTOR}
                                    onChange={(e) => selectPageType(e.target.value, CONSTANTS.ROLE_NAME.INVESTOR)}
                                    name='investorInvesment'
                                />
                                <UserTypeLabel>{t("general.VENTURE FIRM/IMPACT INVESTMENT")}</UserTypeLabel>
                            </div>
                        </div>
                        <p className="line-header" />
                    </PageTitle>
                    <PageTitle className='m-0'>
                        <p className="page-title-header">{t("general.CONSULTANT")}</p>
                        <div className="choose-type">
                            <div className="type">
                                <GeneralCheckBoxForm
                                    value={CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL.toString()}
                                    defaultChecked={pageType === CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL.toString() && selectedPage === CONSTANTS.ROLE_NAME.CONSULTANT}
                                    onChange={(e) => selectPageType(e.target.value, CONSTANTS.ROLE_NAME.CONSULTANT)}
                                    name='consultantIndividual'
                                />
                                <UserTypeLabel>{t("general.INDIVIDUAL")}</UserTypeLabel>
                            </div>
                            <div className="type">
                                <GeneralCheckBoxForm
                                    value={CONSTANTS.USER_PROPERTY_TYPE.PRIVATE_ENTITY.toString()}
                                    defaultChecked={pageType === CONSTANTS.USER_PROPERTY_TYPE.PRIVATE_ENTITY.toString() && selectedPage === CONSTANTS.ROLE_NAME.CONSULTANT}
                                    onChange={(e) => selectPageType(e.target.value, CONSTANTS.ROLE_NAME.CONSULTANT)}
                                    name='consultantPrivate'
                                />
                                <UserTypeLabel>{t("general.Private Entity Register")}</UserTypeLabel>
                            </div>
                        </div>
                        <p className="line-header" />
                    </PageTitle>
                    <SubmitButton type='button' onClick={() => redirectPage()}>
                        {t("general.Next Step")}
                    </SubmitButton>
                </Container>
            </div>
        );
    };

    return (
        <DefaultLayout context={renderMain()} />
    );
};

export default Register;
