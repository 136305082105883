import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from 'lodash';
import moment from 'moment';
import {
    BannerJoinComponent,
    BannerImgComponent,
    ContentImgContent
} from "./Banner.styles";
import LibraryActions from "../../../actions/Library";
import CONSTANTS from "../../../constants/Constants";

const Banner = (props) => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [type, setType] = useState();
    const [backgroundImg, setBackgroundImg] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [searchKeyLibrary, setSearchKeyLibrary] = useState('');
    const [coverImage, setCoverImage] = useState('');
    const [dateReleased, setDateReleased] = useState('');

    useEffect(() => {
        setType(props.type);
        setBackgroundImg(props.backgroundImg);
        setTitle(props.title);
        setDescription(props.description);
        setCoverImage(_.get(props, "previewImage", ""));
        setDateReleased(_.get(props, "dateReleased", ""));
    }, [props]);

    const onChangeLibraryKey = (event) => {
        setSearchKeyLibrary(event.target.value);
    };

    const submitSearchLibrary = (e, isClick) => {
        if ((e && e.which === 13) || isClick) {
            const param = {
                page: 1,
                item_per_page: CONSTANTS.ITEM_PER_PAGE,
                language: i18n.language,
                key_word: searchKeyLibrary
            };
            dispatch(LibraryActions.searchLibrary(param));
        }
    };

    const _bannerJoin = () => {
        return (
            <BannerJoinComponent className="full-width">
                {t("general.Join the Dua Partner Invest community")}
            </BannerJoinComponent>
        );
    };

    const _bannerSignIn = () => {
        return (
            <BannerJoinComponent className="full-width">
                {t("general.Sign in to")}
                {' '}
                &nbsp;
                <span className="strong">{t("general.Dua Partner Invest")}</span>
            </BannerJoinComponent>
        );
    };

    const _bannerImg = () => {
        return (
            <BannerImgComponent className="full-width">
                <ContentImgContent backgroundImg={backgroundImg}>
                    <div className="container">
                        <div className="title">
                            {title}
                        </div>
                        <div className="description">
                            {description}
                        </div>
                    </div>
                </ContentImgContent>
            </BannerImgComponent>
        );
    };

    const _bannerForgotPassword = () => {
        return (
            <BannerJoinComponent className="full-width">
                {t("general.Forgot password")}
            </BannerJoinComponent>
        );
    };

    const _bannerLibrary = () => {
        return (
            <BannerImgComponent className="full-width">
                <ContentImgContent backgroundImg={backgroundImg}>
                    <div className="library-container">
                        <div className="library-title">
                            {t("general.Library")}
                        </div>
                        <div className="search">
                            <input
                                type='text'
                                placeholder={t("general.Search for a question, topic")}
                                onKeyDown={(e) => submitSearchLibrary(e, null)}
                                onChange={(e) => onChangeLibraryKey(e)}
                            />
                            <i className="fas fa-search" onClick={() => submitSearchLibrary(null, true)} />
                        </div>
                    </div>
                </ContentImgContent>
            </BannerImgComponent>
        );
    };

    const _bannerDetailLibary = () => {
        return (
            <BannerImgComponent className="full-width none-height">
                <ContentImgContent className="bg-color detail-library">
                    <div className="library-container library-detail">
                        <div className="title title-library">
                            {title}
                            <div className="released-date">
                                <i className="fas fa-calendar-alt" />
                                {' '}
                                {moment(dateReleased).format("DD-MM-YYYY hh:mm")}
                            </div>
                        </div>
                        <div className="description description-library">
                            <div className="cover-image">
                                <img src={coverImage} alt={title} />
                            </div>
                            <div className="description-context text" dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                    </div>
                </ContentImgContent>
            </BannerImgComponent>
        );
    };

    const _renderMain = () => {
        if (type === "join") {
            return (_bannerJoin());
        } else if (type === "signIn") {
            return (_bannerSignIn());
        } else if (type === "forgotPassword") {
            return (_bannerForgotPassword());
        } else if (type === "library") {
            return (_bannerLibrary());
        } else if (type === 'libraryDetail') {
            return (_bannerDetailLibary());
        } else {
            return (_bannerImg());
        }
    };

    return (
        (_renderMain())
    );
};

Banner.propTypes = {
    type: PropTypes.string,
    backgroundImg: PropTypes.string
};

export default Banner;
