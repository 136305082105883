import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
    Wrapper,
    BodyContainer,
    BodyContent
} from './PortalTemplate.styles';
import Header from "../CommonLayoutPart/Header";
import HeaderFrontEnd from "../GuestTemplate/Header";
import FooterFrontEnd from "../GuestTemplate/Footer";
import SideBar from "../CommonLayoutPart/SideBar";
import Loading from "../CommonLayoutPart/Loading";
import LoginAndRegister from "./LoginAndRegister";
import Utils from "../../../libs/Utils";
import history from "../../../history";
import Constants from "../../../constants/Constants";

const PortalTemplate = (props) => {
    const { t } = useTranslation();
    const [isShowLoginAndRegister, setIsShowLoginAndRegister] = useState(false);
    const {
        userRole,
        content,
        isLoading,
        page
    } = props;
    const userData = Utils.getSavedUserData();
    const _checkRoleUser = () => {
        if (userData.role.name ===  Constants.ROLE_NAME.UNASSIGNED) {
            const href = (window.location.href).toString();
            if (!href.includes('user-overview') && !href.includes('add-view-user')) Utils.popupAlert({ title: "Oops...", text: t("general.Access denied"), type: "error" });
            return 'add-view-user';
        }
        const { pathname } = history.location;
        if (pathname.includes('judge') && userData.role.name ===  Constants.ROLE_NAME.JUDGE) return true;
        if (pathname.includes('judge') && !userData.role.name ===  Constants.ROLE_NAME.JUDGE) {
            if (userData.role.name ===  Constants.ROLE_NAME.SUPER_ADMIN) return "/admin/overview";
            return "/user-overview";
        }
        if (!pathname.includes('judge') && userData.role.name ===  Constants.ROLE_NAME.JUDGE) return "/judge/settings";
        if (pathname.includes('admin') && userData.role.name ===  Constants.ROLE_NAME.SUPER_ADMIN) return true;
        if (pathname.includes('admin') && !userData.role.name ===  Constants.ROLE_NAME.SUPER_ADMIN) {
            if (userData.role.name ===  Constants.ROLE_NAME.JUDGE) return "/judge/applications";
            return "/user-overview";
        }
        if (!pathname.includes('admin') && userData.role.name ===  Constants.ROLE_NAME.SUPER_ADMIN) return "/admin/overview";

        if (!pathname.includes('limited-admin') && userData.role.name ===  Constants.ROLE_NAME.LIMITED_ADMIN) return "/limited-admin/overview";
        if (pathname.includes('limited-admin') && !userData.role.name ===  Constants.ROLE_NAME.LIMITED_ADMIN) {
            Utils.popupAlert({ title: "Oops...", text: t("general.Access denied"), type: "error" });
            return 'add-view-user';
        }
        return true
    };

    const _redirectPage = (path) => {
        if (path !== true) {
            history.push(path);
        }
    };

    useEffect(() => {
        if (userData) {
            const path = _checkRoleUser();
            _redirectPage(path);
        } else {
            history.push("/authentication");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeStatusShowLoginAndRegister = (status) => {
        setIsShowLoginAndRegister(status);
    };

    const hideModal = () => {
        setIsShowLoginAndRegister(false);
    };
    if(userData)
    return (
        <Wrapper>
            {(userData && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER)
                && (<HeaderFrontEnd />)
            }
            {isLoading && <Loading />}
            {isShowLoginAndRegister && <LoginAndRegister hideModal={() => hideModal()} />}
            <Header callBackHandleClickAvatar={(status) => changeStatusShowLoginAndRegister(status)} />
            <BodyContainer>
                <SideBar userRole={userRole} page={page} />
                <BodyContent>
                    {content}
                </BodyContent>
            </BodyContainer>
            {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER
                && (<FooterFrontEnd />)
            }
        </Wrapper>
    );
    else return null
};

PortalTemplate.propTypes = {
    userRole: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default PortalTemplate;
