import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import UsersNavigationBar from "./UsersNavigationBar";
import FundingApplicationsNavigationBar from "./FundingApplicationsNavigationBar";
import { Wrapper } from "./NavigationBar.styles";
import UserOverviewNavigationBar from './UserOverviewNavigationBar';
import LimitedOverviewNavigationBar from './LimitedOverviewNavigationBar';

const NavigationBar = (props) => {
    const [type, setType] = useState('');
    const [selected, setSelected] = useState('');
    const [defaultTab, setDefaultTab] = useState('');
    useEffect(() => {
        setType(props.type);
        props.selectedTab(selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    useEffect(() => {
        setDefaultTab(props.defaultTab);
    }, [props]);

    const selectedTab = (tab) => {
        setSelected(tab);
    };

    const getCurrentTab = (tab) => {
        props.getCurrentTab(tab);
    };

    return (
        <Wrapper>
            {type === 'users' && (<UsersNavigationBar title={type} selectedTab={selectedTab} defaultTab={defaultTab} />)}
            {type === 'limited-admin' && (<LimitedOverviewNavigationBar title={type} selectedTab={selectedTab} defaultTab={defaultTab} />)}
            {type === 'user over view' && (<UserOverviewNavigationBar getCurrentTab={(tab) => getCurrentTab(tab)} />)}
            {type === 'funding opportunities'
                && (
                    <FundingApplicationsNavigationBar
                        title={type}
                        selectedTab={selectedTab}
                    />
                )}
        </Wrapper>
    );
};

NavigationBar.propTypes = {
    type: PropTypes.string,
    selectedTab: PropTypes.func,
    createNewFundingApplication: PropTypes.func,
    defaultTab: PropTypes.string,
    getCurrentTab: PropTypes.func
};

export default NavigationBar;
