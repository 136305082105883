import ActionTypes from "../constants/ActionTypes";

const initialState = {
    hasError: false,
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    announcementData: null,
    isMultipleLanguage: false,
    isFilter: false
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ActionTypes.ANNOUNCEMENT_SETTING_REQUEST:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
                hasError: false
            };
        case ActionTypes.ANNOUNCEMENT_SETTING_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.GET_ANNOUNCEMENT_SETTING_SUCCESS:
            return {
                ...state,
                announcementData: action.payload,
                isSuccess: true,
                isLoading: false,
                hasError: false
            };
        case ActionTypes.ANNOUNCEMENT_SETTING_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false,
                isSuccess: false,
                errorMessage: action.errorMessage
            };
        case ActionTypes.SET_FILTER_SUCCESS:
            return {
                ...state,
                hasError: false,
                isLoading: false,
                isSuccess: true,
                isFilter: action.payload
            };
        case ActionTypes.ANNOUNCEMENT_SETTING_RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};
