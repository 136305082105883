import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { PageTitle, GeneralButton } from "../../../Common/CommonLayout";
import { Wrapper, Container, FormItem, Label } from "./UserDetailPopup.styles";
import CONSTANTS from "../../../../constants/Constants";
import QUESTIONS from "./Questions";
import { ColorName } from "../../../Variables";

const UserDetailPopup = (props) => {
  const { t } = useTranslation();
  const { userDetail, hideModal } = props;

  const _resultForEntrepreneur = (type) => {
    const pageType = {
      1: "Individual with a business idea",
      2: "Registered startup",
    };
    const result = [];
    if (type === CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL) {
      _.map(_.keys(QUESTIONS.Entrepreneur.individual), (key) => {
        if (
          key === "address" ||
          key === "facebook" ||
          key === "website" ||
          key === "instagram"
        ) {
          result.push({
            question: QUESTIONS.Entrepreneur.individual[key],
            answer: userDetail.userData[key] || t("general.No Information"),
          });
        } else if (key === "identity_type") {
          result.push({
            question: QUESTIONS.Entrepreneur.individual[key],
            answer: t(`general.${pageType[type]}`),
          });
        } else if (
          key === "industry_operate" ||
          key === "employment_status" ||
          key === "industry_operate"
        ) {
          result.push({
            question: QUESTIONS.Entrepreneur.individual[key],
            answer: t(`general.${userDetail.userData.extra_data_json[key]}`),
          });
        } else if (key === "consultancy_needed") {
          const arrayTranslate =
            userDetail.userData.extra_data_json[key].split(",");
          let strTranslate = "";
          _.map(arrayTranslate, (child, index) => {
            strTranslate += t(`general.${child.trim()}`);
            if (index !== arrayTranslate.length - 1) strTranslate += ", ";
          });
          result.push({
            question: QUESTIONS.Entrepreneur.business[key],
            answer: strTranslate,
          });
        } else {
          result.push({
            question: QUESTIONS.Entrepreneur.individual[key],
            answer:
              userDetail.userData.extra_data_json[key] ||
              t("general.No Information"),
          });
        }
      });
    }
    if (type === CONSTANTS.USER_PROPERTY_TYPE.BUSINESS) {
      _.map(_.keys(QUESTIONS.Entrepreneur.business), (key) => {
        if (
          key === "address" ||
          key === "facebook" ||
          key === "website" ||
          key === "instagram"
        ) {
          result.push({
            question: QUESTIONS.Entrepreneur.business[key],
            answer: userDetail.userData[key] || t("general.No Information"),
          });
        } else if (key === "identity_type") {
          result.push({
            question: QUESTIONS.Entrepreneur.business[key],
            answer: t(`general.${pageType[type]}`),
          });
        } else if (
          key === "industry_operate" ||
          key === "employment_status" ||
          key === "industry_operate"
        ) {
          result.push({
            question: QUESTIONS.Entrepreneur.business[key],
            answer: t(`general.${userDetail.userData.extra_data_json[key]}`),
          });
        } else if (key === "business_idea") {
          result.push({
            question: QUESTIONS.Entrepreneur.business[key],
            answer: t(
              "general.Yes, I have an idea to scale up my current business"
            ),
          });
        } else if (key === "consultancy_needed") {
          const arrayTranslate =
            userDetail.userData.extra_data_json[key].split(",");
          let strTranslate = "";
          _.map(arrayTranslate, (child, index) => {
            strTranslate += t(`general.${child.trim()}`);
            if (index !== arrayTranslate.length - 1) strTranslate += ", ";
          });
          result.push({
            question: QUESTIONS.Entrepreneur.business[key],
            answer: strTranslate,
          });
        } else {
          result.push({
            question: QUESTIONS.Entrepreneur.business[key],
            answer:
              userDetail.userData.extra_data_json[key] ||
              t("general.No Information"),
          });
        }
      });
    }
    return result;
  };

  const _resultForInvestor = (type) => {
    const result = [];
    if (type === CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL) {
      _.map(_.keys(QUESTIONS.Investor.individual), (key) => {
        if (
          key === "address" ||
          key === "facebook" ||
          key === "website" ||
          key === "instagram"
        ) {
          result.push({
            question: QUESTIONS.Investor.individual[key],
            answer: userDetail.userData[key] || t("general.No Information"),
          });
        } else if (
          key === "invest_platform" ||
          key === "primarily_invest" ||
          key === "is_has_invested" ||
          key === "employment_status"
        ) {
          result.push({
            question: QUESTIONS.Investor.individual[key],
            answer: t(`general.${userDetail.userData.extra_data_json[key]}`),
          });
        } else {
          result.push({
            question: QUESTIONS.Investor.individual[key],
            answer:
              userDetail.userData.extra_data_json[key] ||
              t("general.No Information"),
          });
        }
      });
    }
    if (
      type === CONSTANTS.USER_PROPERTY_TYPE.BUSINESS ||
      type === CONSTANTS.USER_PROPERTY_TYPE.VENTURE_INVESTMENT
    ) {
      _.map(_.keys(QUESTIONS.Investor.business_venture_firm), (key) => {
        if (
          key === "address" ||
          key === "facebook" ||
          key === "website" ||
          key === "instagram"
        ) {
          result.push({
            question: QUESTIONS.Investor.business_venture_firm[key],
            answer: userDetail.userData[key] || t("general.No Information"),
          });
        } else if (
          key === "invest_platform" ||
          key === "primarily_invest" ||
          key === "is_has_invested" ||
          key === "industry_operate"
        ) {
          result.push({
            question: QUESTIONS.Investor.business_venture_firm[key],
            answer: userDetail.userData.extra_data_json[key]
              ? t(`general.${userDetail.userData.extra_data_json[key]}`)
              : t("general.No Information"),
          });
        } else {
          result.push({
            question: QUESTIONS.Investor.business_venture_firm[key],
            answer:
              userDetail.userData.extra_data_json[key] ||
              t("general.No Information"),
          });
        }
      });
    }
    return result;
  };

  const _resultForConsultant = (type) => {
    const result = [];
    if (type === CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL) {
      _.map(_.keys(QUESTIONS.Consultant.individual), (key) => {
        if (
          key === "address" ||
          key === "facebook" ||
          key === "website" ||
          key === "instagram"
        ) {
          result.push({
            question: QUESTIONS.Consultant.individual[key],
            answer: userDetail.userData[key] || t("general.No Information"),
          });
        } else {
          result.push({
            question: QUESTIONS.Consultant.individual[key],
            answer:
              userDetail.userData.extra_data_json[key] ||
              t("general.No Information"),
          });
        }
      });
    }
    if (type === CONSTANTS.USER_PROPERTY_TYPE.PRIVATE_ENTITY) {
      _.map(_.keys(QUESTIONS.Consultant.entity), (key) => {
        if (
          key === "address" ||
          key === "facebook" ||
          key === "website" ||
          key === "instagram"
        ) {
          result.push({
            question: QUESTIONS.Consultant.entity[key],
            answer: userDetail.userData[key] || t("general.No Information"),
          });
        } else {
          result.push({
            question: QUESTIONS.Consultant.entity[key],
            answer:
              userDetail.userData.extra_data_json[key] ||
              t("general.No Information"),
          });
        }
      });
    }
    return result;
  };

  const _resultForJudge = () => {
    const result = [];
    _.map(_.keys(QUESTIONS.Judge), (key) => {
      result.push({
        question: QUESTIONS.Judge[key],
        answer: userDetail.userData[key] || "No Information",
      });
    });
    return result;
  };

  const checkRole = () => {
    if (userDetail.role.name === CONSTANTS.ROLE_NAME.ENTREPRENEUR) {
      return _resultForEntrepreneur(userDetail.property_type);
    }
    if (userDetail.role.name === CONSTANTS.ROLE_NAME.INVESTOR) {
      return _resultForInvestor(userDetail.property_type);
    }
    if (userDetail.role.name === CONSTANTS.ROLE_NAME.CONSULTANT) {
      return _resultForConsultant(userDetail.property_type);
    }
    if (userDetail.role.name === CONSTANTS.ROLE_NAME.JUDGE) {
      return _resultForJudge();
    }
  };

  const closePopup = (event) => {
    if (event.target.classList.contains("wrapper")) {
      hideModal();
    }
  };

  const _renderForm = () => {
    const renderData = checkRole();
    const render = _.map(renderData, (data, index) => {
      return (
        <FormItem key={index}>
          <Label className="text-bold">{t(`userPopup.${data.question}`)}</Label>
          <Label>{data.answer}</Label>
        </FormItem>
      );
    });
    return render;
  };

  const _renderMain = () => {
    return (
      <Wrapper onClick={(event) => closePopup(event)} className="wrapper">
        <Container>
          <PageTitle>{t("general.About")}</PageTitle>
          <p>
            {userDetail.userData.extra_data_json.legal_investing_name ||
              userDetail.userData.full_name}
          </p>
          {_renderForm()}
          {!userDetail.userData.extra_data_json && (
            <Label>{t("general.No Data Found")} !</Label>
          )}
          <GeneralButton
            type="button"
            value={t("general.Close")}
            className="wrapper"
            background={ColorName.chathamsBlueDark}
            onClick={(event) => closePopup(event)}
          />
        </Container>
      </Wrapper>
    );
  };

  return _renderMain();
};

UserDetailPopup.propTypes = {
  userDetail: PropTypes.object,
  hideModal: PropTypes.func,
};

export default UserDetailPopup;
