import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    GeneralDropdownFormContainer,
    DropDownIcon
} from "./GeneralDropDownForm.styles";

const GeneralDropdownForm = (props) => {
    const { t } = useTranslation();
    const {
        onChange, options, placeHolder, defaultValue, translatedropdown
    } = props;
    const handleSelectEvent = (e) => {
        if (onChange) onChange(e);
    };

    const _renderOptions = () => {
        const render = _.map(options, (option, index) => {
            return (
                <option value={option.value} key={index}>
                    {translatedropdown ? t(`general.${option.label}`) : option.label}
                </option>
            );
        });
        return render;
    };
    return (
        <GeneralDropdownFormContainer>
            {defaultValue && (
                <select value={defaultValue} onChange={(e) => handleSelectEvent(e)}>
                    {_renderOptions()}
                </select>
            )}
            {!defaultValue && (
                <select value='' onChange={(e) => handleSelectEvent(e)}>
                    <option value='' disabled>
                        {placeHolder}
                    </option>
                    {_renderOptions()}
                </select>
            )}
            <DropDownIcon>
                <i className="fas fa-chevron-down" />
            </DropDownIcon>
        </GeneralDropdownFormContainer>
    );
};

GeneralDropdownForm.propTypes = {
    options: PropTypes.array,
    defaultChecked: PropTypes.bool,
    translatedropdown: PropTypes.bool,
    onChange: PropTypes.func,
    placeHolder: PropTypes.string,
    defaultValue: PropTypes.any
};

export default GeneralDropdownForm;
