import React, { useRef, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import Avatar from "../../../Common/Avatar";
import Utils from "../../../../libs/Utils";
import { UsernameChatbox } from "./Message.styles";
const Message = ({ dataChannel }) => {
  const { messages } = dataChannel;
  const myRef = useRef(null);
  const scrollToMyRef = () => {
    const scroll = myRef.current.scrollHeight - myRef.current.clientHeight;
    myRef.current.scrollTo(0, scroll);
  };

  useEffect(() => {
    scrollToMyRef();
  }, [dataChannel]);

  const formatNameFile = (file_attach) => {
    if (file_attach) return file_attach.split("/")[1];
  };
  const userSend = Utils.getSavedUserData();

  const checkVideo = (url_file_attach) => {
    if (url_file_attach) {
      const extension = url_file_attach.split(".").pop();
      if (
        extension.toLowerCase() === "mp4" ||
        extension.toLowerCase() === "ogg" ||
        extension.toLowerCase() === "webm"
      )
        return true;
    }
    return false;
  };
  const _renderMessage = () => {
    const render = _.map(messages, (message, index) => {
      const {
        user_id_send,
        url_file_attach,
        file_attach,
        content,
        created_at,
      } = message;
      return (
        <div key={index} className="scroll-over">
          {dataChannel.userSend.id === user_id_send && (
            <div className="userReceive">
              <div className="container-img">
                <UsernameChatbox direction="left">
                  {`${userSend.userData.first_name} ${userSend.userData.last_name}`}
                </UsernameChatbox>
                <Avatar userData={userSend} size="small" />
              </div>
              {content && <div className="content-message">{content}</div>}
              {checkVideo(url_file_attach) && (
                <video width="320" height="240" controls>
                  <source src={url_file_attach} type="video/mp4" />
                </video>
              )}
              {url_file_attach && !checkVideo(url_file_attach) && (
                <a
                  className="file-attach"
                  href={url_file_attach}
                  download={url_file_attach}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatNameFile(file_attach)}
                  <i className="fas fa-paperclip right" />
                </a>
              )}
              <span className="sent-timestamp">
                {moment(created_at).format("DD/MM/YYYY HH:mm")}
              </span>
            </div>
          )}
          {dataChannel.userSend.id !== user_id_send && (
            <div className="userSend">
              <div className="container-img">
                <Avatar userData={dataChannel.userReceive} size="small" />
                <UsernameChatbox direction="right">
                  {`${dataChannel.userReceive.userData.first_name} ${dataChannel.userReceive.userData.last_name}`}
                </UsernameChatbox>
              </div>
              {content && <div className="content-message">{content}</div>}

              {checkVideo(url_file_attach) && (
                <video width="320" height="240" controls>
                  <source src={url_file_attach} type="video/mp4" />
                </video>
              )}
              {url_file_attach && !checkVideo(url_file_attach) && (
                <a
                  className="file-attach"
                  href={url_file_attach}
                  download={url_file_attach}
                >
                  <i className="fas fa-paperclip left" />
                  {formatNameFile(file_attach)}
                </a>
              )}
              <span className="sent-timestamp">
                {moment(created_at).format("DD/MM/YYYY HH:mm")}
              </span>
            </div>
          )}
        </div>
      );
    });
    return render;
  };
  return (
    <div className="messageWrapper admin-manage-message" ref={myRef}>
      {_renderMessage()}
    </div>
  );
};

export default Message;
