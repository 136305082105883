import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    FieldTitle
} from "./Judge.styles";
import FundingApplicationActions from "../../../actions/FundingApplication";
import Button from "../../../components/Common/Button";
import { ColorName } from "../../../components/Variables";
import history from "../../../history";
import JudgeActions from '../../../actions/Judge';
import ExportActions from '../../../actions/Export';
import Utils from "../../../libs/Utils";

const ApplicationsDetail = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const applyApplication = useSelector((state) => state.Judge.applyApplication);
    const isExportLoading = useSelector((state) => state.Export.isLoading);
    const [listSelected, setListSelected] = useState([]);
    const [newData, setNewData] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const listRole = useSelector((state) => state.AdminOverView.roles);

    useEffect(() => {
        dispatch(JudgeActions.getApplicationsJudgeById(location.state.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (applyApplication) {
            if (applyApplication.fundingApplication) {
                setNewData(applyApplication.fundingApplication.applyApplications);
            }
        }
    }, [applyApplication]);

    useEffect(() => {
        if (!_.isEmpty(listRole)) {
            const data = {
                role_id: _.find(listRole, ["name", "Judge"]).id
            };
            dispatch(FundingApplicationActions.getListJudge(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRole]);

    const exportExcell = () => {
        if (!_.isEmpty(listSelected)) dispatch(ExportActions.exportExcell({ list_submitted: JSON.stringify(listSelected), funding_id: applyApplication.fundingApplication.id }));
        else Utils.popupAlert({ title: "Oops...", text: "Please select submitted applications", type: "error" });
    };

    const selectedExport = (e) => {
        const list = [...listSelected];
        if (e.target.checked) {
            list.push(_.parseInt(e.target.value));
        } else {
            setCheckAll(false);
            const index = list.indexOf(_.parseInt(e.target.value));
            if (index > -1) {
                list.splice(index, 1);
            }
        }
        setListSelected(list);
    };

    const checkIncludeList = (child) => {
        if (listSelected.includes(child)) return true;
        return false;
    };

    const _renderTableApplication = () => {
        const render = _.map(newData, (item, index) => {
            if (checkAll) {
                return (
                    <li key={index}>
                        <input id={`checkbox-${index}`} className="checkbox-export" type="checkbox" value={item.id} onChange={(e) => selectedExport(e)} checked={checkAll} />
                        <label htmlFor={`checkbox-${index}`}>{item.user.userData.full_name}</label>
                    </li>
                );
            } else {
                return (
                    <li key={index}>
                        <>
                            <input
                                id={`checkbox-${index}`}
                                className="checkbox-export"
                                type="checkbox"
                                value={item.id}
                                onChange={(e) => selectedExport(e)}
                                checked={!!checkIncludeList(item.id)}
                            />
                            <label htmlFor={`checkbox-${index}`}>{item.user.userData.full_name}</label>
                        </>

                    </li>
                );
            }
        });
        return render;
    };

    const selectedAll = (e) => {
        if (e.target.checked) {
            setCheckAll(true);
            const array = [...listSelected];
            _.map(newData, (item, index) => {
                if (!array.includes(item.id)) {
                    array.push(item.id);
                }
            });
            setListSelected(array);
        } else {
            setListSelected([]);
            setCheckAll(false);
        }
    };

    return (
        <DefaultLayout
            userRole='judge'
            isLoading={isExportLoading}
            page="fundingApplications"
            content={(
                <BodyContainer className="export-container">
                    <FieldTitle>
                        {t("fundingApplications.List Of Applications Submitted")}
                    </FieldTitle>
                    {!_.isUndefined(newData)
                        && (
                            <>
                                {t("general.Choose Submitted Applications To Export")}
                                <ul className="list-export">
                                    <li>
                                        <input id="checkbox-all" className="checkbox-export" type="checkbox" onChange={(e) => selectedAll(e)} checked={checkAll} />
                                        <label htmlFor="checkbox-all">Select All</label>
                                    </li>
                                    {_renderTableApplication()}
                                </ul>
                            </>
                        )
                    }
                    <FieldTitle className="button-export">
                        <Button
                            color={ColorName.white}
                            background={ColorName.chathamsBlueDark}
                            title={t("general.Back")}
                            height="40px"
                            padding="0 1em"
                            margin="0 1em 0 0"
                            onClick={() => history.replace({
                                state: { id: location.state.id },
                                pathname: "/judge/applications/list-applies"
                            })}
                        />
                        <Button
                            color={ColorName.white}
                            background={ColorName.chathamsBlueDark}
                            title={t("fundingApplications.Export Submitted Applications")}
                            height="40px"
                            width="auto"
                            padding="0 1em"
                            onClick={() => exportExcell()}
                        />
                    </FieldTitle>
                </BodyContainer>
            )}
        />
    );
};

export default ApplicationsDetail;
