import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";

const requestSuccess = (payload = false) => {
    return {
        type: ActionTypes.SEARCH_SUCCESS,
        payload
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.SEARCH_REQUEST
    };
};

const requestHasError = () => {
    return {
        type: ActionTypes.SEARCH_FAILURE
    };
};

const searchHomepageSuccess = (payload = false) => {
    return {
        type: ActionTypes.SEARCH_HOMEPAGE_SUCCESS,
        payload
    };
};

const searchHomepageIsLoading = () => {
    return {
        type: ActionTypes.SEARCH_HOMEPAGE_REQUEST
    };
};

const searchHomepageHasError = () => {
    return {
        type: ActionTypes.SEARCH_HOMEPAGE_FAILURE
    };
};


const setSearchHomepageDefault = () => {
    return {
        type: ActionTypes.SET_SEARCH_HOMEPAGE_DEFAULT
    };
};

const setValueSearch = (payload = '') => {
    return {
        type: ActionTypes.SET_VALUE_SEARCH,
        payload
    };
};

const search = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.search(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const searchHomepage = (data) => {
    return (dispatch) => {
        dispatch(searchHomepageIsLoading());
        API.searchHomepage(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(searchHomepageSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(searchHomepageHasError());
            });
    };
};

export default {
    search,
    searchHomepage,
    setSearchHomepageDefault,
    setValueSearch
};
