import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    Container,
    PageTitle
} from "../UserRegister.styles";
import DefaultLayout from "../../../../components/Layout/GuestTemplate/DefaultLayout";
import Banner from "../../../../components/Common/Banner";
import CONSTANTS from "../../../../constants/Constants";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import userRegisterActions from "../../../../actions/UserRegister";
import Individual from "./Individual";
import Business from "./Business";
import Form from "../UserDataForm";
const Entrepreneur = () => {
    const { t } = useTranslation();
    const pageType = {
        1: "Individual with a business idea",
        2: "Registered startup"
    };
    const pageList = [
        {
            name: t("general.Individual with a business idea"),
            pageID: '1'
        },
        {
            name: t("general.Registered startup"),
            pageID: '2'
        }
    ];
    const dispatch = useDispatch();
    const location = useLocation();
    const isLoading = useSelector((state) => state.UserRegister.isLoading);
    const [type, setType] = useState('1');
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (location.state) setType(location.state.type);
        dispatch(userRegisterActions.getUserRoles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderMain = () => {
        return (
            <div>
                {isLoading && <Loading />}
                <Banner type='join' />
                {!_.isEmpty(userData) && (
                    <Container>
                        <PageTitle>
                            <p className="page-title-header">{t("general.ENTREPRENEUR")}</p>
                            <p className="page-title-footer">
                                {t(`general.${pageType[type]}`)}
                            </p>
                        </PageTitle>
                        {type === CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL.toString() && <Individual data={userData} />}
                        {type === CONSTANTS.USER_PROPERTY_TYPE.BUSINESS.toString() && <Business data={userData} />}
                    </Container>
                )}
                {_.isEmpty(userData) && <Form pageList={pageList} selectedPage={type} callBackNext={(data) => setUserData(data)} page={CONSTANTS.ROLE_NAME.ENTREPRENEUR} />}
            </div>
        );
    };

    return (
        <DefaultLayout context={renderMain()} />
    );
};

export default Entrepreneur;
