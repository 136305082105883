import styled from 'styled-components';
import { ColorName } from "../../../components/Variables";
import { HorizontalContainer, VerticalContainer, BreadCrumb } from "../../../components/Common/CommonLayout";

const Wrapper = styled(VerticalContainer)`
    background: ${ColorName.white};
    padding: 2em;
    & .btn-footer{
        display: flex;
        padding: 2em 1em;
    }
    & .title {
        margin: 0.5em;
        font-weight: 400;
        font-size: 1.2em;
    }
`;

const BodyContainer = styled(HorizontalContainer)`
    background: ${ColorName.white};
    & .checkbox-export{
        -webkit-appearance: checkbox;
    }
    & .list-export{
        & li{
            display: flex;
            padding: 0.5em 0;
            align-items: center;
            cursor: pointer;
            & label{
                padding: 0 1em;
                cursor: pointer;
            }
        }
    }
    &.export-container{
        display: flex;
        flex-direction: column;
        padding: 2em;
    }
`;

const BodyContent = styled.div`
    width: 80%;
`;

const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${ColorName.white};
    border-radius: 10px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    margin-left: ${(props) => props.marginLeft || "0px"};
    flex: 1;
    &.mb-2{
        margin-bottom: 20px;
    }
    & textarea{
        width: 200px;
        height: 75px;
        resize: none;
        border-radius: 5px;
        border: 1px solid lightGray;
        padding: 10px;
        font-family: unset;
    }
    &.green{
        color: ${ColorName.screaminGreen};
    }
    & .container-upload{
        display: flex;
        flex-direction: row;
    }
    & .group-input{
        display: flex;
        & .field{
            align-items: start;
        }
        & input{
            border: 1px solid ${ColorName.chathamsBlueDark};
            outline: none;
            border-radius: 5px;
            padding: 5px;
        }
    }
`;

const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || "0"};
    & .title-deadline {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
    }
    & .fa-trash-alt{
        cursor: pointer;
    }
    & .for-multile-files{
        display: flex;
        flex-direction: column;
    }
    & .fonts-status {
        font-weight: bold;
        font-size: 14px;
        line-height: 25px;
        color: ${ColorName.chathamsBlueDark};
    }
    & .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: ${ColorName.tickleMePink};
    }
    & .description{
        font-size: 12px;
        color: ${ColorName.black};
        margin-top: 1.5em;
    }
    & .style-disabled{
        padding: 10px;
        border-radius: 10px;
    }
    &.dropdownStatus{
        padding: 2em 0;
        & select{
            width: 200px;
            border-radius: 5px;
            border: 2px solid gray;
            height: 30px;
            outline: none;
            margin: 1em 0;
        }
    }
    & .btnBottom{
        display: flex;
    }
    a {
        text-decoration: none;
        width: max-content;
        color: ${ColorName.chathamsBlueDark};
        i {
            margin-right: 0.3em;
        }
    }
`;
const TitleCategory = styled.div`
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    margin: 1.5em 0;
    text-transform: uppercase;
    color: ${ColorName.chathamsBlueDark};
    &:firstChild{
        margin-top: 0;
    }
    &.forQuestionnaire{
        font-size: 15px;
        display: flex;
        & .index{
            margin-right: 5px;
        }
        font-weight: 600;
    }
`;

const DetailTitle = styled.div`
    min-width: 150px;
    color: ${ColorName.chathamsBlueDark};
`;
const Column = styled.div`
    display: flex;
    padding: 10px;
    & span{
        &.pink{
            color: ${ColorName.redOrange};
        }
        &.green{
            color: ${ColorName.fern};
        }
        &.red{
            color: ${ColorName.sunsetOrange};
        }
        &.color-title {
            color: ${ColorName.chathamsBlueDark};
            font-weight: 500;
        }
    }
`;

const QuestionnaireTable = styled.table`
    & th{
        text-align: left;
        padding: 10px;
        white-space: nowrap;
        &.maxScore{
            width: 150px
        }
        font-weight: 600;
    }
    & td{
        vertical-align: top;
        padding: 10px;
        & input{
            width: 70px;
            border: 1px solid lightGray;
            border-radius: 5px;
            outline: none;
            padding 5px;
        }
    }
`;

const WrapperQuestionnaire = styled.div`
    padding-bottom: 1em;
    border-bottom: 1px solid lightGray;
`;

const Field = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: inherit;
    margin: 0 0 2em 1em;
    min-width: 400px;
    &.breakField{
        margin: 0px;
        min-width: 100vw;
    }
    & .upload-file{
        width: 100%;
        display: flex;
        padding: 1em 0;
        & p{
            margin: 0;
            cursor: pointer;
        }
        & i{
            margin: 0 1em;
        }
    }
    &.upload-container{    
        min-height: 100px;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
    }
    & .input-file-container{
        cursor: pointer;
        display: flex;
        & .action-upload{
            display: flex;
            margin-left: 1em;
            align-items: center;
            cursor: pointer;
            & i{
                margin-right: 1em;
            }
        }
    }
`;

const FieldTitle = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    text-transform: none;
    color: ${ColorName.chathamsBlue};
    margin-bottom: ${(props) => props.marginBottom || "1.5em"};
    margin-right: ${(props) => props.marginRight || "1em"};
    &.inputEdit{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    & .action-button{
        white-space: nowrap;
    }
    &.button-export{
        display: flex;
        margin-top: 1em;
        justify-content: flex-end;
    }
`;

export {
    Wrapper,
    BodyContainer,
    BodyContent,
    ProgressBar,
    WrapperTitle,
    TitleCategory,
    DetailTitle,
    Column,
    QuestionnaireTable,
    WrapperQuestionnaire,
    Field,
    FieldTitle
};
