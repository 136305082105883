import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer } from "../../../Common/CommonLayout";
import { ColorName } from "../../../Variables";

const Wrapper = styled.div`
    display: flex;
    position: fixed;
    z-index: 9;
    background-color: rgba(16, 16, 16, 0.5);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
`;

const Container = styled(HorizontalContainer)`
    width: 40%;
    background: ${ColorName.white};
    border-radius: 25px;
    border: 3px solid ${ColorName.gallery};
    padding-bottom: 0.7em;
`;

const Content = styled(VerticalContainer)`
    width: ${(props) => props.width || "100%"};
    align-items: center;
    justify-content: center;
    transition: width 0.25s;
    overflow: hidden;
`;

const Title = styled.div`
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    background: linear-gradient(179.68deg, #615155 -13.56%, #281AC8 158.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const InputContainer = styled(HorizontalContainer)`
    background: linear-gradient(178.43deg, ${ColorName.tickleMePink} -13.56%, ${ColorName.persianBlue} 158.3%);
    padding: 2px;
    border-radius: 25px;
    width: ${(props) => props.widthInput || "100%"};
    input {
        border: none;
        outline: none;
        width: ${(props) => props.widthInput || "100%"};
        height: 100%;
        font-size: 19px;
        color: ${ColorName.gray};
        border-radius: 25px;
        padding: 0.5em 1em;
        &::placeholder {
            font-size: 13px;
            font-weight: 100;
        }
    }
`;

const Fields = styled(VerticalContainer)`
    margin-top: 1em;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const Field = styled(HorizontalContainer)`
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5em;
    width: 70%;
    &.alignCenter{  
        place-content: center;
    }
`;

const Footer = styled(Fields)`
    justify-content: flex-start;
    p {
        margin: 0 auto;
        cursor: pointer;
        background: linear-gradient(178.43deg, ${ColorName.tickleMePink} -13.56%, ${ColorName.persianBlue} 158.3%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &:hover {
            background: linear-gradient(179.68deg, #615155 -13.56%, #281AC8 158.3%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
`;

const HideModalContainer = styled.div`
    position: absolute;
    top: 24%;
    left: 70.3%;
    cursor: pointer;
    @media only screen
    and (max-width: 1366px)
    {
        top: 13%;
    }
    i {
        color: ${ColorName.white};
    }
`;

const ConfirmContainer = styled.div`
    display: flex;
    place-content: center;
    place-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
`;

export {
    Wrapper,
    Container,
    Content,
    Title,
    Fields,
    Field,
    Footer,
    InputContainer,
    HideModalContainer,
    ConfirmContainer
};
