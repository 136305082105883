import styled from 'styled-components';
import {
    GeneralButton,
    ContentBodyContainer,
    GridCol,
    FlexGridSixth
} from '../../../components/Common/CommonLayout';
import { ColorName } from "../../../components/Variables";

const Container = styled(ContentBodyContainer)`
    & .content-page{
        margin: 3em 0;
    }
    padding: 0 4em;
    & .invite-item{
        margin: 2em 0;
    }
    color: ${ColorName.chathamsBlueDark};
    & .line-page {
        width: 100%;
        height: 1px;
        background: gray;
    }
    & .margin-line {
        margin: 1em 0;
        clear: both;
    }    
    & .row-news {
        margin-top: 2em;
    }
    & .relate-post {
        margin-left: 1em;
        font-weight: 600;
        
    }
    @media (max-width: 768px) {
        padding: 0;
    }
`;

const Button = styled(GeneralButton)`
    margin-right: 1em;
    border: 1px solid ${ColorName.chathamsBlueDark};
    margin-top: 1em;
`;

const FlexGridSixthTemplate = styled(FlexGridSixth)`
    flex-wrap: wrap;
    &.container-button {
        margin-top: 2em;
    }
    margin: 0 1em;
    & .border-top{
        border-top: 1px solid ${ColorName.chathamsBlueDark};
    }
    & .order-2{
        order: 2;
        padding-left: 2em;
    }
    & .order-1{
        padding-right: 2em;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        & .order-2{
            padding: 0;
        }
        & .order-1{
            padding: 0;
        }
        & .reverse{
            order: 2;
        }
    }
`;
const GridColHomepage = styled(GridCol)`
    & .description{
        margin-top: 2em;
    }
    & ul{
        list-style: none;
        padding-inline-start: 0;
        & li{
            display: flex;
            flex-direction: column;
            padding-bottom: 1em;
        }
        & .title{
            color: ${ColorName.fern};
            font-size: 1.5em;
            font-weight: 600;
        }
        & .description{
            color: ${ColorName.chathamsBlueDark};
            font-size: 0.8em;
        }
    }
    & .image-invite{
        width: 100%;
        border: 1px solid ${ColorName.chathamsBlueDark};
        // border-top: none;
    }
    & .item-card {
        cursor: pointer;
        padding: 1.5em;
        display: flex;
    }
    & .item-card-left {
        cursor: pointer;
        padding: 1.5em;
        display: flex;
        justify-content: flex-end;
    }
    & .item-avatar {
        flex: 2
    }
    & .item-avatar img {
        width: 200px;
        height: 120px;
        object-fit: scale-down;
    }
    & .item-content {
        cursor: pointer;
        flex: 3;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        color: black
    }
    & .item-content-left {
        flex: 3;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        color: black
    }
    & .justify-end {
        display: flex;
        justify-content: flex-end;
    }
    & .item-date-created {
        font-size: 13px;
        color: gray;
        display: flex;
        align-items: center;
    }
    & .item-button {
        display: flex;
        align-items: flex-end;
    }
    & .button-read-more {
        color: ${ColorName.chathamsBlueDark};
        border: 1px solid ${ColorName.chathamsBlueDark};
        padding: 4px 12px;
        font-size: 15px;
        width: max-content;
        border-top-left-radius: 10px;
        cursor: pointer;
        margin-bottom: 3px;
    }
    & .flex-1 {
        flex: 1;
    }
    & .item-title {
        font-weight: 600;
        &.justify-end{
            text-align: right;
        }
    }
    & .item-type {
        font-weight: 600
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Title = styled.div`
    font-size: 2.5em;
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid ${ColorName.chathamsBlueDark};
    & .content{
        font-weight: 200;
    }
`;

const Content = styled.div`
    margin-top: 2em;
    & .title {
        font-size: 18px;
        font-weight: 700;
    }
    & .detail {
        padding: 3px 0 15px 0;
    }
    & .author {
        font-size: 13px;
        color: #57B6B6
    }
    & .date-created {
        font-size: 13px;
        color: gray;
    }
    & .text {
        font-size: 15px;
        color: ${ColorName.scorpion};
        margin-bottom: 2em;
        & .attach-file{
            margin-left: 1em;
        }
        p {
            margin-top: 0px !important;
        }
    }
    & .cover-image {
        width: 25%;
        float: left;
        margin: 0 2em 1em 0;
        img {
            width: 100%;
        }
    }
`;

const ContentMenu = styled.div`
    margin-top: 1em;
    & .title-menu {
        font-weight: 600;
        color: black;
        padding-bottom: 1em;
    }
    & .text {
        font-size: 15px;
        color: ${ColorName.scorpion};
    }
    & .img-content-menu {
        padding-top: 0.5em;
        display: flex;
        justify-content: center;
    }
    & .img-content-menu img{
        max-width: 100%;
    }
    
`;

export {
    Container,
    GridColHomepage,
    Title,
    FlexGridSixthTemplate,
    Button,
    Content,
    ContentMenu
};
