import _ from "lodash";
import CountryList from "../../../../constants/CountryList";
const languages = require('language-list')();
const cities = require('country-city');

const resolveCountryList = () => {
    const result = [];
    _.map(CountryList.countryListDefault, (country) => {
        result.push({
            value: country,
            label: country
        });
    });
    return result;
};

const resolveLanguageList = () => {
    const result = [];
    const langList = languages.getData();
    _.map(langList, (lang) => {
        result.push({
            value: lang.language,
            label: lang.language
        });
    });
    return result;
};

const industryOptions = [
    {
        value: "Agriculture, forestry and fishing",
        label: "Agriculture, forestry and fishing"
    },
    {
        value: "Mining and quarrying",
        label: "Mining and quarrying"
    },
    {
        value: "Manufactured",
        label: "Manufactured"
    },
    {
        value: "Electricity, gas, steam and air conditioning supply",
        label: "Electricity, gas, steam and air conditioning supply"
    },
    {
        value: "Water supply, sewerage, waste management and remediation activities",
        label: "Water supply, sewerage, waste management and remediation activities"
    },
    {
        value: "Construction",
        label: "Construction"
    },
    {
        value: "Wholesale and retail trade; repair of motor vehicles and motorcycles",
        label: "Wholesale and retail trade; repair of motor vehicles and motorcycles"
    },
    {
        value: "Transportation and storage",
        label: "Transportation and storage"
    },
    {
        value: "Accommodation and food service activities",
        label: "Accommodation and food service activities"
    },
    {
        value: "Information and communication",
        label: "Information and communication"
    },
    {
        value: "Real estate activities",
        label: "Real estate activities"
    },
    {
        value: "Professional, scientific and technical activities",
        label: "Professional, scientific and technical activities"
    },
    {
        value: "Administrative and support service activities",
        label: "Administrative and support service activities"
    },
    {
        value: "Public administration and defence; compulsory social activities",
        label: "Public administration and defence; compulsory social activities"
    },
    {
        value: "Education",
        label: "Education"
    },
    {
        value: "Human health and social work activities",
        label: "Human health and social work activities"
    },
    {
        value: "Arts, entertainment and recreation",
        label: "Arts, entertainment and recreation"
    },
    {
        value: "Other service activities",
        label: "Other service activities"
    }
];

const yearOfEstablishment = () => {
    const now = new Date().getUTCFullYear();
    const years = Array(now - (now - 20)).fill('').map((v, idx) => now - idx);
    const result = years.map((year) => {
        return {
            value: year,
            label: year
        };
    });
    return result;
};

const getCityList = (country) => {
    const result = [];
    const cityArrays = cities.getCities(country);
    _.map(cityArrays, (city) => {
        result.push({
            value: city,
            label: city
        });
    });
    return result;
};

export default {
    resolveCountryList,
    industryOptions,
    yearOfEstablishment,
    resolveLanguageList,
    getCityList
};
