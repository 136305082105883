import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import Constants from "../constants/Constants";
import i18n from "../i18n";

const requestHasError = () => {
    return {
        type: ActionTypes.SPONSOR_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.SPONSOR_REQUEST
    };
};

const addSponsorSuccess = (payload) => {
    return {
        type: ActionTypes.ADD_SPONSOR_SUCCESS,
        payload
    };
};

const updateSponsorSuccess = (payload) => {
    return {
        type: ActionTypes.UPDATE_SPONSOR_SUCCESS,
        payload
    };
};

const getSponsorByIdSuccess = (payload) => {
    return {
        type: ActionTypes.GET_SPONSOR_BY_ID_SUCCESS,
        payload
    };
};

const getAllSponsorSuccess = (payload) => {
    return {
        type: ActionTypes.GET_ALL_SPONSOR_SUCCESS,
        payload
    };
};
const setSponsorId = (payload) => {
    return {
        type: ActionTypes.SET_SPONSOR_ID_SUCCESS,
        payload
    };
};

const addSponsor = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addSponsor(data)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    dispatch(addSponsorSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateSponsor = (data, id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateSponsor(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    dispatch(updateSponsorSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getAllSponsor = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getAllSponsor(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getAllSponsorSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateStatusSponsor = (data, id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateSponsor(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    const dataGet = {
                        page: 1,
                        item_per_page: Constants.ITEM_PER_PAGE,
                        language: i18n.language
                    };
                    dispatch(getAllSponsor(dataGet));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getSponsorById = (data, id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getSponsorById(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getSponsorByIdSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const deleteSponsor = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.deleteSponsor(id)
            .then((res) => {
                if (res.data.status === true) {
                    const dataGet = {
                        page: 1,
                        item_per_page: Constants.ITEM_PER_PAGE,
                        language: i18n.language
                    };
                    dispatch(getAllSponsor(dataGet));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(res), type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};
export default {
    addSponsor,
    getSponsorById,
    getAllSponsor,
    setSponsorId,
    updateSponsor,
    deleteSponsor,
    updateStatusSponsor
};
