import React from "react";
import { Route } from "react-router-dom";

import Homepage from "../containers/PortalContainer/Homepage";
import UserOverview from "../containers/PortalContainer/User/Overview";
import UserFunding from '../containers/PortalContainer/User/Funding';
import UserExplore from "../containers/PortalContainer/User/Explore";
import ApplicationsDetail from "../containers/PortalContainer/User/Funding/ApplicationsDetail";
import FundingReports from '../containers/PortalContainer/User/FundingReport';
import ConfirmEmail from '../components/Layout/PortalTemplate/LoginAndRegister/ConfirmEmail';
import Message from '../containers/PortalContainer/Message';
import AddViewUser from "../containers/PortalContainer/User/Overview/AddViewUser";
import Register from "../containers/PortalContainer/Authentication/Register";
import ForgotPassword from "../containers/PortalContainer/Authentication/ForgotPassword";
import UserApplyApplications from "../components/Common/UserApplyApplications";
import UserFundingDetail from "../containers/PortalContainer/User/FundingDetail";
import ViewCategoryQuestionnaire from "../containers/PortalContainer/User/FundingDetail/ViewCategoryQuestionnaire";
import Judge from "../containers/PortalContainer/Judge";
import ApplyApplicationDetails from "../containers/PortalContainer/Judge/ApplyApplicationDetails";
import AllApplies from "../containers/PortalContainer/Judge/AllApplyApplication";
import Notification from "../containers/PortalContainer/Notification";
import Search from "../containers/PortalContainer/Search";
import Evaluation from "../containers/PortalContainer/Judge/Evaluation";
import JudgeSettings from "../containers/PortalContainer/Judge/Settings";
import MessageManage from "../containers/PortalContainer/MessageManage";
import JudgeExportSubmitted from "../containers/PortalContainer/Judge/ExportSubmitted";
import ChangePassword from "../containers/PortalContainer/Authentication/ChangePasswordUser";

import OverView from "../containers/PortalContainer/Admin/Overview";
import Users from "../containers/PortalContainer/Admin/Users";
import FundingApplications from "../containers/PortalContainer/Admin/FundingApplications";
import CreateNewFundingApplication from "../containers/PortalContainer/Admin/FundingApplications/CreateNewFundingApplication";
import CategoryForQuestionnaire from "../containers/PortalContainer/Admin/FundingApplications/CategoryForQuestionnaire";
import UserDetails from "../containers/PortalContainer/Admin/Users/UserDetails";
import FundingApplicationDetails from "../containers/PortalContainer/Admin/FundingApplications/FundingApplicationDetails";
import AdminUpdateInfomation from "../containers/PortalContainer/Admin/Settings/AdminUpdateInfomation";
import AdminFundingReport from "../containers/PortalContainer/Admin/Report";
import AdminApplyApplicationDetails from "../containers/PortalContainer/Admin/FundingApplications/ApplyApplicationDetails";
import AdminAllApplies from "../containers/PortalContainer/Admin/FundingApplications/AllApplyApplication";
import AdminEvaluation from "../containers/PortalContainer/Admin/Evaluation";
import AdminEvaluationApply from "../containers/PortalContainer/Admin/Evaluation/EvaluationApply";
import AddUser from "../containers/PortalContainer/Admin/Users/AddUser";
import AddNews from "../containers/PortalContainer/Admin/News/AddNews";
import AboutUsSetting from "../containers/PortalContainer/Admin/GuestPortalSettings/AboutUsSetting";
import SummarySetting from "../containers/PortalContainer/Admin/GuestPortalSettings/SummarySetting";
import News from "../containers/PortalContainer/Admin/News";
import EditNews from "../containers/PortalContainer/Admin/News/Edit";
import AddEvent from "../containers/PortalContainer/Admin/Event/AddEvent";
import Events from "../containers/PortalContainer/Admin/Event";
import EditEvent from "../containers/PortalContainer/Admin/Event/Edit";
import Library from "../containers/PortalContainer/Admin/Library";
import AddBook from "../containers/PortalContainer/Admin/Library/AddBook";
import EditBook from "../containers/PortalContainer/Admin/Library/EditBook";
import Process from "../containers/PortalContainer/Admin/Process/AddProcess";
import TermSetting from "../containers/PortalContainer/Admin/GuestPortalSettings/TermSetting";
import PrivacyPolicySetting from "../containers/PortalContainer/Admin/GuestPortalSettings/PrivacyPolicySetting";
import DisclosureSetting from "../containers/PortalContainer/Admin/GuestPortalSettings/DisclosureSetting";
import TestimonialSetting from "../containers/PortalContainer/Admin/GuestPortalSettings/TestimonialSetting";
import AddTestimonial from "../containers/PortalContainer/Admin/GuestPortalSettings/TestimonialSetting/AddTestimonial";
import EditTestimonial from "../containers/PortalContainer/Admin/GuestPortalSettings/TestimonialSetting/EditTestimonial";
import ApplySetting from "../containers/PortalContainer/Admin/GuestPortalSettings/ApplySetting";
import LimitedUser from "../containers/PortalContainer/Admin/LimitedUser";
import GenerateLink from "../containers/PortalContainer/Admin/LimitedUser/GenerateLink";
import AddLimitedUser from "../containers/PortalContainer/Admin/LimitedUser/AddLimitedUser";
import AddSponsor from "../containers/PortalContainer/Admin/Sponsor/AddSponsor";
import Sponsor from "../containers/PortalContainer/Admin/Sponsor";
import EditSponsor from "../containers/PortalContainer/Admin/Sponsor/Edit";
import ContactUs from "../containers/PortalContainer/Admin/GuestPortalSettings/ContactUs/ContactUs";
import FAQ from "../containers/PortalContainer/Admin/GuestPortalSettings/FAQ/AddFAQs";
import Introduce from "../containers/PortalContainer/Admin/GuestPortalSettings/Introduce/Introduce";
import AddCriteria from "../containers/PortalContainer/Admin/GuestPortalSettings/Criteria/AddCriteria";
import Criteria from "../containers/PortalContainer/Admin/GuestPortalSettings/Criteria";
import EditCriteria from "../containers/PortalContainer/Admin/GuestPortalSettings/Criteria/Edit";
import AnnouncementSetting from "../containers/PortalContainer/Admin/GuestPortalSettings/Announcement";
import ExportSubmitted from "../containers/PortalContainer/Admin/FundingApplications/ExportSubmitted";

// Limited admin
import LimitedOverView from "../containers/PortalContainer/LimitedAdmin/Overview";
import LimitedUsers from "../containers/PortalContainer/LimitedAdmin/Users";
import LimitedUserDetails from "../containers/PortalContainer/LimitedAdmin/Users/UserDetails";
import LimitedAdminUpdateInfomation from "../containers/PortalContainer/LimitedAdmin/Settings/AdminUpdateInfomation";
import LimitedAddUser from "../containers/PortalContainer/LimitedAdmin/Users/AddUser";
import LimitedAddNews from "../containers/PortalContainer/LimitedAdmin/News/AddNews";
import LimitedAboutUsSetting from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/AboutUsSetting";
import LimitedSummarySetting from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/SummarySetting";
import LimitedNews from "../containers/PortalContainer/LimitedAdmin/News";
import LimitedEditNews from "../containers/PortalContainer/LimitedAdmin/News/Edit";
import LimitedAddEvent from "../containers/PortalContainer/LimitedAdmin/Event/AddEvent";
import LimitedEvents from "../containers/PortalContainer/LimitedAdmin/Event";
import LimitedEditEvent from "../containers/PortalContainer/LimitedAdmin/Event/Edit";
import LimitedLibrary from "../containers/PortalContainer/LimitedAdmin/Library";
import LimitedAddBook from "../containers/PortalContainer/LimitedAdmin/Library/AddBook";
import LimitedEditBook from "../containers/PortalContainer/LimitedAdmin/Library/EditBook";
import LimitedProcess from "../containers/PortalContainer/LimitedAdmin/Process/AddProcess";
import LimitedTermSetting from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/TermSetting";
import LimitedPrivacyPolicySetting from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/PrivacyPolicySetting";
import LimitedDisclosureSetting from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/DisclosureSetting";
import LimitedTestimonialSetting from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/TestimonialSetting";
import LimitedAddTestimonial from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/TestimonialSetting/AddTestimonial";
import LimitedEditTestimonial from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/TestimonialSetting/EditTestimonial";
import LimitedApplySetting from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/ApplySetting";
import LimitedAddSponsor from "../containers/PortalContainer/LimitedAdmin/Sponsor/AddSponsor";
import LimitedSponsor from "../containers/PortalContainer/LimitedAdmin/Sponsor";
import LimitedEditSponsor from "../containers/PortalContainer/LimitedAdmin/Sponsor/Edit";
import LimitedContactUs from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/ContactUs/ContactUs";
import LimitedFAQ from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/FAQ/AddFAQs";
import LimitedIntroduce from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/Introduce/Introduce";
import LimitedAddCriteria from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/Criteria/AddCriteria";
import LimitedCriteria from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/Criteria";
import LimitedEditCriteria from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/Criteria/Edit";
import LimitedAnnouncementSetting from "../containers/PortalContainer/LimitedAdmin/GuestPortalSettings/Announcement";

const PortalRoute = () => (
    <div>
        <Route exact path="/404" render={(props) => (<Homepage breadcrumb="Home" {...props} />)} />
        <Route exact path="/Register" component={Register} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/judge/change-password" component={ChangePassword} />
        <Route exact path="/change-password" component={ChangePassword} />

        {/* admin router */}
        <Route exact path="/admin/change-password" component={ChangePassword} />
        <Route exact path="/admin/overview" component={OverView} />
        <Route exact path="/admin/user-details" component={UserDetails} />
        <Route exact path="/admin/funding-application-details" component={FundingApplicationDetails} />
        <Route exact path="/admin/users" component={Users} />
        <Route exact path="/admin/funding-applications" component={FundingApplications} />
        <Route exact path="/admin/new-funding-application" component={CreateNewFundingApplication} />
        <Route exact path="/admin/update" component={AdminUpdateInfomation} />
        <Route exact path="/admin/category-questionnaire" component={CategoryForQuestionnaire} />
        <Route exact path="/admin/messages" component={Message} />
        <Route exact path="/admin/applications/details" component={AdminApplyApplicationDetails} />
        <Route exact path="/admin/applications/list-applies" component={AdminAllApplies} />
        <Route exact path="/admin/report" component={AdminFundingReport} />
        <Route exact path="/admin/notifications" component={Notification} />
        <Route exact path="/admin/search" component={Search} />
        <Route exact path="/admin/evaluation" component={AdminEvaluation} />
        <Route exact path="/admin/evaluation-apply" component={AdminEvaluationApply} />
        <Route exact path="/admin/add-user" component={AddUser} />
        <Route exact path="/admin/add-news" component={AddNews} />
        <Route exact path="/admin/about-us-setting" component={AboutUsSetting} />
        <Route exact path="/admin/summary-setting" component={SummarySetting} />
        <Route exact path="/admin/news" component={News} />
        <Route exact path="/admin/edit-news" component={EditNews} />
        <Route exact path="/admin/add-event" component={AddEvent} />
        <Route exact path="/admin/events" component={Events} />
        <Route exact path="/admin/edit-event" component={EditEvent} />
        <Route exact path="/admin/library" component={Library} />
        <Route exact path="/admin/add-book" component={AddBook} />
        <Route exact path="/admin/edit-book" component={EditBook} />
        <Route exact path="/admin/process" component={Process} />
        <Route exact path="/admin/term-setting" component={TermSetting} />
        <Route exact path="/admin/privacy-policy-setting" component={PrivacyPolicySetting} />
        <Route exact path="/admin/disclosure-setting" component={DisclosureSetting} />
        <Route exact path="/admin/testimonial-setting" component={TestimonialSetting} />
        <Route exact path="/admin/add-testimonial" component={AddTestimonial} />
        <Route exact path="/admin/edit-testimonial" component={EditTestimonial} />
        <Route exact path="/admin/apply-setting" component={ApplySetting} />
        <Route exact path="/admin/limited-user" component={LimitedUser} />
        <Route exact path="/admin/add-limited-user" component={AddLimitedUser} />
        <Route exact path="/admin/add-sponsor" component={AddSponsor} />
        <Route exact path="/admin/sponsors" component={Sponsor} />
        <Route exact path="/admin/edit-sponsor" component={EditSponsor} />
        <Route exact path="/admin/contact-us-setting" component={ContactUs} />
        <Route exact path="/admin/faqs" component={FAQ} />
        <Route exact path="/admin/message-manage" component={MessageManage} />
        <Route exact path="/admin/introduce-manage" component={Introduce} />
        <Route exact path="/admin/add-criteria" component={AddCriteria} />
        <Route exact path="/admin/criteria" component={Criteria} />
        <Route exact path="/admin/edit-criteria" component={EditCriteria} />
        <Route exact path="/admin/generate-link-limited-user" component={GenerateLink} />
        <Route exact path="/admin/announcement-setting" component={AnnouncementSetting} />
        <Route exact path="/admin/export-submitted" component={ExportSubmitted} />

        {/* user Router */}
        <Route exact path="/user-overview" component={UserOverview} />
        <Route exact path="/user-funding" component={UserFunding} />
        <Route exact path="/user-explore" component={UserExplore} />
        <Route exact path="/user-applications-detail" component={ApplicationsDetail} />
        <Route exact path="/user-funding-report" component={FundingReports} />
        <Route exact path="/confirm-email" component={ConfirmEmail} />
        <Route exact path="/messages" component={Message} />
        <Route exact path="/add-view-user" component={AddViewUser} />
        <Route exact path="/user-apply-application" component={UserApplyApplications} />
        <Route exact path="/user-funding-detail" component={UserFundingDetail} />
        <Route exact path="/view-category-questionnaire" component={ViewCategoryQuestionnaire} />
        <Route exact path="/notifications" component={Notification} />
        <Route exact path="/search" component={Search} />

        {/* Judge Router */}
        <Route exact path="/judge/export-submitted" component={JudgeExportSubmitted} />
        <Route exact path="/judge/settings" component={JudgeSettings} />
        <Route exact path="/judge/messages" component={Message} />
        <Route exact path="/judge/applications" component={Judge} />
        <Route exact path="/judge/applications/details" component={ApplyApplicationDetails} />
        <Route exact path="/judge/applications/list-applies" component={AllApplies} />
        <Route exact path="/judge/notifications" component={Notification} />
        <Route exact path="/judge/search" component={Search} />
        <Route exact path="/judge/evaluation" component={Evaluation} />

        {/* Limited admin router */}
        <Route exact path="/limited-admin/change-password" component={ChangePassword} />
        <Route exact path="/limited-admin/overview" component={LimitedOverView} />
        <Route exact path="/limited-admin/user-details" component={LimitedUserDetails} />
        <Route exact path="/limited-admin/users" component={LimitedUsers} />
        <Route exact path="/limited-admin/update" component={LimitedAdminUpdateInfomation} />
        <Route exact path="/limited-admin/messages" component={Message} />
        <Route exact path="/limited-admin/notifications" component={Notification} />
        <Route exact path="/limited-admin/search" component={Search} />
        <Route exact path="/limited-admin/add-user" component={LimitedAddUser} />
        <Route exact path="/limited-admin/add-news" component={LimitedAddNews} />
        <Route exact path="/limited-admin/about-us-setting" component={LimitedAboutUsSetting} />
        <Route exact path="/limited-admin/summary-setting" component={LimitedSummarySetting} />
        <Route exact path="/limited-admin/news" component={LimitedNews} />
        <Route exact path="/limited-admin/edit-news" component={LimitedEditNews} />
        <Route exact path="/limited-admin/add-event" component={LimitedAddEvent} />
        <Route exact path="/limited-admin/events" component={LimitedEvents} />
        <Route exact path="/limited-admin/edit-event" component={LimitedEditEvent} />
        <Route exact path="/limited-admin/library" component={LimitedLibrary} />
        <Route exact path="/limited-admin/add-book" component={LimitedAddBook} />
        <Route exact path="/limited-admin/edit-book" component={LimitedEditBook} />
        <Route exact path="/limited-admin/process" component={LimitedProcess} />
        <Route exact path="/limited-admin/term-setting" component={LimitedTermSetting} />
        <Route exact path="/limited-admin/privacy-policy-setting" component={LimitedPrivacyPolicySetting} />
        <Route exact path="/limited-admin/disclosure-setting" component={LimitedDisclosureSetting} />
        <Route exact path="/limited-admin/testimonial-setting" component={LimitedTestimonialSetting} />
        <Route exact path="/limited-admin/add-testimonial" component={LimitedAddTestimonial} />
        <Route exact path="/limited-admin/edit-testimonial" component={LimitedEditTestimonial} />
        <Route exact path="/limited-admin/apply-setting" component={LimitedApplySetting} />
        <Route exact path="/limited-admin/add-sponsor" component={LimitedAddSponsor} />
        <Route exact path="/limited-admin/sponsors" component={LimitedSponsor} />
        <Route exact path="/limited-admin/edit-sponsor" component={LimitedEditSponsor} />
        <Route exact path="/limited-admin/contact-us-setting" component={LimitedContactUs} />
        <Route exact path="/limited-admin/faqs" component={LimitedFAQ} />
        <Route exact path="/limited-admin/introduce-manage" component={LimitedIntroduce} />
        <Route exact path="/limited-admin/add-criteria" component={LimitedAddCriteria} />
        <Route exact path="/limited-admin/criteria" component={LimitedCriteria} />
        <Route exact path="/limited-admin/edit-criteria" component={LimitedEditCriteria} />
        <Route exact path="/limited-admin/announcement-setting" component={LimitedAnnouncementSetting} />
    </div>
);

export default PortalRoute;
