import React from 'react';
import { IconDiamondComponent } from "./IconDiamond.styles";

const IconDiamond = () => {
    return (
        <IconDiamondComponent>
            <div className="diamond"/>
        </IconDiamondComponent>
    )
};

export default IconDiamond;