import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import JoditEditor from "jodit-react";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    PageTitle,
    GeneralButton,
    LabelSlide
} from "../../../../components/Common/CommonLayout";
import {
    BodyContainer,
    LibraryContainer,
    WrapperInput,
    TitleInput,
    InputContainer,
    GeneralButtonLanguage
} from "./Library.styles";
import { ColorName } from "../../../../components/Variables";
import LibraryActions from "../../../../actions/Library";
import Utils from "../../../../libs/Utils";
import Validation from "../../../../libs/Validation";
import GeneralCheckBoxForm from "../../../../components/Common/GeneralCheckBoxForm";
import CONSTANTS from "../../../../constants/Constants";
import { Images } from "../../../../themes";

const AddBook = () => {
    const checkBoxRef = [];
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.Library.isLoading);
    const [imageUrl, setImageUrl] = useState("");
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());
    const [videoUrl, setVideoUrl] = useState("");
    const editor = useRef(null);
    const editorAl = useRef(null);
    const [contentEn] = useState('');
    const [contentAl] = useState('');
    const [postData, setPostData] = useState({
        title: {
            en: '',
            al: ''
        },
        cover_img: "",
        description: {
            en: '',
            al: ''
        },
        link_youtube: '',
        is_incoming: false,
        is_support_mul_lang: false,
        type: CONSTANTS.LIBRARY_TYPE.BOOK
    });
    const [detailData, setDetailData] = useState([
        {
            title: "",
            introduction: "",
            content: ""
        }
    ]);
    const [ALDetailData, setALDetailData] = useState([
        {
            title: "",
            introduction: "",
            content: ""
        }
    ]);

    const addNewChapter = () => {
        const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? [...detailData] : [...ALDetailData];
        const checkEmptyField = _.find(
            currentDetailData,
            (el) => el.title === "" || el.content === "" || el.introduction === ""
        );
        if (checkEmptyField) {
            return Utils.popupAlert({
                title: t("alert.Warning"),
                text: t("alert.Please fill all field before add new chapter!"),
                type: "warning"
            });
        }
        currentDetailData.push({
            title: "",
            introduction: "",
            content: ""
        });
        language === CONSTANTS.LANGUAGE_CODE.EN ? setDetailData(currentDetailData) : setALDetailData(currentDetailData);
    };

    const handleChangeDetailData = (original, value, field) => {
        const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? [...detailData] : [...ALDetailData];
        const editedRow = _.find(currentDetailData, original);
        editedRow[field] = value;
        const index = _.findIndex(currentDetailData, original);
        currentDetailData.splice(index, 1, editedRow);
        language === CONSTANTS.LANGUAGE_CODE.EN ? setDetailData(currentDetailData) : setALDetailData(currentDetailData);
    };

    const handleChangeFile = (event, type) => {
        if (event.target.files) {
            const url = URL.createObjectURL(event.target.files[0]);
            if (type === 'image') {
                setPostData({ ...postData, cover_img: event.target.files[0] });
                setImageUrl(url);
            } else if (type === 'video') {
                setVideoUrl(url);
                setPostData({ ...postData, media: event.target.files[0] });
            } else {
                setPostData({ ...postData, media: event.target.files[0] });
            }
        }
    };

    const removeChapter = (data) => {
        const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? [...detailData] : [...ALDetailData];
        const index = _.findIndex(currentDetailData, data);
        currentDetailData.splice(index, 1);
        language === CONSTANTS.LANGUAGE_CODE.EN ? setDetailData(currentDetailData) : setALDetailData(currentDetailData);
    };

    const _resolveDetailData = () => {
        const result = {};
        if (postData.is_support_mul_lang) {
            _.map(detailData, (data, index) => {
                const resolveData = {};
                _.map(_.keys(data), (key) => {
                    resolveData[key] = { en: data[key] };
                });
                result[index] = resolveData;
            });
            _.map(ALDetailData, (data, index) => {
                _.map(_.keys(data), (key) => {
                    result[index][key] = { ...result[index][key], al: data[key] };
                });
            });
        } else {
            const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? detailData : ALDetailData;
            _.map(currentDetailData, (data, index) => {
                const resolveData = {};
                _.map(_.keys(data), (key) => {
                    resolveData[key] = { [language]: data[key] };
                });
                result[index] = resolveData;
            });
        }
        return result;
    };

    const addNewLibrary = () => {
        let validationRules = [];
        if (postData.is_support_mul_lang) {
            validationRules = [
                {
                    name: `${t("general.Title")} ${t("general.English")}`,
                    value: postData.title.en,
                    rule: ["required", { min: 10, max: 200 }]
                },
                {
                    name: `${t("general.Title")} ${t("general.Albania")}`,
                    value: postData.title.al,
                    rule: ["required", { min: 10, max: 200 }]
                },
                {
                    name: t("library.Cover Image"),
                    value: postData.cover_img,
                    rule: "required"
                },
                {
                    name: t("library.Incoming"),
                    value: postData.is_incoming,
                    rule: "required"
                },
                {
                    name: `${t("general.Description")} ${t("general.English")}`,
                    value: editor.current.value,
                    rule: "required"
                },
                {
                    name: `${t("general.Description")} ${t("general.Albania")}`,
                    value: editorAl.current.value,
                    rule: "required"
                }
            ];
        } else {
            const textEditor = language === CONSTANTS.LANGUAGE_CODE.EN ? editor : editorAl;
            validationRules = [
                {
                    name: t("general.Title"),
                    value: postData.title[language],
                    rule: ["required", { min: 10, max: 200 }]
                },
                {
                    name: t("library.Cover Image"),
                    value: postData.cover_img,
                    rule: "required"
                },
                {
                    name: t("library.Incoming"),
                    value: postData.is_incoming,
                    rule: "required"
                },
                {
                    name: t("general.Description"),
                    value: textEditor.current.language,
                    rule: "required"
                }
            ];
        }
        if (!postData.is_incoming && postData.type === CONSTANTS.LIBRARY_TYPE.BOOK) {
            if (postData.is_support_mul_lang) {
                validationRules.push({
                    name: t("library.Chapter"),
                    value: detailData,
                    rule: [{ required: ["title", "content", "introduction"] }]
                });
                validationRules.push({
                    name: t("library.Chapter"),
                    value: ALDetailData,
                    rule: [{ required: ["title", "content", "introduction"] }]
                });
            } else {
                const currentDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? detailData : ALDetailData;
                validationRules.push({
                    name: t("library.Chapter"),
                    value: currentDetailData,
                    rule: [{ required: ["title", "content", "introduction"] }]
                });
            }
        }
        if (!Validation.formValidation(validationRules)) return;
        const formData = new FormData();
        formData.append("title", JSON.stringify(postData.title));
        formData.append("media", postData.media);
        formData.append("media_al", postData.media_al);
        formData.append("is_incoming", postData.is_incoming);
        formData.append('description', JSON.stringify({ en: editor.current.value, al: editorAl.current.value }));
        formData.append("link_youtube", postData.link_youtube);
        formData.append("is_support_mul_lang", postData.is_support_mul_lang);
        formData.append("type", postData.type);
        formData.append("cover_img", postData.cover_img);
        if (!postData.is_incoming) {
            const resolveData = _resolveDetailData();
            _.map(resolveData, (data, index) => {
                _.map(_.keys(data), (key) => {
                    formData.append(`data_detail[${index}][${key}]`, JSON.stringify(data[key]));
                });
            });
        }
        dispatch(LibraryActions.createNewBook(formData));
    };

    const onChangePostData = (value, type) => {
        const currentPostData = { ...postData };
        if (type === "link_youtube") return setPostData({ ...currentPostData, link_youtube: value });
        language === CONSTANTS.LANGUAGE_CODE.EN ? currentPostData[type].en = value : currentPostData[type].al = value;
        setPostData(currentPostData);
    };

    const _renderCreateButton = () => {
        return (
            <div className="container-button mt-2">
                <GeneralButton
                    type="button"
                    className="add-book"
                    background={ColorName.chathamsBlue}
                    value={t("general.Create")}
                    onClick={() => addNewLibrary()}
                />
            </div>
        );
    };

    const _renderAddNewChapter = () => {
        return (
            <div className="container-button">
                <GeneralButton
                    type="button"
                    background={ColorName.chathamsBlue}
                    value={t("library.Add New Chapter")}
                    onClick={() => addNewChapter()}
                />
            </div>
        );
    };

    const _renderDetailContent = () => {
        const renderDetailData = language === CONSTANTS.LANGUAGE_CODE.EN ? detailData : ALDetailData;
        const render = _.map(renderDetailData, (detail, index) => {
            return (
                <div key={`${index}${language}`}>
                    <WrapperInput>
                        <TitleInput className="chapter">
                            {t("library.Chapter")}
                            {' '}
                            {index + 1}
                            {' '}
                            :
                        </TitleInput>
                        {index !== 0 && (
                            <i
                                className="fas fa-trash-alt"
                                onClick={() => removeChapter(detail)}
                            />
                        )}
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("general.Title")}</TitleInput>
                        <InputContainer
                            onChange={(event) => handleChangeDetailData(
                                detail,
                                event.target.value,
                                "title"
                            )}
                            defaultValue={detail.title}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("library.Introduction")}</TitleInput>
                        <InputContainer
                            onChange={(event) => handleChangeDetailData(
                                detail,
                                event.target.value,
                                "introduction"
                            )}
                            defaultValue={detail.introduction}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("general.Content")}</TitleInput>
                        <JoditEditor
                            onChange={(newContent) => handleChangeDetailData(
                                detail,
                                newContent,
                                "content"
                            )}
                            value={detail.content}
                        />
                    </WrapperInput>
                </div>
            );
        });
        return render;
    };

    const _renderCheckBoxInComming = () => {
        return (
            <span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={postData.is_incoming}
                    onChange={() => setPostData({
                        ...postData,
                        is_incoming: !postData.is_incoming
                    })
                    }
                    name="yes"
                    checkBoxRef={(ref) => (checkBoxRef.yes = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.yes.click()}>{t("general.Yes")}</span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={!postData.is_incoming}
                    onChange={() => setPostData({
                        ...postData,
                        is_incoming: !postData.is_incoming
                    })
                    }
                    name="no"
                    className="ml-2"
                    checkBoxRef={(ref) => (checkBoxRef.no = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.no.click()}>{t("general.No")}</span>
            </span>
        );
    };

    const _renderSwitchLangugage = () => {
        return (
            <div className="language">
                <LabelSlide className="switch">
                    <input type="checkbox" value={postData.is_support_mul_lang} onChange={() => setPostData({ ...postData, is_support_mul_lang: !postData.is_support_mul_lang })} />
                    <span className="slider round" />
                </LabelSlide>
                {postData.is_support_mul_lang
                    && (
                        <>
                            <GeneralButtonLanguage
                                type="button"
                                className={(language === "en") ? "active" : ""}
                                value={t("general.English")}
                                onClick={() => setLanguage("en")}
                            />
                            <GeneralButtonLanguage
                                type="button"
                                className={(language === "al") ? "active" : ""}
                                value={t("general.Albania")}
                                onClick={() => setLanguage("al")}
                            />
                        </>
                    )}
                {!postData.is_support_mul_lang
                    && (
                        <>
                            <GeneralButtonLanguage
                                type="button"
                                className={(language === "en") ? "active disable" : "disable disable-unclick"}
                                value={t("general.English")}
                                onClick={() => setLanguage("en")}
                                disabled
                            />
                            <GeneralButtonLanguage
                                type="button"
                                className={(language === "al") ? "active disable" : "disable disable-unclick"}
                                value={t("general.Albania")}
                                onClick={() => setLanguage("al")}
                                disabled
                            />
                        </>
                    )}
            </div>
        );
    };

    const _renderCheckBoxType = () => {
        return (
            <span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={postData.type === CONSTANTS.LIBRARY_TYPE.BOOK}
                    onChange={() => setPostData({
                        ...postData,
                        type: CONSTANTS.LIBRARY_TYPE.BOOK
                    })
                    }
                    name="book"
                    checkBoxRef={(ref) => (checkBoxRef.book = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.book.click()}>{t("library.Books")}</span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={postData.type === CONSTANTS.LIBRARY_TYPE.VIDEO}
                    onChange={() => setPostData({
                        ...postData,
                        type: CONSTANTS.LIBRARY_TYPE.VIDEO
                    })
                    }
                    name="video"
                    className="ml-2"
                    checkBoxRef={(ref) => (checkBoxRef.video = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.video.click()}>{t("general.Video")}</span>
                <GeneralCheckBoxForm
                    value=""
                    defaultChecked={postData.type === CONSTANTS.LIBRARY_TYPE.PDF}
                    onChange={() => setPostData({
                        ...postData,
                        type: CONSTANTS.LIBRARY_TYPE.PDF
                    })
                    }
                    name="pdf"
                    className="ml-2"
                    checkBoxRef={(ref) => (checkBoxRef.pdf = ref)}
                />
                <span className="checkBox" onClick={() => checkBoxRef.pdf.click()}>PDF</span>
            </span>
        );
    };

    const _renderVideoUploadContainer = () => {
        return (
            <WrapperInput>
                <TitleInput>{t("general.Video")}</TitleInput>
                <input
                    type="file"
                    accept="video/*"
                    onChange={(event) => handleChangeFile(event, "video")}
                />
                {videoUrl && (
                    <video controls>
                        <source src={videoUrl} />
                    </video>
                )}
            </WrapperInput>
        );
    };

    const handleChangeFilePDFAl = (event) => {
        if (event.target.files.length > 0) {
            setPostData({ ...postData, media_al: event.target.files[0] });
        }
    };

    const deletePDF = () => {
        setPostData({ ...postData, media: "" });
    };

    const deletePDFAl = () => {
        setPostData({ ...postData, media_al: "" });
    };

    const _renderUploadPDF = () => {
        return (
            <>
                <WrapperInput className={language === CONSTANTS.LANGUAGE_CODE.AL ? "hidden" : ""}>
                <TitleInput>PDF</TitleInput>
                <input
                    type="file"
                    accept=".pdf"
                    onChange={(event) => handleChangeFile(event, "pdf")}
                />
                {postData.media
                    && (
                        <>
                            <div className="contain-remove">
                                <img className="pdf" src={Images.PDFIcon} alt='pdf' />
                                <span onClick={() => deletePDF()}>X</span>
                            </div>

                        </>
                    )
                }
                {postData.media && (
                    <p>{postData.media.name}</p>
                )
                }
                </WrapperInput>
                <WrapperInput className={language === CONSTANTS.LANGUAGE_CODE.EN ? "hidden" : ""}>
                <TitleInput>PDF</TitleInput>
                <input
                    type="file"
                    accept=".pdf"
                    onChange={(event) => handleChangeFilePDFAl(event, "pdf")}
                />
                {postData.media_al
                    && (
                        <>
                            <div className="contain-remove">
                                <img className="pdf" src={Images.PDFIcon} alt='pdf' />
                                <span onClick={() => deletePDFAl()}>X</span>
                            </div>

                        </>
                    )
                }
                {postData.media_al && (
                    <p>{postData.media_al.name}</p>
                )
                }
                </WrapperInput>
            </>
        );

    };

    const renderMain = () => {
        return (
            <BodyContainer>
                <PageTitle>{t("library.Add New Library")}</PageTitle>
                <LibraryContainer className="mb-2">
                    <WrapperInput>
                        <TitleInput>
                            {t("general.Title")}
                            {_renderSwitchLangugage()}
                        </TitleInput>
                        {language === CONSTANTS.LANGUAGE_CODE.EN && (<InputContainer defaultValue={postData.title.en} onChange={(event) => onChangePostData(event.target.value, 'title')} />)}
                        {language === CONSTANTS.LANGUAGE_CODE.AL && (<InputContainer defaultValue={postData.title.al} onChange={(event) => onChangePostData(event.target.value, 'title')} />)}
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("general.Description")}</TitleInput>
                        <div className={language === CONSTANTS.LANGUAGE_CODE.EN ? "" : "hidden-content"}>
                            <JoditEditor
                                ref={editor}
                                value={contentEn}
                            />
                        </div>
                        <div className={language === CONSTANTS.LANGUAGE_CODE.AL ? "" : "hidden-content"}>
                            <JoditEditor
                                ref={editorAl}
                                value={contentAl}
                            />
                        </div>
                    </WrapperInput>

                    <WrapperInput>
                        <TitleInput>{t("general.Youtube URL")}</TitleInput>
                        <InputContainer defaultValue={postData.link_youtube} height="200px" onChange={(event) => onChangePostData(event.target.value, 'link_youtube')} />
                    </WrapperInput>

                    <WrapperInput>
                        <TitleInput>{t("general.Type")}</TitleInput>
                        {_renderCheckBoxType()}
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("library.Incoming")}</TitleInput>
                        {_renderCheckBoxInComming()}
                    </WrapperInput>
                    <WrapperInput>
                        <TitleInput>{t("library.Cover Image")}</TitleInput>
                        <input
                            type="file"
                            accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                            onChange={(event) => handleChangeFile(event, "image")}
                        />
                        {imageUrl && <img src={imageUrl} alt="cover" />}
                    </WrapperInput>
                    {postData.type === CONSTANTS.LIBRARY_TYPE.VIDEO && _renderVideoUploadContainer()}
                    {!postData.is_incoming && postData.type === CONSTANTS.LIBRARY_TYPE.BOOK && _renderDetailContent()}
                    {!postData.is_incoming && postData.type === CONSTANTS.LIBRARY_TYPE.BOOK && _renderAddNewChapter()}
                    {postData.type === CONSTANTS.LIBRARY_TYPE.PDF && _renderUploadPDF()}
                </LibraryContainer>
                {_renderCreateButton()}
            </BodyContainer>
        );
    };

    return (
        <DefaultLayout
            userRole="limited-admin"
            isLoading={isLoading}
            page="library"
            content={renderMain()}
        />
    );
};

export default AddBook;
