import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressProvider from "./ProgressProvider";
import GradientSVG from "./GradientSVG";
import DropDownAction from "../DropDownAction";
import { ProgressBarContainer, Title, ViewMore } from "./ProgressBar.styles";
import { ColorName } from "../../Variables";

const ProgressBar = (props) => {
  const [valueEnd, setValueEnd] = useState(0);
  const [valueMain, setValueMain] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [className, setClassName] = useState("");
  const [listOptions, setListOptions] = useState({ // eslint-disable-line
    1: "view",
  });
  const [typePercent, setTypePercent] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);

  useEffect(() => {
    if (_.isNumber(props.value)) {
      setValueEnd((props.value * 100) / props.total);
    }
    setTitle(props.title);
    setDescription(props.description);
    setClassName(props.className);
    setValueMain(props.value);
    setTypePercent(props.typePercent);
    setShowViewMore(props.showViewMore);
  }, [props]);
  const getActionName = (action) => {
    props.callBackAction(action, props.filter);
  };

  return (
    <ProgressBarContainer className={className}>
      <GradientSVG
        startColor={ColorName.chathamsBlueDark}
        endColor={ColorName.fruitSaladDarker}
        idCSS="gradientProgressBar"
        rotation={80}
      />
      <ProgressProvider valueStart={0} valueEnd={valueEnd}>
        {(value) => (
          <CircularProgressbar
            value={value}
            text={!typePercent ? valueMain : `${valueMain}%`}
            strokeWidth={7}
            styles={{ text: { fontSize: "26px" } }}
          />
        )}
      </ProgressProvider>
      <Title>
        {title}
        <div className="description">{description}</div>
      </Title>
      {!showViewMore && (
        <ViewMore>
          <DropDownAction
            listOptions={listOptions}
            getActionName={getActionName}
            direction="progressBar"
          />
        </ViewMore>
      )}
    </ProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  callBackAction: PropTypes.func,
  filter: PropTypes.string,
  total: PropTypes.number,
  description: PropTypes.string,
  typePercent: PropTypes.bool,
  showViewMore: PropTypes.bool,
};

export default ProgressBar;
