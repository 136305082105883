const types = {
    SUPPER_ADMIN: 1,
    LIMITED_ADMIN: 2,
    BUSINESS: 3,
    JUDGE: 4,
    INVESTOR: 5,
    UNASSIGNED: 6,
    CONSULTANT: 7
};

export default types;
