import styled from 'styled-components';
import { ColorName } from "../../Variables";

const GeneralCheckBoxFormContainer = styled.div`
    position: relative;
    input[type=checkbox] {
        visibility: hidden;
    }
    label {
        cursor: pointer;
        position: absolute;
        top: 0;
        -webkit-appearance: none;
        background-color:white;
        color: black;
        white-space: nowrap;
        width: 20px;
        height: 20px;
        border: 1px solid ${ColorName.chathamsBlueDark};
        margin-right: 1em;
        &:after {
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            filter: alpha(opacity=0);
            opacity: 0;
            content: '';
            position: absolute;
            width: 12px;
            height: 6px;
            background: transparent;
            top: 4px;
            left: 2px;
            border: 3px solid #000;
            border-top: none;
            border-right: none;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        &:hover::after {
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
            filter: alpha(opacity=30);
            opacity: 0.3;
        }
        &.checked {
            &:after {
                opacity: 1;
            }
        }
    }
    
`;

export {
    GeneralCheckBoxFormContainer
};
