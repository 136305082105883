import _ from "lodash";
import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";

const requestHasError = () => {
    return {
        type: ActionTypes.GUEST_SUMMARY_SETTING_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.GUEST_SUMMARY_SETTING_REQUEST
    };
};

const getSummarySettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GUEST_SUMMARY_SETTING_SUCCESS,
        payload
    };
};

const updateSummarySettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GUEST_SUMMARY_SETTING_SUCCESS,
        payload
    };
};

const getSummarySettingData = () => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getMetaData({ type: 'guest_summary' })
            .then((res) => {
                if (res.data.status === true) {
                    const metaData = _.get(res, 'data.data.data_json', null);
                    dispatch(getSummarySettingDataSuccess(metaData));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const createSummarySettingData = (payload) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.addMetaData(payload)
            .then((res) => {
                if (res.data.status === true) {
                    const metaData = _.get(res, 'data.data.data_json', null);
                    dispatch(updateSummarySettingDataSuccess(metaData));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateSummarySettingData = (formPayload) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        formPayload.append("type", "guest_summary");
        API.updateMetaData(formPayload)
            .then((res) => {
                if (res.data.status === true) {
                    const metaData = _.get(res, 'data.data.data_json', null);
                    dispatch(updateSummarySettingDataSuccess(metaData));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    getSummarySettingData,
    updateSummarySettingData,
    createSummarySettingData
};
