import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import _ from "lodash";
import Header from '../Header';
import Footer from '../Footer';
import { ContainerBody } from "./DefaultLayout.styles";
import Loading from "../../CommonLayoutPart/Loading";

const DefaultLayout = (props) => {
    useEffect(() => {
        if (!props.currentTop || _.isUndefined(props.currentTop)) return window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const {
        context,
        currentPage,
        isLoading
    } = props;

    return (
        <div>
            {isLoading && <Loading />}
            <Header currentPage={currentPage} />
            <ContainerBody>
                {context}
            </ContainerBody>
            <Footer />
        </div>
    );
};

DefaultLayout.propTypes = {
    context: PropTypes.element.isRequired,
    currentPage: PropTypes.string,
    currentTop: PropTypes.bool,
    isLoading: PropTypes.bool
};

export default DefaultLayout;
