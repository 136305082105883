import styled from 'styled-components';
import { ColorName } from '../../Variables';

const Flex = styled.div({
    flex: (props) => props.flex || 1
});

const HorizontalContainer = styled.div({
    display: 'flex',
    flexDirection: 'row'
});

const VerticalContainer = styled.div({
    display: 'flex',
    flexDirection: 'column'
});

const BreadCrumb = styled.div`
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    color: ${ColorName.chathamsBlueDark};
    margin-bottom: 1.5em;
    text-transform: uppercase;
`;

const RoundedRowContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction || 'row'};
    border: 2px solid ${ColorName.gallery};
    border-radius: ${(props) => props.radius || '25px'};
    padding: ${(props) => props.padding || '1em'};
    background: ${ColorName.white};
    margin-bottom: ${(props) => props.marginBottom || '2em'};
    
    &.scrollbar {
        max-height: 700px;
        overflow: auto;
    }
`;

const PageTitle = styled(BreadCrumb)`
    font-weight: 300;
    font-size: 3em;
    margin-top: 0.5em;
    color: ${ColorName.chathamsBlue};
`;

const WrapperHomepageFrontEnd = styled.div`
    max-width: 1140px;
    margin: 0 auto;
    letter-spacing: 0.5px;
`;

const ContainerFullWidth = styled.div`
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
`;

const Content = styled.div`
    background: ${(props) => props.backGround || "white"}
`;

const Title = styled.div`
    font-size: 36px;
    font-weight: 700;
    text-align: center;
`;

const Description = styled.div`
    margin-top: 20px;
    max-width: 600px;
    text-align: center;
    opacity: .7;
`;

/**
 * Grid Column
 * Override Default Grid Template is 5 Columns
 * Example: <GridCol colSize='3' gridTemplate='6'>
 * If not set Override Grid Column will have default value depend on FlexGridTemplate
 */
const GridCol = styled.div`
    width: ${(props) => (props.colSize ? `${(100 / (props.gridTemplate || 5)) * props.colSize}% !important` : '0')};
    flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
    &.flex-start {
        display: flex;
        justtify-content: flex-start;
    }
    &.flex-center {
        display: flex;
        justify-content: center;
    }

    &.flex-end {
        display: flex;
        justify-content: flex-end;
    }
    &.align-end {
        align-items: flex-end;
    }
    @media (max-width: 768px) {
        width: 100% !important;
    }
    &.custom-width {
        min-width: 155px;
        @media (max-width: 1024px) {
            min-width: 155px;
        }
        @media (max-width: 768px) {
            width: 155px !important;
        }
    }
`;

/**
 * Grid Template for 5 columns
 */
const FlexGridFifth = styled.div`
    display: flex;
    ${GridCol} {
        width: 20%;
    }
    padding: ${(props) => props.padding || '1em'};
    
    &.flex-start {
        display: flex;
        justify-content: flex-start;
    }

    &.flex-center {
        justify-content: center;
        align-items: center;
    }

    &.text-align-left {
        text-align: left;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        place-content: center;
        padding: 3em 0em;
    }
`;

/**
 * Grid Template for 6 columns
 */
const FlexGridSixth = styled.div`
    display: flex;
    ${GridCol} {
        width: 16.7%;
    }
    padding: ${(props) => props.padding || '0em'};

    &.text-align-left {
        text-align: left;
    }
    &.custom-criteria {
        margin-bottom: 5em;
    }
    &.base-line {
        align-items: baseline;
    }
`;

const ContentBodyContainer = styled.div`
    max-width: 1140px;
    margin: auto;
    &.w-100 {
        width: 100%;
        background: white;
    }
    &.d-flex {
        display: flex;
    }
`;

const PrimaryButton = styled.button`
    background: #FD749B;
    border-radius: 3px;
    border: none;
    box-shadow: 0 5px 10px rgba(14,37,58,0.15);
    color: #FFFFFF;
    display: block;
    height: 56px;
    line-height: 56px;
    padding: 0;
    width: 300px;
    transition: all .2s ease-out;
    font-weight: 400;
    letter-spacing: .3px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    touch-action: manipulation;
    margin-bottom: 0;
    white-space: nowrap;
    font-size: 16px;
    user-select: none;

    .align-center {
        margin: auto !important;
    }
`;

const GeneralButton = styled.input`
    border: none;
    color: ${(props) => props.color || "white"};
    font-size: 1em;
    padding: ${(props) => props.padding || "0.5em 1em"};
    outline: none;
    background: ${(props) => props.background || ColorName.rhino};
    cursor: pointer;
    text-align: center;
    margin: ${(props) => props.margin || 'unset'};
    min-width: 5em;
`;

const GeneralInputForm = styled.input`
    border: 1px solid ${ColorName.chathamsBlueDark};
    outline: none;
    padding: 5px;
    width: ${(props) => props.width || '100%'};
    margin: ${(props) => props.margin || 'unset'};
    &::placeholder {
        color: ${ColorName.glacier}
    }
    @media (max-width: 768px) {
        width: 97.5% !important;
    }
    @media (max-width: 420px) {
        width: 96.5% !important;
    }
`;

const GeneralTextAreaFrom = styled.textarea`
    border: 1px solid ${ColorName.chathamsBlueDark};
    outline: none;
    padding: 5px;
    width: ${(props) => props.width || '100%'};
    margin: ${(props) => props.margin || 'unset'};
    height: ${(props) => props.height || 'auto'};
    resize: none;
    &::placeholder {
        color: ${ColorName.glacier}
    }
    @media (max-width: 768px) {
        width: 97.5% !important;
    }
    @media (max-width: 420px) {
        width: 96.5% !important;
    }
`;

const LabelSlide = styled.label`
    &.switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }
    & span {
        cursor: pointer;
    }
    & input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    & .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        &.round {
            border-radius: 34px;
        }
        &.round:before {
            border-radius: 50%;
        }
    }
      
    & .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    & input:checked + .slider {
        background-color: ${ColorName.chathamsBlueDark};
    }
      
    & input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
`;

export {
    Flex,
    HorizontalContainer,
    VerticalContainer,
    BreadCrumb,
    RoundedRowContainer,
    PageTitle,
    WrapperHomepageFrontEnd,
    FlexGridFifth,
    FlexGridSixth,
    GridCol,
    Content,
    Title,
    Description,
    PrimaryButton,
    ContentBodyContainer,
    ContainerFullWidth,
    GeneralButton,
    GeneralInputForm,
    GeneralTextAreaFrom,
    LabelSlide
};
