import Ws from '@adonisjs/websocket-client';

export class SocketConnection {
    connect() {
        this.ws = Ws(process.env.REACT_APP_WS_URL)
            .connect();

        this.ws.on('open', () => {
            console.log('Connection initialized');
        });

        this.ws.on('close', () => {
            console.log('Connection closed');
        });

        return this;
    }

    close() {
        this.ws.clearListeners();
        this.ws = Ws(process.env.REACT_APP_WS_URL)
            .connect();
        this.ws.close();
    }

    subscribe(channel, handler) {
        if (!this.ws) {
            setTimeout(() => this.subscribe(channel), 1000);
        } else {
            const result = this.ws.subscribe(channel);
            result.on('message', (message) => {
                handler(message);
            });

            result.on('error', (error) => {
                console.error(error);
            });

            return result;
        }
    }
}

export default new SocketConnection();
