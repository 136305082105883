import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import {
    Container,
    ContentSumary,
    GridColTemplate,
    FlexGridSixthTemplate
} from "./SummaryOverview.styles";
import SummarySettingActions from "../../../actions/GuestSummarySetting";

const SummaryOverview = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const summarySetting = useSelector((state) => state.GuestSummarySetting.summaryData);

    const [informationOne, setInformationOne] = useState(summarySetting.informationOne);
    const [informationTwo, setInformationTwo] = useState(summarySetting.informationTwo);
    const [informationThree, setInformationThree] = useState(summarySetting.informationThree);
    const [informationFour, setInformationFour] = useState(summarySetting.informationFour);
    const [informationFive, setInformationFive] = useState(summarySetting.informationFive);

    useEffect(() => {
        dispatch(SummarySettingActions.getSummarySettingData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInformationOne(summarySetting.informationOne);
        setInformationTwo(summarySetting.informationTwo);
        setInformationThree(summarySetting.informationThree);
        setInformationFour(summarySetting.informationFour);
        setInformationFive(summarySetting.informationFive);
    }, [summarySetting]);

    return (
        <Container>
            <ContentSumary>
                <FlexGridSixthTemplate>
                    <GridColTemplate>
                        <div className="container-item">
                            <div className="number">{_.get(informationOne, 'value', 0)}</div>
                            <div className="description">{_.get(informationOne, i18n.language === "en" ? "name" : "nameAl", 'Not Set')}</div>
                        </div>
                    </GridColTemplate>
                    <GridColTemplate className="flex-center">
                        <div className="split-container">
                            <div className="diamon" />
                        </div>
                    </GridColTemplate>
                    <GridColTemplate>
                        <div className="container-item">
                            <div className="number">{_.get(informationTwo, 'value', 0)}</div>
                            <div className="description">{_.get(informationTwo, i18n.language === "en" ? "name" : "nameAl", 'Not Set')}</div>
                        </div>
                    </GridColTemplate>
                    <GridColTemplate className="flex-center">
                        <div className="split-container">
                            <div className="diamon" />
                        </div>
                    </GridColTemplate>
                    <GridColTemplate>
                        <div className="container-item">
                            <div className="number">{_.get(informationThree, 'value', 0)}</div>
                            <div className="description">{_.get(informationThree, i18n.language === "en" ? "name" : "nameAl", 'Not Set')}</div>
                        </div>
                    </GridColTemplate>
                    <GridColTemplate className="flex-center">
                        <div className="split-container">
                            <div className="diamon" />
                        </div>
                    </GridColTemplate>
                    <GridColTemplate>
                        <div className="container-item">
                            <div className="number">{_.get(informationFour, 'value', 0)}</div>
                            <div className="description">{_.get(informationFour, i18n.language === "en" ? "name" : "nameAl", 'Not Set')}</div>
                        </div>
                    </GridColTemplate>
                    <GridColTemplate className="flex-center">
                        <div className="split-container">
                            <div className="diamon" />
                        </div>
                    </GridColTemplate>
                    <GridColTemplate>
                        <div className="container-item">
                            <div className="number">{_.get(informationFive, 'value', 0)}</div>
                            <div className="description">{_.get(informationFive, i18n.language === "en" ? "name" : "nameAl", 'Not Set')}</div>
                        </div>
                    </GridColTemplate>
                </FlexGridSixthTemplate>
            </ContentSumary>
        </Container>
    );
};

export default SummaryOverview;
