import Api from "./Base";

export function reportUsers() {
    return Api.get("/admin_report");
}

export function getUserDetailsByID(id) {
    return Api.get(`/users/${id}`);
}

export function getRoles() {
    return Api.get("/roles");
}

export function updateRoleForUser(data, userId) {
    return Api.postFormData(`/users/${userId}/update`, data);
}

export function getActivitiesOverview(data) {
    return Api.get('/notifications', data);
}

// export function getAllActivitiesForDropdown(data) {
//     return Api.get('/new_notifications', data);
// }

export function getAllActivitiesForDropdown(data) {
    return Api.get('/notifications', data);
}

export function getAllActivitiesOverview(data) {
    return Api.get('/notifications', data);
}

export function getEvaluationApply(data) {
    return Api.get('/evaluation_apply', data);
}

export function addLimitedUser(data) {
    return Api.post('/limited_users', data);
}
