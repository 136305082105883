import React from "react";
import { useTranslation } from "react-i18next";
import {
    Container,
    GridColHomepage,
    Title,
    FlexGridSixthTemplate,
    Button
} from "./Publications.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import SummryOverview from "../../../components/Common/SummaryOverview";
import { ColorName } from "../../../components/Variables/index.js";
import history from "../../../history";
const Publications = () => {
    const { t } = useTranslation();
    const _renderContent = () => {
        return (
            <div className="content-page">
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <Title>
                            Publications
                        </Title>
                        <div className="description">
                            Dua Partner Invest is an unique opportunity to enable all type of investors such as businesses, venture capital firms,
                            impact investment schemes, development programs and individuals around the world to invest in regional startups they
                            see potential. We enable all types of startups in the Western Balkan region to raise funds and access different kinds of support from a large community of investors.
                            {' '}
                        </div>
                        <div className="description">Dua Partner Invest is an initiative of Partners Albania for Change and Development. </div>
                        <div className="description">We believe in the potential of our regional entrepreneurs and the power of collaboration with investors and experts enthusiasts Publications startup community. </div>
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate className="container-button">
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <Button
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => history.push("/homepage/register")}
                        />
                        <Button
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.chathamsBlueDark}
                            background={ColorName.white}
                            onClick={() => history.push("/homepage/register")}
                        />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
            </div>
        );
    };

    const _renderSummaryOverview = () => {
        return <SummryOverview />;
    };

    const _renderMain = () => {
        return (
            <Container>
                {_renderContent()}
                {_renderSummaryOverview()}
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
            currentPage="publications"
        />
    );
};

Publications.propTypes = {
};

export default Publications;
