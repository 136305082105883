import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";
import Constants from "../constants/Constants";
import i18n from "../i18n";

const requestHasError = () => {
    return {
        type: ActionTypes.PAGE_LIBRARY_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.PAGE_LIBRARY_REQUEST
    };
};

const requestIsSuccess = () => {
    return {
        type: ActionTypes.PAGE_LIBRARY_SUCCESS
    };
};

const setDetailData = (payload) => {
    return {
        type: ActionTypes.PAGE_LIBRARY_SET_DETAIL_DATA,
        payload
    };
};

const setAllData = (payload) => {
    return {
        type: ActionTypes.PAGE_LIBRARY_SET_ALL_DATA,
        payload
    };
};

const fetchLibraryData = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.fetchAllLibrary(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setAllData(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getLibraryDetailData = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getLibraryDataByID(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setDetailData(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const createNewBook = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.createNewBook(data)
            .then((res) => {
                if (res.data.status === true) {
                    const userData = Utils.getSavedUserData();
                    dispatch(requestIsSuccess());
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" }).then((r) => {
                        if (r) {
                            if (userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN) {
                                history.push("/limited-admin/library");
                            } else {
                                history.push("/admin/library");
                            }
                        }
                    });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const deleteBook = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.deleteBook(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestIsSuccess());
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" }).then((r) => {
                        const param = {
                            page: 1,
                            item_per_page: Constants.ITEM_PER_PAGE,
                            language: i18n.language
                        };
                        if (r) dispatch(fetchLibraryData(param));
                    });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updateBook = (id, data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateBook(id, data)
            .then((res) => {
                if (res.data.status === true) {
                    const userData = Utils.getSavedUserData();
                    dispatch(requestIsSuccess());
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" }).then((r) => {
                        if (r) {
                            if (userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN) {
                                history.push("/limited-admin/library");
                            } else {
                                history.push("/admin/library");
                            }
                        }
                    });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const searchLibrary = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.searchLibrary(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setAllData(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    fetchLibraryData,
    getLibraryDetailData,
    createNewBook,
    deleteBook,
    updateBook,
    searchLibrary
};
