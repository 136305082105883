import ActionTypes from "../constants/ActionTypes";

const initialState = {
  isSuccess: false,
  hasError: false,
  isLoading: false,
  news: [],
  dataNews: {},
  newsID: 0,
  newSlide: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.ADD_NEWS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        hasError: false,
        isLoading: false,
        dataNews: payload,
      };
    case ActionTypes.NEWS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.NEWS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        hasError: false,
        isLoading: false,
        dataNews: payload,
      };
    case ActionTypes.GET_ALL_NEWS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        hasError: false,
        isLoading: false,
        news: payload,
      };
    case ActionTypes.GET_NEWS_FOR_SLIDE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        hasError: false,
        isLoading: false,
        newSlide: payload,
      };
    case ActionTypes.SET_NEWS_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        hasError: false,
        isLoading: false,
        newsID: payload,
        dataNews: {},
      };
    case ActionTypes.UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        hasError: false,
        isLoading: false,
        dataNews: payload,
      };
    case ActionTypes.RESET_NEWS_SUCCESS:
      return {
        ...state,
        dataNews: {},
      };
    default:
      return state;
  }
};
