import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  Report,
  BlockReport,
  Title
} from "./FundingReport.styles";
import FundingDetailApplications from "../../../../components/Common/UserFundingDetailApplications";
import FundingDetailReport from '../../../../components/Common/UserFundingDetailReport';
import FundingApplicationUserActions from "../../../../actions/FundingApplicationsUser";
import Constants from "../../../../constants/Constants";
import { RoundedRowContainer } from "../../../../components/Common/CommonLayout";
import history from "../../../../history";
import ProgressBar from "../../../../components/Common/ProgressBar";
import Utils from "../../../../libs/Utils";

const FundingReports = () => {
  const { t } = useTranslation();
  const userData = Utils.getSavedUserData();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const FundingStorage = useSelector((state) => state.FundingApplicationsUser);
  const [dataApplyApplicationItem, setDataApplyApplicationItem] = useState();
  const [dataFundingUserReport, setDataFundingUserReport] = useState();
  const [dataReportStatus, setDataReportStatus] = useState();

  useEffect(() => {
    dispatch(FundingApplicationUserActions.getApplyApplicationByIDRequest(location.state.applicationID));
    dispatch(FundingApplicationUserActions.getReportStatusRequest(location.state.applyApplicationID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(FundingStorage.isLoading);
    setDataApplyApplicationItem(FundingStorage.dataApplyApplicationItem);
    setDataFundingUserReport(FundingStorage.dataFundingUserReport);
    setDataReportStatus(FundingStorage.dataReportStatus);
  }, [FundingStorage]);

  const renderTableContent = () => {
    if (_.isEmpty(dataReportStatus)) {
      return (
        <RoundedRowContainer>
          {t("general.Data Not Found")}
        </RoundedRowContainer>
      );
    }
    return _.map(dataReportStatus, (row) => {
      const classNameStatus = _.findKey(Constants.REPORT_STATUS, _.partial(_.isEqual, row.status)) === "DUE" ? "color-status-red" : "color-status-green";
      return (
        <FundingDetailReport
          key={row.id}
          description={row.request_description}
          status={_.findKey(Constants.REPORT_STATUS, _.partial(_.isEqual, row.status))}
          dueDate={moment(row.due_date.deadline).format("YYYY-MM-DD")}
          submitData={row.submitted_data}
          dataUrl={row.data_url}
          classNameStatus={classNameStatus}
          idItemReportStatus={row.id}
          idApplicationItem={dataApplyApplicationItem.id}
        />
      );
    });
  };

  const scrollVertical = async (title) => {
    if (userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
      await history.push("user-funding");
      window.location.href = `${title}`;
    } else {
      history.push("user-overview");
    }
  };

  return (
    <DefaultLayout
      userRole='user'
      isLoading={isLoading}
      page="userFundingDetail"
      content={(
        <BodyContainer>
          {!_.isEmpty(dataFundingUserReport) && (
            <Report>
              <BlockReport>
                <ProgressBar
                  value={dataFundingUserReport.my_application_amount || "0"}
                  title={t("general.My Funding Applications")}
                  callBackAction={() => scrollVertical("#my-applications")}
                  filter="user overview"
                  total={100}
                />
              </BlockReport>
              {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER && (
                <BlockReport>
                  <ProgressBar
                    value={dataFundingUserReport.funding_application_amount || "0"}
                    title={t("fundingApplications.Available Funding Opportunities")}
                    callBackAction={() => scrollVertical("#apply-applications")}
                    filter="user overview"
                    total={100}
                  />
                </BlockReport>
              )}
              <BlockReport />
            </Report>
          )}

          <Title>
            {!_.isEmpty(dataApplyApplicationItem) && dataApplyApplicationItem.fundingApplication.title}
          </Title>
          <FundingDetailApplications />
          <Title className="mt-title mb-title">
            {t("general.Report Status")}
          </Title>
          {renderTableContent()}
        </BodyContainer>
      )}
    />
  );
};

export default FundingReports;
