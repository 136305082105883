import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import {
    Content,
    Title,
    Fields,
    Field,
    Footer,
    InputContainerNewLayout
} from "./Authentication.styles";
import ResetPasswordActions from "../../../actions/ResetPassword";
import Button from "../../../components/Common/ButtonFrontEnd";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import history from "../../../history";
import UserPageTemplateV1 from "../../../components/Layout/GuestTemplate/DefaultLayout/NoHeader";
import Constants from "../../../constants/Constants";
import { ColorName } from "../../../components/Variables";
import Validation from "../../../libs/Validation";
import Utils from "../../../libs/Utils";
const ChangePassword = (props) => {
    const { t, i18n } = useTranslation();
    const isLoading = useSelector((state) => state.User.isLoading);
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
		const [showPassword, setShowPassword] = useState(false);
		const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        const params = queryString.parse(props.location.search);
        setEmail(params.e);
        setCode(params.sc);
        Utils.setCurrentLanguage(params.lang);
        i18n.changeLanguage(params.lang);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const switchScreen = (screen) => {
        history.push(screen);
    };

    const inputPassword = (e) => {
        setPassword(e.target.value);
    };

    const confirmPassword = (e) => {
        setConfirmPass(e.target.value);
    };

    const validateForm = () => {
        const validationRules = [
            {
                name: t("general.Password"),
                value: password,
                rule: ["required", { min: 6 }]
            },
            {
                name: t("general.Confirm Password"),
                value: confirmPass,
                rule: ["required", { min: 6 }]
            },
            {
                name: t("general.Confirm Password"),
                value: confirmPass,
                rule: "equal",
                ruleValue: password
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const changePassword = () => {
        let flag = true;
        flag = validateForm();
        if (flag) {
            const data = {
                email,
                secret_code: code,
                password,
                password_confirmation: confirmPass
            };
            dispatch(ResetPasswordActions.changePasswordLimited(data));
        }
    };

    const onKeyEnterPress = (e) => {
        if (e.which === 13) {
            changePassword();
        }
    };
	
		const showPasswordHandle = () => {
			setShowPassword(!showPassword)
		};
		
		const showConfirmPasswordHandle = () => {
			setShowConfirmPassword(!showConfirmPassword)
		}

    return (
        <UserPageTemplateV1 context={(
            <Content>
                {isLoading && <Loading />}
                <Title>{t("general.Change Password")}</Title>
                <Fields>
                    <Field>
                        <InputContainerNewLayout className='align-center'>
                            <input type={showPassword ? 'text' : 'password'} placeholder={t("general.New Password")} onChange={(e) => inputPassword(e)} onKeyPress={onKeyEnterPress} />
	                          <i className={`far ${showPassword ? 'fa-eye' : 'fa-eye-slash'} icon-eye`} onClick={() => showPasswordHandle()} />
                        </InputContainerNewLayout>
                    </Field>
                    <Field>
                        <InputContainerNewLayout className='align-center'>
                            <input type={showConfirmPassword ? 'text' : 'password'} placeholder={t("general.Confirm New Password")} onChange={(e) => confirmPassword(e)} onKeyPress={onKeyEnterPress} />
	                          <i className={`far ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'} icon-eye`} onClick={() => showConfirmPasswordHandle()} />
                        </InputContainerNewLayout>
                    </Field>
                </Fields>
                <Fields>
                    <div className="line" />
                    <Field className="alignCenter">
                        <Button
                            background='gradient'
                            name={t("general.Submit")}
                            height="40px"
                            width="6em"
                            onClick={() => changePassword()}
                        />
                    </Field>
                    <div className="line mt-2" />
                </Fields>

                <Footer>
                    <Button
                        onClick={() => switchScreen("/homepage/add-limited-user")}
                        name={t("general.Sign In")}
                        width={i18n.language === Constants.LANGUAGE_CODE.EN ? "7em" : "10em"}
                        background={ColorName.bahamaBlue}
                        color={ColorName.oysterBay}
                        border="0"
                    />
                </Footer>
            </Content>
        )}
        />
    );
};

export default ChangePassword;
