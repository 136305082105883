import React from 'react';

const Button = ({
    children, color, disabled, ...otherProps
}) => {
    return (
        <button
            {...otherProps}
        >
            {children}
        </button>
    );
};

Button.defaultProps = {
    color: 'indigo'
};

export default Button;
