import styled from 'styled-components';
import { ColorName } from '../../../Variables';

const Container = styled.div`
    width: 100%;
    background-color: ${ColorName.bahamaBlue};
    & .footer-logo-image{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & .copyright{
            margin: 2em 1em;
            color: ${ColorName.oysterBay};
            display: flex;
            align-items: flex-end;
            white-space: nowrap;
            a{
                color: orange;
                margin-left: 5px;
                text-decoration: none;
            }
        }  
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 1em;
`;
const TitleFooter = styled.div`
    font-size: 1em;
    font-weight: 600;
    color: ${ColorName.oysterBay};
    text-transform: uppercase;
`;
const LinkFooter = styled.div`
    display: flex;
    flex-direction: column;
    & .fab {
        margin-right: 10px;
        font-size: 18px;
        color: white;
        cursor: pointer;
    }
`;
const ItemLink = styled.a`
    list-style: none;
    cursor: pointer;
    font-size: 0.8em;
    line-height: 2.5;
    min-height: 31px;
    text-decoration: none;
    color: ${ColorName.oysterBay};
    &.bold {
        font-weight: 600;
        font-size: 1em;
    }
`;
const WrapperLink = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
const Button = styled.a`
    border: none;
    color: ${(props) => props.color || "white"};
    font-size: 1em;
    padding: ${(props) => props.padding || "0.5em 1em"};
    outline: none;
    background: ${(props) => props.background || ColorName.rhino};
    cursor: pointer;
    text-align: center;
    margin: ${(props) => props.margin || 'unset'};
    width: ${(props) => props.width || "3.5em"};
    font-weight: ${(props) => props.fontWeight || "normal"};
    margin-top: 0.5em;
    text-decoration: none;
    text-transform: uppercase;
`;
const ImagesLogo = styled.img`
    width: 220px;
    margin-bottom: 2em;
    padding: 0 1em;
    cursor: pointer;
`;
export {
    Container,
    Wrapper,
    TitleFooter,
    LinkFooter,
    ItemLink,
    WrapperLink,
    Button,
    ImagesLogo
};
