import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { useSelector } from "react-redux";
import {
    FundingDetailApplicationsComponent
} from "./UserFundingDetailApplications.styles";
import Constants from "../../../constants/Constants";

const UserFundingDetailApplications = () => {
    const { t } = useTranslation();
    const FundingStorage = useSelector((state) => state.FundingApplicationsUser);
    const [dataApplyApplicationItem, setDataApplyApplicationItem] = useState();

    useEffect(() => {
        setDataApplyApplicationItem(FundingStorage.dataApplyApplicationItem);
    }, [FundingStorage]);

    return (
        <FundingDetailApplicationsComponent>
            <div className='d-flex'>
                <div className="w-title">
                    {t("general.Status")}
:
                </div>
                <span className="color-green">
                    {!_.isEmpty(dataApplyApplicationItem)
                    && t(`rounderTable.${_.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, dataApplyApplicationItem.status)).replace("_", " ")}`)}
                </span>
            </div>
        </FundingDetailApplicationsComponent>
    );
};

export default UserFundingDetailApplications;
