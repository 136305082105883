import _ from "lodash";
import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";
import history from "../history";
import Constants from "../constants/Constants";

const adminOverViewHasError = () => {
    return {
        type: ActionTypes.ADMIN_OVER_VIEW_FAILURE
    };
};

const adminOverViewIsLoading = () => {
    return {
        type: ActionTypes.ADMIN_OVER_VIEW_IS_LOADING
    };
};

const adminOverViewSuccess = (payload = false) => {
    return {
        type: ActionTypes.ADMIN_OVER_VIEW_SUCCESS,
        payload
    };
};

const getUserIsSuccess = (payload = false) => {
    return {
        type: ActionTypes.GET_USER_DETAILS_BY_ID,
        payload
    };
};

const getListUsersSuccess = (payload = false) => {
    return {
        type: ActionTypes.GET_ALL_LIST_USERS_SUCCESS,
        payload
    };
};

const usersWithFilter = (filter = "") => {
    return {
        type: ActionTypes.SET_FILTER_USERS_SUCCESS,
        filter
    };
};

const reportIsSuccess = (payload) => {
    return {
        type: ActionTypes.REPORT_ADMIN_SUCCESS,
        payload
    };
};

const getListFundingApplicationIsSuccess = (payload) => {
    return {
        type: ActionTypes.GET_LIST_FUNDING_APPLICATION,
        payload
    };
};

const getRoleIsSuccess = (payload) => {
    return {
        type: ActionTypes.GET_ROLES,
        payload
    };
};

const resetFilter = () => {
    return {
        type: ActionTypes.RESET_FILTER
    };
};

const getActivitiesIsSuccess = (payload) => {
    return {
        type: ActionTypes.GET_ACTIVITIES_SUCCESS,
        payload
    };
};

const setUserId = (payload) => {
    return {
        type: ActionTypes.SET_USER_ID,
        payload
    };
};

const setApplyApplicationId = (payload) => {
    return {
        type: ActionTypes.ADMIN_OVERVIEW_SET_APPLY_ID,
        payload
    };
};

const getEvaluationQuestionnaireSuccess = (payload) => {
    return {
        type: ActionTypes.ADMIN_GET_EVALUATION_QUESTIONNAIRE_SUCCESS,
        payload
    };
};

const getEvaluationApplyeSuccess = (payload) => {
    return {
        type: ActionTypes.ADMIN_GET_EVALUATION_APPLY_SUCCESS,
        payload
    };
};

const getAllActivitiesIsSuccess = (payload) => {
    return {
        type: ActionTypes.GET_ALL_ACTIVITIES_SUCCESS,
        payload
    };
};

const getListUsers = (data = null) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getListUsers(data)
            .then((res) => {
                if (res.data) {
                    dispatch(getListUsersSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const getListFundingApplication = () => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getListFundingApplication()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getListFundingApplicationIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const getListFundingApplicationAdmin = () => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getListFundingApplicationAdmin()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getListFundingApplicationIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const reportUsers = () => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.reportUsers()
            .then(async (res) => {
                if (res.data.status === true) {
                   await dispatch(reportIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const getUserDetailsByID = (id) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getUserDetailsByID(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getUserIsSuccess(Utils.convertSnakeKeyToCamel(res.data.data)));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const getRoles = () => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getRoles()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getRoleIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const updateRoleForUser = (data, userId) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.updateRoleForUser(data, userId)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getUserIsSuccess(Utils.convertSnakeKeyToCamel(res.data.data)));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const addNewUser = (data) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.addNewUser(data)
            .then((res) => {
                if (res.data.status === true) {
                    const userData = Utils.getSavedUserData();
                    dispatch(getUserIsSuccess(Utils.convertSnakeKeyToCamel(res.data.data)));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    if(userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN) {
                        history.push("/limited-admin/overview");
                    }else {
                        history.push("/admin/overview");
                    }
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const getActivitiesOverview = (data) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getActivitiesOverview(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getActivitiesIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};


const getAllActivitiesOverview = (data) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getAllActivitiesForDropdown(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getAllActivitiesIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const updateAdminInforRequest = (id, data) => {
    return async (dispatch) => {
        dispatch(adminOverViewIsLoading());
        await API.updateUserOverview(id, data)
            .then(async (res) => {
                if (res.data.status === true) {
                    const oldData = Utils.getSavedUserData();
                    const newData = _.merge(oldData, Utils.convertSnakeKeyToCamel(res.data.data));
                    await Utils.saveUserData(newData);
                    Utils.popupAlert({ text: res.data.message, type: "success" })
                        .then((isSuccess) => {
                            if (isSuccess) {
                                dispatch(adminOverViewSuccess());
                            }
                        });
                } else {
                    dispatch(adminOverViewHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(adminOverViewHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getEvaluationQuestionnaire = () => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getEvaluationQuestionnaire()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getEvaluationQuestionnaireSuccess(res.data.data));
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const getEvaluationApply = (data) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getEvaluationApply(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getEvaluationApplyeSuccess(res.data.data));
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const addLimitedUser = (data) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.addLimitedUser(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(adminOverViewSuccess());
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const getListFundingApplicationLimited = (data) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getListFundingApplicationLimited(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getListFundingApplicationIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const getListUsersAdmin = (data = null) => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getListUsersAdmin(data)
            .then((res) => {
                if (res.data) {
                    dispatch(adminOverViewSuccess());
                    dispatch(getListUsersSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

const getListFundingApplicationAdminWithLoading = () => {
    return (dispatch) => {
        dispatch(adminOverViewIsLoading());
        API.getListFundingApplicationAdmin()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(adminOverViewSuccess());
                    dispatch(getListFundingApplicationIsSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                    dispatch(adminOverViewHasError());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(adminOverViewHasError());
            });
    };
};

export default {
    getListUsers,
    getListFundingApplication,
    usersWithFilter,
    reportUsers,
    getUserDetailsByID,
    getRoles,
    updateRoleForUser,
    getActivitiesOverview,
    updateAdminInforRequest,
    resetFilter,
    setUserId,
    setApplyApplicationId,
    getEvaluationQuestionnaire,
    getEvaluationApply,
    addNewUser,
    addLimitedUser,
    getListFundingApplicationLimited,
    getAllActivitiesOverview,
    getListFundingApplicationAdmin,
    getListUsersAdmin,
    getListFundingApplicationAdminWithLoading
};
