import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer } from '../CommonLayout';
import { ColorName } from "../../Variables";

const FundingDetailReportComponent = styled(VerticalContainer)`
    & .report {
        margin-bottom: 3em;
    }
`;

const Title = styled.div`
    display: flex;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 1em;
    padding-left: 18px;
    padding-right: 18px;
    
    & .d-flex {
        display: flex;
    }
    & .description {
        width: 40%;
        color: ${ColorName.chathamsBlueDark};
        & .text-description {
            padding-left: 20px;
            padding-right: 1em;
        }
    }
    & .status {
        width: 30%;
        color: ${ColorName.chathamsBlueDark};
    }
    & .due-date {
        width: 20%;
        color: ${ColorName.chathamsBlueDark};
    }
    & .button {
        width: 10%
    }
`;

const TableContainer = styled.table`
    border-collapse: separate;
    border-spacing: 0 1em;
    & .text-description {
        font-weight: normal;
        color: black;
    }
    thead {
        tr {
            text-align: left;
            font-size: 16px;
            td:first-child {
                font-weight: bold;
                padding-right: 20px;
                display: table-cell;
            }
            td:nth-child(2) {
                font-weight: 600;
            }
            td:nth-child(3) {
                font-weight: 600;
                color: #858585;
            }
            td {
                vertical-align: top;
            }
            & .color-status-red {
                color: ${ColorName.redOrange}
            }
            & .color-status-green {
                color: ${ColorName.harlequin}
            }
        }
    }
    tbody {
        tr {
            text-align: left;
            font-weight: 300;
            & .description {
                width: 40%;
                padding-right: 1em;
                color: ${ColorName.black}
            }
            & .status {
                width: 30%;
            }
            & .due-date {
                width: 20%;
            }
            & .button {
                width: 10%
            }
        }
    }
`;

const OpenExtra = styled(VerticalContainer)`
    padding-left: 3em;
    &.d-none {
        display: none;
    }
    &.d-block {
        display: block;
    }
    & .textarea {
        height: 150px;
        width: 70%;
        background: ${ColorName.silver};
        border: none;
        resize: vertical;
    }
    & .mt-button {
        margin-top: 1em;
    }
`;

const Attach = styled(HorizontalContainer)`
    margin-top: 2em;
    margin-bottom: 2em;
    i {
        cursor: pointer;
    }
    & .d-none {
        display: none
    }
    & .attach {
        font-size: 30px;
        color: ${ColorName.mineShaft}
        cursor: pointer;
    }
    & .text-attach {
        margin-left: 30px;
        color: ${ColorName.black}
    }
`;

const FooterButton = styled(HorizontalContainer)`
    margin-top: 1.5em;
    justify-content: flex-end;
    & .space-button {
        margin-right: 20px;
    }
`;

export {
    FundingDetailReportComponent,
    TableContainer,
    Title,
    OpenExtra,
    FooterButton,
    Attach
};
