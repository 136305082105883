import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    FormItem,
    FormContainer,
    SubmitButton,
    SelectionTag,
    SelectionContainer
} from "../UserRegister.styles";
import GeneralCheckBoxForm from "../../../../components/Common/GeneralCheckBoxForm";
import { GeneralInputForm, GeneralTextAreaFrom } from "../../../../components/Common/CommonLayout";
import GeneralDropdownForm from "../../../../components/Common/GeneralDropdownForm";
import CONSTANTS from "../../../../constants/Constants";
import LIST from "../ResolveList";
import userRegisterActions from "../../../../actions/UserRegister";
import Utils from "../../../../libs/Utils";
import Validation from "../../../../libs/Validation";

const PrivateEntity = (props) => {
    const { t } = useTranslation();
    const checkBoxRef = [];
    const location = useLocation();
    const dispatch = useDispatch();
    const locateOptions = LIST.resolveCountryList();
    const LanguageOptions = LIST.resolveLanguageList();
    const userRoles = useSelector((state) => state.UserRegister.userRoles);
    const [validateForEmail, setValidateForEmail] = useState(false);
    
    const [validateAbout, setValidateAbout] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        role_id: '',
        property_type: location.state.type,
        password: props.data.password,
        password_confirmation: props.data.password_confirmation
    });
    const [userData, setUserData] = useState({
        first_name: props.data.first_name,
        last_name: props.data.last_name,
        address: '',
        phone_number: '',
        website: '',
        facebook: '',
        instagram: '',
        linkedin: '',
    });
    const [extraData, setExtraData] = useState({
        interested: [],
        located: '',
        city: '',
        language: [],
        hear_about: [],
        legal_name: '',
        legal_address: '',
        is_offer: '',
        expertise: [],
        daily_fee: '',
        is_flexible: '',
        is_provided: '',
        legal_investing_name: props.data.legal_investing_name,
        capacity_provide: '',
        provided_country: [],
        about_me: ''
    });
    const [describeOtherChoice, setDescribeOtherChoice] = useState({});
    const [agreeTerm, setAgreeTerm] = useState(false);
    const [isHasEmptyFields, setIsHasEmptyFields] = useState(false);
    const fileInput = useRef(null);
    const [imageUrl, setImageUrl] = useState('');
    const [fileAttach, setFileAttach] = useState('');

    useEffect(() => {
        if (!_.isEmpty(userRoles)) {
            const findRole = _.find(userRoles, (el) => el.name === CONSTANTS.ROLE_NAME.CONSULTANT);
            setFormData({ ...formData, role_id: findRole.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRoles]);

    const selectMultipleChoice = (parentData, value, dataType) => {
        let newData = [...parentData];
        if (!newData.includes("None")) {
            if (!newData.includes(value)) {
                newData.push(value);
            } else {
                newData = _.remove(newData, (el) => el !== value);
            }
        } else {
            newData = _.remove(newData, (el) => el !== "None");
            newData.push(value);
        }
        if (dataType === 'interested') setExtraData({ ...extraData, interested: newData });
        if (dataType === 'hearAbout') setExtraData({ ...extraData, hear_about: newData });
        if (dataType === 'expertise') setExtraData({ ...extraData, expertise: newData });
    };
    const validateForAbout = () => {
        const validationRules = [
            {
                name: t("general.About"),
                value: extraData.about_me,
                rule: ["required", { max: 300 }]
            }
        ];
        if (!Validation.formValidation(validationRules)) return true;
        return false;
    };

    const validateEmail = () => {
        const validationRules = [
            {
                name: t("general.Email"),
                value: formData.email,
                rule: "email"
            }
        ];
        if (!Validation.formValidation(validationRules)) return true;
        return false;
    };


    const submitForm = () => {
        if (!agreeTerm) return Utils.popupAlert({ title: t("alert.Warning"), text: t("general.Please agree to terms and conditions with regard to your application"), type: 'warning' });

        let flag = false;
        
        if (!fileAttach) flag = true;

        if (flag) {
            Utils.popupAlert({ title: t("alert.Warning"), text: t('Avatar is required'), type: 'warning' });
            setIsHasEmptyFields(true);
            return;
        }

        const flagAbout = validateForAbout();
        if (flagAbout) {
            setValidateAbout(true);
            return;
        } else {
            setValidateAbout(false);
        }

        const flagEmail = validateEmail();
        if (flagEmail) {
            setValidateForEmail(true);
            return;
        } else {
            setValidateForEmail(false);
        }
        
        const submitFormData = new FormData();
        const notRequireFields = ['address', 'website', 'facebook', 'instagram', 'avatar', 'linkedin'];
        _.map(_.keys(formData), (item) => {
            submitFormData.append(`${item}`, formData[item]);
            if (!formData[item]) flag = true;
        });
        _.map(_.keys(userData), (item) => {
            submitFormData.append(`user_data[${item}]`, userData[item]);
            if (!userData[item] && !notRequireFields.includes(item)) flag = true;
        });
        submitFormData.append('user_data[avatar]', fileAttach);
        _.map(_.keys(extraData), (item) => {
            if (_.isArray(extraData[item])) {
                const otherData = [...extraData[item]];
                otherData.push(describeOtherChoice[item]);
                !extraData[item].includes("Other")
                    ? submitFormData.append(`user_data[extra_data][${item}]`, extraData[item])
                    : submitFormData.append(`user_data[extra_data][${item}]`, otherData);
            } else {
                submitFormData.append(`user_data[extra_data][${item}]`, extraData[item]);
            }
            if ((!extraData[item] || (_.isArray(extraData[item]) && _.isEmpty(extraData[item]))) && item !== 'provided_country' && item !== 'legal_investing_name') flag = true;
        });
        if (flag) {
            Utils.popupAlert({ title: t("alert.Warning"), text: t('alert.Found empty field(s)'), type: 'warning' });
            setIsHasEmptyFields(true);
            return;
        }
        dispatch(userRegisterActions.userRegister(submitFormData));
        setIsHasEmptyFields(false);
    };

    const selectTag = (parentData, value, type) => {
        let newData = [...parentData];
        if (!newData.includes(value)) {
            newData.push(value);
        } else {
            newData = _.remove(newData, (el) => el !== value);
        }
        if (type === 'language') setExtraData({ ...extraData, language: newData });
        if (type === 'provideCountry') setExtraData({ ...extraData, provided_country: newData });
    };

    const _renderTags = (data, type) => {
        const render = _.map(data, (tag, index) => {
            return (
                <SelectionTag key={index}>
                    <span>{tag}</span>
                    <i className="fas fa-times" onClick={() => selectTag(data, tag, type)} />
                </SelectionTag>
            );
        });
        return render;
    };

    const handleFileChange = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setFileAttach(e.target.files[0]);
        setImageUrl(url);
    };

    const _renderAvatarField = () => {
        return (
            <FormItem className="center-content">
                <img
                    alt='profile'
                    className="style-img"
                    src={imageUrl || "https://awodev.com/images/default-forum-user.png"}
                    onClick={() => fileInput.current.click()}
                />
                <input
                    ref={fileInput}
                    type="file"
                    accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                    className='d-none'
                    onChange={(e) => handleFileChange(e)}
                />
            </FormItem>
        );
    };

    const _renderFormItemsForPrivate = () => {
        return (
            <FormContainer>
                {_renderAvatarField()}
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.interested) ? "text-red" : "form-question"}`}>{t("general.Why are you interested in becoming a member of Dua Partner Invest?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want insider access to startups'
                            name='interested1'
                            defaultChecked={extraData.interested.includes('I want insider access to startups')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested1 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested1.click()}>{t("general.I want insider access to startups")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to invest to promising startups'
                            name='interested2'
                            defaultChecked={extraData.interested.includes('I want to invest to promising startups')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested2 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested2.click()}>{t("general.I want to invest to promising startups")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to support promising startups with expertise'
                            name='interested3'
                            defaultChecked={extraData.interested.includes('I want to support promising startups with expertise')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested3 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested3.click()}>{t("general.I want to support promising startups with expertise")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to have information on the latest trends of startups ecosystem'
                            name='interested4'
                            defaultChecked={extraData.interested.includes('I want to have information on the latest trends of startups ecosystem')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested4 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested4.click()}>{t("general.I want to have information on the latest trends of startups ecosystem")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to be part of the community of entrepreneurs'
                            name='interested5'
                            defaultChecked={extraData.interested.includes('I want to be part of the community of entrepreneurs')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested5 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested5.click()}>{t("general.I want to be part of the community of entrepreneurs")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='I want to explore opportunities to join forces with other members'
                            name='interested6'
                            defaultChecked={extraData.interested.includes('I want to explore opportunities to join forces with other members')}
                            onChange={(e) => selectMultipleChoice(extraData.interested, e.target.value, 'interested')}
                            checkBoxRef={(ref) => checkBoxRef.interested6 = ref}
                        />
                        <p onClick={() => checkBoxRef.interested6.click()}>{t("general.I want to explore opportunities to join forces with other members")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.capacity_provide ? "text-red" : "form-question"}`}>{t("general.On what capacity do you provide consultancy to startups?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Individual'
                            name='capacity1'
                            defaultChecked={extraData.capacity_provide === 'Individual'}
                            onChange={(e) => setExtraData({ ...extraData, capacity_provide: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.capacity1 = ref}
                        />
                        <p onClick={() => checkBoxRef.capacity1.click()}>{t("general.Individual")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Private Entity'
                            name='capacity2'
                            defaultChecked={extraData.capacity_provide === 'Private Entity'}
                            onChange={(e) => setExtraData({ ...extraData, capacity_provide: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.capacity2 = ref}
                        />
                        <p onClick={() => checkBoxRef.capacity2.click()}>{t("general.Private Entity")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.located ? "text-red" : "form-question"}`}>{t("general.Where are you located?")}</p>
                    <div className="d-flex">
                        <GeneralDropdownForm defaultValue={extraData.located || ''} placeHolder={t("general.Choose country")} options={locateOptions} onChange={(e) => setExtraData({ ...extraData, located: e.target.value })} />
                        <GeneralDropdownForm defaultValue={extraData.city || ''} placeHolder={t("general.Choose city")} options={LIST.getCityList(extraData.located)} onChange={(e) => setExtraData({ ...extraData, city: e.target.value })} />
                    </div>
                    {(isHasEmptyFields && !extraData.city) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <GeneralInputForm placeholder={t("general.Address")} margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, address: e.target.value })} />
                    <GeneralInputForm placeholder={`${t("general.Phone number")} (${t("general.private")})`} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, phone_number: e.target.value })} />
                    {(isHasEmptyFields && !userData.phone_number) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <GeneralInputForm placeholder={`${t("general.Email address")} (${t("general.private")})`} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                    {(isHasEmptyFields && !formData.email) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <GeneralInputForm placeholder={`${t("general.Internet Address")} or N/A`} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, website: e.target.value })} />
                    <GeneralInputForm placeholder="Facebook or N/A" margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, facebook: e.target.value })} />
                    <GeneralInputForm placeholder="Instagram or N/A" margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, instagram: e.target.value })} />
                    <GeneralInputForm placeholder="Linkedin or N/A" margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, linkedin: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.legal_name ? "text-red" : "form-question"}`}>{t("general.What is the name of Administrator/Legal Representative?")}</p>
                    <GeneralInputForm placeholder={t("general.Name")} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, legal_name: e.target.value })} />
                    {(isHasEmptyFields && !extraData.legal_name) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.legal_address ? "text-red" : "form-question"}`}>{t("general.Email address of Administrator/Legal Representative")}</p>
                    <GeneralInputForm placeholder={t("general.Email address")} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, legal_address: e.target.value })} />
                    {validateForEmail && (
                        <p className="hidden-text-red">
                            *
                            {t("validation.Invalid email address")}
                        </p>
                    )}
                    {(isHasEmptyFields && !extraData.legal_address) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.language) ? "text-red" : "form-question"}`}>{t("general.Which are the languages you prefer to interact?")}</p>
                    <GeneralDropdownForm placeHolder={t("general.Choose language")} options={LanguageOptions} onChange={(e) => selectTag(extraData.language, e.target.value, "language")} />
                    <SelectionContainer>
                        {_renderTags(extraData.language, 'language')}
                    </SelectionContainer>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.expertise) ? "text-red" : "form-question"}`}>{t("general.Which is your field of expertise?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Marketing & PR'
                            name='expertise1'
                            defaultChecked={extraData.expertise.includes('Marketing & PR')}
                            onChange={(e) => selectMultipleChoice(extraData.expertise, e.target.value, 'expertise')}
                            checkBoxRef={(ref) => checkBoxRef.expertise1 = ref}
                        />
                        <p onClick={() => checkBoxRef.expertise1.click()}>{t("general.Marketing & PR")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Legal'
                            name='expertise2'
                            defaultChecked={extraData.expertise.includes('Legal')}
                            onChange={(e) => selectMultipleChoice(extraData.expertise, e.target.value, 'expertise')}
                            checkBoxRef={(ref) => checkBoxRef.expertise2 = ref}
                        />
                        <p onClick={() => checkBoxRef.expertise2.click()}>{t("general.Legal")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Accounting & Audit'
                            name='expertise3'
                            defaultChecked={extraData.expertise.includes('Accounting & Audit')}
                            onChange={(e) => selectMultipleChoice(extraData.expertise, e.target.value, 'expertise')}
                            checkBoxRef={(ref) => checkBoxRef.expertise3 = ref}
                        />
                        <p onClick={() => checkBoxRef.expertise3.click()}>{t("general.Accounting & Audit")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Financial'
                            name='expertise4'
                            defaultChecked={extraData.expertise.includes('Financial')}
                            onChange={(e) => selectMultipleChoice(extraData.expertise, e.target.value, 'expertise')}
                            checkBoxRef={(ref) => checkBoxRef.expertise4 = ref}
                        />
                        <p onClick={() => checkBoxRef.expertise4.click()}>{t("general.Financial")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='IT'
                            name='expertise5'
                            defaultChecked={extraData.expertise.includes('IT')}
                            onChange={(e) => selectMultipleChoice(extraData.expertise, e.target.value, 'expertise')}
                            checkBoxRef={(ref) => checkBoxRef.expertise5 = ref}
                        />
                        <p onClick={() => checkBoxRef.expertise5.click()}>{t("general.IT")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Business plan development'
                            name='expertise6'
                            defaultChecked={extraData.expertise.includes('Business plan development')}
                            onChange={(e) => selectMultipleChoice(extraData.expertise, e.target.value, 'expertise')}
                            checkBoxRef={(ref) => checkBoxRef.expertise6 = ref}
                        />
                        <p onClick={() => checkBoxRef.expertise6.click()}>{t("general.Business plan development")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Innovation'
                            name='expertise7'
                            defaultChecked={extraData.expertise.includes('Innovation')}
                            onChange={(e) => selectMultipleChoice(extraData.expertise, e.target.value, 'expertise')}
                            checkBoxRef={(ref) => checkBoxRef.expertise7 = ref}
                        />
                        <p onClick={() => checkBoxRef.expertise7.click()}>{t("general.Innovation")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Other'
                            name='expertise8'
                            defaultChecked={extraData.expertise.includes('Other')}
                            onChange={(e) => selectMultipleChoice(extraData.expertise, e.target.value, 'expertise')}
                            checkBoxRef={(ref) => checkBoxRef.expertise8 = ref}
                        />
                        <p onClick={() => checkBoxRef.expertise8.click()}>{t("general.Other")}</p>
                        <GeneralInputForm
                            placeholder={t("general.Describe")}
                            margin='0 0 0 0.5em'
                            width="98.5%"
                            onChange={(e) => setDescribeOtherChoice({ ...describeOtherChoice, expertise: e.target.value })}
                        />
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.daily_fee) ? "text-red" : "form-question"}`}>{`${t("general.Which is daily average fee applied in EUR?")} (${t("general.private")})`}</p>
                    <GeneralInputForm type='number' placeholder="€" margin='1em 0 0.5em 0' width="98.5%" onChange={(e) => setExtraData({ ...extraData, daily_fee: e.target.value })} />
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.is_flexible ? "text-red" : "form-question"}`}>{t("general.Are you flexible to negotiate your rate on a case by case basis?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Yes'
                            name='isFlexible1'
                            defaultChecked={extraData.is_flexible === 'Yes'}
                            onChange={(e) => setExtraData({ ...extraData, is_flexible: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.isFlexible1 = ref}
                        />
                        <p onClick={() => checkBoxRef.isFlexible1.click()}>{t("general.Yes")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='No'
                            name='isFlexible2'
                            defaultChecked={extraData.is_flexible === 'No'}
                            onChange={(e) => setExtraData({ ...extraData, is_flexible: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.isFlexible2 = ref}
                        />
                        <p onClick={() => checkBoxRef.isFlexible2.click()}>{t("general.No")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.is_provided ? "text-red" : "form-question"}`}>{t("general.Have you ever provided consultancy for startups?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Yes'
                            name='isProvided2'
                            defaultChecked={extraData.is_provided === 'Yes'}
                            onChange={(e) => setExtraData({ ...extraData, is_provided: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.isProvided2 = ref}
                        />
                        <p onClick={() => checkBoxRef.isProvided2.click()}>{t("general.Yes")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='No'
                            name='isProvided1'
                            defaultChecked={extraData.is_provided === 'No'}
                            onChange={(e) => setExtraData({ ...extraData, is_provided: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.isProvided1 = ref}
                        />
                        <p onClick={() => checkBoxRef.isProvided1.click()}>{t("general.No")}</p>
                    </span>
                    {extraData.is_provided === 'Yes' && (
                        <div className="yes-case">
                            <p>{t("general.If yes, in which countries?")}</p>
                            <GeneralDropdownForm placeHolder={t("general.Choose all that apply")} options={locateOptions} onChange={(e) => selectTag(extraData.provided_country, e.target.value, "provideCountry")} />
                            <SelectionContainer>
                                {_renderTags(extraData.provided_country, "provideCountry")}
                            </SelectionContainer>
                        </div>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.is_offer ? "text-red" : "form-question"}`}>{t("general.Do you apply pro-bono consultancy in your field of expertise?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Yes'
                            name='isOffer1'
                            defaultChecked={extraData.is_offer === 'Yes'}
                            onChange={(e) => setExtraData({ ...extraData, is_offer: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.isOffer1 = ref}
                        />
                        <p onClick={() => checkBoxRef.isOffer1.click()}>{t("general.Yes")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='No'
                            name='isOffer2'
                            defaultChecked={extraData.is_offer === 'No'}
                            onChange={(e) => setExtraData({ ...extraData, is_offer: e.target.value })}
                            checkBoxRef={(ref) => checkBoxRef.isOffer2 = ref}
                        />
                        <p onClick={() => checkBoxRef.isOffer2.click()}>{t("general.No")}</p>
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && !extraData.about_me ? "text-red" : "form-question"}`}>
                        {t("general.About me")}
:
                    </p>
                    <GeneralTextAreaFrom maxLength="300" placeholder={t("general.About me")} margin='0 0 0.5em 0' width="98.5%" height="6em" defaultValue={extraData.about_me} onChange={(e) => setExtraData({ ...extraData, about_me: e.target.value })} />
                    {validateAbout && (
                        <p className="hidden-text-red">
                            *
                            Max character is 300
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <p className={`${isHasEmptyFields && _.isEmpty(extraData.hear_about) ? "text-red" : "form-question"}`}>{t("general.Where did you hear about Dua Partner Invest platform?")}</p>
                    <span>
                        <GeneralCheckBoxForm
                            value='Facebook'
                            name='hearAbout1'
                            defaultChecked={extraData.hear_about.includes('Facebook')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout1 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout1.click()}>Facebook</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Instagram'
                            name='hearAbout2'
                            defaultChecked={extraData.hear_about.includes('Instagram')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout2 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout2.click()}>Instagram</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Linkedin'
                            name='hearAbout3'
                            defaultChecked={extraData.hear_about.includes('Linkedin')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout3 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout3.click()}>Linkedin</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Website of Partners Albania'
                            name='hearAbout4'
                            defaultChecked={extraData.hear_about.includes('Website of Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout4 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout4.click()}>{t("general.Website of Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Activities organized by Partners Albania'
                            name='hearAbout5'
                            defaultChecked={extraData.hear_about.includes('Activities organized by Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout5 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout5.click()}>{t("general.Activities organized by Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Direct contact with the staff of Partners Albania'
                            name='hearAbout6'
                            defaultChecked={extraData.hear_about.includes('Direct contact with the staff of Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout6 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout6.click()}>{t("general.Direct contact with the staff of Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Annual report of Partners Albania'
                            name='hearAbout7'
                            defaultChecked={extraData.hear_about.includes('Annual report of Partners Albania')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout7 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout7.click()}>{t("general.Annual report of Partners Albania")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Other online platforms'
                            name='hearAbout8'
                            defaultChecked={extraData.hear_about.includes('Other online platforms')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout8 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout8.click()}>{t("general.Other online platforms")}</p>
                    </span>
                    <span>
                        <GeneralCheckBoxForm
                            value='Other'
                            name='hearAbout9'
                            defaultChecked={extraData.hear_about.includes('Other')}
                            onChange={(e) => selectMultipleChoice(extraData.hear_about, e.target.value, 'hearAbout')}
                            checkBoxRef={(ref) => checkBoxRef.hearAbout9 = ref}
                        />
                        <p onClick={() => checkBoxRef.hearAbout9.click()}>{t("general.Other")}</p>
                        <GeneralInputForm
                            placeholder={t("general.Describe")}
                            margin='0 0 0 0.5em'
                            width="98.5%"
                            onChange={(e) => setDescribeOtherChoice({ ...describeOtherChoice, hear_about: e.target.value })}
                        />
                    </span>
                    <p className="line" />
                </FormItem>
                <FormItem>
                    <span>
                        <GeneralCheckBoxForm
                            value='agree'
                            name='agreeTerm'
                            defaultChecked={agreeTerm}
                            onChange={() => setAgreeTerm(!agreeTerm)}
                            checkBoxRef={(ref) => checkBoxRef.agree = ref}
                        />
                        <p className="condition" onClick={() => window.open(`${window.location.href.split("register")[0]}terms`)}>
                            {t("general.I agree to")}
                            &nbsp;
                            {' '}
                            <span className="link-highlight">{t("general.terms and conditions")}</span>
                            {' '}
                            &nbsp;
                            {t("general.with regard to my application")}
                        </p>
                    </span>
                    <p className="line" />
                </FormItem>
                <SubmitButton type='button' onClick={() => submitForm()}>
                    {t("general.Submit")}
                </SubmitButton>
            </FormContainer>
        );
    };

    return (_renderFormItemsForPrivate());
};

export default PrivateEntity;
