import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  WrapperTitle,
  DropDownJudge,
  NameJudge,
  FieldTitle,
  ProgressBar,
  ContentDetail
} from "./FundingApplications.styles";
import {
  PageTitle
} from "../../../../components/Common/CommonLayout";
import Constants from "../../../../constants/Constants";
import FundingApplicationActions from "../../../../actions/FundingApplication";
import Button from "../../../../components/Common/Button";
import { ColorName } from "../../../../components/Variables";
import Utils from "../../../../libs/Utils";
import RoundedTable from "../../../../components/Common/RoundedTable";
import history from "../../../../history";
import AdminOverViewAction from '../../../../actions/AdminOverView';

const ApplicationsDetail = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);
  const [statusFunding, setStatusFunding] = useState("Open");
  const dispatch = useDispatch();
  const FundingStorage = useSelector((state) => state.FundingApplication);
  const fundingId = useSelector((state) => state.FundingApplication.fundingId);
  const judgeIsLoading = useSelector((state) => state.Judge.isLoading);
  const [dataOpenApplicationItem, setdataOpenApplicationItem] = useState([]);
  const [newData, setNewData] = useState([]);
  const [listJudge, setListJudge] = useState(false);
  const listRole = useSelector((state) => state.AdminOverView.roles);
  const [judgeId, setJudgeId] = useState('');
  const [numberApply, setNumberApply] = useState(10);
  const columnsApplication = ['applications status', 'Applicant name', 'Date submitted', "Number Judge", "Average Score", 'action'];
  const listOptions = {
    1: "view",
    2: "delete"
  };

  useEffect(() => {
    if (fundingId) {
      dispatch(FundingApplicationActions.getFundingApplicationById(fundingId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundingId]);

  useEffect(() => {
    dispatch(AdminOverViewAction.getRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(listRole)) {
      const data = {
        role_id: _.find(listRole, ["name", "Judge"]).id
      };
      dispatch(FundingApplicationActions.getListJudge(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRole]);

  const calculatorEvaluate = (data) => {
    const sortObject = {};
    const dataFunding = { ...data };
    if (_.isEmpty(data.applyApplications)) return setNewData([]);
    _.map(dataFunding.applyApplications, (apply, index) => {
      const numberJudgeEvaluate = apply.evaluationApplies.length;
      const totalEvaluate = _.chain(apply.evaluationApplies)
        .map("evaluationResultSections")
        .flatten()
        .map("evaluationResultQuestions")
        .flatten()
        .sumBy("evaluate_score")
        .value();
      dataFunding.applyApplications[index].totalEvaluate = totalEvaluate;
      dataFunding.applyApplications[index].numberJudgeEvaluate = numberJudgeEvaluate;
      dataFunding.applyApplications[index].numberJudgeFunding = dataFunding.applicationJudge.length;
      sortObject.applyApplications = _.orderBy(dataFunding.applyApplications, 'totalEvaluate', 'desc');
      setNewData(sortObject);
    });
  };

  useEffect(() => {
    setIsLoading(FundingStorage.isLoading);
    setdataOpenApplicationItem(FundingStorage.newFundingData);
    setStatusFunding(FundingStorage.newFundingData.status);
    setListJudge(FundingStorage.listJudge);
    calculatorEvaluate(FundingStorage.newFundingData)
  }, [FundingStorage]);

  const renderOtherRequirements = () => {
    if (_.isEmpty(dataOpenApplicationItem)) return;
    const render = _.map(dataOpenApplicationItem.extraFieldJson, (item, index) => {
      return (
        <WrapperTitle marginTop="2em" key={index}>
          <div className="title">
            {item.title}
          </div>
          <div className="description">{item.value}</div>
        </WrapperTitle>
      );
    });
    return render;
  };

  const setJudgeID = (e) => {
    setJudgeId(e.target.value);
  };

  const AddJudgeToApplication = async () => {
    if (judgeId) {
      const data = {
        user_id: judgeId,
        funding_application_id: fundingId
      };
      dispatch(FundingApplicationActions.addJudgeToApplication(data));
    }
  };
  const renderDropdownJudge = () => {
    if (_.isEmpty(listJudge)) return;
    const render = _.map(listJudge.data, (judge, index) => {
      return <option key={index} value={judge.id}>{`${judge.userData.first_name} ${judge.userData.last_name}`}</option>;
    });
    return render;
  };

  const renderQuestionnaire = () => {
    if (dataOpenApplicationItem.questionnaire) {
      const render = _.map(dataOpenApplicationItem.questionnaire.questionCategories, (category, index) => {
        return (
          <table key={index}>
            <thead>
              <tr><th colSpan="4" className="category">{`${index + 1}. ${category.title}`}</th></tr>
              <tr>
                <th className="titleQuestionnaire">{t("general.Title")}</th>
                <th>{t("fundingApplications.Field Type")}</th>
                <th>{t("fundingApplications.Max Characters")}</th>
                <th>{t("fundingApplications.Mandatory")}</th>
              </tr>
            </thead>
            <tbody key={index}>
              {
                _.map(category.questionDetails, (field, key) => {
                  return (
                    <tr key={key}>
                      <td className="title">{field.title}</td>
                      <td>{field.question_type === 1 ? "Input" : "select"}</td>
                      <td>{field.max_characters}</td>
                      <td>{field.is_mandatory ? "true" : "false"}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        );
      });
      return render;
    }
  };

  const deleteJudge = (id) => {
    Utils.popupAlert({
      type: "warning",
      title: t("rounderTable.Do you want to delete"),
      buttons: [t("general.Cancel"), true],
      dangerMode: true
    }).then((willContinue) => {
      if (willContinue) {
        dispatch(FundingApplicationActions.removeJudge(id, fundingId));
      }
    });
  };

  const renderJudgeAddToApplication = () => {
    if (!_.isUndefined(dataOpenApplicationItem)) {
      const render = _.map(dataOpenApplicationItem.applicationJudge, (judge, index) => {
        if (judge.status === 1) {
          return (
            <div key={index}>
              <NameJudge>
                <div>
                  {judge.user.userData.first_name}
                  {` `}
                  {judge.user.userData.last_name}
                  {` `}
                </div>
                <i
                  className="fas fa-minus-circle"
                  onClick={() => deleteJudge(judge.id)}
                />
              </NameJudge>
            </div>
          );
        }
      });
      return render;
    }
  };

  const checkColorStatusCell = (status) => {
    if (Constants.COLOR_STATUS.GRADIENT_VALUE.includes(status)) {
      return 'pink';
    } else if (Constants.COLOR_STATUS.GREEN_VALUE.includes(status)) {
      return 'green';
    } else if (Constants.COLOR_STATUS.RED_VALUE.includes(status)) {
      return 'red';
    }
  };

  const clickViewMoreApply = async () => {
    if (newData.applyApplications.length > numberApply) {
      setNumberApply(numberApply + 10)
    }
  };

  const changeStatus = (e) => {
    setStatusFunding(e.target.value);
    setIsSendMail(false);
  };

  const changeStatusApply = () => {
    Utils.popupAlert({
      type: "warning",
      title: t("rounderTable.Do you want to change status"),
      buttons: [t("general.Cancel"), true],
      dangerMode: true
    }).then((willContinue) => {
      if (willContinue) {
        if (dataOpenApplicationItem.id) {
          const formData = new FormData();
          formData.append('status', statusFunding);
          formData.append('is_send_mail', isSendMail);
          dispatch(FundingApplicationActions.editFundingApplication(formData, dataOpenApplicationItem.id, true));
        } else {
          Utils.popupAlert({ title: "Oops...", text: t("general.Apply application not found"), type: "error" });
        }
      }
    });
  };

  return (
    <DefaultLayout
      userRole='admin'
      isLoading={isLoading || judgeIsLoading}
      page="fundingApplications"
      content={(
        <BodyContainer>
          <PageTitle>
            {t("fundingApplications.Applications Management")}
          </PageTitle>
          <FieldTitle>
            {t("fundingApplications.Funding Application Details")}
          </FieldTitle>
          <ProgressBar className="mb-2">
            <ContentDetail>
              <div className="forDetails">
                <WrapperTitle>
                  <div className="title-deadline">{dataOpenApplicationItem && dataOpenApplicationItem.title}</div>
                  <div className="fonts-status">
                    {t("general.Status")}
                    :
                    {`\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`}
                    <span className={checkColorStatusCell(dataOpenApplicationItem.status)}>
                      {dataOpenApplicationItem.status
                        && t(`rounderTable.${_.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, dataOpenApplicationItem.status))}`)}
                    </span>
                  </div>
                </WrapperTitle>
                {renderOtherRequirements()}
              </div>
              <div className="forJudge">
                <WrapperTitle marginTop="1em">
                  <div className="title">
                    {t("general.Status")}
                    :
                  </div>
                  <DropDownJudge key={dataOpenApplicationItem.status} defaultValue={dataOpenApplicationItem.status || ""} onChange={(e) => changeStatus(e)}>
                    <option value={Constants.FUNDING_APPLICATION_STATUS.INACTIVE}>{t("general.Inactive")}</option>
                    <option value={Constants.FUNDING_APPLICATION_STATUS.CLOSED}>{t("general.Closed")}</option>
                    <option value={Constants.FUNDING_APPLICATION_STATUS.OPEN}>{t("general.Open")}</option>
                    <option value={Constants.FUNDING_APPLICATION_STATUS.ANNOUNCED}>{t("general.Announced")}</option>
                  </DropDownJudge>
                  <Button
                    color={ColorName.white}
                    background={ColorName.fern}
                    title={t("general.Save")}
                    height="40px"
                    width="200px"
                    margin="0 1.5em 0 0"
                    padding='1em'
                    onClick={() => changeStatusApply()}
                  />
                </WrapperTitle>
                <WrapperTitle marginTop="1em">
                  <div className="title">{t("fundingApplications.List Judge")}</div>
                  <DropDownJudge onChange={setJudgeID}>
                    <option value={0}>{t("fundingApplications.Select Judge")}</option>
                    {renderDropdownJudge()}

                  </DropDownJudge>
                  <Button
                    color={ColorName.white}
                    background={ColorName.fern}
                    title={t("fundingApplications.Add Judge")}
                    height="40px"
                    width="200px"
                    onClick={() => AddJudgeToApplication()}
                  />
                </WrapperTitle>
                <WrapperTitle marginTop="1em">
                  {_.isEmpty(dataOpenApplicationItem.applicationJudge)
                    && <div>{t("fundingApplications.No Judge!")}</div>
                  }
                  {renderJudgeAddToApplication()}
                </WrapperTitle>
              </div>
            </ContentDetail>
          </ProgressBar>
          <FieldTitle>
            {t("fundingApplications.Questionnaire Details")}
          </FieldTitle>
          <ProgressBar className="mb-2">
            <ContentDetail className="questionnaireDetails">
              {renderQuestionnaire()}
            </ContentDetail>
          </ProgressBar>
          <FieldTitle>
            {t("fundingApplications.List Of Applications Submitted")}
          </FieldTitle>
          <RoundedTable
            initialData={newData.applyApplications || []}
            columns={columnsApplication || []}
            hideTitleHeader
            tableType={Constants.TABLE_TYPE.APPLY_APPLICATION}
            listOptions={listOptions}
            showNumberItems={numberApply}
            buttonTilte={t("general.View More")}
            handleClick={clickViewMoreApply}
          />
          <FieldTitle className="button-export">
            <Button
              color={ColorName.white}
              background={ColorName.chathamsBlueDark}
              title={t("fundingApplications.Export Submitted Applications")}
              height="40px"
              width="auto"
              padding="0 1em"
              onClick={() => history.push("/admin/export-submitted")}
            />
          </FieldTitle>
        </BodyContainer>
      )}
    />
  );
};

export default ApplicationsDetail;
