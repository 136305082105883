import styled from 'styled-components';
import { ColorName } from "../../Variables";

const ButtonDefault = styled.button`
    background: ${(props) => props.background || ColorName.screaminGreen};
    width: ${(props) => props.width || '7em'};
    height: ${(props) => props.height || '30px'};
    padding: ${(props) => props.padding || '2px'};
    border-radius: ${(props) => props.borderRadius || '25px'};
    color: ${(props) => props.color || ColorName.white};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    outLine: none;
    border: none;
    font-weight: 500;
    font-size: 1em;
    margin: ${(props) => props.margin || '0'};
    cursor: pointer;
    &.buttonFundingApplication{
        width: auto;
        height: auto;
        font-size: 12px;
        font-weight: 600;
        padding: 1em;
        min-width: 100px;
    }
    &.buttonChangeRole{
        font-weight: 600;
        padding: 1em;
        min-width: 100px;
    }
    font-size: 16px;
    &.none-capital{
        text-transform: none;
    }
`;

const ButtonGradient = styled(ButtonDefault)`
    background: linear-gradient(178.43deg, ${ColorName.tickleMePink} -13.56%, ${ColorName.persianBlue} 158.3%);
`;

export {
    ButtonDefault,
    ButtonGradient
};
