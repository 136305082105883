import styled from 'styled-components';
import { VerticalContainer, GeneralButton } from "../../../../components/Common/CommonLayout";
import { ColorName } from '../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .ml-1 {
        margin-left: 1em
    }
    & .mb-2 {
        margin-bottom: 2em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
    & .header-explore {
        font-weight: 300;
        font-size: 3em;
        color: ${ColorName.chathamsBlue};
        line-height: 1;
    }
    & .wrapper-button {
        display: flex;
        margin-top: 4em;
        justify-content: center;
    }
`;

const WrapperNav = styled.div`
    display: flex;
    flex-direction: row;
    padding: 30px 30px 30px 0px;
`;

const WrapperContentExplore = styled.div`
    display: flex;
    flex-wrap: wrap;
    & .row-item {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 2em;
    }
`;

const UserCardContainer = styled(VerticalContainer)`
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    background: ${ColorName.botticelli};
    max-width: 240px;
    width: 228px;
    margin-top: 1em;
    height: 400px;
    margin: ${(props) => props.margin || '0'};
    button {
        margin-right: auto;
    }
    margin: 2em 1em 0em 0em;
    // border-radius: 25px;
    & .container-button{
        margin-top: auto;
        display: flex;
        width: 100%;
    }
    position: relative;
    // -webkit-box-shadow: 0px 0px 20px 5px rgba(158,158,158,1);
    // -moz-box-shadow: 0px 0px 20px 5px rgba(158,158,158,1);
    // box-shadow: 0px 0px 20px 5px rgba(158,158,158,1);
    
    & .bor-ra-25 {
        border-radius: 10px;
    }
    & .position-contact-me {
        position: absolute;
        right: 30px;
        margin-right: 0;
    }
`;

const ProfilePicture = styled(VerticalContainer)`
    top: 10px;
    padding: 0px 10px;
    justify-content: center;
    p {
        text-transform: uppercase;
        text-align: center;
        margin: 0;
    }
    img {
        object-fit: cover;
        height: 165px;
        width: 165px;
        border-radius: 10%;
    }
`;

const UserInfomations = styled(VerticalContainer)`
    padding: 0.5em 1em;
    width: 100%;
    & div{
        padding: 0.3em 0;
    }
`;

const UserName = styled.div`
    color: black;
    text-transform: capitalize;
`;

const Infomation = styled.div`
    margin-top: ${(props) => props.marginTop || "0"};
    & .styled-user {
        color: black;
        text-transform: capitalize;
    }
    & .styled-interests {
    }
    & .styled-description {
    }
    span {
        color: black;
    }
    & [data-tooltip-text]:hover {
        overflow:visible;
    }
    & [data-tooltip-text]:hover:after {
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.8);
        align-items: center;
        justify-content: center;
        right: 0px;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
        -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
        box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
        margin-top: -30px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        color: #FFFFFF;
        font-size: 12px;
        content: attr(data-tooltip-text);
        margin-bottom: 10px;
        left: 0;
        padding: 7px 12px;
        position: absolute;
        width: 90%;
        min-width: 50px;
        max-width: 300px;
        word-wrap: break-word;
        z-index: 9999;
    }
`;
const GeneralButtonUser = styled(GeneralButton)`
    font-weight: 600;
    font-size: 1em;
    margin-right: 1em;
    padding: 0.5em 1em;
    border: 1px solid ${ColorName.chathamsBlue};
    &.for-albania{
        font-size: 0.9em;
        padding: 0.5em 0.9em;
    }
`;

export {
    BodyContainer,
    WrapperNav,
    WrapperContentExplore,
    UserCardContainer,
    ProfilePicture,
    UserInfomations,
    UserName,
    Infomation,
    GeneralButtonUser
};
