import Api from "./Base";

export function addCriteria(data) {
    return Api.postFormData("/criterion", data);
}

export function updateCriteria(data, id) {
    return Api.postFormData(`/criterion/${id}/update`, data);
}

export function deleteCriteria(id) {
    return Api.post(`/criterion/${id}/delete`);
}

export function getAllCriteria(data) {
    return Api.get("/criterion_fetch", data);
}

export function getCriteriaById(data, id) {
    return Api.get(`/criterion/${id}`, data);
}
