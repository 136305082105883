import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";

const messageHasError = () => {
  return {
    type: ActionTypes.MESSAGE_FAILURE,
  };
};

const messageIsLoading = () => {
  return {
    type: ActionTypes.MESSAGE_IS_LOADING,
  };
};

const getMessageSuccess = (data = false) => {
  return {
    type: ActionTypes.MESSAGE_SUCCESS,
    data,
  };
};

const getMessageBackGroundSuccess = (data = false) => {
  return {
    type: ActionTypes.GET_MESSAGE_BACKGROUND_SUCCESS,
    data,
  };
};

const getListUsersSuccess = (data = false) => {
  return {
    type: ActionTypes.GET_LIST_USERS_SUCCESS,
    data,
  };
};

const getListUserMessageSuccess = (data = false) => {
  return {
    type: ActionTypes.GET_LIST_USERS_SUCCESS,
    data,
  };
};

const getListUserMessageAdminSuccess = (data = false) => {
  return {
    type: ActionTypes.GET_LIST_USERS_ADMIN_SUCCESS,
    data,
  };
};

const getChannelSuccess = (data = false) => {
  return {
    type: ActionTypes.GET_CHANNEL_SUCCESS,
    data,
  };
};

const deleteChannelSuccess = () => {
  return {
    type: ActionTypes.DELETE_CHANNEL_SUCCESS,
  };
};

const sendMessageSuccess = () => {
  return {
    type: ActionTypes.SEND_MESSAGE_SUCCESS,
  };
};

const setUserReceive = (data = false) => {
  return {
    type: ActionTypes.SET_USER_RECEIVE,
    data,
  };
};

const setUnreadMessagesSuccess = (data = false) => {
  return {
    type: ActionTypes.SET_NUMBER_UNREAD_MESSAGE_SUCCESS,
    data,
  };
};

const getMessage = (data) => {
  return (dispatch) => {
    dispatch(messageIsLoading());
    API.getMessage(data)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getMessageSuccess(res.data.data));
        } else {
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
          dispatch(messageHasError());
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const getListUsers = () => {
  return (dispatch) => {
    dispatch(messageIsLoading());
    API.getListUsers()
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getListUsersSuccess(res.data.data));
        } else {
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
          dispatch(messageHasError());
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const getListUsersBackground = () => {
  return (dispatch) => {
    // dispatch(messageIsLoading());
    API.getListUsers()
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getListUsersSuccess(res.data.data));
        } else {
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
          dispatch(messageHasError());
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const getListUserMessage = () => {
  return (dispatch) => {
    API.getListUserMessage()
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getListUserMessageSuccess(res.data.data));
        } else {
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
          dispatch(messageHasError());
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const getChannel = (data) => {
  return (dispatch) => {
    dispatch(messageIsLoading());
    API.getChannel(data)
      .then((res) => {
        if (res.data) {
          dispatch(getChannelSuccess(res.data.data.id));
        } else {
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
          dispatch(messageHasError());
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const deleteChannel = (id) => {
  return (dispatch) => {
    dispatch(messageIsLoading());
    API.deleteChannel(id)
      .then((res) => {
        if (res.data) {
          dispatch(deleteChannelSuccess());
          dispatch(getListUserMessage());
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
        } else {
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
          dispatch(messageHasError());
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const addMessage = (data) => {
  return (dispatch) => {
    dispatch(messageIsLoading());
    API.addMessage(data)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(sendMessageSuccess());
        } else {
          dispatch(messageHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const addMessageWithNotification = (data) => {
  return (dispatch) => {
    API.addMessage(data)
      .then((res) => {
        if (res.data.status === true) {
          Utils.popupAlert({
            title: i18n.t("alert.Success"),
            text: res.data.message,
            type: "success",
          });
        } else {
          dispatch(messageHasError());
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const getMessageBackground = (data) => {
  return (dispatch) => {
    dispatch(messageIsLoading());
    API.getMessage(data)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getMessageBackGroundSuccess(res.data.data));
        } else {
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
          dispatch(messageHasError());
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const getBasicInfomation = (id) => {
  return (dispatch) => {
    API.getBasicInfomation(id)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(setUserReceive(res.data.data));
        } else {
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
          dispatch(messageHasError());
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const getListUserMessageAdmin = () => {
  return (dispatch) => {
    dispatch(messageIsLoading());
    API.getListUserMessageAdmin()
      .then((res) => {
        if (res.data.status === true) {
          dispatch(getListUserMessageAdminSuccess(res.data.data));
        } else {
          Utils.popupAlert({
            title: "Oops...",
            text: res.data.message,
            type: "error",
          });
          dispatch(messageHasError());
        }
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const updateReadedMessage = (id, data) => {
  return (dispatch) => {
    API.updateReadedMessage(id, data)
      .then((res) => {})
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

const getUnreadMessage = (id) => {
  return (dispatch) => {
    API.getUnreadMessage(id)
      .then((res) => {
        dispatch(setUnreadMessagesSuccess(res.data.data.unreadMessages));
      })
      .catch((e) => {
        Utils.popupAlert({
          title: "Oops...",
          text: Utils.getMessage(e),
          type: "error",
        });
        dispatch(messageHasError());
      });
  };
};

export default {
  getChannel,
  deleteChannel,
  addMessage,
  getMessage,
  getListUsers,
  setUserReceive,
  getListUserMessage,
  getMessageBackground,
  getBasicInfomation,
  addMessageWithNotification,
  getListUserMessageAdmin,
  updateReadedMessage,
  getUnreadMessage,
  getListUsersBackground
};
