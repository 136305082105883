import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import {
    ButtonDefault,
    ButtonGradient
} from "./Button.styles";

const Button = (props) => {
    const [background, setBackground] = useState();
    const [width, setWidth] = useState();
    const [title, setTitle] = useState();
    const [height, setHeight] = useState();
    const [padding, setPadding] = useState();
    const [margin, setMargin] = useState();
    const [borderRadius, setBorderRadius] = useState();
    const [color, setColor] = useState();
    const [className, setClassName] = useState();

    useEffect(() => {
        setBackground(props.background);
        setWidth(props.width);
        setTitle(props.title);
        setHeight(props.height);
        setPadding(props.padding);
        setMargin(props.margin);
        setBorderRadius(props.borderRadius);
        setColor(props.color);
        setClassName(props.className);
    }, [props]);

    const handleOnClick = () => {
        props.onClick();
    };

    return (
        (background === 'gradient')
            ? (
                <ButtonGradient
                    width={width}
                    height={height}
                    padding={padding}
                    onClick={handleOnClick}
                    className={className}
                    margin={margin}
                    borderRadius={borderRadius}
                    color={color}
                >
                    {title}
                </ButtonGradient>
            )
            : (
                <ButtonDefault
                    background={background}
                    width={width}
                    height={height}
                    padding={padding}
                    margin={margin}
                    onClick={handleOnClick}
                    className={className}
                    borderRadius={borderRadius}
                    color={color}
                >
                    {title}
                </ButtonDefault>
            )
    );
};

Button.propTypes = {
    background: PropTypes.string,
    width: PropTypes.string,
    title: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    margin: PropTypes.string,
    borderRadius: PropTypes.string,
    color: PropTypes.string
};

export default Button;
