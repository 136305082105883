import _ from "lodash";
import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";
import FundingApplication from "./FundingApplication";
import Constants from "../constants/Constants";

const requestSuccess = (payload = false) => {
    return {
        type: ActionTypes.GET_APPLICATIONS_SUCCESS,
        payload
    };
};

const requestHasError = () => {
    return {
        type: ActionTypes.JUDGE_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.JUDGE_REQUEST
    };
};

const getApplicationsJudgeByIdSuccess = (payload) => {
    return {
        type: ActionTypes.GET_JUDGE_APPLY_APPLICATION_BY_ID,
        payload
    };
};

const getApplyApplicationByIdSuccess = (payload) => {
    return {
        type: ActionTypes.GET_APPLY_BY_ID,
        payload
    };
};

const getEvaluationQuestionnaireSuccess = (payload) => {
    return {
        type: ActionTypes.GET_EVALUATION_QUESTIONNAIRE_SUCCESS,
        payload
    };
};

const setEvaluationQuestionnaireSuccess = (payload) => {
    return {
        type: ActionTypes.GET_EVALUATION_QUESTIONNAIRE_SUCCESS,
        payload
    };
};

const saveEvaluationQuestionnaireSuccess = (payload) => {
    return {
        type: ActionTypes.GET_EVALUATION_QUESTIONNAIRE_SUCCESS,
        payload
    };
};

const getEvaluationQuestionnaireByIdSuccess = (payload) => {
    return {
        type: ActionTypes.GET_EVALUATION_QUESTIONNAIRE_BY_ID_SUCCESS,
        payload
    };
};

const setEvaluationID = (payload) => {
    return {
        type: ActionTypes.SET_EVALUATION_ID,
        payload
    };
};

const resetEvaluation = () => {
    return {
        type: ActionTypes.RESET_EVALUATION_ID
    };
};

const changeStatusSuccess = (payload) => {
    return {
        type: ActionTypes.CHANGE_STATUS_APPLY_SUCCESS,
        payload
    };
};

const getApplicationsJudge = () => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getApplicationsJudge()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const getApplicationsJudgeById = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getApplicationsJudgeById(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getApplicationsJudgeByIdSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const getApplyApplicationById = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getApplyApplicationById(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getApplyApplicationByIdSuccess(res.data.data));
                } else {
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const changeStatusApplyApplication = (data, id, fundingID) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.changeStatusApplyApplication(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(changeStatusSuccess());
                    API.getFundingApplicationById(fundingID)
                        .then((respone) => {
                            if (respone.data.status === true) {
                                dispatch(FundingApplication.getFundingApplicationByIdSuccess(respone.data.data));
                                Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                            } else {
                                Utils.popupAlert({ title: "Oops...", text: respone.data.message, type: "error" });
                                dispatch(requestHasError());
                            }
                        }).catch((e) => {
                            const messageError = _.map(e.data, 'message').toString();
                            Utils.popupAlert({ title: "Oops...", text: messageError, type: "error" });
                            dispatch(requestHasError());
                        });
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const changeStatusApplyApplicationEvaluation = (data, id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.changeStatusApplyApplication(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(changeStatusSuccess());
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const getEvaluationQuestionnaire = () => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getEvaluationQuestionnaire()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getEvaluationQuestionnaireSuccess(res.data.data));
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const saveEvaluation = (data, applyApplicationID) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.saveEvaluation(data)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    const dataUpdate = {
                        status: `${Constants.APPLY_APPLICATION_STATUS.IN_REVIEW}`
                    };
                    dispatch(changeStatusApplyApplicationEvaluation(dataUpdate, applyApplicationID));
                    dispatch(saveEvaluationQuestionnaireSuccess(res.data.data.evaluationResultSections));
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const updateEvaluation = (data, id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateEvaluation(data, id)
            .then((res) => {
                if (res.data.status === true) {
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    dispatch(saveEvaluationQuestionnaireSuccess(res.data.data.evaluationResultSections));
                }
            }).catch((e) => {
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
                dispatch(requestHasError());
            });
    };
};

const getEvaluationById = (id) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getEvaluationById(id)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(getEvaluationQuestionnaireByIdSuccess(res.data.data.evaluationResultSections));
                }
            }).catch((e) => {
                dispatch(requestHasError());
            });
    };
};

const updateJugdeInfomations = (id, data) => {
    return async (dispatch) => {
        dispatch(requestIsLoading());
        await API.updateUserOverview(id, data)
            .then(async (res) => {
                if (res.data.status === true) {
                    const oldData = Utils.getSavedUserData();
                    const newData = _.merge(oldData, Utils.convertSnakeKeyToCamel(res.data.data));
                    await Utils.saveUserData(newData);
                    Utils.popupAlert({ text: res.data.message, type: "success" })
                        .then((isSuccess) => {
                            if (isSuccess) {
                                dispatch(changeStatusSuccess());
                            }
                        });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    getApplicationsJudge,
    getApplicationsJudgeById,
    getApplyApplicationById,
    changeStatusApplyApplication,
    getEvaluationQuestionnaire,
    setEvaluationQuestionnaireSuccess,
    saveEvaluation,
    getEvaluationById,
    setEvaluationID,
    resetEvaluation,
    updateEvaluation,
    changeStatusApplyApplicationEvaluation,
    updateJugdeInfomations
};
