import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    Title,
    FundingDetailApplicationsComponent
} from "./Report.styles";
import FundingDetailReport from '../../../../components/Common/AdminFundingDetailReport';
import Constants from "../../../../constants/Constants";
import { RoundedRowContainer } from "../../../../components/Common/CommonLayout";
import Button from "../../../../components/Common/Button";
import PopUpCreateReport from "./CreateReport";
import { ColorName } from "../../../../components/Variables";
import history from "../../../../history";

const AdminReports = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const AdminReportStorage = useSelector((state) => state.AdminFundingApplicationReport);
    const fundingID = useSelector((state) => state.FundingApplication.fundingId);
    const [applyApplicationDetail, setApplyApplicationDetail] = useState();
    const [applyApplicationReports, setApplyApplicationReports] = useState();
    const [isShowPopUpCreateReport, setIsShowPopUpCreateReport] = useState(false);

    useEffect(() => {
        if (!fundingID) return history.push("/admin/funding-applications");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoading(AdminReportStorage.isLoading);
        setApplyApplicationDetail(AdminReportStorage.applyApplicationDetail);
        setApplyApplicationReports(AdminReportStorage.applyApplicationReports);
    }, [AdminReportStorage]);

    const _renderTableContent = () => {
        if (_.isEmpty(applyApplicationReports)) {
            return (
                <RoundedRowContainer>
                    {t("general.Data Not Found")}
                </RoundedRowContainer>
            );
        }
        return _.map(applyApplicationReports, (row) => {
            const classNameStatus = _.findKey(Constants.REPORT_STATUS, _.partial(_.isEqual, row.status)) === "DUE" ? "color-status-red" : "color-status-green";
            return (
                <FundingDetailReport
                    key={row.id}
                    description={row.request_description}
                    status={_.findKey(Constants.REPORT_STATUS, _.partial(_.isEqual, row.status))}
                    dueDate={moment(row.due_date.deadline).format("YYYY-MM-DD")}
                    submitData={row.submitted_data}
                    dataUrl={row.data_url}
                    classNameStatus={classNameStatus}
                    idItemReportStatus={row.id}
                    idApplicationItem={applyApplicationDetail.id}
                />
            );
        });
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={isLoading}
            page="fundingApplications"
            content={(
                <BodyContainer>
                    {isShowPopUpCreateReport
                        && (
                            <PopUpCreateReport
                                hideModal={() => setIsShowPopUpCreateReport(false)}
                            />
                        )}
                    <Title>
                        {!_.isEmpty(applyApplicationDetail) && applyApplicationDetail.fundingApplication.title}
                    </Title>
                    <FundingDetailApplicationsComponent>
                        <div className='d-flex'>
                            <div className="w-title">
                                {t("general.Status")}
:
                            </div>
                            <span className="color-green">
                                {!_.isEmpty(applyApplicationDetail) && _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, applyApplicationDetail.status))}
                            </span>
                        </div>
                        <div className='d-flex mt-title '>
                            <div className="w-title">{t("general.Approval data")}</div>
                            <span>{!_.isEmpty(applyApplicationDetail) && moment(applyApplicationDetail.approval_date).format("YYYY-MM-DD")}</span>
                        </div>
                    </FundingDetailApplicationsComponent>
                    <Title className="report-status">
                        <span>{t("general.Report Status")}</span>
                        <Button
                            background={ColorName.fern}
                            color="white"
                            title={t("general.Create new Report")}
                            width='12em'
                            height='35px'
                            className="create-report"
                            onClick={() => setIsShowPopUpCreateReport(true)}
                            borderRadius='0'
                        />
                    </Title>
                    { _renderTableContent()}
                </BodyContainer>
            )}
        />
    );
};

export default AdminReports;
