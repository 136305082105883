import styled from 'styled-components';
import { ColorName } from "../../Variables";
import { HorizontalContainer } from "../CommonLayout";

const RowContainer = styled.tr`
    td:last-child {
        text-align: right;
        i {
            margin-right: 1em;
        }
    }
    td {
        border: 2px solid ${ColorName.gallery};
        border-style: solid none;
        padding: 5px;
    }
    td:first-child {
        border-left-style: solid;
        border-top-left-radius: 55px;
        border-bottom-left-radius: 55px;
        padding-left: 1em;
    }
    td:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 55px;
        border-top-right-radius: 55px;
    }
    &.unread{
        background: #f9ebeb;
    }
`;

const AvatarContainer = styled(HorizontalContainer)`
    align-items: center;
    width: 100%;
    p {
        margin-left: 0.5em;
    }
`;

const AvatarWrapper = styled.div`
    display: flex;
    align-items: center;
    & p{
        text-align: left;
    }
`;

const Avatar = styled.div`
    margin-right: auto;
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
    img {
        border-radius: 50%;
        width: 100%;
        object-fit: cover;
        height: 50px;
    }
`;

const Item = styled.td`
    &.for-align-status{
        text-align: -webkit-center;
        width: auto;
        white-space: nowrap;
    }
    text-align: center;
    padding: 20px;
    i {
        cursor: pointer;
    }
    &.text-left {
        text-align: left;
    }
    & img{
        height: 50px;
    }
    &.type {
        text-align: left;
        text-transform: uppercase;
    }
    &.for-action{
        width: 100px;
    }
`;

const StatusContainer = styled(HorizontalContainer)`
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export {
    RowContainer,
    Item,
    AvatarContainer,
    Avatar,
    StatusContainer,
    AvatarWrapper
};
