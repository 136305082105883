import styled from 'styled-components';
import { ColorName } from "../../Variables";

const Wrapper = styled.div`
    background: ${(props) => props.background || ColorName.screaminGreen};
    width: ${(props) => props.width || '11em'};
    padding: 5px 10px;
    border-radius: 25px;
    max-height: 30px; 
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 0.8em;
    text-transform: uppercase;
`;

const WrapperGradient = styled(Wrapper)`
    background: linear-gradient(178.43deg, ${ColorName.tickleMePink} -13.56%, ${ColorName.persianBlue} 158.3%);
`;

export {
    Wrapper,
    WrapperGradient
};
