import styled from "styled-components";

const ContainerBody = styled.div`
    min-height: 50vh;
    font-size: 1em;
    line-height: 1.5;
    p{
        font-weight: 300;
    }
    & .full-width {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
    }
    & .header-limited{
        max-width: 1140px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
    }
    &.no-header{
        min-height: 100vh;
    }
    & .text{
        & ul{
            list-style: inherit;
            padding-inline-start: 40px;
        }
        & li{
            display: list-item;
        }
        & td{
            border: 1px solid grey;
        }
        & table{
            border-collapse: collapse;
        }
    }
`;

export {
    ContainerBody
};
