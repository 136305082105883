const Constants = {
    USER_STATUS: {
        UNVERIFIED: 0,
        VERIFIED: 1,
        NOT_APPROVED: 2,
        APPROVED: 3,
        USER_UPDATE: 4,
        DELETED: 9
    },
    FUNDING_APPLICATION_STATUS: {
        INACTIVE: 0,
        CLOSED: 1,
        OPEN: 2,
        ANNOUNCED: 3
    },
    FUNDING_CATEGORY: {
        ENTREPRENEUR: 0,
        LIMITED_USER: 1
    },
    APPLY_APPLICATION_STATUS: {
        FAILED: 0,
        INCOMPLETE: 1,
        SUBMITTED: 2,
        IN_REVIEW: 3,
        APPROVED: 4,
        NOT_APPROVED: 5
    },
    ROLE_NAME: {
        SUPER_ADMIN: "Super admin",
        LIMITED_ADMIN: "Limited admin",
        ENTREPRENEUR: "Entrepreneur",
        JUDGE: "Judge",
        INVESTOR: "Investor",
        CONSULTANT: "Consultant",
        UNASSIGNED: "Unassigned",
        LIMITED_USER: "Limited user"
    },
    COMMON: {
        ACTIVE: 1,
        INACTIVE: 0
    },
    NOTIFICATION: {
        NOTIFICATION_TYPE: {
            NOTIFICATION: 0,
            RECENT_ACTIVITY: 1,
            PUBLISH: 3
        },
        NOTIFICATION_STATUS: {
            UNREAD: 0,
            READ: 1
        },
        TABLE_TYPE: {
            USER: 0,
            FUNDING_APPLICATION: 1,
            APPLY_APPLICATION: 2,
            REPORT: 3,
            MESSAGE: 4,
            EVALUATION: 5,
            APPLICATION_JUDGE: 6,
            NEWS: 7,
            EVENT: 8,
            LIBRARY: 9
        }
    },
    COLOR_STATUS: {
        GRADIENT_VALUE: ['pending', 'in progress', 'UNVERIFIED', 1],
        GREEN_VALUE: ['active', 'complete', 'VALID', 'ACTIVE', 'OPEN', 'SUBMITTED', "PUBLISHED", "APPROVED", 2, 3],
        RED_VALUE: ['down', 'failed', 'INVALID', 'INCOMPLETE', 'DUE', "DELETE", "FAILED", 'closed', 'CLOSED', "NOT APPROVED", "DELETED", "INACTIVE"],
        ORANGE_VALUE: ['VERIFIED', 'UNPUBLISHED', 'IN REVIEW'],
        BLUE_VALUE: ["USER UPDATE", "ANNOUNCED"]
    },
    TABLE_TYPE: {
        USER: 'users',
        ACTIVITY: 'activity',
        NOTIFICATION: "notification",
        APPLICATION: 'application',
        APPLICATION_OVERVIEW: 'applicationOverview',
        NOTIFICATION_USER: "notification user",
        APPLICATION_ADMIN_OVERVIEW: "applicationAdminOverview",
        JUDGE_APPLICATION: 'judgeApplication',
        APPLY_APPLICATION: 'applyApplication',
        NEWS: 'news',
        EVENT: 'event',
        LIBRARY: 'library',
        TESTIMONIAL: 'testimonial',
        DOCUMENT_TEMPLATE: 'documentTemplate',
        SPONSOR: 'sponsor',
        CRITERIA: 'criteria'
    },
    QUESTION_TYPE: {
        TEXT_INPUT: 1,
        SELECTION: 2
    },

    REPORT_STATUS: {
        DELETED: 0,
        DUE: 1,
        SUBMITTED: 2
    },

    USER_PROPERTY_TYPE: {
        INDIVIDUAL: 1,
        BUSINESS: 2,
        VENTURE_INVESTMENT: 3,
        PRIVATE_ENTITY: 4
    },
    EVENT_TYPE: {
        OFFLINE: 0,
        ONLINE: 1
    },
    NEWS_STATUS: {
        DELETED: 0,
        PUBLISHED: 1,
        UNPUBLISHED: 2
    },
    EVENT_STATUS: {
        DELETED: 0,
        PUBLISHED: 1,
        UNPUBLISHED: 2
    },
    ITEM_PER_PAGE: 10,
    ITEM_PER_PAGE_NEWS: 8,
    EMBED_YOUTUBE_URL: "https://www.youtube.com/embed/",
    LIBRARY_TYPE: {
        BOOK: 0,
        VIDEO: 1,
        PDF: 2
    },
    SPONSOR_TYPE: {
        ENTREPRENEUR: 0,
        INVESTOR: 1,
        CONSULTANT: 2
    },
    LANGUAGE_CODE: {
        EN: 'en',
        AL: 'al'
    },
    USER_PER_PAGE: 12
};

export default Constants;
