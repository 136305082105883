import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    FormItem,
    FormContainer,
    SubmitButton,
    Container,
    PageTitle
} from "./UserRegister.styles";
import GeneralCheckBoxForm from "../../../components/Common/GeneralCheckBoxForm";
import { GeneralInputForm } from "../../../components/Common/CommonLayout";
import history from "../../../history";
import Utils from "../../../libs/Utils";
import CONSTANTS from "../../../constants/Constants";

const UserDataForm = (props) => {
    const {
        selectedPage, pageList, callBackNext, page
    } = props;
    // const checkBoxRef = [];
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: ''
    });
		const [showPassword, setShowPassword] = useState(false);
		const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isHasEmptyFields, setIsHasEmptyFields] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const newUserData = { ...userData };
        if (selectedPage && selectedPage !== '1') newUserData.legal_investing_name = '';
        (page === CONSTANTS.ROLE_NAME.ENTREPRENEUR && selectedPage !== '1') ? newUserData.identity_type = 'Registered startup' : newUserData.identity_type = 'Individual with a business idea';
        setUserData(newUserData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const back = () => {
        history.push('/homepage/register');
    };
	
		const showPasswordHandle = () => {
			setShowPassword(!showPassword)
		};
		
		const showConfirmPasswordHandle = () => {
			setShowConfirmPassword(!showConfirmPassword)
		}

    const next = () => {
        let flag = false;
        _.map(userData, (data) => {
            if (!data) flag = true;
        });
        if (flag) {
            Utils.popupAlert({ title: t("alert.Warning"), text: t('alert.Found empty field(s)'), type: 'warning' });
            setIsHasEmptyFields(true);
            return;
        }
        if (userData.password !== userData.password_confirmation) return Utils.popupAlert({ title: t("alert.Warning"), text: t("validation.Password and confim password is incorrect"), type: 'warning' });
        if (userData.password.length < 6) return Utils.popupAlert({ title: t("alert.Warning"), text: t('validation.Password needs at least 6 characters'), type: 'warning' });
        setIsHasEmptyFields(false);
        callBackNext(userData);
    };

    const _renderListCheck = () => {
        const render = _.map(pageList, (page, index) => {
            return (
                <div key={index} className={page.pageID !== selectedPage ? 'disable' : ''}>
                    <span>
                        <GeneralCheckBoxForm
                            name={`checkPage${index}`}
                            value={page.pageID}
                            defaultChecked={page.pageID === selectedPage}
                            className={page.pageID !== selectedPage ? 'disable' : ''}
                        />
                        <p>{page.name}</p>
                    </span>
                </div>
            );
        });
        return render;
    };

    const returnTextPage = () => {
        if ((selectedPage === "1") && page === CONSTANTS.ROLE_NAME.INVESTOR) return t("join.What is the name of the investing individual?");
        if ((selectedPage === "2" || selectedPage === "3") && page === CONSTANTS.ROLE_NAME.INVESTOR) return t("join.What is the legal name of the investing entity?");
        if (selectedPage === "2" && page === CONSTANTS.ROLE_NAME.ENTREPRENEUR) return t("join.What is the legal name of the registered startup?");
        return t("join.What is the legal name of the private entity?");
    };

    const returnForm = () => {
        if ((selectedPage === "1" && page === CONSTANTS.ROLE_NAME.INVESTOR)
            || ((selectedPage === "2" || selectedPage === "3") && page === CONSTANTS.ROLE_NAME.INVESTOR)
            || (selectedPage === "2" && page === CONSTANTS.ROLE_NAME.ENTREPRENEUR)
            || (selectedPage === "4" && page === CONSTANTS.ROLE_NAME.CONSULTANT)) {
            return (
                <FormItem>
                    <p className="form-question">{returnTextPage()}</p>
                    <GeneralInputForm placeholder={t("general.Name")} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, legal_investing_name: e.target.value })} />
                    {(isHasEmptyFields && !userData.legal_investing_name) && (
                        <p className="hidden-text-red">
                        *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
            );
        }
    };

    return (
        <Container>
            <PageTitle>
                <p className="page-title-header">{t(`general.${page.toUpperCase()}`)}</p>
                <div className="footer-checkbox line-header">
                    {selectedPage && _renderListCheck()}
                </div>
            </PageTitle>
            <FormContainer>
                <FormItem>
                    <p className="form-question">{t("join.What is your name?")}</p>
                    <GeneralInputForm placeholder={t("general.Name")} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, first_name: e.target.value })} />
                    {(isHasEmptyFields && !userData.first_name) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <GeneralInputForm placeholder={t("general.Surname")} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, last_name: e.target.value })} />
                    {(isHasEmptyFields && !userData.last_name) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                {returnForm()}
                <FormItem>
                    <p className="form-question">{t("join.Create a password")}</p>
		                <div className='wrapper-password'>
	                    <GeneralInputForm type={showPassword ? 'text' : 'password'} placeholder={t("general.Password")} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, password: e.target.value })} />
			                <i className={`far ${showPassword ? 'fa-eye' : 'fa-eye-slash'} icon-eye`} onClick={() => showPasswordHandle()} />
		                </div>
                    {(isHasEmptyFields && !userData.password) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
	                  <div className='wrapper-password'>
                      <GeneralInputForm type={showConfirmPassword ? 'text' : 'password'} placeholder={t("join.Re-enter password")} margin='0 0 0.5em 0' width="98.5%" onChange={(e) => setUserData({ ...userData, password_confirmation: e.target.value })} />
		                  <i className={`far ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'} icon-eye`} onClick={() => showConfirmPasswordHandle()} />
	                  </div>
                    {(isHasEmptyFields && !userData.password_confirmation) && (
                        <p className="hidden-text-red">
                            *
                            {t("general.This field can not empty")}
                        </p>
                    )}
                    <p className="line" />
                </FormItem>
                <div>
                    <SubmitButton className="bg-white" onClick={() => back()}>
                        {t("general.Back")}
                    </SubmitButton>
                    <SubmitButton className="ml-2" onClick={() => next()}>
                        {t("general.Next")}
                    </SubmitButton>
                </div>
            </FormContainer>
        </Container>
    );
};

UserDataForm.propTypes = {
    pageList: PropTypes.array,
    selectedPage: PropTypes.string,
    callBackNext: PropTypes.func,
    page: PropTypes.string
};

export default UserDataForm;
