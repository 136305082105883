import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Title,
  NavigationBarContainer,
  Tab,
  Tabs,
  AddUserTab,
} from "./NavigationBar.styles";
import history from "../../../../history";
import FundingApplicationActions from "../../../../actions/FundingApplication";

const FundingApplicationsNavigationBar = (props) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [selectedTab, setSelectedTab] = useState("all");
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle(props.title);
    props.selectedTab(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const switchTap = (tap) => {
    setSelectedTab(tap);
  };
  const createNewFundingApplication = (page) => {
    dispatch(FundingApplicationActions.setActionForCreateFunding("create"));
    history.push(page);
  };

  return (
    <NavigationBarContainer className="forFundingApplication">
      <Title>{t(`fundingApplications.${title}`)}</Title>
      <Tabs className="tabForFundingApplication">
        <Tab
          className={
            selectedTab === "all" || selectedTab === "" ? "selected-tap" : ""
          }
          onClick={() => switchTap("all")}
        >
          {t("general.All")}
        </Tab>
        <Tab
          className={selectedTab === "closed" ? "selected-tap" : ""}
          onClick={() => switchTap("closed")}
        >
          {t("general.Closed")}
        </Tab>
        <Tab
          className={selectedTab === "open" ? "selected-tap" : ""}
          onClick={() => switchTap("open")}
        >
          {t("general.Open")}
        </Tab>
        <Tab
          className={selectedTab === "announced" ? "selected-tap" : ""}
          onClick={() => switchTap("announced")}
        >
          {t("general.Announced")}
        </Tab>
        <AddUserTab
          onClick={() => createNewFundingApplication("new-funding-application")}
        >
          {t("general.Create")}
        </AddUserTab>
      </Tabs>
    </NavigationBarContainer>
  );
};

FundingApplicationsNavigationBar.propTypes = {
  title: PropTypes.string,
  selectedTab: PropTypes.func,
  createNewFundingApplication: PropTypes.func,
};

export default FundingApplicationsNavigationBar;
