import React from 'react';
import PropTypes from "prop-types";
import {
    AvatarWrapper
} from "./Avatar.styles";

const Avatar = (props) => {
    const { userData, size } = props;

    const _getAvatar = (user) => {
        if (user.userData) return user.userData.url_avatar_aws;
    };

    const avatarPath = _getAvatar(userData) !== null ? _getAvatar(userData) : "https://awodev.com/images/default-forum-user.png";

    return (
        <AvatarWrapper avatarURL={avatarPath} className={size} />
    );
};

Avatar.propTypes = {
    userData: PropTypes.object
};

export default Avatar;
