import styled from 'styled-components';
import {
    VerticalContainer, HorizontalContainer, BreadCrumb, GeneralButton
} from "../../../../components/Common/CommonLayout";
import { ColorName } from '../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .container-button{
        display: flex;
        flex-direction: row-reverse;
    }
`;
const WrapperUserDetail = styled(HorizontalContainer)`
    align-items: center;
    margin-top: 2em;
    justify-content: end;
`;

const WrapperInput = styled(VerticalContainer)`
    & .file-attach{
        display: flex;
        & .remove{
            display: flex;
            width: 15px;
            height: 15px;
            font-size: 1em;
            background: #737373;
            padding: 3px;
            border-radius: 50%;
            align-items: center;
            place-content: center;
            cursor: pointer;
            margin-left: 5px;
            color: white;
        }
    }
    flex: 1;
    display: flex;
    margin-bottom: 2em;
    & .hidden{
        display: none;
    }
    & .hidden-content{
        opacity: 0;
        position: absolute;
        top: -500%;
        width: 100%;
    }
    &.content{
        min-height: 300px;
    }
    & .inputCalendar{
        border-radius: 0;
        border: 1px solid ${ColorName.chathamsBlue};
        padding: 5px 10px;
    }
    & select{
        width: 50%;
        padding: 5px 10px;
        outline: none;
    }
    & .style-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
    }
    & .title-select{
        margin-left: 1em;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
    }
    & .jodit-wysiwyg{
        min-height: 300px !important;
    }
    & #uploadAvatar{
        display: none;
    }
    & .display-image-file{
        & img{
            max-width: 400px;
        }
    }
    &.container-upload{
        flex-direction: row;
        & .fa-upload{
            padding: 0 1em;
            &:hover{
                cursor: pointer;
            }
        }
        & .fa-paperclip{
            padding: 0 1em;
            &:hover{
                cursor: pointer;
            }
        }
        & .display-image-file{
            position: relative;
            display: flex;
            font-weight: 600;
            color: white;
            border: 1px solid lightGray;
            & .remove{
                position: absolute;
                top: -10px;
                right: -10px;
                display: flex;
                width: 15px;
                height: 15px;
                font-size: 1em;
                background: #737373;
                padding: 3px;
                border-radius: 50%;
                align-items: center;
                place-content: center;
                cursor: pointer;
            }
        }
    }
`;

const TitleInput = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${ColorName.chathamsBlueDark};
    display: flex;
    justify-content: space-between;
    & .language{
        display: flex;
        align-items: center;
    }
    & .switch{
        margin-right: 1em;
    }
`;

const InputContainer = styled.input`
    background: none;
    border: 1px solid ${ColorName.chathamsBlue};
    height: auto;
    width: ${(props) => props.width || "50%"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 5px 10px;
    outline: none;
`;
const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: ${ColorName.white};
    border-radius: 10px;
    border: 2px solid ${ColorName.gallery};
    position: relative;
    margin-left: ${(props) => props.marginLeft || "0px"};
    flex: 1;
`;

const GeneralButtonLanguage = styled(GeneralButton)`
    border: 1px solid ${ColorName.chathamsBlueDark};    
    margin-right: 1em;
    background: ${ColorName.white};
    color: ${ColorName.chathamsBlueDark};
    &.active{
        background: ${ColorName.chathamsBlueDark};
        color: ${ColorName.white};
        &.disable{
            color: ${ColorName.white};
            background: #ccc;
            border: 1px solid #ccc;  
            cursor: not-allowed;
        }
    }
    &.disable-unclick{
        background: none;
        border: 1px solid #ccc;  
        color: #ccc;
        cursor: not-allowed;
    }
`;

export {
    BodyContainer,
    InputContainer,
    TitleInput,
    WrapperInput,
    WrapperUserDetail,
    ProgressBar,
    GeneralButtonLanguage
};
