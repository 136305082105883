import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    HeaderContainer,
    TableContainer,
    ButtonWrapper
} from "./FundingApplications.styles";
import { RoundedRowContainer } from "../CommonLayout";
import Button from "../Button";
import UserOverviewActions from "../../../actions/UserOverview";
import Loading from "../../Layout/CommonLayoutPart/Loading";
import Constants from "../../../constants/Constants";
import history from "../../../history";
import FundingApplicationUserActions from "../../../actions/FundingApplicationsUser";
import { ColorName } from "../../Variables";
import Utils from "../../../libs/Utils";

const FundingApplications = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [userData, setUserData] = useState([]);
    const [showNumberItems, setShowNumberItems] = useState(4);

    const FundingApplicationStorage = useSelector((state) => state.UserOverview);

    useEffect(() => {
        const data = Utils.convertSnakeKeyToCamel(Utils.getSavedUserData());
        if (!_.isEmpty(data)) {
            setUserData(_.assign(data, data.userData, data.userData.extraDataJson));
            dispatch(UserOverviewActions.getFundingApplicationDashboard({ currentId: _.get(data, 'userId'), role: _.get(data, 'role.name') }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoading(FundingApplicationStorage.isLoading);
    }, [FundingApplicationStorage]);

    const getApplicationUserByIDRequest = (id) => {
        dispatch(FundingApplicationUserActions.getOpenApplicationUserByIDRequestWithRedirect(id));
    };

    const switchPage = (row) => {
        const rowData = _.find(row.applyApplications, (o) => { return o.user_id === userData.userId; });
        if (!rowData && row.isApplied) {
            return Utils.popupAlert({ title: "Oops...", text: "Your application submitted has been failed.", type: "error" });
        }
        if (_.isEmpty(row.applyApplications)) return getApplicationUserByIDRequest(row.id);

        if (rowData) {
            const applyApplicationId = {
                apply_application_id: rowData.id
            };
            if (_.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, rowData.status)) === _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, 3))) {
                history.push({
                    state: { applicationID: rowData.id, applyApplicationID: applyApplicationId },
                    pathname: "user-funding-report"
                });
            }
            if (_.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, rowData.status)) === _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, 2))
                || _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, rowData.status)) === _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, 1))
                || _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, rowData.status)) === _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, 4))
            ) {
                dispatch(FundingApplicationUserActions.getApplyApplicationByIDRequest(rowData.id));
                history.push("user-funding-detail");
            }
        } else {
            dispatch(FundingApplicationUserActions.getOpenApplicationUserByIDRequest(row.id));
            history.push("user-applications-detail");
        }
    };

    const renderTableContent = () => {
        if (_.isEmpty(FundingApplicationStorage.dataFundingApplication)) {
            return (
                <tr>
                    <td>
                        {t("general.Data Not Found")}
                        !
                    </td>
                </tr>
            );
        }
        if (!_.isEmpty(userData) && (userData.role.name === Constants.ROLE_NAME.INVESTOR || userData.role.name === Constants.ROLE_NAME.CONSULTANT)) {
            const render = _.map(FundingApplicationStorage.dataFundingApplication, (row, index) => {
                if (index < showNumberItems) {
                    return (
                        <tr key={index}>
                            <td>
                                {row.title}
                            </td>
                            <td>
                                {t(`rounderTable.${_.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status))}`)}
                            </td>
                            <td>
                                {row.submitted}
                            </td>
                            {row.isApplied === true && (
                                <td>
                                    <Button background="lightgray" title={t('general.Applied')} onClick={() => console.log("isApplied")} />
                                </td>
                            )}
                            {row.isApplied !== true && _.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status)) === "ANNOUNCED" && (
                                <td>
                                    <Button background='lightgray' title={t('general.Announced')} onClick={() => console.log("ANNOUNCED")} />
                                </td>
                            )}
                            {row.isApplied !== true && _.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status)) === "OPEN" && (
                                <td>
                                    <Button background='lightgray' title={t('general.Open')} onClick={() => console.log("OPEN")} />
                                </td>
                            )}
                            {row.isApplied !== true && _.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status)) === "CLOSED" && (
                                <td>
                                    <Button background="lightgray" title={t('general.Closed')} onClick={() => console.log("CLOSE")} />
                                </td>
                            )}
                        </tr>
                    );
                }
            });
            return render;
        } else {
            const render = _.map(FundingApplicationStorage.dataFundingApplication, (row, index) => {
                if (index < showNumberItems) {
                    return (
                        <tr key={index}>
                            <td>
                                {row.title}
                            </td>
                            <td>
                                {t(`rounderTable.${_.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status))}`)}
                            </td>
                            {(!_.isEmpty(userData) && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) && (
                                <td>
                                    {row.submitted}
                                </td>
                            )}
                            {row.isApplied === true && (
                                <td>
                                    <Button background={ColorName.pelorous} title={t('general.Applied')} onClick={() => switchPage(row)} />
                                </td>
                            )}
                            {row.isApplied !== true && _.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status)) === "ANNOUNCED" && (
                                <td>
                                    <Button background={ColorName.chathamsBlueDark} title={t('general.Announced')} onClick={() => switchPage(row)} />
                                </td>
                            )}
                            {row.isApplied !== true && _.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status)) === "OPEN" && (
                                <td>
                                    <Button background={ColorName.chathamsBlueDark} title={t('general.Open')} onClick={() => getApplicationUserByIDRequest(row.id)} />
                                </td>
                            )}
                            {row.isApplied !== true && _.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, row.status)) === "CLOSED" && (
                                <td>
                                    <Button background={ColorName.wellRead} title={t('general.Closed')} onClick={() => console.log("CLOSE")} />
                                </td>
                            )}
                        </tr>
                    );
                }
            });

            return render;
        }
    };

    const clickViewAllActivity = () => {
        setShowNumberItems(showNumberItems + 4);
    };

    return (
        <RoundedRowContainer direction='column' padding='50px'>
            {isLoading && <Loading />}
            <HeaderContainer>
                {_.isEmpty(FundingApplicationStorage.dataFundingApplication)
                    ? '' : (<p>{t("fundingApplications.funding opportunities")}</p>)}
            </HeaderContainer>
            {_.isEmpty(FundingApplicationStorage.dataFundingApplication)
                ? t("general.Data Not Found") : (
                    <TableContainer>
                        <thead>
                            <tr>
                                <td>{t("general.Name")}</td>
                                <td>{t("general.Status")}</td>
                                {(!_.isEmpty(userData) && userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) && (
                                    <td>{t("general.Submitted")}</td>
                                )}
                                <td />
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableContent()}
                        </tbody>
                    </TableContainer>
                )}
            {
                !_.isEmpty(FundingApplicationStorage.dataFundingApplication) && FundingApplicationStorage.dataFundingApplication.length > showNumberItems
                && (
                    <ButtonWrapper>
                        <Button
                            background={ColorName.chathamsBlueDark}
                            title={t("general.View All")}
                            width='10em'
                            height='35px'
                            className="mt-2"
                            onClick={() => clickViewAllActivity()}
                        />
                    </ButtonWrapper>
                )
            }
        </RoundedRowContainer>
    );
};

FundingApplications.propTypes = {

};

export default FundingApplications;
