import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  WrapperNav,
  WrapperContentExplore,
  UserCardContainer,
  ProfilePicture,
  UserInfomations,
  Infomation,
  GeneralButtonUser
} from "./UserExplore.styles";
import NavigationBar from "../../../../components/Layout/PortalTemplate/NavigationBar";
import UserOverviewActions from "../../../../actions/UserOverview";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import Pagination from "../../../../components/Common/NewPagination";
import PopupUserExplore from "../Overview/PopupUserExplore";
import Utils from "../../../../libs/Utils";
import { ColorName } from "../../../../components/Variables";
import Constants from "../../../../constants/Constants";
import UserDetailPopup from "../../../../components/Layout/PortalTemplate/UserDetailPopup";

const UserExplore = () => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const UserExploreStorage = useSelector((state) => state.UserOverview);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [currentTab, setCurrentTab] = useState('all');
  const [isShowPopUpSendMessage, setIsShowPopSendMessage] = useState(false);
  const [selectedExplore, setSelectedExplore] = useState();
  const userData = Utils.getSavedUserData();
  const [isShowUserDetailPopup, setIsShowUserDetailPopup] = useState(false);
  const [selectedUserData, setDelectedUserData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const dataQuery = {
      page,
      item_per_page: Constants.USER_PER_PAGE
    };
    dispatch(UserOverviewActions.getUserExploreRequest(dataQuery));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(UserExploreStorage.isLoading);
    setData(UserExploreStorage.dataUserExplore.data);
    setPage(UserExploreStorage.dataUserExplore.page);
    setLastPage(UserExploreStorage.dataUserExplore.lastPage);
  }, [UserExploreStorage]);

  useEffect(() => {
    const dataQuery = {
      page,
      item_per_page: Constants.USER_PER_PAGE,
      filter: currentTab
    };
    dispatch(UserOverviewActions.getUserExploreRequest(dataQuery));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const getCurrentTab = (tab) => {
    setPage(1);
    setCurrentTab(tab);
  };

  const currentTabPanel = (tab) => {
    setCurrentTab(tab);
  };

  const openPopUpSendMessage = (dataExpolre) => {
    setSelectedExplore(dataExpolre);
    setIsShowPopSendMessage(true);
  };

  const showUserDetail = (row) => {
    setDelectedUserData(row);
    setIsShowUserDetailPopup(true);
  };

  const _getSecondTooltip = (row) => {
    if (row.userData.extra_data_json) {
      if (row.role.name === Constants.ROLE_NAME.CONSULTANT && row.userData.extra_data_json.about_me) return row.userData.extra_data_json.about_me;
      if (row.role.name === Constants.ROLE_NAME.ENTREPRENEUR && row.userData.extra_data_json.about_me) return row.userData.extra_data_json.about_me;
      if (row.role.name === Constants.ROLE_NAME.INVESTOR && row.userData.extra_data_json.about_me) return row.userData.extra_data_json.about_me;
    }
    return null;
  };

  const renderTableContent = () => {
    const render = _.map(data, (row, index) => {
      if (row.status !== Constants.USER_STATUS.USER_UPDATE) {
        return (
          <UserCardContainer key={index}>
            <ProfilePicture>
              <img alt='profile' src={row.userData.url_avatar_aws ? row.userData.url_avatar_aws : "https://awodev.com/images/default-forum-user.png"} />
              <p>
                {row.role.name}
              </p>
            </ProfilePicture>
            <UserInfomations>
              <Infomation>
                <div className="styled-user" data-tooltip-text={!_.isEmpty(row) && row.userData.full_name}>
                  {!_.isEmpty(row) && (
                    Utils.truncateText(_.get(row.userData, "full_name", ""), 27)
                  )}
                </div>
              </Infomation>
              <Infomation />
              <Infomation>
                <div
                  className={`${row.role.name === Constants.ROLE_NAME.ENTREPRENEUR ? "styled-interests" : "styled-description"}`}
                  data-tooltip-text={_getSecondTooltip(row)}
                >
                  <span>
                    {row.role.name === Constants.ROLE_NAME.CONSULTANT && t("general.About me")}
                    {row.role.name === Constants.ROLE_NAME.ENTREPRENEUR && t("general.About me")}
                    {row.role.name === Constants.ROLE_NAME.INVESTOR && t("general.About me")}
                    :
                    {" "}
                  </span>
                  {(row.role.name === Constants.ROLE_NAME.CONSULTANT) && (Utils.truncateText(_.get(row.userData.extra_data_json, "about_me", ""), 50))}
                  {(row.role.name === Constants.ROLE_NAME.ENTREPRENEUR) && (Utils.truncateText(_.get(row.userData.extra_data_json, "about_me", ""), 50))}
                  {(row.role.name === Constants.ROLE_NAME.INVESTOR) && (Utils.truncateText(_.get(row.userData.extra_data_json, "about_me", ""), 50))}
                </div>
              </Infomation>
            </UserInfomations>
            <div className="container-button">
              <GeneralButtonUser type="button" className={i18n.language === "en" ? "for-more bor-ra-25" : "for-more bor-ra-25 for-albania"} background={ColorName.chathamsBlue} value={t("homepageUser.More")} onClick={() => showUserDetail(row)} />
              <GeneralButtonUser
                type="button"
                className={i18n.language === "en" ? "bor-ra-25 position-contact-me" : "bor-ra-25 position-contact-me for-albania"}
                background='white'
                color={ColorName.chathamsBlue}
                value={t("general.Contact me")}
                height="40px"
                onClick={() => openPopUpSendMessage(row)}
              />
            </div>
          </UserCardContainer>
        );
      }
    });
    return render;
  };

  const clickPageOrLink = (currentPage) => {
    const dataQuery = {
      page: currentPage,
      item_per_page: Constants.USER_PER_PAGE,
      filter: currentTab
    };
    dispatch(UserOverviewActions.getUserExploreRequest(dataQuery));
  };

  const callBackPrev = () => {
    setPage(page - 1);
    clickPageOrLink(page - 1);
  };

  const callBackNext = () => {
    setPage(page + 1);
    clickPageOrLink(page + 1);
  };

  const pageOnclick = (e) => {
    clickPageOrLink(_.parseInt(e));
  };

  const _renderPagination = () => {
    return (
      <Pagination page={page} lastPage={lastPage || 1} callBackPrev={callBackPrev} callBackNext={callBackNext} pageOnclick={pageOnclick} />
    );
  };

  return (
    <DefaultLayout
      userRole={userData.role.name ===  Constants.ROLE_NAME.JUDGE ? "judge" : "user"}
      isLoading={isLoading}
      page="UserExplore"
      isPagination={true}
      content={(
        <BodyContainer>
          {isLoading && <Loading />}
          {isShowUserDetailPopup && (<UserDetailPopup userDetail={selectedUserData} hideModal={() => setIsShowUserDetailPopup(false)} />)}
          {isShowPopUpSendMessage
            && (
              <PopupUserExplore
                hideModal={() => setIsShowPopSendMessage(false)}
                dataItem={selectedExplore}
              />
            )}
          <div className="header-explore">{t("general.Explore")}</div>
          <WrapperNav>
            <NavigationBar type='user over view' selectedTab={currentTabPanel} title="" getCurrentTab={(tab) => getCurrentTab(tab)} />
          </WrapperNav>
          <WrapperContentExplore>
            {renderTableContent()}
          </WrapperContentExplore>
          {_renderPagination()}
        </BodyContainer>
      )}
    />
  );
};

UserExplore.propTypes = {

};

export default UserExplore;
