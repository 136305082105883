import styled from 'styled-components';
import { VerticalContainer } from "../../../components/Common/CommonLayout";

const BodyContainer = styled(VerticalContainer)`
    & .ml-1 {
        margin-left: 1em
    }
    & .mb-2 {
        padding: 0px;
        margin-bottom: 2em;
    }
`;

export {
    BodyContainer
};
