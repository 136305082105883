import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Content,
    Title,
    Fields,
    Field,
    InputContainerNewLayout
} from "./Authentication.styles";
import ResetPasswordActions from "../../../actions/ResetPassword";
import Button from "../../../components/Common/ButtonFrontEnd";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import UserPageTemplateV1 from "../../../components/Layout/PortalTemplate";
import Validation from "../../../libs/Validation";
import Utils from "../../../libs/Utils";
import Constants from "../../../constants/Constants";
const ChangePassword = () => {
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.User.isLoading);
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
		const [showPassword, setShowPassword] = useState(false);
		const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const userData = Utils.getSavedUserData();

    const checkRole = () => {
        if (userData) {
            if (userData.role.name ===  Constants.ROLE_NAME.SUPER_ADMIN) return "admin";
            if (userData.role.name ===  Constants.ROLE_NAME.JUDGE) return "judge";
            if (userData.role.name ===  Constants.ROLE_NAME.LIMITED_ADMIN) return "limited-admin";
            return "user";
        }
    };

    const inputPassword = (e) => {
        setPassword(e.target.value);
    };

    const confirmPassword = (e) => {
        setConfirmPass(e.target.value);
    };

    const validateForm = () => {
        const validationRules = [
            {
                name: t("general.Password"),
                value: password,
                rule: ["required", { min: 6 }]
            },
            {
                name: t("general.Confirm Password"),
                value: confirmPass,
                rule: ["required", { min: 6 }]
            },
            {
                name: t("general.Confirm Password"),
                value: confirmPass,
                rule: "equal",
                ruleValue: password
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const changePassword = () => {
        let flag = true;
        flag = validateForm();
        if (flag) {
            const data = {
                password,
                password_confirmation: confirmPass
            };
            dispatch(ResetPasswordActions.changePasswordUser(data));
        }
    };

    const onKeyEnterPress = (e) => {
        if (e.which === 13) {
            changePassword();
        }
    };
	
		const showPasswordHandle = () => {
			setShowPassword(!showPassword)
		};
		
		const showConfirmPasswordHandle = () => {
			setShowConfirmPassword(!showConfirmPassword)
		}

    return (
        <UserPageTemplateV1
            userRole={checkRole()}
            page="userOverview"
            isLoading={false}
            content={(
                <Content>
                    {isLoading && <Loading />}
                    <Title>{t("general.Change Password")}</Title>
                    <Fields>
                        <Field>
                            <InputContainerNewLayout className='align-center'>
                                <input type={showPassword ? 'text' : 'password'} placeholder={t("general.New Password")} onChange={(e) => inputPassword(e)} onKeyPress={onKeyEnterPress} />
	                              <i className={`far ${showPassword ? 'fa-eye' : 'fa-eye-slash'} icon-eye`} onClick={() => showPasswordHandle()} />
                            </InputContainerNewLayout>
                        </Field>
                        <Field>
                            <InputContainerNewLayout className='align-center'>
                                <input type={showConfirmPassword ? 'text' : 'password'} placeholder={t("general.Confirm New Password")} onChange={(e) => confirmPassword(e)} onKeyPress={onKeyEnterPress} />
	                              <i className={`far ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'} icon-eye`} onClick={() => showConfirmPasswordHandle()} />
                            </InputContainerNewLayout>
                        </Field>
                    </Fields>
                    <Fields>
                        <div className="line" />
                        <Field className="alignCenter">
                            <Button
                                background='gradient'
                                name={t("general.Submit")}
                                height="40px"
                                width="6em"
                                onClick={() => changePassword()}
                            />
                        </Field>
                    </Fields>
                </Content>
            )}
        />
    );
};

export default ChangePassword;
