import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import SearchBar from "../../../components/Common/SearchBar";
import DefaultLayout from '../../../components/Layout/GuestTemplate/DefaultLayout';
import {
    TitleCommunity,
    Result,
    MainContent,
    ContainerListCard,
    Sponsor,
    ItemSponsor,
    ContainerFullWidthCommunity
} from './Entrepreneurs.styles';
import CompanyCard from '../../../components/Common/CompanyCard';
import Banner from '../../../components/Common/Banner';
import { Images } from '../../../themes';
import NavigationBar from '../../../components/Common/NavigationBar';
import SummaryOverview from '../../../components/Common/SummaryOverview';
import CONSTANTS from "../../../constants/Constants";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import CommunityActions from "../../../actions/Community";
import SponsorActions from "../../../actions/Sponsor";

const Community = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const refMain = useRef();
    const isLoading = useSelector((state) => state.Community.isLoading);
    const communityData = useSelector((state) => state.Community.communityData);
    const guestAnnouncementSetting = useSelector(
        (state) => state.GuestAnnouncementSetting
    );
    const listSponsor = useSelector((state) => state.Sponsor.sponsors);
    const [searchIdea, setSearchIdea] = useState(false);
    const [withFilter, setWithFilter] = useState(false);

    const listNav = [
        {
            title: t("general.What We Do"),
            isActive: false,
            path: "/homepage/entrepreneurs/what-we-do"
        },
        {
            title: t("general.Community"),
            isActive: true,
            path: "/homepage/entrepreneurs/community"
        },
        {
            title: t("general.Criteria"),
            isActive: false,
            path: "/homepage/entrepreneurs/criteria"
        },
        {
            title: t("general.Process"),
            isActive: false,
            path: "/homepage/entrepreneurs/process"
        },
        {
            title: t("general.Apply"),
            isActive: false,
            path: "/homepage/entrepreneurs/apply"
        }
    ];

    useEffect(() => {
        const param = { role_name: CONSTANTS.ROLE_NAME.ENTREPRENEUR, property_type: CONSTANTS.USER_PROPERTY_TYPE.BUSINESS };
        dispatch(CommunityActions.fetchCommunity(param));
        const data = {
            type: CONSTANTS.SPONSOR_TYPE.ENTREPRENEUR
        };
        dispatch(SponsorActions.getAllSponsor(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (guestAnnouncementSetting.isFilter) {
            setSearchIdea(guestAnnouncementSetting.isFilter);
            setWithFilter(guestAnnouncementSetting.isFilter);
            const param = { role_name: CONSTANTS.ROLE_NAME.ENTREPRENEUR, is_business_idea: guestAnnouncementSetting.isFilter, property_type: CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL };
            dispatch(CommunityActions.fetchCommunity(param));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guestAnnouncementSetting]);

    const _renderListCard = () => {
        return (
            <ContainerListCard>
                { _.map(communityData, (data) => {
                    return <CompanyCard data={data} key={data.id} renderWithAvatar={searchIdea} />;
                })}
            </ContainerListCard>
        );
    };

    const filterByBusinessIdea = (e) => {
        if (e) {
            const param = { role_name: CONSTANTS.ROLE_NAME.ENTREPRENEUR, is_business_idea: guestAnnouncementSetting.isFilter, property_type: CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL };
            dispatch(CommunityActions.fetchCommunity(param));
            setSearchIdea(e);
            setWithFilter(e);
            return;
        }
        const param = { role_name: CONSTANTS.ROLE_NAME.ENTREPRENEUR, property_type: CONSTANTS.USER_PROPERTY_TYPE.BUSINESS };
        dispatch(CommunityActions.fetchCommunity(param));
        setSearchIdea(false);
        setWithFilter(false);
    };

    const _renderItemSponsor = () => {
        const render = _.map(listSponsor.data, (item, index) => {
            return <ItemSponsor key={index}><img src={item.url_img_aws} alt={item.name} /></ItemSponsor>;
        });
        return render;
    };

    const _renderSponsor = () => {
        return (
            <Sponsor className="display-sponsor">
                {_renderItemSponsor()}
            </Sponsor>
        );
    };

    const _renderSponsorSmallScreen = () => {
        const render = _.map(listSponsor.data, (item, index) => {
            return <div className="item-small-screen" key={index}><img src={item.url_img_aws} alt={item.name} /></div>;
        });
        return render;
    };

    const _renderMain = () => {
        return (
            <MainContent>
                {isLoading && <Loading />}
                <Banner
                    backgroundImg={Images.banner2}
                    title={t("homepageUser.Join our community of Entrepreneurs")}
                    description={t("homepageUser.Access funds and knowledge from a rich and diverse pool of investors and consultants")}
                />
                <NavigationBar listNav={listNav} />
                <ContainerFullWidthCommunity>
                    {_renderSponsor()}
                </ContainerFullWidthCommunity>
                <div ref={refMain} className="community">
                    <div className="content-min-width">
                        <TitleCommunity>
                            {t("homepageUser.Here you will find a list of startups and business ideas, part of our community")}
                        </TitleCommunity>
                        <SearchBar
                            pageType='entrepreneurs'
                            roleName={CONSTANTS.ROLE_NAME.ENTREPRENEUR}
                            noDropdown={searchIdea}
                            filterBy={filterByBusinessIdea}
                            withFilter={withFilter}
                        />
                        <Result>
                            {communityData.length}
                            {' '}
                            {t("general.Results found")}
                        </Result>
                        {_renderListCard()}
                    </div>
                </div>
                <div className="sponsor-small-screen">
                    {_renderSponsorSmallScreen()}
                </div>
                <SummaryOverview />
            </MainContent>
        );
    };
    return (
        <DefaultLayout context={_renderMain()} currentPage="entrepreneur" currentTop={true} />
    );
};

export default Community;
