import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  Container,
  GridColHomepage,
  Title,
  FlexGridSixthTemplate,
} from "./News.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import NewsActions from "../../../actions/News";
import SummryOverview from "../../../components/Common/SummaryOverview";
import Pagination from "../../../components/Common/NewPagination";
import history from "../../../history";
import SlickCarouselNews from "../../../components/Common/SlickCarouselNews";
import Constants from "../../../constants/Constants.js";
// import Utils from "../../../libs/Utils.js";

const News = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const listNews = useSelector((state) => state.News.news);
  const listNewsSlide = useSelector((state) => state.News.newSlide);
  const isLoading = useSelector((state) => state.News.isLoading);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  // const [language] = useState(Utils.getCurrentLanguage());
  useEffect(() => {
    const data = {
      page: 1,
      item_per_page: Constants.ITEM_PER_PAGE_NEWS,
      filter: Constants.NEWS_STATUS.PUBLISHED,
      language: i18n.language,
    };
    dispatch(NewsActions.getAllNews(data));
    const dataWithLanguage = {
      language: i18n.language,
    };
    dispatch(NewsActions.getNewsSlide(dataWithLanguage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = {
      page,
      item_per_page: Constants.ITEM_PER_PAGE_NEWS,
      filter: Constants.NEWS_STATUS.PUBLISHED,
      language: i18n.language,
    };
    dispatch(NewsActions.getAllNews(data));
    const dataWithLanguage = {
      language: i18n.language,
    };
    dispatch(NewsActions.getNewsSlide(dataWithLanguage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const readNews = (id) => {
    history.push({
      search: `?id=${id}`,
      pathname: "/homepage/news/detail",
    });
  };

  const _renderRowNews = () => {
    return _.map(listNews.data, (item, index) => {
      if (index < 8) {
        return (
          <GridColHomepage colSize="3" gridTemplate="6" key={index}>
            <div
              className={index % 2 === 0 ? "item-card-left" : "item-card"}
              onClick={() => readNews(item.id)}
            >
              {index % 2 !== 0 && (
                <div className="item-avatar">
                  <img
                    src={
                      item.url_img_aws ||
                      "https://via.placeholder.com/245x120.png"
                    }
                    alt="feather"
                  />
                </div>
              )}
              <div
                className={
                  index % 2 === 0 ? "item-content-left" : "item-content"
                }
              >
                <div
                  className={
                    index % 2 === 0
                      ? "flex-1 item-title justify-end"
                      : "flex-1 item-title"
                  }
                >
                  {`${item.title.substr(0, 50)}${
                    item.title.split("").length < 50 ? "" : "..."
                  }`}
                </div>
                <div
                  className={
                    index % 2 === 0
                      ? "flex-1 item-type justify-end"
                      : "flex-1 item-type"
                  }
                >
                  {item.type}
                </div>
                <div
                  className={
                    index % 2 === 0
                      ? "flex-1 item-date-created justify-end"
                      : "flex-1 item-date-created"
                  }
                >
                  {item.created_at}
                </div>
                <div
                  className={
                    index % 2 === 0
                      ? "flex-1 item-button justify-end"
                      : "flex-1 item-button"
                  }
                >
                  <div
                    className="button-read-more"
                    onClick={() => readNews(item.id)}
                  >
                    {t("general.READ MORE")}
                  </div>
                </div>
              </div>
              {index % 2 === 0 && (
                <div className="item-avatar">
                  <img
                    src={
                      item.url_img_aws ||
                      "https://via.placeholder.com/245x120.png"
                    }
                    alt="feather"
                  />
                </div>
              )}
            </div>
          </GridColHomepage>
        );
      }
    });
  };

  const _renderContent = () => {
    return (
      <div className="content-page">
        <FlexGridSixthTemplate>
          <GridColHomepage colSize="6" gridTemplate="6">
            <Title>{t("general.News")}</Title>
            {_.isEmpty(listNews.data) && (
              <GridColHomepage colSize="6" gridTemplate="6" key="0">
                {t(
                  "news.There is no News for the moment, please comeback later"
                )}
              </GridColHomepage>
            )}
            <div className="description">
              <SlickCarouselNews listData={listNewsSlide} type="news" />
            </div>
          </GridColHomepage>
        </FlexGridSixthTemplate>
        {!_.isEmpty(listNews.data) && (
          <div>
            <div className="line-page" />
            <div className="row-news">
              <FlexGridSixthTemplate>{_renderRowNews()}</FlexGridSixthTemplate>
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    setPage(listNews.page);
    setLastPage(listNews.lastPage);
  }, [listNews]);

  const clickPageOrLink = (currentPage) => {
    const data = {
      page: currentPage,
      item_per_page: Constants.ITEM_PER_PAGE_NEWS,
    };
    dispatch(NewsActions.getAllNews(data));
  };

  const callBackPrev = () => {
    setPage(page - 1);
    clickPageOrLink(page - 1);
  };

  const callBackNext = () => {
    setPage(page + 1);
    clickPageOrLink(page + 1);
  };

  const pageOnclick = (e) => {
    clickPageOrLink(_.parseInt(e));
  };

  const _renderPagination = () => {
    return (
      <Pagination
        page={page}
        lastPage={lastPage || 1}
        callBackPrev={callBackPrev}
        callBackNext={callBackNext}
        pageOnclick={pageOnclick}
      />
    );
  };

  const _renderSummaryOverview = () => {
    return <SummryOverview />;
  };

  const _renderMain = () => {
    return (
      <Container>
        {_renderContent()}
        {_renderPagination()}
        {_renderSummaryOverview()}
      </Container>
    );
  };
  return (
    <DefaultLayout
      context={_renderMain()}
      currentPage="news"
      isLoading={isLoading}
    />
  );
};

News.propTypes = {};

export default News;
