import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    Container,
    PageTitle
} from "../UserRegister.styles";
import DefaultLayout from "../../../../components/Layout/GuestTemplate/DefaultLayout";
import Banner from "../../../../components/Common/Banner";
import CONSTANTS from "../../../../constants/Constants";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import userRegisterActions from "../../../../actions/UserRegister";
import Individual from "./Individual";
import PrivateEntity from "./PrivateEntity";
import Form from "../UserDataForm";

const Consultant = () => {
    const { t } = useTranslation();
    const pageType = {
        1: "INDIVIDUAL",
        4: "PRIVATE ENTITY"
    };
    const pageList = [
        {
            name: t("general.INDIVIDUAL"),
            pageID: '1'
        },
        {
            name: t("general.PRIVATE ENTITY"),
            pageID: '4'
        }
    ];
    const dispatch = useDispatch();
    const location = useLocation();
    const isLoading = useSelector((state) => state.UserRegister.isLoading);
    const [type, setType] = useState('');
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (location.state) setType(location.state.type);
        dispatch(userRegisterActions.getUserRoles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderMain = () => {
        return (
            <div>
                {isLoading && <Loading />}
                <Banner type='join' />
                {!_.isEmpty(userData) && (
                    <Container>
                        <PageTitle>
                            <p className="page-title-header">{t("general.CONSULTANT")}</p>
                            <p className="page-title-footer">
                                {t(`general.${pageType[type]}`)}
                            </p>
                        </PageTitle>
                        {type === CONSTANTS.USER_PROPERTY_TYPE.INDIVIDUAL.toString() && <Individual data={userData} />}
                        {type === CONSTANTS.USER_PROPERTY_TYPE.PRIVATE_ENTITY.toString() && <PrivateEntity data={userData} />}
                    </Container>
                )}
                {_.isEmpty(userData) && <Form pageList={pageList} selectedPage={type} callBackNext={(data) => setUserData(data)} page={CONSTANTS.ROLE_NAME.CONSULTANT} />}
            </div>
        );
    };

    return (
        <DefaultLayout context={renderMain()} />
    );
};

export default Consultant;
