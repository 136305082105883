import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import history from "../../../../history";
import {
  HeaderFrontEndContainer,
  Logo,
  HeaderContent,
  HeaderAuthen,
  HeaderMenu,
  TopMenu,
  BottomMenu,
  ItemMenu,
  MobileMenu,
  ChildMenu,
  ContentLang,
  Container,
} from "./HeaderFrontEnd.styles";
import logo from "../../../../assets/images/logo.png";
import Button from "../../../Common/ButtonFrontEnd";
import { ColorName } from "../../../Variables";
import IconDiamond from "../../../Common/IconDiamond";
import { ContentBodyContainer } from "../../../Common/CommonLayout";
import useOutsideClick from "./ClickOutside";
import Utils from "../../../../libs/Utils";
import LoginActions from "../../../../actions/Authentication";
import DropDownLanguage from "../../../Common/DropDownLanguage";
import CONSTANTS from "../../../../constants/Constants";
import SearchAction from "../../../../actions/Search";

const HeaderFrontEnd = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isShowMenuMobile, setIsShowMenuMobile] = useState(false);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const isLogged = useSelector((state) => state.Authentication.isLogged);
  const valueSearch = useSelector((state) => state.SearchHomepage.search);
  const [userData, setUserData] = useState({});
  const currentLang = Utils.getCurrentLanguage();
  const [search, setSearch] = useState("");
  const ref = useRef();
  const toggle = () => {
    setIsShowDropdown(false);
  };
  useOutsideClick(ref, () => {
    toggle();
  });

  useEffect(() => {
    const getUserData = Utils.getSavedUserData();
    setUserData(getUserData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (valueSearch !== "") {
      setSearch(valueSearch);
    }
  }, [valueSearch]);

  const logout = () => {
    return dispatch(LoginActions.logout());
  };

  const _logo = () => {
    return (
      <Logo>
        <img
          src={logo}
          alt="logo"
          className="logo"
          onClick={() => history.push("/homepage")}
        />
      </Logo>
    );
  };

  const redirectToUserPage = () => {
    if (userData.role.name === CONSTANTS.ROLE_NAME.SUPER_ADMIN)
      history.push("/admin/overview");
    if (userData.role.name === CONSTANTS.ROLE_NAME.LIMITED_ADMIN)
      history.push("/limited-admin/overview");
    if (
      userData.role.name === CONSTANTS.ROLE_NAME.ENTREPRENEUR ||
      userData.role.name === CONSTANTS.ROLE_NAME.INVESTOR ||
      userData.role.name === CONSTANTS.ROLE_NAME.CONSULTANT
    )
      history.push("/user-overview");
    if (userData.role.name === CONSTANTS.ROLE_NAME.JUDGE)
      history.push("/judge/applications");
  };

  const _headerAuthen = () => {
    if (!isLogged) {
      return (
        <HeaderAuthen>
          <Button
            onClick={() => history.push("/authentication")}
            name={t("general.Sign In")}
          />
          <div className="space" />
          <Button
            onClick={() => history.push("/homepage/register")}
            background={ColorName.chathamsBlueDark}
            color={ColorName.white}
            name={t("general.Join")}
            width={currentLang === CONSTANTS.LANGUAGE_CODE.EN ? "6em" : "10em"}
          />
          <ContentLang>
            <DropDownLanguage />
          </ContentLang>
        </HeaderAuthen>
      );
    }
    if (!_.isEmpty(userData)) {
      return (
        <HeaderAuthen>
          <Button
            name={userData.userData.first_name}
            onClick={() => redirectToUserPage()}
          />
          <div className="space" />
          <Button
            onClick={() => logout()}
            background={ColorName.chathamsBlueDark}
            color={ColorName.white}
            name={t("general.Logout")}
            width={currentLang === CONSTANTS.LANGUAGE_CODE.EN ? "6em" : "10em"}
          />
          <ContentLang>
            <DropDownLanguage flag={false} />
          </ContentLang>
        </HeaderAuthen>
      );
    }
    return <HeaderAuthen />;
  };

  const _renderMenuMobileItem = () => {
    const { currentPage } = props;
    return (
      <div className="container-menu">
        <div
          className={
            currentPage === "entrepreneur" ? "itemMenu active" : "itemMenu"
          }
          onClick={() => history.push("/homepage/entrepreneurs/what-we-do")}
        >
          {t("general.For Entrepreneurs")}
        </div>
        <div
          className={
            currentPage === "investor" ? "itemMenu active" : "itemMenu"
          }
          onClick={() => history.push("/homepage/investors/what-we-do")}
        >
          {t("general.For Investors")}
        </div>
        <div
          className={
            currentPage === "consultant" ? "itemMenu active" : "itemMenu"
          }
          onClick={() => history.push("/homepage/consultants/what-we-do")}
        >
          {t("general.For Consultants")}
        </div>
        <div
          className={currentPage === "about" ? "itemMenu active" : "itemMenu"}
          onClick={() => history.push("/homepage/about")}
        >
          {t("general.About Us")}
        </div>
        <div
          className={currentPage === "library" ? "itemMenu active" : "itemMenu"}
          onClick={() => history.push("/homepage/library")}
        >
          {t("general.Library")}
        </div>
        <div
          className={currentPage === "events" ? "itemMenu active" : "itemMenu"}
          onClick={() => history.push("/homepage/events")}
        >
          {t("general.Events")}
        </div>
        <div
          className={currentPage === "news" ? "itemMenu active" : "itemMenu"}
          onClick={() => history.push("/homepage/news")}
        >
          {t("general.News")}
        </div>
        <div
          className={currentPage === "faq" ? "itemMenu active" : "itemMenu"}
          onClick={() => history.push("/homepage/faq")}
        >
          {t("general.FAQs")}
        </div>
        {!isLogged && (
          <div className="itemMenu">
            <Button
              onClick={() => history.push("/authentication")}
              name={t("general.Sign In")}
            />
            <div className="space" />
            <Button
              onClick={() => history.push("/homepage/register")}
              background={ColorName.chathamsBlueDark}
              color={ColorName.white}
              name={t("general.Join")}
              width={
                currentLang === CONSTANTS.LANGUAGE_CODE.EN ? "6em" : "10em"
              }
            />
            <ContentLang>
              <DropDownLanguage flag={false} />
            </ContentLang>
          </div>
        )}
        {isLogged && (
          <div className="itemMenu">
            <Button
              name={userData.userData.first_name}
              onClick={() => redirectToUserPage()}
            />
            <div className="space" />
            <Button
              onClick={() => logout()}
              background={ColorName.chathamsBlueDark}
              color={ColorName.white}
              name={t("general.Logout")}
              width={
                currentLang === CONSTANTS.LANGUAGE_CODE.EN ? "6em" : "10em"
              }
            />
            <ContentLang>
              <DropDownLanguage flag={false} />
            </ContentLang>
          </div>
        )}
      </div>
    );
  };

  const _renderMobileMenu = () => {
    return (
      <MobileMenu>
        {isShowMenuMobile && _renderMenuMobileItem()}
        <i
          className={!isShowMenuMobile ? "fas fa-bars" : "fas fa-times"}
          onClick={() => setIsShowMenuMobile(!isShowMenuMobile)}
        />
      </MobileMenu>
    );
  };

  const _headerMenu = () => {
    const { currentPage } = props;
    return (
      <HeaderMenu>
        <TopMenu>
          <ItemMenu
            className={currentPage === "entrepreneur" ? "active" : ""}
            onClick={() => history.push("/homepage/entrepreneurs/what-we-do")}
          >
            {t("general.For Entrepreneurs")}
          </ItemMenu>
          <IconDiamond />
          <ItemMenu
            className={currentPage === "investor" ? "active" : ""}
            onClick={() => history.push("/homepage/investors/what-we-do")}
          >
            {t("general.For Investors")}
          </ItemMenu>
          <IconDiamond />
          <ItemMenu
            className={currentPage === "consultant" ? "active" : ""}
            onClick={() => history.push("/homepage/consultants/what-we-do")}
          >
            {t("general.For Consultants")}
          </ItemMenu>
        </TopMenu>
        <BottomMenu>
          <ItemMenu
            className={currentPage === "about" ? "active" : ""}
            onClick={() => history.push("/homepage/about")}
          >
            {t("general.About Us")}
          </ItemMenu>
          <IconDiamond />
          <ItemMenu
            ref={ref}
            className={
              currentPage === "news" || currentPage === "events"
                ? "relative active"
                : "relative"
            }
            onClick={() => setIsShowDropdown(!isShowDropdown)}
          >
            {t("general.News")} & {t("general.Events")}
            <ChildMenu className={!isShowDropdown ? "display-none" : ""}>
              <div
                className="child-item"
                onClick={() => history.push("/homepage/news")}
              >
                {t("general.News")}
              </div>
              <div
                className="child-item"
                onClick={() => history.push("/homepage/events")}
              >
                {t("general.Events")}
              </div>
            </ChildMenu>
          </ItemMenu>
          <IconDiamond />
          <ItemMenu
            className={currentPage === "library" ? "active" : ""}
            onClick={() => history.push("/homepage/library")}
          >
            {t("general.Library")}
          </ItemMenu>
          <IconDiamond />
          <ItemMenu
            className={currentPage === "faq" ? "active" : ""}
            onClick={() => history.push("/homepage/faq")}
          >
            {t("general.FAQs")}
          </ItemMenu>
        </BottomMenu>
        {_renderMobileMenu()}
      </HeaderMenu>
    );
  };

  const _headerContent = () => {
    return (
      <HeaderContent>
        {_headerAuthen()}
        {_headerMenu()}
      </HeaderContent>
    );
  };

  const getResult = () => {
    const data = {
      keyword: search,
    };
    if (search === "") return Utils.popupAlert({ title: "warning", text: "Search is required", type: "warning" });
    dispatch(SearchAction.setValueSearch(search));
    dispatch(SearchAction.searchHomepage(data));
    history.push("/homepage/search");
  };

  const onKeyEnterPress = (e) => {
    if (e.which === 13) {
      getResult();
    }
  };

  return (
    <ContentBodyContainer className="w-100">
      <HeaderFrontEndContainer>
        {_logo()}
        {_headerContent()}
      </HeaderFrontEndContainer>
      <HeaderFrontEndContainer className="search">
        <Container className="for-search">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="search-bar"
            onKeyPress={onKeyEnterPress}
          />
          <i className="fas fa-search" onClick={() => getResult()} />
        </Container>
      </HeaderFrontEndContainer>
    </ContentBodyContainer>
  );
};

HeaderFrontEnd.propTypes = {
  currentPage: PropTypes.string,
};

export default HeaderFrontEnd;
