import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    isConfirm: false,
    isRegister: false
};

export default (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ActionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                isRegister: true
            };
        case ActionTypes.RESET_IS_REGISTER:
            return {
                ...state,
                isRegister: false
            };
        case ActionTypes.CONFIRM_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                isConfirm: true
            };
        case ActionTypes.REGISTER_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.REGISTER_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
};
