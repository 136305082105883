import styled from 'styled-components';

const AvatarWrapper = styled.div`
    width: 50px;
    height: 50px;
    background: url(${(props) => (props.avatarURL) || 'https://awodev.com/images/default-forum-user.png'});
    background-size: cover;
    border-radius: 2em;
    background-repeat: no-repeat;
    
    &.small {
        width: 40px;
        height: 40px;
    }
`;

export {
    AvatarWrapper
};
