import Api from "./Base";

export function exportExcell(data) {
    return Api.get("/apply_application_excel", data);
}

export function exportUserExcell(data) {
    return Api.get("user/exportExcelUser", data);
}

export function exportAllUserExcell(data) {
    return Api.get("user/exportExcelUser", data);
}
