import styled from 'styled-components';
import { ColorName } from "../../Variables";
import {
    GeneralButton
} from "../CommonLayout";

const Container = styled.div`
    width: 24%;
    min-width: 240px;
    font-size: 1em; 
    font-weight: 300;
    &.no-image{
        margin: 0 !important;
        width: 100%;
        border: none !important;
        border-top: 1px solid ${ColorName.pictonBlue} !important;
        & .container-button{
            justify-content: flex-end;
        }
        & .information-company{
            & .name-company{
                display: flex;
                flex-direction: column;
                height: 100%;
                & .detail{
                    height: 100%;
                }
            }
            & .description{
                margin-top: 0px;
                height: 100%;
                color: ${ColorName.fern};
                text-transform: uppercase;
            }
        }
    }
    &.container-card{
        margin: 1em 0.2em;
        color: ${ColorName.chathamsBlueDark};
        font-weight: 400;
        display: flex;
        flex-direction: column;
        border: 2px solid ${ColorName.chathamsBlueDark};
        & .logo{
            background: black;
            color: white;
            font-size: 4em;
            text-align: center;
            min-height: 150px;
            img {
                width: 100%;
                max-height: 150px;
                object-fit: contain;
            }
        }
        & .information-company{
            padding: 1em;
            font-size: 1em;
            line-height: 2.2;
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            & .email-company{
                padding: 0 0 1em 0;
                font-weight: 600;
            }
            & [data-tooltip-text]:hover {
                overflow:visible;
            }
            & [data-tooltip-text]:hover:after {
                background-color: #000000;
                background-color: rgba(0, 0, 0, 0.8);
                align-items: center;
                justify-content: center;
                right: 0px;
                -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
                -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
                box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
                margin-top: -30px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                color: #FFFFFF;
                font-size: 12px;
                content: attr(data-tooltip-text);
                margin-bottom: 10px;
                left: 0;
                padding: 7px;
                position: absolute;
                width: 95%;
                min-width: 50px;
                max-width: 300px;
                word-wrap: break-word;
                z-index: 9999;
            }
        }
        & .name-company{
            font-size: 1em;
            display: flex;
            justify-content: space-between;
            & span{
                display: flex;
                align-items: center;
                line-height: 1.2;
                &.full-name{
                    margin-right: auto;
                    padding-right: 5px;
                    font-weight: 600;
                    color: ${ColorName.fern};
                    text-transform: uppercase;
                }
                &.year{
                    padding-left: 5px;
                }
                &.year:before{
                    content: "•";
                    color: ${ColorName.chathamsBlueDark};
                    margin-right: 5px;
                }
            }
        }
        & .description{
            color: ${ColorName.black};
            font-weight: 600;
            text-transform: capitalize;
            height: 40px;
            line-height: 1;
            margin-top: 0.8em;
            position: relative;
            & .hover {
                color: ${ColorName.white};
                display: none;
                position: absolute;
                top: 40px;
                background: black;
                padding: 1em;
                z-index: 9;
                text-transform: capitalize;
                line-height: 1.2;
            }
            &:hover {
                & .hover {
                    display: block;
                }
            }
        }
        & .detail{
            font-size: 0.8em;
            line-height: 1.5;
            word-wrap: break-word;
            &.no-height{
                height: unset;
            }
        }
        @media (max-width: 376px) {
            width: 100%;
        }
    }
    & .container-button{
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        padding-top: 0.5em;
    }
`;

const GeneralButtonUser = styled(GeneralButton)`
    font-weight: 500;
    font-size: 1em;
    &:first-child{
        margin-right: 0.5em;
    }
    min-width: unset;
    padding: 0.2em 0.8em;
    border: 1px solid ${ColorName.chathamsBlue};
`;

export {
    Container,
    GeneralButtonUser
};
