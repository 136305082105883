import { Component } from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import Utils from "../libs/Utils";
import API from '../api';
import history from "../history";
import AuthenticationActions from "../actions/Authentication";
// import UserActions from "../actions/UserOverview";

class ValidationRoute extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        const { pathname } = history.location;
        if (!pathname.includes("homepage")) {
            this._checkAuthentication();
        } else {
            this._checkAuthenticationForGuestPortal();
        }
    }

    static async getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userData) {
            const oldData = Utils.getSavedUserData();
            const newData = nextProps.userData;
            if (newData && oldData && !_.isEqual(oldData, newData)) {
                Utils.updateSavedUserData("status", _.get(nextProps.userData, "status", oldData.status));
            }
        }
        return null;
    }

    _checkAuthenticationForGuestPortal() {
        const { setUserLogged } = this.props;
        Utils.authenticationValidate({
            found: (access_token) => {
                // const savedUserData = Utils.getSavedUserData();
                // getUserData(savedUserData.id);
                API.setToken(access_token);
                setUserLogged(true);
            },
            notFound: () => {
            }
        });
    }

    _checkAuthentication() {
        const { pathname } = history.location;
        const { setUserLogged } = this.props;
        if (pathname.includes('admin')) {
            Utils.authenticationValidate({
                found: (access_token) => {
                    const userData = Utils.getSavedUserData();
                    if (userData.role.name.includes("admin")) {
                        API.setToken(access_token);
                        setUserLogged(true);
                    } else {
                        history.push("/access-denied");
                    }
                },
                notFound: () => {
                    history.push("/authentication");
                }
            });
        } else {
            Utils.authenticationValidate({
                found: (access_token) => {
                    // const savedUserData = Utils.getSavedUserData();
                    // getUserData(savedUserData.id);
                    API.setToken(access_token);
                    setUserLogged(true);
                },
                notFound: () => {
                    history.push("/homepage");
                }
            });
        }
    };

    render() {
        return "";
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.UserOverview.selectedExplore
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserLogged: (isLogged) => dispatch(AuthenticationActions.isLogged(isLogged)),
        // getUserData: (userID) => dispatch(UserActions.getUserExploreById(userID))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationRoute);
