import React from "react";
import {
    LandingPageContainer
} from './LandingPage.styles';

const LandingPage = () => {
    return (
        <LandingPageContainer />
    );
};

export default LandingPage;
