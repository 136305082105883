import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer } from '../../../../components/Common/CommonLayout';
import { ColorName } from '../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .ml-1 {
        margin-left: 1em
    }
    & .mb-2 {
        margin-bottom: 2em;
    }
    & .mt-2 {
        margin-top: 2em;
    }
`;

const Report = styled(HorizontalContainer)`
    flex: 1;
    margin-bottom: 3em;
`;

const BlockReport = styled.div`
    margin-right: 40px;
    flex: 1
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: ${ColorName.chathamsBlueDark};
    &.mt-title {
        margin-top: 3em
    }
    &.mb-title {
        margin-bottom: 2em
    }
`;

export {
    BodyContainer,
    Report,
    BlockReport,
    Title
};
