import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";
import i18n from "../i18n";

const requestHasError = () => {
    return {
        type: ActionTypes.USER_REGISTER_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.USER_REGISTER_REQUEST
    };
};

const requestIsSuccess = () => {
    return {
        type: ActionTypes.USER_REGISTER_SUCCESS
    };
};

const setUserRoles = (payload) => {
    return {
        type: ActionTypes.USER_REGISTER_SET_USER_ROLE,
        payload
    };
};

const generateLinkSuccess = (payload) => {
    return {
        type: ActionTypes.GENERATE_LINK_SUCCESS,
        payload
    };
};

const userRegister = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.userRegister(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestIsSuccess());
                    Utils.popupAlert({
                        title: i18n.t("alert.A verification link has been sent to your email account"),
                        text: i18n.t("alert.Please click on the link that has just been sent to your email account to verify your email and continue the registration process"),
                        type: "success"
                    }).then((redirect) => { if (redirect) history.push("/homepage/register"); });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const getUserRoles = () => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getUserRoles()
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(setUserRoles(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const limitedUserRegister = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.limitedUserRegister(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestIsSuccess());
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    history.push("/homepage/add-limited-user");
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const generateLink = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.generateLink(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(generateLinkSuccess(res.data.data));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    userRegister,
    getUserRoles,
    limitedUserRegister,
    generateLink
};
