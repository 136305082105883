import styled from "styled-components";
import { Images } from '../../../themes';

const LandingPageContainer = styled.div`
    background: url(${Images.landingScreen});
    width: 100%;
    height: 100vh;
    background-size: cover;
`;

export {
    LandingPageContainer
}