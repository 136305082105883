import _ from "lodash";
import ActionTypes from "../constants/ActionTypes";
import Constants from "../constants/Constants";
import MetaTypes from "../constants/MetaTypes";
import API from "../api";
import Utils from "../libs/Utils";
import i18n from "../i18n";

const requestHasError = () => {
    return {
        type: ActionTypes.GUEST_PRIVACY_POLICY_SETTING_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.GUEST_PRIVACY_POLICY_SETTING_REQUEST
    };
};

const getPrivacyPolicySettingDataSuccess = (payload, isMultipleLanguage) => {
    return {
        type: ActionTypes.GUEST_PRIVACY_POLICY_SETTING_SUCCESS,
        payload,
        isMultipleLanguage
    };
};

const updatePrivacyPolicySettingDataSuccess = (payload) => {
    return {
        type: ActionTypes.GUEST_PRIVACY_POLICY_SETTING_SUCCESS,
        payload
    };
};

const getPrivacyPolicySettingData = () => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.getMetaData({ type: MetaTypes.GUEST_PRIVACY_POLICY })
            .then((res) => {
                if (res.data.status === true) {
                    const metaDataEN = _.get(res, `data.data.data_json[${Constants.LANGUAGE_CODE.EN}]`, null);
                    const metaDataAL = _.get(res, `data.data.data_json[${Constants.LANGUAGE_CODE.AL}]`, null);
                    const isMultipleLanguage = _.get(res, `data.data.is_support_mul_lang`, null);
                    dispatch(getPrivacyPolicySettingDataSuccess({ en: metaDataEN, al: metaDataAL }, isMultipleLanguage));
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const updatePrivacyPolicySettingData = (formPayload) => {
    formPayload.append("type", MetaTypes.GUEST_PRIVACY_POLICY);
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.updateMetaDataWithForm(formPayload)
            .then((res) => {
                if (res.data.status === true) {
                    const metaData = _.get(res, 'data.data.data_json.privacyPolicyContent', null);
                    dispatch(updatePrivacyPolicySettingDataSuccess(metaData));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Oops...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    getPrivacyPolicySettingData,
    updatePrivacyPolicySettingData
};
