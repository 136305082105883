import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  Report,
  BlockReport,
  Title
} from "./Funding.styles";
import OpenApplication from "../../../../components/Common/UserFundingOpenApplication";
import FundingApplicationUserActions from "../../../../actions/FundingApplicationsUser";
import UserFundingMyApplication from '../../../../components/Common/UserFundingMyApplication';
import history from '../../../../history';
import Utils from '../../../../libs/Utils';
import ProgressBar from "../../../../components/Common/ProgressBar";
import Constants from '../../../../constants/Constants';

const UserFunding = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const FundingStorage = useSelector((state) => state.FundingApplicationsUser);
  const [dataFundingUserReport, setDataFundingUserReport] = useState(0);

  const [userData] = useState(Utils.getSavedUserData());

  const checkRoleUser = () => {
    if (userData.role.name ===  Constants.ROLE_NAME.UNASSIGNED) {
      return 'add-view-user';
    }
    return true;
  };
  useEffect(() => {
    const check = checkRoleUser();
    if (check === 'add-view-user') {
      return history.push('add-view-user');
    } else {
      dispatch(FundingApplicationUserActions.getFundingUserReportRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(FundingStorage.isLoading);
    setDataFundingUserReport(FundingStorage.dataFundingUserReport);
  }, [FundingStorage]);

  const scrollVertical = (title) => {
    window.location.href = `user-funding${title}`;
  };

  return (
    <DefaultLayout
      userRole='user'
      isLoading={isLoading}
      page="userFunding"
      content={(
        <BodyContainer>
          <Report>
            <BlockReport>
              <ProgressBar
                value={dataFundingUserReport.my_application_amount || 0}
                title={t("general.My Funding Applications")}
                callBackAction={() => scrollVertical("#my-applications")}
                filter="user overview"
                total={100}
              />
            </BlockReport>
            {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER && (
              <BlockReport>
                <ProgressBar
                  value={dataFundingUserReport.funding_application_amount || 0}
                  title={t("fundingApplications.Available Funding Opportunities")}
                  callBackAction={() => scrollVertical("#apply-applications")}
                  filter="user overview"
                  total={100}
                />
              </BlockReport>
            )}
          </Report>
          <Title id="my-applications">
            {t("general.My Funding Applications")}
          </Title>
          <UserFundingMyApplication />
          <Title id="apply-applications">
            {t("fundingApplications.Available Funding Opportunities")}
          </Title>
          <OpenApplication />
        </BodyContainer>
      )}
    />
  );
};

export default UserFunding;
