import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AdminOverViewAction from "../../../../actions/AdminOverView";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import { BodyContainer, HorizontalContainerProgress } from "./Overview.styles";
import {
  BreadCrumb,
  PageTitle,
} from "../../../../components/Common/CommonLayout";
import ProgressBar from "../../../../components/Common/ProgressBar";
import RoundedTable from "../../../../components/Common/RoundedTable";
import CONSTANTS from "../../../../constants/Constants";
import history from "../../../../history";

const AdminOverview = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const listUsers = useSelector((state) => state.AdminOverView.listUsers);
  const reportUser = useSelector((state) => state.AdminOverView.reportUser);
  const activities = useSelector((state) => state.AdminOverView.allActivities);
  const listFundingApplication = useSelector(
    (state) => state.FundingApplication.allFundingData
  );
  const columns = ["type", "user name", "email", "account status", "action"];
  const columnsActivity = ["date", "activity", "status", "action"];
  const columnsApplication = [
    "name",
    "applications status",
    "submitted",
    "action",
  ];
  const isLoading = useSelector((state) => state.AdminOverView.isLoading);
  const isLoadingFunding = useSelector((state) => state.FundingApplication.isLoading);
  const listOptions = {
    1: "view",
    2: "Send Message",
  };
  const listOptionsFunding = {
    1: "view",
    2: "edit",
  };
  const listOptionsNotification = {
    1: "view",
    2: "delete",
  };

  useEffect(() => {
    async function fetchData() {
      await dispatch(AdminOverViewAction.reportUsers());
      await dispatch(AdminOverViewAction.getListFundingApplicationAdminWithLoading());
      await dispatch(AdminOverViewAction.getListUsersAdmin({ page: 1, item_per_page: 6 }));
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callBackAction = async (action, filter) => {
    await dispatch(AdminOverViewAction.usersWithFilter(filter));
    history.push("users");
  };

  const clickViewAllUser = () => {
    history.push("users");
  };

  const clickViewAllFundingApplication = () => {
    history.push("funding-applications");
  };

  const clickViewAllActivity = () => {
    history.push("/admin/notifications");
  };

  return (
    <DefaultLayout
      userRole="admin"
      isLoading={isLoading || isLoadingFunding}
      page="adminOverview"
      content={
        <BodyContainer>
          <BreadCrumb>
            {t("overview.Agent Management System Overview")}
          </BreadCrumb>
          <PageTitle>{t("general.Overview")}</PageTitle>
          <HorizontalContainerProgress className="mb-2 progress">
            <ProgressBar
              value={reportUser.business_amount}
              title={t("overview.Total Number Of Entrepreneur")}
              callBackAction={callBackAction}
              filter="business"
              total={reportUser.all_account_amount}
            />
            <ProgressBar
              value={reportUser.investor_amount}
              title={t("overview.Total Number Of Investors")}
              callBackAction={callBackAction}
              filter="investor"
              total={reportUser.all_account_amount}
            />
            <ProgressBar
              value={reportUser.account_verified_amount}
              title={t("overview.Pending Approval")}
              callBackAction={callBackAction}
              filter="pending"
              total={reportUser.all_account_amount}
            />
            <ProgressBar
              value={reportUser.consultant_amount}
              title={t("overview.Total Number of Consultants")}
              callBackAction={callBackAction}
              filter="consultant"
              total={reportUser.all_account_amount}
            />
          </HorizontalContainerProgress>
          <RoundedTable
            className="fundingApplicationTableOverview"
            initialData={listFundingApplication}
            columns={columnsApplication}
            tableTitle={t("general.Funding Applications")}
            tableType={CONSTANTS.TABLE_TYPE.APPLICATION_ADMIN_OVERVIEW}
            listOptions={listOptionsFunding}
            buttonTilte={t("general.View More")}
            handleClick={clickViewAllFundingApplication}
            showNumberItems={6}
          />
          <RoundedTable
            initialData={listUsers.data}
            columns={columns}
            tableTitle={t("general.Users")}
            tableType={CONSTANTS.TABLE_TYPE.USER}
            listOptions={listOptions}
            className="mt-2"
            buttonTilte={t("general.View More")}
            handleClick={clickViewAllUser}
            showNumberItems={6}
          />
          <RoundedTable
            initialData={activities.data || []}
            columns={columnsActivity}
            tableTitle={t("general.Recent Activity")}
            tableType={CONSTANTS.TABLE_TYPE.NOTIFICATION}
            className="mt-2"
            buttonTilte={t("general.View More")}
            handleClick={clickViewAllActivity}
            showNumberItems={6}
            listOptions={listOptionsNotification}
          />
        </BodyContainer>
      }
    />
  );
};

AdminOverview.propTypes = {};

export default AdminOverview;
