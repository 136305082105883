import ActionTypes from "../constants/ActionTypes";

const initialState = {
    isSuccess: false,
    hasError: false,
    isLoading: false,
    listApplications: [],
    applyApplication: {},
    applyById: {},
    evaluationQuestionnaire: [],
    evaluateID: 0
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.GET_APPLICATIONS_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                listApplications: payload
            };
        case ActionTypes.GET_JUDGE_APPLY_APPLICATION_BY_ID:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                applyApplication: payload
            };
        case ActionTypes.JUDGE_FAILURE:
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        case ActionTypes.JUDGE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.GET_APPLY_BY_ID:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                applyById: payload
            };
        case ActionTypes.CHANGE_STATUS_APPLY_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                listApplications: payload
            };
        case ActionTypes.GET_EVALUATION_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                evaluationQuestionnaire: payload
            };
        case ActionTypes.GET_EVALUATION_QUESTIONNAIRE_BY_ID_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                evaluationQuestionnaire: payload
            };
        case ActionTypes.SET_EVALUATION_ID:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                evaluateID: payload
            };
        case ActionTypes.RESET_EVALUATION_ID:
            return {
                ...state,
                isSuccess: true,
                hasError: false,
                isLoading: false,
                evaluationQuestionnaire: [],
                evaluateID: 0
            };

        default:
            return state;
    }
};
