import styled from 'styled-components';
import { ColorName } from "../../Variables";

const TableContainer = styled.table`
    border-collapse: separate;
    border-spacing: 0 1em;
    thead {
        tr {
            text-align: left;
            font-weight: bold;
            td {
                color: ${ColorName.chathamsBlueDark}
            }
            
        }
    }
    tbody {
        tr {
            text-align: left;
            font-weight: 500;
            
            & .name {
                width: 25%;
                padding-right: 1em;
                vertical-align: text-top;
                font-weight: 500;
                color: ${ColorName.black}
            }
            
             & .description {
                padding-right: 1em;
                width: 45%;
            }
            
            & .deadline {
                vertical-align: text-top;
            }
            & .button-action {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
`;

export {
    TableContainer
};
