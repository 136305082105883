import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
    Wrapper,
    ProgressBar,
    WrapperQuestionnaire,
    DetailTitle
} from "./Evaluation.styles";
import {
    BreadCrumb
} from "../../../../components/Common/CommonLayout";
import AdminOverViewActions from '../../../../actions/AdminOverView';
import history from "../../../../history";
import Utils from "../../../../libs/Utils";
import Button from "../../../../components/Common/Button";
import { ColorName } from "../../../../components/Variables";

const ApplyApplicationDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.AdminOverView.isLoading);
    const evaluationApply = useSelector((state) => state.AdminOverView.evaluationApply);
    const applyId = useSelector((state) => state.AdminOverView.applyId);

    useEffect(() => {
        if (applyId === 0) return history.push("/admin/funding-applications");
        const data = {
            apply_application_id: applyId
        };
        dispatch(AdminOverViewActions.getEvaluationApply(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyId]);

    useEffect(() => {
        const data = {
            apply_application_id: applyId
        };
        dispatch(AdminOverViewActions.getEvaluationApply(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _renderFiles = (files) => {
        const render = _.map(files, (file, index) => {
            return (
                <DetailTitle key={file}>
                    <a href={file}>{Utils.getNameFileUpload(index)}</a>
                </DetailTitle>
            );
        });
        return render;
    };

    const _renderEvalutejudge = (evalute) => {
        return (
            <>
                <DetailTitle className="title-name">
Evaluated by:  &nbsp;
                    {evalute.applicationJudge.user.userData.full_name}
                </DetailTitle>
                <DetailTitle>
                    {t("general.Total")}
: &nbsp;
                    {' '}
                    {evalute.total_point}
                </DetailTitle>
                <DetailTitle>
                    {t("general.Document for evaluation")}
:
                </DetailTitle>
                {_renderFiles(evalute.document_evaluation_json)}
            </>
        );
    };

    const _renderForJudges = () => {
        if (evaluationApply) {
            const render = _.map(evaluationApply, (evalute, index) => {
                return (
                    <WrapperQuestionnaire key={index}>
                        {_renderEvalutejudge(evalute)}
                    </WrapperQuestionnaire>
                );
            });
            return render;
        }
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={isLoading}
            page="fundingApplications"
            content={(
                <Wrapper>
                    <BreadCrumb>
                        {t("fundingApplications.Application evaluation")}
                    </BreadCrumb>
                    <ProgressBar className="mb-2">
                        {_renderForJudges()}
                        <Button
                            color={ColorName.white}
                            background={ColorName.chathamsBlueDark}
                            title={t("general.Back")}
                            height="35px"
                            width="9em"
                            margin="1em 1.5em 0 0"
                            padding='1em'
                            onClick={() => history.push("/admin/applications/details")}
                        />
                    </ProgressBar>
                </Wrapper>
            )}
        />
    );
};

export default ApplyApplicationDetails;
