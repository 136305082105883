import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import JoditEditor from 'jodit-react';
import moment from 'moment';
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import {
  BodyContainer,
  ProgressBar,
  WrapperInput,
  TitleInput,
  InputContainer,
  GeneralButtonLanguage
} from "./Event.styles";
import {
  PageTitle,
  GeneralButton,
  LabelSlide
} from "../../../../components/Common/CommonLayout";
import InputCalendar from "../../../../components/Common/InputCalendar";
import Loading from "../../../../components/Layout/CommonLayoutPart/Loading";
import EventActions from "../../../../actions/Event";
import { ColorName } from "../../../../components/Variables";
import Utils from "../../../../libs/Utils";
import Validation from "../../../../libs/Validation";
import history from "../../../../history";
import Constants from "../../../../constants/Constants";

const Edit = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const editor = useRef(null);
  const editorAl = useRef(null);
  const isLoading = useSelector((state) => state.Event.isLoading);
  const [contentEn, setContentEn] = useState('');
  const [contentAl, setContentAl] = useState('');
  const eventID = useSelector((state) => state.Event.eventID);
  const dataEvent = useSelector((state) => state.Event.dataEvent);
  const fileInput = useRef(null);
  const fileInputDocument = useRef(null);
  const [formData, setFormData] = useState({
    title: {
      en: "",
      al: ""
    },
    description: {
      en: "",
      al: ""
    },
    location: {
      en: "",
      al: ""
    }
  });
  const [location, setLocation] = useState(""); // eslint-disable-line
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [fileData, setFileData] = useState("");
  const [fileUrlDocument, setFileUrlDocument] = useState("");
  const [fileDataDocument, setFileDataDocument] = useState("");
  const [language, setLanguage] = useState(Utils.getCurrentLanguage());
  const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);

  useEffect(() => {
    if (!eventID) return history.push("/limited-admin/events");
    const data = {
    };
    dispatch(EventActions.getEventById(eventID, data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatNameFile = (file_attach) => {
    if (file_attach) return file_attach.split("/")[1];
  };

  useEffect(() => {
    if (!_.isEmpty(dataEvent)) {
      if (dataEvent.is_support_mul_lang) {
        if (i18n.language === Constants.LANGUAGE_CODE.AL) {
          setLanguage(Constants.LANGUAGE_CODE.AL);
        } else {
          setLanguage(Constants.LANGUAGE_CODE.EN);
        }
      } else if (dataEvent.title_json[i18n.language]) {
        setLanguage(i18n.language);
      } else if (i18n.language === Constants.LANGUAGE_CODE.EN) {
        setLanguage(Constants.LANGUAGE_CODE.AL);
      } else {
        setLanguage(Constants.LANGUAGE_CODE.EN);
      }

      const data = { ...formData };
      data.title.en = dataEvent.title_json.en;
      data.title.al = dataEvent.title_json.al;
      data.description.en = dataEvent.description_json.en;
      data.description.al = dataEvent.description_json.al;
      data.location.en = dataEvent.location_json.en;
      data.location.al = dataEvent.location_json.al;
      setContentAl(dataEvent.content_json.al);
      setContentEn(dataEvent.content_json.en);
      setFileUrl(dataEvent.url_img_aws);
      setLocation(dataEvent.location);
      setType(dataEvent.type);
      setDate(dataEvent.date_event);
      setIsmultipleLanguage(dataEvent.is_support_mul_lang);
      setFileUrlDocument(formatNameFile(dataEvent.file_url));
      setFormData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEvent]);

  const validateMutilpleLanguage = () => {
    const validationRules = [
      {
        name: `${t("general.Title")} ${t("general.English")}`,
        value: formData.title.en,
        rule: ["required", { min: 10, max: 100 }]
      },
      {
        name: `${t("general.Title")} ${t("general.Albanian")}`,
        value: formData.title.al,
        rule: ["required", { min: 10, max: 100 }]
      },
      {
        name: `${t("general.Description")} ${t("general.English")}`,
        value: formData.description.en,
        rule: ["required", { min: 10, max: 200 }]
      },
      {
        name: `${t("general.Description")} ${t("general.Albanian")}`,
        value: formData.description.al,
        rule: ["required", { min: 10, max: 200 }]
      },
      {
        name: `${t("general.Location")} ${t("general.English")}`,
        value: formData.location.en,
        rule: ["required", { min: 10, max: 200 }]
      },
      {
        name: `${t("general.Location")} ${t("general.Albanian")}`,
        value: formData.location.al,
        rule: ["required", { min: 10, max: 200 }]
      },
      {
        name: t("general.Date"),
        value: date,
        rule: 'required'
      },
      {
        name: `${t("general.Content")} ${t("general.English")}`,
        value: editor.current.value,
        rule: 'required'
      },
      {
        name: `${t("general.Content")} ${t("general.Albanian")}`,
        value: editorAl.current.value,
        rule: 'required'
      }
    ];
    if (!Validation.formValidation(validationRules)) return;
    return true;
  };

  const validateForLanguage = (lang) => {
    const textEditor = lang === Constants.LANGUAGE_CODE.EN ? editor : editorAl;
    const validationRules = [
      {
        name: t("general.Title"),
        value: formData.title[lang],
        rule: ["required", { min: 10, max: 100 }]
      },
      {
        name: t("general.Description"),
        value: formData.description[lang],
        rule: ["required", { min: 10, max: 350 }]
      },
      {
        name: t("general.Location"),
        value: formData.location[lang],
        rule: ["required", { min: 10, max: 350 }]
      },
      {
        name: t("general.Date"),
        value: date,
        rule: 'required'
      },
      {
        name: `${t("general.Content")} ${lang === Constants.LANGUAGE_CODE.EN ? t("general.English") : t("general.Albanian")}`,
        value: textEditor.current.value,
        rule: 'required'
      }
    ];
    if (!Validation.formValidation(validationRules)) return;
    return true;
  };

  const paramPostSingleLanguage = (param) => {
    if (language === Constants.LANGUAGE_CODE.EN) {
      param.append('title', JSON.stringify({ en: formData.title.en }));
      param.append('location', JSON.stringify({ en: formData.location.en }));
      param.append('description', JSON.stringify({ en: formData.description.en }));
      param.append('content', JSON.stringify({ en: editor.current.value }));
    } else {
      param.append('title', JSON.stringify({ al: formData.title.al }));
      param.append('location', JSON.stringify({ al: formData.location.al }));
      param.append('description', JSON.stringify({ al: formData.description.al }));
      param.append('content', JSON.stringify({ al: editorAl.current.value }));
    }
  };

  const paramPostMultipleLanguage = (param) => {
    param.append('title', JSON.stringify({ en: formData.title.en, al: formData.title.al }));
    param.append('location', JSON.stringify({ en: formData.location.en, al: formData.location.al }));
    param.append('description', JSON.stringify({ en: formData.description.en, al: formData.description.al }));
    param.append('content', JSON.stringify({ en: editor.current.value, al: editorAl.current.value }));
  };

  const editNews = () => {
    let flag = true;
    if (isMultipleLanguage) {
      flag = validateMutilpleLanguage();
    } else if (!validateForLanguage(language)) return;
    if (flag) {
      const param = new FormData();
      if (isMultipleLanguage) paramPostMultipleLanguage(param);
      if (!isMultipleLanguage) paramPostSingleLanguage(param);
      param.append('type', type);
      param.append('date_event', date);
      param.append('is_support_mul_lang', isMultipleLanguage);
      if (fileData !== "") param.append('img', fileData);
      if (fileDataDocument !== "") param.append('file', fileDataDocument);
      if (fileUrlDocument === "") param.append('remove_file', true);
      dispatch(EventActions.updateEvent(param, eventID));
    }
  };

  const handleFileChange = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    const file = e.target.files[0];
    setFileData(file);
    setFileUrl(url);
  };

  const handleChange = (value, nameInput) => {
    const data = { ...formData };
    formData[nameInput][language] = value;
    setFormData(data);
  };

  const deleteFileAttachment = () => {
    setFileData("");
    setFileUrl("");
    fileInput.current.value = "";
  };

  const onChangeDate = (dateTime) => {
    setDate(dateTime);
  };

  const formatDate = (dateTime) => {
    if (dateTime) {
      const dateFormat = moment(dateTime).format('YYYY-MM-DD');
      return dateFormat;
    }
  };

  const handleFileDocumentChange = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    const file = e.target.files[0];
    setFileDataDocument(file);
    setFileUrlDocument(url);
  };

  const deleteFileAttachmentDocument = () => {
    setFileDataDocument("");
    setFileUrlDocument("");
    fileInputDocument.current.value = "";
  };

  return (
    <DefaultLayout
      userRole='limited-admin'
      isLoading={false}
      page="events"
      content={(
        <BodyContainer>
          {isLoading && <Loading />}
          <PageTitle>
            {t("event.Edit Event")}
          </PageTitle>
          <ProgressBar className="mb-2">
            <WrapperInput>
              <TitleInput>
                {t("general.Title")}
                <div className="language">
                  <LabelSlide className="switch">
                    <input type="checkbox" checked={!!isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} />
                    <span className="slider round" />
                  </LabelSlide>
                  {isMultipleLanguage
                    && (
                      <>
                        <GeneralButtonLanguage
                          type="button"
                          className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                          value={t("general.English")}
                          onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                        />
                        <GeneralButtonLanguage
                          type="button"
                          className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                          value={t("general.Albanian")}
                          onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                        />
                      </>
                    )
                  }
                  {!isMultipleLanguage
                    && (
                      <>
                        <GeneralButtonLanguage
                          type="button"
                          className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                          value={t("general.English")}
                          onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                          disabled
                        />
                        <GeneralButtonLanguage
                          type="button"
                          className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                          value={t("general.Albanian")}
                          onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                          disabled
                        />
                      </>
                    )
                  }

                </div>
              </TitleInput>
              <InputContainer
                value={formData.title[language] || ""}
                onChange={(event) => handleChange(event.target.value, "title")}
              />
            </WrapperInput>
            <WrapperInput>
              <TitleInput>{t("general.Description")}</TitleInput>
              <InputContainer
                value={formData.description[language] || ""}
                onChange={(event) => handleChange(event.target.value, "description")}
              />
            </WrapperInput>
            <WrapperInput>
              <TitleInput>{t("general.Location")}</TitleInput>
              <InputContainer
                value={formData.location[language] || ""}
                onChange={(event) => handleChange(event.target.value, "location")}
              />
            </WrapperInput>
            <WrapperInput>
              <TitleInput>{t("general.Date")}</TitleInput>
              <InputCalendar
                selectedDate={(dateTime) => onChangeDate(dateTime)}
                value={formatDate(date) || ""}
              />
            </WrapperInput>
            <WrapperInput>
              <TitleInput>{t("event.Type")}</TitleInput>
              <select
                value={type}
                onChange={(event) => setType(event.target.value)}
              >
                <option value={Constants.EVENT_TYPE.ONLINE}>{t("event.Online")}</option>
                <option value={Constants.EVENT_TYPE.OFFLINE}>{t("event.Offline")}</option>
              </select>
            </WrapperInput>
            <WrapperInput>
              <TitleInput>{t("general.Content")}</TitleInput>
              <div className={language === Constants.LANGUAGE_CODE.EN ? "" : "hidden-content"}>
                <JoditEditor
                  ref={editor}
                  value={contentEn}
                />
              </div>
              <div className={language === Constants.LANGUAGE_CODE.AL ? "" : "hidden-content"}>
                <JoditEditor
                  ref={editorAl}
                  value={contentAl}
                />
              </div>
            </WrapperInput>
            <WrapperInput className="container-upload">
              <input
                ref={fileInput}
                id='uploadAvatar'
                type="file"
                accept="image/gif,image/jpeg,image/png,.gif,.jpeg,.jpg,.png"
                onChange={(e) => handleFileChange(e)}
              />
              {t("event.Featured Image")}
              {' '}
              <i className="fas fa-upload" onClick={() => fileInput.current.click()} />
              {fileUrl
                && (
                  <div className="display-image-file">
                    <img src={fileUrl} alt="file" />
                    <div className="remove" onClick={() => deleteFileAttachment()}>X</div>
                  </div>
                )
              }
            </WrapperInput>
            <WrapperInput className="container-upload">
              <input
                ref={fileInputDocument}
                id='uploadAvatar'
                type="file"
                accept="*"
                onChange={(e) => handleFileDocumentChange(e)}
              />
              {t("news.Attach File")}
              :
              {' '}
              <i className="fas fa-paperclip" onClick={() => fileInputDocument.current.click()} />
              {fileUrlDocument
                && (
                  <div className="file-attach">
                    <div>
                      {fileUrlDocument}
                    </div>
                    <div className="remove" onClick={() => deleteFileAttachmentDocument()}>X</div>
                  </div>
                )
              }
            </WrapperInput>
            <div className="container-button">
              <GeneralButton
                type="button"
                className="for-more"
                background={ColorName.chathamsBlue}
                value={t("general.Save")}
                onClick={() => editNews()}
              />
            </div>
          </ProgressBar>
        </BodyContainer>
      )}
    />
  );
};

Edit.propTypes = {

};

export default Edit;
