import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import JoditEditor from 'jodit-react';
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput,
    InputContainer,
    GeneralButtonLanguage
} from "./Introduce.styles";
import {
    PageTitle,
    GeneralButton,
    LabelSlide
} from "../../../../../components/Common/CommonLayout";
import IntroduceActions from "../../../../../actions/Introcude";
import Loading from "../../../../../components/Layout/CommonLayoutPart/Loading";
import { ColorName } from "../../../../../components/Variables";
import Utils from "../../../../../libs/Utils";
import Validation from "../../../../../libs/Validation";
import Constants from "../../../../../constants/Constants";

const Introduce = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const editor = useRef(null);
    const editorAl = useRef(null);
    const [contentEn, setContentEn] = useState('');
    const [contentAl, setContentAl] = useState('');
    const isLoading = useSelector((state) => state.Introduce.isLoading);
    const dataIntroduce = useSelector((state) => state.Introduce.introduce);
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());
    const [type, setType] = useState("entrepreneur_introduce");
    const [data, setData] = useState({
        en: {
            title: "",
            description: "",
            content: ""
        },
        al: {
            title: "",
            description: "",
            content: ""
        }
    });

    const validateMutilpleLanguage = () => {
        const validationRules = [
            {
                name: `${t("general.Title")} ${t("general.English")}`,
                value: data.en.title,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: `${t("general.Title")} ${t("general.Albania")}`,
                value: data.al.title,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: `${t("general.Description")} ${t("general.English")}`,
                value: data.en.description,
                rule: ["required", { min: 10, max: 200 }]
            },
            {
                name: `${t("general.Description")} ${t("general.Albania")}`,
                value: data.al.description,
                rule: ["required", { min: 10, max: 200 }]
            },
            {
                name: `${t("general.Content")} ${t("general.English")}`,
                value: editor.current.value,
                rule: 'required'
            },
            {
                name: `${t("general.Content")} ${t("general.Albania")}`,
                value: editorAl.current.value,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const validateForLanguage = (lang) => {
        const textEditor = lang === Constants.LANGUAGE_CODE.EN ? editor : editorAl;
        const validationRules = [
            {
                name: t("general.Title"),
                value: data[lang].title,
                rule: ["required", { min: 10, max: 100 }]
            },
            {
                name: t("general.Description"),
                value: data[lang].description,
                rule: ["required", { min: 10, max: 200 }]
            },
            {
                name: `${t("general.Content")} ${lang === "en" ? t("general.English") : t("general.Albania")}`,
                value: textEditor.current.value,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const addProcess = () => {
        let flag = true;
        if (isMultipleLanguage) {
            flag = validateMutilpleLanguage();
        } else if (!validateForLanguage(language)) return;
        if (flag) {
            const param = new FormData();
            const temp = { ...data };
            temp.en.content = editor.current.value;
            temp.al.content = editorAl.current.value;
            param.append('type', type);
            param.append('data', JSON.stringify(temp));
            param.append('is_support_mul_lang', isMultipleLanguage);
            dispatch(IntroduceActions.addIntroduce(param));
        }
    };

    useEffect(() => {
        const dataGet = {
            type
        };
        setData({
            en: {
                title: "",
                description: "",
                content: ""
            },
            al: {
                title: "",
                description: "",
                content: ""
            }
        });
        dispatch(IntroduceActions.getIntroduce(dataGet));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    useEffect(() => {
        if (!_.isEmpty(dataIntroduce)) {
            setData(dataIntroduce.data_json);
            setContentAl(dataIntroduce.data_json.al.content);
            setContentEn(dataIntroduce.data_json.en.content);
            setIsmultipleLanguage(dataIntroduce.is_support_mul_lang);
        }
    }, [dataIntroduce]);

    const onChangeTitle = (value) => {
        const temp = { ...data };
        if (!_.has(temp, language)) {
            temp[language] = {};
        }
        temp[language].title = value;
        setData(temp);
    };

    const onChangeDesc = (value) => {
        const temp = { ...data };
        if (!_.has(temp, language)) {
            temp[language] = {};
        }
        temp[language].description = value;
        setData(temp);
    };

    const _renderNewStep = (item) => {
        return (
            <ProgressBar className="mb-2">
                <WrapperInput>
                    <TitleInput>
                        {t("general.Title")}
                        <div className="language">
                            <LabelSlide className="switch">
                                <input type="checkbox" checked={!!isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} />
                                <span className="slider round" />
                            </LabelSlide>
                            {isMultipleLanguage
                                && (
                                    <>
                                        <GeneralButtonLanguage
                                            type="button"
                                            className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                            value={t("general.English")}
                                            onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                        />
                                        <GeneralButtonLanguage
                                            type="button"
                                            className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                            value={t("general.Albanian")}
                                            onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                        />
                                    </>
                                )
                            }
                            {!isMultipleLanguage
                                && (
                                    <>
                                        <GeneralButtonLanguage
                                            type="button"
                                            className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                            value={t("general.English")}
                                            onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                            disabled
                                        />
                                        <GeneralButtonLanguage
                                            type="button"
                                            className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                            value={t("general.Albanian")}
                                            onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                            disabled
                                        />
                                    </>
                                )
                            }
                        </div>
                    </TitleInput>
                    <InputContainer
                        value={item[language] ? item[language].title : ""}
                        onChange={(event) => onChangeTitle(event.target.value)}
                    />
                </WrapperInput>
                <WrapperInput>
                    <TitleInput>{t("general.Description")}</TitleInput>
                    <textarea
                        value={item[language] ? item[language].description : ""}
                        onChange={(event) => onChangeDesc(event.target.value)}
                    />
                </WrapperInput>
                <WrapperInput className="content">
                    <TitleInput>{t("general.Content")}</TitleInput>
                    <div className={language === Constants.LANGUAGE_CODE.EN ? "" : "hidden-content"}>
                        <JoditEditor
                            ref={editor}
                            value={contentEn}
                        />
                    </div>
                    <div className={language === Constants.LANGUAGE_CODE.AL ? "" : "hidden-content"}>
                        <JoditEditor
                            ref={editorAl}
                            value={contentAl}
                        />
                    </div>
                </WrapperInput>
            </ProgressBar>
        );
    };

    const _renderAllStep = () => {
        return _renderNewStep(data);
    };

    return (
        <DefaultLayout
            userRole='admin'
            isLoading={false}
            page="introduce"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>
                        {t("introduce.Add What We Do")}
                    </PageTitle>
                    <WrapperInput>
                        <TitleInput>{t("general.Type")}</TitleInput>
                        <select onChange={(e) => setType(e.target.value)} value={type}>
                            <option value="entrepreneur_introduce">{t("general.Entrepreneurs")}</option>
                            <option value="investor_introduce">{t("general.Investors")}</option>
                            <option value="consultant_introduce">{t("general.Consultants")}</option>
                        </select>
                    </WrapperInput>
                    {!data
                        && _renderNewStep()
                    }
                    {data
                        && _renderAllStep()
                    }
                    <div className="container-button">
                        <GeneralButton
                            type="button"
                            className="for-more"
                            background={ColorName.chathamsBlue}
                            value={t("general.Save")}
                            onClick={() => addProcess()}
                        />
                    </div>
                </BodyContainer>
            )}
        />
    );
};

Introduce.propTypes = {

};

export default Introduce;
