import React from "react";
import PropTypes from "prop-types";
import {
    GeneralCheckBoxFormContainer
} from "./GeneralCheckBoxForm.styles";

const GeneralCheckBoxForm = (props) => {
    const {
        value, defaultChecked, onChange, name, className, checkBoxRef
    } = props;
    const handleCheckBox = (e) => {
        if (onChange) onChange(e);
    };
    return (
        <GeneralCheckBoxFormContainer className={className}>
            <label htmlFor={name} className={defaultChecked ? 'checked' : ''} />
            <input type="checkbox" value={value} id={name} defaultChecked={defaultChecked} onChange={(e) => handleCheckBox(e)} ref={checkBoxRef} />
        </GeneralCheckBoxFormContainer>
    );
};

GeneralCheckBoxForm.propTypes = {
    value: PropTypes.string,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    className: PropTypes.string,
    checkBoxRef: PropTypes.any
};

export default GeneralCheckBoxForm;
