const industryOptions = [
    "Agriculture, forestry and fishing",
    "Mining and quarrying",
    "Manufactured",
    "Electricity, gas, steam and air conditioning supply",
    "Water supply, sewerage, waste management and remediation activities",
    "Construction",
    "Wholesale and retail trade; repair of motor vehicles and motorcycles",
    "Transportation and storage",
    "Accommodation and food service activities",
    "Information and communication",
    "Real estate activities",
    "Professional, scientific and technical activities",
    "Administrative and support service activities",
    "Public administration and defence; compulsory social activities",
    "Education",
    "Human health and social work activities",
    "Arts, entertainment and recreation",
    "Other service activities"
];

const getYear = () => {
    const now = new Date().getUTCFullYear();
    const years = Array(now - (now - 20)).fill('').map((v, idx) => now - idx);
    return years;
};

const investorTypeList = ["INDIVIDUAL", "BUSINESS", "VENTURE FIRM/IMPACT INVESTMENT"];
const consultantsTypeList = ["INDIVIDUAL", "PRIVATE ENTITY"];

export default {
    industryOptions,
    getYear,
    investorTypeList,
    consultantsTypeList
};
