import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    FundingDetailReportComponent,
    TableContainer,
    Title,
    OpenExtra,
    FooterButton,
    Attach
} from "./AdminFundingDetailReport.styles";
import { RoundedRowContainer } from "../CommonLayout";
import Button from '../Button';
import { ColorName } from "../../Variables";
import Loading from "../../Layout/CommonLayoutPart/Loading";

const AdminFundingDetailReport = (props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [classNameStatus, setClassNameStatus] = useState('');
    const [nameFileAttach, setNameFileAttach] = useState(''); // eslint-disable-line
    const [clnOpenExtra, setClnOpenExtra] = useState('d-none');
    const [submitData, setSubmitData] = useState(''); 
    const [idItemReportStatus, setIdItemReportStatus] = useState(); // eslint-disable-line
    const [dataUrl, setDataUrl] = useState();
    const FundingStorage = useSelector((state) => state.FundingApplicationsUser);

    useEffect(() => {
        setDescription(props.description);
        setStatus(props.status);
        setDueDate(props.dueDate);
        setClassNameStatus(props.classNameStatus);
        setIdItemReportStatus(props.idItemReportStatus);
        setSubmitData(props.submitData);
        setDataUrl(props.dataUrl);
    }, [props]);

    const textareaSubmitData = (e) => {
        setSubmitData(e.target.value);
    };

    useEffect(() => {
        setIsLoading(FundingStorage.isLoading);
    }, [FundingStorage]);

    const textDescription = () => {
        return (
            <div>
                <span>
                    Your progress report is due. Please submit the following information.
                </span>
                <br />
                <span>
                    1) Your financial report
                </span>
                <br />
                <span>
                    2) Your budgeting report
                </span>
                <br />
                <span>
                    3) Your progress report.
                </span>
                <br />
                <span>
                    You must submit the information requested by attaching documents and the only attachment formats accepted are: pdf, excel, word. Any other formats will not be accepted.
                </span>
            </div>
        );
    };

    const _renderReportByStatus = () => {
        return (
            <RoundedRowContainer direction='column'>
                <TableContainer>
                    <thead>
                        <tr>
                            <td>
                                {description}
                                {' '}
                                <div className="text-description">{textDescription()}</div>
                            </td>
                            <td className={classNameStatus}>{status}</td>
                            <td>{dueDate}</td>
                            <td>
                                <Button
                                    background={ColorName.fern}
                                    title={t("general.View")}
                                    width='5em'
                                    height='35px'
                                    onClick={() => setClnOpenExtra('d-block')}
                                />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="description" />
                            <td className="status" />
                            <td className="due-date" />
                            <td className="button" />
                        </tr>
                    </tbody>
                </TableContainer>
                <OpenExtra className={clnOpenExtra}>
                    <Attach>
                        <div>
                            <i
                                className="fas fa-paperclip attach"
                            />
                        </div>
                        <div className="text-attach">
                            <div>{nameFileAttach === '' ? dataUrl : nameFileAttach}</div>
                            <div>
                                {t("general.Please attach all supporting documents here")}
                                {' '}
                                <br />
                                {t("general.You can use the comment box below  for any additional information")}
                            </div>
                        </div>
                    </Attach>
                    <textarea className="textarea" onChange={textareaSubmitData} value={_.isEmpty(submitData) ? '' : submitData} />
                    <FooterButton>
                        <Button
                            background={ColorName.redOrange}
                            title={t("general.Cancel")}
                            width='5em'
                            height='35px'
                            onClick={() => setClnOpenExtra('d-none')}
                            className="space-button"
                        />
                    </FooterButton>
                </OpenExtra>
            </RoundedRowContainer>
        );
    };

    return (
        <FundingDetailReportComponent>
            {isLoading && <Loading />}
            <Title>
                <div className="description">
                    <div className="text-description">{t("general.Description")}</div>
                </div>
                <div className="status">{t("general.Status")}</div>
                <div className="due-date">{t("general.Due Date")}</div>
                <div className="button" />
            </Title>
            {_renderReportByStatus()}
        </FundingDetailReportComponent>
    );
};

AdminFundingDetailReport.propTypes = {
    idApplicationItem: PropTypes.number,
    idItemReportStatus: PropTypes.number,
    submitData: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    dueDate: PropTypes.string,
    classNameStatus: PropTypes.string,
    dataUrl: PropTypes.string
};

export default AdminFundingDetailReport;
