import React, {
    useState,
    useRef,
    useEffect,
    useCallback
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import JoditEditor from "jodit-react";
import DefaultLayout from "../../../../../components/Layout/PortalTemplate";
import {
    BodyContainer,
    ProgressBar,
    WrapperInput,
    TitleInput
} from "./ApplySetting.styles";
import {
    PageTitle,
    GeneralButton,
    FlexGridSixth,
    GridCol
} from "../../../../../components/Common/CommonLayout";
import {
    SwitchButton,
    SwitchLanguageButton,
    EnableMultipleLanguageButtonContainer
} from "../../../../../components/Common/EnableMultipleLanguageButton";
import Loading from "../../../../../components/Layout/CommonLayoutPart/Loading";
import GuestApplySettingActions from "../../../../../actions/GuestApplySetting";
import { ColorName } from "../../../../../components/Variables";
import Utils from "../../../../../libs/Utils";
import Validation from "../../../../../libs/Validation";
import Constants from "../../../../../constants/Constants";

const ApplySetting = () => {
    const { t } = useTranslation();
    const editorAl = useRef(null);
    const [contentEn, setContentEn] = useState('');
    const [contentAl, setContentAl] = useState('');
    const dispatch = useDispatch();
    const editor = useRef([React.createRef(), React.createRef(), React.createRef()]);
    const isLoading = useSelector(
        (state) => state.GuestApplySetting.isLoading
    );
    const guestApplySetting = useSelector(
        (state) => state.GuestApplySetting.applyData
    );

    const [typeApply, setTypeApply] = useState("guest_apply_entrepreneur");
    const [fileUrl, setFileUrl] = useState("");
    const [fileData, setFileData] = useState("");
    const [isMultipleLanguage, setIsmultipleLanguage] = useState(false);
    const [language, setLanguage] = useState(Utils.getCurrentLanguage());

    const fileInput = useRef(null);

    useEffect(() => {
        setFileUrl("");
        const data = {
            type: typeApply
        };
        dispatch(GuestApplySettingActions.getApplySettingData(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeApply]);

    useEffect(() => {
        if (guestApplySetting) {
            setContentAl(guestApplySetting.data_json.al);
            setContentEn(guestApplySetting.data_json.en);
            setIsmultipleLanguage(guestApplySetting.is_support_mul_lang);
            setFileUrl(guestApplySetting.data_media_json[0]);
        }
    }, [guestApplySetting]);

    const validateMutilpleLanguage = () => {
        const validationRules = [
            {
                name: `${t("general.Content")} ${t("general.English")}`,
                value: editor.current.value,
                rule: 'required'
            },
            {
                name: `${t("general.Content")} ${t("general.Albania")}`,
                value: editorAl.current.value,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const validateForLanguage = (lang) => {
        const textEditor = lang === Constants.LANGUAGE_CODE.EN ? editor : editorAl;
        const validationRules = [
            {
                name: `${t("general.Content")} ${lang === Constants.LANGUAGE_CODE.EN ? t("general.English") : t("general.Albania")}`,
                value: textEditor.current.value,
                rule: 'required'
            }
        ];
        if (!Validation.formValidation(validationRules)) return;
        return true;
    };

    const createOrupdateAboutUs = () => {
        let flag = true;
        if (isMultipleLanguage) {
            flag = validateMutilpleLanguage();
        } else if (!validateForLanguage(language)) return;
        if (flag) {
            const formData = new FormData();
            formData.append('data', JSON.stringify({ en: editor.current.value, al: editorAl.current.value }));
            formData.append("type", typeApply);
            formData.append("is_support_mul_lang", isMultipleLanguage);
            if (fileData !== "") formData.append("data_media[0]", fileData);
            dispatch(GuestApplySettingActions.updateApplySettingData(formData));
        }
    };

    const handleFileChange = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        const file = e.target.files[0];
        setFileData(file);
        setFileUrl(url);
    };

    const deleteFileAttachment = () => {
        setFileData("");
        setFileUrl("");
        fileInput.current.value = "";
    };

    const _renderSwitchLangugage = () => {
        return (
            <EnableMultipleLanguageButtonContainer>
                <SwitchButton className="switch">
                    <input type="checkbox" value={isMultipleLanguage} onChange={() => setIsmultipleLanguage(!isMultipleLanguage)} checked={isMultipleLanguage} />
                    <span className="slider round" />
                </SwitchButton>
                {isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active" : ""}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active" : ""}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                            />
                        </>
                    )}
                {!isMultipleLanguage
                    && (
                        <>
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.EN) ? "active disable" : "disable disable-unclick"}
                                value={t("general.English")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.EN)}
                                disabled
                            />
                            <SwitchLanguageButton
                                type="button"
                                className={(language === Constants.LANGUAGE_CODE.AL) ? "active disable" : "disable disable-unclick"}
                                value={t("general.Albania")}
                                onClick={() => setLanguage(Constants.LANGUAGE_CODE.AL)}
                                disabled
                            />
                        </>
                    )}
            </EnableMultipleLanguageButtonContainer>
        );
    };

    const renderForm = () => {
        return (
            <WrapperInput>
                <TitleInput>{t("general.Content")}</TitleInput>
                <div className={language === Constants.LANGUAGE_CODE.EN ? "" : "hidden-content"}>
                    <JoditEditor
                        ref={editor}
                        value={contentEn}
                    />
                </div>
                <div className={language === Constants.LANGUAGE_CODE.AL ? "" : "hidden-content"}>
                    <JoditEditor
                        ref={editorAl}
                        value={contentAl}
                    />
                </div>
            </WrapperInput>
        );
    };

    const changeType = (type) => {
        setTypeApply(type);
    };

    return (
        <DefaultLayout
            userRole="admin"
            isLoading={false}
            page="apply-setting"
            content={(
                <BodyContainer>
                    {isLoading && <Loading />}
                    <PageTitle>{t("general.Guest Apply Setting")}</PageTitle>
                    <ProgressBar className="mb-2">
                        <FlexGridSixth>
                            <GridCol colSize='7' gridTemplate='7' className="flex-end">
                                {_renderSwitchLangugage()}
                            </GridCol>
                        </FlexGridSixth>
                        <WrapperInput>
                            <TitleInput>{t("general.Type")}</TitleInput>
                            <select
                                onChange={useCallback((e) => {
                                    changeType(e.target.value);
                                    setLanguage(Constants.LANGUAGE_CODE.EN);
                                }, [])}
                                value={typeApply}
                            >
                                <option value="guest_apply_entrepreneur">{t("general.Entrepreneurs")}</option>
                                <option value="guest_apply_investor">{t("general.Investors")}</option>
                                <option value="guest_apply_consultant">{t("general.Consultants")}</option>
                            </select>
                        </WrapperInput>
                        <WrapperInput>
                            <div>
                                {renderForm()}
                            </div>
                        </WrapperInput>
                        <WrapperInput className="container-upload">
                            <input
                                ref={fileInput}
                                id="uploadAvatar"
                                type="file"
                                accept=".mp4"
                                onChange={(e) => handleFileChange(e)}
                            />
                            {t("general.Featured Video")}
                            :
                            {" "}
                            {t("general.Only accept mp4")}
                            <i
                                className="fas fa-upload"
                                onClick={() => fileInput.current.click()}
                            />
                            {fileUrl && (
                                <div className="display-image-file">
                                    <video width="320" height="240" controls>
                                        <source src={fileUrl} type="video/mp4" />
                                        {t("general.Your browser does not support the video tag")}
                                    </video>
                                    <div
                                        className="remove"
                                        onClick={() => deleteFileAttachment()}
                                    >
                                        X
                                    </div>
                                </div>
                            )}
                        </WrapperInput>
                        <div className="container-button">
                            <GeneralButton
                                type="button"
                                className="for-more"
                                background={ColorName.chathamsBlue}
                                value={t("general.Save")}
                                onClick={() => createOrupdateAboutUs()}
                            />
                        </div>
                    </ProgressBar>
                </BodyContainer>
            )}
        />
    );
};

ApplySetting.propTypes = {};

export default ApplySetting;
