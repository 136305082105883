import styled from 'styled-components';
import { ColorName } from '../../Variables';
import { GeneralButton } from "../CommonLayout";

const EnableMultipleLanguageButtonContainer = styled.div`
    display: flex;
`;

const SwitchLanguageButton = styled(GeneralButton)`
    border: 1px solid ${ColorName.chathamsBlueDark};    
    margin-right: 1em;
    background: ${ColorName.white};
    color: ${ColorName.chathamsBlueDark};
    &.active{
        background: ${ColorName.chathamsBlueDark};
        color: ${ColorName.white};
        &.disable{
            color: ${ColorName.white};
            background: #ccc;
            border: 1px solid #ccc;  
            cursor: not-allowed;
        }
    }
    &.disable-unclick{
        background: none;
        border: 1px solid #ccc;  
        color: #ccc;
        cursor: not-allowed;
    }
`;

const SwitchButton = styled.label`
    margin-right: 1em;
    &.switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }
    & input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    &.search-bar{
        & .slider {
            background: gray;
        }
    }
    & .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        &.round {
            border-radius: 34px;
        }
        &.round:before {
            border-radius: 50%;
        }
    }
      
    & .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    & input:checked + .slider {
        background-color: ${ColorName.chathamsBlueDark};
    }
      
    & input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
`;

export {
    EnableMultipleLanguageButtonContainer,
    SwitchLanguageButton,
    SwitchButton
};
