import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    TableContainer,
    ButtonWrapper
} from "./UserFundingMyApplication.styles";
import { RoundedRowContainer } from "../CommonLayout";
import Button from "../Button";
import history from '../../../history';
import FundingApplicationUserActions from "../../../actions/FundingApplicationsUser";
import Loading from "../../Layout/CommonLayoutPart/Loading";
import Constants from "../../../constants/Constants";
import Utils from "../../../libs/Utils";
import { ColorName } from "../../Variables";

const UserFundingMyApplication = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const FundingStorage = useSelector((state) => state.FundingApplicationsUser);
    const [dataApplyApplication, setDataApplyApplication] = useState();

    const [userData, setUserData] = useState(Utils.getSavedUserData()); // eslint-disable-line

    const [showNumberItems, setShowNumberItems] = useState(4);

    const checkRoleUser = () => {
        if (userData.role.name ===  Constants.ROLE_NAME.UNASSIGNED) {
            return 'add-view-user';
        }
        return true;
    };
    useEffect(() => {
        const check = checkRoleUser();
        if (check === 'add-view-user') {
            return history.push('add-view-user');
        } else {
            dispatch(FundingApplicationUserActions.getApplyApplicationRequest());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoading(FundingStorage.isLoading);
        setDataApplyApplication(FundingStorage.dataApplyApplication);
    }, [FundingStorage]);

    const switchPage = (status, id) => {
        const applyApplicationId = {
            apply_application_id: id
        };
        if (_.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, status)) === _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, 3))) {
            history.push({
                state: { applicationID: id, applyApplicationID: applyApplicationId },
                pathname: "user-funding-report"
            });
        }
        if (_.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, status)) === _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, 2))
            || _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, status)) === _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, 1))
            || _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, status)) === _.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, 4))
        ) {
            dispatch(FundingApplicationUserActions.getApplyApplicationByIDRequest(id));
            history.push("user-funding-detail");
        }
    };

    const renderTableContent = () => {
        if (_.isEmpty(dataApplyApplication)) {
            return (
                <tr>
                    <td>{t("general.Data Not Found")}</td>
                </tr>
            );
        }
        const render = _.map(dataApplyApplication, (row, index) => {
            if (index < showNumberItems) {
                return (
                    <tr key={index}>
                        <td>
                            {row.fundingApplication.title}
                        </td>
                        <td>
                            {row.fundingApplication.category === null ? "--" : t(`general.${_.findKey(Constants.FUNDING_CATEGORY, (v) => { return v === row.fundingApplication.category; })}`)}
                        </td>
                        <td>
                            {t(`rounderTable.${_.findKey(Constants.APPLY_APPLICATION_STATUS, _.partial(_.isEqual, row.status)).replace("_", " ")}`)}
                        </td>
                        <td>
                            <Button
                                background={ColorName.chathamsBlueDark}
                                title={t('general.View')}
                                width='5em'
                                height='35px'
                                onClick={() => switchPage(row.status, row.id)}
                            />
                        </td>
                    </tr>
                );
            }
        });
        return render;
    };

    const clickViewAllActivity = () => {
        setShowNumberItems(showNumberItems + 4);
    };

    return (
        <RoundedRowContainer direction='column' padding='30px' marginBottom="5em">
            {isLoading && <Loading />}
            {_.isEmpty(dataApplyApplication) ? t("general.Data Not Found")
                : (
                    <TableContainer>
                        <thead>
                            <tr>
                                <td>{t("general.Name")}</td>
                                <td>{t("general.Category")}</td>
                                <td>{t("general.Status")}</td>
                                <td />
                            </tr>
                        </thead>

                        <tbody>
                            {renderTableContent()}
                        </tbody>
                    </TableContainer>
                )}
            {
                !_.isEmpty(dataApplyApplication) && dataApplyApplication.length > showNumberItems
                && (
                    <ButtonWrapper>
                        <Button
                            background={ColorName.chathamsBlueDark}
                            title={t("general.View All")}
                            width='10em'
                            height='35px'
                            className="mt-2"
                            onClick={() => clickViewAllActivity()}
                        />
                    </ButtonWrapper>
                )
            }
        </RoundedRowContainer>
    );
};

export default UserFundingMyApplication;
