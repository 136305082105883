import styled from 'styled-components';
import { ColorName } from "../../Variables";

const OptionDropDown = styled.div`
    border: 1px solid ${ColorName.chathamsBlueDark};
    border-bottom: 0px;
    padding: 0.5em 1em;
    cursor: pointer;
    color: ${ColorName.chathamsBlueDark};
    &:last-child{
        border-bottom: 1px solid ${ColorName.chathamsBlueDark};
    }
    &:hover{
        background: ${ColorName.chathamsBlueDark};
        color: ${ColorName.white};
    }
    width: 55px;
    display: flex;
    flex-direction: flex-start;
`;

const DropDownLanguageHeader = styled.div`
    position: absolute;
    top: 35px;
    background: white;
    z-index: 9;
    display: flex;
    flex-direction: column;
`;

const ContainerDropDown = styled.div`
    color: ${ColorName.chathamsBlueDark};
    cursor: pointer;
    display: flex;
    height: 35px;
    width: 75px;
    & img{
        width: 25px;
        height: 25px;
    }
    & div{
        display: flex;
        align-items: center;
        & img{
            margin-right: 5px;
        }
    }
    & i{
        font-size: 1em;
    }
    & .display-language{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`;

export {
    OptionDropDown,
    DropDownLanguageHeader,
    ContainerDropDown
};
