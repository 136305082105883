/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import queryString from 'query-string';
import {
    MainContent,
    DetailContainer,
    Details,
    NextChap,
    PrevChap,
    ChaperInfor
} from "./Library.styles";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import Banner from "../../../components/Common/Banner";
import SummryOverview from "../../../components/Common/SummaryOverview";
import LibraryActions from "../../../actions/Library";
import Loading from "../../../components/Layout/CommonLayoutPart/Loading";
import CONSTANTS from "../../../constants/Constants";
import { Images } from "../../../themes";
import Utils from '../../../libs/Utils';
import history from '../../../history';
import Helmet from "react-helmet";
import { ReactSEOMetaTags } from 'react-seo-meta-tags'

const Detail = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const isLoading = useSelector((state) => state.Library.isLoading);
    const bookData = useSelector((state) => state.Library.libraryDetailData);
    const [chapter, setChapter] = useState(0);
    const [youtubeID, setYoutubeID] = useState('');
    const [siteMetadata, setSiteMetadata] = useState({})
    const params = queryString.parse(location.search);
    useEffect(() => {
        dispatch(LibraryActions.getLibraryDetailData(params.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (_.isNull(bookData)) {
            Utils.popupAlert({
                title: 'Oops!',
                text: t("alert.This content has been removed"),
                type: 'error'
            }).then(() => { history.push("/homepage"); });
        }
        if (bookData && bookData.link_youtube) {
            setYoutubeID(resolveYoutubeLink());
        }

        setSiteMetadata({
            title:  bookData.title,
            description: bookData.description,
            language: 'en-US',
            image: _.get(bookData, "url_cover_img") ? (_.get(bookData, "url_cover_img", "")).replace("https", "http") : ""
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookData]);

    const resolveYoutubeLink = () => {
        let result = '';
        if (bookData.link_youtube.match(/(\?|&)v=([^&#]+)/)) {
            return result = bookData.link_youtube.match(/(\?|&)v=([^&#]+)/).pop();
        }
        if (bookData.link_youtube.match(/(\.be\/)+([^/]+)/)) {
            return result = bookData.link_youtube.match(/(\.be\/)+([^/]+)/).pop();
        }
        if (bookData.link_youtube.match(/(embed\/)+([^/]+)/)) {
            return result = bookData.link_youtube.match(/(embed\/)+([^/]+)/).pop().replace('?rel=0', '');
        }
        return result;
    };

    const _renderChapter = () => {
        const render = _.map(bookData.libraryDetails, (detail, index) => {
            return (
                <li key={index} className={chapter === index ? 'active' : ''}>
                    <a onClick={() => setChapter(index)} className={chapter === index ? 'active' : ''}>
                        {t("general.Chapters")}
                        {' '}
                        {index + 1}
                        :
                        {' '}
                        {detail.title_json[i18n.language]}
                    </a>
                </li>
            );
        });
        return render;
    };

    const _renderDetails = () => {
        let content = !_.isEmpty(bookData.libraryDetails) ? bookData.libraryDetails[chapter].content_json[i18n.language] : 'No Content';
        let titleChapter = !_.isEmpty(bookData.libraryDetails) ? bookData.libraryDetails[chapter].title_json[i18n.language] : 'No Content';
        let introChapter = !_.isEmpty(bookData.libraryDetails) ? bookData.libraryDetails[chapter].introduction_json[i18n.language] : 'No Content';
        if (!content && i18n.language === CONSTANTS.LANGUAGE_CODE.AL) content = bookData.libraryDetails[chapter].content_json.en;
        if (!content && i18n.language === CONSTANTS.LANGUAGE_CODE.EN) content = bookData.libraryDetails[chapter].content_json.al;
        if (!titleChapter && i18n.language === CONSTANTS.LANGUAGE_CODE.AL) titleChapter = bookData.libraryDetails[chapter].title_json.en;
        if (!titleChapter && i18n.language === CONSTANTS.LANGUAGE_CODE.EN) titleChapter = bookData.libraryDetails[chapter].title_json.al;
        if (!introChapter && i18n.language === CONSTANTS.LANGUAGE_CODE.AL) introChapter = bookData.libraryDetails[chapter].introduction_json.en;
        if (!introChapter && i18n.language === CONSTANTS.LANGUAGE_CODE.EN) introChapter = bookData.libraryDetails[chapter].introduction_json.al;
        return (
            <DetailContainer>
                <ChaperInfor>
                    <p>
                        {titleChapter}
                    </p>
                    <span>
                        {introChapter}
                    </span>
                </ChaperInfor>
                {!_.isEmpty(bookData.libraryDetails) && (
                    <Details className="text" dangerouslySetInnerHTML={{ __html: content }} />
                )}
                <div className="chap-action">
                    {(bookData.libraryDetails.length > 1 && chapter >= 1) && _renderPrevChapButton()}
                    {(bookData.libraryDetails.length > 1 && chapter + 1 < bookData.libraryDetails.length) && _renderNextChapButton()}
                </div>
                <Details className="chapter">
                    <ul>
                        {_renderChapter()}
                    </ul>
                </Details>
            </DetailContainer>
        );
    };

    const _renderNextChapButton = () => {
        return (
            <NextChap onClick={() => setChapter(chapter + 1)}>
                <p>{t("general.Next Chapters")}</p>
                <i className="fas fa-long-arrow-alt-right ml-1" />
            </NextChap>
        );
    };

    const _renderPrevChapButton = () => {
        return (
            <PrevChap onClick={() => setChapter(chapter - 1)}>
                <i className="fas fa-long-arrow-alt-left mr-1" />
                <p>{t("general.Prev Chapters")}</p>
            </PrevChap>
        );
    };

    const _renderPDF = () => {
        const title = bookData.title_json ? bookData.title_json[i18n.language] : '';
        if(bookData.url_media_aws){
            return (
                <div className="d-flex">
                    <div className="title-file">
                        <img className="pdf" src={Images.PDFIcon} alt='pdf' />
                        {bookData.title_json ? title : ''}
                    </div>
                    <a href={bookData.url_media_aws} target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-eye" />
                        {t("general.Open document")}
                    </a>
                </div>
            );
        }
    };

    const _renderVideo = () => {
        if (bookData.url_media_aws) {
            return (
                <video controls height="360">
                    <source src={bookData.url_media_aws} />
                </video>
            );
        }
    };

    const _renderMediaDetail = () => {
        return (
            <>
                {bookData.type === CONSTANTS.LIBRARY_TYPE.PDF && _renderPDF()}
                {bookData.type === CONSTANTS.LIBRARY_TYPE.VIDEO && _renderVideo()}
            </>
        );
    };

    const renderMain = () => {
        if (!_.isEmpty(bookData)) {
            let title = bookData.title_json ? bookData.title_json[i18n.language] : '';
            let description = bookData.description_json ? bookData.description_json[i18n.language] : '';
            if (!description && !title && i18n.language === CONSTANTS.LANGUAGE_CODE.AL) { title = bookData.title_json.en; description = bookData.description_json.en; }
            if (!description && !title && i18n.language === CONSTANTS.LANGUAGE_CODE.EN) { title = bookData.title_json.al; description = bookData.description_json.al; }
            return (
                <MainContent>
                    <ReactSEOMetaTags
                        render={(el) => <Helmet>{el}</Helmet>}
                        website={{ ...siteMetadata }}
                    />
                    {isLoading && <Loading />}
                    <Banner
                        type='libraryDetail'
                        title={bookData.title_json ? title : ''}
                        description={bookData.description_json ? description : ''}
                        previewImage={_.get(bookData, "url_cover_img", "")}
                        dateReleased={_.get(bookData, "updated_at", "")}
                    />
                    {bookData.type === CONSTANTS.LIBRARY_TYPE.BOOK ? _renderDetails() : _renderMediaDetail()}
                    {youtubeID && (
                        <div className="flex-link">
                            <iframe height='360' width='768' src={`${CONSTANTS.EMBED_YOUTUBE_URL}${youtubeID}`} title="link" />
                        </div>
                    )}
                    <SummryOverview />
                </MainContent>
            );
        }
        return <MainContent />;
    };

    return (
        <DefaultLayout context={renderMain()} currentPage="library" />
    );
};

export default Detail;
