import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Container,
    GridColHomepage,
    FlexGridSixthTemplate,
    Button
} from "./About.styles.js";
import DefaultLayout from "../../../components/Layout/GuestTemplate/DefaultLayout";
import SummryOverview from "../../../components/Common/SummaryOverview";
import { ColorName } from "../../../components/Variables/index.js";
import history from "../../../history";
import GuestAboutUsSettingActions from "../../../actions/GuestAboutUsSetting";
import Utils from "../../../libs/Utils";
import CONSTANTS from "../../../constants/Constants";

const About = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.GuestAboutUsSetting.isLoading);
    const guestAboutUsSetting = useSelector((state) => state.GuestAboutUsSetting);

    const [aboutUsContent, setAboutUsContent] = useState(guestAboutUsSetting.aboutUsContent);

    useEffect(() => {
        dispatch(GuestAboutUsSettingActions.getAboutUsSettingData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAboutUsContent(guestAboutUsSetting.aboutUsContent);
    }, [guestAboutUsSetting]);

    const _renderContent = () => {
        const currentLang = Utils.getCurrentLanguage();
        const currentContent = currentLang === CONSTANTS.LANGUAGE_CODE.EN ? aboutUsContent.en : aboutUsContent.al;
        return (
            <div className="content-page">
                <FlexGridSixthTemplate>
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <div className="text" dangerouslySetInnerHTML={{ __html: currentContent }} />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
                <FlexGridSixthTemplate className="container-button">
                    <GridColHomepage colSize="6" gridTemplate="6">
                        <Button
                            value={t("general.Join the community")}
                            type="button"
                            color={ColorName.oysterBay}
                            background={ColorName.chathamsBlueDark}
                            onClick={() => history.push("/homepage/register")}
                        />
                        <Button
                            value={t("homepage.Search new business ideas")}
                            type="button"
                            color={ColorName.chathamsBlueDark}
                            background={ColorName.white}
                            onClick={() => history.push("/homepage/entrepreneurs/community")}
                        />
                    </GridColHomepage>
                </FlexGridSixthTemplate>
            </div>
        );
    };

    const _renderSummaryOverview = () => {
        return <SummryOverview />;
    };

    const _renderMain = () => {
        return (
            <Container>
                {_renderContent()}
                {_renderSummaryOverview()}
            </Container>
        );
    };
    return (
        <DefaultLayout
            context={_renderMain()}
            currentPage="about"
            isLoading={isLoading}
        />
    );
};

About.propTypes = {
};

export default About;
