import styled from 'styled-components';
import { ColorName } from '../../Variables';
import { ContainerFullWidth, GridCol, FlexGridSixth } from '../CommonLayout';

const Container = styled(ContainerFullWidth)`
    background-color: ${ColorName.oysterBay};
`;

const ContentSumary = styled.div`
    max-width: 1366px;
    padding: 3em;
    display: flex;
    margin: 0 auto;
    @media (max-width: 1024px) {
        padding: 3em 1em;
    }
    @media (max-width: 768px) {
    }
    margin-top: 3em;
`;
const GridColTemplate = styled(GridCol)`
    display: flex;
    flex-direction: row;
    place-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    & .number{
        font-size: 5em;
        color: ${ColorName.fern};
        font-weight: 600;
        display: flex;
        place-content: center;
        @media (max-width: 1024px) {
            font-size: 4em;
            min-width: 150px;
        }
    }
    & .description{
        color: ${ColorName.chathamsBlueDark};
        font-weight: 600;
        font-size: 1.3em;
        text-align: center;
    }
    & .diamon{
        background: white;
        width: 60px;
        height: 60px;
        transform: rotate(45deg);
        align-items: center;
        justify-content: center;
        @media (max-width: 1024px) {
            width: 20px;
            height: 20px;
        }
    }
    & .split-container{
        display: flex;
        align-items: center;
        align-content: center;
        @media (max-width: 768px) {
            place-content: center;
            margin: 1em 0;
        }
    }
`;

const FlexGridSixthTemplate = styled(FlexGridSixth)`
    width: 100%;
    align-items: stretch;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;
export {
    Container,
    ContentSumary,
    GridColTemplate,
    FlexGridSixthTemplate
};
