import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import Utils from "../libs/Utils";
import history from "../history";
import i18n from "../i18n";

const requestSuccess = (data = false) => {
    return {
        type: ActionTypes.REQUEST_UPDATE_PASSWORD_USER_SUCCESS,
        data
    };
};

const requestHasError = () => {
    return {
        type: ActionTypes.UPDATE_USER_FAILURE
    };
};

const requestIsLoading = () => {
    return {
        type: ActionTypes.UPDATE_USER_REQUEST
    };
};

const changePasswordSuccess = () => {
    return {
        type: ActionTypes.CHANGE_PASSWORD_SUCCESS
    };
};

const requestResetPassword = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.requestResetPassword(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestSuccess(res.data));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    history.push("/authentication");
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Opps...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const requestResetPasswordLimitedUser = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.requestResetPassword(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(requestSuccess(res.data));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    history.push("/homepage/add-limited-user");
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Opps...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const changePassword = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.changePassword(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(changePasswordSuccess(res.data));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    history.push("/authentication");
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Opps...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const changePasswordLimited = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.changePassword(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(changePasswordSuccess(res.data));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                    history.push("/homepage/add-limited-user");
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Opps...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

const changePasswordUser = (data) => {
    return (dispatch) => {
        dispatch(requestIsLoading());
        API.changePasswordUser(data)
            .then((res) => {
                if (res.data.status === true) {
                    dispatch(changePasswordSuccess(res.data));
                    Utils.popupAlert({ title: i18n.t("alert.Success"), text: res.data.message, type: "success" });
                } else {
                    dispatch(requestHasError());
                    Utils.popupAlert({ title: "Opps...", text: res.data.message, type: "error" });
                }
            })
            .catch((e) => {
                dispatch(requestHasError());
                Utils.popupAlert({ title: "Oops...", text: Utils.getMessage(e), type: "error" });
            });
    };
};

export default {
    requestSuccess,
    requestHasError,
    requestIsLoading,
    requestResetPassword,
    changePassword,
    changePasswordUser,
    requestResetPasswordLimitedUser,
    changePasswordLimited
};
