import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import UserDetailPopup from "../../Layout/PortalTemplate/UserDetailPopup";
import PopupUserExplore from "../../../containers/PortalContainer/User/Overview/PopupUserExplore";
import {
    Container,
    GeneralButtonUser
} from "./CompanyCard.styles";
import { ColorName } from "../../Variables";
import CONSTANTS from "../../../constants/Constants";

const CompanyCard = (props) => {
    const { t } = useTranslation();
    const { data, renderWithAvatar } = props;
    const [isShowUserDetailPopup, setIsShowUserDetailPopup] = useState(false);
    const [isShowPopUpSendMessage, setIsShowPopSendMessage] = useState(false);
    const isLogged = useSelector((state) => state.Authentication.isLogged);
    const showUserDetail = () => {
        setIsShowUserDetailPopup(true);
    };
    const openPopUpSendMessage = () => {
        setIsShowPopSendMessage(true);
    };
    const subDescription = (str) => {
        if (str) {
            if (str.length > 40) return `${str.substring(0, 39)}...`;
            return str;
        }
        return false;
    };

    const _renderFooterButtons = () => {
        return (
            <div className="container-button">
                <GeneralButtonUser
                    type="button"
                    background={ColorName.chathamsBlue}
                    className="for-more bor-ra-25"
                    value={t("homepageUser.More")}
                    onClick={() => showUserDetail()}
                />
                <GeneralButtonUser
                    type="button"
                    className="bor-ra-25 position-contact-me"
                    background={ColorName.chathamsBlue}
                    value={t("general.Send Message")}
                    height="40px"
                    onClick={() => openPopUpSendMessage(data)}
                />
            </div>
        );
    };

    const trimText = (string) => {
        if (string.length > 40) {
            string = string.substring(0, 39) + "...";
        }
        return string;
    };

    const printName = (legal, fullName) => {
        if(legal) return trimText(legal);
        return trimText(fullName);
    }
    const printFull = (legal, fullName) => {
        if(legal) return legal;
        return fullName;
    }

    const checkLengthText = (legal) => {
        if(legal && legal.length > 40) {
            return true;
        }
        return false;
    };

    const _renderContentOfEntrepreneur = (extraData) => {
        return (
            <div className="information-company">
                {renderWithAvatar && (
                    <>
                        <div className="name-company">
                            <div className="description">{extraData.business_idea_name}</div>
                            <div className="detail">{extraData.new_idea_describe || extraData.idea_describe }</div>
                        </div>

                    </>
                )}
                {!renderWithAvatar && (
                    <>
                        <div className="name-company">
                            <span title={checkLengthText(printFull(extraData.legal_investing_name, data.userData.full_name)) ? printFull(extraData.legal_investing_name, data.userData.full_name) : ""}
                            className="full-name">
                                {printName(extraData.legal_investing_name, data.userData.full_name)}
                            </span>
                            {extraData.establishment_year && (<span className="year">{extraData.establishment_year}</span>)}
                        </div>
                        <div className="description">{subDescription(extraData.idea_describe)}
                        {extraData.idea_describe.length > 40 && <div className='hover'>{extraData.idea_describe}</div>}
                        </div>
                        <div className="detail">{subDescription(t(`general.${extraData.industry_operate}`))}</div>
                        <div className="detail">{data.userData.website}</div>
                        <div className="detail">
                            {extraData.located}
                            {' '}
                            {extraData.located && "/"}
                            {' '}
                            {extraData.city}
                        </div>
                    </>
                )}
                {isLogged && (_renderFooterButtons())}
            </div>
        );
    };

    const _renderContentOfConsultant = (extraData) => {
        return (
            <div className="information-company">
                {renderWithAvatar && (
                    <div className="email-company" data-tooltip-text={data.email}>
                        {subDescription(data.email)}
                    </div>
                )}
                <div className="name-company">
                    <span className="full-name">{extraData.legal_investing_name || data.userData.full_name}</span>
                </div>
                <div className="detail">{data.userData.website}</div>
                <div className="detail no-height">
                    {extraData.expertise}
                </div>
                {isLogged && (_renderFooterButtons())}
            </div>
        );
    };

    const _renderContentOfInvestor = (extraData) => {
        return (
            <div className="information-company">
                {renderWithAvatar && (
                    <div className="email-company" data-tooltip-text={data.email}>
                        {subDescription(data.email)}
                    </div>
                )}
                <div className="name-company">
                    <span className="full-name">{extraData.legal_investing_name || data.userData.full_name}</span>
                </div>
                <div className="description">{subDescription(extraData.invest_platform)}
                {extraData.invest_platform.length > 40 && <div className='hover'>{extraData.invest_platform}</div>}
                </div>
                <div className="detail">{data.userData.website}</div>
                <div className="detail">
                    {extraData.located}
                    {' '}
                    {extraData.located && "/"}
                    {' '}
                    {extraData.city}
                </div>
                {isLogged && (_renderFooterButtons())}
            </div>
        );
    };

    const _renderContent = () => {
        const extraData = data.userData.extra_data_json;
        return (
            <Container className={renderWithAvatar ? "container-card no-image" : "container-card"}>
                {!renderWithAvatar && (
                    <div className="logo">
                        {data.userData.url_avatar_aws && (
                            <img src={data.userData.url_avatar_aws} alt="logo" />
                        )}
                        {!data.userData.url_avatar_aws && "LOGO"}
                    </div>
                )}
                {data.role.name === CONSTANTS.ROLE_NAME.ENTREPRENEUR && _renderContentOfEntrepreneur(extraData)}
                {data.role.name === CONSTANTS.ROLE_NAME.INVESTOR && _renderContentOfInvestor(extraData)}
                {data.role.name === CONSTANTS.ROLE_NAME.CONSULTANT && _renderContentOfConsultant(extraData)}
                { isShowUserDetailPopup && (<UserDetailPopup userDetail={data} hideModal={() => setIsShowUserDetailPopup(false)} />)}
                { isShowPopUpSendMessage && (<PopupUserExplore hideModal={() => setIsShowPopSendMessage(false)} dataItem={data} />)}
            </Container>
        );
    };

    const _renderMain = () => {
        return _renderContent();
    };
    return _renderMain();
};

CompanyCard.propTypes = {
    data: PropTypes.object,
    renderWithAvatar: PropTypes.bool
};

export default CompanyCard;
