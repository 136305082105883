import Api from "./Base";

export function addSponsor(data) {
    return Api.postFormData("/sponsor", data);
}

export function updateSponsor(data, id) {
    return Api.postFormData(`/sponsor/${id}/update`, data);
}

export function deleteSponsor(id) {
    return Api.post(`/sponsor/${id}/delete`);
}

export function getAllSponsor(data) {
    return Api.get("/sponsor_fetch", data);
}

export function getSponsorById(data, id) {
    return Api.get(`/sponsor/${id}`, data);
}
