import styled from 'styled-components';
import {
    VerticalContainer,
    BreadCrumb
} from "../../../../components/Common/CommonLayout";
import { ColorName } from '../../../../components/Variables';

const BodyContainer = styled(VerticalContainer)`
    padding: 3em;
    & .container-button{
        display: flex;
        & .link-register{
            color: ${ColorName.chathamsBlueLighter};
            padding: 0.5em;
            background-color: lightGray;
        }
        & .for-link{
            margin-top: 1em;
            display: flex;
            flex-direction: column;
        }
    }
    & .add-new{
        justify-content: flex-end;
    }
    & .share-link {
        & a {
            color: ${ColorName.chathamsBlueLighter};
            text-decoration: none;
            margin-left: 10px;
            font-weight: 400;
        }
        font-size: 18px;
        font-weight: 600;
        color: ${ColorName.chathamsBlueLighter};
    }
`;

const TitleInput = styled(BreadCrumb)`
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${ColorName.chathamsBlueDark};
    display: flex;
    justify-content: space-between;
    & .language{
        display: flex;
        align-items: center;
    }
    & .switch{
        margin-right: 1em;
    }
`;

const InputContainer = styled.input`
    background: none;
    border: 1px solid ${ColorName.chathamsBlue};
    height: auto;
    width: ${(props) => props.width || "50%"};
    border-radius: ${(props) => props.borderRadius || "0"};
    padding: 5px 10px;
    outline: none;
    max-width: 400px;
    min-width: 300px;
`;

const WrapperInput = styled(VerticalContainer)`
    flex: 1;
    display: flex;
    margin-bottom: 2em;
`;

const DropDownSelect = styled.select`
    padding: 5px;
    width: calc(50% + 20px);
    max-width: 420px;
    min-width: 320px;
    margin-right: 1em;
`;

export {
    DropDownSelect,
    BodyContainer,
    TitleInput,
    InputContainer,
    WrapperInput
};
