import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
    OptionDropDown,
    DropDownUserHeader
} from "./DropDownHeader.styles";
import useOutsideClick from "./ClickOutside";
import Utils from "../../../libs/Utils";
import history from "../../../history";
import Constants from "../../../constants/Constants";

const Dropdown = (props) => {
    const { t } = useTranslation();
    const ref = useRef();

    const toggle = () => {
        props.isShowDropDownHeader();
    };
    useOutsideClick(ref, () => {
        toggle();
    });

    const userSettings = () => {
        const userData = Utils.getSavedUserData();
        if (userData.role.name === Constants.ROLE_NAME.SUPER_ADMIN) return history.push('/admin/update');
        if (userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN) return history.push('/limited-admin/update');
        return history.push('/add-view-user');
    };

    const changePassword = () => {
        const userData = Utils.getSavedUserData();
        if (userData.role.name === Constants.ROLE_NAME.SUPER_ADMIN) return history.push('/admin/change-password');
        if (userData.role.name === Constants.ROLE_NAME.JUDGE) return history.push('/judge/change-password');
        if (userData.role.name === Constants.ROLE_NAME.LIMITED_ADMIN) return history.push('/limited-admin/change-password');
        return history.push('/change-password');
    };

    return (
        <DropDownUserHeader ref={ref}>
            <OptionDropDown onClick={() => userSettings()}>
                {t("general.Settings")}
            </OptionDropDown>
            <OptionDropDown onClick={() => changePassword()}>
                {t("general.Change Password")}
            </OptionDropDown>
        </DropDownUserHeader>
    );
};

Dropdown.propTypes = {
    isShowDropDownHeader: PropTypes.func
};

export default Dropdown;
