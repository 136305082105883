import styled from 'styled-components';
import { HorizontalContainer } from "../../../Common/CommonLayout";
import { ColorName } from "../../../Variables";

const FullWidth = styled.div`
    background: ${ColorName.chathamsBlueDark};
`;

const HeaderContainer = styled(HorizontalContainer)`
    background: ${ColorName.chathamsBlueDark};
    max-width: 1140px;
    margin: 0 auto;  
    width: 100%;
    & .container-header{
        margin: 0 1em;
        align-items: center;
        padding: 0.2em 0em; 
        justify-content: space-between;
        display: flex;
        width: 100%;
    }
    color: white;
`;

const ContentLang = styled.div`
    margin-left: 20px;
    font-weight: 600;
    position: relative;
`;

const AvatarContainer = styled(HorizontalContainer)`
    align-items: baseline;
    cursor: pointer;
    p {
        margin: 0 0.5em;
        font-size: 15px;
        font-weight: 600;
    }
`;

const AvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const Avatar = styled.div`
    margin-right: auto;
    display: flex;
    align-items: center;
    img {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        background: white;
    }
    & .img-wrapper {
        border-radius: 50%;
        width: 55px;
        background: white;
        border: 2px solid #F0F0F0;
        position: relative;
        span {
            position: absolute;
            bottom: 6em;
            right: 2.5em;
        }
    }
`;

const AlertContainer = styled.div`
    margin: 0 0.5em 0 2em;
    position: relative;
    i {
        position: relative;
        font-size: 30px;
    }
    & .badge {
        position: absolute;
        top: -5px;
        width: 15px;
        right: -10px;
        padding: 5px;
        text-align: center;
        border-radius: 50%;
        background: ${ColorName.fern};
        color: white;
        font-size: 10px;
        font-weight: 400;
    }
`;

export {
    HeaderContainer,
    AvatarContainer,
    Avatar,
    AlertContainer,
    AvatarWrapper,
    ContentLang,
    FullWidth
};
