import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import DefaultLayout from "../../../../components/Layout/PortalTemplate";
import { Wrapper, ProgressBar, DetailTitle, Column } from "./Judge.styles";
import { BreadCrumb } from "../../../../components/Common/CommonLayout";
import history from "../../../../history";
import CONSTANTS from "../../../../constants/Constants";
import RoundedTable from "../../../../components/Common/RoundedTable";

const ApplyApplicationDetails = () => {
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.FundingApplication.isLoading);
  const applyApplication = useSelector(
    (state) => state.FundingApplication.newFundingData
  );
  const [details, setDetails] = useState([]);
  const columnsApplication = ["applications status", "Created", "action"];
  const listOptions = {
    1: "view",
  };
  const [showNumber, setShowNumber] = useState(10);

  useEffect(() => {
    if (_.isEmpty(applyApplication))
      return history.push("/admin/funding-applications");
    setDetails(applyApplication);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickViewMoreFunding = () => {
    setShowNumber(showNumber + 10);
  };

  return (
    <DefaultLayout
      userRole="admin"
      isLoading={isLoading}
      page="fundingApplications"
      content={
        <Wrapper>
          <BreadCrumb>Detail Funding Application</BreadCrumb>
          <ProgressBar className="mb-2">
            <Column>
              <DetailTitle>
                {t("fundingApplications.Funding Opportunity’s name")}: &nbsp;
                <span>{!_.isUndefined(details) ? details.title : ""}</span>
              </DetailTitle>
            </Column>
            <Column>
              <DetailTitle>{t("general.Status")}:</DetailTitle>
              <span>
                {!_.isUndefined(details)
                  ? _.findKey(
                      CONSTANTS.FUNDING_APPLICATION_STATUS,
                      _.partial(_.isEqual, details.status)
                    )
                  : ""}
              </span>
            </Column>
            <Column>
              <DetailTitle>{t("general.Amout")}:</DetailTitle>
              <span>{!_.isUndefined(details) ? details.amount : ""}</span>
            </Column>
            <Column>
              <DetailTitle>{t("fundingApplications.Deadline")}:</DetailTitle>
              <span>
                {!_.isUndefined(details)
                  ? moment(details.deadline).format("YYYY-MM-DD")
                  : ""}
              </span>
            </Column>
          </ProgressBar>
          <BreadCrumb>
            {t("fundingApplications.Submitted Applications")}
          </BreadCrumb>
          <RoundedTable
            initialData={
              !_.isUndefined(details) ? details.applyApplications : []
            }
            columns={columnsApplication}
            hideTitleHeader
            tableType={CONSTANTS.TABLE_TYPE.APPLY_APPLICATION}
            listOptions={listOptions}
            showNumberItems={showNumber}
            buttonTilte={t("general.View More")}
            handleClick={clickViewMoreFunding}
          />
        </Wrapper>
      }
    />
  );
};

export default ApplyApplicationDetails;
